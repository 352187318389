export const initAppLang = (lang: Map<string, string>): void => {
  lang.set("Email", "E-mail");
  lang.set("Password", "Heslo");
  lang.set("Retry_password", "Opakujte heslo");
  lang.set("Phone", "Telefonní číslo");
  lang.set("Phone_numbers", "Telefonní čísla");
  lang.set("Name_Surname", "Jméno a příjmení");
  lang.set("Send", "Odeslat");
  lang.set("Registration", "Registrace");
  lang.set("Reset_password", "Obnova hesla");
  lang.set("Login", "Přihlášení");
  lang.set("Log in", "Přihlásit se");
  lang.set("Back_to_login", "Zpět na přihlášení");
  lang.set("Bad_email_or_password", "Špatný přihlašovací e-mail nebo heslo");
  lang.set("Bad_format_ico", "Špatný formát IČO");
  lang.set("Enter correct url!", "Zadejte platnou URL!");
  lang.set("ICO", "IČO");
  lang.set("DIC", "DIČ");
  lang.set("Registration_success", "Registrace proběhla úspěšně");
  lang.set("Activate_profile", "Aktivovat profil");
  lang.set("Password_must_by_same", "Hesla musí být stejná");
  lang.set(
    "Reset_password_success",
    "Reset hesla proběhl úspěšně, bude Vám poslán e-mail"
  );
  lang.set(
    "Other information about the company will be loaded automaically",
    "Další údaje o firmě načteme automaticky"
  );

  lang.set("Telidoo", "Telidoo");
  lang.set("Business", "Business");
  lang.set("User", "Zaměstnanec");
  lang.set("Users", "Zaměstnanci");
  lang.set("Company", "Firma");
  lang.set("Log out", "Odhlasit");
  lang.set("Profile", "Profil");
  lang.set("Settings", "Nastavení");
  lang.set("Venues", "Provozovny");
  lang.set("Roles", "Role");
  lang.set("Accounts", "Účty");

  lang.set("Change password", "Změnit heslo");
  lang.set("Change", "Změnit");
  lang.set("Old password", "Staré heslo");
  lang.set("New password", "Nové heslo");
  lang.set("New password again", "Nové heslo znovu");
  lang.set(
    "Password was changed successfully",
    "Nové heslo bylo úspěšně nastaveno"
  );

  lang.set("Your company", "Vaše společnost");
  lang.set("Name", "Název");
  lang.set("City", "Město");
  lang.set("Postcode", "PSČ");
  lang.set("Street", "Ulice a číslo popisné");
  lang.set("Logo", "Logo");
  lang.set("Region Code", "Kód regionu");
  lang.set("VAT ID", "IČO");
  lang.set("Website", "Webová stránka");
  lang.set("Published", "Publikováno");
  lang.set("published-true", "Firma publikována");
  lang.set("published-false", "Firma nepublikována");

  lang.set("Amount", "Částka");
  lang.set("Description", "Popis");
  lang.set("Short description", "Zkrácený popis");
  lang.set(
    "Short description info",
    "Bude zobrazen na volací obrazovce. Max 200 znaků"
  );
  lang.set(
    "Short description of your company",
    "Stručný popis vaší společnosti"
  );
  lang.set("Longer description of your company", "Podrobný popis");
  lang.set("Contact", "Kontakt");
  lang.set("Send payment request to:", "Odeslat žádost o platbu:");

  lang.set("Yes", "Ano");
  lang.set("No", "Ne");

  lang.set("No category selected", "Nebyla vybrána žádná kategorie");
  lang.set("Category", "Kategorie");
  lang.set("Categories", "Kategorie");
  lang.set("published-undefined", "");
  lang.set("Choose a category", "Vyberte kategorii");
  lang.set("Create category", "Vytvořit kategorii");
  lang.set("Name cannot be empty!", "Jméno nemůže být prázdné!");
  lang.set("Back", "Zpět");
  lang.set("Names", "Jména");
  //   lang.set("Name", "Jméno");
  lang.set("Parent", "Rodič");
  lang.set("Up", "Nahoru");
  lang.set("Edit", "Upravit");
  lang.set(
    "Are you sure? This will remove this category and ALL sub-categories",
    "Jste si jistí? Tím se odstraní tato kategorie a VŠECHNY podkategorie"
  );
  lang.set("Remove", "Odstranit");
  lang.set("Add new categories", "Přidejte nové kategorie");

  lang.set("Your company", "Vaše společnost");
  lang.set("Error fetching companies.", "Chyba při načítání společností.");
  lang.set("Loading", "Načítání");
  lang.set("Loading...", "Načítání...");
  lang.set("Editing your company", "Nastavení firmy");
  lang.set("Save", "Uložit");
  lang.set("Cancel", "Zrušit");
  lang.set("Delete", "Odstranit");
  lang.set("Select image", "Vyberte obrázek");
  lang.set("Select logo", "Vyberte logo");

  lang.set("Establishments", "Provozovny");

  lang.set("Phone number is required", "Telefonní číslo je požadované");
  lang.set(
    "You can add phone number later.",
    "Telefonní číslo můžete přidat později."
  );
  lang.set(
    "You can link establishment belonging to this phone number later.",
    "Provozovnu patřící k tomuto telefonnímu číslu můžete nastavit později."
  );
  lang.set(
    "You can link user belonging to this phone number later.",
    "Užívatele patřící k tomuto telefonnímu číslu můžete nastavit později."
  );
  lang.set("Phone numbers active", "Aktivní telefonní čísla");
  lang.set("Phone numbers", "Telefonní čísla");
  lang.set("Phone number", "Telefonní číslo");
  lang.set("Show phone numbers", "Zobrazit telefonní čísla");
  lang.set("Manage phone numbers", "Spravovat telefonní čísla");
  lang.set("Add category", "Přidat kategorii...");
  lang.set("Done", "Hotovo");
  lang.set("Jump to...", "Přejít na...");
  lang.set("Selected categories:", "Vybrané kategorie:");
  lang.set("Main page", "Hlavní strana");
  lang.set("Registration was successful.", "Registrace byla úspěšná.");
  lang.set(
    "Email was sent to you with a link to activate the account and create a password.",
    "Byl Vám zaslán e-mail s odkazem na aktivaci účtu a vytvoření hesla."
  );
  lang.set("no data error", "Problém s připojením k serveru");

  lang.set("Active", "Aktivní");
  lang.set("Deactivated", "Deaktivováno");
  lang.set("Add", "Přidat");
  lang.set("First name", "Křestní jméno");
  lang.set("Last name", "Příjmení");
  lang.set("Enabled", "Povoleno");
  lang.set("Disabled", "Zakázáno");
  lang.set("Role", "Role");
  lang.set(
    "No users exist for your company. Create one!",
    "Pro vaši společnost neexistují žádní zaměstnanci. Vytvořte prvního!"
  );
  lang.set("Linked users", "Propojení zaměstnanci");
  lang.set("Linked user", "Propojený zaměstnanec");
  lang.set("Linked establishments", "Propojené provozovny");
  lang.set("Linked establishment", "Propojená provozovna");
  lang.set("Linked app", "Propojená aplikace");
  lang.set("Add new user", "Přidat nového zaměstnance");
  lang.set("Upload image", "Nahrát obrázek");
  lang.set(
    "You need to set and verify your email first.",
    "Nejprve musíte nastavit a ověřit svůj e-mail."
  );
  lang.set("Your email", "Tvůj e-mail");
  lang.set("Submit", "Potvrdit");
  lang.set(
    "New email and password were set successfully.",
    "Nový e-mail a heslo byly úspěšně nastaveny."
  );
  lang.set("Back to see users", "Zpět pro zobrazení zaměstnanců");
  lang.set("Confirm new email", "Potvrďte nový e-mail");
  lang.set("Can manage companies", "Může řídit společnosti");
  lang.set("Can manage users", "Může spravovat zaměstnance");
  lang.set("Remove role", "Odstranit roli");
  lang.set("Add new role", "Přidat novou roli");
  lang.set("true", "Ano");
  lang.set("false", "Ne");
  lang.set("Create role", "Vytvořit roli");
  lang.set(
    "Assign roles to users of my company:",
    "Přiřadit role zaměstnancům mé společnosti:"
  );
  lang.set(
    "Are you sure you want to change your own role?",
    "Opravdu chcete změnit svou vlastní roli?"
  );
  lang.set("Editing establishment", "Upravit provozovnu");
  lang.set("Save", "Uložit");
  lang.set(
    "Are you sure you want to delete this establishment?",
    "Opravdu chcete tuto provozovnu smazat?"
  );
  lang.set(
    "Are you sure you want to delete this phone number?",
    "Opravdu chcete toto telefonní číslo smazat?"
  );
  lang.set(
    "Are you sure you want to delete this user?",
    "Opravdu chcete tohoto uživatele smazat?"
  );
  lang.set(
    "No establishments exist for your company. Create the first one!",
    "Pro vaši společnost neexistuje žádná provozovna. Pro zobrazení katalogu je nutné ji nastavit."
  );
  lang.set("Photo", "Fotografie");
  lang.set("Add new establishment", "Přidat novou provozovnu");
  lang.set("Uploading", "Nahrávání");
  lang.set("Payments", "Platby");
  lang.set("Create invoice", "Vytvořit fakturu");
  lang.set("Reservations", "Rezervace");
  lang.set("Transactions", "Transakce");
  lang.set("Home page", "Domovská stránka");
  lang.set(
    "Your company doesn't have any establishments added yet.",
    "Vaše společnost zatím nemá žádné provozovny."
  );
  lang.set("Add your establishment", "Přidejte svojí provozovnu");
  lang.set("Company logo", "Logo firmy");
  lang.set("Add images to your establishment", "Přidat fotky provozovny");
  lang.set("Upload cropped image", "Nahrajte oříznutý obrázek");
  lang.set("Cropped Preview:", "Oříznutý náhled:");
  lang.set("Rotate image:", "Otočit obrázek:");
  lang.set("Please crop image:", "Ořízněte obrázek:");
  lang.set("Company headquaters", "Sídlo společnosti");
  lang.set("Saved successfuly", "Úspěšně uloženo");
  lang.set("rotate_left", "vlevo");
  lang.set("rotate_original", "originál");
  lang.set("rotate_right", "vpravo");
  lang.set("Editing phone number", "Upravit telefonní číslo");
  lang.set("Editing user", "Upravit zaměstnance");
  lang.set("Name (by official registry)", "Název (dle Obchodního rejstříku)");
  lang.set(
    "Address not found, make sure it is entered correctly",
    "Adresa nenalezena, ujistěte se že je zadána správně"
  );
  lang.set("Set your profile photo", "Profilová fotka");
  lang.set(
    "Phone number must be 9 digits long",
    "Telefonní číslo musí mít 9 číslic"
  );
  lang.set(
    "No phone numbers exist for your company. Create one!",
    "Pro vaši společnost neexistují žádná telefonní čísla. Vytvořte první!"
  );
  lang.set(
    "Error: This phone number is already used.",
    "Chyba: Toto telefonní číslo je již v našem systému použito."
  );
  lang.set("Email", "Email");
  lang.set("Facebook", "Facebook");
  lang.set("Instagram", "Instagram");
  lang.set("Public", "Veřejný");
  lang.set("Private", "Soukromný");
  lang.set("Email is public", "Veřejný e-mail");
  lang.set(
    "Once you will have establishment added, you can link it with this phone number here.",
    "Jakmile budete mít přidané zařízení, můžete jej propojit s tímto telefonním číslem zde."
  );
  lang.set(
    "Once you will have user added, you can link it with this phone number here.",
    "Jakmile bude uživatel vytvořen, můžete jej propojit s tímto telefonním číslem zde."
  );
  lang.set("Phone number is not valid", "Neplatné telefonní číslo");
  lang.set("User status", "Stav uživatele");
  lang.set("Status", "Stav");
  lang.set("User activated", "Uživatel aktivován");
  lang.set("Waiting for email verification", "Čekání na ověření e-mailem");
  lang.set("Resend activation email", "Znovu poslat aktivační e-mail");
  lang.set(
    "New email was set successfully. Continue by setting up your password. Instructions were sent to your email.",
    "Nový e-mail byl úspěšně nastaven. Pokračujte nastavením hesla. Pokyny byly odeslány na váš e-mail."
  );
  lang.set("Must be Facebook link", "Musí být odkaz na Facebooku");
  lang.set("Must be Instagram link", "Musí být odkaz na Instagramu");
  lang.set("Profile saved successfully.", "Profil byl úspěšně uložen.");
  lang.set("Your profile", "Váš profil");
  lang.set(
    "Company was not updated, probably because of missing privilegies.",
    "Společnost nebyla aktualizována, pravděpodobně kvůli chybějícím právům."
  );
  lang.set(
    "User not updated. Do you have a permission?",
    "Uživatel nebyl aktualizován. Máte povolení?"
  );
  lang.set(
    "Establishment not updated. Do you have a permission?",
    "Provozovna nebyla aktualizována. Máte povolení?"
  );
  lang.set(
    "Delete Establishment failed. Do you have permission?",
    "Odstranit zařízení se nezdařilo. Máte povolení?"
  );
  lang.set(
    "Phone number not updated. Do you have a permission?",
    "Telefonní číslo nebylo aktualizováno. Máte povolení?"
  );
  lang.set(
    "Deleting phone number failed. Do you have a permission?",
    "Smazání telefonního čísla se nezdařilo. Máte povolení?"
  );
  lang.set("Add new phone number", "Přidat nové telefonní číslo");
  lang.set("Name of new phone number", "Název nového telefonního čísla");
  lang.set("Write phone number", "Napište telefonní číslo");
  lang.set("Phone number invalid", "Telefonní číslo je neplatné");
  lang.set(
    "This phone number already exist",
    "Toto telefonní číslo již existuje"
  );
  lang.set(
    "Set up your basic company information",
    "Nastavte si základní údaje firmy"
  );
  lang.set(
    "Logo will be in square format, displayed in the catalog and incoming call screens",
    "Logo ve čtvercovém formátu, bude zobrazeno v katalogu a u příchozích hovorů"
  );
  lang.set("No categories set up", "Nemáte nastavené žádné kategorie");
  lang.set(
    "Set up establishments that will show up in the catalog",
    "Nastavte provozovny, které se budou zobrazovat v katalogu"
  );
  lang.set(
    "Add your employees to give them access and show their info in incoming calls",
    "Přidejte své zaměstnance pro přísup do systému a k jejich zobrazování u příchozích hovorů"
  );
  lang.set(
    "Set up different access levels for your employees",
    "Nastavte různé úrovně přístupu do systému pro vaše zaměstnance"
  );
  lang.set(
    "Add company phone numbers to identify them in incoming calls",
    "Přidejte firemní telefonní čísla pro jejich zobrazení u příchozích hovorů"
  );
  lang.set(
    "Calls will show up with the employees name and photo",
    "Pro tyto čísla se bude zobrazovat jméno a fotografie zaměstnance"
  );
  lang.set("Really delete?", "Opravdu smazat?");
  lang.set("account_disabled", "Účet byl deaktivovaný");
  lang.set("invalid_details", "Neplatné údaje");
  lang.set("invalid_email", "Neplatný e-mail");
  lang.set(
    "Saving data failed.",
    "Ukládání dat selhalo. Zkontrolujte svá oprávnění, případně kontaktujte podporu."
  );
  lang.set("Role name already exists!", "Název role již existuje!");

  lang.set("CentrumSMS", "CentrumSMS");
  lang.set("History", "Historie");
  lang.set("App connected", "Aplikace propojena");
  lang.set(
    "Waiting for app verification",
    "Čeká na potvrzení v aplikaci Telidoo. Propojení potvrďte v nastavení účtu."
  );
  lang.set("Error occured", "Došlo k chybě");
  lang.set("No number set up", "Nemáte nastaveno žádné číslo");
  lang.set(
    "CentrumSMS setup instructions",
    "1. Nainstalujte si aplikaci Telidoo do mobilního telefonu\n2. Zaregistrujte se a aktivujte aplikaci\n3. V nastavení aktivujte službu CentrumSMS\n4. Přidejte telefonní číslo do Telidoo Business\n5. Potvrďte propojení v mobilní aplikaci\n6. Hotovo, můžete odesílat zprávy"
  );
  lang.set("Connected numbers", "Připojená čísla");
  lang.set("Recipients", "Příjemci");
  lang.set(
    "You can separate by line",
    "Můžete napsat více příjemců, každý na řádek zvlášť"
  );
  lang.set("Sent", "Odesláno");
  lang.set("Text", "Text");
  lang.set("Status received", "Přijato");
  lang.set("Status sent", "Odesláno");
  lang.set("Status new", "Ve frontě");
  lang.set("Status queued", "Odesílání");
  lang.set("Status delivered", "Doručeno");
  lang.set("Status blocked", "Blokováno");
  lang.set("Date", "Datum");
  lang.set("Type", "Typ");
  lang.set("Incoming", "Příchozí");
  lang.set("Outgoing", "Odchozí");
  lang.set("Filter...", "Filtr...");
  lang.set("Tag", "Označení");
  lang.set("Tags", "Označení");
  lang.set("New item", "Nová položka");
  lang.set("Templates", "Šablony");
  lang.set(
    "For your organization",
    "Slouží pro vaše uspořádání (např. označení vyřízeno)"
  );
  lang.set("Can use CentrumSMS", "Může používat CentrumSMS");
  lang.set("Contacts", "Kontakty");
  lang.set(
    "Manage your customers and business contacts",
    "Spravujte své zákazníky a obchodní kontakty"
  );
  lang.set("No options", "Žádné možnosti");
  lang.set("New contact", "Nový kontakt");
  lang.set("Editing contact", "Upravit kontakt");
  lang.set("Title before", "Titul před");
  lang.set("Title after", "Titul za");
  lang.set("Country", "Země");
  lang.set("Field", "Pole");
  lang.set("Import", "Import");
  lang.set("Export", "Export");
  lang.set("Table has header row", "Tabulka má řádek s hlavičkami");
  lang.set("Select contacts", "Vybrat kontakty");
  lang.set("Send from second SIM", "Odeslat z druhé SIM");
  lang.set("Close", "Zavřít");
  lang.set("Custom fields", "Vlastní pole");
  lang.set("Sender", "Odesilatel");
  lang.set("Address", "Adresa");
  lang.set("Selected", "Vybráno");
  lang.set("characters", "znaků");
  lang.set("Filter", "Filter");
  lang.set("Contacts", "Kontakt");
  lang.set("Reply", "Odpovědět");
  lang.set("Deliveries", "Rozesílky");
  lang.set(
    "Delivery will be finished approximately",
    "Rozesílka bude dokončena přibližně"
  );
  lang.set("Must be at least one admin", "Musí být aspoň jeden administrátor");
  lang.set("Reservio integration", "Reservio propojení");
  lang.set(
    "Reservio integration failed",
    "Propojení Reservio selhalo. Zkontrolujte své údaje, případně kontaktujte podporu."
  );
  lang.set("Don't send", "Neodesílat");
  lang.set("Connected to account", "Propojeno s účtem");
  lang.set(
    "sms_template_accepted",
    "Šablona při čekání nové rezervace na přijetí"
  );
  lang.set("sms_template_confirmed", "Šablona při přijetí rezervace");
  lang.set("sms_template_rejected", "Šablona při odmítnutí rezervace");
  lang.set("sms_template_cancelled", "Šablona při zrušení rezervace");
  lang.set("sms_template_moved", "Šablona při přesunu termínu");
  lang.set(
    "sms_template_reminder",
    "Šablona pro připomenutí termínu objednávky"
  );
  lang.set(
    "Reservation reminder interval before",
    "Čas připomenutí rezervace předem"
  );
  lang.set(
    "Log in to Reservio",
    "Přihlašte se pomocí svého hesla do Reservio. Heslo nebude uloženo."
  );
  lang.set(
    "Reservio info HTML",
    `<p>
Ujistěte se, že v systému Reservio máte SMS vypnuté, aby se neodesílaly dvakrát.
</p>
<p>
  Proměnné, které můžete použít v šablonách:
  <ul>
    <li>CLIENT_NAME - jméno klienta</li>
    <li>SERVICE_NAME - název služby</li>
    <li>RESOURCE_NAME - zaměstnanec</li>
    <li>EVENT_NAME - název události</li>
    <li>EVENT_DATE - datum rezervace</li>
    <li>EVENT_TIME - čas rezervace</li>
    <li>
      EVENT_DATE_PREVIOUS - původní datum rezervace (při přesunu
      termínu)
    </li>
    <li>
      EVENT_TIME_PREVIOUS - původní čas rezervace (při přesunu
      termínu)
    </li>
  </ul>
</p>`
  );
  lang.set("Verification pending", "Schválení");
  lang.set(
    "Verification pending message",
    "Váš účet čeká na schválení administrátorem firmy. Jakmile bude schválen, pošleme Vám e-mail. V případě otázek kontaktujte naši podporu."
  );
  lang.set("Shoptet integration", "Shoptet propojení");
  lang.set("Connect to Shoptet", "Propojit s Shoptetem");
  lang.set("Connected to eshop", "Propojeno s e-shopem");
  lang.set(
    "Shoptet info HTML",
    `<p>
Zde si můžete nastavit vaše SMS šablony. Pokud si šablony nenastavíte, tak nebudou odeslány žádné SMS. Názvy šablon musí být unikátní.
</p>
<p>
  Proměnné, které můžete použít v poli "Text":
  <ul>
    <li>ESHOP_NAME - jméno vašeho eshopu</li>
    <li>ESHOP_URL - webová adresa vašeho eshopu</li>
    <li>ORDER_ID - číslo objednávky</li>
    <li>CUSTOMER_NAME - celé jméno zákazníka</li>
    <li>CITY - název města</li>
    <li>HOUSE_NUMBER - číslo domu</li>
    <li>STREET_NAME - název ulice</li>
    <li>ZIP_CODE - PSČ</li>
    <li>ADDRESS - celá adresa zákazníka</li>
    <li>EMAIL - email zákazníka</li>
    <li>TRACKING_URL - webová adresa pro sledování objednávky</li>
    <li>TRACKING_NUMBER - číslo balíku pro sledování objednávky</li>
    <li>PRICE - cena objednávky včetně měny</li>
  </ul>

  Pokud si přejete, aby se SMS odeslala při zvoleném stavu jen za určitých podmínek, lze využít pole "Podmínky pro odeslání".
   Při nevyplnění podmínky se SMS při zvoleném statusu odešle vždy. Jednotlivé podmínky oddělujte enterem a jednotlivé možnosti v nich čárkou.
   Při volbě vlastního statusu je nutné použít totožný název jako v nastavení statusu v aplikaci Shoptet. V případě, 
   že by při odesílání sms vyhovovalo více šablon, vybere se automaticky šablona s více podmínkami.
  <br><br>
  Aktuálně podporované podmínky: <br><br>
  PAYMENT_TYPE - Typ platby
  <ul>
    <li>CASH_ON_DELIVERY - Dobírka</li>
    <li>BANK_TRANSFER - Bankovní převod</li>
    <li>PAY_IN_CASH - Platba hotově</li>
    <li>PAY_BY_CARD - Platba kartou</li>
  </ul>
  ORDER_PAID - Stav platby
  <ul>
    <li>TRUE - Zaplaceno</li>
    <li>FALSE - Nezaplaceno</li>
  </ul>
  DELIVERY_TYPE - Typ dopravy
  <ul>
    Je třeba úvést přesný a celý název dopravy. Název dopravy lze nalézt v Shoptet nastavení - způsoby dopravy<br>
    Například: DELIVERY_TYPE = Zásilkovna Z point,PPL ParcelShop,Osobní odběr 
  </ul>
  COUNTRY_CODE - Kód země
  <ul>
    Je třeba úvést správný kód země dle ISO 3166-1 alpha-2.<br>
    Například: COUNTRY_CODE = CZ,SK
  </ul>
  LANGUAGE_CODE - Kód jazyku
  <ul>
    Je třeba úvést správný kód jazyku dle ISO 639-1.<br>
    Například: LANGUAGE_CODE = cs,en
  </ul> 
  <br>
Například při podmínce: <br><br> 
ORDER_PAID = FALSE  <br>
PAYMENT_TYPE = BANK_TRANSFER,PAY_BY_CARD  <br>
DELIVERY_TYPE = Slovenská pošta,Osobní odběr <br>
COUNTRY_CODE = CZ <br>
LANGUAGE_CODE = en <br><br>
se SMS odešle pouze, pokud nebude objednávka zaplacena, metodou platby bude bankovní převod nebo platba kartou, 
metodou dopravy Slovenská pošta nebo osobní odběr, země do které se doručuje je Česká republika a při objednávce použil zákazník jazyk angličtina.

</p>`
  );
  lang.set(
    "Connect to Shoptet info text",
    "Pro propojení s vaším eshopem je nutné mít vyplněné stejné DIČ v aplikaci Shoptet a také v aplikaci Telidoo business."
  );
  lang.set(
    "Shoptet connection failed",
    "Propojení s Shoptetem selhalo, zkontroljte zda máte vyplněno DIC a zkuste to znovu. Pokud bude chyba přetrvávat, kontaktujte prosím podporu"
  );
  lang.set("Money spent", "Celková útrata");
  lang.set("sms_status_order_created", "Objednávka vytvořena");
  lang.set("sms_status_order_in_process", "Objednávka se vyřižuje");
  lang.set("sms_status_order_completed", "Objednávka vyřízena");
  lang.set("sms_status_order_canceled", "Objednávka zrušena");
  lang.set("sms_status_order_pending", "Objednávka nevyřízená");
  lang.set("sms_status_custom", "Vlastní status");
  lang.set("Event type", "Status objednávky");
  lang.set("Custom state name", "Název vlastního statusu (shodný s Shoptet)");
  lang.set("Sending conditions", "Podmínky pro odeslání");
  lang.set("Shoptet templates", "Shoptet šablony");
  lang.set("Condition", "Podmínka");
  lang.set("Condition null", "žádná");
  lang.set("Incorrect condition", "Chyba v podmínce");
  lang.set("Import contacts", "Importovat kontakty");
  lang.set("Payment Terminals", "Platební terminály");
  lang.set(
    "Manage your payment terminals",
    "Spravujte své platební terminály TeliPay"
  );
  lang.set("No payment terminals", "Žádné platební terminály");
  lang.set("New payment terminal", "Zažádat o nový platební terminál");
  lang.set("Payment Terminals Statements", "Výpisy z platebních terminálů");
  lang.set(
    "Manage your payment terminals statements",
    "Zobrazte své výpisy z terminálů TeliPay"
  );
  lang.set("Statements", "Výpisy");
};
