import React from "react";
import mailIlust from "./mail.svg";

import { T } from "src/utils/translate";

export const VerificationPendingPage: React.FC = () => {
  return (
    <main className="main">
      <div className="main__content login">
        <div className="col-16">
          <div className="row">
            <form className="form login__form">
              <img
                src={mailIlust}
                style={{ width: "250px", marginBottom: "40px" }}
              />
              <h2>{T("Verification pending")}</h2>
              {T("Verification pending message")}
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};
