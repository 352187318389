export function usePercentLayout(hooks: any): void {
  hooks.getHeaderProps.push(getHeaderProps);
  hooks.getCellProps.push(getCellProps);
  hooks.getFooterProps.push(getFooterProps);
}

usePercentLayout.pluginName = "usePercentLayout";

const getHeaderProps = (props, { column }) => [
  props,
  {
    style: {
      width: `${column.totalWidth}%`,
    },
  },
];

const getCellProps = (props, { cell }) => [
  props,
  {
    style: {
      width: `${cell.column.totalWidth}%`,
    },
  },
];

const getFooterProps = (props, { column }) => [
  props,
  {
    style: {
      width: `${column.totalWidth}%`,
    },
  },
];
