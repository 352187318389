import * as React from "react";
import { yup } from "../../utils/yup";
import { TextInput } from "../../sharedComponents/forms";
import { authenticationService } from "../../services/authentication";
import { T } from "../../utils/translate";
import { Form } from "../../sharedComponents/Form";
import {
  USER_EMAIL,
  PASS_HASH,
} from "../../services/authentication/authenticationService";
import { datagetterService } from "../../services/datagetter";
import Button from "@material-ui/core/Button";

const FORM_VALIDATION = yup.object().shape({
  email: yup.string().required(),
  //   password: yup.string().required().min(8),
  //   secondPassword: yup
  //     .string()
  //     .required()
  //     .min(8)
  //     .oneOf([yup.ref("password"), null], T("Password_must_by_same")),
});

const FORM_VALIDATION_NEW = yup.object().shape({
  //   email: yup.string().required(),
  password: yup.string().required().min(8),
  secondPassword: yup
    .string()
    .required()
    .min(8)
    .oneOf([yup.ref("password"), null], T("Password_must_by_same")),
});

const INIT_VALUES: NewEmailFormModel = {
  email: "",
  //   password: "",
  //   secondPassword: "",
};
const INIT_VALUES_NEW: NewPasswordFormModel = {
  password: "",
  secondPassword: "",
};

interface NewEmailFormModel {
  email: string;
}
interface NewPasswordFormModel {
  password: string;
  secondPassword: string;
}

export const NewEmail: React.FC<{ globalRefresh: () => void }> = ({
  globalRefresh,
}) => {
  console.log("NE");
  const [neww, setNeww] = React.useState(0); // 0 = no email, no pass; 1 = email set, need to verify; 3 = email set, password to set; 4 = email set password set all done

  const onSubmit = async (values: NewEmailFormModel, reset: Function) => {
    const passHash = await localStorage.getItem(PASS_HASH);
    if (!passHash) {
      console.error("email not in local storage! ph");
      authenticationService.logout();
      return;
    }
    const email = values.email;
    let companyEmails = [];

    await datagetterService.passHashToEmails(passHash).then((data) => {
      console.log("data", data);
      companyEmails = data?.emails;
    });

    console.log("waited");
    if (!companyEmails.some((c) => c === email)) {
      // I dont want to send activation email if email was already activated by user clicking on secret link that was sent to that email
      // So I will check if email he is trying to set is that already "verified"
      console.log("need to verify this email");
      authenticationService
        .setEmail(email, passHash, true)
        .then(() => {
          reset();
          setNeww(1);
          localStorage.setItem(USER_EMAIL, email);
        })
        .catch((error: string) => {
          alert(T(error));
          setNeww(0);
        });
    } else {
      console.log("email verified already, proceeding to set password");
      authenticationService
        .setEmail(email, passHash, false)
        .then(() => {
          reset();
          localStorage.setItem(USER_EMAIL, email);

          setNeww(3);
        })
        .catch((error: string) => {
          alert(T(error));
          setNeww(0);
        });
    }
  };

  const onSubmitNewPassword = async (
    values: NewPasswordFormModel,
    reset: Function
  ) => {
    const email = await localStorage.getItem(USER_EMAIL);
    const passHash = await localStorage.getItem(PASS_HASH);

    if (!email || email === "undefined") {
      console.error("email not in local storage!");
      authenticationService.logout();
      return;
    }
    if (!passHash) {
      console.error("passHash not in local storage!");
      authenticationService.logout();
      return;
    }

    authenticationService
      .setNewPassword(passHash, email, values.password)
      .then(() => {
        reset();
        setNeww(4);
      })
      .catch((error: string) => {
        alert(T(error));
        setNeww(0);
      });
  };

  const formClicked = () => {
    // TODO why?
    // setNeww(false);
  };

  return (
    <div
      onClick={() => {
        formClicked();
      }}
    >
      {/* <h1 className="title-h3 login__form--title">{T("New email")}</h1> */}
      {neww === 1 && (
        <div>
          <h3>
            {T(
              "New email was set successfully. Continue by setting up your password. Instructions were sent to your email."
            )}
          </h3>
          <Button
            variant="contained"
            color="primary"
            style={{
              cursor: "pointer",
            }}
            onClick={globalRefresh}
          >
            {T("Back to see users")}
          </Button>
        </div>
      )}
      {neww === 4 && (
        <div>
          <h3>{T("New email and password were set successfully.")}</h3>
          <Button
            variant="contained"
            color="primary"
            style={{
              cursor: "pointer",
            }}
            onClick={globalRefresh}
          >
            {T("Back to see users")}
          </Button>
        </div>
      )}
      {neww === 0 && (
        <Form<NewEmailFormModel>
          onSubmit={onSubmit}
          defaultValues={INIT_VALUES}
          validationSchema={FORM_VALIDATION}
          className="form login__form"
        >
          <React.Fragment>
            <TextInput
              autohook
              type="email"
              name="email"
              label={`${T("Your email")}`}
            />
            {/* <TextInput autohook
                  type="password"
                  name="password"
                  label={`${T("New password")} *`}
                />
                <TextInput autohook
                  type="password"
                  name="secondPassword"
                  label={`${T("New password again")} *`}
                /> */}
            <div className="form-group form-group-submit">
              <Button
                variant="contained"
                color="primary"
                style={{
                  cursor: "pointer",
                }}
                type="submit"
              >
                {T("Submit")}
              </Button>
            </div>
          </React.Fragment>
        </Form>
      )}

      {neww === 3 && (
        <Form<NewPasswordFormModel>
          onSubmit={onSubmitNewPassword}
          defaultValues={INIT_VALUES_NEW}
          validationSchema={FORM_VALIDATION_NEW}
          className="form login__form"
        >
          <TextInput
            autohook
            type="password"
            name="password"
            label={`${T("New password")} *`}
          />
          <TextInput
            autohook
            type="password"
            name="secondPassword"
            label={`${T("New password again")} *`}
          />

          <div className="form-group form-group-submit">
            <Button
              variant="contained"
              color="primary"
              style={{
                cursor: "pointer",
              }}
              type="submit"
            >
              {T("Confirm new email")}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};
