import * as React from "react";

import {
  useGetEstablishmentsPageQuery,
  useInsertEstablishmentMutation,
  useUpdateEstablishmentMutation,
  useDeleteEstablishmentMutation,
} from "../../graphql";
import { EstablishmentSelect } from "./EstablishmentSelect";
import { EstablishmentAdd } from "./EstablishmentAdd";
import Typography from "@material-ui/core/Typography";
import { T } from "../../utils/translate";

export const Establishment: React.FC<{}> = () => {
  const {
    data: estData,
    refetch: estRefetch,
    loading: estLoading,
  } = useGetEstablishmentsPageQuery({
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  const globalRefresh = () => {
    estRefetch();
  };

  const [
    editEstablishment,
    // { error: errorEditEst, data: dataEditEst },
  ] = useUpdateEstablishmentMutation({
    errorPolicy: "all",
    onCompleted(answer) {
      if (!answer?.update_establishments_by_pk) {
        console.warn("Establishment not updated.");
        alert(T("Establishment not updated. Do you have a permission?"));
        return;
      }
      setTimeout(() => globalRefresh(), 300);
      console.log("establishments edited", answer);
    },
  });

  const [
    addEstablishment,
    // { error: errorAddEst, data: dataAddEst },
  ] = useInsertEstablishmentMutation({
    errorPolicy: "all",
    onCompleted(data) {
      console.log("Establishment inserted", data);
      setTimeout(() => globalRefresh(), 300);
      return data;
    },
  });

  const [
    removeEstablishment,
    // { error: errorRemoveEst, data: dataRemoveEst },
  ] = useDeleteEstablishmentMutation({
    onCompleted({ delete_establishments_by_pk }) {
      if (!delete_establishments_by_pk) {
        window.alert(T("Delete Establishment failed. Do you have permission?"));
        return;
      }
      estRefetch();
    },
  });

  if (estLoading) {
    return (
      <>
        <Typography variant="h2">{T("Establishments")}</Typography>
      </>
    );
  }

  return (
    <div>
      <EstablishmentSelect
        editEstablishment={editEstablishment}
        myCompanyEstablishments={estData}
        removeEstablishment={removeEstablishment}
        usersData={estData?.users}
        phone_numbersData={estData?.phone_numbers}
        phone_numbers_establishments={estData?.phone_numbers_establishments}
        establishments_users={estData?.establishments_users}
        globalRefresh={globalRefresh}
      />

      <EstablishmentAdd
        addEstablishment={addEstablishment}
        myCompanyEstablishments={estData}
        usersData={estData?.users}
        phone_numbersData={estData?.phone_numbers}
        phone_numbers_establishments={estData?.phone_numbers_establishments}
        establishments_users={estData?.establishments_users}
        globalRefresh={globalRefresh}
      />
    </div>
  );
};
