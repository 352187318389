import * as React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import {
  InputBaseProps,
  InputBaseComponentProps,
} from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import { useFormContext, UseFormMethods } from "react-hook-form";

import { Label } from "./Label";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #ced4dab8;",
    boxShadow: "0px 1px 2px rgba(36, 80, 141, 0.1)",
    overflow: "hidden",
    borderRadius: 3,
    backgroundColor: "#fff",
    padding: "0",
    fontSize: 15,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "& input": {
      padding: "0.6rem 0.8rem",
    },
    "&$focused": {
      boxShadow: "0px 1px 2px rgba(36, 80, 141, 0.3)",
    },
    "&:hover": {
      boxShadow: "0px 1px 4px rgba(36, 80, 141, 0.4)",
    },
  },
  multiline: {
    "& textarea": {
      padding: "0.6rem 0.8rem",
    },
  },
  disabled: {
    background: "#f9f9f9",
  },
  focused: {},
  error: {
    borderColor: "red",
    borderWidth: 1,
    "&:hover": {
      boxShadow: "0px 1px 6px rgba(255, 0, 0, 0.4)",
    },
    "&$focused": {
      boxShadow: "0px 1px 4px rgba(255, 0, 0, 0.3)",
    },
  },
}));

interface ControlledTextInputProps {
  onChange: (value: string) => void;
  value: string;
}

interface HookedTextInputProps
  extends Partial<Pick<UseFormMethods, "register" | "errors">> {
  name: string;
}

interface BaseTextInputProps {
  label?: string;
  type?: "text" | "email" | "number" | "password";
  variant?: "default" | "underlined" | "filled" | "outlined";
  name?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  textarea?: boolean;
  disabled?: boolean;
  autohook?: boolean;
  width?: number | string;
  rows?: number;
  InputProps?: Partial<InputBaseProps>;
  inputProps?: Partial<InputBaseComponentProps>;
  style?: any;
}

type TextInputProps = BaseTextInputProps &
  Partial<ControlledTextInputProps | HookedTextInputProps>;

const parseError: (data: any, name: string) => any = (data, name) => {
  const splitName = name.split(".");

  splitName.forEach((element) => {
    if (data) {
      data = data[element];
    }
  });

  return data;
};

export const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
  const {
    label,
    type = "text",
    variant = "default",
    placeholder,
    textarea,
    disabled,
    width = "100%",
    rows,
    InputProps,
    inputProps,
    style,
  } = props;
  let { error, helperText } = props;

  function getMuiVariant() {
    if (variant == "default" || variant == "underlined") {
      return "standard";
    } else {
      return variant;
    }
  }

  const textFieldProps: TextFieldProps = {
    variant: getMuiVariant(),
    InputProps: { ...InputProps },
    inputProps: { ...inputProps },
  };

  const classes = useStyles();
  if (variant == "default" && textFieldProps.variant == "standard") {
    textFieldProps.InputProps.classes = classes;
    textFieldProps.InputProps.disableUnderline = true;
  } else {
    textFieldProps.label = label;
  }

  function hookForm(register, errors) {
    textFieldProps.name = props.name;
    textFieldProps.inputRef = register;
    const hookError = parseError(errors, props.name);
    if (hookError) {
      error = true;
      helperText = hookError?.message;
    }
  }

  if ("register" in props) {
    // React Hook Form passed via argument
    hookForm(props.register, props.errors);
  } else if (props.autohook && props.name) {
    // React Hook Form automatic
    const { register: autoRegister, errors: autoErrors } = useFormContext();
    hookForm(autoRegister, autoErrors);
  } else if ("onChange" in props) {
    // Regular controlled input
    textFieldProps.onChange = (e) => props.onChange(e.target.value);
    textFieldProps.value = props.value;
  }

  textFieldProps.type = type;
  textFieldProps.placeholder = placeholder;
  textFieldProps.helperText = helperText;
  textFieldProps.error = error;
  textFieldProps.disabled = disabled;
  textFieldProps.fullWidth = true;
  if (textarea) {
    textFieldProps.multiline = textarea;
    textFieldProps.rows = rows;
  }

  return (
    <div style={{ marginBottom: "1rem", width, ...style }}>
      {label && variant == "default" && <Label>{label}</Label>}
      <TextField {...textFieldProps} />
    </div>
  );
};
