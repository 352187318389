import React from "react";
import { Checkbox } from "@material-ui/core";
import { useTable, useFilters, useSortBy, useRowSelect } from "react-table";
import { usePercentLayout } from "./usePercentLayout";
import { QueryResult } from "@apollo/client";
import { startOfDay, endOfDay } from "date-fns";

interface IDataTableQueryFormat {
  info?: { aggregate?: { totalCount?: number } };
  rows?: Array<any>;
}

interface IDataTableProps {
  /**
   * Apollo query hook
   */
  query: QueryResult<Partial<IDataTableQueryFormat>, Record<string, any>>;
  /**
   * Column definitions based on react-table interface
   */
  columns: any;
  /**
   * Enable paging for the table
   */
  pageSize?: number;
  /**
   * Default query filters
   */
  fixedFilters?: [Record<string, any>];
  /**
   * Should use client side filters
   */
  secondaryFilters?: boolean;
  /**
   * Table column width layout method
   */
  layout?: "percent";
  /**
   * Compact option for table
   */
  compact?: boolean;
  /**
   * Event when row clicked
   */
  onClickRow?: ({ row: any }) => void;
  /**
   * Selection mode
   */
  checkboxes?: boolean;
  /**
   * Sortable
   */
  sortable?: boolean;
}

export interface IDataTable {
  controller: IDataTableController;
}

export interface IDataTableController {
  loading: boolean;
  rows: any[];
  headerGroups: any[];
  prepareRow: (any) => any;
  // Props
  getTableProps: () => any;
  getRowProps: (any) => any;
  getCellProps: (any) => any;
  // Pagination
  pageSize: number;
  fullRowCount: number;
  pageIndex: number;
  setPageIndex: (index: number) => void;
  // Selection
  selectedFlatRows?: any[];
  toggleAllRowsSelected?: (selected: boolean) => void;
}

export const useDataTable: (DataTableProps) => IDataTable = ({
  columns,
  query,
  pageSize,
  fixedFilters = [],
  secondaryFilters = false,
  compact,
  onClickRow,
  checkboxes,
  sortable = false,
}) => {
  const [pageIndex, setPageIndex] = React.useState(0);

  // Fetch function
  const _refetch = query.refetch;
  const fetchData = React.useCallback(
    ({ pageIndex, pageSize, filters }) => {
      const variables: any = {
        where: {
          _and: [],
        },
      };
      // Paging
      if (pageSize) {
        variables.offset = pageIndex * pageSize;
        variables.limit = pageSize;
      }
      // Applying filters
      let selectors = [...filters];
      selectors = selectors.flatMap((filter) => {
        const column = columns.find(
          (x) => filter.id == x.id || filter.id == x.accessor
        );
        if (
          (column?.accessor == filter.id || column?.id == filter.id) &&
          column.filterSelector
        ) {
          return column.filterSelector(filter.value);
        } else if (column?.accessor == filter.id || column?.id == filter.id) {
          const accessor =
            typeof column?.accessor == "string" ? column?.accessor : column?.id;
          const querySelector = {};
          if (column.filter == "exact") {
            querySelector[accessor] = { _eq: filter.value };
          } else if (column.filter == "date") {
            querySelector[accessor] = {
              _gt: startOfDay(new Date(filter.value)).toISOString(),
              _lt: endOfDay(new Date(filter.value)).toISOString(),
            };
          } else {
            querySelector[accessor] = {
              _ilike: "%" + filter.value + "%",
            };
          }
          return [{ ...querySelector }];
        }
        return [];
      });
      variables.where["_and"] = [...selectors, ...fixedFilters];
      // Fetch
      _refetch(variables);
    },
    [_refetch, columns]
  );

  // Filtering
  const filterTypes = React.useMemo(() => {
    return {
      greaterThan: (rows, columnIds, filterValue) => {
        return rows.filter((row) => {
          return columnIds.some((id) => {
            const rowValue = row.values[id];
            return Number(rowValue) >= Number(filterValue);
          });
        });
      },
    };
  }, []);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: () => null,
    }),
    []
  );

  // Rows
  const loading = query?.loading || false;
  const data = React.useMemo(() => query?.data?.rows || [], [
    query?.data?.rows,
  ]);
  const fullRowCount =
    query?.data?.info?.aggregate?.totalCount ||
    query?.data?.info?.totalCount ||
    0;

  const otherHooks = [];
  if (checkboxes) {
    otherHooks.push(useRowSelect);
    otherHooks.push((hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} color="primary" />
          ),
          Cell: ({ row }) => (
            <Checkbox {...row.getToggleRowSelectedProps()} color="primary" />
          ),
          width: 1,
        },
        ...columns,
      ]);
    });
  }

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    state: { filters },
    selectedFlatRows,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      defaultColumn,
      autoResetFilters: false,
      manualFilters: !secondaryFilters,
      autoResetSortBy: false,
      disableSortBy: !sortable,
    },
    useFilters,
    useSortBy,
    usePercentLayout,
    ...otherHooks
  );

  // Refetching
  React.useEffect(() => {
    fetchData({ pageIndex, pageSize, filters });
  }, [fetchData, pageIndex, pageSize]);
  React.useEffect(() => {
    if (pageIndex != 0) {
      setPageIndex(0);
    } else {
      fetchData({ pageIndex, pageSize, filters });
    }
  }, [filters]);

  // UI tweaks
  function getRowProps({ row }) {
    if (onClickRow) {
      return {
        onClick: () => onClickRow({ row }),
        style: { cursor: "pointer" },
      };
    }
    return {};
  }
  function getCellProps({ cell }) {
    if (cell.column.id == "selection") {
      return {
        size: "small",
        onClick: (e) => e.stopPropagation(),
      };
    }
    if (compact) {
      return {
        size: "small",
      };
    }
  }

  return {
    controller: {
      getTableProps,
      getRowProps,
      getCellProps,

      headerGroups,
      loading,
      rows,
      prepareRow,

      pageSize,
      pageIndex,
      setPageIndex,
      fullRowCount,

      selectedFlatRows,
      toggleAllRowsSelected,
    },
  };
};
