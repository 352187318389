import * as React from "react";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Typography, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import { HeaderWithRefresh } from "../style/styledComponents/components";

export const EditModal: React.FC<{
  title?: string;
  editObject?: any;
  editComponent: Function;
  onClose: Function;
  width?: number;
}> = ({ title, editObject, editComponent, onClose, width = 1000 }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(editObject != null);
  React.useEffect(() => {
    if (
      (drawerOpen && editObject == null) ||
      (!drawerOpen && editObject != null)
    ) {
      setDrawerOpen(editObject != null);
    }
  }, [editObject]);
  const toggleDrawer = (event: any, open: boolean, id?: string) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
    if (!open) {
      setTimeout(() => {
        onClose();
      }, 500);
    }
  };
  return (
    <SwipeableDrawer
      anchor={"right"}
      open={drawerOpen}
      onClose={(e) => {
        toggleDrawer(e, false);
      }}
      onOpen={(e) => ({})}
      PaperProps={{
        style: { width: "min(100%, " + width + "px)", padding: 20 },
      }}
    >
      {editObject && (
        <>
          <HeaderWithRefresh>
            <Typography variant="h5" gutterBottom style={{ paddingTop: 10 }}>
              {title}
            </Typography>

            <IconButton onClick={() => toggleDrawer(null, false)}>
              <CancelIcon />
            </IconButton>
          </HeaderWithRefresh>
          {editComponent(editObject, () => toggleDrawer(null, false))}
        </>
      )}
    </SwipeableDrawer>
  );
};

export default EditModal;
