import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";

import {
  useInsertContactsCustomFieldMutation,
  useDeleteCustomFieldMutation,
} from "src/graphql";
import { Select } from "src/sharedComponents/forms";
import { T } from "src/utils/translate";

export const CustomFieldsModal: React.FC<{
  contactsCustomFields: any;
}> = ({ contactsCustomFields }) => {
  const [newFieldName, setNewFieldName] = useState("");
  const history = useHistory();
  const [insertCustomField] = useInsertContactsCustomFieldMutation({
    onCompleted: () => {
      contactsCustomFields.refetch();
    },
  });
  const [deleteCustomField] = useDeleteCustomFieldMutation({
    onCompleted: () => {
      contactsCustomFields.refetch();
    },
  });
  const handleClose = () => {
    history.push("/contacts");
  };
  return (
    <Dialog open={true} onClose={handleClose} maxWidth="lg">
      <DialogTitle>{T("Custom fields")}</DialogTitle>
      <DialogContent>
        {contactsCustomFields?.data?.rows?.map(({ custom_field }) => (
          <div key={custom_field.id}>
            {custom_field.name}
            <IconButton
              onClick={() =>
                deleteCustomField({ variables: { id: custom_field.id } })
              }
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Input
            placeholder={T("New item")}
            value={newFieldName}
            onChange={(e) => setNewFieldName(e.target.value)}
            multiline={true}
            fullWidth={true}
          />
          <Button
            onClick={() => {
              insertCustomField({
                variables: { type: "text", name: newFieldName },
              });
              setNewFieldName("");
            }}
          >
            {T("Add")}
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          {T("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
