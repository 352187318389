import * as React from "react";

import { Company } from "../Company";
import { Establishment } from "../Establishment";
import { User } from "../User";

const Spacer: React.FC<{}> = () => {
  return (
    <div
      style={{
        height: 60,
      }}
    />
  );
};

export const MainPage: React.FC<{}> = () => {
  return (
    <div>
      <Company />
      <Spacer />
      <Establishment />
      <Spacer />
      <User />
    </div>
  );
};
