import * as React from "react";

import {
  Select,
  MenuItem,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Container,
  Button,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

import { authenticationService } from "../../services/authentication";
import { T } from "../../utils/translate";
import { TextInput } from "../../sharedComponents/forms";
import { MyTableCellBorder } from "../../style/styledComponents/table";

import { ADMIN_ROLE } from "src/utils/constants";

import {
  useGetUsersQuery,
  useGetRolesQuery,
  useUpdateUserRoleMutation,
  useInsertRoleMutation,
  useDeleteRoleMutation,
  Roles_Insert_Input,
} from "../../graphql";

const MY_ID = authenticationService.decodeToken()?.id;

const PERMISSIONS = [
  { title: T("Can manage companies"), key: "can_manage_company" },
  { title: T("Can manage users"), key: "can_manage_users" },
  { title: T("Can use CentrumSMS"), key: "can_access_centrumsms" },
];

export const Role: React.FC = () => {
  const { data: gcData, refetch: refetchMyCompanyRoles } = useGetRolesQuery();
  const myCompanyRoles = gcData || { roles: [] };

  const [addRole] = useInsertRoleMutation({
    onCompleted() {
      refetchMyCompanyRoles();
    },
    onError() {
      alert(T("Saving data failed."));
    },
  });
  const [updateMyRole] = useUpdateUserRoleMutation({});
  const [removeRole] = useDeleteRoleMutation({
    onCompleted() {
      refetchMyCompanyRoles();
    },
    onError() {
      alert(T("Saving data failed."));
    },
  });
  const {
    data: usersOfMyCompany,
    refetch: refetchUsersOfMyCompany,
  } = useGetUsersQuery();

  const removeRoleAsync = async (id: string) => {
    try {
      await removeRole({ variables: { id } });
    } catch (e) {
      console.warn(e);
      alert(
        "Can not remove this role. Verify noone else is assigned to this role."
      );
    }
  };

  const defaultValues = { name: "" };
  PERMISSIONS.forEach((it) => {
    defaultValues[it.key] = "true";
  });
  const { register, handleSubmit, control, reset } = useForm<
    Roles_Insert_Input
  >({
    defaultValues,
  });

  const addRoleSubmit = async (data: Roles_Insert_Input) => {
    console.log(data);
    if (data.name.length === 0) {
      alert(T("Name cannot be empty!"));
      return;
    }
    if (myCompanyRoles.roles.find((mcr) => mcr.name === data.name)) {
      alert(T("Role name already exists!"));
      return;
    }
    addRole({
      variables: {
        object: data,
      },
    });
    reset();
  };

  const assignRole = async function (e, id) {
    e.preventDefault();
    const foundUsers = usersOfMyCompany.users.filter(
      (mcr) => mcr.role_id == ADMIN_ROLE
    );
    if (foundUsers.length == 1 && id == foundUsers[0].id) {
      alert(T("Must be at least one admin"));
      return;
    }
    if (
      id === MY_ID &&
      !window.confirm(T("Are you sure you want to change your own role?"))
    ) {
      return;
    }
    await updateMyRole({
      variables: {
        r: e.target.value,
        id,
      },
    });

    refetchUsersOfMyCompany();
  };

  return (
    <div>
      <Typography variant="h2">{T("Roles")}</Typography>
      <Typography
        variant="subtitle1"
        style={{ marginBottom: 6, marginTop: -8 }}
      >
        {T("Set up different access levels for your employees")}
      </Typography>

      <TableContainer component={Paper} style={{ marginBottom: "1rem" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <MyTableCellBorder>{T("Name")}</MyTableCellBorder>
              {PERMISSIONS.map((it) => (
                <MyTableCellBorder key={it.key}>{it.title}</MyTableCellBorder>
              ))}
              <MyTableCellBorder></MyTableCellBorder>
            </TableRow>
          </TableHead>
          <TableBody>
            {myCompanyRoles?.roles.map(
              ({ name, id, company_id, ...permissions }) => {
                return (
                  <TableRow key={id}>
                    <MyTableCellBorder>{name}</MyTableCellBorder>
                    {PERMISSIONS.map((it) => (
                      <MyTableCellBorder key={it.key}>
                        {T(permissions[it.key].toString())}
                      </MyTableCellBorder>
                    ))}
                    <MyTableCellBorder padding="none" align="right">
                      <Button
                        onClick={() => removeRoleAsync(id)}
                        disabled={!company_id}
                        style={{ margin: 0 }}
                      >
                        {T("Remove")}
                      </Button>
                    </MyTableCellBorder>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h5" gutterBottom>
        {T("Add new role")}
      </Typography>
      <form onSubmit={handleSubmit(addRoleSubmit)}>
        <TableContainer component={Paper} style={{ marginBottom: "1rem" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <MyTableCellBorder>{T("Name")}</MyTableCellBorder>
                {PERMISSIONS.map((it) => (
                  <MyTableCellBorder key={it.key}>{it.title}</MyTableCellBorder>
                ))}
                <MyTableCellBorder></MyTableCellBorder>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <MyTableCellBorder valign="bottom">
                  <TextInput
                    variant="underlined"
                    name="name"
                    register={register}
                    style={{ marginTop: "0.5rem", marginBottom: 0 }}
                  />
                </MyTableCellBorder>
                {PERMISSIONS.map((it) => (
                  <MyTableCellBorder key={it.key}>
                    <Controller
                      control={control}
                      name={it.key}
                      as={
                        <Select>
                          <MenuItem value={"true"}>{T("true")}</MenuItem>
                          <MenuItem value={"false"}>{T("false")}</MenuItem>
                        </Select>
                      }
                    />
                  </MyTableCellBorder>
                ))}
                <MyTableCellBorder>
                  <Button variant="contained" color="primary" type="submit">
                    {T("Create role")}
                  </Button>
                </MyTableCellBorder>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </form>

      <Typography variant="h5" gutterBottom>
        {T("Assign roles to users of my company:")}
      </Typography>
      <TableContainer component={Paper} style={{ marginBottom: "1rem" }}>
        <Table aria-label="simple table">
          <TableBody>
            {usersOfMyCompany?.users?.map(
              ({ first_name, last_name, email, role_id, id }) => {
                return (
                  <TableRow key={`${id}-${role_id}`}>
                    <MyTableCellBorder
                      style={{ width: "60%" }}
                    >{`${first_name} ${last_name} (${email})`}</MyTableCellBorder>
                    <MyTableCellBorder>
                      <Select
                        value={role_id}
                        onChange={(e) => assignRole(e, id)}
                      >
                        {myCompanyRoles.roles.map(
                          ({ id, name, can_manage_users }) => {
                            return (
                              <MenuItem
                                value={id}
                                title={
                                  T("Can manage users") +
                                  ": " +
                                  T(can_manage_users.toString())
                                }
                                key={id}
                              >
                                {name}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </MyTableCellBorder>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
