import React, { useState, useEffect } from "react";

import { useDataTable, TextFilter } from "src/sharedComponents/table";
import { IDataTable } from "src/sharedComponents/table/DataTableController";
import { T } from "src/utils/translate";

interface IContactsTableOptions {
  contactsQuery: any;
  setEditing?: (any) => void;
  checkboxes?: boolean;
  compact?: boolean;
  customFields: any[];
  shoptet: boolean;
  fixedFilters?: Record<string, any>;
}

export const useContactsTable: (IContactsTableOptions) => IDataTable = ({
  contactsQuery,
  setEditing,
  checkboxes,
  compact,
  customFields,
  shoptet,
  fixedFilters,
}) => {
  const customFieldColumns = () => {
    return customFields.map((it) => ({
      Header: it.name,
      id: it.key,
      accessor: (row) => {
        return row?.custom_values[it.key] || "";
      },
      Filter: TextFilter,
      filter: "text",
      filterSelector: (value) => [{ custom_values: { _has_key: it.key } }],
      width: 15,
    }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: T("First name"),
        accessor: "first_name",
        Filter: TextFilter,
        filter: "text",
        width: 15,
      },
      {
        Header: T("Last name"),
        accessor: "last_name",
        Filter: TextFilter,
        filter: "text",
        width: 15,
      },
      {
        Header: T("Phone number"),
        accessor: "phone_number",
        Filter: TextFilter,
        filter: "text",
        width: 15,
      },
      {
        Header: T("Email"),
        accessor: "email",
        Filter: TextFilter,
        filter: "text",
        width: 15,
      },
      {
        Header: T("Address"),
        id: "address",
        accessor: (row) => {
          return [
            row?.address_street,
            row?.address_city,
            row?.address_postcode,
            row?.address_country,
          ]
            .filter((it) => !!it)
            .join(", ");
        },
        Filter: TextFilter,
        filter: "text",
        width: 15,
        filterSelector: (value) => [{}],
      },
      ...(shoptet
        ? [
            {
              Header: T("Money spent"),
              id: "money_spent",
              accessor: (row) => {
                return row?.custom_values?.total_value_of_orders;
              },
              Filter: TextFilter,
              Cell: ({ row }) => {
                const total_value_of_orders =
                  row?.original?.custom_values?.total_value_of_orders;
                const currencyCode =
                  row?.original?.custom_values?.eshop_currency;
                if (total_value_of_orders && currencyCode) {
                  const price = new Intl.NumberFormat("cs-cz", {
                    style: "currency",
                    currencyDisplay: "narrowSymbol",
                    currency: currencyCode,
                  }).format(total_value_of_orders);
                  return price;
                } else {
                  return "";
                }
              },
              filter: "greaterThan",
              width: 15,
              filterSelector: (value) => [{}],
            },
          ]
        : []),
      ...customFieldColumns(),
      /*{
        Header: "",
        id: "options",
        Cell: ({ row }) => (
          <>
            <IconButton>
              <RefreshIcon />
            </IconButton>
          </>
        ),
      },*/
    ],
    [customFields, shoptet]
  );

  return useDataTable({
    columns,
    query: contactsQuery,
    onClickRow: ({ row }) => {
      if (setEditing) setEditing(row.original);
    },
    checkboxes,
    compact,
    sortable: true,
    secondaryFilters: true,
    fixedFilters,
  });
};
