import axios from 'axios';
import { parseAxiosError } from './axiosErrorParser';
import { authenticationService } from '../authentication';

class AjaxService {
    public get<T>(url: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            axios
                .get<T>(url)
                .then((data) => {
                    resolve(data.data);
                })
                .catch((e) => {
                    const axiosError = parseAxiosError(e);
                    reject(axiosError);
                });
        });
    }

    public post<T>(url: string, data: any): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            axios
                .post<T>(url, data, {
                    headers: authenticationService.getAuthorizationRequestHeader(),
                })
                .then((data) => {
                    resolve(data.data);
                })
                .catch((e) => {
                    const axiosError = parseAxiosError(e);
                    reject(axiosError);
                });
        });
    }
}

export const ajaxService = new AjaxService();
