import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import RedoIcon from "@material-ui/icons/Redo";
import UndoIcon from "@material-ui/icons/Undo";
import { T } from "../../utils/translate";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: "none",
    textAlign: "center",
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: -90,
    label: T("rotate_left"),
  },
  { value: 0, label: T("rotate_original") },
  {
    value: 90,
    label: T("rotate_right"),
  },
];

function valuetext(value) {
  return `${value}°`;
}

export const CropModal = ({ src, cropper, image, setImage, sendToServer }) => {
  const [rotateDegree, setRotateDegree] = useState(0);
  const classes = useStyles();

  const handleRotateChange = (v) => {
    setRotateDegree(v);
    cropper.current.rotate(v - rotateDegree);
    // const dataURL = cropper.current.getCroppedCanvas().toDataURL();
    // setImage((image) => ({ ...image, preview: dataURL }));
  };

  const onCrop = () => {
    /*const dataURL = cropper?.current?.getCroppedCanvas()?.toDataURL();
    if (dataURL) {
      setImage((image) => ({ ...image, preview: dataURL }));
    */
  };

  const handleSubmit = () => {
    const dataURL = cropper?.current?.getCroppedCanvas()?.toDataURL();
    if (dataURL) {
      setImage((image) => ({ ...image, preview: dataURL }));
    }
    sendToServer();
  };

  const rotateRight = () => {
    handleRotateChange(Math.floor(rotateDegree / 90) * 90 + 90);
  };
  const rotateLeft = () => {
    handleRotateChange(Math.ceil(rotateDegree / 90) * 90 - 90);
  };

  return (
    <div className={classes.paper}>
      <div className="Cropper">
        <Cropper
          ref={cropper}
          src={src}
          style={{
            width: Math.min(600, window.innerWidth - 80),
            height: Math.min(600, window.innerWidth - 80),
          }}
          rotatable
          responsive
          viewMode={2}
          aspectRatio={1}
          autoCropArea={1}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton onClick={rotateLeft}>
          <UndoIcon />
        </IconButton>
        <Slider
          defaultValue={0}
          value={rotateDegree}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider-custom"
          step={1}
          valueLabelDisplay="auto"
          marks={marks}
          min={-180}
          max={180}
          onChange={(e, v) => {
            handleRotateChange(v);
          }}
          style={{ width: "min(350px, 100%)" }}
        />
        <IconButton onClick={rotateRight}>
          <RedoIcon />
        </IconButton>
      </div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ margin: 15 }}
      >
        {T("Upload cropped image")}
      </Button>
    </div>
  );
};
