import * as React from "react";
import { T } from "../../utils/translate";
import Divider from "@material-ui/core/Divider";

import { TransactionsSelect } from "./TransactionsSelect";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { Form } from "../../sharedComponents/Form";
import {
  Wrapper,
  MyTableCell,
  MyNBTableCell,
} from "../../style/styledComponents/table";
import { TextInput } from "../../sharedComponents/forms";
import { yup } from "../../utils/yup";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  table: {
    // minWidth: 600,
  },
  select: {
    marginLeft: "1rem",
    minWidth: "10rem",
    "& .MuiSelect-select": {
      fontSize: 14,
      paddingLeft: 15,
    },
  },
  li: {
    fontSize: 14,
  },
});

const DEFAULT_VALUES = {
  amount: "",
  description: "",
};
const VALIDATION_SCHEMA = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required")
    .typeError("Amount must be a number")
    .positive("Amount must be greater than zero"),
  description: yup.string(),
});

export const Withdraw: React.FC<{}> = () => {
  const classes = useStyles();

  const [regionCode, setRegionCode] = React.useState("");

  const handleRegionCode = (e) => {
    console.log("region ", e.target.value);
    setRegionCode(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom>
        Withdraw money
      </Typography>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3">Bank transfer withdrawal</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          <Form
            defaultValues={DEFAULT_VALUES}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(data: { amount: string; description: string }) => {
              console.log("submitted data", data);
              if (!parseInt(data.amount, 10)) {
                console.warn("Amount must be number!");
                return;
              }
              //   createPayment({
              //     variables: {
              //       amount: parseInt(data.amount, 10),
              //       description: data.description,
              //       currency_code: "CZK",
              //     },
              //   });
              //to delete input values
              window.alert("Bank transfer sent.");
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <MyTableCell>
                    <Typography variant="subtitle1" gutterBottom>
                      Withdraw money to your bank account
                    </Typography>
                  </MyTableCell>
                  <MyTableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ float: "right" }}
                    >
                      Confirm
                    </Button>
                  </MyTableCell>
                </TableRow>

                <TableRow>
                  <MyNBTableCell>
                    Bank account:
                    <Select
                      labelId="region_code"
                      id="region_code"
                      value={regionCode}
                      onChange={handleRegionCode}
                      label="Age"
                      className={classes.select}
                    >
                      <MenuItem value={"CZ"} className={classes.li}>
                        BANK ACC #1
                      </MenuItem>
                      <MenuItem value={"CZ"} className={classes.li}>
                        BANK ACC #2
                      </MenuItem>
                    </Select>
                  </MyNBTableCell>
                </TableRow>

                <TableRow>
                  <MyNBTableCell>
                    <TextInput
                      autohook
                      name="amount"
                      placeholder={T("Amount")}
                      label={T("Amount")}
                    />
                  </MyNBTableCell>
                </TableRow>

                <TableRow>
                  <MyNBTableCell>
                    <TextInput
                      autohook
                      textarea
                      name="description"
                      placeholder={T("Description")}
                      label={T("Description")}
                    />
                  </MyNBTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Form>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
