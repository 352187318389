import * as React from "react";
import { useDebouncedCallback } from "use-debounce";
import { useMutation } from "@apollo/client";
import {
  useUpdateEstablishmentPhoneNumbersMutation,
  useUpdateEstablishmentUsersMutation,
} from "../../graphql";

import Button from "@material-ui/core/Button";
import { AddEditEstablishment, EstUser, GeolocationState } from "./types";
import { yup } from "../../utils/yup";

import { TextInput } from "../../sharedComponents/forms";
import { T } from "../../utils/translate";
import GeoMap from "../../sharedComponents/GeoMap";
import { UserType, UserEstablishmentType } from "../User/types";
import { EstablishmentUser } from "./EstablishmentUser";
import { EstablishmentPhoneNumber } from "./EstabishmentPhoneNumber";
import {
  Phone_numberType,
  Phone_numberEstablishmentType,
} from "../PhoneNumber/types";
import { CircularProgress, Paper, Grid, Hidden } from "@material-ui/core";
import { geocodingService } from "../../services/geocoding";
import { ImgMaster } from "../../sharedComponents/CropUploadComponent/ImgMaster";
import cloneDeep from "lodash/cloneDeep";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

interface EditTableProps {
  addEditEstablishment: any;
  editingId?: string;
  closeForm: Function;
  data: AddEditEstablishment;
  removeEstablishment?: Function;
  operation: string;
  usersData?: UserType[];
  phone_numbersData: Phone_numberType[];
  globalRefresh?: Function;
  phone_numbers_establishments: Phone_numberEstablishmentType[];
  establishments_users?: EstUser[];
}

const FORM_VALIDATION = yup.object().shape({
  name: yup.string().required(),
  address_city: yup.string().required(),
  address_postcode: yup.string().required(),
  address_street: yup.string().required(),
  phone_number: yup.string(),
  website: yup
    .string()
    .matches(
      /^(^$|(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+)$/,
      T("Enter correct url!")
    ),
  email: yup.string().email(),
  facebook: yup
    .string()
    .matches(
      /^(^$|https?:\/\/(www\.)?facebook\.com\/.*$)/,
      T("Must be Facebook link")
    ),
  instagram: yup
    .string()
    .matches(
      /^(^$|https?:\/\/(www\.)?instagram\.com\/.*$)/,
      T("Must be Instagram link")
    ),
});

export const EditTable: React.FC<EditTableProps> = ({
  addEditEstablishment,
  editingId,
  closeForm,
  data,
  removeEstablishment,
  operation,
  usersData,
  phone_numbersData,
  globalRefresh,
  phone_numbers_establishments,
  establishments_users,
}) => {
  const [selectedUsers, setSelectedUsers] = React.useState([]);
  const [phone_numbersToSend, setPhone_numbersToSend] = React.useState([]);
  const [photoUrls, setPhotoUrls] = React.useState([]);
  const [geolocation, setGeolocation] = React.useState<GeolocationState>({
    loading: true,
  });

  React.useEffect(() => {
    try {
      const parsed = JSON.parse(data.photo_url);
      if (Array.isArray(parsed)) {
        setPhotoUrls(JSON.parse(data.photo_url));
      }
    } catch (e) {
      //   console.log("photo url parse try error");
    }
  }, [data.photo_url]);

  const DEFAULT_VALUES = {
    name: data.name,
    address_city: data.address_city,
    address_postcode: data.address_postcode,
    address_street: data.address_street,
    phone_number: data.phone_number,
    website: data.website,
    email: data.email,
    facebook: data.facebook,
    instagram: data.instagram,
  };

  const formMethods = useForm({
    resolver: yupResolver(FORM_VALIDATION),
    defaultValues: DEFAULT_VALUES,
    mode: "onBlur",
  });

  const [
    deleteAndInsertPhone_numbersEstablishmentsByEstablishment,
    // { error: errorPE, data: dataPE },
  ] = useUpdateEstablishmentPhoneNumbersMutation({
    onCompleted(answer) {
      globalRefresh();
    },
  });

  const [
    deleteAndInsertUsersEstablishments,
    // { error: errorUI, data: dataUI },
  ] = useUpdateEstablishmentUsersMutation({
    onCompleted(answer) {
      // setEdit(false);
      // companyRefetch();
      //   userEstRefetch();
      globalRefresh();
    },
  });

  const newAddress = formMethods.watch([
    "address_street",
    "address_city",
    "address_postcode",
  ]);
  const [addressLookupCallback] = useDebouncedCallback(
    (value: {
      address_street: string;
      address_city: string;
      address_postcode: string;
    }) => {
      if (
        !value.address_street ||
        !value.address_city ||
        !value.address_postcode
      ) {
        setGeolocation({ incomplete: true });
        return;
      }
      setGeolocation({ loading: true });
      geocodingService
        .searchAddress(
          value.address_street +
            ", " +
            value.address_postcode.replace(" ", "") +
            " " +
            value.address_city
        )
        .then((result) => {
          console.log(result);
          setGeolocation({
            success: true,
            lat: result.lat,
            lng: result.lng,
          });
        })
        .catch((error) => {
          // Retry without postcode
          console.log("Retrying");
          geocodingService
            .searchAddress(value.address_street + ", " + value.address_city)
            .then((result) => {
              console.log(result);
              setGeolocation({
                success: true,
                lat: result.lat,
                lng: result.lng,
              });
            })
            .catch((error) => {
              setGeolocation({ error: true });
            });
        });
    },
    400
  );
  React.useEffect(() => addressLookupCallback(newAddress), [
    JSON.stringify(newAddress),
  ]);

  const submitForm = async (data) => {
    console.log("submitForm", data);
    try {
      const addEditResult = await addEditEstablishment({
        variables: {
          id: editingId,
          name: data.name,
          address_city: data.address_city,
          address_postcode: data.address_postcode,
          address_street: data.address_street,
          website: data.website,
          email: data.email,
          facebook: data.facebook,
          instagram: data.instagram,
          photo_url: JSON.stringify(photoUrls),
        },
      });
      console.log("--------selectedUsers", selectedUsers);

      if (!editingId) {
        editingId = addEditResult.data.insert_establishments_one.id;
      }

      if (editingId) {
        //for edit, I have editingId (of the establishtment) so I can do this; but not for add
        //saving users_establishments separately
        const userObjects = selectedUsers.map((se) => ({
          establishment_id: editingId,
          user_id: se.id,
        }));

        console.log("selectedUsers", selectedUsers);
        console.log("INSERTING selectedEst", userObjects, typeof userObjects);

        deleteAndInsertUsersEstablishments({
          variables: {
            objects: userObjects,
            establishment_id: editingId,
          },
        });

        //saving phone_numbers_establishments separately
        const phone_numberObjects = phone_numbersToSend.map((pn) => ({
          establishment_id: editingId,
          phone_number_id: pn.id,
        }));

        console.log("phone_numberObjects", phone_numberObjects);

        deleteAndInsertPhone_numbersEstablishmentsByEstablishment({
          variables: {
            objects: phone_numberObjects,
            establishment_id: editingId,
          },
        });
      }
      if (operation == "add") {
        formMethods.reset();
      }

      if (closeForm) {
        closeForm();
      }
    } catch (e) {
      if (closeForm) {
        closeForm();
      }
      console.warn("Adding / editing establishment failed.", e);
      alert(T("Saving data failed."));
    }
  };

  const onFileChange = (photo) => {
    const photos = cloneDeep(photoUrls);
    photos.push(photo);
    setPhotoUrls(photos);
  };
  const onFileRemove = (photo) => {
    const photos = cloneDeep(photoUrls).filter((x) => x !== photo);
    setPhotoUrls(photos);
  };

  const deleteHandler = (e) => {
    if (
      !window.confirm(T("Are you sure you want to delete this establishment?"))
    ) {
      return;
    }
    if (!editingId) {
      return;
    }
    removeEstablishment({
      variables: { id: editingId },
    });
    if (closeForm) {
      closeForm();
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(submitForm)}>
        <Grid container>
          <Grid item xs={12} md={4} style={{ padding: "0 8px" }}>
            <TextInput autohook name="name" label={T("Name")} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} style={{ padding: "0 8px" }}>
            <TextInput autohook name="address_street" label={T("Street")} />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0 8px" }}>
            <TextInput autohook name="address_city" label={T("City")} />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0 8px" }}>
            <TextInput autohook name="address_postcode" label={T("Postcode")} />
          </Grid>
        </Grid>
        {!geolocation.incomplete && (
          <Paper
            style={{
              ...{
                width: "calc(100% - 2em)",
                height: "250px",
                borderRadius: "4px",
                marginBottom: "2em",
                marginLeft: "8px",
              },
              ...(!geolocation.success
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }
                : {}),
            }}
          >
            {geolocation.loading && <CircularProgress />}
            {geolocation.error && (
              <div>
                {T("Address not found, make sure it is entered correctly")}
              </div>
            )}
            {geolocation.success && (
              <GeoMap
                position={{ lat: geolocation.lat, lng: geolocation.lng }}
              />
            )}
          </Paper>
        )}
        <Grid container>
          <Grid item xs={12} md={4} style={{ padding: "0 8px" }}>
            <TextInput autohook name="website" label={T("Website")} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} style={{ padding: "0 8px" }}>
            <TextInput autohook name="email" label={T("Email")} />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0 8px" }}>
            <TextInput autohook name="facebook" label={T("Facebook")} />
          </Grid>
          <Grid item xs={12} md={4} style={{ padding: "0 8px" }}>
            <TextInput autohook name="instagram" label={T("Instagram")} />
          </Grid>
        </Grid>

        <div style={{ padding: "0 8px", marginBottom: 30 }}>
          <EstablishmentPhoneNumber
            allPhone_numbers={phone_numbersData?.map((pn) => ({
              name: `${pn.name} (${pn.phone_number})`,
              id: pn.id,
            }))}
            allEstablishmentsPhone_numbers={phone_numbers_establishments}
            setPhone_numbersToSend={setPhone_numbersToSend}
            editingId={editingId}
            globalRefresh={globalRefresh}
            usedNumbers={
              operation === "edit"
                ? phone_numbers_establishments
                    .filter((pne) => pne.establishment?.id !== editingId)
                    .map((pne) => pne.phone_number?.id)
                : phone_numbers_establishments?.map(
                    (pne) => pne.phone_number?.id
                  )
            }
          />

          <EstablishmentUser
            allUsers={usersData?.map((u) => ({
              name: `${u.first_name} ${u.last_name} (${u.email})`,
              id: u.id,
            }))}
            allEstablishmentsUsers={establishments_users}
            setUsersToSend={setSelectedUsers}
            editingId={editingId}
          />

          <ImgMaster
            operation={operation}
            noCrop={true}
            onFileChange={onFileChange}
            onFileRemove={onFileRemove}
            photoUrls={photoUrls}
            titleText={T("Add images to your establishment")}
          />
        </div>

        <Button size="small" variant="contained" color="primary" type="submit">
          {operation === "add" ? T("Add") : T("Save")}
        </Button>
        {editingId && (
          <Button size="small" onClick={() => closeForm()}>
            {T("Cancel")}
          </Button>
        )}
        {editingId && (
          <Button
            size="small"
            // @ts-ignore
            variant="delete"
            style={{ float: "right" }}
            onClick={deleteHandler}
          >
            {T("Delete")}
          </Button>
        )}
      </form>
    </FormProvider>
  );
};
