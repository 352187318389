import React from "react";

import { Typography, Button } from "@material-ui/core";

import { TextInput } from "src/sharedComponents/forms";
import { Form } from "src/sharedComponents/Form";
import { T } from "src/utils/translate";
import { yup } from "src/utils/yup";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { authenticationService } from "src/services/authentication";

const FORM_VALIDATION = yup.object().shape({
  title_after_name: yup.string(),
  first_name: yup.string(),
  last_name: yup.string(),
  title_before_name: yup.string(),
  phone_number: yup
    .string()
    .test("phone-number", T("Phone number is not valid"), (value) => {
      const phoneNumber = parsePhoneNumberFromString(
        value,
        authenticationService.getCountryCode()
      );
      return phoneNumber && phoneNumber.isValid();
    }),
  email: yup.string().email(),
  address_street: yup.string(),
  address_city: yup.string(),
  address_postcode: yup.string(),
  address_country: yup.string(),
});

const FORM_DEFAULT: ContactFormModel = {
  title_after_name: "",
  first_name: "",
  last_name: "",
  title_before_name: "",
  phone_number: "",
  email: "",
  address_street: "",
  address_city: "",
  address_postcode: "",
  address_country: authenticationService.getCountryCode(),
  custom_values: [],
};

interface ContactFormModel {
  title_after_name: string;
  first_name: string;
  last_name: string;
  title_before_name: string;
  phone_number: string;
  email: string;
  address_street: string;
  address_city: string;
  address_postcode: string;
  address_country: string;
  custom_values: any[];
}

interface EditTableProps {
  editObject: any;
  customFields: any[];
  closeForm: () => void;
  insertMutation: (param: any) => Promise<any>;
  updateMutation: (param: any) => Promise<any>;
  deleteMutation: (param: { variables: { id: string } }) => Promise<any>;
}

export const EditTable: React.FC<EditTableProps> = ({
  editObject,
  customFields,
  closeForm,
  insertMutation,
  updateMutation,
  deleteMutation,
}) => {
  const onSubmit = (data, reset) => {
    console.log(data);
    // Format phone number
    const processedPhoneNumber = parsePhoneNumberFromString(
      data.phone_number,
      authenticationService.getCountryCode()
    );
    if (!processedPhoneNumber || !processedPhoneNumber.isValid()) {
      alert(T("Phone number is not valid"));
      return;
    }
    data.phone_number = processedPhoneNumber.number;

    if (editObject?.id) {
      updateMutation({
        variables: { id: editObject?.id, contact: data },
      });
    } else {
      insertMutation({
        variables: { contact: data },
      });
    }
    closeForm();
  };

  const defaultValues = React.useMemo(() => {
    const obj = { ...FORM_DEFAULT };
    Object.keys(editObject).forEach((key) => {
      if (key in obj) {
        obj[key] = editObject[key];
      }
    });
    return obj;
  }, [editObject, editObject?.id]);

  return (
    <div>
      <Form
        defaultValues={defaultValues}
        validationSchema={FORM_VALIDATION}
        onSubmit={onSubmit}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TextInput
            autohook
            name="title_before_name"
            label={T("Title before")}
            width="11%"
          />
          <TextInput
            autohook
            name="first_name"
            label={T("First name")}
            width="36%"
          />
          <TextInput
            autohook
            name="last_name"
            label={T("Last name")}
            width="36%"
          />
          <TextInput
            autohook
            name="title_after_name"
            label={T("Title after")}
            width="11%"
          />
        </div>
        <TextInput autohook name="phone_number" label={T("Phone number")} />
        <TextInput autohook name="email" label={T("Email")} />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <TextInput
            autohook
            name="address_street"
            label={T("Street")}
            width="25%"
          />
          <TextInput
            autohook
            name="address_city"
            label={T("City")}
            width="25%"
          />
          <TextInput
            autohook
            name="address_postcode"
            label={T("Postcode")}
            width="20%"
          />
          <TextInput
            autohook
            name="address_country"
            label={T("Country")}
            width="25%"
          />
        </div>

        {customFields.map((field) => (
          <TextInput
            autohook
            key={field.key}
            name={"custom_values." + field.key}
            label={field.name}
          />
        ))}

        <Button size="small" variant="contained" color="primary" type="submit">
          {editObject === "add" ? T("Add") : T("Save")}
        </Button>
        <Button size="small" onClick={() => closeForm()}>
          {T("Cancel")}
        </Button>
        {editObject?.id && (
          <Button
            size="small"
            // @ts-ignore
            variant="delete"
            style={{ float: "right" }}
            onClick={() => {
              deleteMutation({
                variables: { id: editObject?.id },
              });
              if (closeForm) {
                closeForm();
              }
            }}
          >
            {T("Delete")}
          </Button>
        )}
      </Form>
    </div>
  );
};
