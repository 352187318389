import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MuiDrawer from "@material-ui/core/Drawer";
import MenuIcon from "@material-ui/icons/Menu";

import Header from "./Header";
import Drawer from "./Drawer";

import { useGetUserBasicQuery } from "../graphql";
import { authenticationService } from "../services/authentication";
import { T } from "../utils/translate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: "white",
      boxShadow: "0 0 8px 0px #33333322",
      color: "#333333",
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      width: "100%",
    },
    drawer: {
      flexShrink: 0,
      width: 300,
      [theme.breakpoints.up("sm")]: {
        width: 230,
      },
    },
    drawerPaper: {
      width: 300,
      [theme.breakpoints.up("sm")]: {
        width: 230,
        background: "transparent",
        borderRight: 0,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  })
);

export const Layout: React.FC = ({ children }) => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [username, setUsername] = React.useState(T("User"));
  const [company, setCompany] = React.useState(T("Company"));

  const MY_ID = authenticationService.decodeToken()?.id;

  const {
    // data: usersData,
    refetch: usersRefetch,
    // loading: usersLoading,
    // networkStatus: usersNetworkStatus,
  } = useGetUserBasicQuery({
    errorPolicy: "all",
    variables: { id: MY_ID },
    onCompleted: (a) => {
      if (a && a.users && a.users[0]) {
        const firstName = a.users[0].first_name ? a.users[0].first_name : "";
        const lastName = a.users[0].last_name ? a.users[0].last_name : "";
        let userName = firstName + " " + lastName;
        if (userName.length < 2) {
          userName = "Edit your name";
        }
        setUsername(userName);
        setCompany(a.users[0].company.name);
      } else {
        console.warn("Getting users for header failed");
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    usersRefetch();
  }, [usersRefetch]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Header username={username} company={company} />
        </Toolbar>
      </AppBar>
      <Hidden smUp implementation="css">
        <MuiDrawer
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Drawer
            showUser={{ username, company }}
            onClose={() => setMobileOpen(false)}
          />
        </MuiDrawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <MuiDrawer
          className={classes.drawer}
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <Drawer
            onClose={() => {
              // Nothing
            }}
          />
        </MuiDrawer>
      </Hidden>

      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
};
