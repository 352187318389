import "./App.css";
import "./style/sass/index.scss";

import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { Routes } from "./Routes";
import { useUserStore } from "./store/User";
import { getAppSettings } from "./services/appsettings";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import { authenticationService } from "./services/authentication";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./layout/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { cs } from "date-fns/locale";

const appSettings = getAppSettings();

const errorLink = onError(({ graphQLErrors }) => {
  for (const err of graphQLErrors) {
    console.error("Apollo err:", err, err?.extensions?.code);
    if (err?.extensions?.code === "invalid-jwt") {
      console.error("Invalid JWT");
      authenticationService.logout();
    }
  }
});

const client = new ApolloClient({
  link: from([
    errorLink,
    new HttpLink({
      uri: "https://business.telidoo.com/api/graphql/v1/graphql",
      headers: authenticationService.getAuthorizationRequestHeader(),
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
    },
    query: {
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export const App: React.FC = () => {
  const isLoading = useUserStore((state) => state.isLoading);
  const processLogin = useUserStore((state) => state.processLogin);

  useEffect(() => {
    processLogin();
  }, [processLogin]);
  if (isLoading) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={cs}>
          <CssBaseline />
          <Router basename={appSettings.baseUrl}>
            <Routes />
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
