import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const primaryColor = "#2b48a4";
const secondaryColor = "#d90000";
const previewColor = "#592ba4";
const deleteColor = "#000";
const editColor = "#592ba4";
const WHITE_COLOR = "#fff";

const mediumButtonStyles = {
  color: WHITE_COLOR,
  borderRadius: 46,
  minWidth: 100,
  minHeight: "37px",
  margin: "5px 0px",
};
const largeButtonStyles = {
  color: WHITE_COLOR,
  borderRadius: 46,
  margin: "5px 20px 5px 0px",
};
const defaultButtonShadows = {
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
};
const BUTTON_SHADOW_ON_HOVER =
  "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: WHITE_COLOR,
    },
    secondary: {
      main: secondaryColor,
      contrastText: WHITE_COLOR,
    },
    // @ts-ignore
    preview: {
      main: previewColor,
      contrastText: WHITE_COLOR,
    },
    delete: {
      main: deleteColor,
      contrastText: WHITE_COLOR,
    },
    editColor: {
      main: editColor,
      contrastText: WHITE_COLOR,
    },
  },
  //   typography: {
  //     h6: {
  //       fontWeight: "bold",
  //     },
  //     h4: {
  //       fontWeight: "bold",
  //       margin: "40px 0",
  //     },
  //   },
  overrides: {
    // @ts-ignore
    MuiAutocomplete: {
      inputRoot: {
        userSelect: "none",
        padding: "0px !important",
        '&&[class*="MuiAutocomplete-input"] $input': {
          padding: "0.6rem 0.8rem !important",
          cursor: "default",
        },
      },
      root: {
        margin: 0,
      },
      option: {
        '&[data-focus="true"]': {
          backgroundColor: "rgb(222,235,255)",
        },
      },
      input: {
        borderWidth: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: "#212529",
        fontWeight: 400,
        lineHeight: "1.3",
        marginBottom: 10,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 16,
      },
    },
    MuiSelect: {
      root: {
        height: "24px !important",
        display: "flex",
        alignItems: "center",
      },
      select: {
        borderRadius: "3px",
      },
      icon: {
        right: 2,
        top: "none",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "rgb(76,175,80)",
        },
      },
    },
    MuiButton: {
      root: {
        ...mediumButtonStyles,
        // borderRadius: 46,
        // height: 50,
        // minWidth: 180,
        // marginRight: 30,
        // "&:hover": {
        //     textDecoration: "none",
        //     backgroundColor: primaryColor,
        //     // Reset on touch devices, it doesn't add specificity
        //     "@media (hover: none)": {
        //         backgroundColor: primaryColor
        //     }
        // },
      },
      label: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      startIcon: {
        marginRight: 10,
      },
    },
    MuiTableCell: {
      body: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: 14,
      },
    },
  },

  variants: {
    MuiTypography: [
      {
        // @ts-ignore
        props: { variant: "h2" },
        styles: {
          display: "block",
          fontSize: "1.8rem",
          fontWeight: 500,
          paddingBottom: "0.8rem",
        },
      },
      {
        // @ts-ignore
        props: { variant: "h3" },
        styles: {
          display: "block",
          fontSize: "1.4rem",
          fontWeight: 500,
          paddingBottom: "0rem",
        },
      },
    ],
    MuiButton: [
      {
        // @ts-ignore
        props: { variant: "contained", color: "primary" },
        styles: {
          background: "linear-gradient(12deg, #2B48A4 0%, #6084e3 100%)",
        },
      },

      {
        // @ts-ignore
        props: { variant: "delete" },
        styles: {
          backgroundColor: deleteColor,
          ...mediumButtonStyles,
          "&:hover": {
            textDecoration: "none",
            backgroundColor: deleteColor,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: deleteColor,
            },
          },
        },
      },

      {
        // @ts-ignore
        props: { variant: "preview" },
        styles: {
          backgroundColor: previewColor,
          ...mediumButtonStyles,
          "&:hover": {
            textDecoration: "none",
            backgroundColor: previewColor,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: previewColor,
            },
          },
        },
      },

      {
        // @ts-ignore
        props: { variant: "previewLarge" },
        styles: {
          backgroundColor: previewColor,
          ...largeButtonStyles,
          ...defaultButtonShadows,
          "&:hover": {
            boxShadow: BUTTON_SHADOW_ON_HOVER,
            textDecoration: "none",
            backgroundColor: previewColor,
            // Reset on touch devices, it doesn't add specificity
            "@media (hover: none)": {
              backgroundColor: previewColor,
            },
          },
        },
      },
    ],
  },
});
