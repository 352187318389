import * as React from "react";
import { yup } from "../../utils/yup";

import { TextInput } from "../../sharedComponents/forms";
import Button from "@material-ui/core/Button";

import { authenticationService } from "../../services/authentication";
import { T } from "../../utils/translate";
import { Redirect, useParams } from "react-router-dom";
import { Form } from "../../sharedComponents/Form";

const FORM_VALIDATION = yup.object().shape({
  password: yup.string().required().min(8),
  secondPassword: yup
    .string()
    .required()
    .min(8)
    .oneOf([yup.ref("password"), null], T("Password_must_by_same")),
});

const INIT_VALUES: ResetPasswordFormModel = {
  password: "",
  secondPassword: "",
};

interface ResetPasswordFormModel {
  password: string;
  secondPassword: string;
}

export const ActivationPage: React.FC<{}> = () => {
  const [redirectVerification, setRedirectVerification] = React.useState(false);

  const { guid } = useParams();

  const onSubmit = (values: ResetPasswordFormModel) => {
    authenticationService
      .verify(guid, values.password)
      .catch((error: string) => {
        if (error == "verification_pending") {
          setRedirectVerification(true);
        } else {
          alert(T(error));
        }
      });
  };

  if (redirectVerification) {
    return <Redirect to="/verification" />;
  }

  return (
    <main className="main">
      <div className="main__content login">
        <div className="col-16">
          <div className="row">
            <Form
              onSubmit={onSubmit}
              defaultValues={INIT_VALUES}
              validationSchema={FORM_VALIDATION}
              className="form login__form"
            >
              <h1 className="title-h3 login__form--title">
                {T("Activate_profile")}
              </h1>
              <TextInput
                autohook
                type="password"
                name="password"
                label={`${T("Password")} *`}
              />
              <TextInput
                autohook
                type="password"
                name="secondPassword"
                label={`${T("Retry_password")} *`}
              />
              <Button variant="contained" color="primary" type="submit">
                {T("Send")}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};
