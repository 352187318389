import * as React from "react";
import cloneDeep from "lodash/cloneDeep";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { TextareaAutosize } from "@material-ui/core";

import EditModal from "../../sharedComponents/EditModal";
import { T } from "../../utils/translate";

import {
  useGetMyCategoriesQuery,
  useGetAllCategoriesQuery,
  useInsertCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
} from "../../graphql";
import {
  Select,
  MenuItem,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { MyTableCell } from "../../style/styledComponents/table";
import { CategoryDetail } from "./CategoryDetail";
import { HeaderWithRefresh } from "../../style/styledComponents/components";
import { MyDivider } from "../../style/styledComponents/Mui";
import { TOP_CATEGORY_UUID, NOTHING_HERE } from "../../utils/constants";

export const Categories: React.FC<{}> = () => {
  const [editingId, setEditingId] = React.useState("");
  const [parent_id, setParent_id] = React.useState(TOP_CATEGORY_UUID);
  const [parentIdHistory, setParentIdHistory] = React.useState([
    TOP_CATEGORY_UUID,
  ]);
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  const {
    data: myCategories,
    refetch: refetchMyCategories,
  } = useGetMyCategoriesQuery({
    variables: { parent_id },
  });

  const {
    data: allCategories,
    refetch: refetchAllCategories,
  } = useGetAllCategoriesQuery();

  const [
    removeCategory,
    // { error: errorRemoveCategory, data: dataRemoveCategory },
  ] = useDeleteCategoryMutation({
    onCompleted({ delete_categories_by_pk }) {
      if (!delete_categories_by_pk) {
        window.alert("Delete category failed. Can you delete this category?");
      }
      refetchMyCategories();
    },
  });

  const [
    addCategory,
    // { error: errorAddCategory, data: dataAddCategory },
  ] = useInsertCategoryMutation({
    onCompleted() {
      refetchMyCategories();
    },
  });

  const [editCategory] = useUpdateCategoryMutation({
    errorPolicy: "all",
    onCompleted(answer) {
      console.log("answer", answer);
    },
  });

  const editedCategory = myCategories?.categories.find(
    (c) => c.id === editingId
  );

  const removeCategoryAsync = async (id: string) => {
    if (
      window.confirm(
        "Are you sure? This will remove this category and ALL sub-categories"
      )
    ) {
      try {
        await removeCategory({ variables: { id } });
      } catch (e) {
        console.warn("Can not remove this category.", e);
        alert("Can not remove this category.");
      }
    }
  };

  const submittedAddNewCategory = async (e) => {
    const name = e.target[0].value;
    e.target[0].value = "";

    // const parent_id = e.target[1].value;
    console.log("parent", parent_id);

    if (myCategories.categories.find((c) => c.name === name)) {
      console.warn("Category name already exists!");
      alert("Category name already exists!");
      return;
    }
    if (name.length === 0) {
      console.warn("Name cannot be empty!");
      alert("Name cannot be empty!");
      return;
    }

    console.log("name", name);
    const arr = name.split("\n");
    console.log("arr", arr);
    try {
      arr.forEach((element) => {
        if (element && element.length) {
          addCategory({
            variables: {
              name: element,
              parent_id,
            },
          });
        }
      });

      setTimeout(() => {
        globalRefresh();
      }, 500);
    } catch (e) {
      console.warn("Cannot insert category", e);
      alert("Cannot insert category. Probably you don't have permission.");
    }
  };

  const globalRefresh = () => {
    console.log("globalRefresh");
    refetchMyCategories();
    refetchAllCategories();
  };

  const goBack = () => {
    const myParentIdHistory = cloneDeep(parentIdHistory);
    console.log("myParentIdHistory", myParentIdHistory);
    console.log("parentIdHistory", parentIdHistory);
    // myParentIdHistory.pop();
    myParentIdHistory.pop();
    // console.log("myParentIdHistory", myParentIdHistory);
    const last = myParentIdHistory[myParentIdHistory.length - 1];
    console.log("last", last);
    if (!myParentIdHistory.length) {
      console.log("pushing 0");
      myParentIdHistory.push(TOP_CATEGORY_UUID);
    }
    setParentIdHistory(myParentIdHistory);
    console.log("set Parent Id", last);
    if (last) {
      setParent_id(last);
    }
  };

  const goUpDown = (id) => {
    console.log("go up down id", id);
    if (!id) {
      id = TOP_CATEGORY_UUID;
    }
    const newParent = id;
    const myParentHistoryId = cloneDeep(parentIdHistory);
    myParentHistoryId.push(newParent);
    setParentIdHistory(myParentHistoryId);
    // console.log("myParentHistoryId", myParentHistoryId);
    // console.log("set Parent Id", newParent);

    setParent_id(newParent);
  };

  //show breadcrumps
  let parent = allCategories?.categories?.find((ac) => {
    return ac.id === parent_id;
  });

  console.log("parent", parent);

  const parents = [];
  while (parent && parent.name) {
    parents.push({ name: parent.name, id: parent.id });
    parent = parent?.parent;
  }
  //breadcrumps end

  console.log("selectedCategory", selectedCategory);
  console.log("allCategories", allCategories);

  const renderTableRow = (
    key,
    name,
    id,
    upId,
    downName,
    intend,
    breadcrump
  ) => {
    const helperArray = Array(Math.max(intend - 1, 0))
      .fill(0)
      .map((e, i) => i + 1);
    return (
      <TableRow key={key}>
        <MyTableCell
          title={name === NOTHING_HERE ? "Nothing here" : "Down"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            console.log("goUpDown", id);
            goUpDown(id);
          }}
        >
          {helperArray.map((x, index) => (
            <span key={index} style={{ color: "white" }}>
              ----
            </span>
          ))}
          {breadcrump === 1 ? (
            <button
              style={{ marginRight: 7, cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                console.log("goUpDown", upId);
                goUpDown(upId);
              }}
              title={"Collapse"}
            >
              v
            </button>
          ) : (
            <button style={{ marginRight: 7, visibility: "hidden" }}>v</button>
          )}
          <span>{name}</span>
        </MyTableCell>

        <MyTableCell
          style={{ cursor: "pointer" }}
          title={"Down"}
          onClick={() => {
            console.log("goUpDown", id);
            goUpDown(id);
          }}
        >
          {downName}
        </MyTableCell>
        <MyTableCell
          style={{ cursor: "pointer" }}
          title={"Up"}
          onClick={() => {
            console.log("goUpDown", upId);
            goUpDown(upId);
          }}
        >
          Up
        </MyTableCell>
        {!breadcrump ? (
          <MyTableCell
            style={{ cursor: "pointer" }}
            title={"Edit"}
            onClick={() => setEditingId(id)}
          >
            Edit
          </MyTableCell>
        ) : (
          <MyTableCell></MyTableCell>
        )}
        {!breadcrump ? (
          <MyTableCell
            style={{
              cursor: "pointer",
            }}
            onClick={() => removeCategoryAsync(id)}
          >
            Remove
          </MyTableCell>
        ) : (
          <MyTableCell></MyTableCell>
        )}
      </TableRow>
    );
  };

  return (
    <div>
      {/*<Typography variant="h3" gutterBottom>
        Choose a category
      </Typography>

      <ComboBoxMulti
        options={allCategories?.categories}
        onChangeValue={(c) => {
          console.log("c", c);
          setSelectedCategory(c);
          if (c && c.id) {
            //setParent_id(c.id);
            goUpDown(c.id);
          }
        }}
        chooseLabel={T("Choose a category")}
      />

      <MyDivider />*/}

      <HeaderWithRefresh>
        <Typography variant="h3" gutterBottom>
          {T("Categories")}
        </Typography>
        <Typography
          variant="h4"
          gutterBottom
          style={{ cursor: "pointer" }}
          onClick={() => {
            goBack();
          }}
        >
          Back
        </Typography>
        <IconButton onClick={() => globalRefresh()}>
          <RefreshIcon />
        </IconButton>
      </HeaderWithRefresh>
      <Typography variant="h4" gutterBottom>
        {parents.reverse().map((p, i) => (
          <span
            key={p.id}
            onClick={() => setParent_id(p.id)}
            style={{ cursor: "pointer" }}
          >
            <span style={{ color: "#2B49A4" }}>{p.name}</span>{" "}
            {i + 1 < parents.length ? " >> " : ""}
          </span>
        ))}
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <MyTableCell width={"350px"}>Name</MyTableCell>
              <MyTableCell width={"350px"}>Parent</MyTableCell>
              <MyTableCell
                width={"100px"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  goBack();
                }}
              >
                Back
              </MyTableCell>
              <MyTableCell width={"100px"}></MyTableCell>
              <MyTableCell width={"100px"}></MyTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parents.map((p, i) => {
              if (i === 0) {
                return null;
              }
              console.log("p", p);

              return renderTableRow(
                p.id,
                p.name,
                p.id,
                parents[Math.max(i - 1, 0)].id,
                parents[Math.max(i - 1, 0)].name,
                i,
                1
              );
            })}

            {
              // rendering empty row if no sub-categories here
            }

            {myCategories?.categories.length === 0 &&
              renderTableRow(
                "..key",
                NOTHING_HERE,
                parents[parents.length - 1].id,
                parents[Math.max(0, parents.length - 2)].id,
                parents[parents.length - 1].name,
                parents.length,
                2
              )}

            {
              //rendering categories of this level
            }

            {myCategories?.categories?.map(({ name, parent, id }) => {
              return renderTableRow(
                id,
                name,
                id,
                parent?.parent?.id,
                parent?.name,
                parents.length,
                0
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {myCategories?.categories && (
        <React.Fragment>
          <MyDivider />
          <Typography variant="h3" gutterBottom>
            Add new categories
          </Typography>
          <form
            onSubmit={async function (e) {
              e.preventDefault();
              submittedAddNewCategory(e);
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <MyTableCell>Names</MyTableCell>
                    <MyTableCell>Parent</MyTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <MyTableCell>
                      <TextareaAutosize
                        id={"name"}
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Add many categories; one per line"
                      />
                      ;
                    </MyTableCell>

                    <MyTableCell>
                      <Select
                        labelId="parent"
                        id="parent"
                        name="parent"
                        value={parent_id}
                        label="parent"
                        disabled
                      >
                        <MenuItem key={parent_id} value={parent_id}>
                          {parent_id}
                        </MenuItem>
                      </Select>
                    </MyTableCell>

                    <MyTableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Create category
                      </Button>
                    </MyTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </React.Fragment>
      )}

      <MyDivider />

      <EditModal
        title="Category detail"
        editObject={editedCategory}
        onClose={() => setEditingId("")}
        editComponent={(editObject, closeForm) => (
          <CategoryDetail
            data={editObject}
            editingId={editingId}
            closeForm={closeForm}
            editCategory={editCategory}
          />
        )}
      />
    </div>
  );
};
