import * as React from "react";
import { useQuery, useMutation } from "@apollo/client";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import styled from "styled-components";

import { NewEmail } from "../NewEmail";
import { EditTable } from "../User/EditTable";
import { HeaderWithRefresh } from "../../style/styledComponents/components";

import { authenticationService } from "../../services/authentication";
import { T } from "../../utils/translate";

import {
  useGetUsersQuery,
  useUpdateUserMutation,
  useGetEstablishmentsUserQuery,
  useGetEstablishmentsQuery,
  useGetPhoneNumbersQuery,
  useGetPhoneNumbersUsersQuery,
} from "../../graphql";

export const Profile: React.FC<{}> = () => {
  const [editingId, setEditingId] = React.useState(
    authenticationService.decodeToken()?.id
  ); //todo
  const [alertOpen, setAlertOpen] = React.useState(false);

  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const {
    data: phone_numbersData, //duplicated from phone numbers
    refetch: phone_numbersRefetch,
    // loading: phone_numbersLoading,
    // networkStatus: phone_numbersNetworkStatus,
  } = useGetPhoneNumbersQuery();

  const {
    data: usersData,
    refetch: usersRefetch,
    loading: usersLoading,
    // networkStatus: usersNetworkStatus,
  } = useGetUsersQuery();

  const {
    //duplicated in Establishment
    data: userEstablishmentsData, //these are establishments belonging to this user
    refetch: userEstRefetch,
    // loading: userEstLoading,
    // error: userEstError,
    // networkStatus: userEstNetworkStatus,
  } = useGetEstablishmentsUserQuery();

  const {
    data: phone_numberUserData, //these are all phone_numbers linked to  users - I get them all
    refetch: phone_numberUserRefetch,
    // loading: phone_numberUserDataLoading,
    // error: phone_numberUserDataError,
    // networkStatus: phone_numberUserDataNetworkStatus,
  } = useGetPhoneNumbersUsersQuery();

  const {
    //duplicated in establishment.tsx, later connect it via Mobex?
    data: establishmentsData, //all est of company that user could be linked to
    refetch: estRefetch,
    loading: estLoading,
    // networkStatus: estNetworkStatus,
  } = useGetEstablishmentsQuery();

  const [
    editUser /*{ error: errorEditUser, data: dataEditUser }*/,
  ] = useUpdateUserMutation({
    onCompleted(answer) {
      usersRefetch();
      console.log("update_users_by_pk", answer);
      handleAlertClick();
      if (!answer?.update_users_by_pk) {
        console.warn("Users not updated.");
        alert(T("Use not updated. Do you have a permission?"));
      }
    },
  });

  const globalRefresh = () => {
    console.log("globalRefresh", globalRefresh);
    usersRefetch();
  };

  const editedUser = usersData?.users.find((e) => e.id === editingId);

  if (usersLoading || estLoading) {
    return (
      <>
        <Typography variant="h2">{T("Loading...")}</Typography>
        <CircularProgress />
      </>
    );
  }

  if (
    !usersData ||
    !usersData.users ||
    !Array.isArray(usersData?.users) ||
    usersData?.users.length === 0
  ) {
    return (
      <Typography variant="h5" gutterBottom>
        {T("No users exist for your company. Create one!")}
      </Typography>
    );
  }

  return (
    <div key={1000} style={{ maxWidth: 1000, margin: "0 auto" }}>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          // @ts-ignore
          onClose={handleAlertClose}
          variant={"filled"}
          severity="success"
          elevation={6}
        >
          {T("Profile saved successfully.")}
        </Alert>
      </Snackbar>
      {usersData?.users && usersData?.users[0] && usersData?.users[0].email ? (
        <React.Fragment>
          <HeaderWithRefresh>
            <Typography variant="h2">{T("Your profile")}</Typography>
          </HeaderWithRefresh>
          <EditTable
            addEditUser={editUser}
            editingId={editingId}
            data={editedUser}
            operation={"edit-profile"}
            phone_numbersData={phone_numbersData}
            phone_numbers_users={phone_numberUserData?.phone_numbers_users}
            userEstablishmentsData={userEstablishmentsData}
            globalRefresh={() => {
              usersRefetch();
            }}
            establishmentsData={establishmentsData}
            myCompanyRoles={null}
            resendEmail={null}
          />
        </React.Fragment>
      ) : (
        <div>
          <Typography variant="h4" gutterBottom>
            {T("You need to set and verify your email first.")}
          </Typography>
          <NewEmail
            globalRefresh={() => {
              usersRefetch();
            }}
          />
        </div>
      )}
    </div>
  );
};
