import styled from "styled-components";
import { TableCell } from "@material-ui/core";

export const MyXTableCell = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  padding-bottom: 3px;
`;

export const MyPhotoTableCell = styled(MyXTableCell)`
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 0px;
`;

export const NBTableCell = styled(MyXTableCell)`
  border: 0px;
  color: grey;
  padding-top: 3px;
  padding-bottom: 16px;
  font-weight: 100;
`;

export const VenuePhoto = styled.img`
  padding-left: 13px;
  padding-top: 10px;
`;
