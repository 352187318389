import create from "zustand";
import { combine } from "zustand/middleware";

import { authenticationService } from "../services/authentication";

export const useUserStore = create(
  combine(
    {
      isLoading: true,
      isAuthenticated: false,
      userEmail: "",
    },
    (set) => ({
      processLogin: async () => {
        set({ isLoading: true });
        try {
          await authenticationService.getUserData();
          set({ isLoading: false, isAuthenticated: true });
        } catch (err) {
          set({ isLoading: false, isAuthenticated: false });
        }
      },
      setAuthenticated: (newValue: boolean) =>
        set({ isAuthenticated: newValue }),
      setEmail: (newEmail: string) => set({ userEmail: newEmail }),
    })
  )
);
