import React from "react";

import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";

export const ListItemLink: React.FC<{
  to: string;
  className?: string;
  children: React.ReactNode;
}> = ({ to, className, children }) => {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps?: any, ref?: any) => (
        <Link to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <ListItem button component={renderLink} className={className}>
      {children}
    </ListItem>
  );
};

export default ListItemLink;
