import * as React from "react";

import {
  Typography,
  Paper,
  makeStyles,
  Button,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { yup } from "../../utils/yup";
import { T } from "../../utils/translate";

import { TextInput } from "../../sharedComponents/forms";
import { Form } from "../../sharedComponents/Form";

import TransitionsModal from "./TransitionModal";
import CategoriesChips from "../Categories/CategoriesChips";
import { ImgMaster } from "../../sharedComponents/CropUploadComponent/ImgMaster";
import cloneDeep from "lodash/cloneDeep";

import { authenticationService } from "../../services/authentication";

import {
  useGetMyCompanyQuery,
  useChangeCompanyMutation,
  useChangeCompanyLogoMutation,
  useUpdateCompanyCategoriesMutation,
} from "../../graphql";

const FORM_VALIDATION = yup.object().shape({
  name: yup.string().required(),
  short_description: yup.string().max(200),
  description: yup.string(),
  address_city: yup.string().required(),
  address_postcode: yup.string().required(),
  address_street: yup.string().required(),
  logo_url: yup.string(),
  vat_id: yup.number(),
  dic: yup.string(),
  website: yup
    .string()
    .matches(
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/,
      T("Enter correct url!")
    ),
});

const KEYS_TO_NAMES = {
  name: "Name (by official registry)",
  address_city: "City",
  address_postcode: "Postcode",
  address_street: "Street",
  website: "Website",
  logo_url: "Logo",
  category: "Category",
  phone_numbers_active: "Phone numbers active",
  phone_numbers: "Phone numbers",
  vat_id: "VAT ID",
  dic: "DIC",
  published: "Published",
};

export const Company: React.FC<{}> = () => {
  const [editSuccess, setEditSuccess] = React.useState(false);
  const [photoUrls, setPhotoUrls] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]); //<CategoryDetailType>

  const {
    data: companyData,
    refetch: companyRefetch,
    loading: companyLoading,
    error: companyError,
    // networkStatus: companyNetworkStatus,
  } = useGetMyCompanyQuery({
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    onCompleted(answer) {
      if (answer?.companies[0]) {
        if (answer?.companies[0].logo_url) {
          try {
            setPhotoUrls(JSON.parse(answer?.companies[0].logo_url));
          } catch (e) {
            console.error("e parse company logo", e);
          }
        }

        const categories = answer?.companies[0]?.companies_categories
          ? companyData?.companies[0]?.companies_categories
          : [];
        setCategories(categories);
        if (categories && categories.length) {
          setSelectedCategories(categories.map((c) => c.category));
        }
      } else {
        console.error("Total error. Didn't get any company");
      }
    },
  });

  const [
    editCompany,
    // { error: errorEditCompany, data: dataEditCompany },
  ] = useChangeCompanyMutation({
    errorPolicy: "all",
    onCompleted(answer) {
      if (answer?.update_companies_by_pk) {
        setEditSuccess(true);
        setTimeout(() => {
          setEditSuccess(false);
        }, 5500);
      } else {
        console.warn("company was not updated, probably missing privilegies");
        alert(
          T("Company was not updated, probably because of missing privilegies.")
        );
      }
      //   setEdit(false);
      companyRefetch();
    },
  });

  const [
    editCompanyPhoto,
    // { error: errorEditCompany, data: dataEditCompany },
  ] = useChangeCompanyLogoMutation();

  const [
    deleteAndInsertCompanyCategories,
    // { error: errorDai, data: dataDai },
  ] = useUpdateCompanyCategoriesMutation({
    errorPolicy: "all",
    onCompleted(answer) {
      // setEdit(false);
      // companyRefetch();
    },
  });

  const onFileChange = (photo) => {
    setPhotoUrls([photo]);
    editCompanyPhoto({
      variables: {
        id: companyData.companies[0].id,
        logo_url: JSON.stringify([photo]),
      },
    });
  };

  const onFileRemove = (photo) => {
    const photos = cloneDeep(photoUrls).filter((x) => x !== photo);
    setPhotoUrls(photos);
    editCompanyPhoto({
      variables: {
        id: companyData.companies[0].id,
        logo_url: JSON.stringify(photos),
      },
    });
  };

  const saveSelectedCategories = (checked) => {
    deleteAndInsertCompanyCategories({
      variables: {
        objects: checked.map((sc) => ({
          category_id: sc.id,
        })),
      },
    });
  };

  console.log("companyData", companyData);
  if (companyData && !companyData.companies.length) {
    console.error("ERROR: No company data from the server. Logging out.");
    authenticationService.logout();
    return null;
  }

  return (
    <div>
      {companyError /* || !companyData?.companies[0]*/ && (
        <span>Error fetching companies.</span>
      )}

      {companyLoading && !editSuccess ? ( //todo here some loading screen would prevent blinking on save
        <>
          <Typography variant="h2">{T("Loading...")}</Typography>
          <CircularProgress />
        </>
      ) : (
        <>
          <Typography variant="h2">
            {T("Editing your company")} {/*companyData?.companies[0].name*/}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 6, marginTop: -8 }}
          >
            {T("Set up your basic company information")}
          </Typography>
          <Form
            className={"form"}
            defaultValues={companyData?.companies[0]}
            validationSchema={FORM_VALIDATION}
            onSubmit={(data: any) => {
              data.id = companyData?.companies[0].id;
              data.logo_url = JSON.stringify(photoUrls);
              editCompany({ variables: data });
            }}
          >
            <Paper>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  style={{ borderRight: "1px solid #ddd", padding: 24 }}
                >
                  <TextInput
                    autohook
                    name="name"
                    label={T(KEYS_TO_NAMES["name"])}
                  />
                  <Typography variant="h6" gutterBottom>
                    Adresa sídla
                  </Typography>
                  <TextInput
                    autohook
                    name="address_street"
                    label={T(KEYS_TO_NAMES["address_street"])}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1.5rem",
                    }}
                  >
                    <TextInput
                      autohook
                      name="address_city"
                      label={T(KEYS_TO_NAMES["address_city"])}
                      width={"50%"}
                    />
                    <TextInput
                      autohook
                      name="address_postcode"
                      label={T(KEYS_TO_NAMES["address_postcode"])}
                      width={"30%"}
                    />
                  </div>

                  <TextInput
                    autohook
                    name="website"
                    label={T(KEYS_TO_NAMES["website"])}
                  />

                  <TextInput
                    autohook
                    name="short_description"
                    label={T("Short description")}
                    textarea
                    placeholder={T("Short description of your company")}
                    helperText={T("Short description info")}
                  />

                  <TextInput
                    autohook
                    name="description"
                    label={T("Description")}
                    textarea
                    placeholder={T("Longer description of your company")}
                  />

                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TextInput
                      autohook
                      name="ico"
                      label={T(KEYS_TO_NAMES["vat_id"])}
                      placeholder={companyData?.companies[0].vat_id}
                      disabled
                      width={"30%"}
                    />
                    <TextInput
                      autohook
                      name="dic"
                      label={T(KEYS_TO_NAMES["dic"])}
                      width={"30%"}
                    />
                  </div>

                  <Button variant="contained" color="primary" type="submit">
                    {editSuccess ? T("Saved successfuly") : T("Save")}
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6} style={{ padding: 24 }}>
                  <Typography variant="h6">{T("Company logo")}</Typography>
                  <Typography variant="subtitle1">
                    {T(
                      "Logo will be in square format, displayed in the catalog and incoming call screens"
                    )}
                  </Typography>
                  <ImgMaster
                    operation={"company-img-edit"}
                    onFileChange={onFileChange}
                    onFileRemove={onFileRemove}
                    photoUrls={photoUrls}
                  />

                  <Typography
                    variant="h6"
                    style={{ marginTop: "2rem", marginBottom: "1rem" }}
                  >
                    {T("Category")}
                  </Typography>
                  {selectedCategories.length == 0 && (
                    <div style={{ marginBottom: "1rem" }}>
                      <Alert severity="warning">
                        {T("No categories set up")}
                      </Alert>
                    </div>
                  )}
                  <CategoriesChips
                    chipData={selectedCategories}
                    setChecked={(checked) => {
                      setSelectedCategories(checked);
                      saveSelectedCategories(checked);
                    }}
                    addMore={
                      <TransitionsModal
                        onChangeValue={setSelectedCategories}
                        onClose={() =>
                          saveSelectedCategories(selectedCategories)
                        }
                        defaultValues={selectedCategories}
                      />
                    }
                  />

                  {/*<Typography variant="h6" style={{ marginTop: "2rem" }}>
                  {T(KEYS_TO_NAMES["published"])}
                </Typography>
                {T(
                  `published-${companyData?.companies[0].published.toString()}`
                )}*/}
                </Grid>
              </Grid>
            </Paper>
          </Form>
        </>
      )}
    </div>
  );
};

/**
 * input type for inserting data into table "activityTag"
 */
export interface companies_categories_insert_input {
  company_id: string | null;
  category_id: string | null;
}
