export type AxiosError = {
    message: string;
    status?: number;
    statusText?: string;
};

export function parseAxiosError(axiosError: any): AxiosError {
    if (typeof axiosError === 'object' && axiosError.isAxiosError) {
        const resp = axiosError.response;

        if (!resp) {
            return {
                message: 'no data error',
            };
        }

        if (resp.data) {
            if (!resp.data.success && resp.data.error) {
                return {
                    message: resp.data.error,
                };
            }
        }

        return {
            message: axiosError.message,
            status: resp ? resp.status : null,
            statusText: resp ? resp.statusText : null,
        };
    }

    if (typeof axiosError === 'string') {
        return {
            message: axiosError,
        };
    }

    console.log('axios error response', typeof axiosError, axiosError);
    throw new Error('Cant parse axios error response');
}
