import * as React from "react";

import {
  Typography,
  TableContainer,
  TableHead,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import RefreshIcon from "@material-ui/icons/Refresh";
import ScheduleIcon from "@material-ui/icons/Schedule";
import PublicIcon from "@material-ui/icons/Public";

import { UserType, UserEstablishmentType } from "./types";
import { EditTable } from "./EditTable";
import EditModal from "../../sharedComponents/EditModal";
import { HeaderWithRefresh } from "../../style/styledComponents/components";

import { AddEditEstablishment } from "../Establishment/types";
import { Phone_numberType, Phone_numberUserType } from "../PhoneNumber/types";

import { Role } from "../Role/types";
import { T } from "../../utils/translate";
import { NewEmail } from "../NewEmail";
import {
  CLOUDINARY_BASE_URL_1,
  CLOUDINARY_BASE_URL_2,
  QUALITY_100,
} from "../../utils/constants";

export const MyTableCell = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const MyPhotoTableCell = styled(MyTableCell)`
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0px;
`;

export const MyTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const ThumbnailImg = styled.img`
  ${(props) => props.height && `height: ${props.height};`}
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

interface UserSelectProps {
  editUser: any;
  usersData: { users: UserType[] };
  establishmentsData?: { establishments: AddEditEstablishment[] };
  phone_numbersData?: { phone_numbers: Phone_numberType[] };
  phone_numbers_users?: Phone_numberUserType[];
  userEstablishmentsData?: { establishments_users: UserEstablishmentType[] };
  myCompanyRoles: { roles: Role[] };
  globalRefresh?: () => void;
  resendEmail: (id: string, email: string) => void;
  removeUser: (id: string) => void;
}

//name is bit misleading. This is showing list of users, but also AddUser at the bottom
export const UserSelect: React.FC<UserSelectProps> = ({
  usersData,
  editUser,
  phone_numbersData,
  phone_numbers_users,
  globalRefresh,
  establishmentsData,
  myCompanyRoles,
  userEstablishmentsData,
  resendEmail,
  removeUser,
}) => {
  const [editingId, setEditingId] = React.useState("");

  const tableHeader = [
    T("First name"),
    T("Last name"),
    T("Status"),
    T("Role"),
    T("Email"),
    T("Phone numbers"),
    T("Photo"),
    T("Linked establishments"),
  ];

  const renderUsersTable = (users: UserType[]) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeader.map((th, i) => (
                <MyTableCell key={i}>{th}</MyTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(
              ({
                company_id,
                first_name,
                last_name,
                email,
                id,
                active,
                role,
                disabled,
                is_public_email,
                photo_url,
              }) => {
                let firstPhoto = "";
                try {
                  firstPhoto = JSON.parse(photo_url)[0];
                } catch (e) {
                  // No photo
                }
                return (
                  <MyTableRow key={id} onClick={(e) => setEditingId(id)}>
                    <MyTableCell> {first_name} </MyTableCell>
                    <MyTableCell> {last_name} </MyTableCell>
                    <MyTableCell style={{ opacity: 0.8 }}>
                      {!disabled ? (
                        active ? (
                          <CheckCircleIcon />
                        ) : (
                          <ScheduleIcon />
                        )
                      ) : (
                        <CancelIcon />
                      )}
                    </MyTableCell>
                    <MyTableCell>{role.name}</MyTableCell>
                    <MyTableCell>
                      {email}
                      {is_public_email && (
                        <span title={T("Email is public")}>
                          {" "}
                          <PublicIcon
                            style={{ opacity: 0.3, marginBottom: "-5px" }}
                            fontSize="small"
                          />
                        </span>
                      )}
                    </MyTableCell>
                    <MyTableCell>
                      <ul style={{ margin: 0 }}>
                        {phone_numbers_users
                          ?.filter((x) => x.user?.id === id)
                          .map((x) => (
                            <li key={x?.phone_number?.id}>
                              {`${x.phone_number.name} (${x.phone_number.phone_number})`}
                            </li>
                          ))}
                      </ul>
                    </MyTableCell>
                    <TableCell>
                      {firstPhoto ? (
                        <ThumbnailImg
                          src={
                            CLOUDINARY_BASE_URL_1 +
                            QUALITY_100 +
                            CLOUDINARY_BASE_URL_2 +
                            firstPhoto
                          }
                          alt="thumbnail"
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <MyTableCell>
                      <ul style={{ paddingLeft: "16px" }}>
                        {userEstablishmentsData?.establishments_users
                          .filter((eu) => eu?.user?.id === id)
                          .map((se) => (
                            <li key={se?.establishment.id}>
                              {se?.establishment.name}
                            </li>
                          ))}
                      </ul>
                    </MyTableCell>
                  </MyTableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (
    !usersData ||
    !usersData.users ||
    !Array.isArray(usersData?.users) ||
    usersData?.users.length === 0
  ) {
    return (
      <Typography variant="h5" gutterBottom>
        {T("No users exist for your company. Create one!")}
      </Typography>
    );
  }

  return (
    <div key={15}>
      {usersData?.users && usersData?.users[0] && usersData?.users[0].email ? (
        <React.Fragment>
          <HeaderWithRefresh>
            <div>
              <Typography variant="h2">{T("Users")}</Typography>
              <Typography
                variant="subtitle1"
                style={{ marginBottom: 6, marginTop: -8 }}
              >
                {T(
                  "Add your employees to give them access and show their info in incoming calls"
                )}
              </Typography>
            </div>
            <IconButton onClick={() => globalRefresh()}>
              <RefreshIcon />
            </IconButton>
          </HeaderWithRefresh>

          {renderUsersTable(usersData.users)}
          <EditModal
            title={T("Editing user")}
            editObject={usersData?.users.find((e) => e.id === editingId)}
            editComponent={(editObject, closeForm) => (
              <EditTable
                addEditUser={editUser}
                editingId={editingId}
                closeForm={closeForm}
                data={editObject}
                operation={"edit"}
                phone_numbersData={phone_numbersData}
                globalRefresh={globalRefresh}
                phone_numbers_users={phone_numbers_users}
                establishmentsData={establishmentsData}
                myCompanyRoles={myCompanyRoles}
                userEstablishmentsData={userEstablishmentsData}
                resendEmail={resendEmail}
                removeUser={removeUser}
              />
            )}
            onClose={() => setEditingId("")}
          />
        </React.Fragment>
      ) : (
        <div>
          <Typography variant="h4" gutterBottom>
            {T("You need to set and verify your email first.")}
          </Typography>
          <NewEmail globalRefresh={globalRefresh} />
        </div>
      )}
    </div>
  );
};
