import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bpchar: any;
  date: any;
  json: any;
  jsonb: any;
  money: any;
  numeric: any;
  time: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CentrumSmsInfo = {
  activated?: Maybe<Scalars['Boolean']>;
  count?: Maybe<Scalars['Int']>;
  count_second?: Maybe<Scalars['Int']>;
  last_seen?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  second_sim?: Maybe<Scalars['Boolean']>;
  second_sim_number?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  total_count?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

export type CentrumSmsMessage = {
  message: Scalars['String'];
  recipient?: Maybe<Scalars['String']>;
};

export type CreateReservioUserInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type CreateReservioUserOutput = {
  success: Scalars['Boolean'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type Mutation = {
  centrumsms_send?: Maybe<Scalars['Int']>;
  centrumsms_send_group?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type MutationCentrumsms_SendArgs = {
  message: Scalars['String'];
  recipient: Scalars['String'];
  second_sim?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};


export type MutationCentrumsms_Send_GroupArgs = {
  body: Scalars['String'];
  recipients: Scalars['String'];
  second_sim?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type Query = {
  centrumsms_info?: Maybe<Array<Maybe<CentrumSmsInfo>>>;
};


export type QueryCentrumsms_InfoArgs = {
  userId: Scalars['Int'];
};

export type SampleInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SampleOutput = {
  accessToken: Scalars['String'];
};

export type ShoptetImportOutput = {
  success?: Maybe<Scalars['Boolean']>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type TerminalTransactionsCountOutput = {
  totalCount: Scalars['Int'];
};

export type TerminalTransactionsCountOutputAggregate = {
  total: Scalars['Int'];
};

export type TerminalTransactionsInput = {
  api_key: Scalars['String'];
  offset: Scalars['Int'];
  pc3000_company_id: Scalars['Int'];
};

export type TerminalTransactionsOutput = {
  acquirerTidIdentity?: Maybe<Scalars['String']>;
  amount: Scalars['String'];
  currencyCode: Scalars['String'];
  id: Scalars['String'];
  issuerTidIdentity?: Maybe<Scalars['String']>;
  posId: Scalars['String'];
  responseText?: Maybe<Scalars['String']>;
  successful: Scalars['String'];
  terminalTimestamp: Scalars['String'];
  transactionType: Scalars['String'];
  uuid: Scalars['String'];
};

/** columns and relationships of "automation_flows" */
export type Automation_Flows = {
  active: Scalars['Boolean'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  event_trigger?: Maybe<Scalars['String']>;
  flow: Scalars['jsonb'];
  flowchart_steps?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  integration_type?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  trigger_time?: Maybe<Scalars['time']>;
};


/** columns and relationships of "automation_flows" */
export type Automation_FlowsFlowArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "automation_flows" */
export type Automation_FlowsFlowchart_StepsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "automation_flows" */
export type Automation_Flows_Aggregate = {
  aggregate?: Maybe<Automation_Flows_Aggregate_Fields>;
  nodes: Array<Automation_Flows>;
};

/** aggregate fields of "automation_flows" */
export type Automation_Flows_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Automation_Flows_Max_Fields>;
  min?: Maybe<Automation_Flows_Min_Fields>;
};


/** aggregate fields of "automation_flows" */
export type Automation_Flows_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Automation_Flows_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "automation_flows" */
export type Automation_Flows_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Automation_Flows_Max_Order_By>;
  min?: Maybe<Automation_Flows_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Automation_Flows_Append_Input = {
  flow?: Maybe<Scalars['jsonb']>;
  flowchart_steps?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "automation_flows" */
export type Automation_Flows_Arr_Rel_Insert_Input = {
  data: Array<Automation_Flows_Insert_Input>;
  on_conflict?: Maybe<Automation_Flows_On_Conflict>;
};

/** Boolean expression to filter rows from the table "automation_flows". All fields are combined with a logical 'AND'. */
export type Automation_Flows_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Automation_Flows_Bool_Exp>>>;
  _not?: Maybe<Automation_Flows_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Automation_Flows_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  event_trigger?: Maybe<String_Comparison_Exp>;
  flow?: Maybe<Jsonb_Comparison_Exp>;
  flowchart_steps?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  integration_type?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  trigger_time?: Maybe<Time_Comparison_Exp>;
};

/** unique or primary key constraints on table "automation_flows" */
export enum Automation_Flows_Constraint {
  /** unique or primary key constraint */
  AutomationFlowsPkey = 'automation_flows_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Automation_Flows_Delete_At_Path_Input = {
  flow?: Maybe<Array<Maybe<Scalars['String']>>>;
  flowchart_steps?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Automation_Flows_Delete_Elem_Input = {
  flow?: Maybe<Scalars['Int']>;
  flowchart_steps?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Automation_Flows_Delete_Key_Input = {
  flow?: Maybe<Scalars['String']>;
  flowchart_steps?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "automation_flows" */
export type Automation_Flows_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  event_trigger?: Maybe<Scalars['String']>;
  flow?: Maybe<Scalars['jsonb']>;
  flowchart_steps?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  integration_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  trigger_time?: Maybe<Scalars['time']>;
};

/** aggregate max on columns */
export type Automation_Flows_Max_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  event_trigger?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  integration_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "automation_flows" */
export type Automation_Flows_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  event_trigger?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integration_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Automation_Flows_Min_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  event_trigger?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  integration_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "automation_flows" */
export type Automation_Flows_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  event_trigger?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integration_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "automation_flows" */
export type Automation_Flows_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Automation_Flows>;
};

/** input type for inserting object relation for remote table "automation_flows" */
export type Automation_Flows_Obj_Rel_Insert_Input = {
  data: Automation_Flows_Insert_Input;
  on_conflict?: Maybe<Automation_Flows_On_Conflict>;
};

/** on conflict condition type for table "automation_flows" */
export type Automation_Flows_On_Conflict = {
  constraint: Automation_Flows_Constraint;
  update_columns: Array<Automation_Flows_Update_Column>;
  where?: Maybe<Automation_Flows_Bool_Exp>;
};

/** ordering options when selecting data from "automation_flows" */
export type Automation_Flows_Order_By = {
  active?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  event_trigger?: Maybe<Order_By>;
  flow?: Maybe<Order_By>;
  flowchart_steps?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  integration_type?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  trigger_time?: Maybe<Order_By>;
};

/** primary key columns input for table: "automation_flows" */
export type Automation_Flows_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Automation_Flows_Prepend_Input = {
  flow?: Maybe<Scalars['jsonb']>;
  flowchart_steps?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "automation_flows" */
export enum Automation_Flows_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  EventTrigger = 'event_trigger',
  /** column name */
  Flow = 'flow',
  /** column name */
  FlowchartSteps = 'flowchart_steps',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integration_type',
  /** column name */
  Name = 'name',
  /** column name */
  TriggerTime = 'trigger_time'
}

/** input type for updating data in table "automation_flows" */
export type Automation_Flows_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  event_trigger?: Maybe<Scalars['String']>;
  flow?: Maybe<Scalars['jsonb']>;
  flowchart_steps?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  integration_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  trigger_time?: Maybe<Scalars['time']>;
};

/** update columns of table "automation_flows" */
export enum Automation_Flows_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  EventTrigger = 'event_trigger',
  /** column name */
  Flow = 'flow',
  /** column name */
  FlowchartSteps = 'flowchart_steps',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationType = 'integration_type',
  /** column name */
  Name = 'name',
  /** column name */
  TriggerTime = 'trigger_time'
}

/** columns and relationships of "automation_queue" */
export type Automation_Queue = {
  evaluate_row_at: Scalars['timestamptz'];
  evaluated: Scalars['Boolean'];
  flowchart?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  information: Scalars['jsonb'];
  next_step: Scalars['String'];
};


/** columns and relationships of "automation_queue" */
export type Automation_QueueFlowchartArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "automation_queue" */
export type Automation_QueueInformationArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "automation_queue" */
export type Automation_Queue_Aggregate = {
  aggregate?: Maybe<Automation_Queue_Aggregate_Fields>;
  nodes: Array<Automation_Queue>;
};

/** aggregate fields of "automation_queue" */
export type Automation_Queue_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Automation_Queue_Max_Fields>;
  min?: Maybe<Automation_Queue_Min_Fields>;
};


/** aggregate fields of "automation_queue" */
export type Automation_Queue_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Automation_Queue_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "automation_queue" */
export type Automation_Queue_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Automation_Queue_Max_Order_By>;
  min?: Maybe<Automation_Queue_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Automation_Queue_Append_Input = {
  flowchart?: Maybe<Scalars['jsonb']>;
  information?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "automation_queue" */
export type Automation_Queue_Arr_Rel_Insert_Input = {
  data: Array<Automation_Queue_Insert_Input>;
  on_conflict?: Maybe<Automation_Queue_On_Conflict>;
};

/** Boolean expression to filter rows from the table "automation_queue". All fields are combined with a logical 'AND'. */
export type Automation_Queue_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Automation_Queue_Bool_Exp>>>;
  _not?: Maybe<Automation_Queue_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Automation_Queue_Bool_Exp>>>;
  evaluate_row_at?: Maybe<Timestamptz_Comparison_Exp>;
  evaluated?: Maybe<Boolean_Comparison_Exp>;
  flowchart?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  information?: Maybe<Jsonb_Comparison_Exp>;
  next_step?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "automation_queue" */
export enum Automation_Queue_Constraint {
  /** unique or primary key constraint */
  AutomationQueuePkey = 'automation_queue_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Automation_Queue_Delete_At_Path_Input = {
  flowchart?: Maybe<Array<Maybe<Scalars['String']>>>;
  information?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Automation_Queue_Delete_Elem_Input = {
  flowchart?: Maybe<Scalars['Int']>;
  information?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Automation_Queue_Delete_Key_Input = {
  flowchart?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "automation_queue" */
export type Automation_Queue_Insert_Input = {
  evaluate_row_at?: Maybe<Scalars['timestamptz']>;
  evaluated?: Maybe<Scalars['Boolean']>;
  flowchart?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  information?: Maybe<Scalars['jsonb']>;
  next_step?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Automation_Queue_Max_Fields = {
  evaluate_row_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  next_step?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "automation_queue" */
export type Automation_Queue_Max_Order_By = {
  evaluate_row_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  next_step?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Automation_Queue_Min_Fields = {
  evaluate_row_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  next_step?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "automation_queue" */
export type Automation_Queue_Min_Order_By = {
  evaluate_row_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  next_step?: Maybe<Order_By>;
};

/** response of any mutation on the table "automation_queue" */
export type Automation_Queue_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Automation_Queue>;
};

/** input type for inserting object relation for remote table "automation_queue" */
export type Automation_Queue_Obj_Rel_Insert_Input = {
  data: Automation_Queue_Insert_Input;
  on_conflict?: Maybe<Automation_Queue_On_Conflict>;
};

/** on conflict condition type for table "automation_queue" */
export type Automation_Queue_On_Conflict = {
  constraint: Automation_Queue_Constraint;
  update_columns: Array<Automation_Queue_Update_Column>;
  where?: Maybe<Automation_Queue_Bool_Exp>;
};

/** ordering options when selecting data from "automation_queue" */
export type Automation_Queue_Order_By = {
  evaluate_row_at?: Maybe<Order_By>;
  evaluated?: Maybe<Order_By>;
  flowchart?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  information?: Maybe<Order_By>;
  next_step?: Maybe<Order_By>;
};

/** primary key columns input for table: "automation_queue" */
export type Automation_Queue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Automation_Queue_Prepend_Input = {
  flowchart?: Maybe<Scalars['jsonb']>;
  information?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "automation_queue" */
export enum Automation_Queue_Select_Column {
  /** column name */
  EvaluateRowAt = 'evaluate_row_at',
  /** column name */
  Evaluated = 'evaluated',
  /** column name */
  Flowchart = 'flowchart',
  /** column name */
  Id = 'id',
  /** column name */
  Information = 'information',
  /** column name */
  NextStep = 'next_step'
}

/** input type for updating data in table "automation_queue" */
export type Automation_Queue_Set_Input = {
  evaluate_row_at?: Maybe<Scalars['timestamptz']>;
  evaluated?: Maybe<Scalars['Boolean']>;
  flowchart?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  information?: Maybe<Scalars['jsonb']>;
  next_step?: Maybe<Scalars['String']>;
};

/** update columns of table "automation_queue" */
export enum Automation_Queue_Update_Column {
  /** column name */
  EvaluateRowAt = 'evaluate_row_at',
  /** column name */
  Evaluated = 'evaluated',
  /** column name */
  Flowchart = 'flowchart',
  /** column name */
  Id = 'id',
  /** column name */
  Information = 'information',
  /** column name */
  NextStep = 'next_step'
}

/** columns and relationships of "bank_accs" */
export type Bank_Accs = {
  account: Scalars['String'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  id: Scalars['uuid'];
  primary: Scalars['Boolean'];
};

/** aggregated selection of "bank_accs" */
export type Bank_Accs_Aggregate = {
  aggregate?: Maybe<Bank_Accs_Aggregate_Fields>;
  nodes: Array<Bank_Accs>;
};

/** aggregate fields of "bank_accs" */
export type Bank_Accs_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Bank_Accs_Max_Fields>;
  min?: Maybe<Bank_Accs_Min_Fields>;
};


/** aggregate fields of "bank_accs" */
export type Bank_Accs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bank_Accs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "bank_accs" */
export type Bank_Accs_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Bank_Accs_Max_Order_By>;
  min?: Maybe<Bank_Accs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "bank_accs" */
export type Bank_Accs_Arr_Rel_Insert_Input = {
  data: Array<Bank_Accs_Insert_Input>;
  on_conflict?: Maybe<Bank_Accs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "bank_accs". All fields are combined with a logical 'AND'. */
export type Bank_Accs_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Bank_Accs_Bool_Exp>>>;
  _not?: Maybe<Bank_Accs_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Bank_Accs_Bool_Exp>>>;
  account?: Maybe<String_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  primary?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "bank_accs" */
export enum Bank_Accs_Constraint {
  /** unique or primary key constraint */
  BankAccsPkey = 'bank_accs_pkey'
}

/** input type for inserting data into table "bank_accs" */
export type Bank_Accs_Insert_Input = {
  account?: Maybe<Scalars['String']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  primary?: Maybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Bank_Accs_Max_Fields = {
  account?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "bank_accs" */
export type Bank_Accs_Max_Order_By = {
  account?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bank_Accs_Min_Fields = {
  account?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "bank_accs" */
export type Bank_Accs_Min_Order_By = {
  account?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "bank_accs" */
export type Bank_Accs_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Bank_Accs>;
};

/** input type for inserting object relation for remote table "bank_accs" */
export type Bank_Accs_Obj_Rel_Insert_Input = {
  data: Bank_Accs_Insert_Input;
  on_conflict?: Maybe<Bank_Accs_On_Conflict>;
};

/** on conflict condition type for table "bank_accs" */
export type Bank_Accs_On_Conflict = {
  constraint: Bank_Accs_Constraint;
  update_columns: Array<Bank_Accs_Update_Column>;
  where?: Maybe<Bank_Accs_Bool_Exp>;
};

/** ordering options when selecting data from "bank_accs" */
export type Bank_Accs_Order_By = {
  account?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  primary?: Maybe<Order_By>;
};

/** primary key columns input for table: "bank_accs" */
export type Bank_Accs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "bank_accs" */
export enum Bank_Accs_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Primary = 'primary'
}

/** input type for updating data in table "bank_accs" */
export type Bank_Accs_Set_Input = {
  account?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  primary?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "bank_accs" */
export enum Bank_Accs_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Primary = 'primary'
}


/** columns and relationships of "blocks" */
export type Blocks = {
  /** An object relationship */
  calendar?: Maybe<Calendars>;
  calendar_id: Scalars['uuid'];
  capacity: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  note?: Maybe<Scalars['String']>;
  planned_from?: Maybe<Scalars['timestamptz']>;
  planned_to?: Maybe<Scalars['timestamptz']>;
  priority?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "blocks" */
export type Blocks_Aggregate = {
  aggregate?: Maybe<Blocks_Aggregate_Fields>;
  nodes: Array<Blocks>;
};

/** aggregate fields of "blocks" */
export type Blocks_Aggregate_Fields = {
  avg?: Maybe<Blocks_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Blocks_Max_Fields>;
  min?: Maybe<Blocks_Min_Fields>;
  stddev?: Maybe<Blocks_Stddev_Fields>;
  stddev_pop?: Maybe<Blocks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blocks_Stddev_Samp_Fields>;
  sum?: Maybe<Blocks_Sum_Fields>;
  var_pop?: Maybe<Blocks_Var_Pop_Fields>;
  var_samp?: Maybe<Blocks_Var_Samp_Fields>;
  variance?: Maybe<Blocks_Variance_Fields>;
};


/** aggregate fields of "blocks" */
export type Blocks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blocks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blocks" */
export type Blocks_Aggregate_Order_By = {
  avg?: Maybe<Blocks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Blocks_Max_Order_By>;
  min?: Maybe<Blocks_Min_Order_By>;
  stddev?: Maybe<Blocks_Stddev_Order_By>;
  stddev_pop?: Maybe<Blocks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Blocks_Stddev_Samp_Order_By>;
  sum?: Maybe<Blocks_Sum_Order_By>;
  var_pop?: Maybe<Blocks_Var_Pop_Order_By>;
  var_samp?: Maybe<Blocks_Var_Samp_Order_By>;
  variance?: Maybe<Blocks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "blocks" */
export type Blocks_Arr_Rel_Insert_Input = {
  data: Array<Blocks_Insert_Input>;
  on_conflict?: Maybe<Blocks_On_Conflict>;
};

/** aggregate avg on columns */
export type Blocks_Avg_Fields = {
  capacity?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "blocks" */
export type Blocks_Avg_Order_By = {
  capacity?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "blocks". All fields are combined with a logical 'AND'. */
export type Blocks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Blocks_Bool_Exp>>>;
  _not?: Maybe<Blocks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Blocks_Bool_Exp>>>;
  calendar?: Maybe<Calendars_Bool_Exp>;
  calendar_id?: Maybe<Uuid_Comparison_Exp>;
  capacity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  planned_from?: Maybe<Timestamptz_Comparison_Exp>;
  planned_to?: Maybe<Timestamptz_Comparison_Exp>;
  priority?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "blocks" */
export enum Blocks_Constraint {
  /** unique or primary key constraint */
  BlocksPkey = 'blocks_pkey'
}

/** input type for incrementing integer column in table "blocks" */
export type Blocks_Inc_Input = {
  capacity?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "blocks" */
export type Blocks_Insert_Input = {
  calendar?: Maybe<Calendars_Obj_Rel_Insert_Input>;
  calendar_id?: Maybe<Scalars['uuid']>;
  capacity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  planned_from?: Maybe<Scalars['timestamptz']>;
  planned_to?: Maybe<Scalars['timestamptz']>;
  priority?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Blocks_Max_Fields = {
  calendar_id?: Maybe<Scalars['uuid']>;
  capacity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  planned_from?: Maybe<Scalars['timestamptz']>;
  planned_to?: Maybe<Scalars['timestamptz']>;
  priority?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "blocks" */
export type Blocks_Max_Order_By = {
  calendar_id?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  planned_from?: Maybe<Order_By>;
  planned_to?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Blocks_Min_Fields = {
  calendar_id?: Maybe<Scalars['uuid']>;
  capacity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  planned_from?: Maybe<Scalars['timestamptz']>;
  planned_to?: Maybe<Scalars['timestamptz']>;
  priority?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "blocks" */
export type Blocks_Min_Order_By = {
  calendar_id?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  planned_from?: Maybe<Order_By>;
  planned_to?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** response of any mutation on the table "blocks" */
export type Blocks_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Blocks>;
};

/** input type for inserting object relation for remote table "blocks" */
export type Blocks_Obj_Rel_Insert_Input = {
  data: Blocks_Insert_Input;
  on_conflict?: Maybe<Blocks_On_Conflict>;
};

/** on conflict condition type for table "blocks" */
export type Blocks_On_Conflict = {
  constraint: Blocks_Constraint;
  update_columns: Array<Blocks_Update_Column>;
  where?: Maybe<Blocks_Bool_Exp>;
};

/** ordering options when selecting data from "blocks" */
export type Blocks_Order_By = {
  calendar?: Maybe<Calendars_Order_By>;
  calendar_id?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  planned_from?: Maybe<Order_By>;
  planned_to?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** primary key columns input for table: "blocks" */
export type Blocks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "blocks" */
export enum Blocks_Select_Column {
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  Capacity = 'capacity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  PlannedFrom = 'planned_from',
  /** column name */
  PlannedTo = 'planned_to',
  /** column name */
  Priority = 'priority'
}

/** input type for updating data in table "blocks" */
export type Blocks_Set_Input = {
  calendar_id?: Maybe<Scalars['uuid']>;
  capacity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  note?: Maybe<Scalars['String']>;
  planned_from?: Maybe<Scalars['timestamptz']>;
  planned_to?: Maybe<Scalars['timestamptz']>;
  priority?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Blocks_Stddev_Fields = {
  capacity?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "blocks" */
export type Blocks_Stddev_Order_By = {
  capacity?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Blocks_Stddev_Pop_Fields = {
  capacity?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "blocks" */
export type Blocks_Stddev_Pop_Order_By = {
  capacity?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Blocks_Stddev_Samp_Fields = {
  capacity?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "blocks" */
export type Blocks_Stddev_Samp_Order_By = {
  capacity?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Blocks_Sum_Fields = {
  capacity?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "blocks" */
export type Blocks_Sum_Order_By = {
  capacity?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** update columns of table "blocks" */
export enum Blocks_Update_Column {
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  Capacity = 'capacity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  PlannedFrom = 'planned_from',
  /** column name */
  PlannedTo = 'planned_to',
  /** column name */
  Priority = 'priority'
}

/** aggregate var_pop on columns */
export type Blocks_Var_Pop_Fields = {
  capacity?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "blocks" */
export type Blocks_Var_Pop_Order_By = {
  capacity?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Blocks_Var_Samp_Fields = {
  capacity?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "blocks" */
export type Blocks_Var_Samp_Order_By = {
  capacity?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Blocks_Variance_Fields = {
  capacity?: Maybe<Scalars['Float']>;
  priority?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "blocks" */
export type Blocks_Variance_Order_By = {
  capacity?: Maybe<Order_By>;
  priority?: Maybe<Order_By>;
};


/** expression to compare columns of type bpchar. All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: Maybe<Scalars['bpchar']>;
  _gt?: Maybe<Scalars['bpchar']>;
  _gte?: Maybe<Scalars['bpchar']>;
  _in?: Maybe<Array<Scalars['bpchar']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bpchar']>;
  _lte?: Maybe<Scalars['bpchar']>;
  _neq?: Maybe<Scalars['bpchar']>;
  _nin?: Maybe<Array<Scalars['bpchar']>>;
};

/** columns and relationships of "businesses" */
export type Businesses = {
  activity?: Maybe<Scalars['String']>;
  emails_contact?: Maybe<Scalars['String']>;
  emails_domain?: Maybe<Scalars['String']>;
  emails_other?: Maybe<Scalars['String']>;
  faxes?: Maybe<Scalars['String']>;
  ico: Scalars['String'];
  id: Scalars['uuid'];
  internet_sites?: Maybe<Scalars['String']>;
  passhash: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
};

/** aggregated selection of "businesses" */
export type Businesses_Aggregate = {
  aggregate?: Maybe<Businesses_Aggregate_Fields>;
  nodes: Array<Businesses>;
};

/** aggregate fields of "businesses" */
export type Businesses_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Businesses_Max_Fields>;
  min?: Maybe<Businesses_Min_Fields>;
};


/** aggregate fields of "businesses" */
export type Businesses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Businesses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "businesses" */
export type Businesses_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Businesses_Max_Order_By>;
  min?: Maybe<Businesses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "businesses" */
export type Businesses_Arr_Rel_Insert_Input = {
  data: Array<Businesses_Insert_Input>;
  on_conflict?: Maybe<Businesses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "businesses". All fields are combined with a logical 'AND'. */
export type Businesses_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Businesses_Bool_Exp>>>;
  _not?: Maybe<Businesses_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Businesses_Bool_Exp>>>;
  activity?: Maybe<String_Comparison_Exp>;
  emails_contact?: Maybe<String_Comparison_Exp>;
  emails_domain?: Maybe<String_Comparison_Exp>;
  emails_other?: Maybe<String_Comparison_Exp>;
  faxes?: Maybe<String_Comparison_Exp>;
  ico?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  internet_sites?: Maybe<String_Comparison_Exp>;
  passhash?: Maybe<String_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  phones?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "businesses" */
export enum Businesses_Constraint {
  /** unique or primary key constraint */
  BusinessesIcoKey = 'businesses_ico_key',
  /** unique or primary key constraint */
  BusinessesPkey = 'businesses_pkey'
}

/** input type for inserting data into table "businesses" */
export type Businesses_Insert_Input = {
  activity?: Maybe<Scalars['String']>;
  emails_contact?: Maybe<Scalars['String']>;
  emails_domain?: Maybe<Scalars['String']>;
  emails_other?: Maybe<Scalars['String']>;
  faxes?: Maybe<Scalars['String']>;
  ico?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internet_sites?: Maybe<Scalars['String']>;
  passhash?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Businesses_Max_Fields = {
  activity?: Maybe<Scalars['String']>;
  emails_contact?: Maybe<Scalars['String']>;
  emails_domain?: Maybe<Scalars['String']>;
  emails_other?: Maybe<Scalars['String']>;
  faxes?: Maybe<Scalars['String']>;
  ico?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internet_sites?: Maybe<Scalars['String']>;
  passhash?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "businesses" */
export type Businesses_Max_Order_By = {
  activity?: Maybe<Order_By>;
  emails_contact?: Maybe<Order_By>;
  emails_domain?: Maybe<Order_By>;
  emails_other?: Maybe<Order_By>;
  faxes?: Maybe<Order_By>;
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internet_sites?: Maybe<Order_By>;
  passhash?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phones?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Businesses_Min_Fields = {
  activity?: Maybe<Scalars['String']>;
  emails_contact?: Maybe<Scalars['String']>;
  emails_domain?: Maybe<Scalars['String']>;
  emails_other?: Maybe<Scalars['String']>;
  faxes?: Maybe<Scalars['String']>;
  ico?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internet_sites?: Maybe<Scalars['String']>;
  passhash?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "businesses" */
export type Businesses_Min_Order_By = {
  activity?: Maybe<Order_By>;
  emails_contact?: Maybe<Order_By>;
  emails_domain?: Maybe<Order_By>;
  emails_other?: Maybe<Order_By>;
  faxes?: Maybe<Order_By>;
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internet_sites?: Maybe<Order_By>;
  passhash?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phones?: Maybe<Order_By>;
};

/** response of any mutation on the table "businesses" */
export type Businesses_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Businesses>;
};

/** input type for inserting object relation for remote table "businesses" */
export type Businesses_Obj_Rel_Insert_Input = {
  data: Businesses_Insert_Input;
  on_conflict?: Maybe<Businesses_On_Conflict>;
};

/** on conflict condition type for table "businesses" */
export type Businesses_On_Conflict = {
  constraint: Businesses_Constraint;
  update_columns: Array<Businesses_Update_Column>;
  where?: Maybe<Businesses_Bool_Exp>;
};

/** ordering options when selecting data from "businesses" */
export type Businesses_Order_By = {
  activity?: Maybe<Order_By>;
  emails_contact?: Maybe<Order_By>;
  emails_domain?: Maybe<Order_By>;
  emails_other?: Maybe<Order_By>;
  faxes?: Maybe<Order_By>;
  ico?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internet_sites?: Maybe<Order_By>;
  passhash?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phones?: Maybe<Order_By>;
};

/** primary key columns input for table: "businesses" */
export type Businesses_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "businesses" */
export enum Businesses_Select_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  EmailsContact = 'emails_contact',
  /** column name */
  EmailsDomain = 'emails_domain',
  /** column name */
  EmailsOther = 'emails_other',
  /** column name */
  Faxes = 'faxes',
  /** column name */
  Ico = 'ico',
  /** column name */
  Id = 'id',
  /** column name */
  InternetSites = 'internet_sites',
  /** column name */
  Passhash = 'passhash',
  /** column name */
  Password = 'password',
  /** column name */
  Phones = 'phones'
}

/** input type for updating data in table "businesses" */
export type Businesses_Set_Input = {
  activity?: Maybe<Scalars['String']>;
  emails_contact?: Maybe<Scalars['String']>;
  emails_domain?: Maybe<Scalars['String']>;
  emails_other?: Maybe<Scalars['String']>;
  faxes?: Maybe<Scalars['String']>;
  ico?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  internet_sites?: Maybe<Scalars['String']>;
  passhash?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phones?: Maybe<Scalars['String']>;
};

/** update columns of table "businesses" */
export enum Businesses_Update_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  EmailsContact = 'emails_contact',
  /** column name */
  EmailsDomain = 'emails_domain',
  /** column name */
  EmailsOther = 'emails_other',
  /** column name */
  Faxes = 'faxes',
  /** column name */
  Ico = 'ico',
  /** column name */
  Id = 'id',
  /** column name */
  InternetSites = 'internet_sites',
  /** column name */
  Passhash = 'passhash',
  /** column name */
  Password = 'password',
  /** column name */
  Phones = 'phones'
}

/** columns and relationships of "calendars" */
export type Calendars = {
  acceptConfirmations: Scalars['Boolean'];
  apiKey?: Maybe<Scalars['String']>;
  autoCancelPeriod: Scalars['numeric'];
  capacity: Scalars['numeric'];
  capacityPlanning: Scalars['Boolean'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  earliest: Scalars['numeric'];
  establishment_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  intervalSize: Scalars['numeric'];
  latest: Scalars['numeric'];
  manualApproval: Scalars['Boolean'];
  multipleServices: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  notifyBoolean: Scalars['Boolean'];
  notifyPeriod: Scalars['Int'];
  notifyText?: Maybe<Scalars['String']>;
  repeatBoolean: Scalars['Boolean'];
  repeatPeriod: Scalars['Int'];
  thankBoolean: Scalars['Boolean'];
  thankPeriod: Scalars['Int'];
  thankText?: Maybe<Scalars['String']>;
};

/** aggregated selection of "calendars" */
export type Calendars_Aggregate = {
  aggregate?: Maybe<Calendars_Aggregate_Fields>;
  nodes: Array<Calendars>;
};

/** aggregate fields of "calendars" */
export type Calendars_Aggregate_Fields = {
  avg?: Maybe<Calendars_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Calendars_Max_Fields>;
  min?: Maybe<Calendars_Min_Fields>;
  stddev?: Maybe<Calendars_Stddev_Fields>;
  stddev_pop?: Maybe<Calendars_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Calendars_Stddev_Samp_Fields>;
  sum?: Maybe<Calendars_Sum_Fields>;
  var_pop?: Maybe<Calendars_Var_Pop_Fields>;
  var_samp?: Maybe<Calendars_Var_Samp_Fields>;
  variance?: Maybe<Calendars_Variance_Fields>;
};


/** aggregate fields of "calendars" */
export type Calendars_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Calendars_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendars" */
export type Calendars_Aggregate_Order_By = {
  avg?: Maybe<Calendars_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Calendars_Max_Order_By>;
  min?: Maybe<Calendars_Min_Order_By>;
  stddev?: Maybe<Calendars_Stddev_Order_By>;
  stddev_pop?: Maybe<Calendars_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Calendars_Stddev_Samp_Order_By>;
  sum?: Maybe<Calendars_Sum_Order_By>;
  var_pop?: Maybe<Calendars_Var_Pop_Order_By>;
  var_samp?: Maybe<Calendars_Var_Samp_Order_By>;
  variance?: Maybe<Calendars_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "calendars" */
export type Calendars_Arr_Rel_Insert_Input = {
  data: Array<Calendars_Insert_Input>;
  on_conflict?: Maybe<Calendars_On_Conflict>;
};

/** aggregate avg on columns */
export type Calendars_Avg_Fields = {
  autoCancelPeriod?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Float']>;
  earliest?: Maybe<Scalars['Float']>;
  intervalSize?: Maybe<Scalars['Float']>;
  latest?: Maybe<Scalars['Float']>;
  notifyPeriod?: Maybe<Scalars['Float']>;
  repeatPeriod?: Maybe<Scalars['Float']>;
  thankPeriod?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "calendars" */
export type Calendars_Avg_Order_By = {
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "calendars". All fields are combined with a logical 'AND'. */
export type Calendars_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Calendars_Bool_Exp>>>;
  _not?: Maybe<Calendars_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Calendars_Bool_Exp>>>;
  acceptConfirmations?: Maybe<Boolean_Comparison_Exp>;
  apiKey?: Maybe<String_Comparison_Exp>;
  autoCancelPeriod?: Maybe<Numeric_Comparison_Exp>;
  capacity?: Maybe<Numeric_Comparison_Exp>;
  capacityPlanning?: Maybe<Boolean_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  earliest?: Maybe<Numeric_Comparison_Exp>;
  establishment_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  intervalSize?: Maybe<Numeric_Comparison_Exp>;
  latest?: Maybe<Numeric_Comparison_Exp>;
  manualApproval?: Maybe<Boolean_Comparison_Exp>;
  multipleServices?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notifyBoolean?: Maybe<Boolean_Comparison_Exp>;
  notifyPeriod?: Maybe<Int_Comparison_Exp>;
  notifyText?: Maybe<String_Comparison_Exp>;
  repeatBoolean?: Maybe<Boolean_Comparison_Exp>;
  repeatPeriod?: Maybe<Int_Comparison_Exp>;
  thankBoolean?: Maybe<Boolean_Comparison_Exp>;
  thankPeriod?: Maybe<Int_Comparison_Exp>;
  thankText?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendars" */
export enum Calendars_Constraint {
  /** unique or primary key constraint */
  CalendarsPkey = 'calendars_pkey'
}

/** columns and relationships of "calendars_custom_fields" */
export type Calendars_Custom_Fields = {
  /** An object relationship */
  calendar: Calendars;
  calendar_id: Scalars['uuid'];
  /** An object relationship */
  custom_field: Custom_Fields;
  custom_field_id: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** aggregated selection of "calendars_custom_fields" */
export type Calendars_Custom_Fields_Aggregate = {
  aggregate?: Maybe<Calendars_Custom_Fields_Aggregate_Fields>;
  nodes: Array<Calendars_Custom_Fields>;
};

/** aggregate fields of "calendars_custom_fields" */
export type Calendars_Custom_Fields_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Calendars_Custom_Fields_Max_Fields>;
  min?: Maybe<Calendars_Custom_Fields_Min_Fields>;
};


/** aggregate fields of "calendars_custom_fields" */
export type Calendars_Custom_Fields_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Calendars_Custom_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendars_custom_fields" */
export type Calendars_Custom_Fields_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Calendars_Custom_Fields_Max_Order_By>;
  min?: Maybe<Calendars_Custom_Fields_Min_Order_By>;
};

/** input type for inserting array relation for remote table "calendars_custom_fields" */
export type Calendars_Custom_Fields_Arr_Rel_Insert_Input = {
  data: Array<Calendars_Custom_Fields_Insert_Input>;
  on_conflict?: Maybe<Calendars_Custom_Fields_On_Conflict>;
};

/** Boolean expression to filter rows from the table "calendars_custom_fields". All fields are combined with a logical 'AND'. */
export type Calendars_Custom_Fields_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Calendars_Custom_Fields_Bool_Exp>>>;
  _not?: Maybe<Calendars_Custom_Fields_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Calendars_Custom_Fields_Bool_Exp>>>;
  calendar?: Maybe<Calendars_Bool_Exp>;
  calendar_id?: Maybe<Uuid_Comparison_Exp>;
  custom_field?: Maybe<Custom_Fields_Bool_Exp>;
  custom_field_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendars_custom_fields" */
export enum Calendars_Custom_Fields_Constraint {
  /** unique or primary key constraint */
  CalendarsCustomFieldsPkey = 'calendars_custom_fields_pkey'
}

/** input type for inserting data into table "calendars_custom_fields" */
export type Calendars_Custom_Fields_Insert_Input = {
  calendar?: Maybe<Calendars_Obj_Rel_Insert_Input>;
  calendar_id?: Maybe<Scalars['uuid']>;
  custom_field?: Maybe<Custom_Fields_Obj_Rel_Insert_Input>;
  custom_field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Calendars_Custom_Fields_Max_Fields = {
  calendar_id?: Maybe<Scalars['uuid']>;
  custom_field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "calendars_custom_fields" */
export type Calendars_Custom_Fields_Max_Order_By = {
  calendar_id?: Maybe<Order_By>;
  custom_field_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Calendars_Custom_Fields_Min_Fields = {
  calendar_id?: Maybe<Scalars['uuid']>;
  custom_field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "calendars_custom_fields" */
export type Calendars_Custom_Fields_Min_Order_By = {
  calendar_id?: Maybe<Order_By>;
  custom_field_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "calendars_custom_fields" */
export type Calendars_Custom_Fields_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Calendars_Custom_Fields>;
};

/** input type for inserting object relation for remote table "calendars_custom_fields" */
export type Calendars_Custom_Fields_Obj_Rel_Insert_Input = {
  data: Calendars_Custom_Fields_Insert_Input;
  on_conflict?: Maybe<Calendars_Custom_Fields_On_Conflict>;
};

/** on conflict condition type for table "calendars_custom_fields" */
export type Calendars_Custom_Fields_On_Conflict = {
  constraint: Calendars_Custom_Fields_Constraint;
  update_columns: Array<Calendars_Custom_Fields_Update_Column>;
  where?: Maybe<Calendars_Custom_Fields_Bool_Exp>;
};

/** ordering options when selecting data from "calendars_custom_fields" */
export type Calendars_Custom_Fields_Order_By = {
  calendar?: Maybe<Calendars_Order_By>;
  calendar_id?: Maybe<Order_By>;
  custom_field?: Maybe<Custom_Fields_Order_By>;
  custom_field_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "calendars_custom_fields" */
export type Calendars_Custom_Fields_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "calendars_custom_fields" */
export enum Calendars_Custom_Fields_Select_Column {
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CustomFieldId = 'custom_field_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "calendars_custom_fields" */
export type Calendars_Custom_Fields_Set_Input = {
  calendar_id?: Maybe<Scalars['uuid']>;
  custom_field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "calendars_custom_fields" */
export enum Calendars_Custom_Fields_Update_Column {
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CustomFieldId = 'custom_field_id',
  /** column name */
  Id = 'id'
}

/** input type for incrementing integer column in table "calendars" */
export type Calendars_Inc_Input = {
  autoCancelPeriod?: Maybe<Scalars['numeric']>;
  capacity?: Maybe<Scalars['numeric']>;
  earliest?: Maybe<Scalars['numeric']>;
  intervalSize?: Maybe<Scalars['numeric']>;
  latest?: Maybe<Scalars['numeric']>;
  notifyPeriod?: Maybe<Scalars['Int']>;
  repeatPeriod?: Maybe<Scalars['Int']>;
  thankPeriod?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "calendars" */
export type Calendars_Insert_Input = {
  acceptConfirmations?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  autoCancelPeriod?: Maybe<Scalars['numeric']>;
  capacity?: Maybe<Scalars['numeric']>;
  capacityPlanning?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  earliest?: Maybe<Scalars['numeric']>;
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  intervalSize?: Maybe<Scalars['numeric']>;
  latest?: Maybe<Scalars['numeric']>;
  manualApproval?: Maybe<Scalars['Boolean']>;
  multipleServices?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notifyBoolean?: Maybe<Scalars['Boolean']>;
  notifyPeriod?: Maybe<Scalars['Int']>;
  notifyText?: Maybe<Scalars['String']>;
  repeatBoolean?: Maybe<Scalars['Boolean']>;
  repeatPeriod?: Maybe<Scalars['Int']>;
  thankBoolean?: Maybe<Scalars['Boolean']>;
  thankPeriod?: Maybe<Scalars['Int']>;
  thankText?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Calendars_Max_Fields = {
  apiKey?: Maybe<Scalars['String']>;
  autoCancelPeriod?: Maybe<Scalars['numeric']>;
  capacity?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['uuid']>;
  earliest?: Maybe<Scalars['numeric']>;
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  intervalSize?: Maybe<Scalars['numeric']>;
  latest?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  notifyPeriod?: Maybe<Scalars['Int']>;
  notifyText?: Maybe<Scalars['String']>;
  repeatPeriod?: Maybe<Scalars['Int']>;
  thankPeriod?: Maybe<Scalars['Int']>;
  thankText?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "calendars" */
export type Calendars_Max_Order_By = {
  apiKey?: Maybe<Order_By>;
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  notifyText?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
  thankText?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Calendars_Min_Fields = {
  apiKey?: Maybe<Scalars['String']>;
  autoCancelPeriod?: Maybe<Scalars['numeric']>;
  capacity?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['uuid']>;
  earliest?: Maybe<Scalars['numeric']>;
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  intervalSize?: Maybe<Scalars['numeric']>;
  latest?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  notifyPeriod?: Maybe<Scalars['Int']>;
  notifyText?: Maybe<Scalars['String']>;
  repeatPeriod?: Maybe<Scalars['Int']>;
  thankPeriod?: Maybe<Scalars['Int']>;
  thankText?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "calendars" */
export type Calendars_Min_Order_By = {
  apiKey?: Maybe<Order_By>;
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  notifyText?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
  thankText?: Maybe<Order_By>;
};

/** response of any mutation on the table "calendars" */
export type Calendars_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Calendars>;
};

/** input type for inserting object relation for remote table "calendars" */
export type Calendars_Obj_Rel_Insert_Input = {
  data: Calendars_Insert_Input;
  on_conflict?: Maybe<Calendars_On_Conflict>;
};

/** on conflict condition type for table "calendars" */
export type Calendars_On_Conflict = {
  constraint: Calendars_Constraint;
  update_columns: Array<Calendars_Update_Column>;
  where?: Maybe<Calendars_Bool_Exp>;
};

/** ordering options when selecting data from "calendars" */
export type Calendars_Order_By = {
  acceptConfirmations?: Maybe<Order_By>;
  apiKey?: Maybe<Order_By>;
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  capacityPlanning?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  manualApproval?: Maybe<Order_By>;
  multipleServices?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notifyBoolean?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  notifyText?: Maybe<Order_By>;
  repeatBoolean?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankBoolean?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
  thankText?: Maybe<Order_By>;
};

/** primary key columns input for table: "calendars" */
export type Calendars_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "calendars" */
export enum Calendars_Select_Column {
  /** column name */
  AcceptConfirmations = 'acceptConfirmations',
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  AutoCancelPeriod = 'autoCancelPeriod',
  /** column name */
  Capacity = 'capacity',
  /** column name */
  CapacityPlanning = 'capacityPlanning',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Earliest = 'earliest',
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntervalSize = 'intervalSize',
  /** column name */
  Latest = 'latest',
  /** column name */
  ManualApproval = 'manualApproval',
  /** column name */
  MultipleServices = 'multipleServices',
  /** column name */
  Name = 'name',
  /** column name */
  NotifyBoolean = 'notifyBoolean',
  /** column name */
  NotifyPeriod = 'notifyPeriod',
  /** column name */
  NotifyText = 'notifyText',
  /** column name */
  RepeatBoolean = 'repeatBoolean',
  /** column name */
  RepeatPeriod = 'repeatPeriod',
  /** column name */
  ThankBoolean = 'thankBoolean',
  /** column name */
  ThankPeriod = 'thankPeriod',
  /** column name */
  ThankText = 'thankText'
}

/** columns and relationships of "calendars_services" */
export type Calendars_Services = {
  /** An object relationship */
  calendar: Calendars;
  calendar_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  service: Services;
  service_id: Scalars['uuid'];
};

/** aggregated selection of "calendars_services" */
export type Calendars_Services_Aggregate = {
  aggregate?: Maybe<Calendars_Services_Aggregate_Fields>;
  nodes: Array<Calendars_Services>;
};

/** aggregate fields of "calendars_services" */
export type Calendars_Services_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Calendars_Services_Max_Fields>;
  min?: Maybe<Calendars_Services_Min_Fields>;
};


/** aggregate fields of "calendars_services" */
export type Calendars_Services_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Calendars_Services_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calendars_services" */
export type Calendars_Services_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Calendars_Services_Max_Order_By>;
  min?: Maybe<Calendars_Services_Min_Order_By>;
};

/** input type for inserting array relation for remote table "calendars_services" */
export type Calendars_Services_Arr_Rel_Insert_Input = {
  data: Array<Calendars_Services_Insert_Input>;
  on_conflict?: Maybe<Calendars_Services_On_Conflict>;
};

/** Boolean expression to filter rows from the table "calendars_services". All fields are combined with a logical 'AND'. */
export type Calendars_Services_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Calendars_Services_Bool_Exp>>>;
  _not?: Maybe<Calendars_Services_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Calendars_Services_Bool_Exp>>>;
  calendar?: Maybe<Calendars_Bool_Exp>;
  calendar_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  service?: Maybe<Services_Bool_Exp>;
  service_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "calendars_services" */
export enum Calendars_Services_Constraint {
  /** unique or primary key constraint */
  CalendarsServicesPkey = 'calendars_services_pkey'
}

/** input type for inserting data into table "calendars_services" */
export type Calendars_Services_Insert_Input = {
  calendar?: Maybe<Calendars_Obj_Rel_Insert_Input>;
  calendar_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  service?: Maybe<Services_Obj_Rel_Insert_Input>;
  service_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Calendars_Services_Max_Fields = {
  calendar_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  service_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "calendars_services" */
export type Calendars_Services_Max_Order_By = {
  calendar_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Calendars_Services_Min_Fields = {
  calendar_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  service_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "calendars_services" */
export type Calendars_Services_Min_Order_By = {
  calendar_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "calendars_services" */
export type Calendars_Services_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Calendars_Services>;
};

/** input type for inserting object relation for remote table "calendars_services" */
export type Calendars_Services_Obj_Rel_Insert_Input = {
  data: Calendars_Services_Insert_Input;
  on_conflict?: Maybe<Calendars_Services_On_Conflict>;
};

/** on conflict condition type for table "calendars_services" */
export type Calendars_Services_On_Conflict = {
  constraint: Calendars_Services_Constraint;
  update_columns: Array<Calendars_Services_Update_Column>;
  where?: Maybe<Calendars_Services_Bool_Exp>;
};

/** ordering options when selecting data from "calendars_services" */
export type Calendars_Services_Order_By = {
  calendar?: Maybe<Calendars_Order_By>;
  calendar_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service?: Maybe<Services_Order_By>;
  service_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "calendars_services" */
export type Calendars_Services_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "calendars_services" */
export enum Calendars_Services_Select_Column {
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id'
}

/** input type for updating data in table "calendars_services" */
export type Calendars_Services_Set_Input = {
  calendar_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  service_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "calendars_services" */
export enum Calendars_Services_Update_Column {
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceId = 'service_id'
}

/** input type for updating data in table "calendars" */
export type Calendars_Set_Input = {
  acceptConfirmations?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  autoCancelPeriod?: Maybe<Scalars['numeric']>;
  capacity?: Maybe<Scalars['numeric']>;
  capacityPlanning?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  earliest?: Maybe<Scalars['numeric']>;
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  intervalSize?: Maybe<Scalars['numeric']>;
  latest?: Maybe<Scalars['numeric']>;
  manualApproval?: Maybe<Scalars['Boolean']>;
  multipleServices?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notifyBoolean?: Maybe<Scalars['Boolean']>;
  notifyPeriod?: Maybe<Scalars['Int']>;
  notifyText?: Maybe<Scalars['String']>;
  repeatBoolean?: Maybe<Scalars['Boolean']>;
  repeatPeriod?: Maybe<Scalars['Int']>;
  thankBoolean?: Maybe<Scalars['Boolean']>;
  thankPeriod?: Maybe<Scalars['Int']>;
  thankText?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Calendars_Stddev_Fields = {
  autoCancelPeriod?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Float']>;
  earliest?: Maybe<Scalars['Float']>;
  intervalSize?: Maybe<Scalars['Float']>;
  latest?: Maybe<Scalars['Float']>;
  notifyPeriod?: Maybe<Scalars['Float']>;
  repeatPeriod?: Maybe<Scalars['Float']>;
  thankPeriod?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "calendars" */
export type Calendars_Stddev_Order_By = {
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Calendars_Stddev_Pop_Fields = {
  autoCancelPeriod?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Float']>;
  earliest?: Maybe<Scalars['Float']>;
  intervalSize?: Maybe<Scalars['Float']>;
  latest?: Maybe<Scalars['Float']>;
  notifyPeriod?: Maybe<Scalars['Float']>;
  repeatPeriod?: Maybe<Scalars['Float']>;
  thankPeriod?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "calendars" */
export type Calendars_Stddev_Pop_Order_By = {
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Calendars_Stddev_Samp_Fields = {
  autoCancelPeriod?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Float']>;
  earliest?: Maybe<Scalars['Float']>;
  intervalSize?: Maybe<Scalars['Float']>;
  latest?: Maybe<Scalars['Float']>;
  notifyPeriod?: Maybe<Scalars['Float']>;
  repeatPeriod?: Maybe<Scalars['Float']>;
  thankPeriod?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "calendars" */
export type Calendars_Stddev_Samp_Order_By = {
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Calendars_Sum_Fields = {
  autoCancelPeriod?: Maybe<Scalars['numeric']>;
  capacity?: Maybe<Scalars['numeric']>;
  earliest?: Maybe<Scalars['numeric']>;
  intervalSize?: Maybe<Scalars['numeric']>;
  latest?: Maybe<Scalars['numeric']>;
  notifyPeriod?: Maybe<Scalars['Int']>;
  repeatPeriod?: Maybe<Scalars['Int']>;
  thankPeriod?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "calendars" */
export type Calendars_Sum_Order_By = {
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
};

/** update columns of table "calendars" */
export enum Calendars_Update_Column {
  /** column name */
  AcceptConfirmations = 'acceptConfirmations',
  /** column name */
  ApiKey = 'apiKey',
  /** column name */
  AutoCancelPeriod = 'autoCancelPeriod',
  /** column name */
  Capacity = 'capacity',
  /** column name */
  CapacityPlanning = 'capacityPlanning',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Earliest = 'earliest',
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  Id = 'id',
  /** column name */
  IntervalSize = 'intervalSize',
  /** column name */
  Latest = 'latest',
  /** column name */
  ManualApproval = 'manualApproval',
  /** column name */
  MultipleServices = 'multipleServices',
  /** column name */
  Name = 'name',
  /** column name */
  NotifyBoolean = 'notifyBoolean',
  /** column name */
  NotifyPeriod = 'notifyPeriod',
  /** column name */
  NotifyText = 'notifyText',
  /** column name */
  RepeatBoolean = 'repeatBoolean',
  /** column name */
  RepeatPeriod = 'repeatPeriod',
  /** column name */
  ThankBoolean = 'thankBoolean',
  /** column name */
  ThankPeriod = 'thankPeriod',
  /** column name */
  ThankText = 'thankText'
}

/** aggregate var_pop on columns */
export type Calendars_Var_Pop_Fields = {
  autoCancelPeriod?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Float']>;
  earliest?: Maybe<Scalars['Float']>;
  intervalSize?: Maybe<Scalars['Float']>;
  latest?: Maybe<Scalars['Float']>;
  notifyPeriod?: Maybe<Scalars['Float']>;
  repeatPeriod?: Maybe<Scalars['Float']>;
  thankPeriod?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "calendars" */
export type Calendars_Var_Pop_Order_By = {
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Calendars_Var_Samp_Fields = {
  autoCancelPeriod?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Float']>;
  earliest?: Maybe<Scalars['Float']>;
  intervalSize?: Maybe<Scalars['Float']>;
  latest?: Maybe<Scalars['Float']>;
  notifyPeriod?: Maybe<Scalars['Float']>;
  repeatPeriod?: Maybe<Scalars['Float']>;
  thankPeriod?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "calendars" */
export type Calendars_Var_Samp_Order_By = {
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Calendars_Variance_Fields = {
  autoCancelPeriod?: Maybe<Scalars['Float']>;
  capacity?: Maybe<Scalars['Float']>;
  earliest?: Maybe<Scalars['Float']>;
  intervalSize?: Maybe<Scalars['Float']>;
  latest?: Maybe<Scalars['Float']>;
  notifyPeriod?: Maybe<Scalars['Float']>;
  repeatPeriod?: Maybe<Scalars['Float']>;
  thankPeriod?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "calendars" */
export type Calendars_Variance_Order_By = {
  autoCancelPeriod?: Maybe<Order_By>;
  capacity?: Maybe<Order_By>;
  earliest?: Maybe<Order_By>;
  intervalSize?: Maybe<Order_By>;
  latest?: Maybe<Order_By>;
  notifyPeriod?: Maybe<Order_By>;
  repeatPeriod?: Maybe<Order_By>;
  thankPeriod?: Maybe<Order_By>;
};

/** columns and relationships of "categories" */
export type Categories = {
  /** An array relationship */
  companies_categories: Array<Companies_Categories>;
  /** An aggregated array relationship */
  companies_categories_aggregate: Companies_Categories_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  parent?: Maybe<Categories>;
  parent_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "categories" */
export type CategoriesCompanies_CategoriesArgs = {
  distinct_on?: Maybe<Array<Companies_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Categories_Order_By>>;
  where?: Maybe<Companies_Categories_Bool_Exp>;
};


/** columns and relationships of "categories" */
export type CategoriesCompanies_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Categories_Order_By>>;
  where?: Maybe<Companies_Categories_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  aggregate?: Maybe<Categories_Aggregate_Fields>;
  nodes: Array<Categories>;
};

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Categories_Max_Fields>;
  min?: Maybe<Categories_Min_Fields>;
};


/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Categories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "categories" */
export type Categories_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Categories_Max_Order_By>;
  min?: Maybe<Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "categories" */
export type Categories_Arr_Rel_Insert_Input = {
  data: Array<Categories_Insert_Input>;
  on_conflict?: Maybe<Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Categories_Bool_Exp>>>;
  _not?: Maybe<Categories_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Categories_Bool_Exp>>>;
  companies_categories?: Maybe<Companies_Categories_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  parent?: Maybe<Categories_Bool_Exp>;
  parent_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint */
  CategoriesNameKey = 'categories_name_key',
  /** unique or primary key constraint */
  CategoriesPkey = 'categories_pkey'
}

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  companies_categories?: Maybe<Companies_Categories_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Categories_Obj_Rel_Insert_Input>;
  parent_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Categories_Max_Fields = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "categories" */
export type Categories_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Categories_Min_Fields = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "categories" */
export type Categories_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input;
  on_conflict?: Maybe<Categories_On_Conflict>;
};

/** on conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns: Array<Categories_Update_Column>;
  where?: Maybe<Categories_Bool_Exp>;
};

/** ordering options when selecting data from "categories" */
export type Categories_Order_By = {
  companies_categories_aggregate?: Maybe<Companies_Categories_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  parent?: Maybe<Categories_Order_By>;
  parent_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "categories" */
export type Categories_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id'
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id'
}

/** columns and relationships of "companies" */
export type Companies = {
  active: Scalars['Boolean'];
  address_city: Scalars['String'];
  address_postcode: Scalars['String'];
  address_street: Scalars['String'];
  affiliate?: Maybe<Scalars['String']>;
  /** An array relationship */
  bank_accs: Array<Bank_Accs>;
  /** An aggregated array relationship */
  bank_accs_aggregate: Bank_Accs_Aggregate;
  /** An array relationship */
  companies_categories: Array<Companies_Categories>;
  /** An aggregated array relationship */
  companies_categories_aggregate: Companies_Categories_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dic?: Maybe<Scalars['String']>;
  /** An array relationship */
  establishments: Array<Establishments>;
  /** An aggregated array relationship */
  establishments_aggregate: Establishments_Aggregate;
  id: Scalars['uuid'];
  logo_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  passhash?: Maybe<Scalars['String']>;
  /** An array relationship */
  payments: Array<Payments>;
  /** An aggregated array relationship */
  payments_aggregate: Payments_Aggregate;
  /** An object relationship */
  phone_numbers?: Maybe<Phone_Numbers>;
  /** An array relationship */
  phone_numbers_list: Array<Phone_Numbers>;
  /** An aggregated array relationship */
  phone_numbers_list_aggregate: Phone_Numbers_Aggregate;
  /** An array relationship */
  pricelists: Array<Pricelists>;
  /** An aggregated array relationship */
  pricelists_aggregate: Pricelists_Aggregate;
  published: Scalars['Boolean'];
  /** An object relationship */
  region: Regions;
  region_code: Scalars['String'];
  /** An array relationship */
  roles: Array<Roles>;
  /** An aggregated array relationship */
  roles_aggregate: Roles_Aggregate;
  short_description?: Maybe<Scalars['String']>;
  /** An array relationship */
  smsapi_templates: Array<Smsapi_Templates>;
  /** An aggregated array relationship */
  smsapi_templates_aggregate: Smsapi_Templates_Aggregate;
  /** An array relationship */
  users: Array<Users>;
  /** An aggregated array relationship */
  users_aggregate: Users_Aggregate;
  vat_id: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "companies" */
export type CompaniesBank_AccsArgs = {
  distinct_on?: Maybe<Array<Bank_Accs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Accs_Order_By>>;
  where?: Maybe<Bank_Accs_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesBank_Accs_AggregateArgs = {
  distinct_on?: Maybe<Array<Bank_Accs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Accs_Order_By>>;
  where?: Maybe<Bank_Accs_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesCompanies_CategoriesArgs = {
  distinct_on?: Maybe<Array<Companies_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Categories_Order_By>>;
  where?: Maybe<Companies_Categories_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesCompanies_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Categories_Order_By>>;
  where?: Maybe<Companies_Categories_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesEstablishmentsArgs = {
  distinct_on?: Maybe<Array<Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Order_By>>;
  where?: Maybe<Establishments_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesEstablishments_AggregateArgs = {
  distinct_on?: Maybe<Array<Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Order_By>>;
  where?: Maybe<Establishments_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesPhone_Numbers_ListArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Order_By>>;
  where?: Maybe<Phone_Numbers_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesPhone_Numbers_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Order_By>>;
  where?: Maybe<Phone_Numbers_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesPricelistsArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesPricelists_AggregateArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesRolesArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesRoles_AggregateArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesSmsapi_TemplatesArgs = {
  distinct_on?: Maybe<Array<Smsapi_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Templates_Order_By>>;
  where?: Maybe<Smsapi_Templates_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesSmsapi_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Templates_Order_By>>;
  where?: Maybe<Smsapi_Templates_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "companies" */
export type Companies_Aggregate = {
  aggregate?: Maybe<Companies_Aggregate_Fields>;
  nodes: Array<Companies>;
};

/** aggregate fields of "companies" */
export type Companies_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Companies_Max_Fields>;
  min?: Maybe<Companies_Min_Fields>;
};


/** aggregate fields of "companies" */
export type Companies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Companies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "companies" */
export type Companies_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Companies_Max_Order_By>;
  min?: Maybe<Companies_Min_Order_By>;
};

/** input type for inserting array relation for remote table "companies" */
export type Companies_Arr_Rel_Insert_Input = {
  data: Array<Companies_Insert_Input>;
  on_conflict?: Maybe<Companies_On_Conflict>;
};

/** Boolean expression to filter rows from the table "companies". All fields are combined with a logical 'AND'. */
export type Companies_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Companies_Bool_Exp>>>;
  _not?: Maybe<Companies_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Companies_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  address_city?: Maybe<String_Comparison_Exp>;
  address_postcode?: Maybe<String_Comparison_Exp>;
  address_street?: Maybe<String_Comparison_Exp>;
  affiliate?: Maybe<String_Comparison_Exp>;
  bank_accs?: Maybe<Bank_Accs_Bool_Exp>;
  companies_categories?: Maybe<Companies_Categories_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  dic?: Maybe<String_Comparison_Exp>;
  establishments?: Maybe<Establishments_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logo_url?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  passhash?: Maybe<String_Comparison_Exp>;
  payments?: Maybe<Payments_Bool_Exp>;
  phone_numbers?: Maybe<Phone_Numbers_Bool_Exp>;
  phone_numbers_list?: Maybe<Phone_Numbers_Bool_Exp>;
  pricelists?: Maybe<Pricelists_Bool_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
  region?: Maybe<Regions_Bool_Exp>;
  region_code?: Maybe<String_Comparison_Exp>;
  roles?: Maybe<Roles_Bool_Exp>;
  short_description?: Maybe<String_Comparison_Exp>;
  smsapi_templates?: Maybe<Smsapi_Templates_Bool_Exp>;
  users?: Maybe<Users_Bool_Exp>;
  vat_id?: Maybe<String_Comparison_Exp>;
  website?: Maybe<String_Comparison_Exp>;
};

/**
 * >>
 * 
 * 
 * columns and relationships of "companies_categories"
 */
export type Companies_Categories = {
  /** An object relationship */
  category: Categories;
  category_id: Scalars['uuid'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** aggregated selection of "companies_categories" */
export type Companies_Categories_Aggregate = {
  aggregate?: Maybe<Companies_Categories_Aggregate_Fields>;
  nodes: Array<Companies_Categories>;
};

/** aggregate fields of "companies_categories" */
export type Companies_Categories_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Companies_Categories_Max_Fields>;
  min?: Maybe<Companies_Categories_Min_Fields>;
};


/** aggregate fields of "companies_categories" */
export type Companies_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Companies_Categories_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "companies_categories" */
export type Companies_Categories_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Companies_Categories_Max_Order_By>;
  min?: Maybe<Companies_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "companies_categories" */
export type Companies_Categories_Arr_Rel_Insert_Input = {
  data: Array<Companies_Categories_Insert_Input>;
  on_conflict?: Maybe<Companies_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "companies_categories". All fields are combined with a logical 'AND'. */
export type Companies_Categories_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Companies_Categories_Bool_Exp>>>;
  _not?: Maybe<Companies_Categories_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Companies_Categories_Bool_Exp>>>;
  category?: Maybe<Categories_Bool_Exp>;
  category_id?: Maybe<Uuid_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "companies_categories" */
export enum Companies_Categories_Constraint {
  /** unique or primary key constraint */
  CompaniesCategoriesIdKey = 'companies_categories_id_key',
  /** unique or primary key constraint */
  CompaniesCategoriesPkey = 'companies_categories_pkey'
}

/** input type for inserting data into table "companies_categories" */
export type Companies_Categories_Insert_Input = {
  category?: Maybe<Categories_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['uuid']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Companies_Categories_Max_Fields = {
  category_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "companies_categories" */
export type Companies_Categories_Max_Order_By = {
  category_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Companies_Categories_Min_Fields = {
  category_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "companies_categories" */
export type Companies_Categories_Min_Order_By = {
  category_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "companies_categories" */
export type Companies_Categories_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Companies_Categories>;
};

/** input type for inserting object relation for remote table "companies_categories" */
export type Companies_Categories_Obj_Rel_Insert_Input = {
  data: Companies_Categories_Insert_Input;
  on_conflict?: Maybe<Companies_Categories_On_Conflict>;
};

/** on conflict condition type for table "companies_categories" */
export type Companies_Categories_On_Conflict = {
  constraint: Companies_Categories_Constraint;
  update_columns: Array<Companies_Categories_Update_Column>;
  where?: Maybe<Companies_Categories_Bool_Exp>;
};

/** ordering options when selecting data from "companies_categories" */
export type Companies_Categories_Order_By = {
  category?: Maybe<Categories_Order_By>;
  category_id?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "companies_categories" */
export type Companies_Categories_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "companies_categories" */
export enum Companies_Categories_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "companies_categories" */
export type Companies_Categories_Set_Input = {
  category_id?: Maybe<Scalars['uuid']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "companies_categories" */
export enum Companies_Categories_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id'
}

/** unique or primary key constraints on table "companies" */
export enum Companies_Constraint {
  /** unique or primary key constraint */
  CompaniesPasshashKey = 'companies_passhash_key',
  /** unique or primary key constraint */
  CompaniesPkey = 'companies_pkey'
}

/** input type for inserting data into table "companies" */
export type Companies_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  address_city?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  affiliate?: Maybe<Scalars['String']>;
  bank_accs?: Maybe<Bank_Accs_Arr_Rel_Insert_Input>;
  companies_categories?: Maybe<Companies_Categories_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dic?: Maybe<Scalars['String']>;
  establishments?: Maybe<Establishments_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  passhash?: Maybe<Scalars['String']>;
  payments?: Maybe<Payments_Arr_Rel_Insert_Input>;
  phone_numbers?: Maybe<Phone_Numbers_Obj_Rel_Insert_Input>;
  phone_numbers_list?: Maybe<Phone_Numbers_Arr_Rel_Insert_Input>;
  pricelists?: Maybe<Pricelists_Arr_Rel_Insert_Input>;
  published?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Regions_Obj_Rel_Insert_Input>;
  region_code?: Maybe<Scalars['String']>;
  roles?: Maybe<Roles_Arr_Rel_Insert_Input>;
  short_description?: Maybe<Scalars['String']>;
  smsapi_templates?: Maybe<Smsapi_Templates_Arr_Rel_Insert_Input>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
  vat_id?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Companies_Max_Fields = {
  address_city?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  affiliate?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dic?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  passhash?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  vat_id?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "companies" */
export type Companies_Max_Order_By = {
  address_city?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  affiliate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  dic?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  passhash?: Maybe<Order_By>;
  region_code?: Maybe<Order_By>;
  short_description?: Maybe<Order_By>;
  vat_id?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Companies_Min_Fields = {
  address_city?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  affiliate?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dic?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  passhash?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  vat_id?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "companies" */
export type Companies_Min_Order_By = {
  address_city?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  affiliate?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  dic?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  passhash?: Maybe<Order_By>;
  region_code?: Maybe<Order_By>;
  short_description?: Maybe<Order_By>;
  vat_id?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** response of any mutation on the table "companies" */
export type Companies_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Companies>;
};

/** input type for inserting object relation for remote table "companies" */
export type Companies_Obj_Rel_Insert_Input = {
  data: Companies_Insert_Input;
  on_conflict?: Maybe<Companies_On_Conflict>;
};

/** on conflict condition type for table "companies" */
export type Companies_On_Conflict = {
  constraint: Companies_Constraint;
  update_columns: Array<Companies_Update_Column>;
  where?: Maybe<Companies_Bool_Exp>;
};

/** ordering options when selecting data from "companies" */
export type Companies_Order_By = {
  active?: Maybe<Order_By>;
  address_city?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  affiliate?: Maybe<Order_By>;
  bank_accs_aggregate?: Maybe<Bank_Accs_Aggregate_Order_By>;
  companies_categories_aggregate?: Maybe<Companies_Categories_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  dic?: Maybe<Order_By>;
  establishments_aggregate?: Maybe<Establishments_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  logo_url?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  passhash?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payments_Aggregate_Order_By>;
  phone_numbers?: Maybe<Phone_Numbers_Order_By>;
  phone_numbers_list_aggregate?: Maybe<Phone_Numbers_Aggregate_Order_By>;
  pricelists_aggregate?: Maybe<Pricelists_Aggregate_Order_By>;
  published?: Maybe<Order_By>;
  region?: Maybe<Regions_Order_By>;
  region_code?: Maybe<Order_By>;
  roles_aggregate?: Maybe<Roles_Aggregate_Order_By>;
  short_description?: Maybe<Order_By>;
  smsapi_templates_aggregate?: Maybe<Smsapi_Templates_Aggregate_Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
  vat_id?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** primary key columns input for table: "companies" */
export type Companies_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "companies" */
export enum Companies_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressPostcode = 'address_postcode',
  /** column name */
  AddressStreet = 'address_street',
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Dic = 'dic',
  /** column name */
  Id = 'id',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name',
  /** column name */
  Passhash = 'passhash',
  /** column name */
  Published = 'published',
  /** column name */
  RegionCode = 'region_code',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  VatId = 'vat_id',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "companies" */
export type Companies_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  address_city?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  affiliate?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  dic?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  passhash?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  region_code?: Maybe<Scalars['String']>;
  short_description?: Maybe<Scalars['String']>;
  vat_id?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** update columns of table "companies" */
export enum Companies_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressPostcode = 'address_postcode',
  /** column name */
  AddressStreet = 'address_street',
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Dic = 'dic',
  /** column name */
  Id = 'id',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name',
  /** column name */
  Passhash = 'passhash',
  /** column name */
  Published = 'published',
  /** column name */
  RegionCode = 'region_code',
  /** column name */
  ShortDescription = 'short_description',
  /** column name */
  VatId = 'vat_id',
  /** column name */
  Website = 'website'
}

/** columns and relationships of "contacts" */
export type Contacts = {
  active: Scalars['Boolean'];
  address_city: Scalars['String'];
  address_country: Scalars['String'];
  address_postcode: Scalars['String'];
  address_street: Scalars['String'];
  birthday?: Maybe<Scalars['date']>;
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  custom_values: Scalars['jsonb'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  phone_number: Scalars['String'];
  shoptet_id?: Maybe<Scalars['String']>;
  title_after_name: Scalars['String'];
  title_before_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "contacts" */
export type ContactsCustom_ValuesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "contacts" */
export type Contacts_Aggregate = {
  aggregate?: Maybe<Contacts_Aggregate_Fields>;
  nodes: Array<Contacts>;
};

/** aggregate fields of "contacts" */
export type Contacts_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Contacts_Max_Fields>;
  min?: Maybe<Contacts_Min_Fields>;
};


/** aggregate fields of "contacts" */
export type Contacts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contacts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contacts" */
export type Contacts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Contacts_Max_Order_By>;
  min?: Maybe<Contacts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Contacts_Append_Input = {
  custom_values?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "contacts" */
export type Contacts_Arr_Rel_Insert_Input = {
  data: Array<Contacts_Insert_Input>;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contacts". All fields are combined with a logical 'AND'. */
export type Contacts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Contacts_Bool_Exp>>>;
  _not?: Maybe<Contacts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Contacts_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  address_city?: Maybe<String_Comparison_Exp>;
  address_country?: Maybe<String_Comparison_Exp>;
  address_postcode?: Maybe<String_Comparison_Exp>;
  address_street?: Maybe<String_Comparison_Exp>;
  birthday?: Maybe<Date_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_values?: Maybe<Jsonb_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  shoptet_id?: Maybe<String_Comparison_Exp>;
  title_after_name?: Maybe<String_Comparison_Exp>;
  title_before_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts" */
export enum Contacts_Constraint {
  /** unique or primary key constraint */
  ContactsPkey = 'contacts_pkey',
  /** unique or primary key constraint */
  ContactsShoptetIdEmailKey = 'contacts_shoptet_id_email_key'
}

/** columns and relationships of "contacts_custom_fields" */
export type Contacts_Custom_Fields = {
  company_id: Scalars['uuid'];
  /** An object relationship */
  custom_field: Custom_Fields;
  custom_field_id: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** aggregated selection of "contacts_custom_fields" */
export type Contacts_Custom_Fields_Aggregate = {
  aggregate?: Maybe<Contacts_Custom_Fields_Aggregate_Fields>;
  nodes: Array<Contacts_Custom_Fields>;
};

/** aggregate fields of "contacts_custom_fields" */
export type Contacts_Custom_Fields_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Contacts_Custom_Fields_Max_Fields>;
  min?: Maybe<Contacts_Custom_Fields_Min_Fields>;
};


/** aggregate fields of "contacts_custom_fields" */
export type Contacts_Custom_Fields_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contacts_Custom_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contacts_custom_fields" */
export type Contacts_Custom_Fields_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Contacts_Custom_Fields_Max_Order_By>;
  min?: Maybe<Contacts_Custom_Fields_Min_Order_By>;
};

/** input type for inserting array relation for remote table "contacts_custom_fields" */
export type Contacts_Custom_Fields_Arr_Rel_Insert_Input = {
  data: Array<Contacts_Custom_Fields_Insert_Input>;
  on_conflict?: Maybe<Contacts_Custom_Fields_On_Conflict>;
};

/** Boolean expression to filter rows from the table "contacts_custom_fields". All fields are combined with a logical 'AND'. */
export type Contacts_Custom_Fields_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Contacts_Custom_Fields_Bool_Exp>>>;
  _not?: Maybe<Contacts_Custom_Fields_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Contacts_Custom_Fields_Bool_Exp>>>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  custom_field?: Maybe<Custom_Fields_Bool_Exp>;
  custom_field_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "contacts_custom_fields" */
export enum Contacts_Custom_Fields_Constraint {
  /** unique or primary key constraint */
  ContactsCustomFieldsPkey = 'contacts_custom_fields_pkey'
}

/** input type for inserting data into table "contacts_custom_fields" */
export type Contacts_Custom_Fields_Insert_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  custom_field?: Maybe<Custom_Fields_Obj_Rel_Insert_Input>;
  custom_field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Contacts_Custom_Fields_Max_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  custom_field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "contacts_custom_fields" */
export type Contacts_Custom_Fields_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  custom_field_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_Custom_Fields_Min_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  custom_field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "contacts_custom_fields" */
export type Contacts_Custom_Fields_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  custom_field_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "contacts_custom_fields" */
export type Contacts_Custom_Fields_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Contacts_Custom_Fields>;
};

/** input type for inserting object relation for remote table "contacts_custom_fields" */
export type Contacts_Custom_Fields_Obj_Rel_Insert_Input = {
  data: Contacts_Custom_Fields_Insert_Input;
  on_conflict?: Maybe<Contacts_Custom_Fields_On_Conflict>;
};

/** on conflict condition type for table "contacts_custom_fields" */
export type Contacts_Custom_Fields_On_Conflict = {
  constraint: Contacts_Custom_Fields_Constraint;
  update_columns: Array<Contacts_Custom_Fields_Update_Column>;
  where?: Maybe<Contacts_Custom_Fields_Bool_Exp>;
};

/** ordering options when selecting data from "contacts_custom_fields" */
export type Contacts_Custom_Fields_Order_By = {
  company_id?: Maybe<Order_By>;
  custom_field?: Maybe<Custom_Fields_Order_By>;
  custom_field_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "contacts_custom_fields" */
export type Contacts_Custom_Fields_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "contacts_custom_fields" */
export enum Contacts_Custom_Fields_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CustomFieldId = 'custom_field_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "contacts_custom_fields" */
export type Contacts_Custom_Fields_Set_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  custom_field_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "contacts_custom_fields" */
export enum Contacts_Custom_Fields_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CustomFieldId = 'custom_field_id',
  /** column name */
  Id = 'id'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Contacts_Delete_At_Path_Input = {
  custom_values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Contacts_Delete_Elem_Input = {
  custom_values?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Contacts_Delete_Key_Input = {
  custom_values?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "contacts" */
export type Contacts_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_values?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  shoptet_id?: Maybe<Scalars['String']>;
  title_after_name?: Maybe<Scalars['String']>;
  title_before_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contacts_Max_Fields = {
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  shoptet_id?: Maybe<Scalars['String']>;
  title_after_name?: Maybe<Scalars['String']>;
  title_before_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contacts" */
export type Contacts_Max_Order_By = {
  address_city?: Maybe<Order_By>;
  address_country?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  birthday?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  shoptet_id?: Maybe<Order_By>;
  title_after_name?: Maybe<Order_By>;
  title_before_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_Min_Fields = {
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  shoptet_id?: Maybe<Scalars['String']>;
  title_after_name?: Maybe<Scalars['String']>;
  title_before_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contacts" */
export type Contacts_Min_Order_By = {
  address_city?: Maybe<Order_By>;
  address_country?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  birthday?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  shoptet_id?: Maybe<Order_By>;
  title_after_name?: Maybe<Order_By>;
  title_before_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "contacts" */
export type Contacts_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Contacts>;
};

/** input type for inserting object relation for remote table "contacts" */
export type Contacts_Obj_Rel_Insert_Input = {
  data: Contacts_Insert_Input;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};

/** on conflict condition type for table "contacts" */
export type Contacts_On_Conflict = {
  constraint: Contacts_Constraint;
  update_columns: Array<Contacts_Update_Column>;
  where?: Maybe<Contacts_Bool_Exp>;
};

/** ordering options when selecting data from "contacts" */
export type Contacts_Order_By = {
  active?: Maybe<Order_By>;
  address_city?: Maybe<Order_By>;
  address_country?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  birthday?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_values?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  shoptet_id?: Maybe<Order_By>;
  title_after_name?: Maybe<Order_By>;
  title_before_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "contacts" */
export type Contacts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Contacts_Prepend_Input = {
  custom_values?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "contacts" */
export enum Contacts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressCountry = 'address_country',
  /** column name */
  AddressPostcode = 'address_postcode',
  /** column name */
  AddressStreet = 'address_street',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomValues = 'custom_values',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ShoptetId = 'shoptet_id',
  /** column name */
  TitleAfterName = 'title_after_name',
  /** column name */
  TitleBeforeName = 'title_before_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contacts" */
export type Contacts_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_values?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  shoptet_id?: Maybe<Scalars['String']>;
  title_after_name?: Maybe<Scalars['String']>;
  title_before_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "contacts" */
export enum Contacts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressCountry = 'address_country',
  /** column name */
  AddressPostcode = 'address_postcode',
  /** column name */
  AddressStreet = 'address_street',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomValues = 'custom_values',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ShoptetId = 'shoptet_id',
  /** column name */
  TitleAfterName = 'title_after_name',
  /** column name */
  TitleBeforeName = 'title_before_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "contacts_view" */
export type Contacts_View = {
  active?: Maybe<Scalars['Boolean']>;
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_values?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  title_after_name?: Maybe<Scalars['String']>;
  title_before_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "contacts_view" */
export type Contacts_ViewCustom_ValuesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "contacts_view" */
export type Contacts_View_Aggregate = {
  aggregate?: Maybe<Contacts_View_Aggregate_Fields>;
  nodes: Array<Contacts_View>;
};

/** aggregate fields of "contacts_view" */
export type Contacts_View_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Contacts_View_Max_Fields>;
  min?: Maybe<Contacts_View_Min_Fields>;
};


/** aggregate fields of "contacts_view" */
export type Contacts_View_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contacts_View_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "contacts_view" */
export type Contacts_View_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Contacts_View_Max_Order_By>;
  min?: Maybe<Contacts_View_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Contacts_View_Append_Input = {
  custom_values?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "contacts_view" */
export type Contacts_View_Arr_Rel_Insert_Input = {
  data: Array<Contacts_View_Insert_Input>;
};

/** Boolean expression to filter rows from the table "contacts_view". All fields are combined with a logical 'AND'. */
export type Contacts_View_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Contacts_View_Bool_Exp>>>;
  _not?: Maybe<Contacts_View_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Contacts_View_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  address_city?: Maybe<String_Comparison_Exp>;
  address_country?: Maybe<String_Comparison_Exp>;
  address_postcode?: Maybe<String_Comparison_Exp>;
  address_street?: Maybe<String_Comparison_Exp>;
  birthday?: Maybe<Date_Comparison_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_values?: Maybe<Jsonb_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  title_after_name?: Maybe<String_Comparison_Exp>;
  title_before_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Contacts_View_Delete_At_Path_Input = {
  custom_values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Contacts_View_Delete_Elem_Input = {
  custom_values?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Contacts_View_Delete_Key_Input = {
  custom_values?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "contacts_view" */
export type Contacts_View_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_values?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  title_after_name?: Maybe<Scalars['String']>;
  title_before_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Contacts_View_Max_Fields = {
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  title_after_name?: Maybe<Scalars['String']>;
  title_before_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "contacts_view" */
export type Contacts_View_Max_Order_By = {
  address_city?: Maybe<Order_By>;
  address_country?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  birthday?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  title_after_name?: Maybe<Order_By>;
  title_before_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contacts_View_Min_Fields = {
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  title_after_name?: Maybe<Scalars['String']>;
  title_before_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "contacts_view" */
export type Contacts_View_Min_Order_By = {
  address_city?: Maybe<Order_By>;
  address_country?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  birthday?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  title_after_name?: Maybe<Order_By>;
  title_before_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "contacts_view" */
export type Contacts_View_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Contacts_View>;
};

/** input type for inserting object relation for remote table "contacts_view" */
export type Contacts_View_Obj_Rel_Insert_Input = {
  data: Contacts_View_Insert_Input;
};

/** ordering options when selecting data from "contacts_view" */
export type Contacts_View_Order_By = {
  active?: Maybe<Order_By>;
  address_city?: Maybe<Order_By>;
  address_country?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  birthday?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_values?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  title_after_name?: Maybe<Order_By>;
  title_before_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Contacts_View_Prepend_Input = {
  custom_values?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "contacts_view" */
export enum Contacts_View_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressCountry = 'address_country',
  /** column name */
  AddressPostcode = 'address_postcode',
  /** column name */
  AddressStreet = 'address_street',
  /** column name */
  Birthday = 'birthday',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomValues = 'custom_values',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  TitleAfterName = 'title_after_name',
  /** column name */
  TitleBeforeName = 'title_before_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "contacts_view" */
export type Contacts_View_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  address_city?: Maybe<Scalars['String']>;
  address_country?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['date']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_values?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  title_after_name?: Maybe<Scalars['String']>;
  title_before_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

export type CreatePaymentOutput = {
  id: Scalars['uuid'];
  payment?: Maybe<Payments>;
};

/** columns and relationships of "currencies" */
export type Currencies = {
  code: Scalars['String'];
  display: Scalars['String'];
  display_end: Scalars['Boolean'];
  /** An array relationship */
  pricelists: Array<Pricelists>;
  /** An aggregated array relationship */
  pricelists_aggregate: Pricelists_Aggregate;
  rate_eur: Scalars['numeric'];
};


/** columns and relationships of "currencies" */
export type CurrenciesPricelistsArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};


/** columns and relationships of "currencies" */
export type CurrenciesPricelists_AggregateArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};

/** aggregated selection of "currencies" */
export type Currencies_Aggregate = {
  aggregate?: Maybe<Currencies_Aggregate_Fields>;
  nodes: Array<Currencies>;
};

/** aggregate fields of "currencies" */
export type Currencies_Aggregate_Fields = {
  avg?: Maybe<Currencies_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Currencies_Max_Fields>;
  min?: Maybe<Currencies_Min_Fields>;
  stddev?: Maybe<Currencies_Stddev_Fields>;
  stddev_pop?: Maybe<Currencies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Currencies_Stddev_Samp_Fields>;
  sum?: Maybe<Currencies_Sum_Fields>;
  var_pop?: Maybe<Currencies_Var_Pop_Fields>;
  var_samp?: Maybe<Currencies_Var_Samp_Fields>;
  variance?: Maybe<Currencies_Variance_Fields>;
};


/** aggregate fields of "currencies" */
export type Currencies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Currencies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "currencies" */
export type Currencies_Aggregate_Order_By = {
  avg?: Maybe<Currencies_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Currencies_Max_Order_By>;
  min?: Maybe<Currencies_Min_Order_By>;
  stddev?: Maybe<Currencies_Stddev_Order_By>;
  stddev_pop?: Maybe<Currencies_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Currencies_Stddev_Samp_Order_By>;
  sum?: Maybe<Currencies_Sum_Order_By>;
  var_pop?: Maybe<Currencies_Var_Pop_Order_By>;
  var_samp?: Maybe<Currencies_Var_Samp_Order_By>;
  variance?: Maybe<Currencies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "currencies" */
export type Currencies_Arr_Rel_Insert_Input = {
  data: Array<Currencies_Insert_Input>;
  on_conflict?: Maybe<Currencies_On_Conflict>;
};

/** aggregate avg on columns */
export type Currencies_Avg_Fields = {
  rate_eur?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "currencies" */
export type Currencies_Avg_Order_By = {
  rate_eur?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "currencies". All fields are combined with a logical 'AND'. */
export type Currencies_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Currencies_Bool_Exp>>>;
  _not?: Maybe<Currencies_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Currencies_Bool_Exp>>>;
  code?: Maybe<String_Comparison_Exp>;
  display?: Maybe<String_Comparison_Exp>;
  display_end?: Maybe<Boolean_Comparison_Exp>;
  pricelists?: Maybe<Pricelists_Bool_Exp>;
  rate_eur?: Maybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "currencies" */
export enum Currencies_Constraint {
  /** unique or primary key constraint */
  CurrenciesCode = 'currencies_code'
}

/** input type for incrementing integer column in table "currencies" */
export type Currencies_Inc_Input = {
  rate_eur?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "currencies" */
export type Currencies_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  display_end?: Maybe<Scalars['Boolean']>;
  pricelists?: Maybe<Pricelists_Arr_Rel_Insert_Input>;
  rate_eur?: Maybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Currencies_Max_Fields = {
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  rate_eur?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "currencies" */
export type Currencies_Max_Order_By = {
  code?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  rate_eur?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Currencies_Min_Fields = {
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  rate_eur?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "currencies" */
export type Currencies_Min_Order_By = {
  code?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  rate_eur?: Maybe<Order_By>;
};

/** response of any mutation on the table "currencies" */
export type Currencies_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Currencies>;
};

/** input type for inserting object relation for remote table "currencies" */
export type Currencies_Obj_Rel_Insert_Input = {
  data: Currencies_Insert_Input;
  on_conflict?: Maybe<Currencies_On_Conflict>;
};

/** on conflict condition type for table "currencies" */
export type Currencies_On_Conflict = {
  constraint: Currencies_Constraint;
  update_columns: Array<Currencies_Update_Column>;
  where?: Maybe<Currencies_Bool_Exp>;
};

/** ordering options when selecting data from "currencies" */
export type Currencies_Order_By = {
  code?: Maybe<Order_By>;
  display?: Maybe<Order_By>;
  display_end?: Maybe<Order_By>;
  pricelists_aggregate?: Maybe<Pricelists_Aggregate_Order_By>;
  rate_eur?: Maybe<Order_By>;
};

/** primary key columns input for table: "currencies" */
export type Currencies_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "currencies" */
export enum Currencies_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Display = 'display',
  /** column name */
  DisplayEnd = 'display_end',
  /** column name */
  RateEur = 'rate_eur'
}

/** input type for updating data in table "currencies" */
export type Currencies_Set_Input = {
  code?: Maybe<Scalars['String']>;
  display?: Maybe<Scalars['String']>;
  display_end?: Maybe<Scalars['Boolean']>;
  rate_eur?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Currencies_Stddev_Fields = {
  rate_eur?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "currencies" */
export type Currencies_Stddev_Order_By = {
  rate_eur?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Currencies_Stddev_Pop_Fields = {
  rate_eur?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "currencies" */
export type Currencies_Stddev_Pop_Order_By = {
  rate_eur?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Currencies_Stddev_Samp_Fields = {
  rate_eur?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "currencies" */
export type Currencies_Stddev_Samp_Order_By = {
  rate_eur?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Currencies_Sum_Fields = {
  rate_eur?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "currencies" */
export type Currencies_Sum_Order_By = {
  rate_eur?: Maybe<Order_By>;
};

/** update columns of table "currencies" */
export enum Currencies_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Display = 'display',
  /** column name */
  DisplayEnd = 'display_end',
  /** column name */
  RateEur = 'rate_eur'
}

/** aggregate var_pop on columns */
export type Currencies_Var_Pop_Fields = {
  rate_eur?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "currencies" */
export type Currencies_Var_Pop_Order_By = {
  rate_eur?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Currencies_Var_Samp_Fields = {
  rate_eur?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "currencies" */
export type Currencies_Var_Samp_Order_By = {
  rate_eur?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Currencies_Variance_Fields = {
  rate_eur?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "currencies" */
export type Currencies_Variance_Order_By = {
  rate_eur?: Maybe<Order_By>;
};

/** columns and relationships of "custom_fields" */
export type Custom_Fields = {
  /** An array relationship */
  calendars_custom_fields: Array<Calendars_Custom_Fields>;
  /** An aggregated array relationship */
  calendars_custom_fields_aggregate: Calendars_Custom_Fields_Aggregate;
  /** An array relationship */
  contacts_custom_fields: Array<Contacts_Custom_Fields>;
  /** An aggregated array relationship */
  contacts_custom_fields_aggregate: Contacts_Custom_Fields_Aggregate;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  type_value?: Maybe<Scalars['String']>;
};


/** columns and relationships of "custom_fields" */
export type Custom_FieldsCalendars_Custom_FieldsArgs = {
  distinct_on?: Maybe<Array<Calendars_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Custom_Fields_Order_By>>;
  where?: Maybe<Calendars_Custom_Fields_Bool_Exp>;
};


/** columns and relationships of "custom_fields" */
export type Custom_FieldsCalendars_Custom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Calendars_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Custom_Fields_Order_By>>;
  where?: Maybe<Calendars_Custom_Fields_Bool_Exp>;
};


/** columns and relationships of "custom_fields" */
export type Custom_FieldsContacts_Custom_FieldsArgs = {
  distinct_on?: Maybe<Array<Contacts_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Custom_Fields_Order_By>>;
  where?: Maybe<Contacts_Custom_Fields_Bool_Exp>;
};


/** columns and relationships of "custom_fields" */
export type Custom_FieldsContacts_Custom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Custom_Fields_Order_By>>;
  where?: Maybe<Contacts_Custom_Fields_Bool_Exp>;
};

/** aggregated selection of "custom_fields" */
export type Custom_Fields_Aggregate = {
  aggregate?: Maybe<Custom_Fields_Aggregate_Fields>;
  nodes: Array<Custom_Fields>;
};

/** aggregate fields of "custom_fields" */
export type Custom_Fields_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Custom_Fields_Max_Fields>;
  min?: Maybe<Custom_Fields_Min_Fields>;
};


/** aggregate fields of "custom_fields" */
export type Custom_Fields_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Custom_Fields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "custom_fields" */
export type Custom_Fields_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Custom_Fields_Max_Order_By>;
  min?: Maybe<Custom_Fields_Min_Order_By>;
};

/** input type for inserting array relation for remote table "custom_fields" */
export type Custom_Fields_Arr_Rel_Insert_Input = {
  data: Array<Custom_Fields_Insert_Input>;
  on_conflict?: Maybe<Custom_Fields_On_Conflict>;
};

/** Boolean expression to filter rows from the table "custom_fields". All fields are combined with a logical 'AND'. */
export type Custom_Fields_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Custom_Fields_Bool_Exp>>>;
  _not?: Maybe<Custom_Fields_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Custom_Fields_Bool_Exp>>>;
  calendars_custom_fields?: Maybe<Calendars_Custom_Fields_Bool_Exp>;
  contacts_custom_fields?: Maybe<Contacts_Custom_Fields_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  type_value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "custom_fields" */
export enum Custom_Fields_Constraint {
  /** unique or primary key constraint */
  CustomFieldPkey = 'custom_field_pkey'
}

/** input type for inserting data into table "custom_fields" */
export type Custom_Fields_Insert_Input = {
  calendars_custom_fields?: Maybe<Calendars_Custom_Fields_Arr_Rel_Insert_Input>;
  contacts_custom_fields?: Maybe<Contacts_Custom_Fields_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Custom_Fields_Max_Fields = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "custom_fields" */
export type Custom_Fields_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  type_value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Custom_Fields_Min_Fields = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "custom_fields" */
export type Custom_Fields_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  type_value?: Maybe<Order_By>;
};

/** response of any mutation on the table "custom_fields" */
export type Custom_Fields_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Custom_Fields>;
};

/** input type for inserting object relation for remote table "custom_fields" */
export type Custom_Fields_Obj_Rel_Insert_Input = {
  data: Custom_Fields_Insert_Input;
  on_conflict?: Maybe<Custom_Fields_On_Conflict>;
};

/** on conflict condition type for table "custom_fields" */
export type Custom_Fields_On_Conflict = {
  constraint: Custom_Fields_Constraint;
  update_columns: Array<Custom_Fields_Update_Column>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};

/** ordering options when selecting data from "custom_fields" */
export type Custom_Fields_Order_By = {
  calendars_custom_fields_aggregate?: Maybe<Calendars_Custom_Fields_Aggregate_Order_By>;
  contacts_custom_fields_aggregate?: Maybe<Contacts_Custom_Fields_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  type_value?: Maybe<Order_By>;
};

/** primary key columns input for table: "custom_fields" */
export type Custom_Fields_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "custom_fields" */
export enum Custom_Fields_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  TypeValue = 'type_value'
}

/** input type for updating data in table "custom_fields" */
export type Custom_Fields_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_value?: Maybe<Scalars['String']>;
};

/** update columns of table "custom_fields" */
export enum Custom_Fields_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  TypeValue = 'type_value'
}


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "establishments" */
export type Establishments = {
  address_city: Scalars['String'];
  address_postcode: Scalars['String'];
  address_street: Scalars['String'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  establishments_users: Array<Establishments_Users>;
  /** An aggregated array relationship */
  establishments_users_aggregate: Establishments_Users_Aggregate;
  facebook?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['numeric']>;
  geo_lng?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  instagram?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregated array relationship */
  orders_aggregate: Orders_Aggregate;
  phone_number?: Maybe<Scalars['String']>;
  /** An array relationship */
  phone_numbers_establishments: Array<Phone_Numbers_Establishments>;
  /** An aggregated array relationship */
  phone_numbers_establishments_aggregate: Phone_Numbers_Establishments_Aggregate;
  photo_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  pricelists: Array<Pricelists>;
  /** An aggregated array relationship */
  pricelists_aggregate: Pricelists_Aggregate;
  website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "establishments" */
export type EstablishmentsEstablishments_UsersArgs = {
  distinct_on?: Maybe<Array<Establishments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Users_Order_By>>;
  where?: Maybe<Establishments_Users_Bool_Exp>;
};


/** columns and relationships of "establishments" */
export type EstablishmentsEstablishments_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Establishments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Users_Order_By>>;
  where?: Maybe<Establishments_Users_Bool_Exp>;
};


/** columns and relationships of "establishments" */
export type EstablishmentsOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "establishments" */
export type EstablishmentsOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** columns and relationships of "establishments" */
export type EstablishmentsPhone_Numbers_EstablishmentsArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Establishments_Order_By>>;
  where?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
};


/** columns and relationships of "establishments" */
export type EstablishmentsPhone_Numbers_Establishments_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Establishments_Order_By>>;
  where?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
};


/** columns and relationships of "establishments" */
export type EstablishmentsPricelistsArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};


/** columns and relationships of "establishments" */
export type EstablishmentsPricelists_AggregateArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};

/** aggregated selection of "establishments" */
export type Establishments_Aggregate = {
  aggregate?: Maybe<Establishments_Aggregate_Fields>;
  nodes: Array<Establishments>;
};

/** aggregate fields of "establishments" */
export type Establishments_Aggregate_Fields = {
  avg?: Maybe<Establishments_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Establishments_Max_Fields>;
  min?: Maybe<Establishments_Min_Fields>;
  stddev?: Maybe<Establishments_Stddev_Fields>;
  stddev_pop?: Maybe<Establishments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Establishments_Stddev_Samp_Fields>;
  sum?: Maybe<Establishments_Sum_Fields>;
  var_pop?: Maybe<Establishments_Var_Pop_Fields>;
  var_samp?: Maybe<Establishments_Var_Samp_Fields>;
  variance?: Maybe<Establishments_Variance_Fields>;
};


/** aggregate fields of "establishments" */
export type Establishments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Establishments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "establishments" */
export type Establishments_Aggregate_Order_By = {
  avg?: Maybe<Establishments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Establishments_Max_Order_By>;
  min?: Maybe<Establishments_Min_Order_By>;
  stddev?: Maybe<Establishments_Stddev_Order_By>;
  stddev_pop?: Maybe<Establishments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Establishments_Stddev_Samp_Order_By>;
  sum?: Maybe<Establishments_Sum_Order_By>;
  var_pop?: Maybe<Establishments_Var_Pop_Order_By>;
  var_samp?: Maybe<Establishments_Var_Samp_Order_By>;
  variance?: Maybe<Establishments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "establishments" */
export type Establishments_Arr_Rel_Insert_Input = {
  data: Array<Establishments_Insert_Input>;
  on_conflict?: Maybe<Establishments_On_Conflict>;
};

/** aggregate avg on columns */
export type Establishments_Avg_Fields = {
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lng?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "establishments" */
export type Establishments_Avg_Order_By = {
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "establishments". All fields are combined with a logical 'AND'. */
export type Establishments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Establishments_Bool_Exp>>>;
  _not?: Maybe<Establishments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Establishments_Bool_Exp>>>;
  address_city?: Maybe<String_Comparison_Exp>;
  address_postcode?: Maybe<String_Comparison_Exp>;
  address_street?: Maybe<String_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  establishments_users?: Maybe<Establishments_Users_Bool_Exp>;
  facebook?: Maybe<String_Comparison_Exp>;
  geo_lat?: Maybe<Numeric_Comparison_Exp>;
  geo_lng?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  instagram?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  orders?: Maybe<Orders_Bool_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  phone_numbers_establishments?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
  photo_url?: Maybe<String_Comparison_Exp>;
  pricelists?: Maybe<Pricelists_Bool_Exp>;
  website?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "establishments" */
export enum Establishments_Constraint {
  /** unique or primary key constraint */
  EstablishmentsPkey = 'establishments_pkey'
}

/** input type for incrementing integer column in table "establishments" */
export type Establishments_Inc_Input = {
  geo_lat?: Maybe<Scalars['numeric']>;
  geo_lng?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "establishments" */
export type Establishments_Insert_Input = {
  address_city?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  establishments_users?: Maybe<Establishments_Users_Arr_Rel_Insert_Input>;
  facebook?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['numeric']>;
  geo_lng?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<Orders_Arr_Rel_Insert_Input>;
  phone_number?: Maybe<Scalars['String']>;
  phone_numbers_establishments?: Maybe<Phone_Numbers_Establishments_Arr_Rel_Insert_Input>;
  photo_url?: Maybe<Scalars['String']>;
  pricelists?: Maybe<Pricelists_Arr_Rel_Insert_Input>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Establishments_Max_Fields = {
  address_city?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['numeric']>;
  geo_lng?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "establishments" */
export type Establishments_Max_Order_By = {
  address_city?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  facebook?: Maybe<Order_By>;
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instagram?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Establishments_Min_Fields = {
  address_city?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['numeric']>;
  geo_lng?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "establishments" */
export type Establishments_Min_Order_By = {
  address_city?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  facebook?: Maybe<Order_By>;
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instagram?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  website?: Maybe<Order_By>;
};

/** response of any mutation on the table "establishments" */
export type Establishments_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Establishments>;
};

/** input type for inserting object relation for remote table "establishments" */
export type Establishments_Obj_Rel_Insert_Input = {
  data: Establishments_Insert_Input;
  on_conflict?: Maybe<Establishments_On_Conflict>;
};

/** on conflict condition type for table "establishments" */
export type Establishments_On_Conflict = {
  constraint: Establishments_Constraint;
  update_columns: Array<Establishments_Update_Column>;
  where?: Maybe<Establishments_Bool_Exp>;
};

/** ordering options when selecting data from "establishments" */
export type Establishments_Order_By = {
  address_city?: Maybe<Order_By>;
  address_postcode?: Maybe<Order_By>;
  address_street?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  establishments_users_aggregate?: Maybe<Establishments_Users_Aggregate_Order_By>;
  facebook?: Maybe<Order_By>;
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  instagram?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  orders_aggregate?: Maybe<Orders_Aggregate_Order_By>;
  phone_number?: Maybe<Order_By>;
  phone_numbers_establishments_aggregate?: Maybe<Phone_Numbers_Establishments_Aggregate_Order_By>;
  photo_url?: Maybe<Order_By>;
  pricelists_aggregate?: Maybe<Pricelists_Aggregate_Order_By>;
  website?: Maybe<Order_By>;
};

/** primary key columns input for table: "establishments" */
export type Establishments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "establishments" */
export enum Establishments_Select_Column {
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressPostcode = 'address_postcode',
  /** column name */
  AddressStreet = 'address_street',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Email = 'email',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  GeoLat = 'geo_lat',
  /** column name */
  GeoLng = 'geo_lng',
  /** column name */
  Id = 'id',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  Website = 'website'
}

/** input type for updating data in table "establishments" */
export type Establishments_Set_Input = {
  address_city?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_street?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['numeric']>;
  geo_lng?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Establishments_Stddev_Fields = {
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lng?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "establishments" */
export type Establishments_Stddev_Order_By = {
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Establishments_Stddev_Pop_Fields = {
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "establishments" */
export type Establishments_Stddev_Pop_Order_By = {
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Establishments_Stddev_Samp_Fields = {
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lng?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "establishments" */
export type Establishments_Stddev_Samp_Order_By = {
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Establishments_Sum_Fields = {
  geo_lat?: Maybe<Scalars['numeric']>;
  geo_lng?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "establishments" */
export type Establishments_Sum_Order_By = {
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
};

/** update columns of table "establishments" */
export enum Establishments_Update_Column {
  /** column name */
  AddressCity = 'address_city',
  /** column name */
  AddressPostcode = 'address_postcode',
  /** column name */
  AddressStreet = 'address_street',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Email = 'email',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  GeoLat = 'geo_lat',
  /** column name */
  GeoLng = 'geo_lng',
  /** column name */
  Id = 'id',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  Website = 'website'
}

/** columns and relationships of "establishments_users" */
export type Establishments_Users = {
  /** An object relationship */
  establishment: Establishments;
  establishment_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "establishments_users" */
export type Establishments_Users_Aggregate = {
  aggregate?: Maybe<Establishments_Users_Aggregate_Fields>;
  nodes: Array<Establishments_Users>;
};

/** aggregate fields of "establishments_users" */
export type Establishments_Users_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Establishments_Users_Max_Fields>;
  min?: Maybe<Establishments_Users_Min_Fields>;
};


/** aggregate fields of "establishments_users" */
export type Establishments_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Establishments_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "establishments_users" */
export type Establishments_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Establishments_Users_Max_Order_By>;
  min?: Maybe<Establishments_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "establishments_users" */
export type Establishments_Users_Arr_Rel_Insert_Input = {
  data: Array<Establishments_Users_Insert_Input>;
  on_conflict?: Maybe<Establishments_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "establishments_users". All fields are combined with a logical 'AND'. */
export type Establishments_Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Establishments_Users_Bool_Exp>>>;
  _not?: Maybe<Establishments_Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Establishments_Users_Bool_Exp>>>;
  establishment?: Maybe<Establishments_Bool_Exp>;
  establishment_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "establishments_users" */
export enum Establishments_Users_Constraint {
  /** unique or primary key constraint */
  EstablishmentsUsersPkey = 'establishments_users_pkey'
}

/** input type for inserting data into table "establishments_users" */
export type Establishments_Users_Insert_Input = {
  establishment?: Maybe<Establishments_Obj_Rel_Insert_Input>;
  establishment_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Establishments_Users_Max_Fields = {
  establishment_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "establishments_users" */
export type Establishments_Users_Max_Order_By = {
  establishment_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Establishments_Users_Min_Fields = {
  establishment_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "establishments_users" */
export type Establishments_Users_Min_Order_By = {
  establishment_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "establishments_users" */
export type Establishments_Users_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Establishments_Users>;
};

/** input type for inserting object relation for remote table "establishments_users" */
export type Establishments_Users_Obj_Rel_Insert_Input = {
  data: Establishments_Users_Insert_Input;
  on_conflict?: Maybe<Establishments_Users_On_Conflict>;
};

/** on conflict condition type for table "establishments_users" */
export type Establishments_Users_On_Conflict = {
  constraint: Establishments_Users_Constraint;
  update_columns: Array<Establishments_Users_Update_Column>;
  where?: Maybe<Establishments_Users_Bool_Exp>;
};

/** ordering options when selecting data from "establishments_users" */
export type Establishments_Users_Order_By = {
  establishment?: Maybe<Establishments_Order_By>;
  establishment_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "establishments_users" */
export type Establishments_Users_Pk_Columns_Input = {
  establishment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "establishments_users" */
export enum Establishments_Users_Select_Column {
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "establishments_users" */
export type Establishments_Users_Set_Input = {
  establishment_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "establishments_users" */
export enum Establishments_Users_Update_Column {
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Establishments_Var_Pop_Fields = {
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lng?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "establishments" */
export type Establishments_Var_Pop_Order_By = {
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Establishments_Var_Samp_Fields = {
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lng?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "establishments" */
export type Establishments_Var_Samp_Order_By = {
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Establishments_Variance_Fields = {
  geo_lat?: Maybe<Scalars['Float']>;
  geo_lng?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "establishments" */
export type Establishments_Variance_Order_By = {
  geo_lat?: Maybe<Order_By>;
  geo_lng?: Maybe<Order_By>;
};


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};


/** expression to compare columns of type money. All fields are combined with logical 'AND'. */
export type Money_Comparison_Exp = {
  _eq?: Maybe<Scalars['money']>;
  _gt?: Maybe<Scalars['money']>;
  _gte?: Maybe<Scalars['money']>;
  _in?: Maybe<Array<Scalars['money']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['money']>;
  _lte?: Maybe<Scalars['money']>;
  _neq?: Maybe<Scalars['money']>;
  _nin?: Maybe<Array<Scalars['money']>>;
};

/** mutation root */
export type Mutation_Root = {
  centrumsms_send?: Maybe<Scalars['Int']>;
  centrumsms_send_group?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** perform the action: "create_payment" */
  create_payment?: Maybe<CreatePaymentOutput>;
  /** perform the action: "create_reservio_user" */
  create_reservio_user?: Maybe<CreateReservioUserOutput>;
  /** delete data from the table: "automation_flows" */
  delete_automation_flows?: Maybe<Automation_Flows_Mutation_Response>;
  /** delete single row from the table: "automation_flows" */
  delete_automation_flows_by_pk?: Maybe<Automation_Flows>;
  /** delete data from the table: "automation_queue" */
  delete_automation_queue?: Maybe<Automation_Queue_Mutation_Response>;
  /** delete single row from the table: "automation_queue" */
  delete_automation_queue_by_pk?: Maybe<Automation_Queue>;
  /** delete data from the table: "bank_accs" */
  delete_bank_accs?: Maybe<Bank_Accs_Mutation_Response>;
  /** delete single row from the table: "bank_accs" */
  delete_bank_accs_by_pk?: Maybe<Bank_Accs>;
  /** delete data from the table: "blocks" */
  delete_blocks?: Maybe<Blocks_Mutation_Response>;
  /** delete single row from the table: "blocks" */
  delete_blocks_by_pk?: Maybe<Blocks>;
  /** delete data from the table: "businesses" */
  delete_businesses?: Maybe<Businesses_Mutation_Response>;
  /** delete single row from the table: "businesses" */
  delete_businesses_by_pk?: Maybe<Businesses>;
  /** delete data from the table: "calendars" */
  delete_calendars?: Maybe<Calendars_Mutation_Response>;
  /** delete single row from the table: "calendars" */
  delete_calendars_by_pk?: Maybe<Calendars>;
  /** delete data from the table: "calendars_custom_fields" */
  delete_calendars_custom_fields?: Maybe<Calendars_Custom_Fields_Mutation_Response>;
  /** delete single row from the table: "calendars_custom_fields" */
  delete_calendars_custom_fields_by_pk?: Maybe<Calendars_Custom_Fields>;
  /** delete data from the table: "calendars_services" */
  delete_calendars_services?: Maybe<Calendars_Services_Mutation_Response>;
  /** delete single row from the table: "calendars_services" */
  delete_calendars_services_by_pk?: Maybe<Calendars_Services>;
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>;
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>;
  /** delete data from the table: "companies" */
  delete_companies?: Maybe<Companies_Mutation_Response>;
  /** delete single row from the table: "companies" */
  delete_companies_by_pk?: Maybe<Companies>;
  /** delete data from the table: "companies_categories" */
  delete_companies_categories?: Maybe<Companies_Categories_Mutation_Response>;
  /** delete single row from the table: "companies_categories" */
  delete_companies_categories_by_pk?: Maybe<Companies_Categories>;
  /** delete data from the table: "contacts" */
  delete_contacts?: Maybe<Contacts_Mutation_Response>;
  /** delete single row from the table: "contacts" */
  delete_contacts_by_pk?: Maybe<Contacts>;
  /** delete data from the table: "contacts_custom_fields" */
  delete_contacts_custom_fields?: Maybe<Contacts_Custom_Fields_Mutation_Response>;
  /** delete single row from the table: "contacts_custom_fields" */
  delete_contacts_custom_fields_by_pk?: Maybe<Contacts_Custom_Fields>;
  /** delete data from the table: "contacts_view" */
  delete_contacts_view?: Maybe<Contacts_View_Mutation_Response>;
  /** delete data from the table: "currencies" */
  delete_currencies?: Maybe<Currencies_Mutation_Response>;
  /** delete single row from the table: "currencies" */
  delete_currencies_by_pk?: Maybe<Currencies>;
  /** delete data from the table: "custom_fields" */
  delete_custom_fields?: Maybe<Custom_Fields_Mutation_Response>;
  /** delete single row from the table: "custom_fields" */
  delete_custom_fields_by_pk?: Maybe<Custom_Fields>;
  /** delete data from the table: "establishments" */
  delete_establishments?: Maybe<Establishments_Mutation_Response>;
  /** delete single row from the table: "establishments" */
  delete_establishments_by_pk?: Maybe<Establishments>;
  /** delete data from the table: "establishments_users" */
  delete_establishments_users?: Maybe<Establishments_Users_Mutation_Response>;
  /** delete single row from the table: "establishments_users" */
  delete_establishments_users_by_pk?: Maybe<Establishments_Users>;
  /** delete data from the table: "orders" */
  delete_orders?: Maybe<Orders_Mutation_Response>;
  /** delete single row from the table: "orders" */
  delete_orders_by_pk?: Maybe<Orders>;
  /** delete data from the table: "payments" */
  delete_payments?: Maybe<Payments_Mutation_Response>;
  /** delete single row from the table: "payments" */
  delete_payments_by_pk?: Maybe<Payments>;
  /** delete data from the table: "phone_numbers" */
  delete_phone_numbers?: Maybe<Phone_Numbers_Mutation_Response>;
  /** delete single row from the table: "phone_numbers" */
  delete_phone_numbers_by_pk?: Maybe<Phone_Numbers>;
  /** delete data from the table: "phone_numbers_establishments" */
  delete_phone_numbers_establishments?: Maybe<Phone_Numbers_Establishments_Mutation_Response>;
  /** delete single row from the table: "phone_numbers_establishments" */
  delete_phone_numbers_establishments_by_pk?: Maybe<Phone_Numbers_Establishments>;
  /** delete data from the table: "phone_numbers_users" */
  delete_phone_numbers_users?: Maybe<Phone_Numbers_Users_Mutation_Response>;
  /** delete single row from the table: "phone_numbers_users" */
  delete_phone_numbers_users_by_pk?: Maybe<Phone_Numbers_Users>;
  /** delete data from the table: "pricelists" */
  delete_pricelists?: Maybe<Pricelists_Mutation_Response>;
  /** delete single row from the table: "pricelists" */
  delete_pricelists_by_pk?: Maybe<Pricelists>;
  /** delete data from the table: "regions" */
  delete_regions?: Maybe<Regions_Mutation_Response>;
  /** delete single row from the table: "regions" */
  delete_regions_by_pk?: Maybe<Regions>;
  /** delete data from the table: "reservations" */
  delete_reservations?: Maybe<Reservations_Mutation_Response>;
  /** delete single row from the table: "reservations" */
  delete_reservations_by_pk?: Maybe<Reservations>;
  /** delete data from the table: "reservations_services" */
  delete_reservations_services?: Maybe<Reservations_Services_Mutation_Response>;
  /** delete single row from the table: "reservations_services" */
  delete_reservations_services_by_pk?: Maybe<Reservations_Services>;
  /** delete data from the table: "reservio_integration" */
  delete_reservio_integration?: Maybe<Reservio_Integration_Mutation_Response>;
  /** delete single row from the table: "reservio_integration" */
  delete_reservio_integration_by_pk?: Maybe<Reservio_Integration>;
  /** delete data from the table: "reservio_integration_reservations" */
  delete_reservio_integration_reservations?: Maybe<Reservio_Integration_Reservations_Mutation_Response>;
  /** delete single row from the table: "reservio_integration_reservations" */
  delete_reservio_integration_reservations_by_pk?: Maybe<Reservio_Integration_Reservations>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "services" */
  delete_services?: Maybe<Services_Mutation_Response>;
  /** delete single row from the table: "services" */
  delete_services_by_pk?: Maybe<Services>;
  /** delete data from the table: "shoptet_eshops" */
  delete_shoptet_eshops?: Maybe<Shoptet_Eshops_Mutation_Response>;
  /** delete single row from the table: "shoptet_eshops" */
  delete_shoptet_eshops_by_pk?: Maybe<Shoptet_Eshops>;
  /** delete data from the table: "shoptet_orders" */
  delete_shoptet_orders?: Maybe<Shoptet_Orders_Mutation_Response>;
  /** delete single row from the table: "shoptet_orders" */
  delete_shoptet_orders_by_pk?: Maybe<Shoptet_Orders>;
  /** delete data from the table: "smsapi_deliveries" */
  delete_smsapi_deliveries?: Maybe<Smsapi_Deliveries_Mutation_Response>;
  /** delete single row from the table: "smsapi_deliveries" */
  delete_smsapi_deliveries_by_pk?: Maybe<Smsapi_Deliveries>;
  /** delete data from the table: "smsapi_queue" */
  delete_smsapi_queue?: Maybe<Smsapi_Queue_Mutation_Response>;
  /** delete data from the table: "smsapi_queue_extra" */
  delete_smsapi_queue_extra?: Maybe<Smsapi_Queue_Extra_Mutation_Response>;
  /** delete single row from the table: "smsapi_queue_extra" */
  delete_smsapi_queue_extra_by_pk?: Maybe<Smsapi_Queue_Extra>;
  /** delete data from the table: "smsapi_queue_extra_tags_history" */
  delete_smsapi_queue_extra_tags_history?: Maybe<Smsapi_Queue_Extra_Tags_History_Mutation_Response>;
  /** delete single row from the table: "smsapi_queue_extra_tags_history" */
  delete_smsapi_queue_extra_tags_history_by_pk?: Maybe<Smsapi_Queue_Extra_Tags_History>;
  /** delete data from the table: "smsapi_queue_index" */
  delete_smsapi_queue_index?: Maybe<Smsapi_Queue_Index_Mutation_Response>;
  /** delete data from the table: "smsapi_tags" */
  delete_smsapi_tags?: Maybe<Smsapi_Tags_Mutation_Response>;
  /** delete single row from the table: "smsapi_tags" */
  delete_smsapi_tags_by_pk?: Maybe<Smsapi_Tags>;
  /** delete data from the table: "smsapi_templates" */
  delete_smsapi_templates?: Maybe<Smsapi_Templates_Mutation_Response>;
  /** delete single row from the table: "smsapi_templates" */
  delete_smsapi_templates_by_pk?: Maybe<Smsapi_Templates>;
  /** delete data from the table: "tables" */
  delete_tables?: Maybe<Tables_Mutation_Response>;
  /** delete single row from the table: "tables" */
  delete_tables_by_pk?: Maybe<Tables>;
  /** delete data from the table: "telidoo_main.users" */
  delete_telidoo_main_users?: Maybe<Telidoo_Main_Users_Mutation_Response>;
  /** delete data from the table: "terminal_statements" */
  delete_terminal_statements?: Maybe<Terminal_Statements_Mutation_Response>;
  /** delete single row from the table: "terminal_statements" */
  delete_terminal_statements_by_pk?: Maybe<Terminal_Statements>;
  /** delete data from the table: "terminals" */
  delete_terminals?: Maybe<Terminals_Mutation_Response>;
  /** delete single row from the table: "terminals" */
  delete_terminals_by_pk?: Maybe<Terminals>;
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<Transactions_Mutation_Response>;
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "verification_requests" */
  delete_verification_requests?: Maybe<Verification_Requests_Mutation_Response>;
  /** delete single row from the table: "verification_requests" */
  delete_verification_requests_by_pk?: Maybe<Verification_Requests>;
  /** insert data into the table: "automation_flows" */
  insert_automation_flows?: Maybe<Automation_Flows_Mutation_Response>;
  /** insert a single row into the table: "automation_flows" */
  insert_automation_flows_one?: Maybe<Automation_Flows>;
  /** insert data into the table: "automation_queue" */
  insert_automation_queue?: Maybe<Automation_Queue_Mutation_Response>;
  /** insert a single row into the table: "automation_queue" */
  insert_automation_queue_one?: Maybe<Automation_Queue>;
  /** insert data into the table: "bank_accs" */
  insert_bank_accs?: Maybe<Bank_Accs_Mutation_Response>;
  /** insert a single row into the table: "bank_accs" */
  insert_bank_accs_one?: Maybe<Bank_Accs>;
  /** insert data into the table: "blocks" */
  insert_blocks?: Maybe<Blocks_Mutation_Response>;
  /** insert a single row into the table: "blocks" */
  insert_blocks_one?: Maybe<Blocks>;
  /** insert data into the table: "businesses" */
  insert_businesses?: Maybe<Businesses_Mutation_Response>;
  /** insert a single row into the table: "businesses" */
  insert_businesses_one?: Maybe<Businesses>;
  /** insert data into the table: "calendars" */
  insert_calendars?: Maybe<Calendars_Mutation_Response>;
  /** insert data into the table: "calendars_custom_fields" */
  insert_calendars_custom_fields?: Maybe<Calendars_Custom_Fields_Mutation_Response>;
  /** insert a single row into the table: "calendars_custom_fields" */
  insert_calendars_custom_fields_one?: Maybe<Calendars_Custom_Fields>;
  /** insert a single row into the table: "calendars" */
  insert_calendars_one?: Maybe<Calendars>;
  /** insert data into the table: "calendars_services" */
  insert_calendars_services?: Maybe<Calendars_Services_Mutation_Response>;
  /** insert a single row into the table: "calendars_services" */
  insert_calendars_services_one?: Maybe<Calendars_Services>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>;
  /** insert data into the table: "companies" */
  insert_companies?: Maybe<Companies_Mutation_Response>;
  /** insert data into the table: "companies_categories" */
  insert_companies_categories?: Maybe<Companies_Categories_Mutation_Response>;
  /** insert a single row into the table: "companies_categories" */
  insert_companies_categories_one?: Maybe<Companies_Categories>;
  /** insert a single row into the table: "companies" */
  insert_companies_one?: Maybe<Companies>;
  /** insert data into the table: "contacts" */
  insert_contacts?: Maybe<Contacts_Mutation_Response>;
  /** insert data into the table: "contacts_custom_fields" */
  insert_contacts_custom_fields?: Maybe<Contacts_Custom_Fields_Mutation_Response>;
  /** insert a single row into the table: "contacts_custom_fields" */
  insert_contacts_custom_fields_one?: Maybe<Contacts_Custom_Fields>;
  /** insert a single row into the table: "contacts" */
  insert_contacts_one?: Maybe<Contacts>;
  /** insert data into the table: "contacts_view" */
  insert_contacts_view?: Maybe<Contacts_View_Mutation_Response>;
  /** insert a single row into the table: "contacts_view" */
  insert_contacts_view_one?: Maybe<Contacts_View>;
  /** insert data into the table: "currencies" */
  insert_currencies?: Maybe<Currencies_Mutation_Response>;
  /** insert a single row into the table: "currencies" */
  insert_currencies_one?: Maybe<Currencies>;
  /** insert data into the table: "custom_fields" */
  insert_custom_fields?: Maybe<Custom_Fields_Mutation_Response>;
  /** insert a single row into the table: "custom_fields" */
  insert_custom_fields_one?: Maybe<Custom_Fields>;
  /** insert data into the table: "establishments" */
  insert_establishments?: Maybe<Establishments_Mutation_Response>;
  /** insert a single row into the table: "establishments" */
  insert_establishments_one?: Maybe<Establishments>;
  /** insert data into the table: "establishments_users" */
  insert_establishments_users?: Maybe<Establishments_Users_Mutation_Response>;
  /** insert a single row into the table: "establishments_users" */
  insert_establishments_users_one?: Maybe<Establishments_Users>;
  /** insert data into the table: "orders" */
  insert_orders?: Maybe<Orders_Mutation_Response>;
  /** insert a single row into the table: "orders" */
  insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "payments" */
  insert_payments?: Maybe<Payments_Mutation_Response>;
  /** insert a single row into the table: "payments" */
  insert_payments_one?: Maybe<Payments>;
  /** insert data into the table: "phone_numbers" */
  insert_phone_numbers?: Maybe<Phone_Numbers_Mutation_Response>;
  /** insert data into the table: "phone_numbers_establishments" */
  insert_phone_numbers_establishments?: Maybe<Phone_Numbers_Establishments_Mutation_Response>;
  /** insert a single row into the table: "phone_numbers_establishments" */
  insert_phone_numbers_establishments_one?: Maybe<Phone_Numbers_Establishments>;
  /** insert a single row into the table: "phone_numbers" */
  insert_phone_numbers_one?: Maybe<Phone_Numbers>;
  /** insert data into the table: "phone_numbers_users" */
  insert_phone_numbers_users?: Maybe<Phone_Numbers_Users_Mutation_Response>;
  /** insert a single row into the table: "phone_numbers_users" */
  insert_phone_numbers_users_one?: Maybe<Phone_Numbers_Users>;
  /** insert data into the table: "pricelists" */
  insert_pricelists?: Maybe<Pricelists_Mutation_Response>;
  /** insert a single row into the table: "pricelists" */
  insert_pricelists_one?: Maybe<Pricelists>;
  /** insert data into the table: "regions" */
  insert_regions?: Maybe<Regions_Mutation_Response>;
  /** insert a single row into the table: "regions" */
  insert_regions_one?: Maybe<Regions>;
  /** insert data into the table: "reservations" */
  insert_reservations?: Maybe<Reservations_Mutation_Response>;
  /** insert a single row into the table: "reservations" */
  insert_reservations_one?: Maybe<Reservations>;
  /** insert data into the table: "reservations_services" */
  insert_reservations_services?: Maybe<Reservations_Services_Mutation_Response>;
  /** insert a single row into the table: "reservations_services" */
  insert_reservations_services_one?: Maybe<Reservations_Services>;
  /** insert data into the table: "reservio_integration" */
  insert_reservio_integration?: Maybe<Reservio_Integration_Mutation_Response>;
  /** insert a single row into the table: "reservio_integration" */
  insert_reservio_integration_one?: Maybe<Reservio_Integration>;
  /** insert data into the table: "reservio_integration_reservations" */
  insert_reservio_integration_reservations?: Maybe<Reservio_Integration_Reservations_Mutation_Response>;
  /** insert a single row into the table: "reservio_integration_reservations" */
  insert_reservio_integration_reservations_one?: Maybe<Reservio_Integration_Reservations>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "services" */
  insert_services?: Maybe<Services_Mutation_Response>;
  /** insert a single row into the table: "services" */
  insert_services_one?: Maybe<Services>;
  /** insert data into the table: "shoptet_eshops" */
  insert_shoptet_eshops?: Maybe<Shoptet_Eshops_Mutation_Response>;
  /** insert a single row into the table: "shoptet_eshops" */
  insert_shoptet_eshops_one?: Maybe<Shoptet_Eshops>;
  /** insert data into the table: "shoptet_orders" */
  insert_shoptet_orders?: Maybe<Shoptet_Orders_Mutation_Response>;
  /** insert a single row into the table: "shoptet_orders" */
  insert_shoptet_orders_one?: Maybe<Shoptet_Orders>;
  /** insert data into the table: "smsapi_deliveries" */
  insert_smsapi_deliveries?: Maybe<Smsapi_Deliveries_Mutation_Response>;
  /** insert a single row into the table: "smsapi_deliveries" */
  insert_smsapi_deliveries_one?: Maybe<Smsapi_Deliveries>;
  /** insert data into the table: "smsapi_queue" */
  insert_smsapi_queue?: Maybe<Smsapi_Queue_Mutation_Response>;
  /** insert data into the table: "smsapi_queue_extra" */
  insert_smsapi_queue_extra?: Maybe<Smsapi_Queue_Extra_Mutation_Response>;
  /** insert a single row into the table: "smsapi_queue_extra" */
  insert_smsapi_queue_extra_one?: Maybe<Smsapi_Queue_Extra>;
  /** insert data into the table: "smsapi_queue_extra_tags_history" */
  insert_smsapi_queue_extra_tags_history?: Maybe<Smsapi_Queue_Extra_Tags_History_Mutation_Response>;
  /** insert a single row into the table: "smsapi_queue_extra_tags_history" */
  insert_smsapi_queue_extra_tags_history_one?: Maybe<Smsapi_Queue_Extra_Tags_History>;
  /** insert data into the table: "smsapi_queue_index" */
  insert_smsapi_queue_index?: Maybe<Smsapi_Queue_Index_Mutation_Response>;
  /** insert a single row into the table: "smsapi_queue_index" */
  insert_smsapi_queue_index_one?: Maybe<Smsapi_Queue_Index>;
  /** insert a single row into the table: "smsapi_queue" */
  insert_smsapi_queue_one?: Maybe<Smsapi_Queue>;
  /** insert data into the table: "smsapi_tags" */
  insert_smsapi_tags?: Maybe<Smsapi_Tags_Mutation_Response>;
  /** insert a single row into the table: "smsapi_tags" */
  insert_smsapi_tags_one?: Maybe<Smsapi_Tags>;
  /** insert data into the table: "smsapi_templates" */
  insert_smsapi_templates?: Maybe<Smsapi_Templates_Mutation_Response>;
  /** insert a single row into the table: "smsapi_templates" */
  insert_smsapi_templates_one?: Maybe<Smsapi_Templates>;
  /** insert a single row into the table: "tables" */
  insert_tables_one?: Maybe<Tables>;
  /** insert data into the table: "tables" */
  insert_tables_query?: Maybe<Tables_Mutation_Response>;
  /** insert data into the table: "telidoo_main.users" */
  insert_telidoo_main_users?: Maybe<Telidoo_Main_Users_Mutation_Response>;
  /** insert a single row into the table: "telidoo_main.users" */
  insert_telidoo_main_users_one?: Maybe<Telidoo_Main_Users>;
  /** insert data into the table: "terminal_statements" */
  insert_terminal_statements?: Maybe<Terminal_Statements_Mutation_Response>;
  /** insert a single row into the table: "terminal_statements" */
  insert_terminal_statements_one?: Maybe<Terminal_Statements>;
  /** insert data into the table: "terminals" */
  insert_terminals?: Maybe<Terminals_Mutation_Response>;
  /** insert a single row into the table: "terminals" */
  insert_terminals_one?: Maybe<Terminals>;
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<Transactions_Mutation_Response>;
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "verification_requests" */
  insert_verification_requests?: Maybe<Verification_Requests_Mutation_Response>;
  /** insert a single row into the table: "verification_requests" */
  insert_verification_requests_one?: Maybe<Verification_Requests>;
  /** perform the action: "renew_payment_code" */
  renew_payment_code?: Maybe<RenewPaymentCodeOutput>;
  /** perform the action: "send_payment" */
  send_payment?: Maybe<SendPaymentOutput>;
  /** perform the action: "shoptet_import_contacts" */
  shoptet_import_contacts?: Maybe<ShoptetImportOutput>;
  /** update data of the table: "automation_flows" */
  update_automation_flows?: Maybe<Automation_Flows_Mutation_Response>;
  /** update single row of the table: "automation_flows" */
  update_automation_flows_by_pk?: Maybe<Automation_Flows>;
  /** update data of the table: "automation_queue" */
  update_automation_queue?: Maybe<Automation_Queue_Mutation_Response>;
  /** update single row of the table: "automation_queue" */
  update_automation_queue_by_pk?: Maybe<Automation_Queue>;
  /** update data of the table: "bank_accs" */
  update_bank_accs?: Maybe<Bank_Accs_Mutation_Response>;
  /** update single row of the table: "bank_accs" */
  update_bank_accs_by_pk?: Maybe<Bank_Accs>;
  /** update data of the table: "blocks" */
  update_blocks?: Maybe<Blocks_Mutation_Response>;
  /** update single row of the table: "blocks" */
  update_blocks_by_pk?: Maybe<Blocks>;
  /** update data of the table: "businesses" */
  update_businesses?: Maybe<Businesses_Mutation_Response>;
  /** update single row of the table: "businesses" */
  update_businesses_by_pk?: Maybe<Businesses>;
  /** update data of the table: "calendars" */
  update_calendars?: Maybe<Calendars_Mutation_Response>;
  /** update single row of the table: "calendars" */
  update_calendars_by_pk?: Maybe<Calendars>;
  /** update data of the table: "calendars_custom_fields" */
  update_calendars_custom_fields?: Maybe<Calendars_Custom_Fields_Mutation_Response>;
  /** update single row of the table: "calendars_custom_fields" */
  update_calendars_custom_fields_by_pk?: Maybe<Calendars_Custom_Fields>;
  /** update data of the table: "calendars_services" */
  update_calendars_services?: Maybe<Calendars_Services_Mutation_Response>;
  /** update single row of the table: "calendars_services" */
  update_calendars_services_by_pk?: Maybe<Calendars_Services>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>;
  /** update data of the table: "companies" */
  update_companies?: Maybe<Companies_Mutation_Response>;
  /** update single row of the table: "companies" */
  update_companies_by_pk?: Maybe<Companies>;
  /** update data of the table: "companies_categories" */
  update_companies_categories?: Maybe<Companies_Categories_Mutation_Response>;
  /** update single row of the table: "companies_categories" */
  update_companies_categories_by_pk?: Maybe<Companies_Categories>;
  /** update data of the table: "contacts" */
  update_contacts?: Maybe<Contacts_Mutation_Response>;
  /** update single row of the table: "contacts" */
  update_contacts_by_pk?: Maybe<Contacts>;
  /** update data of the table: "contacts_custom_fields" */
  update_contacts_custom_fields?: Maybe<Contacts_Custom_Fields_Mutation_Response>;
  /** update single row of the table: "contacts_custom_fields" */
  update_contacts_custom_fields_by_pk?: Maybe<Contacts_Custom_Fields>;
  /** update data of the table: "contacts_view" */
  update_contacts_view?: Maybe<Contacts_View_Mutation_Response>;
  /** update data of the table: "currencies" */
  update_currencies?: Maybe<Currencies_Mutation_Response>;
  /** update single row of the table: "currencies" */
  update_currencies_by_pk?: Maybe<Currencies>;
  /** update data of the table: "custom_fields" */
  update_custom_fields?: Maybe<Custom_Fields_Mutation_Response>;
  /** update single row of the table: "custom_fields" */
  update_custom_fields_by_pk?: Maybe<Custom_Fields>;
  /** update data of the table: "establishments" */
  update_establishments?: Maybe<Establishments_Mutation_Response>;
  /** update single row of the table: "establishments" */
  update_establishments_by_pk?: Maybe<Establishments>;
  /** update data of the table: "establishments_users" */
  update_establishments_users?: Maybe<Establishments_Users_Mutation_Response>;
  /** update single row of the table: "establishments_users" */
  update_establishments_users_by_pk?: Maybe<Establishments_Users>;
  /** update data of the table: "orders" */
  update_orders?: Maybe<Orders_Mutation_Response>;
  /** update single row of the table: "orders" */
  update_orders_by_pk?: Maybe<Orders>;
  /** update data of the table: "payments" */
  update_payments?: Maybe<Payments_Mutation_Response>;
  /** update single row of the table: "payments" */
  update_payments_by_pk?: Maybe<Payments>;
  /** update data of the table: "phone_numbers" */
  update_phone_numbers?: Maybe<Phone_Numbers_Mutation_Response>;
  /** update single row of the table: "phone_numbers" */
  update_phone_numbers_by_pk?: Maybe<Phone_Numbers>;
  /** update data of the table: "phone_numbers_establishments" */
  update_phone_numbers_establishments?: Maybe<Phone_Numbers_Establishments_Mutation_Response>;
  /** update single row of the table: "phone_numbers_establishments" */
  update_phone_numbers_establishments_by_pk?: Maybe<Phone_Numbers_Establishments>;
  /** update data of the table: "phone_numbers_users" */
  update_phone_numbers_users?: Maybe<Phone_Numbers_Users_Mutation_Response>;
  /** update single row of the table: "phone_numbers_users" */
  update_phone_numbers_users_by_pk?: Maybe<Phone_Numbers_Users>;
  /** update data of the table: "pricelists" */
  update_pricelists?: Maybe<Pricelists_Mutation_Response>;
  /** update single row of the table: "pricelists" */
  update_pricelists_by_pk?: Maybe<Pricelists>;
  /** update data of the table: "regions" */
  update_regions?: Maybe<Regions_Mutation_Response>;
  /** update single row of the table: "regions" */
  update_regions_by_pk?: Maybe<Regions>;
  /** update data of the table: "reservations" */
  update_reservations?: Maybe<Reservations_Mutation_Response>;
  /** update single row of the table: "reservations" */
  update_reservations_by_pk?: Maybe<Reservations>;
  /** update data of the table: "reservations_services" */
  update_reservations_services?: Maybe<Reservations_Services_Mutation_Response>;
  /** update single row of the table: "reservations_services" */
  update_reservations_services_by_pk?: Maybe<Reservations_Services>;
  /** update data of the table: "reservio_integration" */
  update_reservio_integration?: Maybe<Reservio_Integration_Mutation_Response>;
  /** update single row of the table: "reservio_integration" */
  update_reservio_integration_by_pk?: Maybe<Reservio_Integration>;
  /** update data of the table: "reservio_integration_reservations" */
  update_reservio_integration_reservations?: Maybe<Reservio_Integration_Reservations_Mutation_Response>;
  /** update single row of the table: "reservio_integration_reservations" */
  update_reservio_integration_reservations_by_pk?: Maybe<Reservio_Integration_Reservations>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update data of the table: "services" */
  update_services?: Maybe<Services_Mutation_Response>;
  /** update single row of the table: "services" */
  update_services_by_pk?: Maybe<Services>;
  /** update data of the table: "shoptet_eshops" */
  update_shoptet_eshops?: Maybe<Shoptet_Eshops_Mutation_Response>;
  /** update single row of the table: "shoptet_eshops" */
  update_shoptet_eshops_by_pk?: Maybe<Shoptet_Eshops>;
  /** update data of the table: "shoptet_orders" */
  update_shoptet_orders?: Maybe<Shoptet_Orders_Mutation_Response>;
  /** update single row of the table: "shoptet_orders" */
  update_shoptet_orders_by_pk?: Maybe<Shoptet_Orders>;
  /** update data of the table: "smsapi_deliveries" */
  update_smsapi_deliveries?: Maybe<Smsapi_Deliveries_Mutation_Response>;
  /** update single row of the table: "smsapi_deliveries" */
  update_smsapi_deliveries_by_pk?: Maybe<Smsapi_Deliveries>;
  /** update data of the table: "smsapi_queue" */
  update_smsapi_queue?: Maybe<Smsapi_Queue_Mutation_Response>;
  /** update data of the table: "smsapi_queue_extra" */
  update_smsapi_queue_extra?: Maybe<Smsapi_Queue_Extra_Mutation_Response>;
  /** update single row of the table: "smsapi_queue_extra" */
  update_smsapi_queue_extra_by_pk?: Maybe<Smsapi_Queue_Extra>;
  /** update data of the table: "smsapi_queue_extra_tags_history" */
  update_smsapi_queue_extra_tags_history?: Maybe<Smsapi_Queue_Extra_Tags_History_Mutation_Response>;
  /** update single row of the table: "smsapi_queue_extra_tags_history" */
  update_smsapi_queue_extra_tags_history_by_pk?: Maybe<Smsapi_Queue_Extra_Tags_History>;
  /** update data of the table: "smsapi_queue_index" */
  update_smsapi_queue_index?: Maybe<Smsapi_Queue_Index_Mutation_Response>;
  /** update data of the table: "smsapi_tags" */
  update_smsapi_tags?: Maybe<Smsapi_Tags_Mutation_Response>;
  /** update single row of the table: "smsapi_tags" */
  update_smsapi_tags_by_pk?: Maybe<Smsapi_Tags>;
  /** update data of the table: "smsapi_templates" */
  update_smsapi_templates?: Maybe<Smsapi_Templates_Mutation_Response>;
  /** update single row of the table: "smsapi_templates" */
  update_smsapi_templates_by_pk?: Maybe<Smsapi_Templates>;
  /** update data of the table: "tables" */
  update_tables?: Maybe<Tables_Mutation_Response>;
  /** update single row of the table: "tables" */
  update_tables_by_pk?: Maybe<Tables>;
  /** update data of the table: "telidoo_main.users" */
  update_telidoo_main_users?: Maybe<Telidoo_Main_Users_Mutation_Response>;
  /** update data of the table: "terminal_statements" */
  update_terminal_statements?: Maybe<Terminal_Statements_Mutation_Response>;
  /** update single row of the table: "terminal_statements" */
  update_terminal_statements_by_pk?: Maybe<Terminal_Statements>;
  /** update data of the table: "terminals" */
  update_terminals?: Maybe<Terminals_Mutation_Response>;
  /** update single row of the table: "terminals" */
  update_terminals_by_pk?: Maybe<Terminals>;
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<Transactions_Mutation_Response>;
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update data of the table: "verification_requests" */
  update_verification_requests?: Maybe<Verification_Requests_Mutation_Response>;
  /** update single row of the table: "verification_requests" */
  update_verification_requests_by_pk?: Maybe<Verification_Requests>;
};


/** mutation root */
export type Mutation_RootCentrumsms_SendArgs = {
  message: Scalars['String'];
  recipient: Scalars['String'];
  second_sim?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCentrumsms_Send_GroupArgs = {
  body: Scalars['String'];
  recipients: Scalars['String'];
  second_sim?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreate_PaymentArgs = {
  amount: Scalars['numeric'];
  currency_code: Scalars['String'];
  description: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreate_Reservio_UserArgs = {
  user: CreateReservioUserInput;
};


/** mutation root */
export type Mutation_RootDelete_Automation_FlowsArgs = {
  where: Automation_Flows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Automation_Flows_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Automation_QueueArgs = {
  where: Automation_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Automation_Queue_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Bank_AccsArgs = {
  where: Bank_Accs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bank_Accs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_BlocksArgs = {
  where: Blocks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blocks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_BusinessesArgs = {
  where: Businesses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Businesses_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CalendarsArgs = {
  where: Calendars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Calendars_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Calendars_Custom_FieldsArgs = {
  where: Calendars_Custom_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Calendars_Custom_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Calendars_ServicesArgs = {
  where: Calendars_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Calendars_Services_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CompaniesArgs = {
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Companies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Companies_CategoriesArgs = {
  where: Companies_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Companies_Categories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ContactsArgs = {
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Contacts_Custom_FieldsArgs = {
  where: Contacts_Custom_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contacts_Custom_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Contacts_ViewArgs = {
  where: Contacts_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CurrenciesArgs = {
  where: Currencies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Currencies_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Custom_FieldsArgs = {
  where: Custom_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Custom_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EstablishmentsArgs = {
  where: Establishments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Establishments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Establishments_UsersArgs = {
  where: Establishments_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Establishments_Users_By_PkArgs = {
  establishment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_OrdersArgs = {
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentsArgs = {
  where: Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Phone_NumbersArgs = {
  where: Phone_Numbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Phone_Numbers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Phone_Numbers_EstablishmentsArgs = {
  where: Phone_Numbers_Establishments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Phone_Numbers_Establishments_By_PkArgs = {
  establishment_id: Scalars['uuid'];
  phone_number_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Phone_Numbers_UsersArgs = {
  where: Phone_Numbers_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Phone_Numbers_Users_By_PkArgs = {
  phone_number_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PricelistsArgs = {
  where: Pricelists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pricelists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RegionsArgs = {
  where: Regions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Regions_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ReservationsArgs = {
  where: Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reservations_ServicesArgs = {
  where: Reservations_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservations_Services_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reservio_IntegrationArgs = {
  where: Reservio_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservio_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Reservio_Integration_ReservationsArgs = {
  where: Reservio_Integration_Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reservio_Integration_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ServicesArgs = {
  where: Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Services_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Shoptet_EshopsArgs = {
  where: Shoptet_Eshops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shoptet_Eshops_By_PkArgs = {
  primary_key: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Shoptet_OrdersArgs = {
  where: Shoptet_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shoptet_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_DeliveriesArgs = {
  where: Smsapi_Deliveries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_Deliveries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_QueueArgs = {
  where: Smsapi_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_Queue_ExtraArgs = {
  where: Smsapi_Queue_Extra_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_Queue_Extra_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_Queue_Extra_Tags_HistoryArgs = {
  where: Smsapi_Queue_Extra_Tags_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_Queue_Extra_Tags_History_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_Queue_IndexArgs = {
  where: Smsapi_Queue_Index_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_TagsArgs = {
  where: Smsapi_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_TemplatesArgs = {
  where: Smsapi_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Smsapi_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TablesArgs = {
  where: Tables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tables_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Telidoo_Main_UsersArgs = {
  where: Telidoo_Main_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Terminal_StatementsArgs = {
  where: Terminal_Statements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Terminal_Statements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TerminalsArgs = {
  where: Terminals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Terminals_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TransactionsArgs = {
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Verification_RequestsArgs = {
  where: Verification_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Verification_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Automation_FlowsArgs = {
  objects: Array<Automation_Flows_Insert_Input>;
  on_conflict?: Maybe<Automation_Flows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Automation_Flows_OneArgs = {
  object: Automation_Flows_Insert_Input;
  on_conflict?: Maybe<Automation_Flows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Automation_QueueArgs = {
  objects: Array<Automation_Queue_Insert_Input>;
  on_conflict?: Maybe<Automation_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Automation_Queue_OneArgs = {
  object: Automation_Queue_Insert_Input;
  on_conflict?: Maybe<Automation_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bank_AccsArgs = {
  objects: Array<Bank_Accs_Insert_Input>;
  on_conflict?: Maybe<Bank_Accs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bank_Accs_OneArgs = {
  object: Bank_Accs_Insert_Input;
  on_conflict?: Maybe<Bank_Accs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BlocksArgs = {
  objects: Array<Blocks_Insert_Input>;
  on_conflict?: Maybe<Blocks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blocks_OneArgs = {
  object: Blocks_Insert_Input;
  on_conflict?: Maybe<Blocks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BusinessesArgs = {
  objects: Array<Businesses_Insert_Input>;
  on_conflict?: Maybe<Businesses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Businesses_OneArgs = {
  object: Businesses_Insert_Input;
  on_conflict?: Maybe<Businesses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CalendarsArgs = {
  objects: Array<Calendars_Insert_Input>;
  on_conflict?: Maybe<Calendars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calendars_Custom_FieldsArgs = {
  objects: Array<Calendars_Custom_Fields_Insert_Input>;
  on_conflict?: Maybe<Calendars_Custom_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calendars_Custom_Fields_OneArgs = {
  object: Calendars_Custom_Fields_Insert_Input;
  on_conflict?: Maybe<Calendars_Custom_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calendars_OneArgs = {
  object: Calendars_Insert_Input;
  on_conflict?: Maybe<Calendars_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calendars_ServicesArgs = {
  objects: Array<Calendars_Services_Insert_Input>;
  on_conflict?: Maybe<Calendars_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calendars_Services_OneArgs = {
  object: Calendars_Services_Insert_Input;
  on_conflict?: Maybe<Calendars_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: Maybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: Maybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompaniesArgs = {
  objects: Array<Companies_Insert_Input>;
  on_conflict?: Maybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Companies_CategoriesArgs = {
  objects: Array<Companies_Categories_Insert_Input>;
  on_conflict?: Maybe<Companies_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Companies_Categories_OneArgs = {
  object: Companies_Categories_Insert_Input;
  on_conflict?: Maybe<Companies_Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Companies_OneArgs = {
  object: Companies_Insert_Input;
  on_conflict?: Maybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContactsArgs = {
  objects: Array<Contacts_Insert_Input>;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_Custom_FieldsArgs = {
  objects: Array<Contacts_Custom_Fields_Insert_Input>;
  on_conflict?: Maybe<Contacts_Custom_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_Custom_Fields_OneArgs = {
  object: Contacts_Custom_Fields_Insert_Input;
  on_conflict?: Maybe<Contacts_Custom_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_OneArgs = {
  object: Contacts_Insert_Input;
  on_conflict?: Maybe<Contacts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_ViewArgs = {
  objects: Array<Contacts_View_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Contacts_View_OneArgs = {
  object: Contacts_View_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_CurrenciesArgs = {
  objects: Array<Currencies_Insert_Input>;
  on_conflict?: Maybe<Currencies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Currencies_OneArgs = {
  object: Currencies_Insert_Input;
  on_conflict?: Maybe<Currencies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_FieldsArgs = {
  objects: Array<Custom_Fields_Insert_Input>;
  on_conflict?: Maybe<Custom_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Custom_Fields_OneArgs = {
  object: Custom_Fields_Insert_Input;
  on_conflict?: Maybe<Custom_Fields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EstablishmentsArgs = {
  objects: Array<Establishments_Insert_Input>;
  on_conflict?: Maybe<Establishments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Establishments_OneArgs = {
  object: Establishments_Insert_Input;
  on_conflict?: Maybe<Establishments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Establishments_UsersArgs = {
  objects: Array<Establishments_Users_Insert_Input>;
  on_conflict?: Maybe<Establishments_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Establishments_Users_OneArgs = {
  object: Establishments_Users_Insert_Input;
  on_conflict?: Maybe<Establishments_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrdersArgs = {
  objects: Array<Orders_Insert_Input>;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Orders_OneArgs = {
  object: Orders_Insert_Input;
  on_conflict?: Maybe<Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentsArgs = {
  objects: Array<Payments_Insert_Input>;
  on_conflict?: Maybe<Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payments_OneArgs = {
  object: Payments_Insert_Input;
  on_conflict?: Maybe<Payments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Phone_NumbersArgs = {
  objects: Array<Phone_Numbers_Insert_Input>;
  on_conflict?: Maybe<Phone_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Phone_Numbers_EstablishmentsArgs = {
  objects: Array<Phone_Numbers_Establishments_Insert_Input>;
  on_conflict?: Maybe<Phone_Numbers_Establishments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Phone_Numbers_Establishments_OneArgs = {
  object: Phone_Numbers_Establishments_Insert_Input;
  on_conflict?: Maybe<Phone_Numbers_Establishments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Phone_Numbers_OneArgs = {
  object: Phone_Numbers_Insert_Input;
  on_conflict?: Maybe<Phone_Numbers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Phone_Numbers_UsersArgs = {
  objects: Array<Phone_Numbers_Users_Insert_Input>;
  on_conflict?: Maybe<Phone_Numbers_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Phone_Numbers_Users_OneArgs = {
  object: Phone_Numbers_Users_Insert_Input;
  on_conflict?: Maybe<Phone_Numbers_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PricelistsArgs = {
  objects: Array<Pricelists_Insert_Input>;
  on_conflict?: Maybe<Pricelists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pricelists_OneArgs = {
  object: Pricelists_Insert_Input;
  on_conflict?: Maybe<Pricelists_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RegionsArgs = {
  objects: Array<Regions_Insert_Input>;
  on_conflict?: Maybe<Regions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Regions_OneArgs = {
  object: Regions_Insert_Input;
  on_conflict?: Maybe<Regions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReservationsArgs = {
  objects: Array<Reservations_Insert_Input>;
  on_conflict?: Maybe<Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservations_OneArgs = {
  object: Reservations_Insert_Input;
  on_conflict?: Maybe<Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservations_ServicesArgs = {
  objects: Array<Reservations_Services_Insert_Input>;
  on_conflict?: Maybe<Reservations_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservations_Services_OneArgs = {
  object: Reservations_Services_Insert_Input;
  on_conflict?: Maybe<Reservations_Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservio_IntegrationArgs = {
  objects: Array<Reservio_Integration_Insert_Input>;
  on_conflict?: Maybe<Reservio_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservio_Integration_OneArgs = {
  object: Reservio_Integration_Insert_Input;
  on_conflict?: Maybe<Reservio_Integration_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservio_Integration_ReservationsArgs = {
  objects: Array<Reservio_Integration_Reservations_Insert_Input>;
  on_conflict?: Maybe<Reservio_Integration_Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reservio_Integration_Reservations_OneArgs = {
  object: Reservio_Integration_Reservations_Insert_Input;
  on_conflict?: Maybe<Reservio_Integration_Reservations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: Maybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: Maybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ServicesArgs = {
  objects: Array<Services_Insert_Input>;
  on_conflict?: Maybe<Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Services_OneArgs = {
  object: Services_Insert_Input;
  on_conflict?: Maybe<Services_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shoptet_EshopsArgs = {
  objects: Array<Shoptet_Eshops_Insert_Input>;
  on_conflict?: Maybe<Shoptet_Eshops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shoptet_Eshops_OneArgs = {
  object: Shoptet_Eshops_Insert_Input;
  on_conflict?: Maybe<Shoptet_Eshops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shoptet_OrdersArgs = {
  objects: Array<Shoptet_Orders_Insert_Input>;
  on_conflict?: Maybe<Shoptet_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shoptet_Orders_OneArgs = {
  object: Shoptet_Orders_Insert_Input;
  on_conflict?: Maybe<Shoptet_Orders_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_DeliveriesArgs = {
  objects: Array<Smsapi_Deliveries_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Deliveries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Deliveries_OneArgs = {
  object: Smsapi_Deliveries_Insert_Input;
  on_conflict?: Maybe<Smsapi_Deliveries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_QueueArgs = {
  objects: Array<Smsapi_Queue_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Queue_ExtraArgs = {
  objects: Array<Smsapi_Queue_Extra_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Queue_Extra_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Queue_Extra_OneArgs = {
  object: Smsapi_Queue_Extra_Insert_Input;
  on_conflict?: Maybe<Smsapi_Queue_Extra_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Queue_Extra_Tags_HistoryArgs = {
  objects: Array<Smsapi_Queue_Extra_Tags_History_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Queue_Extra_Tags_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Queue_Extra_Tags_History_OneArgs = {
  object: Smsapi_Queue_Extra_Tags_History_Insert_Input;
  on_conflict?: Maybe<Smsapi_Queue_Extra_Tags_History_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Queue_IndexArgs = {
  objects: Array<Smsapi_Queue_Index_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Queue_Index_OneArgs = {
  object: Smsapi_Queue_Index_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Queue_OneArgs = {
  object: Smsapi_Queue_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_TagsArgs = {
  objects: Array<Smsapi_Tags_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Tags_OneArgs = {
  object: Smsapi_Tags_Insert_Input;
  on_conflict?: Maybe<Smsapi_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_TemplatesArgs = {
  objects: Array<Smsapi_Templates_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Smsapi_Templates_OneArgs = {
  object: Smsapi_Templates_Insert_Input;
  on_conflict?: Maybe<Smsapi_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tables_OneArgs = {
  object: Tables_Insert_Input;
  on_conflict?: Maybe<Tables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tables_QueryArgs = {
  objects: Array<Tables_Insert_Input>;
  on_conflict?: Maybe<Tables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Telidoo_Main_UsersArgs = {
  objects: Array<Telidoo_Main_Users_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Telidoo_Main_Users_OneArgs = {
  object: Telidoo_Main_Users_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Terminal_StatementsArgs = {
  objects: Array<Terminal_Statements_Insert_Input>;
  on_conflict?: Maybe<Terminal_Statements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Terminal_Statements_OneArgs = {
  object: Terminal_Statements_Insert_Input;
  on_conflict?: Maybe<Terminal_Statements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TerminalsArgs = {
  objects: Array<Terminals_Insert_Input>;
  on_conflict?: Maybe<Terminals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Terminals_OneArgs = {
  object: Terminals_Insert_Input;
  on_conflict?: Maybe<Terminals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransactionsArgs = {
  objects: Array<Transactions_Insert_Input>;
  on_conflict?: Maybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transactions_OneArgs = {
  object: Transactions_Insert_Input;
  on_conflict?: Maybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_RequestsArgs = {
  objects: Array<Verification_Requests_Insert_Input>;
  on_conflict?: Maybe<Verification_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verification_Requests_OneArgs = {
  object: Verification_Requests_Insert_Input;
  on_conflict?: Maybe<Verification_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRenew_Payment_CodeArgs = {
  payment_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootSend_PaymentArgs = {
  payment_id: Scalars['uuid'];
  user_contact: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_Automation_FlowsArgs = {
  _append?: Maybe<Automation_Flows_Append_Input>;
  _delete_at_path?: Maybe<Automation_Flows_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Automation_Flows_Delete_Elem_Input>;
  _delete_key?: Maybe<Automation_Flows_Delete_Key_Input>;
  _prepend?: Maybe<Automation_Flows_Prepend_Input>;
  _set?: Maybe<Automation_Flows_Set_Input>;
  where: Automation_Flows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Automation_Flows_By_PkArgs = {
  _append?: Maybe<Automation_Flows_Append_Input>;
  _delete_at_path?: Maybe<Automation_Flows_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Automation_Flows_Delete_Elem_Input>;
  _delete_key?: Maybe<Automation_Flows_Delete_Key_Input>;
  _prepend?: Maybe<Automation_Flows_Prepend_Input>;
  _set?: Maybe<Automation_Flows_Set_Input>;
  pk_columns: Automation_Flows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Automation_QueueArgs = {
  _append?: Maybe<Automation_Queue_Append_Input>;
  _delete_at_path?: Maybe<Automation_Queue_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Automation_Queue_Delete_Elem_Input>;
  _delete_key?: Maybe<Automation_Queue_Delete_Key_Input>;
  _prepend?: Maybe<Automation_Queue_Prepend_Input>;
  _set?: Maybe<Automation_Queue_Set_Input>;
  where: Automation_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Automation_Queue_By_PkArgs = {
  _append?: Maybe<Automation_Queue_Append_Input>;
  _delete_at_path?: Maybe<Automation_Queue_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Automation_Queue_Delete_Elem_Input>;
  _delete_key?: Maybe<Automation_Queue_Delete_Key_Input>;
  _prepend?: Maybe<Automation_Queue_Prepend_Input>;
  _set?: Maybe<Automation_Queue_Set_Input>;
  pk_columns: Automation_Queue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Bank_AccsArgs = {
  _set?: Maybe<Bank_Accs_Set_Input>;
  where: Bank_Accs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bank_Accs_By_PkArgs = {
  _set?: Maybe<Bank_Accs_Set_Input>;
  pk_columns: Bank_Accs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BlocksArgs = {
  _inc?: Maybe<Blocks_Inc_Input>;
  _set?: Maybe<Blocks_Set_Input>;
  where: Blocks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blocks_By_PkArgs = {
  _inc?: Maybe<Blocks_Inc_Input>;
  _set?: Maybe<Blocks_Set_Input>;
  pk_columns: Blocks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BusinessesArgs = {
  _set?: Maybe<Businesses_Set_Input>;
  where: Businesses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Businesses_By_PkArgs = {
  _set?: Maybe<Businesses_Set_Input>;
  pk_columns: Businesses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CalendarsArgs = {
  _inc?: Maybe<Calendars_Inc_Input>;
  _set?: Maybe<Calendars_Set_Input>;
  where: Calendars_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Calendars_By_PkArgs = {
  _inc?: Maybe<Calendars_Inc_Input>;
  _set?: Maybe<Calendars_Set_Input>;
  pk_columns: Calendars_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Calendars_Custom_FieldsArgs = {
  _set?: Maybe<Calendars_Custom_Fields_Set_Input>;
  where: Calendars_Custom_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Calendars_Custom_Fields_By_PkArgs = {
  _set?: Maybe<Calendars_Custom_Fields_Set_Input>;
  pk_columns: Calendars_Custom_Fields_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Calendars_ServicesArgs = {
  _set?: Maybe<Calendars_Services_Set_Input>;
  where: Calendars_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Calendars_Services_By_PkArgs = {
  _set?: Maybe<Calendars_Services_Set_Input>;
  pk_columns: Calendars_Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _set?: Maybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _set?: Maybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CompaniesArgs = {
  _set?: Maybe<Companies_Set_Input>;
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Companies_By_PkArgs = {
  _set?: Maybe<Companies_Set_Input>;
  pk_columns: Companies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Companies_CategoriesArgs = {
  _set?: Maybe<Companies_Categories_Set_Input>;
  where: Companies_Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Companies_Categories_By_PkArgs = {
  _set?: Maybe<Companies_Categories_Set_Input>;
  pk_columns: Companies_Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContactsArgs = {
  _append?: Maybe<Contacts_Append_Input>;
  _delete_at_path?: Maybe<Contacts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Contacts_Delete_Elem_Input>;
  _delete_key?: Maybe<Contacts_Delete_Key_Input>;
  _prepend?: Maybe<Contacts_Prepend_Input>;
  _set?: Maybe<Contacts_Set_Input>;
  where: Contacts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_By_PkArgs = {
  _append?: Maybe<Contacts_Append_Input>;
  _delete_at_path?: Maybe<Contacts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Contacts_Delete_Elem_Input>;
  _delete_key?: Maybe<Contacts_Delete_Key_Input>;
  _prepend?: Maybe<Contacts_Prepend_Input>;
  _set?: Maybe<Contacts_Set_Input>;
  pk_columns: Contacts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_Custom_FieldsArgs = {
  _set?: Maybe<Contacts_Custom_Fields_Set_Input>;
  where: Contacts_Custom_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_Custom_Fields_By_PkArgs = {
  _set?: Maybe<Contacts_Custom_Fields_Set_Input>;
  pk_columns: Contacts_Custom_Fields_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Contacts_ViewArgs = {
  _append?: Maybe<Contacts_View_Append_Input>;
  _delete_at_path?: Maybe<Contacts_View_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Contacts_View_Delete_Elem_Input>;
  _delete_key?: Maybe<Contacts_View_Delete_Key_Input>;
  _prepend?: Maybe<Contacts_View_Prepend_Input>;
  _set?: Maybe<Contacts_View_Set_Input>;
  where: Contacts_View_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CurrenciesArgs = {
  _inc?: Maybe<Currencies_Inc_Input>;
  _set?: Maybe<Currencies_Set_Input>;
  where: Currencies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Currencies_By_PkArgs = {
  _inc?: Maybe<Currencies_Inc_Input>;
  _set?: Maybe<Currencies_Set_Input>;
  pk_columns: Currencies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_FieldsArgs = {
  _set?: Maybe<Custom_Fields_Set_Input>;
  where: Custom_Fields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Custom_Fields_By_PkArgs = {
  _set?: Maybe<Custom_Fields_Set_Input>;
  pk_columns: Custom_Fields_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EstablishmentsArgs = {
  _inc?: Maybe<Establishments_Inc_Input>;
  _set?: Maybe<Establishments_Set_Input>;
  where: Establishments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Establishments_By_PkArgs = {
  _inc?: Maybe<Establishments_Inc_Input>;
  _set?: Maybe<Establishments_Set_Input>;
  pk_columns: Establishments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Establishments_UsersArgs = {
  _set?: Maybe<Establishments_Users_Set_Input>;
  where: Establishments_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Establishments_Users_By_PkArgs = {
  _set?: Maybe<Establishments_Users_Set_Input>;
  pk_columns: Establishments_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrdersArgs = {
  _set?: Maybe<Orders_Set_Input>;
  where: Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Orders_By_PkArgs = {
  _set?: Maybe<Orders_Set_Input>;
  pk_columns: Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentsArgs = {
  _inc?: Maybe<Payments_Inc_Input>;
  _set?: Maybe<Payments_Set_Input>;
  where: Payments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payments_By_PkArgs = {
  _inc?: Maybe<Payments_Inc_Input>;
  _set?: Maybe<Payments_Set_Input>;
  pk_columns: Payments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Phone_NumbersArgs = {
  _inc?: Maybe<Phone_Numbers_Inc_Input>;
  _set?: Maybe<Phone_Numbers_Set_Input>;
  where: Phone_Numbers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Phone_Numbers_By_PkArgs = {
  _inc?: Maybe<Phone_Numbers_Inc_Input>;
  _set?: Maybe<Phone_Numbers_Set_Input>;
  pk_columns: Phone_Numbers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Phone_Numbers_EstablishmentsArgs = {
  _set?: Maybe<Phone_Numbers_Establishments_Set_Input>;
  where: Phone_Numbers_Establishments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Phone_Numbers_Establishments_By_PkArgs = {
  _set?: Maybe<Phone_Numbers_Establishments_Set_Input>;
  pk_columns: Phone_Numbers_Establishments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Phone_Numbers_UsersArgs = {
  _set?: Maybe<Phone_Numbers_Users_Set_Input>;
  where: Phone_Numbers_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Phone_Numbers_Users_By_PkArgs = {
  _set?: Maybe<Phone_Numbers_Users_Set_Input>;
  pk_columns: Phone_Numbers_Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PricelistsArgs = {
  _append?: Maybe<Pricelists_Append_Input>;
  _delete_at_path?: Maybe<Pricelists_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pricelists_Delete_Elem_Input>;
  _delete_key?: Maybe<Pricelists_Delete_Key_Input>;
  _inc?: Maybe<Pricelists_Inc_Input>;
  _prepend?: Maybe<Pricelists_Prepend_Input>;
  _set?: Maybe<Pricelists_Set_Input>;
  where: Pricelists_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pricelists_By_PkArgs = {
  _append?: Maybe<Pricelists_Append_Input>;
  _delete_at_path?: Maybe<Pricelists_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pricelists_Delete_Elem_Input>;
  _delete_key?: Maybe<Pricelists_Delete_Key_Input>;
  _inc?: Maybe<Pricelists_Inc_Input>;
  _prepend?: Maybe<Pricelists_Prepend_Input>;
  _set?: Maybe<Pricelists_Set_Input>;
  pk_columns: Pricelists_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RegionsArgs = {
  _set?: Maybe<Regions_Set_Input>;
  where: Regions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Regions_By_PkArgs = {
  _set?: Maybe<Regions_Set_Input>;
  pk_columns: Regions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReservationsArgs = {
  _inc?: Maybe<Reservations_Inc_Input>;
  _set?: Maybe<Reservations_Set_Input>;
  where: Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservations_By_PkArgs = {
  _inc?: Maybe<Reservations_Inc_Input>;
  _set?: Maybe<Reservations_Set_Input>;
  pk_columns: Reservations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservations_ServicesArgs = {
  _set?: Maybe<Reservations_Services_Set_Input>;
  where: Reservations_Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservations_Services_By_PkArgs = {
  _set?: Maybe<Reservations_Services_Set_Input>;
  pk_columns: Reservations_Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservio_IntegrationArgs = {
  _inc?: Maybe<Reservio_Integration_Inc_Input>;
  _set?: Maybe<Reservio_Integration_Set_Input>;
  where: Reservio_Integration_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservio_Integration_By_PkArgs = {
  _inc?: Maybe<Reservio_Integration_Inc_Input>;
  _set?: Maybe<Reservio_Integration_Set_Input>;
  pk_columns: Reservio_Integration_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reservio_Integration_ReservationsArgs = {
  _set?: Maybe<Reservio_Integration_Reservations_Set_Input>;
  where: Reservio_Integration_Reservations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reservio_Integration_Reservations_By_PkArgs = {
  _set?: Maybe<Reservio_Integration_Reservations_Set_Input>;
  pk_columns: Reservio_Integration_Reservations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: Maybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: Maybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ServicesArgs = {
  _inc?: Maybe<Services_Inc_Input>;
  _set?: Maybe<Services_Set_Input>;
  where: Services_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Services_By_PkArgs = {
  _inc?: Maybe<Services_Inc_Input>;
  _set?: Maybe<Services_Set_Input>;
  pk_columns: Services_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shoptet_EshopsArgs = {
  _set?: Maybe<Shoptet_Eshops_Set_Input>;
  where: Shoptet_Eshops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shoptet_Eshops_By_PkArgs = {
  _set?: Maybe<Shoptet_Eshops_Set_Input>;
  pk_columns: Shoptet_Eshops_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shoptet_OrdersArgs = {
  _inc?: Maybe<Shoptet_Orders_Inc_Input>;
  _set?: Maybe<Shoptet_Orders_Set_Input>;
  where: Shoptet_Orders_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shoptet_Orders_By_PkArgs = {
  _inc?: Maybe<Shoptet_Orders_Inc_Input>;
  _set?: Maybe<Shoptet_Orders_Set_Input>;
  pk_columns: Shoptet_Orders_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_DeliveriesArgs = {
  _inc?: Maybe<Smsapi_Deliveries_Inc_Input>;
  _set?: Maybe<Smsapi_Deliveries_Set_Input>;
  where: Smsapi_Deliveries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_Deliveries_By_PkArgs = {
  _inc?: Maybe<Smsapi_Deliveries_Inc_Input>;
  _set?: Maybe<Smsapi_Deliveries_Set_Input>;
  pk_columns: Smsapi_Deliveries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_QueueArgs = {
  _inc?: Maybe<Smsapi_Queue_Inc_Input>;
  _set?: Maybe<Smsapi_Queue_Set_Input>;
  where: Smsapi_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_Queue_ExtraArgs = {
  _inc?: Maybe<Smsapi_Queue_Extra_Inc_Input>;
  _set?: Maybe<Smsapi_Queue_Extra_Set_Input>;
  where: Smsapi_Queue_Extra_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_Queue_Extra_By_PkArgs = {
  _inc?: Maybe<Smsapi_Queue_Extra_Inc_Input>;
  _set?: Maybe<Smsapi_Queue_Extra_Set_Input>;
  pk_columns: Smsapi_Queue_Extra_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_Queue_Extra_Tags_HistoryArgs = {
  _inc?: Maybe<Smsapi_Queue_Extra_Tags_History_Inc_Input>;
  _set?: Maybe<Smsapi_Queue_Extra_Tags_History_Set_Input>;
  where: Smsapi_Queue_Extra_Tags_History_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_Queue_Extra_Tags_History_By_PkArgs = {
  _inc?: Maybe<Smsapi_Queue_Extra_Tags_History_Inc_Input>;
  _set?: Maybe<Smsapi_Queue_Extra_Tags_History_Set_Input>;
  pk_columns: Smsapi_Queue_Extra_Tags_History_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_Queue_IndexArgs = {
  _inc?: Maybe<Smsapi_Queue_Index_Inc_Input>;
  _set?: Maybe<Smsapi_Queue_Index_Set_Input>;
  where: Smsapi_Queue_Index_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_TagsArgs = {
  _set?: Maybe<Smsapi_Tags_Set_Input>;
  where: Smsapi_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_Tags_By_PkArgs = {
  _set?: Maybe<Smsapi_Tags_Set_Input>;
  pk_columns: Smsapi_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_TemplatesArgs = {
  _append?: Maybe<Smsapi_Templates_Append_Input>;
  _delete_at_path?: Maybe<Smsapi_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Smsapi_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Smsapi_Templates_Delete_Key_Input>;
  _prepend?: Maybe<Smsapi_Templates_Prepend_Input>;
  _set?: Maybe<Smsapi_Templates_Set_Input>;
  where: Smsapi_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Smsapi_Templates_By_PkArgs = {
  _append?: Maybe<Smsapi_Templates_Append_Input>;
  _delete_at_path?: Maybe<Smsapi_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Smsapi_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Smsapi_Templates_Delete_Key_Input>;
  _prepend?: Maybe<Smsapi_Templates_Prepend_Input>;
  _set?: Maybe<Smsapi_Templates_Set_Input>;
  pk_columns: Smsapi_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TablesArgs = {
  _set?: Maybe<Tables_Set_Input>;
  where: Tables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tables_By_PkArgs = {
  _set?: Maybe<Tables_Set_Input>;
  pk_columns: Tables_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Telidoo_Main_UsersArgs = {
  _append?: Maybe<Telidoo_Main_Users_Append_Input>;
  _delete_at_path?: Maybe<Telidoo_Main_Users_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Telidoo_Main_Users_Delete_Elem_Input>;
  _delete_key?: Maybe<Telidoo_Main_Users_Delete_Key_Input>;
  _inc?: Maybe<Telidoo_Main_Users_Inc_Input>;
  _prepend?: Maybe<Telidoo_Main_Users_Prepend_Input>;
  _set?: Maybe<Telidoo_Main_Users_Set_Input>;
  where: Telidoo_Main_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Terminal_StatementsArgs = {
  _set?: Maybe<Terminal_Statements_Set_Input>;
  where: Terminal_Statements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Terminal_Statements_By_PkArgs = {
  _set?: Maybe<Terminal_Statements_Set_Input>;
  pk_columns: Terminal_Statements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TerminalsArgs = {
  _inc?: Maybe<Terminals_Inc_Input>;
  _set?: Maybe<Terminals_Set_Input>;
  where: Terminals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Terminals_By_PkArgs = {
  _inc?: Maybe<Terminals_Inc_Input>;
  _set?: Maybe<Terminals_Set_Input>;
  pk_columns: Terminals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TransactionsArgs = {
  _append?: Maybe<Transactions_Append_Input>;
  _delete_at_path?: Maybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Transactions_Delete_Elem_Input>;
  _delete_key?: Maybe<Transactions_Delete_Key_Input>;
  _inc?: Maybe<Transactions_Inc_Input>;
  _prepend?: Maybe<Transactions_Prepend_Input>;
  _set?: Maybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_By_PkArgs = {
  _append?: Maybe<Transactions_Append_Input>;
  _delete_at_path?: Maybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Transactions_Delete_Elem_Input>;
  _delete_key?: Maybe<Transactions_Delete_Key_Input>;
  _inc?: Maybe<Transactions_Inc_Input>;
  _prepend?: Maybe<Transactions_Prepend_Input>;
  _set?: Maybe<Transactions_Set_Input>;
  pk_columns: Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_RequestsArgs = {
  _set?: Maybe<Verification_Requests_Set_Input>;
  where: Verification_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Verification_Requests_By_PkArgs = {
  _set?: Maybe<Verification_Requests_Set_Input>;
  pk_columns: Verification_Requests_Pk_Columns_Input;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "orders" */
export type Orders = {
  /** An object relationship */
  establishment: Establishments;
  establishment_id: Scalars['uuid'];
  id: Scalars['uuid'];
};

/** aggregated selection of "orders" */
export type Orders_Aggregate = {
  aggregate?: Maybe<Orders_Aggregate_Fields>;
  nodes: Array<Orders>;
};

/** aggregate fields of "orders" */
export type Orders_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Orders_Max_Fields>;
  min?: Maybe<Orders_Min_Fields>;
};


/** aggregate fields of "orders" */
export type Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders" */
export type Orders_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Orders_Max_Order_By>;
  min?: Maybe<Orders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "orders" */
export type Orders_Arr_Rel_Insert_Input = {
  data: Array<Orders_Insert_Input>;
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type Orders_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Orders_Bool_Exp>>>;
  _not?: Maybe<Orders_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Orders_Bool_Exp>>>;
  establishment?: Maybe<Establishments_Bool_Exp>;
  establishment_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "orders" */
export enum Orders_Constraint {
  /** unique or primary key constraint */
  OrdersPkey = 'orders_pkey'
}

/** input type for inserting data into table "orders" */
export type Orders_Insert_Input = {
  establishment?: Maybe<Establishments_Obj_Rel_Insert_Input>;
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Orders_Max_Fields = {
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "orders" */
export type Orders_Max_Order_By = {
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Orders_Min_Fields = {
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "orders" */
export type Orders_Min_Order_By = {
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "orders" */
export type Orders_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type Orders_Obj_Rel_Insert_Input = {
  data: Orders_Insert_Input;
  on_conflict?: Maybe<Orders_On_Conflict>;
};

/** on conflict condition type for table "orders" */
export type Orders_On_Conflict = {
  constraint: Orders_Constraint;
  update_columns: Array<Orders_Update_Column>;
  where?: Maybe<Orders_Bool_Exp>;
};

/** ordering options when selecting data from "orders" */
export type Orders_Order_By = {
  establishment?: Maybe<Establishments_Order_By>;
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: "orders" */
export type Orders_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "orders" */
export enum Orders_Select_Column {
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "orders" */
export type Orders_Set_Input = {
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "orders" */
export enum Orders_Update_Column {
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  Id = 'id'
}

/** columns and relationships of "payments" */
export type Payments = {
  amount: Scalars['numeric'];
  cancelled: Scalars['Boolean'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  currency_code: Scalars['String'];
  date_created: Scalars['timestamptz'];
  date_paid?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  key: Scalars['String'];
  paid: Scalars['Boolean'];
  quick_code: Scalars['Int'];
  quick_code_expires: Scalars['timestamptz'];
};

/** aggregated selection of "payments" */
export type Payments_Aggregate = {
  aggregate?: Maybe<Payments_Aggregate_Fields>;
  nodes: Array<Payments>;
};

/** aggregate fields of "payments" */
export type Payments_Aggregate_Fields = {
  avg?: Maybe<Payments_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Payments_Max_Fields>;
  min?: Maybe<Payments_Min_Fields>;
  stddev?: Maybe<Payments_Stddev_Fields>;
  stddev_pop?: Maybe<Payments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payments_Stddev_Samp_Fields>;
  sum?: Maybe<Payments_Sum_Fields>;
  var_pop?: Maybe<Payments_Var_Pop_Fields>;
  var_samp?: Maybe<Payments_Var_Samp_Fields>;
  variance?: Maybe<Payments_Variance_Fields>;
};


/** aggregate fields of "payments" */
export type Payments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payments" */
export type Payments_Aggregate_Order_By = {
  avg?: Maybe<Payments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payments_Max_Order_By>;
  min?: Maybe<Payments_Min_Order_By>;
  stddev?: Maybe<Payments_Stddev_Order_By>;
  stddev_pop?: Maybe<Payments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payments_Stddev_Samp_Order_By>;
  sum?: Maybe<Payments_Sum_Order_By>;
  var_pop?: Maybe<Payments_Var_Pop_Order_By>;
  var_samp?: Maybe<Payments_Var_Samp_Order_By>;
  variance?: Maybe<Payments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payments" */
export type Payments_Arr_Rel_Insert_Input = {
  data: Array<Payments_Insert_Input>;
  on_conflict?: Maybe<Payments_On_Conflict>;
};

/** aggregate avg on columns */
export type Payments_Avg_Fields = {
  amount?: Maybe<Scalars['Float']>;
  quick_code?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payments" */
export type Payments_Avg_Order_By = {
  amount?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payments". All fields are combined with a logical 'AND'. */
export type Payments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Payments_Bool_Exp>>>;
  _not?: Maybe<Payments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Payments_Bool_Exp>>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  cancelled?: Maybe<Boolean_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  date_created?: Maybe<Timestamptz_Comparison_Exp>;
  date_paid?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  paid?: Maybe<Boolean_Comparison_Exp>;
  quick_code?: Maybe<Int_Comparison_Exp>;
  quick_code_expires?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "payments" */
export enum Payments_Constraint {
  /** unique or primary key constraint */
  PaymentsKeyKey = 'payments_key_key',
  /** unique or primary key constraint */
  PaymentsPkey = 'payments_pkey'
}

/** input type for incrementing integer column in table "payments" */
export type Payments_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
  quick_code?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "payments" */
export type Payments_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_paid?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  quick_code?: Maybe<Scalars['Int']>;
  quick_code_expires?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Payments_Max_Fields = {
  amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_paid?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  quick_code?: Maybe<Scalars['Int']>;
  quick_code_expires?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "payments" */
export type Payments_Max_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  date_created?: Maybe<Order_By>;
  date_paid?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
  quick_code_expires?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payments_Min_Fields = {
  amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_paid?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  quick_code?: Maybe<Scalars['Int']>;
  quick_code_expires?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "payments" */
export type Payments_Min_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  date_created?: Maybe<Order_By>;
  date_paid?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
  quick_code_expires?: Maybe<Order_By>;
};

/** response of any mutation on the table "payments" */
export type Payments_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Payments>;
};

/** input type for inserting object relation for remote table "payments" */
export type Payments_Obj_Rel_Insert_Input = {
  data: Payments_Insert_Input;
  on_conflict?: Maybe<Payments_On_Conflict>;
};

/** on conflict condition type for table "payments" */
export type Payments_On_Conflict = {
  constraint: Payments_Constraint;
  update_columns: Array<Payments_Update_Column>;
  where?: Maybe<Payments_Bool_Exp>;
};

/** ordering options when selecting data from "payments" */
export type Payments_Order_By = {
  amount?: Maybe<Order_By>;
  cancelled?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  date_created?: Maybe<Order_By>;
  date_paid?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  paid?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
  quick_code_expires?: Maybe<Order_By>;
};

/** primary key columns input for table: "payments" */
export type Payments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "payments" */
export enum Payments_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DatePaid = 'date_paid',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Paid = 'paid',
  /** column name */
  QuickCode = 'quick_code',
  /** column name */
  QuickCodeExpires = 'quick_code_expires'
}

/** input type for updating data in table "payments" */
export type Payments_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  date_paid?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  paid?: Maybe<Scalars['Boolean']>;
  quick_code?: Maybe<Scalars['Int']>;
  quick_code_expires?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Payments_Stddev_Fields = {
  amount?: Maybe<Scalars['Float']>;
  quick_code?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payments" */
export type Payments_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payments_Stddev_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
  quick_code?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payments" */
export type Payments_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payments_Stddev_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
  quick_code?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payments" */
export type Payments_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Payments_Sum_Fields = {
  amount?: Maybe<Scalars['numeric']>;
  quick_code?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payments" */
export type Payments_Sum_Order_By = {
  amount?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
};

/** update columns of table "payments" */
export enum Payments_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  DatePaid = 'date_paid',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Paid = 'paid',
  /** column name */
  QuickCode = 'quick_code',
  /** column name */
  QuickCodeExpires = 'quick_code_expires'
}

/** aggregate var_pop on columns */
export type Payments_Var_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
  quick_code?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payments" */
export type Payments_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payments_Var_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
  quick_code?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payments" */
export type Payments_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payments_Variance_Fields = {
  amount?: Maybe<Scalars['Float']>;
  quick_code?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payments" */
export type Payments_Variance_Order_By = {
  amount?: Maybe<Order_By>;
  quick_code?: Maybe<Order_By>;
};

/** columns and relationships of "phone_numbers" */
export type Phone_Numbers = {
  active: Scalars['Boolean'];
  app_connection_active?: Maybe<Scalars['Boolean']>;
  app_connection_id?: Maybe<Scalars['Int']>;
  /** Remote relationship field */
  centrumsms_info?: Maybe<Array<Maybe<CentrumSmsInfo>>>;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregated array relationship */
  companies_aggregate: Companies_Aggregate;
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  phone_number: Scalars['String'];
  /** An array relationship */
  phone_numbers_establishments: Array<Phone_Numbers_Establishments>;
  /** An aggregated array relationship */
  phone_numbers_establishments_aggregate: Phone_Numbers_Establishments_Aggregate;
  /** An array relationship */
  phone_numbers_users: Array<Phone_Numbers_Users>;
  /** An aggregated array relationship */
  phone_numbers_users_aggregate: Phone_Numbers_Users_Aggregate;
};


/** columns and relationships of "phone_numbers" */
export type Phone_NumbersCompaniesArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** columns and relationships of "phone_numbers" */
export type Phone_NumbersCompanies_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** columns and relationships of "phone_numbers" */
export type Phone_NumbersPhone_Numbers_EstablishmentsArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Establishments_Order_By>>;
  where?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
};


/** columns and relationships of "phone_numbers" */
export type Phone_NumbersPhone_Numbers_Establishments_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Establishments_Order_By>>;
  where?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
};


/** columns and relationships of "phone_numbers" */
export type Phone_NumbersPhone_Numbers_UsersArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Users_Order_By>>;
  where?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};


/** columns and relationships of "phone_numbers" */
export type Phone_NumbersPhone_Numbers_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Users_Order_By>>;
  where?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};

/** aggregated selection of "phone_numbers" */
export type Phone_Numbers_Aggregate = {
  aggregate?: Maybe<Phone_Numbers_Aggregate_Fields>;
  nodes: Array<Phone_Numbers>;
};

/** aggregate fields of "phone_numbers" */
export type Phone_Numbers_Aggregate_Fields = {
  avg?: Maybe<Phone_Numbers_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Phone_Numbers_Max_Fields>;
  min?: Maybe<Phone_Numbers_Min_Fields>;
  stddev?: Maybe<Phone_Numbers_Stddev_Fields>;
  stddev_pop?: Maybe<Phone_Numbers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Phone_Numbers_Stddev_Samp_Fields>;
  sum?: Maybe<Phone_Numbers_Sum_Fields>;
  var_pop?: Maybe<Phone_Numbers_Var_Pop_Fields>;
  var_samp?: Maybe<Phone_Numbers_Var_Samp_Fields>;
  variance?: Maybe<Phone_Numbers_Variance_Fields>;
};


/** aggregate fields of "phone_numbers" */
export type Phone_Numbers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Phone_Numbers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "phone_numbers" */
export type Phone_Numbers_Aggregate_Order_By = {
  avg?: Maybe<Phone_Numbers_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Phone_Numbers_Max_Order_By>;
  min?: Maybe<Phone_Numbers_Min_Order_By>;
  stddev?: Maybe<Phone_Numbers_Stddev_Order_By>;
  stddev_pop?: Maybe<Phone_Numbers_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Phone_Numbers_Stddev_Samp_Order_By>;
  sum?: Maybe<Phone_Numbers_Sum_Order_By>;
  var_pop?: Maybe<Phone_Numbers_Var_Pop_Order_By>;
  var_samp?: Maybe<Phone_Numbers_Var_Samp_Order_By>;
  variance?: Maybe<Phone_Numbers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "phone_numbers" */
export type Phone_Numbers_Arr_Rel_Insert_Input = {
  data: Array<Phone_Numbers_Insert_Input>;
  on_conflict?: Maybe<Phone_Numbers_On_Conflict>;
};

/** aggregate avg on columns */
export type Phone_Numbers_Avg_Fields = {
  app_connection_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "phone_numbers" */
export type Phone_Numbers_Avg_Order_By = {
  app_connection_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "phone_numbers". All fields are combined with a logical 'AND'. */
export type Phone_Numbers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Phone_Numbers_Bool_Exp>>>;
  _not?: Maybe<Phone_Numbers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Phone_Numbers_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  app_connection_active?: Maybe<Boolean_Comparison_Exp>;
  app_connection_id?: Maybe<Int_Comparison_Exp>;
  companies?: Maybe<Companies_Bool_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  phone_numbers_establishments?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
  phone_numbers_users?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};

/** unique or primary key constraints on table "phone_numbers" */
export enum Phone_Numbers_Constraint {
  /** unique or primary key constraint */
  PhoneNumbersPhoneNumberKey = 'phone_numbers_phone_number_key',
  /** unique or primary key constraint */
  PhoneNumbersPkey = 'phone_numbers_pkey'
}

/** columns and relationships of "phone_numbers_establishments" */
export type Phone_Numbers_Establishments = {
  /** An object relationship */
  establishment: Establishments;
  establishment_id: Scalars['uuid'];
  /** An object relationship */
  phone_number: Phone_Numbers;
  phone_number_id: Scalars['uuid'];
};

/** aggregated selection of "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Aggregate = {
  aggregate?: Maybe<Phone_Numbers_Establishments_Aggregate_Fields>;
  nodes: Array<Phone_Numbers_Establishments>;
};

/** aggregate fields of "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Phone_Numbers_Establishments_Max_Fields>;
  min?: Maybe<Phone_Numbers_Establishments_Min_Fields>;
};


/** aggregate fields of "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Phone_Numbers_Establishments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Phone_Numbers_Establishments_Max_Order_By>;
  min?: Maybe<Phone_Numbers_Establishments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Arr_Rel_Insert_Input = {
  data: Array<Phone_Numbers_Establishments_Insert_Input>;
  on_conflict?: Maybe<Phone_Numbers_Establishments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "phone_numbers_establishments". All fields are combined with a logical 'AND'. */
export type Phone_Numbers_Establishments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Phone_Numbers_Establishments_Bool_Exp>>>;
  _not?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Phone_Numbers_Establishments_Bool_Exp>>>;
  establishment?: Maybe<Establishments_Bool_Exp>;
  establishment_id?: Maybe<Uuid_Comparison_Exp>;
  phone_number?: Maybe<Phone_Numbers_Bool_Exp>;
  phone_number_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "phone_numbers_establishments" */
export enum Phone_Numbers_Establishments_Constraint {
  /** unique or primary key constraint */
  PhoneNumbersEstablishmentsPkey = 'phone_numbers_establishments_pkey'
}

/** input type for inserting data into table "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Insert_Input = {
  establishment?: Maybe<Establishments_Obj_Rel_Insert_Input>;
  establishment_id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Phone_Numbers_Obj_Rel_Insert_Input>;
  phone_number_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Phone_Numbers_Establishments_Max_Fields = {
  establishment_id?: Maybe<Scalars['uuid']>;
  phone_number_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Max_Order_By = {
  establishment_id?: Maybe<Order_By>;
  phone_number_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Phone_Numbers_Establishments_Min_Fields = {
  establishment_id?: Maybe<Scalars['uuid']>;
  phone_number_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Min_Order_By = {
  establishment_id?: Maybe<Order_By>;
  phone_number_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Phone_Numbers_Establishments>;
};

/** input type for inserting object relation for remote table "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Obj_Rel_Insert_Input = {
  data: Phone_Numbers_Establishments_Insert_Input;
  on_conflict?: Maybe<Phone_Numbers_Establishments_On_Conflict>;
};

/** on conflict condition type for table "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_On_Conflict = {
  constraint: Phone_Numbers_Establishments_Constraint;
  update_columns: Array<Phone_Numbers_Establishments_Update_Column>;
  where?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
};

/** ordering options when selecting data from "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Order_By = {
  establishment?: Maybe<Establishments_Order_By>;
  establishment_id?: Maybe<Order_By>;
  phone_number?: Maybe<Phone_Numbers_Order_By>;
  phone_number_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Pk_Columns_Input = {
  establishment_id: Scalars['uuid'];
  phone_number_id: Scalars['uuid'];
};

/** select columns of table "phone_numbers_establishments" */
export enum Phone_Numbers_Establishments_Select_Column {
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  PhoneNumberId = 'phone_number_id'
}

/** input type for updating data in table "phone_numbers_establishments" */
export type Phone_Numbers_Establishments_Set_Input = {
  establishment_id?: Maybe<Scalars['uuid']>;
  phone_number_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "phone_numbers_establishments" */
export enum Phone_Numbers_Establishments_Update_Column {
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  PhoneNumberId = 'phone_number_id'
}

/** input type for incrementing integer column in table "phone_numbers" */
export type Phone_Numbers_Inc_Input = {
  app_connection_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "phone_numbers" */
export type Phone_Numbers_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  app_connection_active?: Maybe<Scalars['Boolean']>;
  app_connection_id?: Maybe<Scalars['Int']>;
  companies?: Maybe<Companies_Arr_Rel_Insert_Input>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_numbers_establishments?: Maybe<Phone_Numbers_Establishments_Arr_Rel_Insert_Input>;
  phone_numbers_users?: Maybe<Phone_Numbers_Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Phone_Numbers_Max_Fields = {
  app_connection_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "phone_numbers" */
export type Phone_Numbers_Max_Order_By = {
  app_connection_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Phone_Numbers_Min_Fields = {
  app_connection_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "phone_numbers" */
export type Phone_Numbers_Min_Order_By = {
  app_connection_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
};

/** response of any mutation on the table "phone_numbers" */
export type Phone_Numbers_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Phone_Numbers>;
};

/** input type for inserting object relation for remote table "phone_numbers" */
export type Phone_Numbers_Obj_Rel_Insert_Input = {
  data: Phone_Numbers_Insert_Input;
  on_conflict?: Maybe<Phone_Numbers_On_Conflict>;
};

/** on conflict condition type for table "phone_numbers" */
export type Phone_Numbers_On_Conflict = {
  constraint: Phone_Numbers_Constraint;
  update_columns: Array<Phone_Numbers_Update_Column>;
  where?: Maybe<Phone_Numbers_Bool_Exp>;
};

/** ordering options when selecting data from "phone_numbers" */
export type Phone_Numbers_Order_By = {
  active?: Maybe<Order_By>;
  app_connection_active?: Maybe<Order_By>;
  app_connection_id?: Maybe<Order_By>;
  companies_aggregate?: Maybe<Companies_Aggregate_Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  phone_numbers_establishments_aggregate?: Maybe<Phone_Numbers_Establishments_Aggregate_Order_By>;
  phone_numbers_users_aggregate?: Maybe<Phone_Numbers_Users_Aggregate_Order_By>;
};

/** primary key columns input for table: "phone_numbers" */
export type Phone_Numbers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "phone_numbers" */
export enum Phone_Numbers_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AppConnectionActive = 'app_connection_active',
  /** column name */
  AppConnectionId = 'app_connection_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number'
}

/** input type for updating data in table "phone_numbers" */
export type Phone_Numbers_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  app_connection_active?: Maybe<Scalars['Boolean']>;
  app_connection_id?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Phone_Numbers_Stddev_Fields = {
  app_connection_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "phone_numbers" */
export type Phone_Numbers_Stddev_Order_By = {
  app_connection_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Phone_Numbers_Stddev_Pop_Fields = {
  app_connection_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "phone_numbers" */
export type Phone_Numbers_Stddev_Pop_Order_By = {
  app_connection_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Phone_Numbers_Stddev_Samp_Fields = {
  app_connection_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "phone_numbers" */
export type Phone_Numbers_Stddev_Samp_Order_By = {
  app_connection_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Phone_Numbers_Sum_Fields = {
  app_connection_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "phone_numbers" */
export type Phone_Numbers_Sum_Order_By = {
  app_connection_id?: Maybe<Order_By>;
};

/** update columns of table "phone_numbers" */
export enum Phone_Numbers_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AppConnectionActive = 'app_connection_active',
  /** column name */
  AppConnectionId = 'app_connection_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number'
}

/** columns and relationships of "phone_numbers_users" */
export type Phone_Numbers_Users = {
  /** An object relationship */
  phone_number: Phone_Numbers;
  phone_number_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "phone_numbers_users" */
export type Phone_Numbers_Users_Aggregate = {
  aggregate?: Maybe<Phone_Numbers_Users_Aggregate_Fields>;
  nodes: Array<Phone_Numbers_Users>;
};

/** aggregate fields of "phone_numbers_users" */
export type Phone_Numbers_Users_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Phone_Numbers_Users_Max_Fields>;
  min?: Maybe<Phone_Numbers_Users_Min_Fields>;
};


/** aggregate fields of "phone_numbers_users" */
export type Phone_Numbers_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Phone_Numbers_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "phone_numbers_users" */
export type Phone_Numbers_Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Phone_Numbers_Users_Max_Order_By>;
  min?: Maybe<Phone_Numbers_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "phone_numbers_users" */
export type Phone_Numbers_Users_Arr_Rel_Insert_Input = {
  data: Array<Phone_Numbers_Users_Insert_Input>;
  on_conflict?: Maybe<Phone_Numbers_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "phone_numbers_users". All fields are combined with a logical 'AND'. */
export type Phone_Numbers_Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Phone_Numbers_Users_Bool_Exp>>>;
  _not?: Maybe<Phone_Numbers_Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Phone_Numbers_Users_Bool_Exp>>>;
  phone_number?: Maybe<Phone_Numbers_Bool_Exp>;
  phone_number_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "phone_numbers_users" */
export enum Phone_Numbers_Users_Constraint {
  /** unique or primary key constraint */
  PhoneNumbersUsersPkey = 'phone_numbers_users_pkey'
}

/** input type for inserting data into table "phone_numbers_users" */
export type Phone_Numbers_Users_Insert_Input = {
  phone_number?: Maybe<Phone_Numbers_Obj_Rel_Insert_Input>;
  phone_number_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Phone_Numbers_Users_Max_Fields = {
  phone_number_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "phone_numbers_users" */
export type Phone_Numbers_Users_Max_Order_By = {
  phone_number_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Phone_Numbers_Users_Min_Fields = {
  phone_number_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "phone_numbers_users" */
export type Phone_Numbers_Users_Min_Order_By = {
  phone_number_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "phone_numbers_users" */
export type Phone_Numbers_Users_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Phone_Numbers_Users>;
};

/** input type for inserting object relation for remote table "phone_numbers_users" */
export type Phone_Numbers_Users_Obj_Rel_Insert_Input = {
  data: Phone_Numbers_Users_Insert_Input;
  on_conflict?: Maybe<Phone_Numbers_Users_On_Conflict>;
};

/** on conflict condition type for table "phone_numbers_users" */
export type Phone_Numbers_Users_On_Conflict = {
  constraint: Phone_Numbers_Users_Constraint;
  update_columns: Array<Phone_Numbers_Users_Update_Column>;
  where?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};

/** ordering options when selecting data from "phone_numbers_users" */
export type Phone_Numbers_Users_Order_By = {
  phone_number?: Maybe<Phone_Numbers_Order_By>;
  phone_number_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "phone_numbers_users" */
export type Phone_Numbers_Users_Pk_Columns_Input = {
  phone_number_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** select columns of table "phone_numbers_users" */
export enum Phone_Numbers_Users_Select_Column {
  /** column name */
  PhoneNumberId = 'phone_number_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "phone_numbers_users" */
export type Phone_Numbers_Users_Set_Input = {
  phone_number_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "phone_numbers_users" */
export enum Phone_Numbers_Users_Update_Column {
  /** column name */
  PhoneNumberId = 'phone_number_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Phone_Numbers_Var_Pop_Fields = {
  app_connection_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "phone_numbers" */
export type Phone_Numbers_Var_Pop_Order_By = {
  app_connection_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Phone_Numbers_Var_Samp_Fields = {
  app_connection_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "phone_numbers" */
export type Phone_Numbers_Var_Samp_Order_By = {
  app_connection_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Phone_Numbers_Variance_Fields = {
  app_connection_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "phone_numbers" */
export type Phone_Numbers_Variance_Order_By = {
  app_connection_id?: Maybe<Order_By>;
};

/** columns and relationships of "pricelists" */
export type Pricelists = {
  category: Scalars['String'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  /** An object relationship */
  currency: Currencies;
  currency_code: Scalars['String'];
  description: Scalars['String'];
  /** An object relationship */
  establishment?: Maybe<Establishments>;
  establishment_id?: Maybe<Scalars['uuid']>;
  extra_data?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  photo_url?: Maybe<Scalars['String']>;
  price: Scalars['money'];
  public: Scalars['Boolean'];
  tax_percent: Scalars['Int'];
};


/** columns and relationships of "pricelists" */
export type PricelistsExtra_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pricelists" */
export type Pricelists_Aggregate = {
  aggregate?: Maybe<Pricelists_Aggregate_Fields>;
  nodes: Array<Pricelists>;
};

/** aggregate fields of "pricelists" */
export type Pricelists_Aggregate_Fields = {
  avg?: Maybe<Pricelists_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Pricelists_Max_Fields>;
  min?: Maybe<Pricelists_Min_Fields>;
  stddev?: Maybe<Pricelists_Stddev_Fields>;
  stddev_pop?: Maybe<Pricelists_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pricelists_Stddev_Samp_Fields>;
  sum?: Maybe<Pricelists_Sum_Fields>;
  var_pop?: Maybe<Pricelists_Var_Pop_Fields>;
  var_samp?: Maybe<Pricelists_Var_Samp_Fields>;
  variance?: Maybe<Pricelists_Variance_Fields>;
};


/** aggregate fields of "pricelists" */
export type Pricelists_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pricelists_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pricelists" */
export type Pricelists_Aggregate_Order_By = {
  avg?: Maybe<Pricelists_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Pricelists_Max_Order_By>;
  min?: Maybe<Pricelists_Min_Order_By>;
  stddev?: Maybe<Pricelists_Stddev_Order_By>;
  stddev_pop?: Maybe<Pricelists_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Pricelists_Stddev_Samp_Order_By>;
  sum?: Maybe<Pricelists_Sum_Order_By>;
  var_pop?: Maybe<Pricelists_Var_Pop_Order_By>;
  var_samp?: Maybe<Pricelists_Var_Samp_Order_By>;
  variance?: Maybe<Pricelists_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pricelists_Append_Input = {
  extra_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "pricelists" */
export type Pricelists_Arr_Rel_Insert_Input = {
  data: Array<Pricelists_Insert_Input>;
  on_conflict?: Maybe<Pricelists_On_Conflict>;
};

/** aggregate avg on columns */
export type Pricelists_Avg_Fields = {
  price?: Maybe<Scalars['Float']>;
  tax_percent?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pricelists" */
export type Pricelists_Avg_Order_By = {
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pricelists". All fields are combined with a logical 'AND'. */
export type Pricelists_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Pricelists_Bool_Exp>>>;
  _not?: Maybe<Pricelists_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Pricelists_Bool_Exp>>>;
  category?: Maybe<String_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  currency?: Maybe<Currencies_Bool_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  establishment?: Maybe<Establishments_Bool_Exp>;
  establishment_id?: Maybe<Uuid_Comparison_Exp>;
  extra_data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  photo_url?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Money_Comparison_Exp>;
  public?: Maybe<Boolean_Comparison_Exp>;
  tax_percent?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "pricelists" */
export enum Pricelists_Constraint {
  /** unique or primary key constraint */
  PricelistsPkey = 'pricelists_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pricelists_Delete_At_Path_Input = {
  extra_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pricelists_Delete_Elem_Input = {
  extra_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pricelists_Delete_Key_Input = {
  extra_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "pricelists" */
export type Pricelists_Inc_Input = {
  price?: Maybe<Scalars['money']>;
  tax_percent?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pricelists" */
export type Pricelists_Insert_Input = {
  category?: Maybe<Scalars['String']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  currency?: Maybe<Currencies_Obj_Rel_Insert_Input>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  establishment?: Maybe<Establishments_Obj_Rel_Insert_Input>;
  establishment_id?: Maybe<Scalars['uuid']>;
  extra_data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['money']>;
  public?: Maybe<Scalars['Boolean']>;
  tax_percent?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Pricelists_Max_Fields = {
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['money']>;
  tax_percent?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "pricelists" */
export type Pricelists_Max_Order_By = {
  category?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Pricelists_Min_Fields = {
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['money']>;
  tax_percent?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "pricelists" */
export type Pricelists_Min_Order_By = {
  category?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** response of any mutation on the table "pricelists" */
export type Pricelists_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Pricelists>;
};

/** input type for inserting object relation for remote table "pricelists" */
export type Pricelists_Obj_Rel_Insert_Input = {
  data: Pricelists_Insert_Input;
  on_conflict?: Maybe<Pricelists_On_Conflict>;
};

/** on conflict condition type for table "pricelists" */
export type Pricelists_On_Conflict = {
  constraint: Pricelists_Constraint;
  update_columns: Array<Pricelists_Update_Column>;
  where?: Maybe<Pricelists_Bool_Exp>;
};

/** ordering options when selecting data from "pricelists" */
export type Pricelists_Order_By = {
  category?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  currency?: Maybe<Currencies_Order_By>;
  currency_code?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  establishment?: Maybe<Establishments_Order_By>;
  establishment_id?: Maybe<Order_By>;
  extra_data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  public?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** primary key columns input for table: "pricelists" */
export type Pricelists_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pricelists_Prepend_Input = {
  extra_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "pricelists" */
export enum Pricelists_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Description = 'description',
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  ExtraData = 'extra_data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  Price = 'price',
  /** column name */
  Public = 'public',
  /** column name */
  TaxPercent = 'tax_percent'
}

/** input type for updating data in table "pricelists" */
export type Pricelists_Set_Input = {
  category?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  establishment_id?: Maybe<Scalars['uuid']>;
  extra_data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['money']>;
  public?: Maybe<Scalars['Boolean']>;
  tax_percent?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Pricelists_Stddev_Fields = {
  price?: Maybe<Scalars['Float']>;
  tax_percent?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pricelists" */
export type Pricelists_Stddev_Order_By = {
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pricelists_Stddev_Pop_Fields = {
  price?: Maybe<Scalars['Float']>;
  tax_percent?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pricelists" */
export type Pricelists_Stddev_Pop_Order_By = {
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pricelists_Stddev_Samp_Fields = {
  price?: Maybe<Scalars['Float']>;
  tax_percent?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pricelists" */
export type Pricelists_Stddev_Samp_Order_By = {
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Pricelists_Sum_Fields = {
  price?: Maybe<Scalars['money']>;
  tax_percent?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pricelists" */
export type Pricelists_Sum_Order_By = {
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** update columns of table "pricelists" */
export enum Pricelists_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  Description = 'description',
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  ExtraData = 'extra_data',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  Price = 'price',
  /** column name */
  Public = 'public',
  /** column name */
  TaxPercent = 'tax_percent'
}

/** aggregate var_pop on columns */
export type Pricelists_Var_Pop_Fields = {
  price?: Maybe<Scalars['Float']>;
  tax_percent?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pricelists" */
export type Pricelists_Var_Pop_Order_By = {
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pricelists_Var_Samp_Fields = {
  price?: Maybe<Scalars['Float']>;
  tax_percent?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pricelists" */
export type Pricelists_Var_Samp_Order_By = {
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Pricelists_Variance_Fields = {
  price?: Maybe<Scalars['Float']>;
  tax_percent?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pricelists" */
export type Pricelists_Variance_Order_By = {
  price?: Maybe<Order_By>;
  tax_percent?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  /** fetch data from the table: "automation_flows" */
  automation_flows: Array<Automation_Flows>;
  /** fetch aggregated fields from the table: "automation_flows" */
  automation_flows_aggregate: Automation_Flows_Aggregate;
  /** fetch data from the table: "automation_flows" using primary key columns */
  automation_flows_by_pk?: Maybe<Automation_Flows>;
  /** fetch data from the table: "automation_queue" */
  automation_queue: Array<Automation_Queue>;
  /** fetch aggregated fields from the table: "automation_queue" */
  automation_queue_aggregate: Automation_Queue_Aggregate;
  /** fetch data from the table: "automation_queue" using primary key columns */
  automation_queue_by_pk?: Maybe<Automation_Queue>;
  /** fetch data from the table: "bank_accs" */
  bank_accs: Array<Bank_Accs>;
  /** fetch aggregated fields from the table: "bank_accs" */
  bank_accs_aggregate: Bank_Accs_Aggregate;
  /** fetch data from the table: "bank_accs" using primary key columns */
  bank_accs_by_pk?: Maybe<Bank_Accs>;
  /** fetch data from the table: "blocks" */
  blocks: Array<Blocks>;
  /** fetch aggregated fields from the table: "blocks" */
  blocks_aggregate: Blocks_Aggregate;
  /** fetch data from the table: "blocks" using primary key columns */
  blocks_by_pk?: Maybe<Blocks>;
  /** fetch data from the table: "businesses" */
  businesses: Array<Businesses>;
  /** fetch aggregated fields from the table: "businesses" */
  businesses_aggregate: Businesses_Aggregate;
  /** fetch data from the table: "businesses" using primary key columns */
  businesses_by_pk?: Maybe<Businesses>;
  /** fetch data from the table: "calendars" */
  calendars: Array<Calendars>;
  /** fetch aggregated fields from the table: "calendars" */
  calendars_aggregate: Calendars_Aggregate;
  /** fetch data from the table: "calendars" using primary key columns */
  calendars_by_pk?: Maybe<Calendars>;
  /** fetch data from the table: "calendars_custom_fields" */
  calendars_custom_fields: Array<Calendars_Custom_Fields>;
  /** fetch aggregated fields from the table: "calendars_custom_fields" */
  calendars_custom_fields_aggregate: Calendars_Custom_Fields_Aggregate;
  /** fetch data from the table: "calendars_custom_fields" using primary key columns */
  calendars_custom_fields_by_pk?: Maybe<Calendars_Custom_Fields>;
  /** fetch data from the table: "calendars_services" */
  calendars_services: Array<Calendars_Services>;
  /** fetch aggregated fields from the table: "calendars_services" */
  calendars_services_aggregate: Calendars_Services_Aggregate;
  /** fetch data from the table: "calendars_services" using primary key columns */
  calendars_services_by_pk?: Maybe<Calendars_Services>;
  /** fetch data from the table: "categories" */
  categories: Array<Categories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  centrumsms_info?: Maybe<Array<Maybe<CentrumSmsInfo>>>;
  /** fetch data from the table: "companies" */
  companies: Array<Companies>;
  /** fetch aggregated fields from the table: "companies" */
  companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table: "companies_categories" */
  companies_categories: Array<Companies_Categories>;
  /** fetch aggregated fields from the table: "companies_categories" */
  companies_categories_aggregate: Companies_Categories_Aggregate;
  /** fetch data from the table: "companies_categories" using primary key columns */
  companies_categories_by_pk?: Maybe<Companies_Categories>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "contacts_custom_fields" */
  contacts_custom_fields: Array<Contacts_Custom_Fields>;
  /** fetch aggregated fields from the table: "contacts_custom_fields" */
  contacts_custom_fields_aggregate: Contacts_Custom_Fields_Aggregate;
  /** fetch data from the table: "contacts_custom_fields" using primary key columns */
  contacts_custom_fields_by_pk?: Maybe<Contacts_Custom_Fields>;
  /** fetch data from the table: "contacts_view" */
  contacts_view: Array<Contacts_View>;
  /** fetch aggregated fields from the table: "contacts_view" */
  contacts_view_aggregate: Contacts_View_Aggregate;
  /** fetch data from the table: "currencies" */
  currencies: Array<Currencies>;
  /** fetch aggregated fields from the table: "currencies" */
  currencies_aggregate: Currencies_Aggregate;
  /** fetch data from the table: "currencies" using primary key columns */
  currencies_by_pk?: Maybe<Currencies>;
  /** fetch data from the table: "custom_fields" */
  custom_fields: Array<Custom_Fields>;
  /** fetch aggregated fields from the table: "custom_fields" */
  custom_fields_aggregate: Custom_Fields_Aggregate;
  /** fetch data from the table: "custom_fields" using primary key columns */
  custom_fields_by_pk?: Maybe<Custom_Fields>;
  /** fetch data from the table: "establishments" */
  establishments: Array<Establishments>;
  /** fetch aggregated fields from the table: "establishments" */
  establishments_aggregate: Establishments_Aggregate;
  /** fetch data from the table: "establishments" using primary key columns */
  establishments_by_pk?: Maybe<Establishments>;
  /** fetch data from the table: "establishments_users" */
  establishments_users: Array<Establishments_Users>;
  /** fetch aggregated fields from the table: "establishments_users" */
  establishments_users_aggregate: Establishments_Users_Aggregate;
  /** fetch data from the table: "establishments_users" using primary key columns */
  establishments_users_by_pk?: Maybe<Establishments_Users>;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** fetch aggregated fields from the table: "orders" */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "payments" */
  payments: Array<Payments>;
  /** fetch aggregated fields from the table: "payments" */
  payments_aggregate: Payments_Aggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** fetch data from the table: "phone_numbers" */
  phone_numbers: Array<Phone_Numbers>;
  /** fetch aggregated fields from the table: "phone_numbers" */
  phone_numbers_aggregate: Phone_Numbers_Aggregate;
  /** fetch data from the table: "phone_numbers" using primary key columns */
  phone_numbers_by_pk?: Maybe<Phone_Numbers>;
  /** fetch data from the table: "phone_numbers_establishments" */
  phone_numbers_establishments: Array<Phone_Numbers_Establishments>;
  /** fetch aggregated fields from the table: "phone_numbers_establishments" */
  phone_numbers_establishments_aggregate: Phone_Numbers_Establishments_Aggregate;
  /** fetch data from the table: "phone_numbers_establishments" using primary key columns */
  phone_numbers_establishments_by_pk?: Maybe<Phone_Numbers_Establishments>;
  /** fetch data from the table: "phone_numbers_users" */
  phone_numbers_users: Array<Phone_Numbers_Users>;
  /** fetch aggregated fields from the table: "phone_numbers_users" */
  phone_numbers_users_aggregate: Phone_Numbers_Users_Aggregate;
  /** fetch data from the table: "phone_numbers_users" using primary key columns */
  phone_numbers_users_by_pk?: Maybe<Phone_Numbers_Users>;
  /** fetch data from the table: "pricelists" */
  pricelists: Array<Pricelists>;
  /** fetch aggregated fields from the table: "pricelists" */
  pricelists_aggregate: Pricelists_Aggregate;
  /** fetch data from the table: "pricelists" using primary key columns */
  pricelists_by_pk?: Maybe<Pricelists>;
  /** fetch data from the table: "regions" */
  regions: Array<Regions>;
  /** fetch aggregated fields from the table: "regions" */
  regions_aggregate: Regions_Aggregate;
  /** fetch data from the table: "regions" using primary key columns */
  regions_by_pk?: Maybe<Regions>;
  /** fetch data from the table: "reservations" */
  reservations: Array<Reservations>;
  /** fetch aggregated fields from the table: "reservations" */
  reservations_aggregate: Reservations_Aggregate;
  /** fetch data from the table: "reservations" using primary key columns */
  reservations_by_pk?: Maybe<Reservations>;
  /** fetch data from the table: "reservations_services" */
  reservations_services: Array<Reservations_Services>;
  /** fetch aggregated fields from the table: "reservations_services" */
  reservations_services_aggregate: Reservations_Services_Aggregate;
  /** fetch data from the table: "reservations_services" using primary key columns */
  reservations_services_by_pk?: Maybe<Reservations_Services>;
  /** fetch data from the table: "reservio_integration" */
  reservio_integration: Array<Reservio_Integration>;
  /** fetch aggregated fields from the table: "reservio_integration" */
  reservio_integration_aggregate: Reservio_Integration_Aggregate;
  /** fetch data from the table: "reservio_integration" using primary key columns */
  reservio_integration_by_pk?: Maybe<Reservio_Integration>;
  /** fetch data from the table: "reservio_integration_reservations" */
  reservio_integration_reservations: Array<Reservio_Integration_Reservations>;
  /** fetch aggregated fields from the table: "reservio_integration_reservations" */
  reservio_integration_reservations_aggregate: Reservio_Integration_Reservations_Aggregate;
  /** fetch data from the table: "reservio_integration_reservations" using primary key columns */
  reservio_integration_reservations_by_pk?: Maybe<Reservio_Integration_Reservations>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "services" */
  services: Array<Services>;
  /** fetch aggregated fields from the table: "services" */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** fetch data from the table: "shoptet_eshops" */
  shoptet_eshops: Array<Shoptet_Eshops>;
  /** fetch aggregated fields from the table: "shoptet_eshops" */
  shoptet_eshops_aggregate: Shoptet_Eshops_Aggregate;
  /** fetch data from the table: "shoptet_eshops" using primary key columns */
  shoptet_eshops_by_pk?: Maybe<Shoptet_Eshops>;
  /** fetch data from the table: "shoptet_orders" */
  shoptet_orders: Array<Shoptet_Orders>;
  /** fetch aggregated fields from the table: "shoptet_orders" */
  shoptet_orders_aggregate: Shoptet_Orders_Aggregate;
  /** fetch data from the table: "shoptet_orders" using primary key columns */
  shoptet_orders_by_pk?: Maybe<Shoptet_Orders>;
  /** fetch data from the table: "smsapi_deliveries" */
  smsapi_deliveries: Array<Smsapi_Deliveries>;
  /** fetch aggregated fields from the table: "smsapi_deliveries" */
  smsapi_deliveries_aggregate: Smsapi_Deliveries_Aggregate;
  /** fetch data from the table: "smsapi_deliveries" using primary key columns */
  smsapi_deliveries_by_pk?: Maybe<Smsapi_Deliveries>;
  /** fetch data from the table: "smsapi_queue" */
  smsapi_queue: Array<Smsapi_Queue>;
  /** fetch aggregated fields from the table: "smsapi_queue" */
  smsapi_queue_aggregate: Smsapi_Queue_Aggregate;
  /** fetch data from the table: "smsapi_queue_extra" */
  smsapi_queue_extra: Array<Smsapi_Queue_Extra>;
  /** fetch aggregated fields from the table: "smsapi_queue_extra" */
  smsapi_queue_extra_aggregate: Smsapi_Queue_Extra_Aggregate;
  /** fetch data from the table: "smsapi_queue_extra" using primary key columns */
  smsapi_queue_extra_by_pk?: Maybe<Smsapi_Queue_Extra>;
  /** fetch data from the table: "smsapi_queue_extra_tags_history" */
  smsapi_queue_extra_tags_history: Array<Smsapi_Queue_Extra_Tags_History>;
  /** fetch aggregated fields from the table: "smsapi_queue_extra_tags_history" */
  smsapi_queue_extra_tags_history_aggregate: Smsapi_Queue_Extra_Tags_History_Aggregate;
  /** fetch data from the table: "smsapi_queue_extra_tags_history" using primary key columns */
  smsapi_queue_extra_tags_history_by_pk?: Maybe<Smsapi_Queue_Extra_Tags_History>;
  /** fetch data from the table: "smsapi_queue_index" */
  smsapi_queue_index: Array<Smsapi_Queue_Index>;
  /** fetch aggregated fields from the table: "smsapi_queue_index" */
  smsapi_queue_index_aggregate: Smsapi_Queue_Index_Aggregate;
  /** fetch data from the table: "smsapi_tags" */
  smsapi_tags: Array<Smsapi_Tags>;
  /** fetch aggregated fields from the table: "smsapi_tags" */
  smsapi_tags_aggregate: Smsapi_Tags_Aggregate;
  /** fetch data from the table: "smsapi_tags" using primary key columns */
  smsapi_tags_by_pk?: Maybe<Smsapi_Tags>;
  /** fetch data from the table: "smsapi_templates" */
  smsapi_templates: Array<Smsapi_Templates>;
  /** fetch aggregated fields from the table: "smsapi_templates" */
  smsapi_templates_aggregate: Smsapi_Templates_Aggregate;
  /** fetch data from the table: "smsapi_templates" using primary key columns */
  smsapi_templates_by_pk?: Maybe<Smsapi_Templates>;
  /** fetch data from the table: "tables" */
  tables: Array<Tables>;
  /** fetch aggregated fields from the table: "tables" */
  tables_aggregate: Tables_Aggregate;
  /** fetch data from the table: "tables" using primary key columns */
  tables_by_pk?: Maybe<Tables>;
  /** fetch data from the table: "telidoo_main.users" */
  telidoo_main_users: Array<Telidoo_Main_Users>;
  /** fetch aggregated fields from the table: "telidoo_main.users" */
  telidoo_main_users_aggregate: Telidoo_Main_Users_Aggregate;
  /** fetch data from the table: "terminal_statements" */
  terminal_statements: Array<Terminal_Statements>;
  /** fetch aggregated fields from the table: "terminal_statements" */
  terminal_statements_aggregate: Terminal_Statements_Aggregate;
  /** fetch data from the table: "terminal_statements" using primary key columns */
  terminal_statements_by_pk?: Maybe<Terminal_Statements>;
  /** perform the action: "terminal_transactions" */
  terminal_transactions?: Maybe<Array<Maybe<TerminalTransactionsOutput>>>;
  /** perform the action: "terminal_transactions_count" */
  terminal_transactions_count: TerminalTransactionsCountOutput;
  /** fetch data from the table: "terminals" */
  terminals: Array<Terminals>;
  /** fetch aggregated fields from the table: "terminals" */
  terminals_aggregate: Terminals_Aggregate;
  /** fetch data from the table: "terminals" using primary key columns */
  terminals_by_pk?: Maybe<Terminals>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "verification_requests" */
  verification_requests: Array<Verification_Requests>;
  /** fetch aggregated fields from the table: "verification_requests" */
  verification_requests_aggregate: Verification_Requests_Aggregate;
  /** fetch data from the table: "verification_requests" using primary key columns */
  verification_requests_by_pk?: Maybe<Verification_Requests>;
};


/** query root */
export type Query_RootAutomation_FlowsArgs = {
  distinct_on?: Maybe<Array<Automation_Flows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Automation_Flows_Order_By>>;
  where?: Maybe<Automation_Flows_Bool_Exp>;
};


/** query root */
export type Query_RootAutomation_Flows_AggregateArgs = {
  distinct_on?: Maybe<Array<Automation_Flows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Automation_Flows_Order_By>>;
  where?: Maybe<Automation_Flows_Bool_Exp>;
};


/** query root */
export type Query_RootAutomation_Flows_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAutomation_QueueArgs = {
  distinct_on?: Maybe<Array<Automation_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Automation_Queue_Order_By>>;
  where?: Maybe<Automation_Queue_Bool_Exp>;
};


/** query root */
export type Query_RootAutomation_Queue_AggregateArgs = {
  distinct_on?: Maybe<Array<Automation_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Automation_Queue_Order_By>>;
  where?: Maybe<Automation_Queue_Bool_Exp>;
};


/** query root */
export type Query_RootAutomation_Queue_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootBank_AccsArgs = {
  distinct_on?: Maybe<Array<Bank_Accs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Accs_Order_By>>;
  where?: Maybe<Bank_Accs_Bool_Exp>;
};


/** query root */
export type Query_RootBank_Accs_AggregateArgs = {
  distinct_on?: Maybe<Array<Bank_Accs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Accs_Order_By>>;
  where?: Maybe<Bank_Accs_Bool_Exp>;
};


/** query root */
export type Query_RootBank_Accs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootBlocksArgs = {
  distinct_on?: Maybe<Array<Blocks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blocks_Order_By>>;
  where?: Maybe<Blocks_Bool_Exp>;
};


/** query root */
export type Query_RootBlocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Blocks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blocks_Order_By>>;
  where?: Maybe<Blocks_Bool_Exp>;
};


/** query root */
export type Query_RootBlocks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootBusinessesArgs = {
  distinct_on?: Maybe<Array<Businesses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Businesses_Order_By>>;
  where?: Maybe<Businesses_Bool_Exp>;
};


/** query root */
export type Query_RootBusinesses_AggregateArgs = {
  distinct_on?: Maybe<Array<Businesses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Businesses_Order_By>>;
  where?: Maybe<Businesses_Bool_Exp>;
};


/** query root */
export type Query_RootBusinesses_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCalendarsArgs = {
  distinct_on?: Maybe<Array<Calendars_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Order_By>>;
  where?: Maybe<Calendars_Bool_Exp>;
};


/** query root */
export type Query_RootCalendars_AggregateArgs = {
  distinct_on?: Maybe<Array<Calendars_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Order_By>>;
  where?: Maybe<Calendars_Bool_Exp>;
};


/** query root */
export type Query_RootCalendars_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCalendars_Custom_FieldsArgs = {
  distinct_on?: Maybe<Array<Calendars_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Custom_Fields_Order_By>>;
  where?: Maybe<Calendars_Custom_Fields_Bool_Exp>;
};


/** query root */
export type Query_RootCalendars_Custom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Calendars_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Custom_Fields_Order_By>>;
  where?: Maybe<Calendars_Custom_Fields_Bool_Exp>;
};


/** query root */
export type Query_RootCalendars_Custom_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCalendars_ServicesArgs = {
  distinct_on?: Maybe<Array<Calendars_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Services_Order_By>>;
  where?: Maybe<Calendars_Services_Bool_Exp>;
};


/** query root */
export type Query_RootCalendars_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Calendars_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Services_Order_By>>;
  where?: Maybe<Calendars_Services_Bool_Exp>;
};


/** query root */
export type Query_RootCalendars_Services_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


/** query root */
export type Query_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


/** query root */
export type Query_RootCategories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCentrumsms_InfoArgs = {
  userId: Scalars['Int'];
};


/** query root */
export type Query_RootCompaniesArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** query root */
export type Query_RootCompanies_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** query root */
export type Query_RootCompanies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCompanies_CategoriesArgs = {
  distinct_on?: Maybe<Array<Companies_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Categories_Order_By>>;
  where?: Maybe<Companies_Categories_Bool_Exp>;
};


/** query root */
export type Query_RootCompanies_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Categories_Order_By>>;
  where?: Maybe<Companies_Categories_Bool_Exp>;
};


/** query root */
export type Query_RootCompanies_Categories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** query root */
export type Query_RootContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** query root */
export type Query_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootContacts_Custom_FieldsArgs = {
  distinct_on?: Maybe<Array<Contacts_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Custom_Fields_Order_By>>;
  where?: Maybe<Contacts_Custom_Fields_Bool_Exp>;
};


/** query root */
export type Query_RootContacts_Custom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Custom_Fields_Order_By>>;
  where?: Maybe<Contacts_Custom_Fields_Bool_Exp>;
};


/** query root */
export type Query_RootContacts_Custom_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootContacts_ViewArgs = {
  distinct_on?: Maybe<Array<Contacts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_View_Order_By>>;
  where?: Maybe<Contacts_View_Bool_Exp>;
};


/** query root */
export type Query_RootContacts_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_View_Order_By>>;
  where?: Maybe<Contacts_View_Bool_Exp>;
};


/** query root */
export type Query_RootCurrenciesArgs = {
  distinct_on?: Maybe<Array<Currencies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currencies_Order_By>>;
  where?: Maybe<Currencies_Bool_Exp>;
};


/** query root */
export type Query_RootCurrencies_AggregateArgs = {
  distinct_on?: Maybe<Array<Currencies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currencies_Order_By>>;
  where?: Maybe<Currencies_Bool_Exp>;
};


/** query root */
export type Query_RootCurrencies_By_PkArgs = {
  code: Scalars['String'];
};


/** query root */
export type Query_RootCustom_FieldsArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


/** query root */
export type Query_RootCustom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


/** query root */
export type Query_RootCustom_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootEstablishmentsArgs = {
  distinct_on?: Maybe<Array<Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Order_By>>;
  where?: Maybe<Establishments_Bool_Exp>;
};


/** query root */
export type Query_RootEstablishments_AggregateArgs = {
  distinct_on?: Maybe<Array<Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Order_By>>;
  where?: Maybe<Establishments_Bool_Exp>;
};


/** query root */
export type Query_RootEstablishments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootEstablishments_UsersArgs = {
  distinct_on?: Maybe<Array<Establishments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Users_Order_By>>;
  where?: Maybe<Establishments_Users_Bool_Exp>;
};


/** query root */
export type Query_RootEstablishments_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Establishments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Users_Order_By>>;
  where?: Maybe<Establishments_Users_Bool_Exp>;
};


/** query root */
export type Query_RootEstablishments_Users_By_PkArgs = {
  establishment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** query root */
export type Query_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** query root */
export type Query_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** query root */
export type Query_RootOrders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** query root */
export type Query_RootPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** query root */
export type Query_RootPayments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootPhone_NumbersArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Order_By>>;
  where?: Maybe<Phone_Numbers_Bool_Exp>;
};


/** query root */
export type Query_RootPhone_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Order_By>>;
  where?: Maybe<Phone_Numbers_Bool_Exp>;
};


/** query root */
export type Query_RootPhone_Numbers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootPhone_Numbers_EstablishmentsArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Establishments_Order_By>>;
  where?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
};


/** query root */
export type Query_RootPhone_Numbers_Establishments_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Establishments_Order_By>>;
  where?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
};


/** query root */
export type Query_RootPhone_Numbers_Establishments_By_PkArgs = {
  establishment_id: Scalars['uuid'];
  phone_number_id: Scalars['uuid'];
};


/** query root */
export type Query_RootPhone_Numbers_UsersArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Users_Order_By>>;
  where?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};


/** query root */
export type Query_RootPhone_Numbers_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Users_Order_By>>;
  where?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};


/** query root */
export type Query_RootPhone_Numbers_Users_By_PkArgs = {
  phone_number_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** query root */
export type Query_RootPricelistsArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};


/** query root */
export type Query_RootPricelists_AggregateArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};


/** query root */
export type Query_RootPricelists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootRegionsArgs = {
  distinct_on?: Maybe<Array<Regions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Regions_Order_By>>;
  where?: Maybe<Regions_Bool_Exp>;
};


/** query root */
export type Query_RootRegions_AggregateArgs = {
  distinct_on?: Maybe<Array<Regions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Regions_Order_By>>;
  where?: Maybe<Regions_Bool_Exp>;
};


/** query root */
export type Query_RootRegions_By_PkArgs = {
  code: Scalars['String'];
};


/** query root */
export type Query_RootReservationsArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


/** query root */
export type Query_RootReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


/** query root */
export type Query_RootReservations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootReservations_ServicesArgs = {
  distinct_on?: Maybe<Array<Reservations_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Services_Order_By>>;
  where?: Maybe<Reservations_Services_Bool_Exp>;
};


/** query root */
export type Query_RootReservations_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Services_Order_By>>;
  where?: Maybe<Reservations_Services_Bool_Exp>;
};


/** query root */
export type Query_RootReservations_Services_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootReservio_IntegrationArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Order_By>>;
  where?: Maybe<Reservio_Integration_Bool_Exp>;
};


/** query root */
export type Query_RootReservio_Integration_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Order_By>>;
  where?: Maybe<Reservio_Integration_Bool_Exp>;
};


/** query root */
export type Query_RootReservio_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootReservio_Integration_ReservationsArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Reservations_Order_By>>;
  where?: Maybe<Reservio_Integration_Reservations_Bool_Exp>;
};


/** query root */
export type Query_RootReservio_Integration_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Reservations_Order_By>>;
  where?: Maybe<Reservio_Integration_Reservations_Bool_Exp>;
};


/** query root */
export type Query_RootReservio_Integration_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootRolesArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** query root */
export type Query_RootRoles_AggregateArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** query root */
export type Query_RootRoles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootServicesArgs = {
  distinct_on?: Maybe<Array<Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Services_Order_By>>;
  where?: Maybe<Services_Bool_Exp>;
};


/** query root */
export type Query_RootServices_AggregateArgs = {
  distinct_on?: Maybe<Array<Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Services_Order_By>>;
  where?: Maybe<Services_Bool_Exp>;
};


/** query root */
export type Query_RootServices_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootShoptet_EshopsArgs = {
  distinct_on?: Maybe<Array<Shoptet_Eshops_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shoptet_Eshops_Order_By>>;
  where?: Maybe<Shoptet_Eshops_Bool_Exp>;
};


/** query root */
export type Query_RootShoptet_Eshops_AggregateArgs = {
  distinct_on?: Maybe<Array<Shoptet_Eshops_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shoptet_Eshops_Order_By>>;
  where?: Maybe<Shoptet_Eshops_Bool_Exp>;
};


/** query root */
export type Query_RootShoptet_Eshops_By_PkArgs = {
  primary_key: Scalars['uuid'];
};


/** query root */
export type Query_RootShoptet_OrdersArgs = {
  distinct_on?: Maybe<Array<Shoptet_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shoptet_Orders_Order_By>>;
  where?: Maybe<Shoptet_Orders_Bool_Exp>;
};


/** query root */
export type Query_RootShoptet_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Shoptet_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shoptet_Orders_Order_By>>;
  where?: Maybe<Shoptet_Orders_Bool_Exp>;
};


/** query root */
export type Query_RootShoptet_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSmsapi_DeliveriesArgs = {
  distinct_on?: Maybe<Array<Smsapi_Deliveries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Deliveries_Order_By>>;
  where?: Maybe<Smsapi_Deliveries_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Deliveries_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Deliveries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Deliveries_Order_By>>;
  where?: Maybe<Smsapi_Deliveries_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Deliveries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSmsapi_QueueArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Order_By>>;
  where?: Maybe<Smsapi_Queue_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Queue_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Order_By>>;
  where?: Maybe<Smsapi_Queue_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Queue_ExtraArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Queue_Extra_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Queue_Extra_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootSmsapi_Queue_Extra_Tags_HistoryArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Queue_Extra_Tags_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Queue_Extra_Tags_History_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSmsapi_Queue_IndexArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Index_Order_By>>;
  where?: Maybe<Smsapi_Queue_Index_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Queue_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Index_Order_By>>;
  where?: Maybe<Smsapi_Queue_Index_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_TagsArgs = {
  distinct_on?: Maybe<Array<Smsapi_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Tags_Order_By>>;
  where?: Maybe<Smsapi_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Tags_Order_By>>;
  where?: Maybe<Smsapi_Tags_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSmsapi_TemplatesArgs = {
  distinct_on?: Maybe<Array<Smsapi_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Templates_Order_By>>;
  where?: Maybe<Smsapi_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Templates_Order_By>>;
  where?: Maybe<Smsapi_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootSmsapi_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootTablesArgs = {
  distinct_on?: Maybe<Array<Tables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tables_Order_By>>;
  where?: Maybe<Tables_Bool_Exp>;
};


/** query root */
export type Query_RootTables_AggregateArgs = {
  distinct_on?: Maybe<Array<Tables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tables_Order_By>>;
  where?: Maybe<Tables_Bool_Exp>;
};


/** query root */
export type Query_RootTables_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootTelidoo_Main_UsersArgs = {
  distinct_on?: Maybe<Array<Telidoo_Main_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Telidoo_Main_Users_Order_By>>;
  where?: Maybe<Telidoo_Main_Users_Bool_Exp>;
};


/** query root */
export type Query_RootTelidoo_Main_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Telidoo_Main_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Telidoo_Main_Users_Order_By>>;
  where?: Maybe<Telidoo_Main_Users_Bool_Exp>;
};


/** query root */
export type Query_RootTerminal_StatementsArgs = {
  distinct_on?: Maybe<Array<Terminal_Statements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Terminal_Statements_Order_By>>;
  where?: Maybe<Terminal_Statements_Bool_Exp>;
};


/** query root */
export type Query_RootTerminal_Statements_AggregateArgs = {
  distinct_on?: Maybe<Array<Terminal_Statements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Terminal_Statements_Order_By>>;
  where?: Maybe<Terminal_Statements_Bool_Exp>;
};


/** query root */
export type Query_RootTerminal_Statements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootTerminal_TransactionsArgs = {
  api_key: Scalars['String'];
  offset: Scalars['Int'];
  pc3000_company_id: Scalars['Int'];
};


/** query root */
export type Query_RootTerminal_Transactions_CountArgs = {
  api_key: Scalars['String'];
  pc3000_company_id: Scalars['Int'];
};


/** query root */
export type Query_RootTerminalsArgs = {
  distinct_on?: Maybe<Array<Terminals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Terminals_Order_By>>;
  where?: Maybe<Terminals_Bool_Exp>;
};


/** query root */
export type Query_RootTerminals_AggregateArgs = {
  distinct_on?: Maybe<Array<Terminals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Terminals_Order_By>>;
  where?: Maybe<Terminals_Bool_Exp>;
};


/** query root */
export type Query_RootTerminals_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** query root */
export type Query_RootTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** query root */
export type Query_RootTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootVerification_RequestsArgs = {
  distinct_on?: Maybe<Array<Verification_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Verification_Requests_Order_By>>;
  where?: Maybe<Verification_Requests_Bool_Exp>;
};


/** query root */
export type Query_RootVerification_Requests_AggregateArgs = {
  distinct_on?: Maybe<Array<Verification_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Verification_Requests_Order_By>>;
  where?: Maybe<Verification_Requests_Bool_Exp>;
};


/** query root */
export type Query_RootVerification_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "regions" */
export type Regions = {
  code: Scalars['String'];
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregated array relationship */
  companies_aggregate: Companies_Aggregate;
  name: Scalars['String'];
};


/** columns and relationships of "regions" */
export type RegionsCompaniesArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** columns and relationships of "regions" */
export type RegionsCompanies_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};

/** aggregated selection of "regions" */
export type Regions_Aggregate = {
  aggregate?: Maybe<Regions_Aggregate_Fields>;
  nodes: Array<Regions>;
};

/** aggregate fields of "regions" */
export type Regions_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Regions_Max_Fields>;
  min?: Maybe<Regions_Min_Fields>;
};


/** aggregate fields of "regions" */
export type Regions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Regions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "regions" */
export type Regions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Regions_Max_Order_By>;
  min?: Maybe<Regions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "regions" */
export type Regions_Arr_Rel_Insert_Input = {
  data: Array<Regions_Insert_Input>;
  on_conflict?: Maybe<Regions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "regions". All fields are combined with a logical 'AND'. */
export type Regions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Regions_Bool_Exp>>>;
  _not?: Maybe<Regions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Regions_Bool_Exp>>>;
  code?: Maybe<String_Comparison_Exp>;
  companies?: Maybe<Companies_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "regions" */
export enum Regions_Constraint {
  /** unique or primary key constraint */
  RegionsCode = 'regions_code'
}

/** input type for inserting data into table "regions" */
export type Regions_Insert_Input = {
  code?: Maybe<Scalars['String']>;
  companies?: Maybe<Companies_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Regions_Max_Fields = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "regions" */
export type Regions_Max_Order_By = {
  code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Regions_Min_Fields = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "regions" */
export type Regions_Min_Order_By = {
  code?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "regions" */
export type Regions_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Regions>;
};

/** input type for inserting object relation for remote table "regions" */
export type Regions_Obj_Rel_Insert_Input = {
  data: Regions_Insert_Input;
  on_conflict?: Maybe<Regions_On_Conflict>;
};

/** on conflict condition type for table "regions" */
export type Regions_On_Conflict = {
  constraint: Regions_Constraint;
  update_columns: Array<Regions_Update_Column>;
  where?: Maybe<Regions_Bool_Exp>;
};

/** ordering options when selecting data from "regions" */
export type Regions_Order_By = {
  code?: Maybe<Order_By>;
  companies_aggregate?: Maybe<Companies_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "regions" */
export type Regions_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "regions" */
export enum Regions_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "regions" */
export type Regions_Set_Input = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "regions" */
export enum Regions_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Name = 'name'
}

export type RenewPaymentCodeOutput = {
  quick_code: Scalars['Int'];
  quick_code_expires: Scalars['timestamptz'];
};

/** columns and relationships of "reservations" */
export type Reservations = {
  /** An object relationship */
  calendar?: Maybe<Calendars>;
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  custom_values?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  planned_at: Scalars['timestamptz'];
  price?: Maybe<Scalars['Int']>;
  /** An array relationship */
  reservations_services: Array<Reservations_Services>;
  /** An aggregated array relationship */
  reservations_services_aggregate: Reservations_Services_Aggregate;
  state: Scalars['String'];
};


/** columns and relationships of "reservations" */
export type ReservationsReservations_ServicesArgs = {
  distinct_on?: Maybe<Array<Reservations_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Services_Order_By>>;
  where?: Maybe<Reservations_Services_Bool_Exp>;
};


/** columns and relationships of "reservations" */
export type ReservationsReservations_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Services_Order_By>>;
  where?: Maybe<Reservations_Services_Bool_Exp>;
};

/** aggregated selection of "reservations" */
export type Reservations_Aggregate = {
  aggregate?: Maybe<Reservations_Aggregate_Fields>;
  nodes: Array<Reservations>;
};

/** aggregate fields of "reservations" */
export type Reservations_Aggregate_Fields = {
  avg?: Maybe<Reservations_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reservations_Max_Fields>;
  min?: Maybe<Reservations_Min_Fields>;
  stddev?: Maybe<Reservations_Stddev_Fields>;
  stddev_pop?: Maybe<Reservations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reservations_Stddev_Samp_Fields>;
  sum?: Maybe<Reservations_Sum_Fields>;
  var_pop?: Maybe<Reservations_Var_Pop_Fields>;
  var_samp?: Maybe<Reservations_Var_Samp_Fields>;
  variance?: Maybe<Reservations_Variance_Fields>;
};


/** aggregate fields of "reservations" */
export type Reservations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reservations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reservations" */
export type Reservations_Aggregate_Order_By = {
  avg?: Maybe<Reservations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Reservations_Max_Order_By>;
  min?: Maybe<Reservations_Min_Order_By>;
  stddev?: Maybe<Reservations_Stddev_Order_By>;
  stddev_pop?: Maybe<Reservations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Reservations_Stddev_Samp_Order_By>;
  sum?: Maybe<Reservations_Sum_Order_By>;
  var_pop?: Maybe<Reservations_Var_Pop_Order_By>;
  var_samp?: Maybe<Reservations_Var_Samp_Order_By>;
  variance?: Maybe<Reservations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reservations" */
export type Reservations_Arr_Rel_Insert_Input = {
  data: Array<Reservations_Insert_Input>;
  on_conflict?: Maybe<Reservations_On_Conflict>;
};

/** aggregate avg on columns */
export type Reservations_Avg_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reservations" */
export type Reservations_Avg_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reservations". All fields are combined with a logical 'AND'. */
export type Reservations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Reservations_Bool_Exp>>>;
  _not?: Maybe<Reservations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Reservations_Bool_Exp>>>;
  calendar?: Maybe<Calendars_Bool_Exp>;
  calendar_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_values?: Maybe<String_Comparison_Exp>;
  duration?: Maybe<Int_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  planned_at?: Maybe<Timestamptz_Comparison_Exp>;
  price?: Maybe<Int_Comparison_Exp>;
  reservations_services?: Maybe<Reservations_Services_Bool_Exp>;
  state?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservations" */
export enum Reservations_Constraint {
  /** unique or primary key constraint */
  ReservationsPkey = 'reservations_pkey'
}

/** input type for incrementing integer column in table "reservations" */
export type Reservations_Inc_Input = {
  duration?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "reservations" */
export type Reservations_Insert_Input = {
  calendar?: Maybe<Calendars_Obj_Rel_Insert_Input>;
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_values?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  planned_at?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  reservations_services?: Maybe<Reservations_Services_Arr_Rel_Insert_Input>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reservations_Max_Fields = {
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_values?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  planned_at?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "reservations" */
export type Reservations_Max_Order_By = {
  calendar_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_values?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  planned_at?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reservations_Min_Fields = {
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_values?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  planned_at?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "reservations" */
export type Reservations_Min_Order_By = {
  calendar_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_values?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  planned_at?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
};

/** response of any mutation on the table "reservations" */
export type Reservations_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Reservations>;
};

/** input type for inserting object relation for remote table "reservations" */
export type Reservations_Obj_Rel_Insert_Input = {
  data: Reservations_Insert_Input;
  on_conflict?: Maybe<Reservations_On_Conflict>;
};

/** on conflict condition type for table "reservations" */
export type Reservations_On_Conflict = {
  constraint: Reservations_Constraint;
  update_columns: Array<Reservations_Update_Column>;
  where?: Maybe<Reservations_Bool_Exp>;
};

/** ordering options when selecting data from "reservations" */
export type Reservations_Order_By = {
  calendar?: Maybe<Calendars_Order_By>;
  calendar_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_values?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  planned_at?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  reservations_services_aggregate?: Maybe<Reservations_Services_Aggregate_Order_By>;
  state?: Maybe<Order_By>;
};

/** primary key columns input for table: "reservations" */
export type Reservations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reservations" */
export enum Reservations_Select_Column {
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomValues = 'custom_values',
  /** column name */
  Duration = 'duration',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlannedAt = 'planned_at',
  /** column name */
  Price = 'price',
  /** column name */
  State = 'state'
}

/** columns and relationships of "reservations_services" */
export type Reservations_Services = {
  id: Scalars['uuid'];
  /** An object relationship */
  reservation: Reservations;
  reservation_id: Scalars['uuid'];
  /** An object relationship */
  service: Services;
  service_id: Scalars['uuid'];
};

/** aggregated selection of "reservations_services" */
export type Reservations_Services_Aggregate = {
  aggregate?: Maybe<Reservations_Services_Aggregate_Fields>;
  nodes: Array<Reservations_Services>;
};

/** aggregate fields of "reservations_services" */
export type Reservations_Services_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reservations_Services_Max_Fields>;
  min?: Maybe<Reservations_Services_Min_Fields>;
};


/** aggregate fields of "reservations_services" */
export type Reservations_Services_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reservations_Services_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reservations_services" */
export type Reservations_Services_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Reservations_Services_Max_Order_By>;
  min?: Maybe<Reservations_Services_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reservations_services" */
export type Reservations_Services_Arr_Rel_Insert_Input = {
  data: Array<Reservations_Services_Insert_Input>;
  on_conflict?: Maybe<Reservations_Services_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reservations_services". All fields are combined with a logical 'AND'. */
export type Reservations_Services_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Reservations_Services_Bool_Exp>>>;
  _not?: Maybe<Reservations_Services_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Reservations_Services_Bool_Exp>>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  reservation?: Maybe<Reservations_Bool_Exp>;
  reservation_id?: Maybe<Uuid_Comparison_Exp>;
  service?: Maybe<Services_Bool_Exp>;
  service_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservations_services" */
export enum Reservations_Services_Constraint {
  /** unique or primary key constraint */
  ReservationsServicesPkey = 'reservations_services_pkey'
}

/** input type for inserting data into table "reservations_services" */
export type Reservations_Services_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  reservation?: Maybe<Reservations_Obj_Rel_Insert_Input>;
  reservation_id?: Maybe<Scalars['uuid']>;
  service?: Maybe<Services_Obj_Rel_Insert_Input>;
  service_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Reservations_Services_Max_Fields = {
  id?: Maybe<Scalars['uuid']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  service_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "reservations_services" */
export type Reservations_Services_Max_Order_By = {
  id?: Maybe<Order_By>;
  reservation_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reservations_Services_Min_Fields = {
  id?: Maybe<Scalars['uuid']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  service_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "reservations_services" */
export type Reservations_Services_Min_Order_By = {
  id?: Maybe<Order_By>;
  reservation_id?: Maybe<Order_By>;
  service_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "reservations_services" */
export type Reservations_Services_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Reservations_Services>;
};

/** input type for inserting object relation for remote table "reservations_services" */
export type Reservations_Services_Obj_Rel_Insert_Input = {
  data: Reservations_Services_Insert_Input;
  on_conflict?: Maybe<Reservations_Services_On_Conflict>;
};

/** on conflict condition type for table "reservations_services" */
export type Reservations_Services_On_Conflict = {
  constraint: Reservations_Services_Constraint;
  update_columns: Array<Reservations_Services_Update_Column>;
  where?: Maybe<Reservations_Services_Bool_Exp>;
};

/** ordering options when selecting data from "reservations_services" */
export type Reservations_Services_Order_By = {
  id?: Maybe<Order_By>;
  reservation?: Maybe<Reservations_Order_By>;
  reservation_id?: Maybe<Order_By>;
  service?: Maybe<Services_Order_By>;
  service_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "reservations_services" */
export type Reservations_Services_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reservations_services" */
export enum Reservations_Services_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  ServiceId = 'service_id'
}

/** input type for updating data in table "reservations_services" */
export type Reservations_Services_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  service_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "reservations_services" */
export enum Reservations_Services_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  ServiceId = 'service_id'
}

/** input type for updating data in table "reservations" */
export type Reservations_Set_Input = {
  calendar_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_values?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  planned_at?: Maybe<Scalars['timestamptz']>;
  price?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Reservations_Stddev_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reservations" */
export type Reservations_Stddev_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reservations_Stddev_Pop_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reservations" */
export type Reservations_Stddev_Pop_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reservations_Stddev_Samp_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reservations" */
export type Reservations_Stddev_Samp_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Reservations_Sum_Fields = {
  duration?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "reservations" */
export type Reservations_Sum_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** update columns of table "reservations" */
export enum Reservations_Update_Column {
  /** column name */
  CalendarId = 'calendar_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomValues = 'custom_values',
  /** column name */
  Duration = 'duration',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PlannedAt = 'planned_at',
  /** column name */
  Price = 'price',
  /** column name */
  State = 'state'
}

/** aggregate var_pop on columns */
export type Reservations_Var_Pop_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reservations" */
export type Reservations_Var_Pop_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reservations_Var_Samp_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reservations" */
export type Reservations_Var_Samp_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Reservations_Variance_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reservations" */
export type Reservations_Variance_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** columns and relationships of "reservio_integration" */
export type Reservio_Integration = {
  active: Scalars['Boolean'];
  company_id: Scalars['uuid'];
  id: Scalars['uuid'];
  last_fetch?: Maybe<Scalars['timestamptz']>;
  last_login: Scalars['timestamptz'];
  reminder_interval_before: Scalars['Int'];
  reminder_skip_weekend: Scalars['Boolean'];
  reservio_business_id: Scalars['String'];
  reservio_email: Scalars['String'];
  /** An array relationship */
  reservio_integration_reservations: Array<Reservio_Integration_Reservations>;
  /** An aggregated array relationship */
  reservio_integration_reservations_aggregate: Reservio_Integration_Reservations_Aggregate;
  reservio_pass?: Maybe<Scalars['String']>;
  reservio_refresh_token: Scalars['String'];
  reservio_user_id: Scalars['String'];
  /** An object relationship */
  sms_template_accepted?: Maybe<Smsapi_Templates>;
  sms_template_accepted_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sms_template_cancelled?: Maybe<Smsapi_Templates>;
  sms_template_cancelled_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sms_template_confirmed?: Maybe<Smsapi_Templates>;
  sms_template_confirmed_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sms_template_moved?: Maybe<Smsapi_Templates>;
  sms_template_moved_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sms_template_rejected?: Maybe<Smsapi_Templates>;
  sms_template_rejected_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sms_template_reminder?: Maybe<Smsapi_Templates>;
  sms_template_reminder_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "reservio_integration" */
export type Reservio_IntegrationReservio_Integration_ReservationsArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Reservations_Order_By>>;
  where?: Maybe<Reservio_Integration_Reservations_Bool_Exp>;
};


/** columns and relationships of "reservio_integration" */
export type Reservio_IntegrationReservio_Integration_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Reservations_Order_By>>;
  where?: Maybe<Reservio_Integration_Reservations_Bool_Exp>;
};

/** aggregated selection of "reservio_integration" */
export type Reservio_Integration_Aggregate = {
  aggregate?: Maybe<Reservio_Integration_Aggregate_Fields>;
  nodes: Array<Reservio_Integration>;
};

/** aggregate fields of "reservio_integration" */
export type Reservio_Integration_Aggregate_Fields = {
  avg?: Maybe<Reservio_Integration_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reservio_Integration_Max_Fields>;
  min?: Maybe<Reservio_Integration_Min_Fields>;
  stddev?: Maybe<Reservio_Integration_Stddev_Fields>;
  stddev_pop?: Maybe<Reservio_Integration_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reservio_Integration_Stddev_Samp_Fields>;
  sum?: Maybe<Reservio_Integration_Sum_Fields>;
  var_pop?: Maybe<Reservio_Integration_Var_Pop_Fields>;
  var_samp?: Maybe<Reservio_Integration_Var_Samp_Fields>;
  variance?: Maybe<Reservio_Integration_Variance_Fields>;
};


/** aggregate fields of "reservio_integration" */
export type Reservio_Integration_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reservio_Integration_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reservio_integration" */
export type Reservio_Integration_Aggregate_Order_By = {
  avg?: Maybe<Reservio_Integration_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Reservio_Integration_Max_Order_By>;
  min?: Maybe<Reservio_Integration_Min_Order_By>;
  stddev?: Maybe<Reservio_Integration_Stddev_Order_By>;
  stddev_pop?: Maybe<Reservio_Integration_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Reservio_Integration_Stddev_Samp_Order_By>;
  sum?: Maybe<Reservio_Integration_Sum_Order_By>;
  var_pop?: Maybe<Reservio_Integration_Var_Pop_Order_By>;
  var_samp?: Maybe<Reservio_Integration_Var_Samp_Order_By>;
  variance?: Maybe<Reservio_Integration_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reservio_integration" */
export type Reservio_Integration_Arr_Rel_Insert_Input = {
  data: Array<Reservio_Integration_Insert_Input>;
  on_conflict?: Maybe<Reservio_Integration_On_Conflict>;
};

/** aggregate avg on columns */
export type Reservio_Integration_Avg_Fields = {
  reminder_interval_before?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "reservio_integration" */
export type Reservio_Integration_Avg_Order_By = {
  reminder_interval_before?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reservio_integration". All fields are combined with a logical 'AND'. */
export type Reservio_Integration_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Reservio_Integration_Bool_Exp>>>;
  _not?: Maybe<Reservio_Integration_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Reservio_Integration_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_fetch?: Maybe<Timestamptz_Comparison_Exp>;
  last_login?: Maybe<Timestamptz_Comparison_Exp>;
  reminder_interval_before?: Maybe<Int_Comparison_Exp>;
  reminder_skip_weekend?: Maybe<Boolean_Comparison_Exp>;
  reservio_business_id?: Maybe<String_Comparison_Exp>;
  reservio_email?: Maybe<String_Comparison_Exp>;
  reservio_integration_reservations?: Maybe<Reservio_Integration_Reservations_Bool_Exp>;
  reservio_pass?: Maybe<String_Comparison_Exp>;
  reservio_refresh_token?: Maybe<String_Comparison_Exp>;
  reservio_user_id?: Maybe<String_Comparison_Exp>;
  sms_template_accepted?: Maybe<Smsapi_Templates_Bool_Exp>;
  sms_template_accepted_id?: Maybe<Uuid_Comparison_Exp>;
  sms_template_cancelled?: Maybe<Smsapi_Templates_Bool_Exp>;
  sms_template_cancelled_id?: Maybe<Uuid_Comparison_Exp>;
  sms_template_confirmed?: Maybe<Smsapi_Templates_Bool_Exp>;
  sms_template_confirmed_id?: Maybe<Uuid_Comparison_Exp>;
  sms_template_moved?: Maybe<Smsapi_Templates_Bool_Exp>;
  sms_template_moved_id?: Maybe<Uuid_Comparison_Exp>;
  sms_template_rejected?: Maybe<Smsapi_Templates_Bool_Exp>;
  sms_template_rejected_id?: Maybe<Uuid_Comparison_Exp>;
  sms_template_reminder?: Maybe<Smsapi_Templates_Bool_Exp>;
  sms_template_reminder_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservio_integration" */
export enum Reservio_Integration_Constraint {
  /** unique or primary key constraint */
  ReservioIntegrationCompanyIdKey = 'reservio_integration_company_id_key',
  /** unique or primary key constraint */
  ReservioIntegrationPkey = 'reservio_integration_pkey',
  /** unique or primary key constraint */
  ReservioIntegrationReservioUserIdKey = 'reservio_integration_reservio_user_id_key'
}

/** input type for incrementing integer column in table "reservio_integration" */
export type Reservio_Integration_Inc_Input = {
  reminder_interval_before?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "reservio_integration" */
export type Reservio_Integration_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  last_fetch?: Maybe<Scalars['timestamptz']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  reminder_interval_before?: Maybe<Scalars['Int']>;
  reminder_skip_weekend?: Maybe<Scalars['Boolean']>;
  reservio_business_id?: Maybe<Scalars['String']>;
  reservio_email?: Maybe<Scalars['String']>;
  reservio_integration_reservations?: Maybe<Reservio_Integration_Reservations_Arr_Rel_Insert_Input>;
  reservio_pass?: Maybe<Scalars['String']>;
  reservio_refresh_token?: Maybe<Scalars['String']>;
  reservio_user_id?: Maybe<Scalars['String']>;
  sms_template_accepted?: Maybe<Smsapi_Templates_Obj_Rel_Insert_Input>;
  sms_template_accepted_id?: Maybe<Scalars['uuid']>;
  sms_template_cancelled?: Maybe<Smsapi_Templates_Obj_Rel_Insert_Input>;
  sms_template_cancelled_id?: Maybe<Scalars['uuid']>;
  sms_template_confirmed?: Maybe<Smsapi_Templates_Obj_Rel_Insert_Input>;
  sms_template_confirmed_id?: Maybe<Scalars['uuid']>;
  sms_template_moved?: Maybe<Smsapi_Templates_Obj_Rel_Insert_Input>;
  sms_template_moved_id?: Maybe<Scalars['uuid']>;
  sms_template_rejected?: Maybe<Smsapi_Templates_Obj_Rel_Insert_Input>;
  sms_template_rejected_id?: Maybe<Scalars['uuid']>;
  sms_template_reminder?: Maybe<Smsapi_Templates_Obj_Rel_Insert_Input>;
  sms_template_reminder_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Reservio_Integration_Max_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  last_fetch?: Maybe<Scalars['timestamptz']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  reminder_interval_before?: Maybe<Scalars['Int']>;
  reservio_business_id?: Maybe<Scalars['String']>;
  reservio_email?: Maybe<Scalars['String']>;
  reservio_pass?: Maybe<Scalars['String']>;
  reservio_refresh_token?: Maybe<Scalars['String']>;
  reservio_user_id?: Maybe<Scalars['String']>;
  sms_template_accepted_id?: Maybe<Scalars['uuid']>;
  sms_template_cancelled_id?: Maybe<Scalars['uuid']>;
  sms_template_confirmed_id?: Maybe<Scalars['uuid']>;
  sms_template_moved_id?: Maybe<Scalars['uuid']>;
  sms_template_rejected_id?: Maybe<Scalars['uuid']>;
  sms_template_reminder_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "reservio_integration" */
export type Reservio_Integration_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_fetch?: Maybe<Order_By>;
  last_login?: Maybe<Order_By>;
  reminder_interval_before?: Maybe<Order_By>;
  reservio_business_id?: Maybe<Order_By>;
  reservio_email?: Maybe<Order_By>;
  reservio_pass?: Maybe<Order_By>;
  reservio_refresh_token?: Maybe<Order_By>;
  reservio_user_id?: Maybe<Order_By>;
  sms_template_accepted_id?: Maybe<Order_By>;
  sms_template_cancelled_id?: Maybe<Order_By>;
  sms_template_confirmed_id?: Maybe<Order_By>;
  sms_template_moved_id?: Maybe<Order_By>;
  sms_template_rejected_id?: Maybe<Order_By>;
  sms_template_reminder_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reservio_Integration_Min_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  last_fetch?: Maybe<Scalars['timestamptz']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  reminder_interval_before?: Maybe<Scalars['Int']>;
  reservio_business_id?: Maybe<Scalars['String']>;
  reservio_email?: Maybe<Scalars['String']>;
  reservio_pass?: Maybe<Scalars['String']>;
  reservio_refresh_token?: Maybe<Scalars['String']>;
  reservio_user_id?: Maybe<Scalars['String']>;
  sms_template_accepted_id?: Maybe<Scalars['uuid']>;
  sms_template_cancelled_id?: Maybe<Scalars['uuid']>;
  sms_template_confirmed_id?: Maybe<Scalars['uuid']>;
  sms_template_moved_id?: Maybe<Scalars['uuid']>;
  sms_template_rejected_id?: Maybe<Scalars['uuid']>;
  sms_template_reminder_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "reservio_integration" */
export type Reservio_Integration_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_fetch?: Maybe<Order_By>;
  last_login?: Maybe<Order_By>;
  reminder_interval_before?: Maybe<Order_By>;
  reservio_business_id?: Maybe<Order_By>;
  reservio_email?: Maybe<Order_By>;
  reservio_pass?: Maybe<Order_By>;
  reservio_refresh_token?: Maybe<Order_By>;
  reservio_user_id?: Maybe<Order_By>;
  sms_template_accepted_id?: Maybe<Order_By>;
  sms_template_cancelled_id?: Maybe<Order_By>;
  sms_template_confirmed_id?: Maybe<Order_By>;
  sms_template_moved_id?: Maybe<Order_By>;
  sms_template_rejected_id?: Maybe<Order_By>;
  sms_template_reminder_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "reservio_integration" */
export type Reservio_Integration_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Reservio_Integration>;
};

/** input type for inserting object relation for remote table "reservio_integration" */
export type Reservio_Integration_Obj_Rel_Insert_Input = {
  data: Reservio_Integration_Insert_Input;
  on_conflict?: Maybe<Reservio_Integration_On_Conflict>;
};

/** on conflict condition type for table "reservio_integration" */
export type Reservio_Integration_On_Conflict = {
  constraint: Reservio_Integration_Constraint;
  update_columns: Array<Reservio_Integration_Update_Column>;
  where?: Maybe<Reservio_Integration_Bool_Exp>;
};

/** ordering options when selecting data from "reservio_integration" */
export type Reservio_Integration_Order_By = {
  active?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_fetch?: Maybe<Order_By>;
  last_login?: Maybe<Order_By>;
  reminder_interval_before?: Maybe<Order_By>;
  reminder_skip_weekend?: Maybe<Order_By>;
  reservio_business_id?: Maybe<Order_By>;
  reservio_email?: Maybe<Order_By>;
  reservio_integration_reservations_aggregate?: Maybe<Reservio_Integration_Reservations_Aggregate_Order_By>;
  reservio_pass?: Maybe<Order_By>;
  reservio_refresh_token?: Maybe<Order_By>;
  reservio_user_id?: Maybe<Order_By>;
  sms_template_accepted?: Maybe<Smsapi_Templates_Order_By>;
  sms_template_accepted_id?: Maybe<Order_By>;
  sms_template_cancelled?: Maybe<Smsapi_Templates_Order_By>;
  sms_template_cancelled_id?: Maybe<Order_By>;
  sms_template_confirmed?: Maybe<Smsapi_Templates_Order_By>;
  sms_template_confirmed_id?: Maybe<Order_By>;
  sms_template_moved?: Maybe<Smsapi_Templates_Order_By>;
  sms_template_moved_id?: Maybe<Order_By>;
  sms_template_rejected?: Maybe<Smsapi_Templates_Order_By>;
  sms_template_rejected_id?: Maybe<Order_By>;
  sms_template_reminder?: Maybe<Smsapi_Templates_Order_By>;
  sms_template_reminder_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "reservio_integration" */
export type Reservio_Integration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "reservio_integration_reservations" */
export type Reservio_Integration_Reservations = {
  account_id: Scalars['uuid'];
  booking_updated_at?: Maybe<Scalars['timestamptz']>;
  client_email?: Maybe<Scalars['String']>;
  client_name?: Maybe<Scalars['String']>;
  client_phone?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  event_updated_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  last_update: Scalars['timestamptz'];
  reminder_sent_at?: Maybe<Scalars['timestamptz']>;
  reservation_createdAt: Scalars['timestamptz'];
  reservation_id: Scalars['uuid'];
  reservation_name: Scalars['String'];
  reservation_start: Scalars['timestamptz'];
  reservation_state: Scalars['String'];
  service_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Aggregate = {
  aggregate?: Maybe<Reservio_Integration_Reservations_Aggregate_Fields>;
  nodes: Array<Reservio_Integration_Reservations>;
};

/** aggregate fields of "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Reservio_Integration_Reservations_Max_Fields>;
  min?: Maybe<Reservio_Integration_Reservations_Min_Fields>;
};


/** aggregate fields of "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Reservio_Integration_Reservations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Reservio_Integration_Reservations_Max_Order_By>;
  min?: Maybe<Reservio_Integration_Reservations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Arr_Rel_Insert_Input = {
  data: Array<Reservio_Integration_Reservations_Insert_Input>;
  on_conflict?: Maybe<Reservio_Integration_Reservations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reservio_integration_reservations". All fields are combined with a logical 'AND'. */
export type Reservio_Integration_Reservations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Reservio_Integration_Reservations_Bool_Exp>>>;
  _not?: Maybe<Reservio_Integration_Reservations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Reservio_Integration_Reservations_Bool_Exp>>>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  booking_updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  client_email?: Maybe<String_Comparison_Exp>;
  client_name?: Maybe<String_Comparison_Exp>;
  client_phone?: Maybe<String_Comparison_Exp>;
  employee_name?: Maybe<String_Comparison_Exp>;
  event_updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_update?: Maybe<Timestamptz_Comparison_Exp>;
  reminder_sent_at?: Maybe<Timestamptz_Comparison_Exp>;
  reservation_createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  reservation_id?: Maybe<Uuid_Comparison_Exp>;
  reservation_name?: Maybe<String_Comparison_Exp>;
  reservation_start?: Maybe<Timestamptz_Comparison_Exp>;
  reservation_state?: Maybe<String_Comparison_Exp>;
  service_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reservio_integration_reservations" */
export enum Reservio_Integration_Reservations_Constraint {
  /** unique or primary key constraint */
  ReservioIntegrationReservationsPkey = 'reservio_integration_reservations_pkey',
  /** unique or primary key constraint */
  ReservioIntegrationReservationsReservationIdKey = 'reservio_integration_reservations_reservation_id_key'
}

/** input type for inserting data into table "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Insert_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  booking_updated_at?: Maybe<Scalars['timestamptz']>;
  client_email?: Maybe<Scalars['String']>;
  client_name?: Maybe<Scalars['String']>;
  client_phone?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  event_updated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  reminder_sent_at?: Maybe<Scalars['timestamptz']>;
  reservation_createdAt?: Maybe<Scalars['timestamptz']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  reservation_name?: Maybe<Scalars['String']>;
  reservation_start?: Maybe<Scalars['timestamptz']>;
  reservation_state?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Reservio_Integration_Reservations_Max_Fields = {
  account_id?: Maybe<Scalars['uuid']>;
  booking_updated_at?: Maybe<Scalars['timestamptz']>;
  client_email?: Maybe<Scalars['String']>;
  client_name?: Maybe<Scalars['String']>;
  client_phone?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  event_updated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  reminder_sent_at?: Maybe<Scalars['timestamptz']>;
  reservation_createdAt?: Maybe<Scalars['timestamptz']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  reservation_name?: Maybe<Scalars['String']>;
  reservation_start?: Maybe<Scalars['timestamptz']>;
  reservation_state?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  booking_updated_at?: Maybe<Order_By>;
  client_email?: Maybe<Order_By>;
  client_name?: Maybe<Order_By>;
  client_phone?: Maybe<Order_By>;
  employee_name?: Maybe<Order_By>;
  event_updated_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  reminder_sent_at?: Maybe<Order_By>;
  reservation_createdAt?: Maybe<Order_By>;
  reservation_id?: Maybe<Order_By>;
  reservation_name?: Maybe<Order_By>;
  reservation_start?: Maybe<Order_By>;
  reservation_state?: Maybe<Order_By>;
  service_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Reservio_Integration_Reservations_Min_Fields = {
  account_id?: Maybe<Scalars['uuid']>;
  booking_updated_at?: Maybe<Scalars['timestamptz']>;
  client_email?: Maybe<Scalars['String']>;
  client_name?: Maybe<Scalars['String']>;
  client_phone?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  event_updated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  reminder_sent_at?: Maybe<Scalars['timestamptz']>;
  reservation_createdAt?: Maybe<Scalars['timestamptz']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  reservation_name?: Maybe<Scalars['String']>;
  reservation_start?: Maybe<Scalars['timestamptz']>;
  reservation_state?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  booking_updated_at?: Maybe<Order_By>;
  client_email?: Maybe<Order_By>;
  client_name?: Maybe<Order_By>;
  client_phone?: Maybe<Order_By>;
  employee_name?: Maybe<Order_By>;
  event_updated_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  reminder_sent_at?: Maybe<Order_By>;
  reservation_createdAt?: Maybe<Order_By>;
  reservation_id?: Maybe<Order_By>;
  reservation_name?: Maybe<Order_By>;
  reservation_start?: Maybe<Order_By>;
  reservation_state?: Maybe<Order_By>;
  service_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Reservio_Integration_Reservations>;
};

/** input type for inserting object relation for remote table "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Obj_Rel_Insert_Input = {
  data: Reservio_Integration_Reservations_Insert_Input;
  on_conflict?: Maybe<Reservio_Integration_Reservations_On_Conflict>;
};

/** on conflict condition type for table "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_On_Conflict = {
  constraint: Reservio_Integration_Reservations_Constraint;
  update_columns: Array<Reservio_Integration_Reservations_Update_Column>;
  where?: Maybe<Reservio_Integration_Reservations_Bool_Exp>;
};

/** ordering options when selecting data from "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Order_By = {
  account_id?: Maybe<Order_By>;
  booking_updated_at?: Maybe<Order_By>;
  client_email?: Maybe<Order_By>;
  client_name?: Maybe<Order_By>;
  client_phone?: Maybe<Order_By>;
  employee_name?: Maybe<Order_By>;
  event_updated_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  reminder_sent_at?: Maybe<Order_By>;
  reservation_createdAt?: Maybe<Order_By>;
  reservation_id?: Maybe<Order_By>;
  reservation_name?: Maybe<Order_By>;
  reservation_start?: Maybe<Order_By>;
  reservation_state?: Maybe<Order_By>;
  service_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "reservio_integration_reservations" */
export enum Reservio_Integration_Reservations_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BookingUpdatedAt = 'booking_updated_at',
  /** column name */
  ClientEmail = 'client_email',
  /** column name */
  ClientName = 'client_name',
  /** column name */
  ClientPhone = 'client_phone',
  /** column name */
  EmployeeName = 'employee_name',
  /** column name */
  EventUpdatedAt = 'event_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  ReminderSentAt = 'reminder_sent_at',
  /** column name */
  ReservationCreatedAt = 'reservation_createdAt',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  ReservationName = 'reservation_name',
  /** column name */
  ReservationStart = 'reservation_start',
  /** column name */
  ReservationState = 'reservation_state',
  /** column name */
  ServiceName = 'service_name'
}

/** input type for updating data in table "reservio_integration_reservations" */
export type Reservio_Integration_Reservations_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  booking_updated_at?: Maybe<Scalars['timestamptz']>;
  client_email?: Maybe<Scalars['String']>;
  client_name?: Maybe<Scalars['String']>;
  client_phone?: Maybe<Scalars['String']>;
  employee_name?: Maybe<Scalars['String']>;
  event_updated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  reminder_sent_at?: Maybe<Scalars['timestamptz']>;
  reservation_createdAt?: Maybe<Scalars['timestamptz']>;
  reservation_id?: Maybe<Scalars['uuid']>;
  reservation_name?: Maybe<Scalars['String']>;
  reservation_start?: Maybe<Scalars['timestamptz']>;
  reservation_state?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
};

/** update columns of table "reservio_integration_reservations" */
export enum Reservio_Integration_Reservations_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BookingUpdatedAt = 'booking_updated_at',
  /** column name */
  ClientEmail = 'client_email',
  /** column name */
  ClientName = 'client_name',
  /** column name */
  ClientPhone = 'client_phone',
  /** column name */
  EmployeeName = 'employee_name',
  /** column name */
  EventUpdatedAt = 'event_updated_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  ReminderSentAt = 'reminder_sent_at',
  /** column name */
  ReservationCreatedAt = 'reservation_createdAt',
  /** column name */
  ReservationId = 'reservation_id',
  /** column name */
  ReservationName = 'reservation_name',
  /** column name */
  ReservationStart = 'reservation_start',
  /** column name */
  ReservationState = 'reservation_state',
  /** column name */
  ServiceName = 'service_name'
}

/** select columns of table "reservio_integration" */
export enum Reservio_Integration_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastFetch = 'last_fetch',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  ReminderIntervalBefore = 'reminder_interval_before',
  /** column name */
  ReminderSkipWeekend = 'reminder_skip_weekend',
  /** column name */
  ReservioBusinessId = 'reservio_business_id',
  /** column name */
  ReservioEmail = 'reservio_email',
  /** column name */
  ReservioPass = 'reservio_pass',
  /** column name */
  ReservioRefreshToken = 'reservio_refresh_token',
  /** column name */
  ReservioUserId = 'reservio_user_id',
  /** column name */
  SmsTemplateAcceptedId = 'sms_template_accepted_id',
  /** column name */
  SmsTemplateCancelledId = 'sms_template_cancelled_id',
  /** column name */
  SmsTemplateConfirmedId = 'sms_template_confirmed_id',
  /** column name */
  SmsTemplateMovedId = 'sms_template_moved_id',
  /** column name */
  SmsTemplateRejectedId = 'sms_template_rejected_id',
  /** column name */
  SmsTemplateReminderId = 'sms_template_reminder_id'
}

/** input type for updating data in table "reservio_integration" */
export type Reservio_Integration_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  last_fetch?: Maybe<Scalars['timestamptz']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  reminder_interval_before?: Maybe<Scalars['Int']>;
  reminder_skip_weekend?: Maybe<Scalars['Boolean']>;
  reservio_business_id?: Maybe<Scalars['String']>;
  reservio_email?: Maybe<Scalars['String']>;
  reservio_pass?: Maybe<Scalars['String']>;
  reservio_refresh_token?: Maybe<Scalars['String']>;
  reservio_user_id?: Maybe<Scalars['String']>;
  sms_template_accepted_id?: Maybe<Scalars['uuid']>;
  sms_template_cancelled_id?: Maybe<Scalars['uuid']>;
  sms_template_confirmed_id?: Maybe<Scalars['uuid']>;
  sms_template_moved_id?: Maybe<Scalars['uuid']>;
  sms_template_rejected_id?: Maybe<Scalars['uuid']>;
  sms_template_reminder_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Reservio_Integration_Stddev_Fields = {
  reminder_interval_before?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "reservio_integration" */
export type Reservio_Integration_Stddev_Order_By = {
  reminder_interval_before?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reservio_Integration_Stddev_Pop_Fields = {
  reminder_interval_before?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "reservio_integration" */
export type Reservio_Integration_Stddev_Pop_Order_By = {
  reminder_interval_before?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reservio_Integration_Stddev_Samp_Fields = {
  reminder_interval_before?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "reservio_integration" */
export type Reservio_Integration_Stddev_Samp_Order_By = {
  reminder_interval_before?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Reservio_Integration_Sum_Fields = {
  reminder_interval_before?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "reservio_integration" */
export type Reservio_Integration_Sum_Order_By = {
  reminder_interval_before?: Maybe<Order_By>;
};

/** update columns of table "reservio_integration" */
export enum Reservio_Integration_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Id = 'id',
  /** column name */
  LastFetch = 'last_fetch',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  ReminderIntervalBefore = 'reminder_interval_before',
  /** column name */
  ReminderSkipWeekend = 'reminder_skip_weekend',
  /** column name */
  ReservioBusinessId = 'reservio_business_id',
  /** column name */
  ReservioEmail = 'reservio_email',
  /** column name */
  ReservioPass = 'reservio_pass',
  /** column name */
  ReservioRefreshToken = 'reservio_refresh_token',
  /** column name */
  ReservioUserId = 'reservio_user_id',
  /** column name */
  SmsTemplateAcceptedId = 'sms_template_accepted_id',
  /** column name */
  SmsTemplateCancelledId = 'sms_template_cancelled_id',
  /** column name */
  SmsTemplateConfirmedId = 'sms_template_confirmed_id',
  /** column name */
  SmsTemplateMovedId = 'sms_template_moved_id',
  /** column name */
  SmsTemplateRejectedId = 'sms_template_rejected_id',
  /** column name */
  SmsTemplateReminderId = 'sms_template_reminder_id'
}

/** aggregate var_pop on columns */
export type Reservio_Integration_Var_Pop_Fields = {
  reminder_interval_before?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "reservio_integration" */
export type Reservio_Integration_Var_Pop_Order_By = {
  reminder_interval_before?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reservio_Integration_Var_Samp_Fields = {
  reminder_interval_before?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "reservio_integration" */
export type Reservio_Integration_Var_Samp_Order_By = {
  reminder_interval_before?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Reservio_Integration_Variance_Fields = {
  reminder_interval_before?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "reservio_integration" */
export type Reservio_Integration_Variance_Order_By = {
  reminder_interval_before?: Maybe<Order_By>;
};

/** columns and relationships of "roles" */
export type Roles = {
  can_access_centrumsms: Scalars['Boolean'];
  can_manage_company: Scalars['Boolean'];
  can_manage_users: Scalars['Boolean'];
  can_withdraw: Scalars['Boolean'];
  /** An object relationship */
  company?: Maybe<Companies>;
  company_id?: Maybe<Scalars['uuid']>;
  default: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregated array relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "roles" */
export type RolesUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "roles" */
export type Roles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Roles_Max_Order_By>;
  min?: Maybe<Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "roles" */
export type Roles_Arr_Rel_Insert_Input = {
  data: Array<Roles_Insert_Input>;
  on_conflict?: Maybe<Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Roles_Bool_Exp>>>;
  _not?: Maybe<Roles_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Roles_Bool_Exp>>>;
  can_access_centrumsms?: Maybe<Boolean_Comparison_Exp>;
  can_manage_company?: Maybe<Boolean_Comparison_Exp>;
  can_manage_users?: Maybe<Boolean_Comparison_Exp>;
  can_withdraw?: Maybe<Boolean_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  default?: Maybe<Boolean_Comparison_Exp>;
  hidden?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  can_access_centrumsms?: Maybe<Scalars['Boolean']>;
  can_manage_company?: Maybe<Scalars['Boolean']>;
  can_manage_users?: Maybe<Scalars['Boolean']>;
  can_withdraw?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  default?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "roles" */
export type Roles_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "roles" */
export type Roles_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  on_conflict?: Maybe<Roles_On_Conflict>;
};

/** on conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns: Array<Roles_Update_Column>;
  where?: Maybe<Roles_Bool_Exp>;
};

/** ordering options when selecting data from "roles" */
export type Roles_Order_By = {
  can_access_centrumsms?: Maybe<Order_By>;
  can_manage_company?: Maybe<Order_By>;
  can_manage_users?: Maybe<Order_By>;
  can_withdraw?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  default?: Maybe<Order_By>;
  hidden?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: "roles" */
export type Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  CanAccessCentrumsms = 'can_access_centrumsms',
  /** column name */
  CanManageCompany = 'can_manage_company',
  /** column name */
  CanManageUsers = 'can_manage_users',
  /** column name */
  CanWithdraw = 'can_withdraw',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Default = 'default',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  can_access_centrumsms?: Maybe<Scalars['Boolean']>;
  can_manage_company?: Maybe<Scalars['Boolean']>;
  can_manage_users?: Maybe<Scalars['Boolean']>;
  can_withdraw?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  default?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  CanAccessCentrumsms = 'can_access_centrumsms',
  /** column name */
  CanManageCompany = 'can_manage_company',
  /** column name */
  CanManageUsers = 'can_manage_users',
  /** column name */
  CanWithdraw = 'can_withdraw',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Default = 'default',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

export type SendPaymentOutput = {
  success: Scalars['Boolean'];
};

/** columns and relationships of "services" */
export type Services = {
  /** An array relationship */
  calendars_services: Array<Calendars_Services>;
  /** An aggregated array relationship */
  calendars_services_aggregate: Calendars_Services_Aggregate;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  price?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  reservations_services: Array<Reservations_Services>;
  /** An aggregated array relationship */
  reservations_services_aggregate: Reservations_Services_Aggregate;
};


/** columns and relationships of "services" */
export type ServicesCalendars_ServicesArgs = {
  distinct_on?: Maybe<Array<Calendars_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Services_Order_By>>;
  where?: Maybe<Calendars_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesCalendars_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Calendars_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Services_Order_By>>;
  where?: Maybe<Calendars_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesReservations_ServicesArgs = {
  distinct_on?: Maybe<Array<Reservations_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Services_Order_By>>;
  where?: Maybe<Reservations_Services_Bool_Exp>;
};


/** columns and relationships of "services" */
export type ServicesReservations_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Services_Order_By>>;
  where?: Maybe<Reservations_Services_Bool_Exp>;
};

/** aggregated selection of "services" */
export type Services_Aggregate = {
  aggregate?: Maybe<Services_Aggregate_Fields>;
  nodes: Array<Services>;
};

/** aggregate fields of "services" */
export type Services_Aggregate_Fields = {
  avg?: Maybe<Services_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Services_Max_Fields>;
  min?: Maybe<Services_Min_Fields>;
  stddev?: Maybe<Services_Stddev_Fields>;
  stddev_pop?: Maybe<Services_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Services_Stddev_Samp_Fields>;
  sum?: Maybe<Services_Sum_Fields>;
  var_pop?: Maybe<Services_Var_Pop_Fields>;
  var_samp?: Maybe<Services_Var_Samp_Fields>;
  variance?: Maybe<Services_Variance_Fields>;
};


/** aggregate fields of "services" */
export type Services_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Services_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "services" */
export type Services_Aggregate_Order_By = {
  avg?: Maybe<Services_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Services_Max_Order_By>;
  min?: Maybe<Services_Min_Order_By>;
  stddev?: Maybe<Services_Stddev_Order_By>;
  stddev_pop?: Maybe<Services_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Services_Stddev_Samp_Order_By>;
  sum?: Maybe<Services_Sum_Order_By>;
  var_pop?: Maybe<Services_Var_Pop_Order_By>;
  var_samp?: Maybe<Services_Var_Samp_Order_By>;
  variance?: Maybe<Services_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "services" */
export type Services_Arr_Rel_Insert_Input = {
  data: Array<Services_Insert_Input>;
  on_conflict?: Maybe<Services_On_Conflict>;
};

/** aggregate avg on columns */
export type Services_Avg_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "services" */
export type Services_Avg_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "services". All fields are combined with a logical 'AND'. */
export type Services_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Services_Bool_Exp>>>;
  _not?: Maybe<Services_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Services_Bool_Exp>>>;
  calendars_services?: Maybe<Calendars_Services_Bool_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  duration?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  reservations_services?: Maybe<Reservations_Services_Bool_Exp>;
};

/** unique or primary key constraints on table "services" */
export enum Services_Constraint {
  /** unique or primary key constraint */
  ServicesPkey = 'services_pkey'
}

/** input type for incrementing integer column in table "services" */
export type Services_Inc_Input = {
  duration?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "services" */
export type Services_Insert_Input = {
  calendars_services?: Maybe<Calendars_Services_Arr_Rel_Insert_Input>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  reservations_services?: Maybe<Reservations_Services_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Services_Max_Fields = {
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "services" */
export type Services_Max_Order_By = {
  description?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Services_Min_Fields = {
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "services" */
export type Services_Min_Order_By = {
  description?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** response of any mutation on the table "services" */
export type Services_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Services>;
};

/** input type for inserting object relation for remote table "services" */
export type Services_Obj_Rel_Insert_Input = {
  data: Services_Insert_Input;
  on_conflict?: Maybe<Services_On_Conflict>;
};

/** on conflict condition type for table "services" */
export type Services_On_Conflict = {
  constraint: Services_Constraint;
  update_columns: Array<Services_Update_Column>;
  where?: Maybe<Services_Bool_Exp>;
};

/** ordering options when selecting data from "services" */
export type Services_Order_By = {
  calendars_services_aggregate?: Maybe<Calendars_Services_Aggregate_Order_By>;
  description?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  reservations_services_aggregate?: Maybe<Reservations_Services_Aggregate_Order_By>;
};

/** primary key columns input for table: "services" */
export type Services_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "services" */
export enum Services_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price'
}

/** input type for updating data in table "services" */
export type Services_Set_Input = {
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Services_Stddev_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "services" */
export type Services_Stddev_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Services_Stddev_Pop_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "services" */
export type Services_Stddev_Pop_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Services_Stddev_Samp_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "services" */
export type Services_Stddev_Samp_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Services_Sum_Fields = {
  duration?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "services" */
export type Services_Sum_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** update columns of table "services" */
export enum Services_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price'
}

/** aggregate var_pop on columns */
export type Services_Var_Pop_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "services" */
export type Services_Var_Pop_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Services_Var_Samp_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "services" */
export type Services_Var_Samp_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Services_Variance_Fields = {
  duration?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "services" */
export type Services_Variance_Order_By = {
  duration?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** columns and relationships of "shoptet_eshops" */
export type Shoptet_Eshops = {
  access_token?: Maybe<Scalars['String']>;
  addon_status: Scalars['String'];
  /** An object relationship */
  company?: Maybe<Companies>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dic?: Maybe<Scalars['String']>;
  eshop_id: Scalars['String'];
  eshop_name: Scalars['String'];
  eshop_url: Scalars['String'];
  oauth_access_token: Scalars['String'];
  primary_key: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "shoptet_eshops" */
export type Shoptet_Eshops_Aggregate = {
  aggregate?: Maybe<Shoptet_Eshops_Aggregate_Fields>;
  nodes: Array<Shoptet_Eshops>;
};

/** aggregate fields of "shoptet_eshops" */
export type Shoptet_Eshops_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Shoptet_Eshops_Max_Fields>;
  min?: Maybe<Shoptet_Eshops_Min_Fields>;
};


/** aggregate fields of "shoptet_eshops" */
export type Shoptet_Eshops_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shoptet_Eshops_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "shoptet_eshops" */
export type Shoptet_Eshops_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Shoptet_Eshops_Max_Order_By>;
  min?: Maybe<Shoptet_Eshops_Min_Order_By>;
};

/** input type for inserting array relation for remote table "shoptet_eshops" */
export type Shoptet_Eshops_Arr_Rel_Insert_Input = {
  data: Array<Shoptet_Eshops_Insert_Input>;
  on_conflict?: Maybe<Shoptet_Eshops_On_Conflict>;
};

/** Boolean expression to filter rows from the table "shoptet_eshops". All fields are combined with a logical 'AND'. */
export type Shoptet_Eshops_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Shoptet_Eshops_Bool_Exp>>>;
  _not?: Maybe<Shoptet_Eshops_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Shoptet_Eshops_Bool_Exp>>>;
  access_token?: Maybe<String_Comparison_Exp>;
  addon_status?: Maybe<String_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dic?: Maybe<String_Comparison_Exp>;
  eshop_id?: Maybe<String_Comparison_Exp>;
  eshop_name?: Maybe<String_Comparison_Exp>;
  eshop_url?: Maybe<String_Comparison_Exp>;
  oauth_access_token?: Maybe<String_Comparison_Exp>;
  primary_key?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "shoptet_eshops" */
export enum Shoptet_Eshops_Constraint {
  /** unique or primary key constraint */
  ShoptetEshopsEshopIdKey = 'shoptet_eshops_eshop_id_key',
  /** unique or primary key constraint */
  ShoptetEshopsEshopUrlKey = 'shoptet_eshops_eshop_url_key',
  /** unique or primary key constraint */
  ShoptetEshopsOauthAccessTokenKey = 'shoptet_eshops_oauth_access_token_key',
  /** unique or primary key constraint */
  ShoptetEshopsPkey = 'shoptet_eshops_pkey'
}

/** input type for inserting data into table "shoptet_eshops" */
export type Shoptet_Eshops_Insert_Input = {
  access_token?: Maybe<Scalars['String']>;
  addon_status?: Maybe<Scalars['String']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dic?: Maybe<Scalars['String']>;
  eshop_id?: Maybe<Scalars['String']>;
  eshop_name?: Maybe<Scalars['String']>;
  eshop_url?: Maybe<Scalars['String']>;
  oauth_access_token?: Maybe<Scalars['String']>;
  primary_key?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Shoptet_Eshops_Max_Fields = {
  access_token?: Maybe<Scalars['String']>;
  addon_status?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dic?: Maybe<Scalars['String']>;
  eshop_id?: Maybe<Scalars['String']>;
  eshop_name?: Maybe<Scalars['String']>;
  eshop_url?: Maybe<Scalars['String']>;
  oauth_access_token?: Maybe<Scalars['String']>;
  primary_key?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "shoptet_eshops" */
export type Shoptet_Eshops_Max_Order_By = {
  access_token?: Maybe<Order_By>;
  addon_status?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dic?: Maybe<Order_By>;
  eshop_id?: Maybe<Order_By>;
  eshop_name?: Maybe<Order_By>;
  eshop_url?: Maybe<Order_By>;
  oauth_access_token?: Maybe<Order_By>;
  primary_key?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Shoptet_Eshops_Min_Fields = {
  access_token?: Maybe<Scalars['String']>;
  addon_status?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dic?: Maybe<Scalars['String']>;
  eshop_id?: Maybe<Scalars['String']>;
  eshop_name?: Maybe<Scalars['String']>;
  eshop_url?: Maybe<Scalars['String']>;
  oauth_access_token?: Maybe<Scalars['String']>;
  primary_key?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "shoptet_eshops" */
export type Shoptet_Eshops_Min_Order_By = {
  access_token?: Maybe<Order_By>;
  addon_status?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dic?: Maybe<Order_By>;
  eshop_id?: Maybe<Order_By>;
  eshop_name?: Maybe<Order_By>;
  eshop_url?: Maybe<Order_By>;
  oauth_access_token?: Maybe<Order_By>;
  primary_key?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "shoptet_eshops" */
export type Shoptet_Eshops_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Shoptet_Eshops>;
};

/** input type for inserting object relation for remote table "shoptet_eshops" */
export type Shoptet_Eshops_Obj_Rel_Insert_Input = {
  data: Shoptet_Eshops_Insert_Input;
  on_conflict?: Maybe<Shoptet_Eshops_On_Conflict>;
};

/** on conflict condition type for table "shoptet_eshops" */
export type Shoptet_Eshops_On_Conflict = {
  constraint: Shoptet_Eshops_Constraint;
  update_columns: Array<Shoptet_Eshops_Update_Column>;
  where?: Maybe<Shoptet_Eshops_Bool_Exp>;
};

/** ordering options when selecting data from "shoptet_eshops" */
export type Shoptet_Eshops_Order_By = {
  access_token?: Maybe<Order_By>;
  addon_status?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dic?: Maybe<Order_By>;
  eshop_id?: Maybe<Order_By>;
  eshop_name?: Maybe<Order_By>;
  eshop_url?: Maybe<Order_By>;
  oauth_access_token?: Maybe<Order_By>;
  primary_key?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "shoptet_eshops" */
export type Shoptet_Eshops_Pk_Columns_Input = {
  primary_key: Scalars['uuid'];
};

/** select columns of table "shoptet_eshops" */
export enum Shoptet_Eshops_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  AddonStatus = 'addon_status',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dic = 'dic',
  /** column name */
  EshopId = 'eshop_id',
  /** column name */
  EshopName = 'eshop_name',
  /** column name */
  EshopUrl = 'eshop_url',
  /** column name */
  OauthAccessToken = 'oauth_access_token',
  /** column name */
  PrimaryKey = 'primary_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "shoptet_eshops" */
export type Shoptet_Eshops_Set_Input = {
  access_token?: Maybe<Scalars['String']>;
  addon_status?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dic?: Maybe<Scalars['String']>;
  eshop_id?: Maybe<Scalars['String']>;
  eshop_name?: Maybe<Scalars['String']>;
  eshop_url?: Maybe<Scalars['String']>;
  oauth_access_token?: Maybe<Scalars['String']>;
  primary_key?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "shoptet_eshops" */
export enum Shoptet_Eshops_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  AddonStatus = 'addon_status',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dic = 'dic',
  /** column name */
  EshopId = 'eshop_id',
  /** column name */
  EshopName = 'eshop_name',
  /** column name */
  EshopUrl = 'eshop_url',
  /** column name */
  OauthAccessToken = 'oauth_access_token',
  /** column name */
  PrimaryKey = 'primary_key',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "shoptet_orders" */
export type Shoptet_Orders = {
  company_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  eshop_id: Scalars['Int'];
  id: Scalars['uuid'];
  order_id: Scalars['String'];
  status_custom?: Maybe<Scalars['String']>;
  status_id: Scalars['String'];
};

/** aggregated selection of "shoptet_orders" */
export type Shoptet_Orders_Aggregate = {
  aggregate?: Maybe<Shoptet_Orders_Aggregate_Fields>;
  nodes: Array<Shoptet_Orders>;
};

/** aggregate fields of "shoptet_orders" */
export type Shoptet_Orders_Aggregate_Fields = {
  avg?: Maybe<Shoptet_Orders_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Shoptet_Orders_Max_Fields>;
  min?: Maybe<Shoptet_Orders_Min_Fields>;
  stddev?: Maybe<Shoptet_Orders_Stddev_Fields>;
  stddev_pop?: Maybe<Shoptet_Orders_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Shoptet_Orders_Stddev_Samp_Fields>;
  sum?: Maybe<Shoptet_Orders_Sum_Fields>;
  var_pop?: Maybe<Shoptet_Orders_Var_Pop_Fields>;
  var_samp?: Maybe<Shoptet_Orders_Var_Samp_Fields>;
  variance?: Maybe<Shoptet_Orders_Variance_Fields>;
};


/** aggregate fields of "shoptet_orders" */
export type Shoptet_Orders_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shoptet_Orders_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "shoptet_orders" */
export type Shoptet_Orders_Aggregate_Order_By = {
  avg?: Maybe<Shoptet_Orders_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Shoptet_Orders_Max_Order_By>;
  min?: Maybe<Shoptet_Orders_Min_Order_By>;
  stddev?: Maybe<Shoptet_Orders_Stddev_Order_By>;
  stddev_pop?: Maybe<Shoptet_Orders_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Shoptet_Orders_Stddev_Samp_Order_By>;
  sum?: Maybe<Shoptet_Orders_Sum_Order_By>;
  var_pop?: Maybe<Shoptet_Orders_Var_Pop_Order_By>;
  var_samp?: Maybe<Shoptet_Orders_Var_Samp_Order_By>;
  variance?: Maybe<Shoptet_Orders_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "shoptet_orders" */
export type Shoptet_Orders_Arr_Rel_Insert_Input = {
  data: Array<Shoptet_Orders_Insert_Input>;
  on_conflict?: Maybe<Shoptet_Orders_On_Conflict>;
};

/** aggregate avg on columns */
export type Shoptet_Orders_Avg_Fields = {
  eshop_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Avg_Order_By = {
  eshop_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shoptet_orders". All fields are combined with a logical 'AND'. */
export type Shoptet_Orders_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Shoptet_Orders_Bool_Exp>>>;
  _not?: Maybe<Shoptet_Orders_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Shoptet_Orders_Bool_Exp>>>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  eshop_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  order_id?: Maybe<String_Comparison_Exp>;
  status_custom?: Maybe<String_Comparison_Exp>;
  status_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "shoptet_orders" */
export enum Shoptet_Orders_Constraint {
  /** unique or primary key constraint */
  ShoptetOrdersCompanyIdOrderIdStatusIdStatusCustomEsho = 'shoptet_orders_company_id_order_id_status_id_status_custom_esho',
  /** unique or primary key constraint */
  ShoptetOrdersPkey = 'shoptet_orders_pkey'
}

/** input type for incrementing integer column in table "shoptet_orders" */
export type Shoptet_Orders_Inc_Input = {
  eshop_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "shoptet_orders" */
export type Shoptet_Orders_Insert_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  eshop_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order_id?: Maybe<Scalars['String']>;
  status_custom?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Shoptet_Orders_Max_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  eshop_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order_id?: Maybe<Scalars['String']>;
  status_custom?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  eshop_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  status_custom?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Shoptet_Orders_Min_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  eshop_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order_id?: Maybe<Scalars['String']>;
  status_custom?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  eshop_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  status_custom?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "shoptet_orders" */
export type Shoptet_Orders_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Shoptet_Orders>;
};

/** input type for inserting object relation for remote table "shoptet_orders" */
export type Shoptet_Orders_Obj_Rel_Insert_Input = {
  data: Shoptet_Orders_Insert_Input;
  on_conflict?: Maybe<Shoptet_Orders_On_Conflict>;
};

/** on conflict condition type for table "shoptet_orders" */
export type Shoptet_Orders_On_Conflict = {
  constraint: Shoptet_Orders_Constraint;
  update_columns: Array<Shoptet_Orders_Update_Column>;
  where?: Maybe<Shoptet_Orders_Bool_Exp>;
};

/** ordering options when selecting data from "shoptet_orders" */
export type Shoptet_Orders_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  eshop_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order_id?: Maybe<Order_By>;
  status_custom?: Maybe<Order_By>;
  status_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "shoptet_orders" */
export type Shoptet_Orders_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "shoptet_orders" */
export enum Shoptet_Orders_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EshopId = 'eshop_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  StatusCustom = 'status_custom',
  /** column name */
  StatusId = 'status_id'
}

/** input type for updating data in table "shoptet_orders" */
export type Shoptet_Orders_Set_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  eshop_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order_id?: Maybe<Scalars['String']>;
  status_custom?: Maybe<Scalars['String']>;
  status_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Shoptet_Orders_Stddev_Fields = {
  eshop_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Stddev_Order_By = {
  eshop_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Shoptet_Orders_Stddev_Pop_Fields = {
  eshop_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Stddev_Pop_Order_By = {
  eshop_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Shoptet_Orders_Stddev_Samp_Fields = {
  eshop_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Stddev_Samp_Order_By = {
  eshop_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Shoptet_Orders_Sum_Fields = {
  eshop_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Sum_Order_By = {
  eshop_id?: Maybe<Order_By>;
};

/** update columns of table "shoptet_orders" */
export enum Shoptet_Orders_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EshopId = 'eshop_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  StatusCustom = 'status_custom',
  /** column name */
  StatusId = 'status_id'
}

/** aggregate var_pop on columns */
export type Shoptet_Orders_Var_Pop_Fields = {
  eshop_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Var_Pop_Order_By = {
  eshop_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Shoptet_Orders_Var_Samp_Fields = {
  eshop_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Var_Samp_Order_By = {
  eshop_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Shoptet_Orders_Variance_Fields = {
  eshop_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "shoptet_orders" */
export type Shoptet_Orders_Variance_Order_By = {
  eshop_id?: Maybe<Order_By>;
};

/** columns and relationships of "smsapi_deliveries" */
export type Smsapi_Deliveries = {
  cancelled: Scalars['Boolean'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** A computed field, executes function "smsapi_deliveries_delivered_count" */
  delivered_count?: Maybe<Scalars['bigint']>;
  id: Scalars['uuid'];
  /** A computed field, executes function "smsapi_deliveries_latest_message_body" */
  latest_message_body?: Maybe<Scalars['String']>;
  message_count: Scalars['Int'];
  /** An array relationship */
  messages: Array<Smsapi_Queue_Extra>;
  /** An aggregated array relationship */
  messages_aggregate: Smsapi_Queue_Extra_Aggregate;
  planned_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  sender?: Maybe<Users>;
  sent_by?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "smsapi_deliveries_sent_count" */
  sent_count?: Maybe<Scalars['bigint']>;
  status: Scalars['String'];
};


/** columns and relationships of "smsapi_deliveries" */
export type Smsapi_DeliveriesMessagesArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
};


/** columns and relationships of "smsapi_deliveries" */
export type Smsapi_DeliveriesMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
};

/** aggregated selection of "smsapi_deliveries" */
export type Smsapi_Deliveries_Aggregate = {
  aggregate?: Maybe<Smsapi_Deliveries_Aggregate_Fields>;
  nodes: Array<Smsapi_Deliveries>;
};

/** aggregate fields of "smsapi_deliveries" */
export type Smsapi_Deliveries_Aggregate_Fields = {
  avg?: Maybe<Smsapi_Deliveries_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Smsapi_Deliveries_Max_Fields>;
  min?: Maybe<Smsapi_Deliveries_Min_Fields>;
  stddev?: Maybe<Smsapi_Deliveries_Stddev_Fields>;
  stddev_pop?: Maybe<Smsapi_Deliveries_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Smsapi_Deliveries_Stddev_Samp_Fields>;
  sum?: Maybe<Smsapi_Deliveries_Sum_Fields>;
  var_pop?: Maybe<Smsapi_Deliveries_Var_Pop_Fields>;
  var_samp?: Maybe<Smsapi_Deliveries_Var_Samp_Fields>;
  variance?: Maybe<Smsapi_Deliveries_Variance_Fields>;
};


/** aggregate fields of "smsapi_deliveries" */
export type Smsapi_Deliveries_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Smsapi_Deliveries_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Aggregate_Order_By = {
  avg?: Maybe<Smsapi_Deliveries_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Smsapi_Deliveries_Max_Order_By>;
  min?: Maybe<Smsapi_Deliveries_Min_Order_By>;
  stddev?: Maybe<Smsapi_Deliveries_Stddev_Order_By>;
  stddev_pop?: Maybe<Smsapi_Deliveries_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Smsapi_Deliveries_Stddev_Samp_Order_By>;
  sum?: Maybe<Smsapi_Deliveries_Sum_Order_By>;
  var_pop?: Maybe<Smsapi_Deliveries_Var_Pop_Order_By>;
  var_samp?: Maybe<Smsapi_Deliveries_Var_Samp_Order_By>;
  variance?: Maybe<Smsapi_Deliveries_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "smsapi_deliveries" */
export type Smsapi_Deliveries_Arr_Rel_Insert_Input = {
  data: Array<Smsapi_Deliveries_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Deliveries_On_Conflict>;
};

/** aggregate avg on columns */
export type Smsapi_Deliveries_Avg_Fields = {
  message_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Avg_Order_By = {
  message_count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "smsapi_deliveries". All fields are combined with a logical 'AND'. */
export type Smsapi_Deliveries_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Smsapi_Deliveries_Bool_Exp>>>;
  _not?: Maybe<Smsapi_Deliveries_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Smsapi_Deliveries_Bool_Exp>>>;
  cancelled?: Maybe<Boolean_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  message_count?: Maybe<Int_Comparison_Exp>;
  messages?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
  planned_at?: Maybe<Timestamptz_Comparison_Exp>;
  sender?: Maybe<Users_Bool_Exp>;
  sent_by?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "smsapi_deliveries" */
export enum Smsapi_Deliveries_Constraint {
  /** unique or primary key constraint */
  SmsapiDeliveriesPkey = 'smsapi_deliveries_pkey'
}

/** input type for incrementing integer column in table "smsapi_deliveries" */
export type Smsapi_Deliveries_Inc_Input = {
  message_count?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "smsapi_deliveries" */
export type Smsapi_Deliveries_Insert_Input = {
  cancelled?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_count?: Maybe<Scalars['Int']>;
  messages?: Maybe<Smsapi_Queue_Extra_Arr_Rel_Insert_Input>;
  planned_at?: Maybe<Scalars['timestamptz']>;
  sender?: Maybe<Users_Obj_Rel_Insert_Input>;
  sent_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Smsapi_Deliveries_Max_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_count?: Maybe<Scalars['Int']>;
  planned_at?: Maybe<Scalars['timestamptz']>;
  sent_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_count?: Maybe<Order_By>;
  planned_at?: Maybe<Order_By>;
  sent_by?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Smsapi_Deliveries_Min_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_count?: Maybe<Scalars['Int']>;
  planned_at?: Maybe<Scalars['timestamptz']>;
  sent_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_count?: Maybe<Order_By>;
  planned_at?: Maybe<Order_By>;
  sent_by?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "smsapi_deliveries" */
export type Smsapi_Deliveries_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Smsapi_Deliveries>;
};

/** input type for inserting object relation for remote table "smsapi_deliveries" */
export type Smsapi_Deliveries_Obj_Rel_Insert_Input = {
  data: Smsapi_Deliveries_Insert_Input;
  on_conflict?: Maybe<Smsapi_Deliveries_On_Conflict>;
};

/** on conflict condition type for table "smsapi_deliveries" */
export type Smsapi_Deliveries_On_Conflict = {
  constraint: Smsapi_Deliveries_Constraint;
  update_columns: Array<Smsapi_Deliveries_Update_Column>;
  where?: Maybe<Smsapi_Deliveries_Bool_Exp>;
};

/** ordering options when selecting data from "smsapi_deliveries" */
export type Smsapi_Deliveries_Order_By = {
  cancelled?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_count?: Maybe<Order_By>;
  messages_aggregate?: Maybe<Smsapi_Queue_Extra_Aggregate_Order_By>;
  planned_at?: Maybe<Order_By>;
  sender?: Maybe<Users_Order_By>;
  sent_by?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "smsapi_deliveries" */
export type Smsapi_Deliveries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "smsapi_deliveries" */
export enum Smsapi_Deliveries_Select_Column {
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageCount = 'message_count',
  /** column name */
  PlannedAt = 'planned_at',
  /** column name */
  SentBy = 'sent_by',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "smsapi_deliveries" */
export type Smsapi_Deliveries_Set_Input = {
  cancelled?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_count?: Maybe<Scalars['Int']>;
  planned_at?: Maybe<Scalars['timestamptz']>;
  sent_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Smsapi_Deliveries_Stddev_Fields = {
  message_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Stddev_Order_By = {
  message_count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Smsapi_Deliveries_Stddev_Pop_Fields = {
  message_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Stddev_Pop_Order_By = {
  message_count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Smsapi_Deliveries_Stddev_Samp_Fields = {
  message_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Stddev_Samp_Order_By = {
  message_count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Smsapi_Deliveries_Sum_Fields = {
  message_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Sum_Order_By = {
  message_count?: Maybe<Order_By>;
};

/** update columns of table "smsapi_deliveries" */
export enum Smsapi_Deliveries_Update_Column {
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageCount = 'message_count',
  /** column name */
  PlannedAt = 'planned_at',
  /** column name */
  SentBy = 'sent_by',
  /** column name */
  Status = 'status'
}

/** aggregate var_pop on columns */
export type Smsapi_Deliveries_Var_Pop_Fields = {
  message_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Var_Pop_Order_By = {
  message_count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Smsapi_Deliveries_Var_Samp_Fields = {
  message_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Var_Samp_Order_By = {
  message_count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Smsapi_Deliveries_Variance_Fields = {
  message_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "smsapi_deliveries" */
export type Smsapi_Deliveries_Variance_Order_By = {
  message_count?: Maybe<Order_By>;
};

/** columns and relationships of "smsapi_queue" */
export type Smsapi_Queue = {
  body?: Maybe<Scalars['String']>;
  /** An array relationship */
  contact: Array<Contacts>;
  /** An aggregated array relationship */
  contact_aggregate: Contacts_Aggregate;
  date?: Maybe<Scalars['timestamp']>;
  /** An object relationship */
  extra?: Maybe<Smsapi_Queue_Extra>;
  id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  /** An object relationship */
  phone_number_conn?: Maybe<Phone_Numbers>;
  second_sim?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "smsapi_queue" */
export type Smsapi_QueueContactArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** columns and relationships of "smsapi_queue" */
export type Smsapi_QueueContact_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};

/** aggregated selection of "smsapi_queue" */
export type Smsapi_Queue_Aggregate = {
  aggregate?: Maybe<Smsapi_Queue_Aggregate_Fields>;
  nodes: Array<Smsapi_Queue>;
};

/** aggregate fields of "smsapi_queue" */
export type Smsapi_Queue_Aggregate_Fields = {
  avg?: Maybe<Smsapi_Queue_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Smsapi_Queue_Max_Fields>;
  min?: Maybe<Smsapi_Queue_Min_Fields>;
  stddev?: Maybe<Smsapi_Queue_Stddev_Fields>;
  stddev_pop?: Maybe<Smsapi_Queue_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Smsapi_Queue_Stddev_Samp_Fields>;
  sum?: Maybe<Smsapi_Queue_Sum_Fields>;
  var_pop?: Maybe<Smsapi_Queue_Var_Pop_Fields>;
  var_samp?: Maybe<Smsapi_Queue_Var_Samp_Fields>;
  variance?: Maybe<Smsapi_Queue_Variance_Fields>;
};


/** aggregate fields of "smsapi_queue" */
export type Smsapi_Queue_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Smsapi_Queue_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smsapi_queue" */
export type Smsapi_Queue_Aggregate_Order_By = {
  avg?: Maybe<Smsapi_Queue_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Smsapi_Queue_Max_Order_By>;
  min?: Maybe<Smsapi_Queue_Min_Order_By>;
  stddev?: Maybe<Smsapi_Queue_Stddev_Order_By>;
  stddev_pop?: Maybe<Smsapi_Queue_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Smsapi_Queue_Stddev_Samp_Order_By>;
  sum?: Maybe<Smsapi_Queue_Sum_Order_By>;
  var_pop?: Maybe<Smsapi_Queue_Var_Pop_Order_By>;
  var_samp?: Maybe<Smsapi_Queue_Var_Samp_Order_By>;
  variance?: Maybe<Smsapi_Queue_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "smsapi_queue" */
export type Smsapi_Queue_Arr_Rel_Insert_Input = {
  data: Array<Smsapi_Queue_Insert_Input>;
};

/** aggregate avg on columns */
export type Smsapi_Queue_Avg_Fields = {
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Avg_Order_By = {
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "smsapi_queue". All fields are combined with a logical 'AND'. */
export type Smsapi_Queue_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Smsapi_Queue_Bool_Exp>>>;
  _not?: Maybe<Smsapi_Queue_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Smsapi_Queue_Bool_Exp>>>;
  body?: Maybe<String_Comparison_Exp>;
  contact?: Maybe<Contacts_Bool_Exp>;
  date?: Maybe<Timestamp_Comparison_Exp>;
  extra?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  phone_number_conn?: Maybe<Phone_Numbers_Bool_Exp>;
  second_sim?: Maybe<Boolean_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Int_Comparison_Exp>;
  userid?: Maybe<Int_Comparison_Exp>;
};

/** columns and relationships of "smsapi_queue_extra" */
export type Smsapi_Queue_Extra = {
  /** An object relationship */
  delivery?: Maybe<Smsapi_Deliveries>;
  delivery_id?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  /** An object relationship */
  message?: Maybe<Smsapi_Queue>;
  /** An object relationship */
  message_index?: Maybe<Smsapi_Queue_Index>;
  /** An object relationship */
  sender?: Maybe<Users>;
  sent_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  source?: Maybe<Smsapi_Queue>;
  /** An object relationship */
  tag?: Maybe<Smsapi_Tags>;
  /** An array relationship */
  tag_history: Array<Smsapi_Queue_Extra_Tags_History>;
  /** An aggregated array relationship */
  tag_history_aggregate: Smsapi_Queue_Extra_Tags_History_Aggregate;
  tag_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "smsapi_queue_extra" */
export type Smsapi_Queue_ExtraTag_HistoryArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>;
};


/** columns and relationships of "smsapi_queue_extra" */
export type Smsapi_Queue_ExtraTag_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>;
};

/** aggregated selection of "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Aggregate = {
  aggregate?: Maybe<Smsapi_Queue_Extra_Aggregate_Fields>;
  nodes: Array<Smsapi_Queue_Extra>;
};

/** aggregate fields of "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Aggregate_Fields = {
  avg?: Maybe<Smsapi_Queue_Extra_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Smsapi_Queue_Extra_Max_Fields>;
  min?: Maybe<Smsapi_Queue_Extra_Min_Fields>;
  stddev?: Maybe<Smsapi_Queue_Extra_Stddev_Fields>;
  stddev_pop?: Maybe<Smsapi_Queue_Extra_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Smsapi_Queue_Extra_Stddev_Samp_Fields>;
  sum?: Maybe<Smsapi_Queue_Extra_Sum_Fields>;
  var_pop?: Maybe<Smsapi_Queue_Extra_Var_Pop_Fields>;
  var_samp?: Maybe<Smsapi_Queue_Extra_Var_Samp_Fields>;
  variance?: Maybe<Smsapi_Queue_Extra_Variance_Fields>;
};


/** aggregate fields of "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Smsapi_Queue_Extra_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Aggregate_Order_By = {
  avg?: Maybe<Smsapi_Queue_Extra_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Smsapi_Queue_Extra_Max_Order_By>;
  min?: Maybe<Smsapi_Queue_Extra_Min_Order_By>;
  stddev?: Maybe<Smsapi_Queue_Extra_Stddev_Order_By>;
  stddev_pop?: Maybe<Smsapi_Queue_Extra_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Smsapi_Queue_Extra_Stddev_Samp_Order_By>;
  sum?: Maybe<Smsapi_Queue_Extra_Sum_Order_By>;
  var_pop?: Maybe<Smsapi_Queue_Extra_Var_Pop_Order_By>;
  var_samp?: Maybe<Smsapi_Queue_Extra_Var_Samp_Order_By>;
  variance?: Maybe<Smsapi_Queue_Extra_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Arr_Rel_Insert_Input = {
  data: Array<Smsapi_Queue_Extra_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Queue_Extra_On_Conflict>;
};

/** aggregate avg on columns */
export type Smsapi_Queue_Extra_Avg_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "smsapi_queue_extra". All fields are combined with a logical 'AND'. */
export type Smsapi_Queue_Extra_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Smsapi_Queue_Extra_Bool_Exp>>>;
  _not?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Smsapi_Queue_Extra_Bool_Exp>>>;
  delivery?: Maybe<Smsapi_Deliveries_Bool_Exp>;
  delivery_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  message?: Maybe<Smsapi_Queue_Bool_Exp>;
  message_index?: Maybe<Smsapi_Queue_Index_Bool_Exp>;
  sender?: Maybe<Users_Bool_Exp>;
  sent_by?: Maybe<Uuid_Comparison_Exp>;
  source?: Maybe<Smsapi_Queue_Bool_Exp>;
  tag?: Maybe<Smsapi_Tags_Bool_Exp>;
  tag_history?: Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>;
  tag_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "smsapi_queue_extra" */
export enum Smsapi_Queue_Extra_Constraint {
  /** unique or primary key constraint */
  SmsapiQueueExtraPkey = 'smsapi_queue_extra_pkey'
}

/** input type for incrementing integer column in table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Insert_Input = {
  delivery?: Maybe<Smsapi_Deliveries_Obj_Rel_Insert_Input>;
  delivery_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Smsapi_Queue_Obj_Rel_Insert_Input>;
  message_index?: Maybe<Smsapi_Queue_Index_Obj_Rel_Insert_Input>;
  sender?: Maybe<Users_Obj_Rel_Insert_Input>;
  sent_by?: Maybe<Scalars['uuid']>;
  source?: Maybe<Smsapi_Queue_Obj_Rel_Insert_Input>;
  tag?: Maybe<Smsapi_Tags_Obj_Rel_Insert_Input>;
  tag_history?: Maybe<Smsapi_Queue_Extra_Tags_History_Arr_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Smsapi_Queue_Extra_Max_Fields = {
  delivery_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  sent_by?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Max_Order_By = {
  delivery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sent_by?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Smsapi_Queue_Extra_Min_Fields = {
  delivery_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  sent_by?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Min_Order_By = {
  delivery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sent_by?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Smsapi_Queue_Extra>;
};

/** input type for inserting object relation for remote table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Obj_Rel_Insert_Input = {
  data: Smsapi_Queue_Extra_Insert_Input;
  on_conflict?: Maybe<Smsapi_Queue_Extra_On_Conflict>;
};

/** on conflict condition type for table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_On_Conflict = {
  constraint: Smsapi_Queue_Extra_Constraint;
  update_columns: Array<Smsapi_Queue_Extra_Update_Column>;
  where?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
};

/** ordering options when selecting data from "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Order_By = {
  delivery?: Maybe<Smsapi_Deliveries_Order_By>;
  delivery_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Smsapi_Queue_Order_By>;
  message_index?: Maybe<Smsapi_Queue_Index_Order_By>;
  sender?: Maybe<Users_Order_By>;
  sent_by?: Maybe<Order_By>;
  source?: Maybe<Smsapi_Queue_Order_By>;
  tag?: Maybe<Smsapi_Tags_Order_By>;
  tag_history_aggregate?: Maybe<Smsapi_Queue_Extra_Tags_History_Aggregate_Order_By>;
  tag_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "smsapi_queue_extra" */
export enum Smsapi_Queue_Extra_Select_Column {
  /** column name */
  DeliveryId = 'delivery_id',
  /** column name */
  Id = 'id',
  /** column name */
  SentBy = 'sent_by',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Set_Input = {
  delivery_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  sent_by?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Smsapi_Queue_Extra_Stddev_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Smsapi_Queue_Extra_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Smsapi_Queue_Extra_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Smsapi_Queue_Extra_Sum_Fields = {
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History = {
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  message: Smsapi_Queue_Extra;
  message_id: Scalars['Int'];
  /** An object relationship */
  tag: Smsapi_Tags;
  tag_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Aggregate = {
  aggregate?: Maybe<Smsapi_Queue_Extra_Tags_History_Aggregate_Fields>;
  nodes: Array<Smsapi_Queue_Extra_Tags_History>;
};

/** aggregate fields of "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Aggregate_Fields = {
  avg?: Maybe<Smsapi_Queue_Extra_Tags_History_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Smsapi_Queue_Extra_Tags_History_Max_Fields>;
  min?: Maybe<Smsapi_Queue_Extra_Tags_History_Min_Fields>;
  stddev?: Maybe<Smsapi_Queue_Extra_Tags_History_Stddev_Fields>;
  stddev_pop?: Maybe<Smsapi_Queue_Extra_Tags_History_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Smsapi_Queue_Extra_Tags_History_Stddev_Samp_Fields>;
  sum?: Maybe<Smsapi_Queue_Extra_Tags_History_Sum_Fields>;
  var_pop?: Maybe<Smsapi_Queue_Extra_Tags_History_Var_Pop_Fields>;
  var_samp?: Maybe<Smsapi_Queue_Extra_Tags_History_Var_Samp_Fields>;
  variance?: Maybe<Smsapi_Queue_Extra_Tags_History_Variance_Fields>;
};


/** aggregate fields of "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Aggregate_Order_By = {
  avg?: Maybe<Smsapi_Queue_Extra_Tags_History_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Smsapi_Queue_Extra_Tags_History_Max_Order_By>;
  min?: Maybe<Smsapi_Queue_Extra_Tags_History_Min_Order_By>;
  stddev?: Maybe<Smsapi_Queue_Extra_Tags_History_Stddev_Order_By>;
  stddev_pop?: Maybe<Smsapi_Queue_Extra_Tags_History_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Smsapi_Queue_Extra_Tags_History_Stddev_Samp_Order_By>;
  sum?: Maybe<Smsapi_Queue_Extra_Tags_History_Sum_Order_By>;
  var_pop?: Maybe<Smsapi_Queue_Extra_Tags_History_Var_Pop_Order_By>;
  var_samp?: Maybe<Smsapi_Queue_Extra_Tags_History_Var_Samp_Order_By>;
  variance?: Maybe<Smsapi_Queue_Extra_Tags_History_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Arr_Rel_Insert_Input = {
  data: Array<Smsapi_Queue_Extra_Tags_History_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Queue_Extra_Tags_History_On_Conflict>;
};

/** aggregate avg on columns */
export type Smsapi_Queue_Extra_Tags_History_Avg_Fields = {
  message_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Avg_Order_By = {
  message_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "smsapi_queue_extra_tags_history". All fields are combined with a logical 'AND'. */
export type Smsapi_Queue_Extra_Tags_History_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>>>;
  _not?: Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  message?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
  message_id?: Maybe<Int_Comparison_Exp>;
  tag?: Maybe<Smsapi_Tags_Bool_Exp>;
  tag_id?: Maybe<Uuid_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "smsapi_queue_extra_tags_history" */
export enum Smsapi_Queue_Extra_Tags_History_Constraint {
  /** unique or primary key constraint */
  SmsapiQueueExtraTagsHistoryPkey = 'smsapi_queue_extra_tags_history_pkey'
}

/** input type for incrementing integer column in table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Inc_Input = {
  message_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Smsapi_Queue_Extra_Obj_Rel_Insert_Input>;
  message_id?: Maybe<Scalars['Int']>;
  tag?: Maybe<Smsapi_Tags_Obj_Rel_Insert_Input>;
  tag_id?: Maybe<Scalars['uuid']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Smsapi_Queue_Extra_Tags_History_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Smsapi_Queue_Extra_Tags_History_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message_id?: Maybe<Order_By>;
  tag_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Smsapi_Queue_Extra_Tags_History>;
};

/** input type for inserting object relation for remote table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Obj_Rel_Insert_Input = {
  data: Smsapi_Queue_Extra_Tags_History_Insert_Input;
  on_conflict?: Maybe<Smsapi_Queue_Extra_Tags_History_On_Conflict>;
};

/** on conflict condition type for table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_On_Conflict = {
  constraint: Smsapi_Queue_Extra_Tags_History_Constraint;
  update_columns: Array<Smsapi_Queue_Extra_Tags_History_Update_Column>;
  where?: Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>;
};

/** ordering options when selecting data from "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Smsapi_Queue_Extra_Order_By>;
  message_id?: Maybe<Order_By>;
  tag?: Maybe<Smsapi_Tags_Order_By>;
  tag_id?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "smsapi_queue_extra_tags_history" */
export enum Smsapi_Queue_Extra_Tags_History_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message_id?: Maybe<Scalars['Int']>;
  tag_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Smsapi_Queue_Extra_Tags_History_Stddev_Fields = {
  message_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Stddev_Order_By = {
  message_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Smsapi_Queue_Extra_Tags_History_Stddev_Pop_Fields = {
  message_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Stddev_Pop_Order_By = {
  message_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Smsapi_Queue_Extra_Tags_History_Stddev_Samp_Fields = {
  message_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Stddev_Samp_Order_By = {
  message_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Smsapi_Queue_Extra_Tags_History_Sum_Fields = {
  message_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Sum_Order_By = {
  message_id?: Maybe<Order_By>;
};

/** update columns of table "smsapi_queue_extra_tags_history" */
export enum Smsapi_Queue_Extra_Tags_History_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Smsapi_Queue_Extra_Tags_History_Var_Pop_Fields = {
  message_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Var_Pop_Order_By = {
  message_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Smsapi_Queue_Extra_Tags_History_Var_Samp_Fields = {
  message_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Var_Samp_Order_By = {
  message_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Smsapi_Queue_Extra_Tags_History_Variance_Fields = {
  message_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "smsapi_queue_extra_tags_history" */
export type Smsapi_Queue_Extra_Tags_History_Variance_Order_By = {
  message_id?: Maybe<Order_By>;
};

/** update columns of table "smsapi_queue_extra" */
export enum Smsapi_Queue_Extra_Update_Column {
  /** column name */
  DeliveryId = 'delivery_id',
  /** column name */
  Id = 'id',
  /** column name */
  SentBy = 'sent_by',
  /** column name */
  TagId = 'tag_id'
}

/** aggregate var_pop on columns */
export type Smsapi_Queue_Extra_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Smsapi_Queue_Extra_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Smsapi_Queue_Extra_Variance_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "smsapi_queue_extra" */
export type Smsapi_Queue_Extra_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** input type for incrementing integer column in table "smsapi_queue" */
export type Smsapi_Queue_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "smsapi_queue_index" */
export type Smsapi_Queue_Index = {
  id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  smsapi_queue?: Maybe<Smsapi_Queue>;
  userid?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "smsapi_queue_index" */
export type Smsapi_Queue_Index_Aggregate = {
  aggregate?: Maybe<Smsapi_Queue_Index_Aggregate_Fields>;
  nodes: Array<Smsapi_Queue_Index>;
};

/** aggregate fields of "smsapi_queue_index" */
export type Smsapi_Queue_Index_Aggregate_Fields = {
  avg?: Maybe<Smsapi_Queue_Index_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Smsapi_Queue_Index_Max_Fields>;
  min?: Maybe<Smsapi_Queue_Index_Min_Fields>;
  stddev?: Maybe<Smsapi_Queue_Index_Stddev_Fields>;
  stddev_pop?: Maybe<Smsapi_Queue_Index_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Smsapi_Queue_Index_Stddev_Samp_Fields>;
  sum?: Maybe<Smsapi_Queue_Index_Sum_Fields>;
  var_pop?: Maybe<Smsapi_Queue_Index_Var_Pop_Fields>;
  var_samp?: Maybe<Smsapi_Queue_Index_Var_Samp_Fields>;
  variance?: Maybe<Smsapi_Queue_Index_Variance_Fields>;
};


/** aggregate fields of "smsapi_queue_index" */
export type Smsapi_Queue_Index_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Smsapi_Queue_Index_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Aggregate_Order_By = {
  avg?: Maybe<Smsapi_Queue_Index_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Smsapi_Queue_Index_Max_Order_By>;
  min?: Maybe<Smsapi_Queue_Index_Min_Order_By>;
  stddev?: Maybe<Smsapi_Queue_Index_Stddev_Order_By>;
  stddev_pop?: Maybe<Smsapi_Queue_Index_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Smsapi_Queue_Index_Stddev_Samp_Order_By>;
  sum?: Maybe<Smsapi_Queue_Index_Sum_Order_By>;
  var_pop?: Maybe<Smsapi_Queue_Index_Var_Pop_Order_By>;
  var_samp?: Maybe<Smsapi_Queue_Index_Var_Samp_Order_By>;
  variance?: Maybe<Smsapi_Queue_Index_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Arr_Rel_Insert_Input = {
  data: Array<Smsapi_Queue_Index_Insert_Input>;
};

/** aggregate avg on columns */
export type Smsapi_Queue_Index_Avg_Fields = {
  id?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Avg_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "smsapi_queue_index". All fields are combined with a logical 'AND'. */
export type Smsapi_Queue_Index_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Smsapi_Queue_Index_Bool_Exp>>>;
  _not?: Maybe<Smsapi_Queue_Index_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Smsapi_Queue_Index_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  smsapi_queue?: Maybe<Smsapi_Queue_Bool_Exp>;
  userid?: Maybe<Int_Comparison_Exp>;
};

/** input type for incrementing integer column in table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  smsapi_queue?: Maybe<Smsapi_Queue_Obj_Rel_Insert_Input>;
  userid?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Smsapi_Queue_Index_Max_Fields = {
  id?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Max_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Smsapi_Queue_Index_Min_Fields = {
  id?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Min_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** response of any mutation on the table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Smsapi_Queue_Index>;
};

/** input type for inserting object relation for remote table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Obj_Rel_Insert_Input = {
  data: Smsapi_Queue_Index_Insert_Input;
};

/** ordering options when selecting data from "smsapi_queue_index" */
export type Smsapi_Queue_Index_Order_By = {
  id?: Maybe<Order_By>;
  smsapi_queue?: Maybe<Smsapi_Queue_Order_By>;
  userid?: Maybe<Order_By>;
};

/** select columns of table "smsapi_queue_index" */
export enum Smsapi_Queue_Index_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Userid = 'userid'
}

/** input type for updating data in table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Smsapi_Queue_Index_Stddev_Fields = {
  id?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Smsapi_Queue_Index_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Smsapi_Queue_Index_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Smsapi_Queue_Index_Sum_Fields = {
  id?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Sum_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Smsapi_Queue_Index_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Smsapi_Queue_Index_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Smsapi_Queue_Index_Variance_Fields = {
  id?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "smsapi_queue_index" */
export type Smsapi_Queue_Index_Variance_Order_By = {
  id?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** input type for inserting data into table "smsapi_queue" */
export type Smsapi_Queue_Insert_Input = {
  body?: Maybe<Scalars['String']>;
  contact?: Maybe<Contacts_Arr_Rel_Insert_Input>;
  date?: Maybe<Scalars['timestamp']>;
  extra?: Maybe<Smsapi_Queue_Extra_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_number_conn?: Maybe<Phone_Numbers_Obj_Rel_Insert_Input>;
  second_sim?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Smsapi_Queue_Max_Fields = {
  body?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Max_Order_By = {
  body?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Smsapi_Queue_Min_Fields = {
  body?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Min_Order_By = {
  body?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** response of any mutation on the table "smsapi_queue" */
export type Smsapi_Queue_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Smsapi_Queue>;
};

/** input type for inserting object relation for remote table "smsapi_queue" */
export type Smsapi_Queue_Obj_Rel_Insert_Input = {
  data: Smsapi_Queue_Insert_Input;
};

/** ordering options when selecting data from "smsapi_queue" */
export type Smsapi_Queue_Order_By = {
  body?: Maybe<Order_By>;
  contact_aggregate?: Maybe<Contacts_Aggregate_Order_By>;
  date?: Maybe<Order_By>;
  extra?: Maybe<Smsapi_Queue_Extra_Order_By>;
  id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  phone_number_conn?: Maybe<Phone_Numbers_Order_By>;
  second_sim?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** select columns of table "smsapi_queue" */
export enum Smsapi_Queue_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  SecondSim = 'second_sim',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  Userid = 'userid'
}

/** input type for updating data in table "smsapi_queue" */
export type Smsapi_Queue_Set_Input = {
  body?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  phone_number?: Maybe<Scalars['String']>;
  second_sim?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Smsapi_Queue_Stddev_Fields = {
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Smsapi_Queue_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Smsapi_Queue_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Smsapi_Queue_Sum_Fields = {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  userid?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Sum_Order_By = {
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Smsapi_Queue_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Smsapi_Queue_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Smsapi_Queue_Variance_Fields = {
  id?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['Float']>;
  userid?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "smsapi_queue" */
export type Smsapi_Queue_Variance_Order_By = {
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  userid?: Maybe<Order_By>;
};

/** columns and relationships of "smsapi_tags" */
export type Smsapi_Tags = {
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};

/** aggregated selection of "smsapi_tags" */
export type Smsapi_Tags_Aggregate = {
  aggregate?: Maybe<Smsapi_Tags_Aggregate_Fields>;
  nodes: Array<Smsapi_Tags>;
};

/** aggregate fields of "smsapi_tags" */
export type Smsapi_Tags_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Smsapi_Tags_Max_Fields>;
  min?: Maybe<Smsapi_Tags_Min_Fields>;
};


/** aggregate fields of "smsapi_tags" */
export type Smsapi_Tags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Smsapi_Tags_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smsapi_tags" */
export type Smsapi_Tags_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Smsapi_Tags_Max_Order_By>;
  min?: Maybe<Smsapi_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "smsapi_tags" */
export type Smsapi_Tags_Arr_Rel_Insert_Input = {
  data: Array<Smsapi_Tags_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "smsapi_tags". All fields are combined with a logical 'AND'. */
export type Smsapi_Tags_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Smsapi_Tags_Bool_Exp>>>;
  _not?: Maybe<Smsapi_Tags_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Smsapi_Tags_Bool_Exp>>>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "smsapi_tags" */
export enum Smsapi_Tags_Constraint {
  /** unique or primary key constraint */
  SmsapiTagsPkey = 'smsapi_tags_pkey'
}

/** input type for inserting data into table "smsapi_tags" */
export type Smsapi_Tags_Insert_Input = {
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Smsapi_Tags_Max_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "smsapi_tags" */
export type Smsapi_Tags_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Smsapi_Tags_Min_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "smsapi_tags" */
export type Smsapi_Tags_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "smsapi_tags" */
export type Smsapi_Tags_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Smsapi_Tags>;
};

/** input type for inserting object relation for remote table "smsapi_tags" */
export type Smsapi_Tags_Obj_Rel_Insert_Input = {
  data: Smsapi_Tags_Insert_Input;
  on_conflict?: Maybe<Smsapi_Tags_On_Conflict>;
};

/** on conflict condition type for table "smsapi_tags" */
export type Smsapi_Tags_On_Conflict = {
  constraint: Smsapi_Tags_Constraint;
  update_columns: Array<Smsapi_Tags_Update_Column>;
  where?: Maybe<Smsapi_Tags_Bool_Exp>;
};

/** ordering options when selecting data from "smsapi_tags" */
export type Smsapi_Tags_Order_By = {
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: "smsapi_tags" */
export type Smsapi_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "smsapi_tags" */
export enum Smsapi_Tags_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "smsapi_tags" */
export type Smsapi_Tags_Set_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "smsapi_tags" */
export enum Smsapi_Tags_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "smsapi_templates" */
export type Smsapi_Templates = {
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  condition?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  special_type?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};


/** columns and relationships of "smsapi_templates" */
export type Smsapi_TemplatesConditionArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "smsapi_templates" */
export type Smsapi_Templates_Aggregate = {
  aggregate?: Maybe<Smsapi_Templates_Aggregate_Fields>;
  nodes: Array<Smsapi_Templates>;
};

/** aggregate fields of "smsapi_templates" */
export type Smsapi_Templates_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Smsapi_Templates_Max_Fields>;
  min?: Maybe<Smsapi_Templates_Min_Fields>;
};


/** aggregate fields of "smsapi_templates" */
export type Smsapi_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Smsapi_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "smsapi_templates" */
export type Smsapi_Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Smsapi_Templates_Max_Order_By>;
  min?: Maybe<Smsapi_Templates_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Smsapi_Templates_Append_Input = {
  condition?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "smsapi_templates" */
export type Smsapi_Templates_Arr_Rel_Insert_Input = {
  data: Array<Smsapi_Templates_Insert_Input>;
  on_conflict?: Maybe<Smsapi_Templates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "smsapi_templates". All fields are combined with a logical 'AND'. */
export type Smsapi_Templates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Smsapi_Templates_Bool_Exp>>>;
  _not?: Maybe<Smsapi_Templates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Smsapi_Templates_Bool_Exp>>>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  condition?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  special_type?: Maybe<String_Comparison_Exp>;
  text?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "smsapi_templates" */
export enum Smsapi_Templates_Constraint {
  /** unique or primary key constraint */
  SmsapiTemplatesCompanyIdSpecialTypeNameKey = 'smsapi_templates_company_id_special_type_name_key',
  /** unique or primary key constraint */
  SmsapiTemplatesPkey = 'smsapi_templates_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Smsapi_Templates_Delete_At_Path_Input = {
  condition?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Smsapi_Templates_Delete_Elem_Input = {
  condition?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Smsapi_Templates_Delete_Key_Input = {
  condition?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "smsapi_templates" */
export type Smsapi_Templates_Insert_Input = {
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  condition?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  special_type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Smsapi_Templates_Max_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  special_type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "smsapi_templates" */
export type Smsapi_Templates_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  special_type?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Smsapi_Templates_Min_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  special_type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "smsapi_templates" */
export type Smsapi_Templates_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  special_type?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** response of any mutation on the table "smsapi_templates" */
export type Smsapi_Templates_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Smsapi_Templates>;
};

/** input type for inserting object relation for remote table "smsapi_templates" */
export type Smsapi_Templates_Obj_Rel_Insert_Input = {
  data: Smsapi_Templates_Insert_Input;
  on_conflict?: Maybe<Smsapi_Templates_On_Conflict>;
};

/** on conflict condition type for table "smsapi_templates" */
export type Smsapi_Templates_On_Conflict = {
  constraint: Smsapi_Templates_Constraint;
  update_columns: Array<Smsapi_Templates_Update_Column>;
  where?: Maybe<Smsapi_Templates_Bool_Exp>;
};

/** ordering options when selecting data from "smsapi_templates" */
export type Smsapi_Templates_Order_By = {
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  condition?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  special_type?: Maybe<Order_By>;
  text?: Maybe<Order_By>;
};

/** primary key columns input for table: "smsapi_templates" */
export type Smsapi_Templates_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Smsapi_Templates_Prepend_Input = {
  condition?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "smsapi_templates" */
export enum Smsapi_Templates_Select_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Condition = 'condition',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SpecialType = 'special_type',
  /** column name */
  Text = 'text'
}

/** input type for updating data in table "smsapi_templates" */
export type Smsapi_Templates_Set_Input = {
  company_id?: Maybe<Scalars['uuid']>;
  condition?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  special_type?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

/** update columns of table "smsapi_templates" */
export enum Smsapi_Templates_Update_Column {
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  Condition = 'condition',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SpecialType = 'special_type',
  /** column name */
  Text = 'text'
}

/** subscription root */
export type Subscription_Root = {
  /** fetch data from the table: "automation_flows" */
  automation_flows: Array<Automation_Flows>;
  /** fetch aggregated fields from the table: "automation_flows" */
  automation_flows_aggregate: Automation_Flows_Aggregate;
  /** fetch data from the table: "automation_flows" using primary key columns */
  automation_flows_by_pk?: Maybe<Automation_Flows>;
  /** fetch data from the table: "automation_queue" */
  automation_queue: Array<Automation_Queue>;
  /** fetch aggregated fields from the table: "automation_queue" */
  automation_queue_aggregate: Automation_Queue_Aggregate;
  /** fetch data from the table: "automation_queue" using primary key columns */
  automation_queue_by_pk?: Maybe<Automation_Queue>;
  /** fetch data from the table: "bank_accs" */
  bank_accs: Array<Bank_Accs>;
  /** fetch aggregated fields from the table: "bank_accs" */
  bank_accs_aggregate: Bank_Accs_Aggregate;
  /** fetch data from the table: "bank_accs" using primary key columns */
  bank_accs_by_pk?: Maybe<Bank_Accs>;
  /** fetch data from the table: "blocks" */
  blocks: Array<Blocks>;
  /** fetch aggregated fields from the table: "blocks" */
  blocks_aggregate: Blocks_Aggregate;
  /** fetch data from the table: "blocks" using primary key columns */
  blocks_by_pk?: Maybe<Blocks>;
  /** fetch data from the table: "businesses" */
  businesses: Array<Businesses>;
  /** fetch aggregated fields from the table: "businesses" */
  businesses_aggregate: Businesses_Aggregate;
  /** fetch data from the table: "businesses" using primary key columns */
  businesses_by_pk?: Maybe<Businesses>;
  /** fetch data from the table: "calendars" */
  calendars: Array<Calendars>;
  /** fetch aggregated fields from the table: "calendars" */
  calendars_aggregate: Calendars_Aggregate;
  /** fetch data from the table: "calendars" using primary key columns */
  calendars_by_pk?: Maybe<Calendars>;
  /** fetch data from the table: "calendars_custom_fields" */
  calendars_custom_fields: Array<Calendars_Custom_Fields>;
  /** fetch aggregated fields from the table: "calendars_custom_fields" */
  calendars_custom_fields_aggregate: Calendars_Custom_Fields_Aggregate;
  /** fetch data from the table: "calendars_custom_fields" using primary key columns */
  calendars_custom_fields_by_pk?: Maybe<Calendars_Custom_Fields>;
  /** fetch data from the table: "calendars_services" */
  calendars_services: Array<Calendars_Services>;
  /** fetch aggregated fields from the table: "calendars_services" */
  calendars_services_aggregate: Calendars_Services_Aggregate;
  /** fetch data from the table: "calendars_services" using primary key columns */
  calendars_services_by_pk?: Maybe<Calendars_Services>;
  /** fetch data from the table: "categories" */
  categories: Array<Categories>;
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table: "companies" */
  companies: Array<Companies>;
  /** fetch aggregated fields from the table: "companies" */
  companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table: "companies_categories" */
  companies_categories: Array<Companies_Categories>;
  /** fetch aggregated fields from the table: "companies_categories" */
  companies_categories_aggregate: Companies_Categories_Aggregate;
  /** fetch data from the table: "companies_categories" using primary key columns */
  companies_categories_by_pk?: Maybe<Companies_Categories>;
  /** fetch data from the table: "contacts" */
  contacts: Array<Contacts>;
  /** fetch aggregated fields from the table: "contacts" */
  contacts_aggregate: Contacts_Aggregate;
  /** fetch data from the table: "contacts" using primary key columns */
  contacts_by_pk?: Maybe<Contacts>;
  /** fetch data from the table: "contacts_custom_fields" */
  contacts_custom_fields: Array<Contacts_Custom_Fields>;
  /** fetch aggregated fields from the table: "contacts_custom_fields" */
  contacts_custom_fields_aggregate: Contacts_Custom_Fields_Aggregate;
  /** fetch data from the table: "contacts_custom_fields" using primary key columns */
  contacts_custom_fields_by_pk?: Maybe<Contacts_Custom_Fields>;
  /** fetch data from the table: "contacts_view" */
  contacts_view: Array<Contacts_View>;
  /** fetch aggregated fields from the table: "contacts_view" */
  contacts_view_aggregate: Contacts_View_Aggregate;
  /** fetch data from the table: "currencies" */
  currencies: Array<Currencies>;
  /** fetch aggregated fields from the table: "currencies" */
  currencies_aggregate: Currencies_Aggregate;
  /** fetch data from the table: "currencies" using primary key columns */
  currencies_by_pk?: Maybe<Currencies>;
  /** fetch data from the table: "custom_fields" */
  custom_fields: Array<Custom_Fields>;
  /** fetch aggregated fields from the table: "custom_fields" */
  custom_fields_aggregate: Custom_Fields_Aggregate;
  /** fetch data from the table: "custom_fields" using primary key columns */
  custom_fields_by_pk?: Maybe<Custom_Fields>;
  /** fetch data from the table: "establishments" */
  establishments: Array<Establishments>;
  /** fetch aggregated fields from the table: "establishments" */
  establishments_aggregate: Establishments_Aggregate;
  /** fetch data from the table: "establishments" using primary key columns */
  establishments_by_pk?: Maybe<Establishments>;
  /** fetch data from the table: "establishments_users" */
  establishments_users: Array<Establishments_Users>;
  /** fetch aggregated fields from the table: "establishments_users" */
  establishments_users_aggregate: Establishments_Users_Aggregate;
  /** fetch data from the table: "establishments_users" using primary key columns */
  establishments_users_by_pk?: Maybe<Establishments_Users>;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** fetch aggregated fields from the table: "orders" */
  orders_aggregate: Orders_Aggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "payments" */
  payments: Array<Payments>;
  /** fetch aggregated fields from the table: "payments" */
  payments_aggregate: Payments_Aggregate;
  /** fetch data from the table: "payments" using primary key columns */
  payments_by_pk?: Maybe<Payments>;
  /** fetch data from the table: "phone_numbers" */
  phone_numbers: Array<Phone_Numbers>;
  /** fetch aggregated fields from the table: "phone_numbers" */
  phone_numbers_aggregate: Phone_Numbers_Aggregate;
  /** fetch data from the table: "phone_numbers" using primary key columns */
  phone_numbers_by_pk?: Maybe<Phone_Numbers>;
  /** fetch data from the table: "phone_numbers_establishments" */
  phone_numbers_establishments: Array<Phone_Numbers_Establishments>;
  /** fetch aggregated fields from the table: "phone_numbers_establishments" */
  phone_numbers_establishments_aggregate: Phone_Numbers_Establishments_Aggregate;
  /** fetch data from the table: "phone_numbers_establishments" using primary key columns */
  phone_numbers_establishments_by_pk?: Maybe<Phone_Numbers_Establishments>;
  /** fetch data from the table: "phone_numbers_users" */
  phone_numbers_users: Array<Phone_Numbers_Users>;
  /** fetch aggregated fields from the table: "phone_numbers_users" */
  phone_numbers_users_aggregate: Phone_Numbers_Users_Aggregate;
  /** fetch data from the table: "phone_numbers_users" using primary key columns */
  phone_numbers_users_by_pk?: Maybe<Phone_Numbers_Users>;
  /** fetch data from the table: "pricelists" */
  pricelists: Array<Pricelists>;
  /** fetch aggregated fields from the table: "pricelists" */
  pricelists_aggregate: Pricelists_Aggregate;
  /** fetch data from the table: "pricelists" using primary key columns */
  pricelists_by_pk?: Maybe<Pricelists>;
  /** fetch data from the table: "regions" */
  regions: Array<Regions>;
  /** fetch aggregated fields from the table: "regions" */
  regions_aggregate: Regions_Aggregate;
  /** fetch data from the table: "regions" using primary key columns */
  regions_by_pk?: Maybe<Regions>;
  /** fetch data from the table: "reservations" */
  reservations: Array<Reservations>;
  /** fetch aggregated fields from the table: "reservations" */
  reservations_aggregate: Reservations_Aggregate;
  /** fetch data from the table: "reservations" using primary key columns */
  reservations_by_pk?: Maybe<Reservations>;
  /** fetch data from the table: "reservations_services" */
  reservations_services: Array<Reservations_Services>;
  /** fetch aggregated fields from the table: "reservations_services" */
  reservations_services_aggregate: Reservations_Services_Aggregate;
  /** fetch data from the table: "reservations_services" using primary key columns */
  reservations_services_by_pk?: Maybe<Reservations_Services>;
  /** fetch data from the table: "reservio_integration" */
  reservio_integration: Array<Reservio_Integration>;
  /** fetch aggregated fields from the table: "reservio_integration" */
  reservio_integration_aggregate: Reservio_Integration_Aggregate;
  /** fetch data from the table: "reservio_integration" using primary key columns */
  reservio_integration_by_pk?: Maybe<Reservio_Integration>;
  /** fetch data from the table: "reservio_integration_reservations" */
  reservio_integration_reservations: Array<Reservio_Integration_Reservations>;
  /** fetch aggregated fields from the table: "reservio_integration_reservations" */
  reservio_integration_reservations_aggregate: Reservio_Integration_Reservations_Aggregate;
  /** fetch data from the table: "reservio_integration_reservations" using primary key columns */
  reservio_integration_reservations_by_pk?: Maybe<Reservio_Integration_Reservations>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "services" */
  services: Array<Services>;
  /** fetch aggregated fields from the table: "services" */
  services_aggregate: Services_Aggregate;
  /** fetch data from the table: "services" using primary key columns */
  services_by_pk?: Maybe<Services>;
  /** fetch data from the table: "shoptet_eshops" */
  shoptet_eshops: Array<Shoptet_Eshops>;
  /** fetch aggregated fields from the table: "shoptet_eshops" */
  shoptet_eshops_aggregate: Shoptet_Eshops_Aggregate;
  /** fetch data from the table: "shoptet_eshops" using primary key columns */
  shoptet_eshops_by_pk?: Maybe<Shoptet_Eshops>;
  /** fetch data from the table: "shoptet_orders" */
  shoptet_orders: Array<Shoptet_Orders>;
  /** fetch aggregated fields from the table: "shoptet_orders" */
  shoptet_orders_aggregate: Shoptet_Orders_Aggregate;
  /** fetch data from the table: "shoptet_orders" using primary key columns */
  shoptet_orders_by_pk?: Maybe<Shoptet_Orders>;
  /** fetch data from the table: "smsapi_deliveries" */
  smsapi_deliveries: Array<Smsapi_Deliveries>;
  /** fetch aggregated fields from the table: "smsapi_deliveries" */
  smsapi_deliveries_aggregate: Smsapi_Deliveries_Aggregate;
  /** fetch data from the table: "smsapi_deliveries" using primary key columns */
  smsapi_deliveries_by_pk?: Maybe<Smsapi_Deliveries>;
  /** fetch data from the table: "smsapi_queue" */
  smsapi_queue: Array<Smsapi_Queue>;
  /** fetch aggregated fields from the table: "smsapi_queue" */
  smsapi_queue_aggregate: Smsapi_Queue_Aggregate;
  /** fetch data from the table: "smsapi_queue_extra" */
  smsapi_queue_extra: Array<Smsapi_Queue_Extra>;
  /** fetch aggregated fields from the table: "smsapi_queue_extra" */
  smsapi_queue_extra_aggregate: Smsapi_Queue_Extra_Aggregate;
  /** fetch data from the table: "smsapi_queue_extra" using primary key columns */
  smsapi_queue_extra_by_pk?: Maybe<Smsapi_Queue_Extra>;
  /** fetch data from the table: "smsapi_queue_extra_tags_history" */
  smsapi_queue_extra_tags_history: Array<Smsapi_Queue_Extra_Tags_History>;
  /** fetch aggregated fields from the table: "smsapi_queue_extra_tags_history" */
  smsapi_queue_extra_tags_history_aggregate: Smsapi_Queue_Extra_Tags_History_Aggregate;
  /** fetch data from the table: "smsapi_queue_extra_tags_history" using primary key columns */
  smsapi_queue_extra_tags_history_by_pk?: Maybe<Smsapi_Queue_Extra_Tags_History>;
  /** fetch data from the table: "smsapi_queue_index" */
  smsapi_queue_index: Array<Smsapi_Queue_Index>;
  /** fetch aggregated fields from the table: "smsapi_queue_index" */
  smsapi_queue_index_aggregate: Smsapi_Queue_Index_Aggregate;
  /** fetch data from the table: "smsapi_tags" */
  smsapi_tags: Array<Smsapi_Tags>;
  /** fetch aggregated fields from the table: "smsapi_tags" */
  smsapi_tags_aggregate: Smsapi_Tags_Aggregate;
  /** fetch data from the table: "smsapi_tags" using primary key columns */
  smsapi_tags_by_pk?: Maybe<Smsapi_Tags>;
  /** fetch data from the table: "smsapi_templates" */
  smsapi_templates: Array<Smsapi_Templates>;
  /** fetch aggregated fields from the table: "smsapi_templates" */
  smsapi_templates_aggregate: Smsapi_Templates_Aggregate;
  /** fetch data from the table: "smsapi_templates" using primary key columns */
  smsapi_templates_by_pk?: Maybe<Smsapi_Templates>;
  /** fetch data from the table: "tables" */
  tables: Array<Tables>;
  /** fetch aggregated fields from the table: "tables" */
  tables_aggregate: Tables_Aggregate;
  /** fetch data from the table: "tables" using primary key columns */
  tables_by_pk?: Maybe<Tables>;
  /** fetch data from the table: "telidoo_main.users" */
  telidoo_main_users: Array<Telidoo_Main_Users>;
  /** fetch aggregated fields from the table: "telidoo_main.users" */
  telidoo_main_users_aggregate: Telidoo_Main_Users_Aggregate;
  /** fetch data from the table: "terminal_statements" */
  terminal_statements: Array<Terminal_Statements>;
  /** fetch aggregated fields from the table: "terminal_statements" */
  terminal_statements_aggregate: Terminal_Statements_Aggregate;
  /** fetch data from the table: "terminal_statements" using primary key columns */
  terminal_statements_by_pk?: Maybe<Terminal_Statements>;
  /** perform the action: "terminal_transactions" */
  terminal_transactions?: Maybe<Array<Maybe<TerminalTransactionsOutput>>>;
  /** perform the action: "terminal_transactions_count" */
  terminal_transactions_count: TerminalTransactionsCountOutput;
  /** fetch data from the table: "terminals" */
  terminals: Array<Terminals>;
  /** fetch aggregated fields from the table: "terminals" */
  terminals_aggregate: Terminals_Aggregate;
  /** fetch data from the table: "terminals" using primary key columns */
  terminals_by_pk?: Maybe<Terminals>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "verification_requests" */
  verification_requests: Array<Verification_Requests>;
  /** fetch aggregated fields from the table: "verification_requests" */
  verification_requests_aggregate: Verification_Requests_Aggregate;
  /** fetch data from the table: "verification_requests" using primary key columns */
  verification_requests_by_pk?: Maybe<Verification_Requests>;
};


/** subscription root */
export type Subscription_RootAutomation_FlowsArgs = {
  distinct_on?: Maybe<Array<Automation_Flows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Automation_Flows_Order_By>>;
  where?: Maybe<Automation_Flows_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAutomation_Flows_AggregateArgs = {
  distinct_on?: Maybe<Array<Automation_Flows_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Automation_Flows_Order_By>>;
  where?: Maybe<Automation_Flows_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAutomation_Flows_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAutomation_QueueArgs = {
  distinct_on?: Maybe<Array<Automation_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Automation_Queue_Order_By>>;
  where?: Maybe<Automation_Queue_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAutomation_Queue_AggregateArgs = {
  distinct_on?: Maybe<Array<Automation_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Automation_Queue_Order_By>>;
  where?: Maybe<Automation_Queue_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAutomation_Queue_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootBank_AccsArgs = {
  distinct_on?: Maybe<Array<Bank_Accs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Accs_Order_By>>;
  where?: Maybe<Bank_Accs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBank_Accs_AggregateArgs = {
  distinct_on?: Maybe<Array<Bank_Accs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bank_Accs_Order_By>>;
  where?: Maybe<Bank_Accs_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBank_Accs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootBlocksArgs = {
  distinct_on?: Maybe<Array<Blocks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blocks_Order_By>>;
  where?: Maybe<Blocks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Blocks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blocks_Order_By>>;
  where?: Maybe<Blocks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlocks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootBusinessesArgs = {
  distinct_on?: Maybe<Array<Businesses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Businesses_Order_By>>;
  where?: Maybe<Businesses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBusinesses_AggregateArgs = {
  distinct_on?: Maybe<Array<Businesses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Businesses_Order_By>>;
  where?: Maybe<Businesses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBusinesses_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCalendarsArgs = {
  distinct_on?: Maybe<Array<Calendars_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Order_By>>;
  where?: Maybe<Calendars_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCalendars_AggregateArgs = {
  distinct_on?: Maybe<Array<Calendars_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Order_By>>;
  where?: Maybe<Calendars_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCalendars_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCalendars_Custom_FieldsArgs = {
  distinct_on?: Maybe<Array<Calendars_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Custom_Fields_Order_By>>;
  where?: Maybe<Calendars_Custom_Fields_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCalendars_Custom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Calendars_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Custom_Fields_Order_By>>;
  where?: Maybe<Calendars_Custom_Fields_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCalendars_Custom_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCalendars_ServicesArgs = {
  distinct_on?: Maybe<Array<Calendars_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Services_Order_By>>;
  where?: Maybe<Calendars_Services_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCalendars_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Calendars_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calendars_Services_Order_By>>;
  where?: Maybe<Calendars_Services_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCalendars_Services_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Categories_Order_By>>;
  where?: Maybe<Categories_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCompaniesArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompanies_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Order_By>>;
  where?: Maybe<Companies_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompanies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCompanies_CategoriesArgs = {
  distinct_on?: Maybe<Array<Companies_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Categories_Order_By>>;
  where?: Maybe<Companies_Categories_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompanies_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Companies_Categories_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Companies_Categories_Order_By>>;
  where?: Maybe<Companies_Categories_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCompanies_Categories_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootContactsArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContacts_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Order_By>>;
  where?: Maybe<Contacts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContacts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootContacts_Custom_FieldsArgs = {
  distinct_on?: Maybe<Array<Contacts_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Custom_Fields_Order_By>>;
  where?: Maybe<Contacts_Custom_Fields_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContacts_Custom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_Custom_Fields_Order_By>>;
  where?: Maybe<Contacts_Custom_Fields_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContacts_Custom_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootContacts_ViewArgs = {
  distinct_on?: Maybe<Array<Contacts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_View_Order_By>>;
  where?: Maybe<Contacts_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContacts_View_AggregateArgs = {
  distinct_on?: Maybe<Array<Contacts_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contacts_View_Order_By>>;
  where?: Maybe<Contacts_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCurrenciesArgs = {
  distinct_on?: Maybe<Array<Currencies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currencies_Order_By>>;
  where?: Maybe<Currencies_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCurrencies_AggregateArgs = {
  distinct_on?: Maybe<Array<Currencies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Currencies_Order_By>>;
  where?: Maybe<Currencies_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCurrencies_By_PkArgs = {
  code: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCustom_FieldsArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCustom_Fields_AggregateArgs = {
  distinct_on?: Maybe<Array<Custom_Fields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Custom_Fields_Order_By>>;
  where?: Maybe<Custom_Fields_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCustom_Fields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootEstablishmentsArgs = {
  distinct_on?: Maybe<Array<Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Order_By>>;
  where?: Maybe<Establishments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEstablishments_AggregateArgs = {
  distinct_on?: Maybe<Array<Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Order_By>>;
  where?: Maybe<Establishments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEstablishments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootEstablishments_UsersArgs = {
  distinct_on?: Maybe<Array<Establishments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Users_Order_By>>;
  where?: Maybe<Establishments_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEstablishments_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Establishments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Users_Order_By>>;
  where?: Maybe<Establishments_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEstablishments_Users_By_PkArgs = {
  establishment_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootOrdersArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrders_AggregateArgs = {
  distinct_on?: Maybe<Array<Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Orders_Order_By>>;
  where?: Maybe<Orders_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootPaymentsArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payments_Order_By>>;
  where?: Maybe<Payments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootPhone_NumbersArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Order_By>>;
  where?: Maybe<Phone_Numbers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPhone_Numbers_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Order_By>>;
  where?: Maybe<Phone_Numbers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPhone_Numbers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootPhone_Numbers_EstablishmentsArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Establishments_Order_By>>;
  where?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPhone_Numbers_Establishments_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Establishments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Establishments_Order_By>>;
  where?: Maybe<Phone_Numbers_Establishments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPhone_Numbers_Establishments_By_PkArgs = {
  establishment_id: Scalars['uuid'];
  phone_number_id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootPhone_Numbers_UsersArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Users_Order_By>>;
  where?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPhone_Numbers_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Users_Order_By>>;
  where?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPhone_Numbers_Users_By_PkArgs = {
  phone_number_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootPricelistsArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPricelists_AggregateArgs = {
  distinct_on?: Maybe<Array<Pricelists_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pricelists_Order_By>>;
  where?: Maybe<Pricelists_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPricelists_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootRegionsArgs = {
  distinct_on?: Maybe<Array<Regions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Regions_Order_By>>;
  where?: Maybe<Regions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegions_AggregateArgs = {
  distinct_on?: Maybe<Array<Regions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Regions_Order_By>>;
  where?: Maybe<Regions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRegions_By_PkArgs = {
  code: Scalars['String'];
};


/** subscription root */
export type Subscription_RootReservationsArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Order_By>>;
  where?: Maybe<Reservations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReservations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootReservations_ServicesArgs = {
  distinct_on?: Maybe<Array<Reservations_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Services_Order_By>>;
  where?: Maybe<Reservations_Services_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReservations_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservations_Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservations_Services_Order_By>>;
  where?: Maybe<Reservations_Services_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReservations_Services_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootReservio_IntegrationArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Order_By>>;
  where?: Maybe<Reservio_Integration_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReservio_Integration_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Order_By>>;
  where?: Maybe<Reservio_Integration_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReservio_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootReservio_Integration_ReservationsArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Reservations_Order_By>>;
  where?: Maybe<Reservio_Integration_Reservations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReservio_Integration_Reservations_AggregateArgs = {
  distinct_on?: Maybe<Array<Reservio_Integration_Reservations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Reservio_Integration_Reservations_Order_By>>;
  where?: Maybe<Reservio_Integration_Reservations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReservio_Integration_Reservations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootRolesArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: Maybe<Array<Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Roles_Order_By>>;
  where?: Maybe<Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootServicesArgs = {
  distinct_on?: Maybe<Array<Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Services_Order_By>>;
  where?: Maybe<Services_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootServices_AggregateArgs = {
  distinct_on?: Maybe<Array<Services_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Services_Order_By>>;
  where?: Maybe<Services_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootServices_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootShoptet_EshopsArgs = {
  distinct_on?: Maybe<Array<Shoptet_Eshops_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shoptet_Eshops_Order_By>>;
  where?: Maybe<Shoptet_Eshops_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootShoptet_Eshops_AggregateArgs = {
  distinct_on?: Maybe<Array<Shoptet_Eshops_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shoptet_Eshops_Order_By>>;
  where?: Maybe<Shoptet_Eshops_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootShoptet_Eshops_By_PkArgs = {
  primary_key: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootShoptet_OrdersArgs = {
  distinct_on?: Maybe<Array<Shoptet_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shoptet_Orders_Order_By>>;
  where?: Maybe<Shoptet_Orders_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootShoptet_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Shoptet_Orders_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shoptet_Orders_Order_By>>;
  where?: Maybe<Shoptet_Orders_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootShoptet_Orders_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootSmsapi_DeliveriesArgs = {
  distinct_on?: Maybe<Array<Smsapi_Deliveries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Deliveries_Order_By>>;
  where?: Maybe<Smsapi_Deliveries_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Deliveries_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Deliveries_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Deliveries_Order_By>>;
  where?: Maybe<Smsapi_Deliveries_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Deliveries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootSmsapi_QueueArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Order_By>>;
  where?: Maybe<Smsapi_Queue_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Queue_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Order_By>>;
  where?: Maybe<Smsapi_Queue_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Queue_ExtraArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Queue_Extra_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Queue_Extra_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootSmsapi_Queue_Extra_Tags_HistoryArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Queue_Extra_Tags_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Extra_Tags_History_Order_By>>;
  where?: Maybe<Smsapi_Queue_Extra_Tags_History_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Queue_Extra_Tags_History_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootSmsapi_Queue_IndexArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Index_Order_By>>;
  where?: Maybe<Smsapi_Queue_Index_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Queue_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Queue_Index_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Queue_Index_Order_By>>;
  where?: Maybe<Smsapi_Queue_Index_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_TagsArgs = {
  distinct_on?: Maybe<Array<Smsapi_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Tags_Order_By>>;
  where?: Maybe<Smsapi_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Tags_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Tags_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Tags_Order_By>>;
  where?: Maybe<Smsapi_Tags_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootSmsapi_TemplatesArgs = {
  distinct_on?: Maybe<Array<Smsapi_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Templates_Order_By>>;
  where?: Maybe<Smsapi_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Smsapi_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Smsapi_Templates_Order_By>>;
  where?: Maybe<Smsapi_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSmsapi_Templates_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootTablesArgs = {
  distinct_on?: Maybe<Array<Tables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tables_Order_By>>;
  where?: Maybe<Tables_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTables_AggregateArgs = {
  distinct_on?: Maybe<Array<Tables_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tables_Order_By>>;
  where?: Maybe<Tables_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTables_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootTelidoo_Main_UsersArgs = {
  distinct_on?: Maybe<Array<Telidoo_Main_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Telidoo_Main_Users_Order_By>>;
  where?: Maybe<Telidoo_Main_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTelidoo_Main_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Telidoo_Main_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Telidoo_Main_Users_Order_By>>;
  where?: Maybe<Telidoo_Main_Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTerminal_StatementsArgs = {
  distinct_on?: Maybe<Array<Terminal_Statements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Terminal_Statements_Order_By>>;
  where?: Maybe<Terminal_Statements_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTerminal_Statements_AggregateArgs = {
  distinct_on?: Maybe<Array<Terminal_Statements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Terminal_Statements_Order_By>>;
  where?: Maybe<Terminal_Statements_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTerminal_Statements_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootTerminal_TransactionsArgs = {
  api_key: Scalars['String'];
  offset: Scalars['Int'];
  pc3000_company_id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootTerminal_Transactions_CountArgs = {
  api_key: Scalars['String'];
  pc3000_company_id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootTerminalsArgs = {
  distinct_on?: Maybe<Array<Terminals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Terminals_Order_By>>;
  where?: Maybe<Terminals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTerminals_AggregateArgs = {
  distinct_on?: Maybe<Array<Terminals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Terminals_Order_By>>;
  where?: Maybe<Terminals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTerminals_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootTransactionsArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTransactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Transactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Transactions_Order_By>>;
  where?: Maybe<Transactions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootVerification_RequestsArgs = {
  distinct_on?: Maybe<Array<Verification_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Verification_Requests_Order_By>>;
  where?: Maybe<Verification_Requests_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVerification_Requests_AggregateArgs = {
  distinct_on?: Maybe<Array<Verification_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Verification_Requests_Order_By>>;
  where?: Maybe<Verification_Requests_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootVerification_Requests_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "tables" */
export type Tables = {
  establishment_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  qr_key?: Maybe<Scalars['String']>;
};

/** aggregated selection of "tables" */
export type Tables_Aggregate = {
  aggregate?: Maybe<Tables_Aggregate_Fields>;
  nodes: Array<Tables>;
};

/** aggregate fields of "tables" */
export type Tables_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Tables_Max_Fields>;
  min?: Maybe<Tables_Min_Fields>;
};


/** aggregate fields of "tables" */
export type Tables_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tables_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tables" */
export type Tables_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Tables_Max_Order_By>;
  min?: Maybe<Tables_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tables" */
export type Tables_Arr_Rel_Insert_Input = {
  data: Array<Tables_Insert_Input>;
  on_conflict?: Maybe<Tables_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tables". All fields are combined with a logical 'AND'. */
export type Tables_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Tables_Bool_Exp>>>;
  _not?: Maybe<Tables_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Tables_Bool_Exp>>>;
  establishment_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  qr_key?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tables" */
export enum Tables_Constraint {
  /** unique or primary key constraint */
  TablesPkey = 'tables_pkey',
  /** unique or primary key constraint */
  TablesQrKeyKey = 'tables_qr_key_key'
}

/** input type for inserting data into table "tables" */
export type Tables_Insert_Input = {
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  qr_key?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tables_Max_Fields = {
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  qr_key?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tables" */
export type Tables_Max_Order_By = {
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  qr_key?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tables_Min_Fields = {
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  qr_key?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tables" */
export type Tables_Min_Order_By = {
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  qr_key?: Maybe<Order_By>;
};

/** response of any mutation on the table "tables" */
export type Tables_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Tables>;
};

/** input type for inserting object relation for remote table "tables" */
export type Tables_Obj_Rel_Insert_Input = {
  data: Tables_Insert_Input;
  on_conflict?: Maybe<Tables_On_Conflict>;
};

/** on conflict condition type for table "tables" */
export type Tables_On_Conflict = {
  constraint: Tables_Constraint;
  update_columns: Array<Tables_Update_Column>;
  where?: Maybe<Tables_Bool_Exp>;
};

/** ordering options when selecting data from "tables" */
export type Tables_Order_By = {
  establishment_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  qr_key?: Maybe<Order_By>;
};

/** primary key columns input for table: "tables" */
export type Tables_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tables" */
export enum Tables_Select_Column {
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  QrKey = 'qr_key'
}

/** input type for updating data in table "tables" */
export type Tables_Set_Input = {
  establishment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  qr_key?: Maybe<Scalars['String']>;
};

/** update columns of table "tables" */
export enum Tables_Update_Column {
  /** column name */
  EstablishmentId = 'establishment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  QrKey = 'qr_key'
}

/** columns and relationships of "telidoo_main.users" */
export type Telidoo_Main_Users = {
  activated: Scalars['Boolean'];
  api_key: Scalars['bpchar'];
  business?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['jsonb']>;
  country?: Maybe<Scalars['String']>;
  custom_account?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  fcm_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inactive: Scalars['Boolean'];
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  nickname: Scalars['String'];
  password: Scalars['bpchar'];
  phone_number: Scalars['String'];
  platform: Scalars['String'];
  region: Scalars['String'];
  registered_at: Scalars['timestamp'];
  service_type?: Maybe<Scalars['String']>;
  share_info: Scalars['Boolean'];
  sim_id?: Maybe<Scalars['String']>;
  tid: Scalars['Int'];
};


/** columns and relationships of "telidoo_main.users" */
export type Telidoo_Main_UsersCompanyArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "telidoo_main.users" */
export type Telidoo_Main_Users_Aggregate = {
  aggregate?: Maybe<Telidoo_Main_Users_Aggregate_Fields>;
  nodes: Array<Telidoo_Main_Users>;
};

/** aggregate fields of "telidoo_main.users" */
export type Telidoo_Main_Users_Aggregate_Fields = {
  avg?: Maybe<Telidoo_Main_Users_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Telidoo_Main_Users_Max_Fields>;
  min?: Maybe<Telidoo_Main_Users_Min_Fields>;
  stddev?: Maybe<Telidoo_Main_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Telidoo_Main_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Telidoo_Main_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Telidoo_Main_Users_Sum_Fields>;
  var_pop?: Maybe<Telidoo_Main_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Telidoo_Main_Users_Var_Samp_Fields>;
  variance?: Maybe<Telidoo_Main_Users_Variance_Fields>;
};


/** aggregate fields of "telidoo_main.users" */
export type Telidoo_Main_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Telidoo_Main_Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "telidoo_main.users" */
export type Telidoo_Main_Users_Aggregate_Order_By = {
  avg?: Maybe<Telidoo_Main_Users_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Telidoo_Main_Users_Max_Order_By>;
  min?: Maybe<Telidoo_Main_Users_Min_Order_By>;
  stddev?: Maybe<Telidoo_Main_Users_Stddev_Order_By>;
  stddev_pop?: Maybe<Telidoo_Main_Users_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Telidoo_Main_Users_Stddev_Samp_Order_By>;
  sum?: Maybe<Telidoo_Main_Users_Sum_Order_By>;
  var_pop?: Maybe<Telidoo_Main_Users_Var_Pop_Order_By>;
  var_samp?: Maybe<Telidoo_Main_Users_Var_Samp_Order_By>;
  variance?: Maybe<Telidoo_Main_Users_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Telidoo_Main_Users_Append_Input = {
  company?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "telidoo_main.users" */
export type Telidoo_Main_Users_Arr_Rel_Insert_Input = {
  data: Array<Telidoo_Main_Users_Insert_Input>;
};

/** aggregate avg on columns */
export type Telidoo_Main_Users_Avg_Fields = {
  id?: Maybe<Scalars['Float']>;
  tid?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Avg_Order_By = {
  id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "telidoo_main.users". All fields are combined with a logical 'AND'. */
export type Telidoo_Main_Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Telidoo_Main_Users_Bool_Exp>>>;
  _not?: Maybe<Telidoo_Main_Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Telidoo_Main_Users_Bool_Exp>>>;
  activated?: Maybe<Boolean_Comparison_Exp>;
  api_key?: Maybe<Bpchar_Comparison_Exp>;
  business?: Maybe<Boolean_Comparison_Exp>;
  company?: Maybe<Jsonb_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  custom_account?: Maybe<String_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  fcm_token?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inactive?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  last_seen?: Maybe<Timestamp_Comparison_Exp>;
  nickname?: Maybe<String_Comparison_Exp>;
  password?: Maybe<Bpchar_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  platform?: Maybe<String_Comparison_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  registered_at?: Maybe<Timestamp_Comparison_Exp>;
  service_type?: Maybe<String_Comparison_Exp>;
  share_info?: Maybe<Boolean_Comparison_Exp>;
  sim_id?: Maybe<String_Comparison_Exp>;
  tid?: Maybe<Int_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Telidoo_Main_Users_Delete_At_Path_Input = {
  company?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Telidoo_Main_Users_Delete_Elem_Input = {
  company?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Telidoo_Main_Users_Delete_Key_Input = {
  company?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "telidoo_main.users" */
export type Telidoo_Main_Users_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  tid?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "telidoo_main.users" */
export type Telidoo_Main_Users_Insert_Input = {
  activated?: Maybe<Scalars['Boolean']>;
  api_key?: Maybe<Scalars['bpchar']>;
  business?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['jsonb']>;
  country?: Maybe<Scalars['String']>;
  custom_account?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inactive?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  nickname?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['bpchar']>;
  phone_number?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  registered_at?: Maybe<Scalars['timestamp']>;
  service_type?: Maybe<Scalars['String']>;
  share_info?: Maybe<Scalars['Boolean']>;
  sim_id?: Maybe<Scalars['String']>;
  tid?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Telidoo_Main_Users_Max_Fields = {
  country?: Maybe<Scalars['String']>;
  custom_account?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  nickname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  registered_at?: Maybe<Scalars['timestamp']>;
  service_type?: Maybe<Scalars['String']>;
  sim_id?: Maybe<Scalars['String']>;
  tid?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Max_Order_By = {
  country?: Maybe<Order_By>;
  custom_account?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcm_token?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  nickname?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  registered_at?: Maybe<Order_By>;
  service_type?: Maybe<Order_By>;
  sim_id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Telidoo_Main_Users_Min_Fields = {
  country?: Maybe<Scalars['String']>;
  custom_account?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  nickname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  registered_at?: Maybe<Scalars['timestamp']>;
  service_type?: Maybe<Scalars['String']>;
  sim_id?: Maybe<Scalars['String']>;
  tid?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Min_Order_By = {
  country?: Maybe<Order_By>;
  custom_account?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcm_token?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  nickname?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  registered_at?: Maybe<Order_By>;
  service_type?: Maybe<Order_By>;
  sim_id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** response of any mutation on the table "telidoo_main.users" */
export type Telidoo_Main_Users_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Telidoo_Main_Users>;
};

/** input type for inserting object relation for remote table "telidoo_main.users" */
export type Telidoo_Main_Users_Obj_Rel_Insert_Input = {
  data: Telidoo_Main_Users_Insert_Input;
};

/** ordering options when selecting data from "telidoo_main.users" */
export type Telidoo_Main_Users_Order_By = {
  activated?: Maybe<Order_By>;
  api_key?: Maybe<Order_By>;
  business?: Maybe<Order_By>;
  company?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  custom_account?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  fcm_token?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inactive?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  last_seen?: Maybe<Order_By>;
  nickname?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  platform?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  registered_at?: Maybe<Order_By>;
  service_type?: Maybe<Order_By>;
  share_info?: Maybe<Order_By>;
  sim_id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Telidoo_Main_Users_Prepend_Input = {
  company?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "telidoo_main.users" */
export enum Telidoo_Main_Users_Select_Column {
  /** column name */
  Activated = 'activated',
  /** column name */
  ApiKey = 'api_key',
  /** column name */
  Business = 'business',
  /** column name */
  Company = 'company',
  /** column name */
  Country = 'country',
  /** column name */
  CustomAccount = 'custom_account',
  /** column name */
  Email = 'email',
  /** column name */
  FcmToken = 'fcm_token',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  Inactive = 'inactive',
  /** column name */
  LastName = 'last_name',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Platform = 'platform',
  /** column name */
  Region = 'region',
  /** column name */
  RegisteredAt = 'registered_at',
  /** column name */
  ServiceType = 'service_type',
  /** column name */
  ShareInfo = 'share_info',
  /** column name */
  SimId = 'sim_id',
  /** column name */
  Tid = 'tid'
}

/** input type for updating data in table "telidoo_main.users" */
export type Telidoo_Main_Users_Set_Input = {
  activated?: Maybe<Scalars['Boolean']>;
  api_key?: Maybe<Scalars['bpchar']>;
  business?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['jsonb']>;
  country?: Maybe<Scalars['String']>;
  custom_account?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fcm_token?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inactive?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  last_seen?: Maybe<Scalars['timestamp']>;
  nickname?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['bpchar']>;
  phone_number?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  registered_at?: Maybe<Scalars['timestamp']>;
  service_type?: Maybe<Scalars['String']>;
  share_info?: Maybe<Scalars['Boolean']>;
  sim_id?: Maybe<Scalars['String']>;
  tid?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Telidoo_Main_Users_Stddev_Fields = {
  id?: Maybe<Scalars['Float']>;
  tid?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Telidoo_Main_Users_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  tid?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Telidoo_Main_Users_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  tid?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Telidoo_Main_Users_Sum_Fields = {
  id?: Maybe<Scalars['Int']>;
  tid?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Sum_Order_By = {
  id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Telidoo_Main_Users_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
  tid?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Telidoo_Main_Users_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
  tid?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Telidoo_Main_Users_Variance_Fields = {
  id?: Maybe<Scalars['Float']>;
  tid?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "telidoo_main.users" */
export type Telidoo_Main_Users_Variance_Order_By = {
  id?: Maybe<Order_By>;
  tid?: Maybe<Order_By>;
};

/** columns and relationships of "terminal_statements" */
export type Terminal_Statements = {
  acquirer: Scalars['String'];
  acquirers_id: Scalars['String'];
  company_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** aggregated selection of "terminal_statements" */
export type Terminal_Statements_Aggregate = {
  aggregate?: Maybe<Terminal_Statements_Aggregate_Fields>;
  nodes: Array<Terminal_Statements>;
};

/** aggregate fields of "terminal_statements" */
export type Terminal_Statements_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Terminal_Statements_Max_Fields>;
  min?: Maybe<Terminal_Statements_Min_Fields>;
};


/** aggregate fields of "terminal_statements" */
export type Terminal_Statements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Terminal_Statements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "terminal_statements" */
export type Terminal_Statements_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Terminal_Statements_Max_Order_By>;
  min?: Maybe<Terminal_Statements_Min_Order_By>;
};

/** input type for inserting array relation for remote table "terminal_statements" */
export type Terminal_Statements_Arr_Rel_Insert_Input = {
  data: Array<Terminal_Statements_Insert_Input>;
  on_conflict?: Maybe<Terminal_Statements_On_Conflict>;
};

/** Boolean expression to filter rows from the table "terminal_statements". All fields are combined with a logical 'AND'. */
export type Terminal_Statements_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Terminal_Statements_Bool_Exp>>>;
  _not?: Maybe<Terminal_Statements_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Terminal_Statements_Bool_Exp>>>;
  acquirer?: Maybe<String_Comparison_Exp>;
  acquirers_id?: Maybe<String_Comparison_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "terminal_statements" */
export enum Terminal_Statements_Constraint {
  /** unique or primary key constraint */
  TerminalStatementsPkey = 'terminal_statements_pkey'
}

/** input type for inserting data into table "terminal_statements" */
export type Terminal_Statements_Insert_Input = {
  acquirer?: Maybe<Scalars['String']>;
  acquirers_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Terminal_Statements_Max_Fields = {
  acquirer?: Maybe<Scalars['String']>;
  acquirers_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "terminal_statements" */
export type Terminal_Statements_Max_Order_By = {
  acquirer?: Maybe<Order_By>;
  acquirers_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Terminal_Statements_Min_Fields = {
  acquirer?: Maybe<Scalars['String']>;
  acquirers_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "terminal_statements" */
export type Terminal_Statements_Min_Order_By = {
  acquirer?: Maybe<Order_By>;
  acquirers_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "terminal_statements" */
export type Terminal_Statements_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Terminal_Statements>;
};

/** input type for inserting object relation for remote table "terminal_statements" */
export type Terminal_Statements_Obj_Rel_Insert_Input = {
  data: Terminal_Statements_Insert_Input;
  on_conflict?: Maybe<Terminal_Statements_On_Conflict>;
};

/** on conflict condition type for table "terminal_statements" */
export type Terminal_Statements_On_Conflict = {
  constraint: Terminal_Statements_Constraint;
  update_columns: Array<Terminal_Statements_Update_Column>;
  where?: Maybe<Terminal_Statements_Bool_Exp>;
};

/** ordering options when selecting data from "terminal_statements" */
export type Terminal_Statements_Order_By = {
  acquirer?: Maybe<Order_By>;
  acquirers_id?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: "terminal_statements" */
export type Terminal_Statements_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "terminal_statements" */
export enum Terminal_Statements_Select_Column {
  /** column name */
  Acquirer = 'acquirer',
  /** column name */
  AcquirersId = 'acquirers_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "terminal_statements" */
export type Terminal_Statements_Set_Input = {
  acquirer?: Maybe<Scalars['String']>;
  acquirers_id?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** update columns of table "terminal_statements" */
export enum Terminal_Statements_Update_Column {
  /** column name */
  Acquirer = 'acquirer',
  /** column name */
  AcquirersId = 'acquirers_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** columns and relationships of "terminals" */
export type Terminals = {
  acquirers_id?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  company_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  pc3000_company_id?: Maybe<Scalars['Int']>;
  statements_emails?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "terminals" */
export type Terminals_Aggregate = {
  aggregate?: Maybe<Terminals_Aggregate_Fields>;
  nodes: Array<Terminals>;
};

/** aggregate fields of "terminals" */
export type Terminals_Aggregate_Fields = {
  avg?: Maybe<Terminals_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Terminals_Max_Fields>;
  min?: Maybe<Terminals_Min_Fields>;
  stddev?: Maybe<Terminals_Stddev_Fields>;
  stddev_pop?: Maybe<Terminals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Terminals_Stddev_Samp_Fields>;
  sum?: Maybe<Terminals_Sum_Fields>;
  var_pop?: Maybe<Terminals_Var_Pop_Fields>;
  var_samp?: Maybe<Terminals_Var_Samp_Fields>;
  variance?: Maybe<Terminals_Variance_Fields>;
};


/** aggregate fields of "terminals" */
export type Terminals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Terminals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "terminals" */
export type Terminals_Aggregate_Order_By = {
  avg?: Maybe<Terminals_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Terminals_Max_Order_By>;
  min?: Maybe<Terminals_Min_Order_By>;
  stddev?: Maybe<Terminals_Stddev_Order_By>;
  stddev_pop?: Maybe<Terminals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Terminals_Stddev_Samp_Order_By>;
  sum?: Maybe<Terminals_Sum_Order_By>;
  var_pop?: Maybe<Terminals_Var_Pop_Order_By>;
  var_samp?: Maybe<Terminals_Var_Samp_Order_By>;
  variance?: Maybe<Terminals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "terminals" */
export type Terminals_Arr_Rel_Insert_Input = {
  data: Array<Terminals_Insert_Input>;
  on_conflict?: Maybe<Terminals_On_Conflict>;
};

/** aggregate avg on columns */
export type Terminals_Avg_Fields = {
  pc3000_company_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "terminals" */
export type Terminals_Avg_Order_By = {
  pc3000_company_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "terminals". All fields are combined with a logical 'AND'. */
export type Terminals_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Terminals_Bool_Exp>>>;
  _not?: Maybe<Terminals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Terminals_Bool_Exp>>>;
  acquirers_id?: Maybe<String_Comparison_Exp>;
  api_key?: Maybe<String_Comparison_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  pc3000_company_id?: Maybe<Int_Comparison_Exp>;
  statements_emails?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "terminals" */
export enum Terminals_Constraint {
  /** unique or primary key constraint */
  TerminalsPkey = 'terminals_pkey'
}

/** input type for incrementing integer column in table "terminals" */
export type Terminals_Inc_Input = {
  pc3000_company_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "terminals" */
export type Terminals_Insert_Input = {
  acquirers_id?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pc3000_company_id?: Maybe<Scalars['Int']>;
  statements_emails?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Terminals_Max_Fields = {
  acquirers_id?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pc3000_company_id?: Maybe<Scalars['Int']>;
  statements_emails?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "terminals" */
export type Terminals_Max_Order_By = {
  acquirers_id?: Maybe<Order_By>;
  api_key?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pc3000_company_id?: Maybe<Order_By>;
  statements_emails?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Terminals_Min_Fields = {
  acquirers_id?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pc3000_company_id?: Maybe<Scalars['Int']>;
  statements_emails?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "terminals" */
export type Terminals_Min_Order_By = {
  acquirers_id?: Maybe<Order_By>;
  api_key?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pc3000_company_id?: Maybe<Order_By>;
  statements_emails?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "terminals" */
export type Terminals_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Terminals>;
};

/** input type for inserting object relation for remote table "terminals" */
export type Terminals_Obj_Rel_Insert_Input = {
  data: Terminals_Insert_Input;
  on_conflict?: Maybe<Terminals_On_Conflict>;
};

/** on conflict condition type for table "terminals" */
export type Terminals_On_Conflict = {
  constraint: Terminals_Constraint;
  update_columns: Array<Terminals_Update_Column>;
  where?: Maybe<Terminals_Bool_Exp>;
};

/** ordering options when selecting data from "terminals" */
export type Terminals_Order_By = {
  acquirers_id?: Maybe<Order_By>;
  api_key?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pc3000_company_id?: Maybe<Order_By>;
  statements_emails?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "terminals" */
export type Terminals_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "terminals" */
export enum Terminals_Select_Column {
  /** column name */
  AcquirersId = 'acquirers_id',
  /** column name */
  ApiKey = 'api_key',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Pc3000CompanyId = 'pc3000_company_id',
  /** column name */
  StatementsEmails = 'statements_emails',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "terminals" */
export type Terminals_Set_Input = {
  acquirers_id?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  pc3000_company_id?: Maybe<Scalars['Int']>;
  statements_emails?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Terminals_Stddev_Fields = {
  pc3000_company_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "terminals" */
export type Terminals_Stddev_Order_By = {
  pc3000_company_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Terminals_Stddev_Pop_Fields = {
  pc3000_company_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "terminals" */
export type Terminals_Stddev_Pop_Order_By = {
  pc3000_company_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Terminals_Stddev_Samp_Fields = {
  pc3000_company_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "terminals" */
export type Terminals_Stddev_Samp_Order_By = {
  pc3000_company_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Terminals_Sum_Fields = {
  pc3000_company_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "terminals" */
export type Terminals_Sum_Order_By = {
  pc3000_company_id?: Maybe<Order_By>;
};

/** update columns of table "terminals" */
export enum Terminals_Update_Column {
  /** column name */
  AcquirersId = 'acquirers_id',
  /** column name */
  ApiKey = 'api_key',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Pc3000CompanyId = 'pc3000_company_id',
  /** column name */
  StatementsEmails = 'statements_emails',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Terminals_Var_Pop_Fields = {
  pc3000_company_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "terminals" */
export type Terminals_Var_Pop_Order_By = {
  pc3000_company_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Terminals_Var_Samp_Fields = {
  pc3000_company_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "terminals" */
export type Terminals_Var_Samp_Order_By = {
  pc3000_company_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Terminals_Variance_Fields = {
  pc3000_company_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "terminals" */
export type Terminals_Variance_Order_By = {
  pc3000_company_id?: Maybe<Order_By>;
};


/** expression to compare columns of type time. All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: Maybe<Scalars['time']>;
  _gt?: Maybe<Scalars['time']>;
  _gte?: Maybe<Scalars['time']>;
  _in?: Maybe<Array<Scalars['time']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['time']>;
  _lte?: Maybe<Scalars['time']>;
  _neq?: Maybe<Scalars['time']>;
  _nin?: Maybe<Array<Scalars['time']>>;
};


/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "transactions" */
export type Transactions = {
  amount: Scalars['numeric'];
  cancelled: Scalars['Boolean'];
  company_id: Scalars['uuid'];
  /** An object relationship */
  currency: Currencies;
  currency_code: Scalars['String'];
  date_created: Scalars['timestamptz'];
  extra_data?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  payment_id?: Maybe<Scalars['uuid']>;
  type: Scalars['String'];
};


/** columns and relationships of "transactions" */
export type TransactionsExtra_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "transactions" */
export type Transactions_Aggregate = {
  aggregate?: Maybe<Transactions_Aggregate_Fields>;
  nodes: Array<Transactions>;
};

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_Fields = {
  avg?: Maybe<Transactions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Transactions_Max_Fields>;
  min?: Maybe<Transactions_Min_Fields>;
  stddev?: Maybe<Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Transactions_Sum_Fields>;
  var_pop?: Maybe<Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Transactions_Var_Samp_Fields>;
  variance?: Maybe<Transactions_Variance_Fields>;
};


/** aggregate fields of "transactions" */
export type Transactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Transactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "transactions" */
export type Transactions_Aggregate_Order_By = {
  avg?: Maybe<Transactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Transactions_Max_Order_By>;
  min?: Maybe<Transactions_Min_Order_By>;
  stddev?: Maybe<Transactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Transactions_Sum_Order_By>;
  var_pop?: Maybe<Transactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Transactions_Var_Samp_Order_By>;
  variance?: Maybe<Transactions_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Append_Input = {
  extra_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "transactions" */
export type Transactions_Arr_Rel_Insert_Input = {
  data: Array<Transactions_Insert_Input>;
  on_conflict?: Maybe<Transactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Transactions_Avg_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "transactions" */
export type Transactions_Avg_Order_By = {
  amount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type Transactions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Transactions_Bool_Exp>>>;
  _not?: Maybe<Transactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Transactions_Bool_Exp>>>;
  amount?: Maybe<Numeric_Comparison_Exp>;
  cancelled?: Maybe<Boolean_Comparison_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  currency?: Maybe<Currencies_Bool_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  date_created?: Maybe<Timestamptz_Comparison_Exp>;
  extra_data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  payment_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "transactions" */
export enum Transactions_Constraint {
  /** unique or primary key constraint */
  TransactionsPkey = 'transactions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transactions_Delete_At_Path_Input = {
  extra_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transactions_Delete_Elem_Input = {
  extra_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transactions_Delete_Key_Input = {
  extra_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "transactions" */
export type Transactions_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "transactions" */
export type Transactions_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency?: Maybe<Currencies_Obj_Rel_Insert_Input>;
  currency_code?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  extra_data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Transactions_Max_Fields = {
  amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "transactions" */
export type Transactions_Max_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  date_created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Transactions_Min_Fields = {
  amount?: Maybe<Scalars['numeric']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "transactions" */
export type Transactions_Min_Order_By = {
  amount?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  date_created?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "transactions" */
export type Transactions_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Transactions>;
};

/** input type for inserting object relation for remote table "transactions" */
export type Transactions_Obj_Rel_Insert_Input = {
  data: Transactions_Insert_Input;
  on_conflict?: Maybe<Transactions_On_Conflict>;
};

/** on conflict condition type for table "transactions" */
export type Transactions_On_Conflict = {
  constraint: Transactions_Constraint;
  update_columns: Array<Transactions_Update_Column>;
  where?: Maybe<Transactions_Bool_Exp>;
};

/** ordering options when selecting data from "transactions" */
export type Transactions_Order_By = {
  amount?: Maybe<Order_By>;
  cancelled?: Maybe<Order_By>;
  company_id?: Maybe<Order_By>;
  currency?: Maybe<Currencies_Order_By>;
  currency_code?: Maybe<Order_By>;
  date_created?: Maybe<Order_By>;
  extra_data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  payment_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: "transactions" */
export type Transactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Prepend_Input = {
  extra_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  ExtraData = 'extra_data',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "transactions" */
export type Transactions_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  currency_code?: Maybe<Scalars['String']>;
  date_created?: Maybe<Scalars['timestamptz']>;
  extra_data?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['uuid']>;
  payment_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Transactions_Stddev_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "transactions" */
export type Transactions_Stddev_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Transactions_Stddev_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "transactions" */
export type Transactions_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Transactions_Stddev_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "transactions" */
export type Transactions_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Transactions_Sum_Fields = {
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "transactions" */
export type Transactions_Sum_Order_By = {
  amount?: Maybe<Order_By>;
};

/** update columns of table "transactions" */
export enum Transactions_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Cancelled = 'cancelled',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  DateCreated = 'date_created',
  /** column name */
  ExtraData = 'extra_data',
  /** column name */
  Id = 'id',
  /** column name */
  PaymentId = 'payment_id',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Transactions_Var_Pop_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "transactions" */
export type Transactions_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Transactions_Var_Samp_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "transactions" */
export type Transactions_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Transactions_Variance_Fields = {
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "transactions" */
export type Transactions_Variance_Order_By = {
  amount?: Maybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  active: Scalars['Boolean'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** if user is deleted, disabled is set to false. otherwise always true */
  disabled: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  establishments_users: Array<Establishments_Users>;
  /** An aggregated array relationship */
  establishments_users_aggregate: Establishments_Users_Aggregate;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_public_email: Scalars['Boolean'];
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  /** An array relationship */
  phone_numbers_users: Array<Phone_Numbers_Users>;
  /** An aggregated array relationship */
  phone_numbers_users_aggregate: Phone_Numbers_Users_Aggregate;
  photo_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  role: Roles;
  role_id: Scalars['uuid'];
};


/** columns and relationships of "users" */
export type UsersEstablishments_UsersArgs = {
  distinct_on?: Maybe<Array<Establishments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Users_Order_By>>;
  where?: Maybe<Establishments_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersEstablishments_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Establishments_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Establishments_Users_Order_By>>;
  where?: Maybe<Establishments_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPhone_Numbers_UsersArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Users_Order_By>>;
  where?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPhone_Numbers_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Phone_Numbers_Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Phone_Numbers_Users_Order_By>>;
  where?: Maybe<Phone_Numbers_Users_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  company?: Maybe<Companies_Bool_Exp>;
  company_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  disabled?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  establishments_users?: Maybe<Establishments_Users_Bool_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_public_email?: Maybe<Boolean_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  phone_numbers_users?: Maybe<Phone_Numbers_Users_Bool_Exp>;
  photo_url?: Maybe<String_Comparison_Exp>;
  role?: Maybe<Roles_Bool_Exp>;
  role_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  establishments_users?: Maybe<Establishments_Users_Arr_Rel_Insert_Input>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_public_email?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone_numbers_users?: Maybe<Phone_Numbers_Users_Arr_Rel_Insert_Input>;
  photo_url?: Maybe<Scalars['String']>;
  role?: Maybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  photo_url?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  active?: Maybe<Order_By>;
  company?: Maybe<Companies_Order_By>;
  company_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  disabled?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  establishments_users_aggregate?: Maybe<Establishments_Users_Aggregate_Order_By>;
  first_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_public_email?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  phone_numbers_users_aggregate?: Maybe<Phone_Numbers_Users_Aggregate_Order_By>;
  photo_url?: Maybe<Order_By>;
  role?: Maybe<Roles_Order_By>;
  role_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublicEmail = 'is_public_email',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Password = 'password',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  RoleId = 'role_id'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  company_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_public_email?: Maybe<Scalars['Boolean']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublicEmail = 'is_public_email',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Password = 'password',
  /** column name */
  PhotoUrl = 'photo_url',
  /** column name */
  RoleId = 'role_id'
}


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "verification_requests" */
export type Verification_Requests = {
  completed: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  phone_number?: Maybe<Scalars['String']>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "verification_requests" */
export type Verification_Requests_Aggregate = {
  aggregate?: Maybe<Verification_Requests_Aggregate_Fields>;
  nodes: Array<Verification_Requests>;
};

/** aggregate fields of "verification_requests" */
export type Verification_Requests_Aggregate_Fields = {
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Verification_Requests_Max_Fields>;
  min?: Maybe<Verification_Requests_Min_Fields>;
};


/** aggregate fields of "verification_requests" */
export type Verification_Requests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Verification_Requests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "verification_requests" */
export type Verification_Requests_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Verification_Requests_Max_Order_By>;
  min?: Maybe<Verification_Requests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "verification_requests" */
export type Verification_Requests_Arr_Rel_Insert_Input = {
  data: Array<Verification_Requests_Insert_Input>;
  on_conflict?: Maybe<Verification_Requests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "verification_requests". All fields are combined with a logical 'AND'. */
export type Verification_Requests_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Verification_Requests_Bool_Exp>>>;
  _not?: Maybe<Verification_Requests_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Verification_Requests_Bool_Exp>>>;
  completed?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "verification_requests" */
export enum Verification_Requests_Constraint {
  /** unique or primary key constraint */
  VerificationRequestsPkey = 'verification_requests_pkey'
}

/** input type for inserting data into table "verification_requests" */
export type Verification_Requests_Insert_Input = {
  completed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Verification_Requests_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "verification_requests" */
export type Verification_Requests_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Verification_Requests_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "verification_requests" */
export type Verification_Requests_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "verification_requests" */
export type Verification_Requests_Mutation_Response = {
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Verification_Requests>;
};

/** input type for inserting object relation for remote table "verification_requests" */
export type Verification_Requests_Obj_Rel_Insert_Input = {
  data: Verification_Requests_Insert_Input;
  on_conflict?: Maybe<Verification_Requests_On_Conflict>;
};

/** on conflict condition type for table "verification_requests" */
export type Verification_Requests_On_Conflict = {
  constraint: Verification_Requests_Constraint;
  update_columns: Array<Verification_Requests_Update_Column>;
  where?: Maybe<Verification_Requests_Bool_Exp>;
};

/** ordering options when selecting data from "verification_requests" */
export type Verification_Requests_Order_By = {
  completed?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "verification_requests" */
export type Verification_Requests_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "verification_requests" */
export enum Verification_Requests_Select_Column {
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "verification_requests" */
export type Verification_Requests_Set_Input = {
  completed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  phone_number?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "verification_requests" */
export enum Verification_Requests_Update_Column {
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  UserId = 'user_id'
}

export type GetMyCategoriesQueryVariables = Exact<{
  parent_id: Scalars['uuid'];
}>;


export type GetMyCategoriesQuery = { categories: Array<(
    Pick<Categories, 'id' | 'name' | 'parent_id'>
    & { parent?: Maybe<(
      Pick<Categories, 'name' | 'id'>
      & { parent?: Maybe<(
        Pick<Categories, 'id' | 'name'>
        & { parent?: Maybe<(
          Pick<Categories, 'id' | 'name'>
          & { parent?: Maybe<(
            Pick<Categories, 'id' | 'name'>
            & { parent?: Maybe<(
              Pick<Categories, 'id' | 'name'>
              & { parent?: Maybe<Pick<Categories, 'id' | 'name'>> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> };

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCategoriesQuery = { categories: Array<(
    Pick<Categories, 'id' | 'name' | 'parent_id'>
    & { parent?: Maybe<(
      Pick<Categories, 'name' | 'id'>
      & { parent?: Maybe<(
        Pick<Categories, 'id' | 'name'>
        & { parent?: Maybe<(
          Pick<Categories, 'id' | 'name'>
          & { parent?: Maybe<(
            Pick<Categories, 'id' | 'name'>
            & { parent?: Maybe<(
              Pick<Categories, 'id' | 'name'>
              & { parent?: Maybe<Pick<Categories, 'id' | 'name'>> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> };

export type GetCategoryParentQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetCategoryParentQuery = { categories: Array<Pick<Categories, 'parent_id'>> };

export type InsertCategoryMutationVariables = Exact<{
  name: Scalars['String'];
  parent_id: Scalars['uuid'];
}>;


export type InsertCategoryMutation = { insert_categories_one?: Maybe<Pick<Categories, 'name' | 'parent_id'>> };

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCategoryMutation = { delete_categories_by_pk?: Maybe<Pick<Categories, 'id' | 'name'>> };

export type UpdateCategoryMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
}>;


export type UpdateCategoryMutation = { update_categories_by_pk?: Maybe<Pick<Categories, 'name'>> };

export type GetSmsConnectedNumbersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSmsConnectedNumbersQuery = { phone_numbers: Array<(
    Pick<Phone_Numbers, 'phone_number'>
    & { centrumsms_info?: Maybe<Array<Maybe<Pick<CentrumSmsInfo, 'userid' | 'activated' | 'last_seen' | 'phone_number' | 'token' | 'second_sim' | 'second_sim_number' | 'count' | 'count_second' | 'total_count'>>>> }
  )> };

export type GetSmsTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSmsTagsQuery = { smsapi_tags: Array<Pick<Smsapi_Tags, 'id' | 'name'>> };

export type GetSmsTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSmsTemplatesQuery = { smsapi_templates: Array<Pick<Smsapi_Templates, 'id' | 'text' | 'name' | 'company_id' | 'created_at'>> };

export type InsertSmsTagMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type InsertSmsTagMutation = { insert_smsapi_tags_one?: Maybe<Pick<Smsapi_Tags, 'id' | 'name'>> };

export type DeleteSmsTagMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteSmsTagMutation = { delete_smsapi_tags_by_pk?: Maybe<Pick<Smsapi_Tags, 'id'>> };

export type InsertSmsTemplateMutationVariables = Exact<{
  text: Scalars['String'];
  name: Scalars['String'];
  condition?: Maybe<Scalars['jsonb']>;
  special_type?: Maybe<Scalars['String']>;
}>;


export type InsertSmsTemplateMutation = { insert_smsapi_templates_one?: Maybe<Pick<Smsapi_Templates, 'id' | 'text' | 'name'>> };

export type DeleteSmsTemplateMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteSmsTemplateMutation = { delete_smsapi_templates_by_pk?: Maybe<Pick<Smsapi_Templates, 'id'>> };

export type SendSmsMutationVariables = Exact<{
  message: Scalars['String'];
  recipient: Scalars['String'];
  token: Scalars['String'];
  second_sim?: Maybe<Scalars['Boolean']>;
}>;


export type SendSmsMutation = Pick<Mutation_Root, 'centrumsms_send'>;

export type SendSmsDeliveryMutationVariables = Exact<{
  recipients: Scalars['String'];
  body: Scalars['String'];
  token: Scalars['String'];
  second_sim?: Maybe<Scalars['Boolean']>;
}>;


export type SendSmsDeliveryMutation = Pick<Mutation_Root, 'centrumsms_send_group'>;

export type UpdateSmsSentMutationVariables = Exact<{
  objects: Array<Smsapi_Queue_Extra_Insert_Input>;
}>;


export type UpdateSmsSentMutation = { insert_smsapi_queue_extra?: Maybe<{ returning: Array<Pick<Smsapi_Queue_Extra, 'id'>> }> };

export type GetSmsHistoryQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  userids?: Maybe<Array<Scalars['Int']>>;
  where: Smsapi_Queue_Bool_Exp;
}>;


export type GetSmsHistoryQuery = { info: { aggregate?: Maybe<{ totalCount: Smsapi_Queue_Index_Aggregate_Fields['count'] }> }, rows: Array<(
    Pick<Smsapi_Queue_Index, 'id'>
    & { smsapi_queue?: Maybe<(
      Pick<Smsapi_Queue, 'id' | 'phone_number' | 'date' | 'type' | 'body' | 'status' | 'second_sim'>
      & { extra?: Maybe<{ tag?: Maybe<Pick<Smsapi_Tags, 'id'>>, tag_history: Array<(
          Pick<Smsapi_Queue_Extra_Tags_History, 'created_at'>
          & { user: Pick<Users, 'first_name' | 'last_name'>, tag: Pick<Smsapi_Tags, 'id'> }
        )> }>, contact: Array<Pick<Contacts, 'id' | 'phone_number' | 'title_before_name' | 'first_name' | 'last_name' | 'title_after_name' | 'custom_values'>> }
    )> }
  )> };

export type UpdateSmsTagMutationVariables = Exact<{
  id: Scalars['Int'];
  tag?: Maybe<Scalars['uuid']>;
}>;


export type UpdateSmsTagMutation = { insert_smsapi_queue_extra_one?: Maybe<Pick<Smsapi_Queue_Extra, 'id' | 'tag_id'>> };

export type GetSmsDeliveriesQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where: Smsapi_Deliveries_Bool_Exp;
}>;


export type GetSmsDeliveriesQuery = { info: { aggregate?: Maybe<{ totalCount: Smsapi_Deliveries_Aggregate_Fields['count'] }> }, rows: Array<Pick<Smsapi_Deliveries, 'id' | 'message_count' | 'sent_count' | 'delivered_count' | 'latest_message_body' | 'created_at' | 'planned_at' | 'sent_by' | 'cancelled' | 'status'>> };

export type InsertSmsDeliveryMutationVariables = Exact<{
  object: Smsapi_Deliveries_Insert_Input;
}>;


export type InsertSmsDeliveryMutation = { insert_smsapi_deliveries_one?: Maybe<Pick<Smsapi_Deliveries, 'id'>> };

export type GetMyCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyCompanyQuery = { companies: Array<(
    Pick<Companies, 'id' | 'name' | 'description' | 'short_description' | 'address_city' | 'address_postcode' | 'address_street' | 'logo_url' | 'vat_id' | 'dic' | 'website' | 'published'>
    & { companies_categories: Array<{ category: (
        Pick<Categories, 'id' | 'name' | 'parent_id'>
        & { parent?: Maybe<(
          Pick<Categories, 'id' | 'name'>
          & { parent?: Maybe<(
            Pick<Categories, 'id' | 'name'>
            & { parent?: Maybe<(
              Pick<Categories, 'id' | 'name'>
              & { parent?: Maybe<(
                Pick<Categories, 'name' | 'id'>
                & { parent?: Maybe<(
                  Pick<Categories, 'id' | 'name'>
                  & { parent?: Maybe<Pick<Categories, 'id' | 'name'>> }
                )> }
              )> }
            )> }
          )> }
        )> }
      ) }> }
  )> };

export type ChangeCompanyMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
  description: Scalars['String'];
  short_description: Scalars['String'];
  address_city: Scalars['String'];
  address_postcode: Scalars['String'];
  address_street: Scalars['String'];
  website: Scalars['String'];
  dic: Scalars['String'];
  logo_url: Scalars['String'];
}>;


export type ChangeCompanyMutation = { update_companies_by_pk?: Maybe<Pick<Companies, 'address_city'>> };

export type ChangeCompanyLogoMutationVariables = Exact<{
  id: Scalars['uuid'];
  logo_url: Scalars['String'];
}>;


export type ChangeCompanyLogoMutation = { update_companies_by_pk?: Maybe<Pick<Companies, 'logo_url'>> };

export type UpdateCompanyCategoriesMutationVariables = Exact<{
  objects: Array<Companies_Categories_Insert_Input>;
}>;


export type UpdateCompanyCategoriesMutation = { delete_companies_categories?: Maybe<Pick<Companies_Categories_Mutation_Response, 'affected_rows'>>, insert_companies_categories?: Maybe<Pick<Companies_Categories_Mutation_Response, 'affected_rows'>> };

export type ContactFragmentFragment = Pick<Contacts, 'id' | 'first_name' | 'last_name' | 'phone_number' | 'title_after_name' | 'title_before_name' | 'email' | 'birthday' | 'address_street' | 'address_postcode' | 'address_city' | 'address_country' | 'custom_values' | 'updated_at' | 'created_at'>;

export type GetContactsQueryVariables = Exact<{
  where?: Maybe<Contacts_Bool_Exp>;
}>;


export type GetContactsQuery = { rows: Array<ContactFragmentFragment> };

export type GetContactsPagingQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetContactsPagingQuery = { info: { aggregate?: Maybe<{ totalCount: Contacts_Aggregate_Fields['count'] }> }, rows: Array<ContactFragmentFragment> };

export type InsertContactMutationVariables = Exact<{
  contact: Contacts_Insert_Input;
}>;


export type InsertContactMutation = { insert_contacts_one?: Maybe<ContactFragmentFragment> };

export type InsertMultipleContactsMutationVariables = Exact<{
  contacts: Array<Contacts_Insert_Input>;
}>;


export type InsertMultipleContactsMutation = { insert_contacts?: Maybe<Pick<Contacts_Mutation_Response, 'affected_rows'>> };

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['uuid'];
  contact: Contacts_Set_Input;
}>;


export type UpdateContactMutation = { update_contacts_by_pk?: Maybe<ContactFragmentFragment> };

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteContactMutation = { delete_contacts_by_pk?: Maybe<Pick<Contacts, 'id'>> };

export type DeleteContactsMultiMutationVariables = Exact<{
  ids?: Maybe<Array<Scalars['uuid']>>;
}>;


export type DeleteContactsMultiMutation = { delete_contacts?: Maybe<Pick<Contacts_Mutation_Response, 'affected_rows'>> };

export type GetContactsCustomFieldsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactsCustomFieldsQuery = { rows: Array<{ custom_field: Pick<Custom_Fields, 'id' | 'name' | 'type'> }> };

export type InsertContactsCustomFieldMutationVariables = Exact<{
  type: Scalars['String'];
  name: Scalars['String'];
}>;


export type InsertContactsCustomFieldMutation = { result?: Maybe<Pick<Contacts_Custom_Fields, 'id'>> };

export type DeleteCustomFieldMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCustomFieldMutation = { result?: Maybe<Pick<Custom_Fields, 'id'>> };

export type GetEstablishmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEstablishmentsQuery = { establishments: Array<Pick<Establishments, 'address_city' | 'address_postcode' | 'address_street' | 'id' | 'name' | 'phone_number' | 'website' | 'email' | 'facebook' | 'instagram' | 'photo_url'>> };

export type GetEstablishmentsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEstablishmentsPageQuery = { establishments: Array<Pick<Establishments, 'address_city' | 'address_postcode' | 'address_street' | 'id' | 'name' | 'phone_number' | 'website' | 'email' | 'facebook' | 'instagram' | 'photo_url'>>, establishments_users: Array<{ user: Pick<Users, 'id' | 'email' | 'first_name' | 'last_name' | 'photo_url'>, establishment: Pick<Establishments, 'id' | 'name'> }>, users: Array<(
    Pick<Users, 'company_id' | 'first_name' | 'last_name' | 'email' | 'id' | 'active' | 'role_id' | 'disabled' | 'is_public_email' | 'photo_url'>
    & { role: Pick<Roles, 'id' | 'name' | 'can_manage_users'>, company: Pick<Companies, 'name'> }
  )>, phone_numbers: Array<Pick<Phone_Numbers, 'name' | 'id' | 'phone_number' | 'active' | 'app_connection_active'>>, phone_numbers_establishments: Array<{ establishment: Pick<Establishments, 'id' | 'name' | 'photo_url'>, phone_number: Pick<Phone_Numbers, 'id' | 'name' | 'phone_number' | 'active'> }> };

export type UpdateEstablishmentMutationVariables = Exact<{
  id: Scalars['uuid'];
  address_street?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_city?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['numeric']>;
  geo_lng?: Maybe<Scalars['numeric']>;
}>;


export type UpdateEstablishmentMutation = { update_establishments_by_pk?: Maybe<Pick<Establishments, 'name' | 'id'>> };

export type InsertEstablishmentMutationVariables = Exact<{
  address_street?: Maybe<Scalars['String']>;
  address_postcode?: Maybe<Scalars['String']>;
  address_city?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  geo_lat?: Maybe<Scalars['numeric']>;
  geo_lng?: Maybe<Scalars['numeric']>;
}>;


export type InsertEstablishmentMutation = { insert_establishments_one?: Maybe<Pick<Establishments, 'id' | 'name'>> };

export type DeleteEstablishmentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteEstablishmentMutation = { delete_establishments_by_pk?: Maybe<Pick<Establishments, 'id' | 'name'>> };

export type UpdateEstablishmentPhoneNumbersMutationVariables = Exact<{
  establishment_id: Scalars['uuid'];
  objects: Array<Phone_Numbers_Establishments_Insert_Input>;
}>;


export type UpdateEstablishmentPhoneNumbersMutation = { delete_phone_numbers_establishments?: Maybe<Pick<Phone_Numbers_Establishments_Mutation_Response, 'affected_rows'>>, insert_phone_numbers_establishments?: Maybe<Pick<Phone_Numbers_Establishments_Mutation_Response, 'affected_rows'>> };

export type UpdateEstablishmentUsersMutationVariables = Exact<{
  establishment_id: Scalars['uuid'];
  objects: Array<Establishments_Users_Insert_Input>;
}>;


export type UpdateEstablishmentUsersMutation = { delete_establishments_users?: Maybe<Pick<Establishments_Users_Mutation_Response, 'affected_rows'>>, insert_establishments_users?: Maybe<Pick<Establishments_Users_Mutation_Response, 'affected_rows'>> };

export type GetEstablishmentsUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEstablishmentsUserQuery = { establishments_users: Array<{ user: Pick<Users, 'id' | 'email' | 'first_name' | 'last_name' | 'photo_url'>, establishment: Pick<Establishments, 'id' | 'name'> }> };

export type GetPaymentsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetPaymentsQuery = { info: { aggregate?: Maybe<{ totalCount: Payments_Aggregate_Fields['count'] }> }, rows: Array<Pick<Payments, 'id' | 'amount' | 'currency_code' | 'date_created' | 'date_paid' | 'description' | 'paid' | 'key' | 'quick_code_expires' | 'quick_code' | 'cancelled'>> };

export type CreatePaymentMutationVariables = Exact<{
  amount: Scalars['numeric'];
  currency_code: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreatePaymentMutation = { create_payment?: Maybe<(
    Pick<CreatePaymentOutput, 'id'>
    & { payment?: Maybe<Pick<Payments, 'key' | 'quick_code' | 'quick_code_expires' | 'amount'>> }
  )> };

export type SendPaymentMutationVariables = Exact<{
  payment_id: Scalars['uuid'];
  user_contact: Scalars['String'];
}>;


export type SendPaymentMutation = { send_payment?: Maybe<Pick<SendPaymentOutput, 'success'>> };

export type CancelPaymentMutationVariables = Exact<{
  id: Scalars['uuid'];
  cancelled: Scalars['Boolean'];
}>;


export type CancelPaymentMutation = { update_payments_by_pk?: Maybe<Pick<Payments, 'cancelled' | 'id'>> };

export type RenewPaymentMutationVariables = Exact<{
  payment_id: Scalars['uuid'];
}>;


export type RenewPaymentMutation = { renew_payment_code?: Maybe<Pick<RenewPaymentCodeOutput, 'quick_code' | 'quick_code_expires'>> };

export type GetPhoneNumbersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPhoneNumbersQuery = { phone_numbers: Array<Pick<Phone_Numbers, 'name' | 'id' | 'phone_number' | 'active' | 'app_connection_active'>> };

export type InsertPhoneNumberMutationVariables = Exact<{
  active?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
}>;


export type InsertPhoneNumberMutation = { insert_phone_numbers_one?: Maybe<Pick<Phone_Numbers, 'id' | 'name' | 'phone_number'>> };

export type UpdatePhoneNumberMutationVariables = Exact<{
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
}>;


export type UpdatePhoneNumberMutation = { update_phone_numbers_by_pk?: Maybe<Pick<Phone_Numbers, 'id' | 'name' | 'phone_number'>> };

export type DeletePhoneNumberMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeletePhoneNumberMutation = { delete_phone_numbers_by_pk?: Maybe<Pick<Phone_Numbers, 'id'>> };

export type GetPhoneNumbersUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPhoneNumbersUsersQuery = { phone_numbers_users: Array<{ user: Pick<Users, 'id' | 'email' | 'first_name' | 'last_name'>, phone_number: Pick<Phone_Numbers, 'id' | 'name' | 'phone_number' | 'active'> }> };

export type GetPhoneNumbersEstablishmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPhoneNumbersEstablishmentsQuery = { phone_numbers_establishments: Array<{ establishment: Pick<Establishments, 'id' | 'name' | 'photo_url'>, phone_number: Pick<Phone_Numbers, 'id' | 'name' | 'phone_number' | 'active'> }> };

export type UpdatePhoneNumberEstablishmentsMutationVariables = Exact<{
  phone_number_id: Scalars['uuid'];
  objects: Array<Phone_Numbers_Establishments_Insert_Input>;
}>;


export type UpdatePhoneNumberEstablishmentsMutation = { delete_phone_numbers_establishments?: Maybe<Pick<Phone_Numbers_Establishments_Mutation_Response, 'affected_rows'>>, insert_phone_numbers_establishments?: Maybe<Pick<Phone_Numbers_Establishments_Mutation_Response, 'affected_rows'>> };

export type UpdatePhoneNumberUsersMutationVariables = Exact<{
  phone_number_id: Scalars['uuid'];
  objects: Array<Phone_Numbers_Users_Insert_Input>;
}>;


export type UpdatePhoneNumberUsersMutation = { delete_phone_numbers_users?: Maybe<Pick<Phone_Numbers_Users_Mutation_Response, 'affected_rows'>>, insert_phone_numbers_users?: Maybe<Pick<Phone_Numbers_Users_Mutation_Response, 'affected_rows'>> };

export type GetReservioIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReservioIntegrationQuery = { reservio_integration: Array<(
    Pick<Reservio_Integration, 'reservio_email' | 'reservio_user_id' | 'last_fetch' | 'reminder_interval_before'>
    & { sms_template_accepted?: Maybe<Pick<Smsapi_Templates, 'id' | 'text'>>, sms_template_confirmed?: Maybe<Pick<Smsapi_Templates, 'id' | 'text'>>, sms_template_cancelled?: Maybe<Pick<Smsapi_Templates, 'id' | 'text'>>, sms_template_rejected?: Maybe<Pick<Smsapi_Templates, 'id' | 'text'>>, sms_template_moved?: Maybe<Pick<Smsapi_Templates, 'id' | 'text'>>, sms_template_reminder?: Maybe<Pick<Smsapi_Templates, 'id' | 'text'>> }
  )> };

export type UpsertSmsTemplatesMutationVariables = Exact<{
  objects: Array<Smsapi_Templates_Insert_Input>;
}>;


export type UpsertSmsTemplatesMutation = { insert_smsapi_templates?: Maybe<{ returning: Array<Pick<Smsapi_Templates, 'text' | 'id' | 'special_type'>> }> };

export type UpdateReservioIntegrationMutationVariables = Exact<{
  values: Reservio_Integration_Set_Input;
}>;


export type UpdateReservioIntegrationMutation = { update_reservio_integration?: Maybe<Pick<Reservio_Integration_Mutation_Response, 'affected_rows'>> };

export type CreateReservioUserMutationVariables = Exact<{
  user: CreateReservioUserInput;
}>;


export type CreateReservioUserMutation = { create_reservio_user?: Maybe<Pick<CreateReservioUserOutput, 'success'>> };

export type GetRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { roles: Array<Pick<Roles, 'company_id' | 'name' | 'can_manage_users' | 'can_manage_company' | 'can_access_centrumsms' | 'id' | 'hidden'>> };

export type UpdateUserRoleMutationVariables = Exact<{
  r?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
}>;


export type UpdateUserRoleMutation = { update_users_by_pk?: Maybe<Pick<Users, 'first_name' | 'role_id'>> };

export type InsertRoleMutationVariables = Exact<{
  object: Roles_Insert_Input;
}>;


export type InsertRoleMutation = { insert_roles_one?: Maybe<Pick<Roles, 'id' | 'name'>> };

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteRoleMutation = { delete_roles_by_pk?: Maybe<Pick<Roles, 'id' | 'name'>> };

export type GetShoptetEshopsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShoptetEshopsQuery = { shoptet_eshops: Array<Pick<Shoptet_Eshops, 'eshop_name'>> };

export type GetCompanyDicQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyDicQuery = { companies: Array<Pick<Companies, 'id' | 'dic'>> };

export type GetShoptetSmsTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShoptetSmsTemplatesQuery = { smsapi_templates: Array<Pick<Smsapi_Templates, 'id' | 'text' | 'name' | 'company_id' | 'condition'>> };

export type UpdateShoptetEshopsDicMutationVariables = Exact<{
  dic: Scalars['String'];
  company_id: Scalars['uuid'];
}>;


export type UpdateShoptetEshopsDicMutation = { update_shoptet_eshops?: Maybe<{ returning: Array<Pick<Shoptet_Eshops, 'company_id'>> }> };

export type ShoptetImportContactsMutationVariables = Exact<{ [key: string]: never; }>;


export type ShoptetImportContactsMutation = { shoptet_import_contacts?: Maybe<Pick<ShoptetImportOutput, 'success'>> };

export type GetTerminalsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTerminalsQuery = { terminals: Array<Pick<Terminals, 'id' | 'api_key' | 'pc3000_company_id'>> };

export type GetTerminalTransactionsQueryVariables = Exact<{
  api_key: Scalars['String'];
  pc3000_company_id: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetTerminalTransactionsQuery = { info: Pick<TerminalTransactionsCountOutput, 'totalCount'>, rows?: Maybe<Array<Maybe<Pick<TerminalTransactionsOutput, 'id' | 'issuerTidIdentity' | 'transactionType' | 'amount' | 'currencyCode' | 'acquirerTidIdentity' | 'uuid' | 'posId' | 'successful' | 'terminalTimestamp' | 'responseText'>>>> };

export type GetTerminalStatementsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetTerminalStatementsQuery = { info: { aggregate?: Maybe<{ totalCount: Terminal_Statements_Aggregate_Fields['count'] }> }, rows: Array<Pick<Terminal_Statements, 'id' | 'acquirer' | 'acquirers_id' | 'type' | 'date' | 'url'>> };

export type GetTransactionsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetTransactionsQuery = { info: { aggregate?: Maybe<(
      { totalCount: Transactions_Aggregate_Fields['count'] }
      & { sumAmount?: Maybe<Pick<Transactions_Sum_Fields, 'amount'>> }
    )> }, rows: Array<Pick<Transactions, 'id' | 'payment_id' | 'type' | 'extra_data' | 'date_created' | 'currency_code' | 'cancelled' | 'amount'>> };

export type GetUserBasicQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserBasicQuery = { users: Array<(
    Pick<Users, 'id' | 'first_name' | 'last_name' | 'email'>
    & { company: Pick<Companies, 'name'> }
  )> };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { users: Array<(
    Pick<Users, 'company_id' | 'first_name' | 'last_name' | 'email' | 'id' | 'active' | 'role_id' | 'disabled' | 'is_public_email' | 'photo_url'>
    & { role: Pick<Roles, 'id' | 'name' | 'can_manage_users'>, company: Pick<Companies, 'name'> }
  )> };

export type InsertUserMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  role_id: Scalars['uuid'];
  disabled?: Maybe<Scalars['Boolean']>;
  is_public_email?: Maybe<Scalars['Boolean']>;
}>;


export type InsertUserMutation = { insert_users_one?: Maybe<Pick<Users, 'id' | 'email'>> };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  role_id: Scalars['uuid'];
  email?: Maybe<Scalars['String']>;
  photo_url?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  is_public_email?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserMutation = { update_users_by_pk?: Maybe<Pick<Users, 'email' | 'first_name' | 'last_name' | 'photo_url' | 'role_id' | 'disabled' | 'is_public_email'>> };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RemoveUserMutation = { delete_phone_numbers?: Maybe<Pick<Phone_Numbers_Mutation_Response, 'affected_rows'>>, delete_users_by_pk?: Maybe<Pick<Users, 'id'>> };

export type UpdateUserPhoneNumbersMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  objects: Array<Phone_Numbers_Users_Insert_Input>;
}>;


export type UpdateUserPhoneNumbersMutation = { delete_phone_numbers_users?: Maybe<Pick<Phone_Numbers_Users_Mutation_Response, 'affected_rows'>>, insert_phone_numbers_users?: Maybe<Pick<Phone_Numbers_Users_Mutation_Response, 'affected_rows'>> };

export type UpdateUserEstablishmentsMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  objects: Array<Establishments_Users_Insert_Input>;
}>;


export type UpdateUserEstablishmentsMutation = { delete_establishments_users?: Maybe<Pick<Establishments_Users_Mutation_Response, 'affected_rows'>>, insert_establishments_users?: Maybe<Pick<Establishments_Users_Mutation_Response, 'affected_rows'>> };

export type InsertUserVerificationRequestMutationVariables = Exact<{
  user_id: Scalars['uuid'];
  email?: Maybe<Scalars['String']>;
}>;


export type InsertUserVerificationRequestMutation = { insert_verification_requests_one?: Maybe<Pick<Verification_Requests, 'id' | 'email'>> };

export const ContactFragmentFragmentDoc = gql`
    fragment ContactFragment on contacts {
  id
  first_name
  last_name
  phone_number
  title_after_name
  title_before_name
  email
  birthday
  address_street
  address_postcode
  address_city
  address_country
  custom_values
  updated_at
  created_at
  custom_values
}
    `;
export const GetMyCategoriesDocument = gql`
    query GetMyCategories($parent_id: uuid!) {
  categories(order_by: {parent: {name: asc_nulls_first}}, where: {parent_id: {_eq: $parent_id}}) {
    id
    name
    parent_id
    parent {
      name
      id
      parent {
        id
        name
        parent {
          id
          name
          parent {
            id
            name
            parent {
              id
              name
              parent {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetMyCategoriesQuery__
 *
 * To run a query within a React component, call `useGetMyCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCategoriesQuery({
 *   variables: {
 *      parent_id: // value for 'parent_id'
 *   },
 * });
 */
export function useGetMyCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetMyCategoriesQuery, GetMyCategoriesQueryVariables>) {
        return Apollo.useQuery<GetMyCategoriesQuery, GetMyCategoriesQueryVariables>(GetMyCategoriesDocument, baseOptions);
      }
export function useGetMyCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCategoriesQuery, GetMyCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetMyCategoriesQuery, GetMyCategoriesQueryVariables>(GetMyCategoriesDocument, baseOptions);
        }
export type GetMyCategoriesQueryHookResult = ReturnType<typeof useGetMyCategoriesQuery>;
export type GetMyCategoriesLazyQueryHookResult = ReturnType<typeof useGetMyCategoriesLazyQuery>;
export type GetMyCategoriesQueryResult = Apollo.QueryResult<GetMyCategoriesQuery, GetMyCategoriesQueryVariables>;
export const GetAllCategoriesDocument = gql`
    query GetAllCategories {
  categories(order_by: {name: asc_nulls_first}) {
    id
    name
    parent_id
    parent {
      name
      id
      parent {
        id
        name
        parent {
          id
          name
          parent {
            id
            name
            parent {
              id
              name
              parent {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAllCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
        return Apollo.useQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, baseOptions);
      }
export function useGetAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, baseOptions);
        }
export type GetAllCategoriesQueryHookResult = ReturnType<typeof useGetAllCategoriesQuery>;
export type GetAllCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllCategoriesLazyQuery>;
export type GetAllCategoriesQueryResult = Apollo.QueryResult<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
export const GetCategoryParentDocument = gql`
    query GetCategoryParent($id: uuid!) {
  categories(where: {id: {_eq: $id}}) {
    parent_id
  }
}
    `;

/**
 * __useGetCategoryParentQuery__
 *
 * To run a query within a React component, call `useGetCategoryParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryParentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryParentQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryParentQuery, GetCategoryParentQueryVariables>) {
        return Apollo.useQuery<GetCategoryParentQuery, GetCategoryParentQueryVariables>(GetCategoryParentDocument, baseOptions);
      }
export function useGetCategoryParentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryParentQuery, GetCategoryParentQueryVariables>) {
          return Apollo.useLazyQuery<GetCategoryParentQuery, GetCategoryParentQueryVariables>(GetCategoryParentDocument, baseOptions);
        }
export type GetCategoryParentQueryHookResult = ReturnType<typeof useGetCategoryParentQuery>;
export type GetCategoryParentLazyQueryHookResult = ReturnType<typeof useGetCategoryParentLazyQuery>;
export type GetCategoryParentQueryResult = Apollo.QueryResult<GetCategoryParentQuery, GetCategoryParentQueryVariables>;
export const InsertCategoryDocument = gql`
    mutation InsertCategory($name: String!, $parent_id: uuid!) {
  insert_categories_one(object: {name: $name, parent_id: $parent_id}) {
    name
    parent_id
  }
}
    `;
export type InsertCategoryMutationFn = Apollo.MutationFunction<InsertCategoryMutation, InsertCategoryMutationVariables>;

/**
 * __useInsertCategoryMutation__
 *
 * To run a mutation, you first call `useInsertCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCategoryMutation, { data, loading, error }] = useInsertCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      parent_id: // value for 'parent_id'
 *   },
 * });
 */
export function useInsertCategoryMutation(baseOptions?: Apollo.MutationHookOptions<InsertCategoryMutation, InsertCategoryMutationVariables>) {
        return Apollo.useMutation<InsertCategoryMutation, InsertCategoryMutationVariables>(InsertCategoryDocument, baseOptions);
      }
export type InsertCategoryMutationHookResult = ReturnType<typeof useInsertCategoryMutation>;
export type InsertCategoryMutationResult = Apollo.MutationResult<InsertCategoryMutation>;
export type InsertCategoryMutationOptions = Apollo.BaseMutationOptions<InsertCategoryMutation, InsertCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($id: uuid!) {
  delete_categories_by_pk(id: $id) {
    id
    name
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, baseOptions);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($name: String, $id: uuid!) {
  update_categories_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    name
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, baseOptions);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const GetSmsConnectedNumbersDocument = gql`
    query GetSmsConnectedNumbers {
  phone_numbers(where: {app_connection_id: {_is_null: false}, app_connection_active: {_eq: true}}) {
    phone_number
    centrumsms_info {
      userid
      activated
      last_seen
      phone_number
      token
      second_sim
      second_sim_number
      count
      count_second
      total_count
    }
  }
}
    `;

/**
 * __useGetSmsConnectedNumbersQuery__
 *
 * To run a query within a React component, call `useGetSmsConnectedNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsConnectedNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsConnectedNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSmsConnectedNumbersQuery(baseOptions?: Apollo.QueryHookOptions<GetSmsConnectedNumbersQuery, GetSmsConnectedNumbersQueryVariables>) {
        return Apollo.useQuery<GetSmsConnectedNumbersQuery, GetSmsConnectedNumbersQueryVariables>(GetSmsConnectedNumbersDocument, baseOptions);
      }
export function useGetSmsConnectedNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsConnectedNumbersQuery, GetSmsConnectedNumbersQueryVariables>) {
          return Apollo.useLazyQuery<GetSmsConnectedNumbersQuery, GetSmsConnectedNumbersQueryVariables>(GetSmsConnectedNumbersDocument, baseOptions);
        }
export type GetSmsConnectedNumbersQueryHookResult = ReturnType<typeof useGetSmsConnectedNumbersQuery>;
export type GetSmsConnectedNumbersLazyQueryHookResult = ReturnType<typeof useGetSmsConnectedNumbersLazyQuery>;
export type GetSmsConnectedNumbersQueryResult = Apollo.QueryResult<GetSmsConnectedNumbersQuery, GetSmsConnectedNumbersQueryVariables>;
export const GetSmsTagsDocument = gql`
    query GetSMSTags {
  smsapi_tags {
    id
    name
  }
}
    `;

/**
 * __useGetSmsTagsQuery__
 *
 * To run a query within a React component, call `useGetSmsTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSmsTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetSmsTagsQuery, GetSmsTagsQueryVariables>) {
        return Apollo.useQuery<GetSmsTagsQuery, GetSmsTagsQueryVariables>(GetSmsTagsDocument, baseOptions);
      }
export function useGetSmsTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsTagsQuery, GetSmsTagsQueryVariables>) {
          return Apollo.useLazyQuery<GetSmsTagsQuery, GetSmsTagsQueryVariables>(GetSmsTagsDocument, baseOptions);
        }
export type GetSmsTagsQueryHookResult = ReturnType<typeof useGetSmsTagsQuery>;
export type GetSmsTagsLazyQueryHookResult = ReturnType<typeof useGetSmsTagsLazyQuery>;
export type GetSmsTagsQueryResult = Apollo.QueryResult<GetSmsTagsQuery, GetSmsTagsQueryVariables>;
export const GetSmsTemplatesDocument = gql`
    query GetSMSTemplates {
  smsapi_templates {
    id
    text
    name
    company_id
    created_at
  }
}
    `;

/**
 * __useGetSmsTemplatesQuery__
 *
 * To run a query within a React component, call `useGetSmsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSmsTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>) {
        return Apollo.useQuery<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>(GetSmsTemplatesDocument, baseOptions);
      }
export function useGetSmsTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>) {
          return Apollo.useLazyQuery<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>(GetSmsTemplatesDocument, baseOptions);
        }
export type GetSmsTemplatesQueryHookResult = ReturnType<typeof useGetSmsTemplatesQuery>;
export type GetSmsTemplatesLazyQueryHookResult = ReturnType<typeof useGetSmsTemplatesLazyQuery>;
export type GetSmsTemplatesQueryResult = Apollo.QueryResult<GetSmsTemplatesQuery, GetSmsTemplatesQueryVariables>;
export const InsertSmsTagDocument = gql`
    mutation InsertSMSTag($name: String!) {
  insert_smsapi_tags_one(object: {name: $name}) {
    id
    name
  }
}
    `;
export type InsertSmsTagMutationFn = Apollo.MutationFunction<InsertSmsTagMutation, InsertSmsTagMutationVariables>;

/**
 * __useInsertSmsTagMutation__
 *
 * To run a mutation, you first call `useInsertSmsTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSmsTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSmsTagMutation, { data, loading, error }] = useInsertSmsTagMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsertSmsTagMutation(baseOptions?: Apollo.MutationHookOptions<InsertSmsTagMutation, InsertSmsTagMutationVariables>) {
        return Apollo.useMutation<InsertSmsTagMutation, InsertSmsTagMutationVariables>(InsertSmsTagDocument, baseOptions);
      }
export type InsertSmsTagMutationHookResult = ReturnType<typeof useInsertSmsTagMutation>;
export type InsertSmsTagMutationResult = Apollo.MutationResult<InsertSmsTagMutation>;
export type InsertSmsTagMutationOptions = Apollo.BaseMutationOptions<InsertSmsTagMutation, InsertSmsTagMutationVariables>;
export const DeleteSmsTagDocument = gql`
    mutation DeleteSMSTag($id: uuid!) {
  delete_smsapi_tags_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteSmsTagMutationFn = Apollo.MutationFunction<DeleteSmsTagMutation, DeleteSmsTagMutationVariables>;

/**
 * __useDeleteSmsTagMutation__
 *
 * To run a mutation, you first call `useDeleteSmsTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmsTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmsTagMutation, { data, loading, error }] = useDeleteSmsTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmsTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSmsTagMutation, DeleteSmsTagMutationVariables>) {
        return Apollo.useMutation<DeleteSmsTagMutation, DeleteSmsTagMutationVariables>(DeleteSmsTagDocument, baseOptions);
      }
export type DeleteSmsTagMutationHookResult = ReturnType<typeof useDeleteSmsTagMutation>;
export type DeleteSmsTagMutationResult = Apollo.MutationResult<DeleteSmsTagMutation>;
export type DeleteSmsTagMutationOptions = Apollo.BaseMutationOptions<DeleteSmsTagMutation, DeleteSmsTagMutationVariables>;
export const InsertSmsTemplateDocument = gql`
    mutation InsertSMSTemplate($text: String!, $name: String!, $condition: jsonb, $special_type: String) {
  insert_smsapi_templates_one(object: {text: $text, name: $name, condition: $condition, special_type: $special_type}) {
    id
    text
    name
  }
}
    `;
export type InsertSmsTemplateMutationFn = Apollo.MutationFunction<InsertSmsTemplateMutation, InsertSmsTemplateMutationVariables>;

/**
 * __useInsertSmsTemplateMutation__
 *
 * To run a mutation, you first call `useInsertSmsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSmsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSmsTemplateMutation, { data, loading, error }] = useInsertSmsTemplateMutation({
 *   variables: {
 *      text: // value for 'text'
 *      name: // value for 'name'
 *      condition: // value for 'condition'
 *      special_type: // value for 'special_type'
 *   },
 * });
 */
export function useInsertSmsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<InsertSmsTemplateMutation, InsertSmsTemplateMutationVariables>) {
        return Apollo.useMutation<InsertSmsTemplateMutation, InsertSmsTemplateMutationVariables>(InsertSmsTemplateDocument, baseOptions);
      }
export type InsertSmsTemplateMutationHookResult = ReturnType<typeof useInsertSmsTemplateMutation>;
export type InsertSmsTemplateMutationResult = Apollo.MutationResult<InsertSmsTemplateMutation>;
export type InsertSmsTemplateMutationOptions = Apollo.BaseMutationOptions<InsertSmsTemplateMutation, InsertSmsTemplateMutationVariables>;
export const DeleteSmsTemplateDocument = gql`
    mutation DeleteSMSTemplate($id: uuid!) {
  delete_smsapi_templates_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteSmsTemplateMutationFn = Apollo.MutationFunction<DeleteSmsTemplateMutation, DeleteSmsTemplateMutationVariables>;

/**
 * __useDeleteSmsTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteSmsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmsTemplateMutation, { data, loading, error }] = useDeleteSmsTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSmsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSmsTemplateMutation, DeleteSmsTemplateMutationVariables>) {
        return Apollo.useMutation<DeleteSmsTemplateMutation, DeleteSmsTemplateMutationVariables>(DeleteSmsTemplateDocument, baseOptions);
      }
export type DeleteSmsTemplateMutationHookResult = ReturnType<typeof useDeleteSmsTemplateMutation>;
export type DeleteSmsTemplateMutationResult = Apollo.MutationResult<DeleteSmsTemplateMutation>;
export type DeleteSmsTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteSmsTemplateMutation, DeleteSmsTemplateMutationVariables>;
export const SendSmsDocument = gql`
    mutation SendSMS($message: String!, $recipient: String!, $token: String!, $second_sim: Boolean = false) {
  centrumsms_send(message: $message, recipient: $recipient, token: $token, second_sim: $second_sim)
}
    `;
export type SendSmsMutationFn = Apollo.MutationFunction<SendSmsMutation, SendSmsMutationVariables>;

/**
 * __useSendSmsMutation__
 *
 * To run a mutation, you first call `useSendSmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsMutation, { data, loading, error }] = useSendSmsMutation({
 *   variables: {
 *      message: // value for 'message'
 *      recipient: // value for 'recipient'
 *      token: // value for 'token'
 *      second_sim: // value for 'second_sim'
 *   },
 * });
 */
export function useSendSmsMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsMutation, SendSmsMutationVariables>) {
        return Apollo.useMutation<SendSmsMutation, SendSmsMutationVariables>(SendSmsDocument, baseOptions);
      }
export type SendSmsMutationHookResult = ReturnType<typeof useSendSmsMutation>;
export type SendSmsMutationResult = Apollo.MutationResult<SendSmsMutation>;
export type SendSmsMutationOptions = Apollo.BaseMutationOptions<SendSmsMutation, SendSmsMutationVariables>;
export const SendSmsDeliveryDocument = gql`
    mutation SendSMSDelivery($recipients: String!, $body: String!, $token: String!, $second_sim: Boolean = false) {
  centrumsms_send_group(recipients: $recipients, body: $body, token: $token, second_sim: $second_sim)
}
    `;
export type SendSmsDeliveryMutationFn = Apollo.MutationFunction<SendSmsDeliveryMutation, SendSmsDeliveryMutationVariables>;

/**
 * __useSendSmsDeliveryMutation__
 *
 * To run a mutation, you first call `useSendSmsDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSmsDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSmsDeliveryMutation, { data, loading, error }] = useSendSmsDeliveryMutation({
 *   variables: {
 *      recipients: // value for 'recipients'
 *      body: // value for 'body'
 *      token: // value for 'token'
 *      second_sim: // value for 'second_sim'
 *   },
 * });
 */
export function useSendSmsDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<SendSmsDeliveryMutation, SendSmsDeliveryMutationVariables>) {
        return Apollo.useMutation<SendSmsDeliveryMutation, SendSmsDeliveryMutationVariables>(SendSmsDeliveryDocument, baseOptions);
      }
export type SendSmsDeliveryMutationHookResult = ReturnType<typeof useSendSmsDeliveryMutation>;
export type SendSmsDeliveryMutationResult = Apollo.MutationResult<SendSmsDeliveryMutation>;
export type SendSmsDeliveryMutationOptions = Apollo.BaseMutationOptions<SendSmsDeliveryMutation, SendSmsDeliveryMutationVariables>;
export const UpdateSmsSentDocument = gql`
    mutation UpdateSMSSent($objects: [smsapi_queue_extra_insert_input!]!) {
  insert_smsapi_queue_extra(objects: $objects) {
    returning {
      id
    }
  }
}
    `;
export type UpdateSmsSentMutationFn = Apollo.MutationFunction<UpdateSmsSentMutation, UpdateSmsSentMutationVariables>;

/**
 * __useUpdateSmsSentMutation__
 *
 * To run a mutation, you first call `useUpdateSmsSentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmsSentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmsSentMutation, { data, loading, error }] = useUpdateSmsSentMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateSmsSentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSmsSentMutation, UpdateSmsSentMutationVariables>) {
        return Apollo.useMutation<UpdateSmsSentMutation, UpdateSmsSentMutationVariables>(UpdateSmsSentDocument, baseOptions);
      }
export type UpdateSmsSentMutationHookResult = ReturnType<typeof useUpdateSmsSentMutation>;
export type UpdateSmsSentMutationResult = Apollo.MutationResult<UpdateSmsSentMutation>;
export type UpdateSmsSentMutationOptions = Apollo.BaseMutationOptions<UpdateSmsSentMutation, UpdateSmsSentMutationVariables>;
export const GetSmsHistoryDocument = gql`
    query GetSMSHistory($limit: Int!, $offset: Int!, $userids: [Int!], $where: smsapi_queue_bool_exp!) {
  info: smsapi_queue_index_aggregate(where: {_and: [{userid: {_in: $userids}}, {smsapi_queue: $where}]}) {
    aggregate {
      totalCount: count
    }
  }
  rows: smsapi_queue_index(where: {_and: [{userid: {_in: $userids}}, {smsapi_queue: $where}]}, order_by: {id: desc}, limit: $limit, offset: $offset) {
    id
    smsapi_queue {
      id
      phone_number
      date
      type
      body
      status
      second_sim
      extra {
        tag {
          id
        }
        tag_history {
          created_at
          user {
            first_name
            last_name
          }
          tag {
            id
          }
        }
      }
      contact(order_by: {id: desc}) {
        id
        phone_number
        title_before_name
        first_name
        last_name
        title_after_name
        custom_values
      }
    }
  }
}
    `;

/**
 * __useGetSmsHistoryQuery__
 *
 * To run a query within a React component, call `useGetSmsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsHistoryQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      userids: // value for 'userids'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSmsHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetSmsHistoryQuery, GetSmsHistoryQueryVariables>) {
        return Apollo.useQuery<GetSmsHistoryQuery, GetSmsHistoryQueryVariables>(GetSmsHistoryDocument, baseOptions);
      }
export function useGetSmsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsHistoryQuery, GetSmsHistoryQueryVariables>) {
          return Apollo.useLazyQuery<GetSmsHistoryQuery, GetSmsHistoryQueryVariables>(GetSmsHistoryDocument, baseOptions);
        }
export type GetSmsHistoryQueryHookResult = ReturnType<typeof useGetSmsHistoryQuery>;
export type GetSmsHistoryLazyQueryHookResult = ReturnType<typeof useGetSmsHistoryLazyQuery>;
export type GetSmsHistoryQueryResult = Apollo.QueryResult<GetSmsHistoryQuery, GetSmsHistoryQueryVariables>;
export const UpdateSmsTagDocument = gql`
    mutation UpdateSMSTag($id: Int!, $tag: uuid) {
  insert_smsapi_queue_extra_one(object: {id: $id, tag_id: $tag, tag_history: {data: {tag_id: $tag}}}, on_conflict: {constraint: smsapi_queue_extra_pkey, update_columns: [tag_id], where: {id: {_eq: $id}}}) {
    id
    tag_id
  }
}
    `;
export type UpdateSmsTagMutationFn = Apollo.MutationFunction<UpdateSmsTagMutation, UpdateSmsTagMutationVariables>;

/**
 * __useUpdateSmsTagMutation__
 *
 * To run a mutation, you first call `useUpdateSmsTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmsTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmsTagMutation, { data, loading, error }] = useUpdateSmsTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useUpdateSmsTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSmsTagMutation, UpdateSmsTagMutationVariables>) {
        return Apollo.useMutation<UpdateSmsTagMutation, UpdateSmsTagMutationVariables>(UpdateSmsTagDocument, baseOptions);
      }
export type UpdateSmsTagMutationHookResult = ReturnType<typeof useUpdateSmsTagMutation>;
export type UpdateSmsTagMutationResult = Apollo.MutationResult<UpdateSmsTagMutation>;
export type UpdateSmsTagMutationOptions = Apollo.BaseMutationOptions<UpdateSmsTagMutation, UpdateSmsTagMutationVariables>;
export const GetSmsDeliveriesDocument = gql`
    query GetSMSDeliveries($limit: Int!, $offset: Int!, $where: smsapi_deliveries_bool_exp!) {
  info: smsapi_deliveries_aggregate(where: $where) {
    aggregate {
      totalCount: count
    }
  }
  rows: smsapi_deliveries(where: $where, order_by: {created_at: desc}, limit: $limit, offset: $offset) {
    id
    message_count
    sent_count
    delivered_count
    latest_message_body
    created_at
    planned_at
    sent_by
    cancelled
    status
  }
}
    `;

/**
 * __useGetSmsDeliveriesQuery__
 *
 * To run a query within a React component, call `useGetSmsDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSmsDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSmsDeliveriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSmsDeliveriesQuery(baseOptions: Apollo.QueryHookOptions<GetSmsDeliveriesQuery, GetSmsDeliveriesQueryVariables>) {
        return Apollo.useQuery<GetSmsDeliveriesQuery, GetSmsDeliveriesQueryVariables>(GetSmsDeliveriesDocument, baseOptions);
      }
export function useGetSmsDeliveriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSmsDeliveriesQuery, GetSmsDeliveriesQueryVariables>) {
          return Apollo.useLazyQuery<GetSmsDeliveriesQuery, GetSmsDeliveriesQueryVariables>(GetSmsDeliveriesDocument, baseOptions);
        }
export type GetSmsDeliveriesQueryHookResult = ReturnType<typeof useGetSmsDeliveriesQuery>;
export type GetSmsDeliveriesLazyQueryHookResult = ReturnType<typeof useGetSmsDeliveriesLazyQuery>;
export type GetSmsDeliveriesQueryResult = Apollo.QueryResult<GetSmsDeliveriesQuery, GetSmsDeliveriesQueryVariables>;
export const InsertSmsDeliveryDocument = gql`
    mutation InsertSMSDelivery($object: smsapi_deliveries_insert_input!) {
  insert_smsapi_deliveries_one(object: $object) {
    id
  }
}
    `;
export type InsertSmsDeliveryMutationFn = Apollo.MutationFunction<InsertSmsDeliveryMutation, InsertSmsDeliveryMutationVariables>;

/**
 * __useInsertSmsDeliveryMutation__
 *
 * To run a mutation, you first call `useInsertSmsDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSmsDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSmsDeliveryMutation, { data, loading, error }] = useInsertSmsDeliveryMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertSmsDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<InsertSmsDeliveryMutation, InsertSmsDeliveryMutationVariables>) {
        return Apollo.useMutation<InsertSmsDeliveryMutation, InsertSmsDeliveryMutationVariables>(InsertSmsDeliveryDocument, baseOptions);
      }
export type InsertSmsDeliveryMutationHookResult = ReturnType<typeof useInsertSmsDeliveryMutation>;
export type InsertSmsDeliveryMutationResult = Apollo.MutationResult<InsertSmsDeliveryMutation>;
export type InsertSmsDeliveryMutationOptions = Apollo.BaseMutationOptions<InsertSmsDeliveryMutation, InsertSmsDeliveryMutationVariables>;
export const GetMyCompanyDocument = gql`
    query GetMyCompany {
  companies {
    id
    name
    description
    short_description
    address_city
    address_postcode
    address_street
    logo_url
    vat_id
    dic
    website
    published
    companies_categories {
      category {
        id
        name
        parent_id
        parent {
          id
          name
          parent {
            id
            name
            parent {
              id
              name
              parent {
                name
                id
                parent {
                  id
                  name
                  parent {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetMyCompanyQuery__
 *
 * To run a query within a React component, call `useGetMyCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetMyCompanyQuery, GetMyCompanyQueryVariables>) {
        return Apollo.useQuery<GetMyCompanyQuery, GetMyCompanyQueryVariables>(GetMyCompanyDocument, baseOptions);
      }
export function useGetMyCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCompanyQuery, GetMyCompanyQueryVariables>) {
          return Apollo.useLazyQuery<GetMyCompanyQuery, GetMyCompanyQueryVariables>(GetMyCompanyDocument, baseOptions);
        }
export type GetMyCompanyQueryHookResult = ReturnType<typeof useGetMyCompanyQuery>;
export type GetMyCompanyLazyQueryHookResult = ReturnType<typeof useGetMyCompanyLazyQuery>;
export type GetMyCompanyQueryResult = Apollo.QueryResult<GetMyCompanyQuery, GetMyCompanyQueryVariables>;
export const ChangeCompanyDocument = gql`
    mutation ChangeCompany($id: uuid!, $name: String!, $description: String!, $short_description: String!, $address_city: String!, $address_postcode: String!, $address_street: String!, $website: String!, $dic: String!, $logo_url: String!) {
  update_companies_by_pk(pk_columns: {id: $id}, _set: {name: $name, description: $description, short_description: $short_description, address_city: $address_city, address_postcode: $address_postcode, address_street: $address_street, website: $website, dic: $dic, logo_url: $logo_url}) {
    address_city
  }
}
    `;
export type ChangeCompanyMutationFn = Apollo.MutationFunction<ChangeCompanyMutation, ChangeCompanyMutationVariables>;

/**
 * __useChangeCompanyMutation__
 *
 * To run a mutation, you first call `useChangeCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCompanyMutation, { data, loading, error }] = useChangeCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      short_description: // value for 'short_description'
 *      address_city: // value for 'address_city'
 *      address_postcode: // value for 'address_postcode'
 *      address_street: // value for 'address_street'
 *      website: // value for 'website'
 *      dic: // value for 'dic'
 *      logo_url: // value for 'logo_url'
 *   },
 * });
 */
export function useChangeCompanyMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCompanyMutation, ChangeCompanyMutationVariables>) {
        return Apollo.useMutation<ChangeCompanyMutation, ChangeCompanyMutationVariables>(ChangeCompanyDocument, baseOptions);
      }
export type ChangeCompanyMutationHookResult = ReturnType<typeof useChangeCompanyMutation>;
export type ChangeCompanyMutationResult = Apollo.MutationResult<ChangeCompanyMutation>;
export type ChangeCompanyMutationOptions = Apollo.BaseMutationOptions<ChangeCompanyMutation, ChangeCompanyMutationVariables>;
export const ChangeCompanyLogoDocument = gql`
    mutation ChangeCompanyLogo($id: uuid!, $logo_url: String!) {
  update_companies_by_pk(pk_columns: {id: $id}, _set: {logo_url: $logo_url}) {
    logo_url
  }
}
    `;
export type ChangeCompanyLogoMutationFn = Apollo.MutationFunction<ChangeCompanyLogoMutation, ChangeCompanyLogoMutationVariables>;

/**
 * __useChangeCompanyLogoMutation__
 *
 * To run a mutation, you first call `useChangeCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCompanyLogoMutation, { data, loading, error }] = useChangeCompanyLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      logo_url: // value for 'logo_url'
 *   },
 * });
 */
export function useChangeCompanyLogoMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCompanyLogoMutation, ChangeCompanyLogoMutationVariables>) {
        return Apollo.useMutation<ChangeCompanyLogoMutation, ChangeCompanyLogoMutationVariables>(ChangeCompanyLogoDocument, baseOptions);
      }
export type ChangeCompanyLogoMutationHookResult = ReturnType<typeof useChangeCompanyLogoMutation>;
export type ChangeCompanyLogoMutationResult = Apollo.MutationResult<ChangeCompanyLogoMutation>;
export type ChangeCompanyLogoMutationOptions = Apollo.BaseMutationOptions<ChangeCompanyLogoMutation, ChangeCompanyLogoMutationVariables>;
export const UpdateCompanyCategoriesDocument = gql`
    mutation UpdateCompanyCategories($objects: [companies_categories_insert_input!]!) {
  delete_companies_categories(where: {}) {
    affected_rows
  }
  insert_companies_categories(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateCompanyCategoriesMutationFn = Apollo.MutationFunction<UpdateCompanyCategoriesMutation, UpdateCompanyCategoriesMutationVariables>;

/**
 * __useUpdateCompanyCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyCategoriesMutation, { data, loading, error }] = useUpdateCompanyCategoriesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateCompanyCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyCategoriesMutation, UpdateCompanyCategoriesMutationVariables>) {
        return Apollo.useMutation<UpdateCompanyCategoriesMutation, UpdateCompanyCategoriesMutationVariables>(UpdateCompanyCategoriesDocument, baseOptions);
      }
export type UpdateCompanyCategoriesMutationHookResult = ReturnType<typeof useUpdateCompanyCategoriesMutation>;
export type UpdateCompanyCategoriesMutationResult = Apollo.MutationResult<UpdateCompanyCategoriesMutation>;
export type UpdateCompanyCategoriesMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyCategoriesMutation, UpdateCompanyCategoriesMutationVariables>;
export const GetContactsDocument = gql`
    query GetContacts($where: contacts_bool_exp = {}) {
  rows: contacts(where: $where, order_by: {last_name: desc_nulls_last}) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
      }
export function useGetContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, baseOptions);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const GetContactsPagingDocument = gql`
    query GetContactsPaging($limit: Int!, $offset: Int!) {
  info: contacts_aggregate {
    aggregate {
      totalCount: count
    }
  }
  rows: contacts(order_by: {last_name: desc_nulls_last}, limit: $limit, offset: $offset) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;

/**
 * __useGetContactsPagingQuery__
 *
 * To run a query within a React component, call `useGetContactsPagingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsPagingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsPagingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetContactsPagingQuery(baseOptions: Apollo.QueryHookOptions<GetContactsPagingQuery, GetContactsPagingQueryVariables>) {
        return Apollo.useQuery<GetContactsPagingQuery, GetContactsPagingQueryVariables>(GetContactsPagingDocument, baseOptions);
      }
export function useGetContactsPagingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsPagingQuery, GetContactsPagingQueryVariables>) {
          return Apollo.useLazyQuery<GetContactsPagingQuery, GetContactsPagingQueryVariables>(GetContactsPagingDocument, baseOptions);
        }
export type GetContactsPagingQueryHookResult = ReturnType<typeof useGetContactsPagingQuery>;
export type GetContactsPagingLazyQueryHookResult = ReturnType<typeof useGetContactsPagingLazyQuery>;
export type GetContactsPagingQueryResult = Apollo.QueryResult<GetContactsPagingQuery, GetContactsPagingQueryVariables>;
export const InsertContactDocument = gql`
    mutation InsertContact($contact: contacts_insert_input!) {
  insert_contacts_one(object: $contact) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;
export type InsertContactMutationFn = Apollo.MutationFunction<InsertContactMutation, InsertContactMutationVariables>;

/**
 * __useInsertContactMutation__
 *
 * To run a mutation, you first call `useInsertContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactMutation, { data, loading, error }] = useInsertContactMutation({
 *   variables: {
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useInsertContactMutation(baseOptions?: Apollo.MutationHookOptions<InsertContactMutation, InsertContactMutationVariables>) {
        return Apollo.useMutation<InsertContactMutation, InsertContactMutationVariables>(InsertContactDocument, baseOptions);
      }
export type InsertContactMutationHookResult = ReturnType<typeof useInsertContactMutation>;
export type InsertContactMutationResult = Apollo.MutationResult<InsertContactMutation>;
export type InsertContactMutationOptions = Apollo.BaseMutationOptions<InsertContactMutation, InsertContactMutationVariables>;
export const InsertMultipleContactsDocument = gql`
    mutation InsertMultipleContacts($contacts: [contacts_insert_input!]!) {
  insert_contacts(objects: $contacts) {
    affected_rows
  }
}
    `;
export type InsertMultipleContactsMutationFn = Apollo.MutationFunction<InsertMultipleContactsMutation, InsertMultipleContactsMutationVariables>;

/**
 * __useInsertMultipleContactsMutation__
 *
 * To run a mutation, you first call `useInsertMultipleContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertMultipleContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertMultipleContactsMutation, { data, loading, error }] = useInsertMultipleContactsMutation({
 *   variables: {
 *      contacts: // value for 'contacts'
 *   },
 * });
 */
export function useInsertMultipleContactsMutation(baseOptions?: Apollo.MutationHookOptions<InsertMultipleContactsMutation, InsertMultipleContactsMutationVariables>) {
        return Apollo.useMutation<InsertMultipleContactsMutation, InsertMultipleContactsMutationVariables>(InsertMultipleContactsDocument, baseOptions);
      }
export type InsertMultipleContactsMutationHookResult = ReturnType<typeof useInsertMultipleContactsMutation>;
export type InsertMultipleContactsMutationResult = Apollo.MutationResult<InsertMultipleContactsMutation>;
export type InsertMultipleContactsMutationOptions = Apollo.BaseMutationOptions<InsertMultipleContactsMutation, InsertMultipleContactsMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($id: uuid!, $contact: contacts_set_input!) {
  update_contacts_by_pk(pk_columns: {id: $id}, _set: $contact) {
    ...ContactFragment
  }
}
    ${ContactFragmentFragmentDoc}`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contact: // value for 'contact'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: uuid!) {
  delete_contacts_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, baseOptions);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const DeleteContactsMultiDocument = gql`
    mutation DeleteContactsMulti($ids: [uuid!]) {
  delete_contacts(where: {id: {_in: $ids}}) {
    affected_rows
  }
}
    `;
export type DeleteContactsMultiMutationFn = Apollo.MutationFunction<DeleteContactsMultiMutation, DeleteContactsMultiMutationVariables>;

/**
 * __useDeleteContactsMultiMutation__
 *
 * To run a mutation, you first call `useDeleteContactsMultiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactsMultiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactsMultiMutation, { data, loading, error }] = useDeleteContactsMultiMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteContactsMultiMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactsMultiMutation, DeleteContactsMultiMutationVariables>) {
        return Apollo.useMutation<DeleteContactsMultiMutation, DeleteContactsMultiMutationVariables>(DeleteContactsMultiDocument, baseOptions);
      }
export type DeleteContactsMultiMutationHookResult = ReturnType<typeof useDeleteContactsMultiMutation>;
export type DeleteContactsMultiMutationResult = Apollo.MutationResult<DeleteContactsMultiMutation>;
export type DeleteContactsMultiMutationOptions = Apollo.BaseMutationOptions<DeleteContactsMultiMutation, DeleteContactsMultiMutationVariables>;
export const GetContactsCustomFieldsDocument = gql`
    query GetContactsCustomFields {
  rows: contacts_custom_fields {
    custom_field {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useGetContactsCustomFieldsQuery__
 *
 * To run a query within a React component, call `useGetContactsCustomFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsCustomFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsCustomFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactsCustomFieldsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsCustomFieldsQuery, GetContactsCustomFieldsQueryVariables>) {
        return Apollo.useQuery<GetContactsCustomFieldsQuery, GetContactsCustomFieldsQueryVariables>(GetContactsCustomFieldsDocument, baseOptions);
      }
export function useGetContactsCustomFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsCustomFieldsQuery, GetContactsCustomFieldsQueryVariables>) {
          return Apollo.useLazyQuery<GetContactsCustomFieldsQuery, GetContactsCustomFieldsQueryVariables>(GetContactsCustomFieldsDocument, baseOptions);
        }
export type GetContactsCustomFieldsQueryHookResult = ReturnType<typeof useGetContactsCustomFieldsQuery>;
export type GetContactsCustomFieldsLazyQueryHookResult = ReturnType<typeof useGetContactsCustomFieldsLazyQuery>;
export type GetContactsCustomFieldsQueryResult = Apollo.QueryResult<GetContactsCustomFieldsQuery, GetContactsCustomFieldsQueryVariables>;
export const InsertContactsCustomFieldDocument = gql`
    mutation InsertContactsCustomField($type: String!, $name: String!) {
  result: insert_contacts_custom_fields_one(object: {custom_field: {data: {type: $type, name: $name}}}) {
    id
  }
}
    `;
export type InsertContactsCustomFieldMutationFn = Apollo.MutationFunction<InsertContactsCustomFieldMutation, InsertContactsCustomFieldMutationVariables>;

/**
 * __useInsertContactsCustomFieldMutation__
 *
 * To run a mutation, you first call `useInsertContactsCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertContactsCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertContactsCustomFieldMutation, { data, loading, error }] = useInsertContactsCustomFieldMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useInsertContactsCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<InsertContactsCustomFieldMutation, InsertContactsCustomFieldMutationVariables>) {
        return Apollo.useMutation<InsertContactsCustomFieldMutation, InsertContactsCustomFieldMutationVariables>(InsertContactsCustomFieldDocument, baseOptions);
      }
export type InsertContactsCustomFieldMutationHookResult = ReturnType<typeof useInsertContactsCustomFieldMutation>;
export type InsertContactsCustomFieldMutationResult = Apollo.MutationResult<InsertContactsCustomFieldMutation>;
export type InsertContactsCustomFieldMutationOptions = Apollo.BaseMutationOptions<InsertContactsCustomFieldMutation, InsertContactsCustomFieldMutationVariables>;
export const DeleteCustomFieldDocument = gql`
    mutation DeleteCustomField($id: uuid!) {
  result: delete_custom_fields_by_pk(id: $id) {
    id
  }
}
    `;
export type DeleteCustomFieldMutationFn = Apollo.MutationFunction<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables>;

/**
 * __useDeleteCustomFieldMutation__
 *
 * To run a mutation, you first call `useDeleteCustomFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomFieldMutation, { data, loading, error }] = useDeleteCustomFieldMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables>) {
        return Apollo.useMutation<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables>(DeleteCustomFieldDocument, baseOptions);
      }
export type DeleteCustomFieldMutationHookResult = ReturnType<typeof useDeleteCustomFieldMutation>;
export type DeleteCustomFieldMutationResult = Apollo.MutationResult<DeleteCustomFieldMutation>;
export type DeleteCustomFieldMutationOptions = Apollo.BaseMutationOptions<DeleteCustomFieldMutation, DeleteCustomFieldMutationVariables>;
export const GetEstablishmentsDocument = gql`
    query GetEstablishments {
  establishments(order_by: {name: asc}) {
    address_city
    address_postcode
    address_street
    id
    name
    phone_number
    website
    email
    facebook
    instagram
    photo_url
  }
}
    `;

/**
 * __useGetEstablishmentsQuery__
 *
 * To run a query within a React component, call `useGetEstablishmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstablishmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstablishmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEstablishmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetEstablishmentsQuery, GetEstablishmentsQueryVariables>) {
        return Apollo.useQuery<GetEstablishmentsQuery, GetEstablishmentsQueryVariables>(GetEstablishmentsDocument, baseOptions);
      }
export function useGetEstablishmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstablishmentsQuery, GetEstablishmentsQueryVariables>) {
          return Apollo.useLazyQuery<GetEstablishmentsQuery, GetEstablishmentsQueryVariables>(GetEstablishmentsDocument, baseOptions);
        }
export type GetEstablishmentsQueryHookResult = ReturnType<typeof useGetEstablishmentsQuery>;
export type GetEstablishmentsLazyQueryHookResult = ReturnType<typeof useGetEstablishmentsLazyQuery>;
export type GetEstablishmentsQueryResult = Apollo.QueryResult<GetEstablishmentsQuery, GetEstablishmentsQueryVariables>;
export const GetEstablishmentsPageDocument = gql`
    query GetEstablishmentsPage {
  establishments(order_by: {name: asc}) {
    address_city
    address_postcode
    address_street
    id
    name
    phone_number
    website
    email
    facebook
    instagram
    photo_url
  }
  establishments_users {
    user {
      id
      email
      first_name
      last_name
      photo_url
    }
    establishment {
      id
      name
    }
  }
  users(order_by: {email: asc}) {
    company_id
    first_name
    last_name
    email
    id
    active
    role_id
    disabled
    is_public_email
    photo_url
    role {
      id
      name
      can_manage_users
    }
    company {
      name
    }
  }
  phone_numbers(order_by: {name: asc}) {
    name
    id
    phone_number
    name
    active
    app_connection_active
  }
  phone_numbers_establishments {
    establishment {
      id
      name
      photo_url
    }
    phone_number {
      id
      name
      phone_number
      active
    }
  }
}
    `;

/**
 * __useGetEstablishmentsPageQuery__
 *
 * To run a query within a React component, call `useGetEstablishmentsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstablishmentsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstablishmentsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEstablishmentsPageQuery(baseOptions?: Apollo.QueryHookOptions<GetEstablishmentsPageQuery, GetEstablishmentsPageQueryVariables>) {
        return Apollo.useQuery<GetEstablishmentsPageQuery, GetEstablishmentsPageQueryVariables>(GetEstablishmentsPageDocument, baseOptions);
      }
export function useGetEstablishmentsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstablishmentsPageQuery, GetEstablishmentsPageQueryVariables>) {
          return Apollo.useLazyQuery<GetEstablishmentsPageQuery, GetEstablishmentsPageQueryVariables>(GetEstablishmentsPageDocument, baseOptions);
        }
export type GetEstablishmentsPageQueryHookResult = ReturnType<typeof useGetEstablishmentsPageQuery>;
export type GetEstablishmentsPageLazyQueryHookResult = ReturnType<typeof useGetEstablishmentsPageLazyQuery>;
export type GetEstablishmentsPageQueryResult = Apollo.QueryResult<GetEstablishmentsPageQuery, GetEstablishmentsPageQueryVariables>;
export const UpdateEstablishmentDocument = gql`
    mutation UpdateEstablishment($id: uuid!, $address_street: String, $address_postcode: String, $address_city: String, $name: String, $phone_number: String, $photo_url: String, $website: String, $email: String, $facebook: String, $instagram: String, $geo_lat: numeric, $geo_lng: numeric) {
  update_establishments_by_pk(pk_columns: {id: $id}, _set: {address_street: $address_street, address_postcode: $address_postcode, address_city: $address_city, name: $name, phone_number: $phone_number, photo_url: $photo_url, website: $website, email: $email, facebook: $facebook, instagram: $instagram, geo_lat: $geo_lat, geo_lng: $geo_lng}) {
    name
    id
  }
}
    `;
export type UpdateEstablishmentMutationFn = Apollo.MutationFunction<UpdateEstablishmentMutation, UpdateEstablishmentMutationVariables>;

/**
 * __useUpdateEstablishmentMutation__
 *
 * To run a mutation, you first call `useUpdateEstablishmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstablishmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstablishmentMutation, { data, loading, error }] = useUpdateEstablishmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      address_street: // value for 'address_street'
 *      address_postcode: // value for 'address_postcode'
 *      address_city: // value for 'address_city'
 *      name: // value for 'name'
 *      phone_number: // value for 'phone_number'
 *      photo_url: // value for 'photo_url'
 *      website: // value for 'website'
 *      email: // value for 'email'
 *      facebook: // value for 'facebook'
 *      instagram: // value for 'instagram'
 *      geo_lat: // value for 'geo_lat'
 *      geo_lng: // value for 'geo_lng'
 *   },
 * });
 */
export function useUpdateEstablishmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEstablishmentMutation, UpdateEstablishmentMutationVariables>) {
        return Apollo.useMutation<UpdateEstablishmentMutation, UpdateEstablishmentMutationVariables>(UpdateEstablishmentDocument, baseOptions);
      }
export type UpdateEstablishmentMutationHookResult = ReturnType<typeof useUpdateEstablishmentMutation>;
export type UpdateEstablishmentMutationResult = Apollo.MutationResult<UpdateEstablishmentMutation>;
export type UpdateEstablishmentMutationOptions = Apollo.BaseMutationOptions<UpdateEstablishmentMutation, UpdateEstablishmentMutationVariables>;
export const InsertEstablishmentDocument = gql`
    mutation InsertEstablishment($address_street: String, $address_postcode: String, $address_city: String, $name: String, $phone_number: String, $photo_url: String, $website: String, $email: String, $facebook: String, $instagram: String, $geo_lat: numeric, $geo_lng: numeric) {
  insert_establishments_one(object: {address_street: $address_street, address_postcode: $address_postcode, address_city: $address_city, name: $name, phone_number: $phone_number, photo_url: $photo_url, website: $website, email: $email, facebook: $facebook, instagram: $instagram, geo_lat: $geo_lat, geo_lng: $geo_lng}) {
    id
    name
  }
}
    `;
export type InsertEstablishmentMutationFn = Apollo.MutationFunction<InsertEstablishmentMutation, InsertEstablishmentMutationVariables>;

/**
 * __useInsertEstablishmentMutation__
 *
 * To run a mutation, you first call `useInsertEstablishmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertEstablishmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertEstablishmentMutation, { data, loading, error }] = useInsertEstablishmentMutation({
 *   variables: {
 *      address_street: // value for 'address_street'
 *      address_postcode: // value for 'address_postcode'
 *      address_city: // value for 'address_city'
 *      name: // value for 'name'
 *      phone_number: // value for 'phone_number'
 *      photo_url: // value for 'photo_url'
 *      website: // value for 'website'
 *      email: // value for 'email'
 *      facebook: // value for 'facebook'
 *      instagram: // value for 'instagram'
 *      geo_lat: // value for 'geo_lat'
 *      geo_lng: // value for 'geo_lng'
 *   },
 * });
 */
export function useInsertEstablishmentMutation(baseOptions?: Apollo.MutationHookOptions<InsertEstablishmentMutation, InsertEstablishmentMutationVariables>) {
        return Apollo.useMutation<InsertEstablishmentMutation, InsertEstablishmentMutationVariables>(InsertEstablishmentDocument, baseOptions);
      }
export type InsertEstablishmentMutationHookResult = ReturnType<typeof useInsertEstablishmentMutation>;
export type InsertEstablishmentMutationResult = Apollo.MutationResult<InsertEstablishmentMutation>;
export type InsertEstablishmentMutationOptions = Apollo.BaseMutationOptions<InsertEstablishmentMutation, InsertEstablishmentMutationVariables>;
export const DeleteEstablishmentDocument = gql`
    mutation DeleteEstablishment($id: uuid!) {
  delete_establishments_by_pk(id: $id) {
    id
    name
  }
}
    `;
export type DeleteEstablishmentMutationFn = Apollo.MutationFunction<DeleteEstablishmentMutation, DeleteEstablishmentMutationVariables>;

/**
 * __useDeleteEstablishmentMutation__
 *
 * To run a mutation, you first call `useDeleteEstablishmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEstablishmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEstablishmentMutation, { data, loading, error }] = useDeleteEstablishmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEstablishmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEstablishmentMutation, DeleteEstablishmentMutationVariables>) {
        return Apollo.useMutation<DeleteEstablishmentMutation, DeleteEstablishmentMutationVariables>(DeleteEstablishmentDocument, baseOptions);
      }
export type DeleteEstablishmentMutationHookResult = ReturnType<typeof useDeleteEstablishmentMutation>;
export type DeleteEstablishmentMutationResult = Apollo.MutationResult<DeleteEstablishmentMutation>;
export type DeleteEstablishmentMutationOptions = Apollo.BaseMutationOptions<DeleteEstablishmentMutation, DeleteEstablishmentMutationVariables>;
export const UpdateEstablishmentPhoneNumbersDocument = gql`
    mutation UpdateEstablishmentPhoneNumbers($establishment_id: uuid!, $objects: [phone_numbers_establishments_insert_input!]!) {
  delete_phone_numbers_establishments(where: {establishment_id: {_eq: $establishment_id}}) {
    affected_rows
  }
  insert_phone_numbers_establishments(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateEstablishmentPhoneNumbersMutationFn = Apollo.MutationFunction<UpdateEstablishmentPhoneNumbersMutation, UpdateEstablishmentPhoneNumbersMutationVariables>;

/**
 * __useUpdateEstablishmentPhoneNumbersMutation__
 *
 * To run a mutation, you first call `useUpdateEstablishmentPhoneNumbersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstablishmentPhoneNumbersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstablishmentPhoneNumbersMutation, { data, loading, error }] = useUpdateEstablishmentPhoneNumbersMutation({
 *   variables: {
 *      establishment_id: // value for 'establishment_id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateEstablishmentPhoneNumbersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEstablishmentPhoneNumbersMutation, UpdateEstablishmentPhoneNumbersMutationVariables>) {
        return Apollo.useMutation<UpdateEstablishmentPhoneNumbersMutation, UpdateEstablishmentPhoneNumbersMutationVariables>(UpdateEstablishmentPhoneNumbersDocument, baseOptions);
      }
export type UpdateEstablishmentPhoneNumbersMutationHookResult = ReturnType<typeof useUpdateEstablishmentPhoneNumbersMutation>;
export type UpdateEstablishmentPhoneNumbersMutationResult = Apollo.MutationResult<UpdateEstablishmentPhoneNumbersMutation>;
export type UpdateEstablishmentPhoneNumbersMutationOptions = Apollo.BaseMutationOptions<UpdateEstablishmentPhoneNumbersMutation, UpdateEstablishmentPhoneNumbersMutationVariables>;
export const UpdateEstablishmentUsersDocument = gql`
    mutation UpdateEstablishmentUsers($establishment_id: uuid!, $objects: [establishments_users_insert_input!]!) {
  delete_establishments_users(where: {establishment_id: {_eq: $establishment_id}}) {
    affected_rows
  }
  insert_establishments_users(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateEstablishmentUsersMutationFn = Apollo.MutationFunction<UpdateEstablishmentUsersMutation, UpdateEstablishmentUsersMutationVariables>;

/**
 * __useUpdateEstablishmentUsersMutation__
 *
 * To run a mutation, you first call `useUpdateEstablishmentUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstablishmentUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstablishmentUsersMutation, { data, loading, error }] = useUpdateEstablishmentUsersMutation({
 *   variables: {
 *      establishment_id: // value for 'establishment_id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateEstablishmentUsersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEstablishmentUsersMutation, UpdateEstablishmentUsersMutationVariables>) {
        return Apollo.useMutation<UpdateEstablishmentUsersMutation, UpdateEstablishmentUsersMutationVariables>(UpdateEstablishmentUsersDocument, baseOptions);
      }
export type UpdateEstablishmentUsersMutationHookResult = ReturnType<typeof useUpdateEstablishmentUsersMutation>;
export type UpdateEstablishmentUsersMutationResult = Apollo.MutationResult<UpdateEstablishmentUsersMutation>;
export type UpdateEstablishmentUsersMutationOptions = Apollo.BaseMutationOptions<UpdateEstablishmentUsersMutation, UpdateEstablishmentUsersMutationVariables>;
export const GetEstablishmentsUserDocument = gql`
    query GetEstablishmentsUser {
  establishments_users {
    user {
      id
      email
      first_name
      last_name
      photo_url
    }
    establishment {
      id
      name
    }
  }
}
    `;

/**
 * __useGetEstablishmentsUserQuery__
 *
 * To run a query within a React component, call `useGetEstablishmentsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEstablishmentsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEstablishmentsUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEstablishmentsUserQuery(baseOptions?: Apollo.QueryHookOptions<GetEstablishmentsUserQuery, GetEstablishmentsUserQueryVariables>) {
        return Apollo.useQuery<GetEstablishmentsUserQuery, GetEstablishmentsUserQueryVariables>(GetEstablishmentsUserDocument, baseOptions);
      }
export function useGetEstablishmentsUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEstablishmentsUserQuery, GetEstablishmentsUserQueryVariables>) {
          return Apollo.useLazyQuery<GetEstablishmentsUserQuery, GetEstablishmentsUserQueryVariables>(GetEstablishmentsUserDocument, baseOptions);
        }
export type GetEstablishmentsUserQueryHookResult = ReturnType<typeof useGetEstablishmentsUserQuery>;
export type GetEstablishmentsUserLazyQueryHookResult = ReturnType<typeof useGetEstablishmentsUserLazyQuery>;
export type GetEstablishmentsUserQueryResult = Apollo.QueryResult<GetEstablishmentsUserQuery, GetEstablishmentsUserQueryVariables>;
export const GetPaymentsDocument = gql`
    query GetPayments($limit: Int, $offset: Int) {
  info: payments_aggregate(where: {cancelled: {_eq: false}}) {
    aggregate {
      totalCount: count
    }
  }
  rows: payments(limit: $limit, offset: $offset, where: {cancelled: {_eq: false}}, order_by: {date_created: desc}) {
    id
    amount
    currency_code
    date_created
    date_paid
    description
    paid
    key
    quick_code_expires
    quick_code
    cancelled
  }
}
    `;

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
        return Apollo.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, baseOptions);
      }
export function useGetPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
          return Apollo.useLazyQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, baseOptions);
        }
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<GetPaymentsQuery, GetPaymentsQueryVariables>;
export const CreatePaymentDocument = gql`
    mutation CreatePayment($amount: numeric!, $currency_code: String!, $description: String!) {
  create_payment(amount: $amount, currency_code: $currency_code, description: $description) {
    id
    payment {
      key
      quick_code
      quick_code_expires
      amount
    }
  }
}
    `;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      currency_code: // value for 'currency_code'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, baseOptions);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const SendPaymentDocument = gql`
    mutation SendPayment($payment_id: uuid!, $user_contact: String!) {
  send_payment(user_contact: $user_contact, payment_id: $payment_id) {
    success
  }
}
    `;
export type SendPaymentMutationFn = Apollo.MutationFunction<SendPaymentMutation, SendPaymentMutationVariables>;

/**
 * __useSendPaymentMutation__
 *
 * To run a mutation, you first call `useSendPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPaymentMutation, { data, loading, error }] = useSendPaymentMutation({
 *   variables: {
 *      payment_id: // value for 'payment_id'
 *      user_contact: // value for 'user_contact'
 *   },
 * });
 */
export function useSendPaymentMutation(baseOptions?: Apollo.MutationHookOptions<SendPaymentMutation, SendPaymentMutationVariables>) {
        return Apollo.useMutation<SendPaymentMutation, SendPaymentMutationVariables>(SendPaymentDocument, baseOptions);
      }
export type SendPaymentMutationHookResult = ReturnType<typeof useSendPaymentMutation>;
export type SendPaymentMutationResult = Apollo.MutationResult<SendPaymentMutation>;
export type SendPaymentMutationOptions = Apollo.BaseMutationOptions<SendPaymentMutation, SendPaymentMutationVariables>;
export const CancelPaymentDocument = gql`
    mutation CancelPayment($id: uuid!, $cancelled: Boolean!) {
  update_payments_by_pk(pk_columns: {id: $id}, _set: {cancelled: $cancelled}) {
    cancelled
    id
  }
}
    `;
export type CancelPaymentMutationFn = Apollo.MutationFunction<CancelPaymentMutation, CancelPaymentMutationVariables>;

/**
 * __useCancelPaymentMutation__
 *
 * To run a mutation, you first call `useCancelPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaymentMutation, { data, loading, error }] = useCancelPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cancelled: // value for 'cancelled'
 *   },
 * });
 */
export function useCancelPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CancelPaymentMutation, CancelPaymentMutationVariables>) {
        return Apollo.useMutation<CancelPaymentMutation, CancelPaymentMutationVariables>(CancelPaymentDocument, baseOptions);
      }
export type CancelPaymentMutationHookResult = ReturnType<typeof useCancelPaymentMutation>;
export type CancelPaymentMutationResult = Apollo.MutationResult<CancelPaymentMutation>;
export type CancelPaymentMutationOptions = Apollo.BaseMutationOptions<CancelPaymentMutation, CancelPaymentMutationVariables>;
export const RenewPaymentDocument = gql`
    mutation RenewPayment($payment_id: uuid!) {
  renew_payment_code(payment_id: $payment_id) {
    quick_code
    quick_code_expires
  }
}
    `;
export type RenewPaymentMutationFn = Apollo.MutationFunction<RenewPaymentMutation, RenewPaymentMutationVariables>;

/**
 * __useRenewPaymentMutation__
 *
 * To run a mutation, you first call `useRenewPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewPaymentMutation, { data, loading, error }] = useRenewPaymentMutation({
 *   variables: {
 *      payment_id: // value for 'payment_id'
 *   },
 * });
 */
export function useRenewPaymentMutation(baseOptions?: Apollo.MutationHookOptions<RenewPaymentMutation, RenewPaymentMutationVariables>) {
        return Apollo.useMutation<RenewPaymentMutation, RenewPaymentMutationVariables>(RenewPaymentDocument, baseOptions);
      }
export type RenewPaymentMutationHookResult = ReturnType<typeof useRenewPaymentMutation>;
export type RenewPaymentMutationResult = Apollo.MutationResult<RenewPaymentMutation>;
export type RenewPaymentMutationOptions = Apollo.BaseMutationOptions<RenewPaymentMutation, RenewPaymentMutationVariables>;
export const GetPhoneNumbersDocument = gql`
    query GetPhoneNumbers {
  phone_numbers(order_by: {name: asc}) {
    name
    id
    phone_number
    name
    active
    app_connection_active
  }
}
    `;

/**
 * __useGetPhoneNumbersQuery__
 *
 * To run a query within a React component, call `useGetPhoneNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhoneNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhoneNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPhoneNumbersQuery(baseOptions?: Apollo.QueryHookOptions<GetPhoneNumbersQuery, GetPhoneNumbersQueryVariables>) {
        return Apollo.useQuery<GetPhoneNumbersQuery, GetPhoneNumbersQueryVariables>(GetPhoneNumbersDocument, baseOptions);
      }
export function useGetPhoneNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhoneNumbersQuery, GetPhoneNumbersQueryVariables>) {
          return Apollo.useLazyQuery<GetPhoneNumbersQuery, GetPhoneNumbersQueryVariables>(GetPhoneNumbersDocument, baseOptions);
        }
export type GetPhoneNumbersQueryHookResult = ReturnType<typeof useGetPhoneNumbersQuery>;
export type GetPhoneNumbersLazyQueryHookResult = ReturnType<typeof useGetPhoneNumbersLazyQuery>;
export type GetPhoneNumbersQueryResult = Apollo.QueryResult<GetPhoneNumbersQuery, GetPhoneNumbersQueryVariables>;
export const InsertPhoneNumberDocument = gql`
    mutation InsertPhoneNumber($active: Boolean, $name: String, $phone_number: String) {
  insert_phone_numbers_one(object: {active: $active, name: $name, phone_number: $phone_number}) {
    id
    name
    phone_number
  }
}
    `;
export type InsertPhoneNumberMutationFn = Apollo.MutationFunction<InsertPhoneNumberMutation, InsertPhoneNumberMutationVariables>;

/**
 * __useInsertPhoneNumberMutation__
 *
 * To run a mutation, you first call `useInsertPhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPhoneNumberMutation, { data, loading, error }] = useInsertPhoneNumberMutation({
 *   variables: {
 *      active: // value for 'active'
 *      name: // value for 'name'
 *      phone_number: // value for 'phone_number'
 *   },
 * });
 */
export function useInsertPhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<InsertPhoneNumberMutation, InsertPhoneNumberMutationVariables>) {
        return Apollo.useMutation<InsertPhoneNumberMutation, InsertPhoneNumberMutationVariables>(InsertPhoneNumberDocument, baseOptions);
      }
export type InsertPhoneNumberMutationHookResult = ReturnType<typeof useInsertPhoneNumberMutation>;
export type InsertPhoneNumberMutationResult = Apollo.MutationResult<InsertPhoneNumberMutation>;
export type InsertPhoneNumberMutationOptions = Apollo.BaseMutationOptions<InsertPhoneNumberMutation, InsertPhoneNumberMutationVariables>;
export const UpdatePhoneNumberDocument = gql`
    mutation UpdatePhoneNumber($id: uuid!, $name: String, $phone_number: String, $active: Boolean) {
  update_phone_numbers_by_pk(pk_columns: {id: $id}, _set: {active: $active, name: $name, phone_number: $phone_number}) {
    id
    name
    phone_number
  }
}
    `;
export type UpdatePhoneNumberMutationFn = Apollo.MutationFunction<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>;

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      phone_number: // value for 'phone_number'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>) {
        return Apollo.useMutation<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>(UpdatePhoneNumberDocument, baseOptions);
      }
export type UpdatePhoneNumberMutationHookResult = ReturnType<typeof useUpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationResult = Apollo.MutationResult<UpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<UpdatePhoneNumberMutation, UpdatePhoneNumberMutationVariables>;
export const DeletePhoneNumberDocument = gql`
    mutation DeletePhoneNumber($id: uuid!) {
  delete_phone_numbers_by_pk(id: $id) {
    id
  }
}
    `;
export type DeletePhoneNumberMutationFn = Apollo.MutationFunction<DeletePhoneNumberMutation, DeletePhoneNumberMutationVariables>;

/**
 * __useDeletePhoneNumberMutation__
 *
 * To run a mutation, you first call `useDeletePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneNumberMutation, { data, loading, error }] = useDeletePhoneNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhoneNumberMutation, DeletePhoneNumberMutationVariables>) {
        return Apollo.useMutation<DeletePhoneNumberMutation, DeletePhoneNumberMutationVariables>(DeletePhoneNumberDocument, baseOptions);
      }
export type DeletePhoneNumberMutationHookResult = ReturnType<typeof useDeletePhoneNumberMutation>;
export type DeletePhoneNumberMutationResult = Apollo.MutationResult<DeletePhoneNumberMutation>;
export type DeletePhoneNumberMutationOptions = Apollo.BaseMutationOptions<DeletePhoneNumberMutation, DeletePhoneNumberMutationVariables>;
export const GetPhoneNumbersUsersDocument = gql`
    query GetPhoneNumbersUsers {
  phone_numbers_users {
    user {
      id
      email
      first_name
      last_name
    }
    phone_number {
      id
      name
      phone_number
      active
    }
  }
}
    `;

/**
 * __useGetPhoneNumbersUsersQuery__
 *
 * To run a query within a React component, call `useGetPhoneNumbersUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhoneNumbersUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhoneNumbersUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPhoneNumbersUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetPhoneNumbersUsersQuery, GetPhoneNumbersUsersQueryVariables>) {
        return Apollo.useQuery<GetPhoneNumbersUsersQuery, GetPhoneNumbersUsersQueryVariables>(GetPhoneNumbersUsersDocument, baseOptions);
      }
export function useGetPhoneNumbersUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhoneNumbersUsersQuery, GetPhoneNumbersUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetPhoneNumbersUsersQuery, GetPhoneNumbersUsersQueryVariables>(GetPhoneNumbersUsersDocument, baseOptions);
        }
export type GetPhoneNumbersUsersQueryHookResult = ReturnType<typeof useGetPhoneNumbersUsersQuery>;
export type GetPhoneNumbersUsersLazyQueryHookResult = ReturnType<typeof useGetPhoneNumbersUsersLazyQuery>;
export type GetPhoneNumbersUsersQueryResult = Apollo.QueryResult<GetPhoneNumbersUsersQuery, GetPhoneNumbersUsersQueryVariables>;
export const GetPhoneNumbersEstablishmentsDocument = gql`
    query GetPhoneNumbersEstablishments {
  phone_numbers_establishments {
    establishment {
      id
      name
      photo_url
    }
    phone_number {
      id
      name
      phone_number
      active
    }
  }
}
    `;

/**
 * __useGetPhoneNumbersEstablishmentsQuery__
 *
 * To run a query within a React component, call `useGetPhoneNumbersEstablishmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhoneNumbersEstablishmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhoneNumbersEstablishmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPhoneNumbersEstablishmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetPhoneNumbersEstablishmentsQuery, GetPhoneNumbersEstablishmentsQueryVariables>) {
        return Apollo.useQuery<GetPhoneNumbersEstablishmentsQuery, GetPhoneNumbersEstablishmentsQueryVariables>(GetPhoneNumbersEstablishmentsDocument, baseOptions);
      }
export function useGetPhoneNumbersEstablishmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhoneNumbersEstablishmentsQuery, GetPhoneNumbersEstablishmentsQueryVariables>) {
          return Apollo.useLazyQuery<GetPhoneNumbersEstablishmentsQuery, GetPhoneNumbersEstablishmentsQueryVariables>(GetPhoneNumbersEstablishmentsDocument, baseOptions);
        }
export type GetPhoneNumbersEstablishmentsQueryHookResult = ReturnType<typeof useGetPhoneNumbersEstablishmentsQuery>;
export type GetPhoneNumbersEstablishmentsLazyQueryHookResult = ReturnType<typeof useGetPhoneNumbersEstablishmentsLazyQuery>;
export type GetPhoneNumbersEstablishmentsQueryResult = Apollo.QueryResult<GetPhoneNumbersEstablishmentsQuery, GetPhoneNumbersEstablishmentsQueryVariables>;
export const UpdatePhoneNumberEstablishmentsDocument = gql`
    mutation UpdatePhoneNumberEstablishments($phone_number_id: uuid!, $objects: [phone_numbers_establishments_insert_input!]!) {
  delete_phone_numbers_establishments(where: {phone_number_id: {_eq: $phone_number_id}}) {
    affected_rows
  }
  insert_phone_numbers_establishments(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdatePhoneNumberEstablishmentsMutationFn = Apollo.MutationFunction<UpdatePhoneNumberEstablishmentsMutation, UpdatePhoneNumberEstablishmentsMutationVariables>;

/**
 * __useUpdatePhoneNumberEstablishmentsMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberEstablishmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberEstablishmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberEstablishmentsMutation, { data, loading, error }] = useUpdatePhoneNumberEstablishmentsMutation({
 *   variables: {
 *      phone_number_id: // value for 'phone_number_id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdatePhoneNumberEstablishmentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhoneNumberEstablishmentsMutation, UpdatePhoneNumberEstablishmentsMutationVariables>) {
        return Apollo.useMutation<UpdatePhoneNumberEstablishmentsMutation, UpdatePhoneNumberEstablishmentsMutationVariables>(UpdatePhoneNumberEstablishmentsDocument, baseOptions);
      }
export type UpdatePhoneNumberEstablishmentsMutationHookResult = ReturnType<typeof useUpdatePhoneNumberEstablishmentsMutation>;
export type UpdatePhoneNumberEstablishmentsMutationResult = Apollo.MutationResult<UpdatePhoneNumberEstablishmentsMutation>;
export type UpdatePhoneNumberEstablishmentsMutationOptions = Apollo.BaseMutationOptions<UpdatePhoneNumberEstablishmentsMutation, UpdatePhoneNumberEstablishmentsMutationVariables>;
export const UpdatePhoneNumberUsersDocument = gql`
    mutation UpdatePhoneNumberUsers($phone_number_id: uuid!, $objects: [phone_numbers_users_insert_input!]!) {
  delete_phone_numbers_users(where: {phone_number_id: {_eq: $phone_number_id}}) {
    affected_rows
  }
  insert_phone_numbers_users(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdatePhoneNumberUsersMutationFn = Apollo.MutationFunction<UpdatePhoneNumberUsersMutation, UpdatePhoneNumberUsersMutationVariables>;

/**
 * __useUpdatePhoneNumberUsersMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberUsersMutation, { data, loading, error }] = useUpdatePhoneNumberUsersMutation({
 *   variables: {
 *      phone_number_id: // value for 'phone_number_id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdatePhoneNumberUsersMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhoneNumberUsersMutation, UpdatePhoneNumberUsersMutationVariables>) {
        return Apollo.useMutation<UpdatePhoneNumberUsersMutation, UpdatePhoneNumberUsersMutationVariables>(UpdatePhoneNumberUsersDocument, baseOptions);
      }
export type UpdatePhoneNumberUsersMutationHookResult = ReturnType<typeof useUpdatePhoneNumberUsersMutation>;
export type UpdatePhoneNumberUsersMutationResult = Apollo.MutationResult<UpdatePhoneNumberUsersMutation>;
export type UpdatePhoneNumberUsersMutationOptions = Apollo.BaseMutationOptions<UpdatePhoneNumberUsersMutation, UpdatePhoneNumberUsersMutationVariables>;
export const GetReservioIntegrationDocument = gql`
    query GetReservioIntegration {
  reservio_integration {
    reservio_email
    reservio_user_id
    last_fetch
    reminder_interval_before
    sms_template_accepted {
      id
      text
    }
    sms_template_confirmed {
      id
      text
    }
    sms_template_cancelled {
      id
      text
    }
    sms_template_rejected {
      id
      text
    }
    sms_template_moved {
      id
      text
    }
    sms_template_reminder {
      id
      text
    }
  }
}
    `;

/**
 * __useGetReservioIntegrationQuery__
 *
 * To run a query within a React component, call `useGetReservioIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservioIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservioIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReservioIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<GetReservioIntegrationQuery, GetReservioIntegrationQueryVariables>) {
        return Apollo.useQuery<GetReservioIntegrationQuery, GetReservioIntegrationQueryVariables>(GetReservioIntegrationDocument, baseOptions);
      }
export function useGetReservioIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservioIntegrationQuery, GetReservioIntegrationQueryVariables>) {
          return Apollo.useLazyQuery<GetReservioIntegrationQuery, GetReservioIntegrationQueryVariables>(GetReservioIntegrationDocument, baseOptions);
        }
export type GetReservioIntegrationQueryHookResult = ReturnType<typeof useGetReservioIntegrationQuery>;
export type GetReservioIntegrationLazyQueryHookResult = ReturnType<typeof useGetReservioIntegrationLazyQuery>;
export type GetReservioIntegrationQueryResult = Apollo.QueryResult<GetReservioIntegrationQuery, GetReservioIntegrationQueryVariables>;
export const UpsertSmsTemplatesDocument = gql`
    mutation UpsertSmsTemplates($objects: [smsapi_templates_insert_input!]!) {
  insert_smsapi_templates(objects: $objects, on_conflict: {update_columns: text, constraint: smsapi_templates_company_id_special_type_name_key}) {
    returning {
      text
      id
      special_type
    }
  }
}
    `;
export type UpsertSmsTemplatesMutationFn = Apollo.MutationFunction<UpsertSmsTemplatesMutation, UpsertSmsTemplatesMutationVariables>;

/**
 * __useUpsertSmsTemplatesMutation__
 *
 * To run a mutation, you first call `useUpsertSmsTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSmsTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSmsTemplatesMutation, { data, loading, error }] = useUpsertSmsTemplatesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpsertSmsTemplatesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertSmsTemplatesMutation, UpsertSmsTemplatesMutationVariables>) {
        return Apollo.useMutation<UpsertSmsTemplatesMutation, UpsertSmsTemplatesMutationVariables>(UpsertSmsTemplatesDocument, baseOptions);
      }
export type UpsertSmsTemplatesMutationHookResult = ReturnType<typeof useUpsertSmsTemplatesMutation>;
export type UpsertSmsTemplatesMutationResult = Apollo.MutationResult<UpsertSmsTemplatesMutation>;
export type UpsertSmsTemplatesMutationOptions = Apollo.BaseMutationOptions<UpsertSmsTemplatesMutation, UpsertSmsTemplatesMutationVariables>;
export const UpdateReservioIntegrationDocument = gql`
    mutation UpdateReservioIntegration($values: reservio_integration_set_input!) {
  update_reservio_integration(where: {}, _set: $values) {
    affected_rows
  }
}
    `;
export type UpdateReservioIntegrationMutationFn = Apollo.MutationFunction<UpdateReservioIntegrationMutation, UpdateReservioIntegrationMutationVariables>;

/**
 * __useUpdateReservioIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateReservioIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservioIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservioIntegrationMutation, { data, loading, error }] = useUpdateReservioIntegrationMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateReservioIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReservioIntegrationMutation, UpdateReservioIntegrationMutationVariables>) {
        return Apollo.useMutation<UpdateReservioIntegrationMutation, UpdateReservioIntegrationMutationVariables>(UpdateReservioIntegrationDocument, baseOptions);
      }
export type UpdateReservioIntegrationMutationHookResult = ReturnType<typeof useUpdateReservioIntegrationMutation>;
export type UpdateReservioIntegrationMutationResult = Apollo.MutationResult<UpdateReservioIntegrationMutation>;
export type UpdateReservioIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateReservioIntegrationMutation, UpdateReservioIntegrationMutationVariables>;
export const CreateReservioUserDocument = gql`
    mutation CreateReservioUser($user: CreateReservioUserInput!) {
  create_reservio_user(user: $user) {
    success
  }
}
    `;
export type CreateReservioUserMutationFn = Apollo.MutationFunction<CreateReservioUserMutation, CreateReservioUserMutationVariables>;

/**
 * __useCreateReservioUserMutation__
 *
 * To run a mutation, you first call `useCreateReservioUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservioUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservioUserMutation, { data, loading, error }] = useCreateReservioUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateReservioUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateReservioUserMutation, CreateReservioUserMutationVariables>) {
        return Apollo.useMutation<CreateReservioUserMutation, CreateReservioUserMutationVariables>(CreateReservioUserDocument, baseOptions);
      }
export type CreateReservioUserMutationHookResult = ReturnType<typeof useCreateReservioUserMutation>;
export type CreateReservioUserMutationResult = Apollo.MutationResult<CreateReservioUserMutation>;
export type CreateReservioUserMutationOptions = Apollo.BaseMutationOptions<CreateReservioUserMutation, CreateReservioUserMutationVariables>;
export const GetRolesDocument = gql`
    query GetRoles {
  roles(where: {hidden: {_eq: false}}) {
    company_id
    name
    can_manage_users
    can_manage_company
    can_access_centrumsms
    id
    hidden
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, baseOptions);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, baseOptions);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($r: uuid, $id: uuid!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {role_id: $r}) {
    first_name
    role_id
  }
}
    `;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      r: // value for 'r'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        return Apollo.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, baseOptions);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const InsertRoleDocument = gql`
    mutation InsertRole($object: roles_insert_input!) {
  insert_roles_one(object: $object) {
    id
    name
  }
}
    `;
export type InsertRoleMutationFn = Apollo.MutationFunction<InsertRoleMutation, InsertRoleMutationVariables>;

/**
 * __useInsertRoleMutation__
 *
 * To run a mutation, you first call `useInsertRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertRoleMutation, { data, loading, error }] = useInsertRoleMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useInsertRoleMutation(baseOptions?: Apollo.MutationHookOptions<InsertRoleMutation, InsertRoleMutationVariables>) {
        return Apollo.useMutation<InsertRoleMutation, InsertRoleMutationVariables>(InsertRoleDocument, baseOptions);
      }
export type InsertRoleMutationHookResult = ReturnType<typeof useInsertRoleMutation>;
export type InsertRoleMutationResult = Apollo.MutationResult<InsertRoleMutation>;
export type InsertRoleMutationOptions = Apollo.BaseMutationOptions<InsertRoleMutation, InsertRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: uuid!) {
  delete_roles_by_pk(id: $id) {
    id
    name
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, baseOptions);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const GetShoptetEshopsDocument = gql`
    query GetShoptetEshops {
  shoptet_eshops {
    eshop_name
  }
}
    `;

/**
 * __useGetShoptetEshopsQuery__
 *
 * To run a query within a React component, call `useGetShoptetEshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShoptetEshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShoptetEshopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShoptetEshopsQuery(baseOptions?: Apollo.QueryHookOptions<GetShoptetEshopsQuery, GetShoptetEshopsQueryVariables>) {
        return Apollo.useQuery<GetShoptetEshopsQuery, GetShoptetEshopsQueryVariables>(GetShoptetEshopsDocument, baseOptions);
      }
export function useGetShoptetEshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShoptetEshopsQuery, GetShoptetEshopsQueryVariables>) {
          return Apollo.useLazyQuery<GetShoptetEshopsQuery, GetShoptetEshopsQueryVariables>(GetShoptetEshopsDocument, baseOptions);
        }
export type GetShoptetEshopsQueryHookResult = ReturnType<typeof useGetShoptetEshopsQuery>;
export type GetShoptetEshopsLazyQueryHookResult = ReturnType<typeof useGetShoptetEshopsLazyQuery>;
export type GetShoptetEshopsQueryResult = Apollo.QueryResult<GetShoptetEshopsQuery, GetShoptetEshopsQueryVariables>;
export const GetCompanyDicDocument = gql`
    query GetCompanyDic {
  companies {
    id
    dic
  }
}
    `;

/**
 * __useGetCompanyDicQuery__
 *
 * To run a query within a React component, call `useGetCompanyDicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDicQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyDicQuery(baseOptions?: Apollo.QueryHookOptions<GetCompanyDicQuery, GetCompanyDicQueryVariables>) {
        return Apollo.useQuery<GetCompanyDicQuery, GetCompanyDicQueryVariables>(GetCompanyDicDocument, baseOptions);
      }
export function useGetCompanyDicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyDicQuery, GetCompanyDicQueryVariables>) {
          return Apollo.useLazyQuery<GetCompanyDicQuery, GetCompanyDicQueryVariables>(GetCompanyDicDocument, baseOptions);
        }
export type GetCompanyDicQueryHookResult = ReturnType<typeof useGetCompanyDicQuery>;
export type GetCompanyDicLazyQueryHookResult = ReturnType<typeof useGetCompanyDicLazyQuery>;
export type GetCompanyDicQueryResult = Apollo.QueryResult<GetCompanyDicQuery, GetCompanyDicQueryVariables>;
export const GetShoptetSmsTemplatesDocument = gql`
    query GetShoptetSmsTemplates {
  smsapi_templates(where: {special_type: {_eq: "shoptet"}}) {
    id
    text
    name
    company_id
    condition
  }
}
    `;

/**
 * __useGetShoptetSmsTemplatesQuery__
 *
 * To run a query within a React component, call `useGetShoptetSmsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShoptetSmsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShoptetSmsTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShoptetSmsTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetShoptetSmsTemplatesQuery, GetShoptetSmsTemplatesQueryVariables>) {
        return Apollo.useQuery<GetShoptetSmsTemplatesQuery, GetShoptetSmsTemplatesQueryVariables>(GetShoptetSmsTemplatesDocument, baseOptions);
      }
export function useGetShoptetSmsTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShoptetSmsTemplatesQuery, GetShoptetSmsTemplatesQueryVariables>) {
          return Apollo.useLazyQuery<GetShoptetSmsTemplatesQuery, GetShoptetSmsTemplatesQueryVariables>(GetShoptetSmsTemplatesDocument, baseOptions);
        }
export type GetShoptetSmsTemplatesQueryHookResult = ReturnType<typeof useGetShoptetSmsTemplatesQuery>;
export type GetShoptetSmsTemplatesLazyQueryHookResult = ReturnType<typeof useGetShoptetSmsTemplatesLazyQuery>;
export type GetShoptetSmsTemplatesQueryResult = Apollo.QueryResult<GetShoptetSmsTemplatesQuery, GetShoptetSmsTemplatesQueryVariables>;
export const UpdateShoptetEshopsDicDocument = gql`
    mutation UpdateShoptetEshopsDic($dic: String!, $company_id: uuid!) {
  update_shoptet_eshops(where: {dic: {_eq: $dic}}, _set: {company_id: $company_id}) {
    returning {
      company_id
    }
  }
}
    `;
export type UpdateShoptetEshopsDicMutationFn = Apollo.MutationFunction<UpdateShoptetEshopsDicMutation, UpdateShoptetEshopsDicMutationVariables>;

/**
 * __useUpdateShoptetEshopsDicMutation__
 *
 * To run a mutation, you first call `useUpdateShoptetEshopsDicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShoptetEshopsDicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShoptetEshopsDicMutation, { data, loading, error }] = useUpdateShoptetEshopsDicMutation({
 *   variables: {
 *      dic: // value for 'dic'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useUpdateShoptetEshopsDicMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShoptetEshopsDicMutation, UpdateShoptetEshopsDicMutationVariables>) {
        return Apollo.useMutation<UpdateShoptetEshopsDicMutation, UpdateShoptetEshopsDicMutationVariables>(UpdateShoptetEshopsDicDocument, baseOptions);
      }
export type UpdateShoptetEshopsDicMutationHookResult = ReturnType<typeof useUpdateShoptetEshopsDicMutation>;
export type UpdateShoptetEshopsDicMutationResult = Apollo.MutationResult<UpdateShoptetEshopsDicMutation>;
export type UpdateShoptetEshopsDicMutationOptions = Apollo.BaseMutationOptions<UpdateShoptetEshopsDicMutation, UpdateShoptetEshopsDicMutationVariables>;
export const ShoptetImportContactsDocument = gql`
    mutation ShoptetImportContacts {
  shoptet_import_contacts {
    success
  }
}
    `;
export type ShoptetImportContactsMutationFn = Apollo.MutationFunction<ShoptetImportContactsMutation, ShoptetImportContactsMutationVariables>;

/**
 * __useShoptetImportContactsMutation__
 *
 * To run a mutation, you first call `useShoptetImportContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShoptetImportContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shoptetImportContactsMutation, { data, loading, error }] = useShoptetImportContactsMutation({
 *   variables: {
 *   },
 * });
 */
export function useShoptetImportContactsMutation(baseOptions?: Apollo.MutationHookOptions<ShoptetImportContactsMutation, ShoptetImportContactsMutationVariables>) {
        return Apollo.useMutation<ShoptetImportContactsMutation, ShoptetImportContactsMutationVariables>(ShoptetImportContactsDocument, baseOptions);
      }
export type ShoptetImportContactsMutationHookResult = ReturnType<typeof useShoptetImportContactsMutation>;
export type ShoptetImportContactsMutationResult = Apollo.MutationResult<ShoptetImportContactsMutation>;
export type ShoptetImportContactsMutationOptions = Apollo.BaseMutationOptions<ShoptetImportContactsMutation, ShoptetImportContactsMutationVariables>;
export const GetTerminalsDocument = gql`
    query GetTerminals {
  terminals {
    id
    api_key
    pc3000_company_id
  }
}
    `;

/**
 * __useGetTerminalsQuery__
 *
 * To run a query within a React component, call `useGetTerminalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTerminalsQuery(baseOptions?: Apollo.QueryHookOptions<GetTerminalsQuery, GetTerminalsQueryVariables>) {
        return Apollo.useQuery<GetTerminalsQuery, GetTerminalsQueryVariables>(GetTerminalsDocument, baseOptions);
      }
export function useGetTerminalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalsQuery, GetTerminalsQueryVariables>) {
          return Apollo.useLazyQuery<GetTerminalsQuery, GetTerminalsQueryVariables>(GetTerminalsDocument, baseOptions);
        }
export type GetTerminalsQueryHookResult = ReturnType<typeof useGetTerminalsQuery>;
export type GetTerminalsLazyQueryHookResult = ReturnType<typeof useGetTerminalsLazyQuery>;
export type GetTerminalsQueryResult = Apollo.QueryResult<GetTerminalsQuery, GetTerminalsQueryVariables>;
export const GetTerminalTransactionsDocument = gql`
    query GetTerminalTransactions($api_key: String!, $pc3000_company_id: Int!, $offset: Int!) {
  info: terminal_transactions_count(api_key: $api_key, pc3000_company_id: $pc3000_company_id) {
    totalCount
  }
  rows: terminal_transactions(api_key: $api_key, pc3000_company_id: $pc3000_company_id, offset: $offset) {
    id
    issuerTidIdentity
    transactionType
    amount
    currencyCode
    acquirerTidIdentity
    uuid
    posId
    successful
    terminalTimestamp
    responseText
  }
}
    `;

/**
 * __useGetTerminalTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTerminalTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalTransactionsQuery({
 *   variables: {
 *      api_key: // value for 'api_key'
 *      pc3000_company_id: // value for 'pc3000_company_id'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTerminalTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetTerminalTransactionsQuery, GetTerminalTransactionsQueryVariables>) {
        return Apollo.useQuery<GetTerminalTransactionsQuery, GetTerminalTransactionsQueryVariables>(GetTerminalTransactionsDocument, baseOptions);
      }
export function useGetTerminalTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalTransactionsQuery, GetTerminalTransactionsQueryVariables>) {
          return Apollo.useLazyQuery<GetTerminalTransactionsQuery, GetTerminalTransactionsQueryVariables>(GetTerminalTransactionsDocument, baseOptions);
        }
export type GetTerminalTransactionsQueryHookResult = ReturnType<typeof useGetTerminalTransactionsQuery>;
export type GetTerminalTransactionsLazyQueryHookResult = ReturnType<typeof useGetTerminalTransactionsLazyQuery>;
export type GetTerminalTransactionsQueryResult = Apollo.QueryResult<GetTerminalTransactionsQuery, GetTerminalTransactionsQueryVariables>;
export const GetTerminalStatementsDocument = gql`
    query GetTerminalStatements($limit: Int!, $offset: Int!) {
  info: terminal_statements_aggregate {
    aggregate {
      totalCount: count
    }
  }
  rows: terminal_statements(offset: $offset, limit: $limit, order_by: {date: desc}) {
    id
    acquirer
    acquirers_id
    type
    date
    url
  }
}
    `;

/**
 * __useGetTerminalStatementsQuery__
 *
 * To run a query within a React component, call `useGetTerminalStatementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalStatementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalStatementsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTerminalStatementsQuery(baseOptions: Apollo.QueryHookOptions<GetTerminalStatementsQuery, GetTerminalStatementsQueryVariables>) {
        return Apollo.useQuery<GetTerminalStatementsQuery, GetTerminalStatementsQueryVariables>(GetTerminalStatementsDocument, baseOptions);
      }
export function useGetTerminalStatementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalStatementsQuery, GetTerminalStatementsQueryVariables>) {
          return Apollo.useLazyQuery<GetTerminalStatementsQuery, GetTerminalStatementsQueryVariables>(GetTerminalStatementsDocument, baseOptions);
        }
export type GetTerminalStatementsQueryHookResult = ReturnType<typeof useGetTerminalStatementsQuery>;
export type GetTerminalStatementsLazyQueryHookResult = ReturnType<typeof useGetTerminalStatementsLazyQuery>;
export type GetTerminalStatementsQueryResult = Apollo.QueryResult<GetTerminalStatementsQuery, GetTerminalStatementsQueryVariables>;
export const GetTransactionsDocument = gql`
    query GetTransactions($limit: Int, $offset: Int) {
  info: transactions_aggregate {
    aggregate {
      totalCount: count
      sumAmount: sum {
        amount
      }
    }
  }
  rows: transactions(offset: $offset, limit: $limit, order_by: {date_created: desc}) {
    id
    payment_id
    type
    extra_data
    date_created
    currency_code
    cancelled
    amount
  }
}
    `;

/**
 * __useGetTransactionsQuery__
 *
 * To run a query within a React component, call `useGetTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
        return Apollo.useQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, baseOptions);
      }
export function useGetTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsQuery, GetTransactionsQueryVariables>) {
          return Apollo.useLazyQuery<GetTransactionsQuery, GetTransactionsQueryVariables>(GetTransactionsDocument, baseOptions);
        }
export type GetTransactionsQueryHookResult = ReturnType<typeof useGetTransactionsQuery>;
export type GetTransactionsLazyQueryHookResult = ReturnType<typeof useGetTransactionsLazyQuery>;
export type GetTransactionsQueryResult = Apollo.QueryResult<GetTransactionsQuery, GetTransactionsQueryVariables>;
export const GetUserBasicDocument = gql`
    query GetUserBasic($id: uuid!) {
  users(where: {id: {_eq: $id}}) {
    id
    company {
      name
    }
    first_name
    last_name
    email
  }
}
    `;

/**
 * __useGetUserBasicQuery__
 *
 * To run a query within a React component, call `useGetUserBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserBasicQuery(baseOptions: Apollo.QueryHookOptions<GetUserBasicQuery, GetUserBasicQueryVariables>) {
        return Apollo.useQuery<GetUserBasicQuery, GetUserBasicQueryVariables>(GetUserBasicDocument, baseOptions);
      }
export function useGetUserBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBasicQuery, GetUserBasicQueryVariables>) {
          return Apollo.useLazyQuery<GetUserBasicQuery, GetUserBasicQueryVariables>(GetUserBasicDocument, baseOptions);
        }
export type GetUserBasicQueryHookResult = ReturnType<typeof useGetUserBasicQuery>;
export type GetUserBasicLazyQueryHookResult = ReturnType<typeof useGetUserBasicLazyQuery>;
export type GetUserBasicQueryResult = Apollo.QueryResult<GetUserBasicQuery, GetUserBasicQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users(order_by: {email: asc}) {
    company_id
    first_name
    last_name
    email
    id
    active
    role_id
    disabled
    is_public_email
    photo_url
    role {
      id
      name
      can_manage_users
    }
    company {
      name
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const InsertUserDocument = gql`
    mutation InsertUser($email: String, $first_name: String, $last_name: String, $photo_url: String, $role_id: uuid!, $disabled: Boolean, $is_public_email: Boolean) {
  insert_users_one(object: {email: $email, first_name: $first_name, last_name: $last_name, photo_url: $photo_url, role_id: $role_id, disabled: $disabled, is_public_email: $is_public_email}) {
    id
    email
  }
}
    `;
export type InsertUserMutationFn = Apollo.MutationFunction<InsertUserMutation, InsertUserMutationVariables>;

/**
 * __useInsertUserMutation__
 *
 * To run a mutation, you first call `useInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserMutation, { data, loading, error }] = useInsertUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      photo_url: // value for 'photo_url'
 *      role_id: // value for 'role_id'
 *      disabled: // value for 'disabled'
 *      is_public_email: // value for 'is_public_email'
 *   },
 * });
 */
export function useInsertUserMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserMutation, InsertUserMutationVariables>) {
        return Apollo.useMutation<InsertUserMutation, InsertUserMutationVariables>(InsertUserDocument, baseOptions);
      }
export type InsertUserMutationHookResult = ReturnType<typeof useInsertUserMutation>;
export type InsertUserMutationResult = Apollo.MutationResult<InsertUserMutation>;
export type InsertUserMutationOptions = Apollo.BaseMutationOptions<InsertUserMutation, InsertUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: uuid!, $first_name: String, $last_name: String, $role_id: uuid!, $email: String, $photo_url: String, $disabled: Boolean, $is_public_email: Boolean) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {email: $email, first_name: $first_name, last_name: $last_name, photo_url: $photo_url, role_id: $role_id, disabled: $disabled, is_public_email: $is_public_email}) {
    email
    first_name
    last_name
    photo_url
    role_id
    disabled
    is_public_email
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      role_id: // value for 'role_id'
 *      email: // value for 'email'
 *      photo_url: // value for 'photo_url'
 *      disabled: // value for 'disabled'
 *      is_public_email: // value for 'is_public_email'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: uuid!) {
  delete_phone_numbers(where: {phone_numbers_users: {user_id: {_eq: $id}}}) {
    affected_rows
  }
  delete_users_by_pk(id: $id) {
    id
  }
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, baseOptions);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const UpdateUserPhoneNumbersDocument = gql`
    mutation UpdateUserPhoneNumbers($user_id: uuid!, $objects: [phone_numbers_users_insert_input!]!) {
  delete_phone_numbers_users(where: {user_id: {_eq: $user_id}}) {
    affected_rows
  }
  insert_phone_numbers_users(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateUserPhoneNumbersMutationFn = Apollo.MutationFunction<UpdateUserPhoneNumbersMutation, UpdateUserPhoneNumbersMutationVariables>;

/**
 * __useUpdateUserPhoneNumbersMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhoneNumbersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhoneNumbersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhoneNumbersMutation, { data, loading, error }] = useUpdateUserPhoneNumbersMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateUserPhoneNumbersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPhoneNumbersMutation, UpdateUserPhoneNumbersMutationVariables>) {
        return Apollo.useMutation<UpdateUserPhoneNumbersMutation, UpdateUserPhoneNumbersMutationVariables>(UpdateUserPhoneNumbersDocument, baseOptions);
      }
export type UpdateUserPhoneNumbersMutationHookResult = ReturnType<typeof useUpdateUserPhoneNumbersMutation>;
export type UpdateUserPhoneNumbersMutationResult = Apollo.MutationResult<UpdateUserPhoneNumbersMutation>;
export type UpdateUserPhoneNumbersMutationOptions = Apollo.BaseMutationOptions<UpdateUserPhoneNumbersMutation, UpdateUserPhoneNumbersMutationVariables>;
export const UpdateUserEstablishmentsDocument = gql`
    mutation UpdateUserEstablishments($user_id: uuid!, $objects: [establishments_users_insert_input!]!) {
  delete_establishments_users(where: {user_id: {_eq: $user_id}}) {
    affected_rows
  }
  insert_establishments_users(objects: $objects) {
    affected_rows
  }
}
    `;
export type UpdateUserEstablishmentsMutationFn = Apollo.MutationFunction<UpdateUserEstablishmentsMutation, UpdateUserEstablishmentsMutationVariables>;

/**
 * __useUpdateUserEstablishmentsMutation__
 *
 * To run a mutation, you first call `useUpdateUserEstablishmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEstablishmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEstablishmentsMutation, { data, loading, error }] = useUpdateUserEstablishmentsMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useUpdateUserEstablishmentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEstablishmentsMutation, UpdateUserEstablishmentsMutationVariables>) {
        return Apollo.useMutation<UpdateUserEstablishmentsMutation, UpdateUserEstablishmentsMutationVariables>(UpdateUserEstablishmentsDocument, baseOptions);
      }
export type UpdateUserEstablishmentsMutationHookResult = ReturnType<typeof useUpdateUserEstablishmentsMutation>;
export type UpdateUserEstablishmentsMutationResult = Apollo.MutationResult<UpdateUserEstablishmentsMutation>;
export type UpdateUserEstablishmentsMutationOptions = Apollo.BaseMutationOptions<UpdateUserEstablishmentsMutation, UpdateUserEstablishmentsMutationVariables>;
export const InsertUserVerificationRequestDocument = gql`
    mutation InsertUserVerificationRequest($user_id: uuid!, $email: String) {
  insert_verification_requests_one(object: {user_id: $user_id, email: $email}) {
    id
    email
  }
}
    `;
export type InsertUserVerificationRequestMutationFn = Apollo.MutationFunction<InsertUserVerificationRequestMutation, InsertUserVerificationRequestMutationVariables>;

/**
 * __useInsertUserVerificationRequestMutation__
 *
 * To run a mutation, you first call `useInsertUserVerificationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUserVerificationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUserVerificationRequestMutation, { data, loading, error }] = useInsertUserVerificationRequestMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInsertUserVerificationRequestMutation(baseOptions?: Apollo.MutationHookOptions<InsertUserVerificationRequestMutation, InsertUserVerificationRequestMutationVariables>) {
        return Apollo.useMutation<InsertUserVerificationRequestMutation, InsertUserVerificationRequestMutationVariables>(InsertUserVerificationRequestDocument, baseOptions);
      }
export type InsertUserVerificationRequestMutationHookResult = ReturnType<typeof useInsertUserVerificationRequestMutation>;
export type InsertUserVerificationRequestMutationResult = Apollo.MutationResult<InsertUserVerificationRequestMutation>;
export type InsertUserVerificationRequestMutationOptions = Apollo.BaseMutationOptions<InsertUserVerificationRequestMutation, InsertUserVerificationRequestMutationVariables>;