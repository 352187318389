import { AddEditTransaction } from "./types";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";
import { MyXTableCell, NBTableCell, VenuePhoto } from "./StyledComponents";
import React from "react";

interface ShowTableProps {
  data: AddEditTransaction;
  editingId: string;
  closeForm: Function;
}

export const ShowTable: React.FC<ShowTableProps> = ({
  data,
  editingId,
  closeForm,
}) => {
  console.log("data", data);

  if (!data) {
    try {
      closeForm();
    } catch (e) {
      console.warn(e);
    }
    return null;
  }

  return (
    <div>
      <Table>
        <TableBody>
          <TableRow>
            <MyXTableCell>
              {data.amount} {data.currency_code}
            </MyXTableCell>
            <MyXTableCell> </MyXTableCell>
          </TableRow>
          <TableRow>
            <NBTableCell> Amount </NBTableCell>
            <NBTableCell> </NBTableCell>
          </TableRow>
          <TableRow>
            <MyXTableCell>
              {new Date(data.date_created).toUTCString()}
            </MyXTableCell>
            <MyXTableCell>{data.cancelled ? "Paid" : "Not paid"} </MyXTableCell>
          </TableRow>
          <TableRow>
            <NBTableCell> Date created </NBTableCell>
            <NBTableCell> Cancelled </NBTableCell>
          </TableRow>
          <TableRow>
            <MyXTableCell> {data.extra_data} </MyXTableCell>
            <MyXTableCell> {data.type} </MyXTableCell>
          </TableRow>
          <TableRow>
            <NBTableCell> Extra data </NBTableCell>
            <NBTableCell> Type </NBTableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {editingId && (
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    if (closeForm) {
                      closeForm();
                    }
                  }}
                >
                  Close
                </Button>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
