import * as React from "react";

import { T } from "../../utils/translate";

import { EstPhone_number } from "./types";
import { Phone_numberType } from "../PhoneNumber/types";

import {
  useInsertPhoneNumberMutation,
  useDeletePhoneNumberMutation,
} from "../../graphql";

import cloneDeep from "lodash/cloneDeep";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { TextInput, ComboBoxMulti } from "../../sharedComponents/forms";
import { Table, TableBody, TableRow } from "@material-ui/core";
import { MyTableCell } from "../../style/styledComponents/table";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { authenticationService } from "src/services/authentication";

interface EstablishmentPhone_numberProps {
  editingId?: string | undefined;
  allPhone_numbers: Phone_numberType[];
  setPhone_numbersToSend: Function;
  globalRefresh: Function;
  allEstablishmentsPhone_numbers: EstPhone_number[];
  usedNumbers: string[];
}

export const EstablishmentPhoneNumber: React.FC<EstablishmentPhone_numberProps> = ({
  allPhone_numbers,
  allEstablishmentsPhone_numbers,
  editingId,
  setPhone_numbersToSend,
  globalRefresh,
  usedNumbers,
}) => {
  const [
    selectedEstablishmentsPhone_numbers,
    setSelectedEstablishmentsPhone_numbers,
  ] = React.useState([]);
  const [open, toggleOpen] = React.useState(false); //dialog
  const [dialogValue, setDialogValue] = React.useState({
    name: "",
    phone_number: "",
  });
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(
    "Phone number invalid"
  );

  React.useEffect(() => {
    if (
      allEstablishmentsPhone_numbers &&
      allEstablishmentsPhone_numbers.length
    ) {
      const newValues = allEstablishmentsPhone_numbers
        .filter((x) => x.establishment?.id === editingId)
        .map((x) => x.phone_number)
        .map((pn) => ({
          name: `${pn.name} (${pn.phone_number})`,
          id: pn.id,
          disabled: usedNumbers.indexOf(pn.id) >= 0,
        }));

      setSelectedEstablishmentsPhone_numbers(newValues);
      setPhone_numbersToSend(newValues);
    }
  }, [allEstablishmentsPhone_numbers]);

  //add phone number from here
  const [
    addPhone_number,
    // { error: errorAddPhone_number, data: dataAddPhone_number },
  ] = useInsertPhoneNumberMutation({
    onCompleted({ insert_phone_numbers_one }) {
      console.log("insert_phone_numbers_one", insert_phone_numbers_one);
      const newValue = {
        id: insert_phone_numbers_one.id,
        // name: insert_phone_numbers_one.name,
        // phone_number: insert_phone_numbers_one.phone_number,
        name: insert_phone_numbers_one.phone_number,
      };

      const newValues = cloneDeep(selectedEstablishmentsPhone_numbers);
      newValues.push(newValue);
      // variable in this function to give default values to combo box multi
      onChangeValue(newValues);

      setTimeout(() => {
        globalRefresh();
        handleClose();
      }, 1);
    },

    onError(error) {
      console.error("error:::", error);
      if (JSON.stringify(error).includes("Uniqueness")) {
        setErrorMessage("This phone number already exist");
      }
      setError(true);
    },
  });

  const [
    removePhoneNumber,
    // { error: errorRemovePN, data: dataRemovePN },
  ] = useDeletePhoneNumberMutation({
    onCompleted(answer) {
      console.log("delete answer", answer);
      if (!answer?.delete_phone_numbers_by_pk?.id) {
        alert(T("Deleting phone number failed. Do you have a permission?"));
      } else {
        const deletedId = answer.delete_phone_numbers_by_pk.id;
        let selectedOptions = cloneDeep(selectedEstablishmentsPhone_numbers);
        selectedOptions = selectedOptions.filter((x) => x.id !== deletedId);
        onChangeValue(selectedOptions);
      }
      setTimeout(() => {
        globalRefresh();
      }, 100);
    },
  });

  const addItem = (item) => {
    console.log("add phone number", item);
    if (!item) {
      console.error("item cannot be false");
      return;
    }
    try {
      addPhone_number({
        variables: {
          phone_number: item.phone_number,
          active: true,
          name: item.name,
        },
      });
    } catch (e) {
      console.warn("Cannot add phone_number", e);
      alert(T("Saving data failed."));
    }
  };

  //to update parent
  const onChangeValue = (newValues) => {
    console.log("onChangeValue", newValues);
    setSelectedEstablishmentsPhone_numbers(newValues);
    setPhone_numbersToSend(newValues);
  };

  const handleClose = () => {
    //dialog
    setDialogValue({
      name: "",
      phone_number: "",
    });

    toggleOpen(false);
  };

  const handleSubmit = (event) => {
    //dialog
    event.preventDefault();

    console.log("dialogValue", dialogValue.phone_number);
    const phoneNumber = parsePhoneNumberFromString(
      dialogValue.phone_number,
      authenticationService.getCountryCode()
    );
    if (phoneNumber && phoneNumber.isValid()) {
      console.log("valid");
      console.log("handlesubmit");
      addItem(dialogValue);
      //   handleClose();
    } else {
      console.log("validation invalid");
      setErrorMessage("Phone number invalid");
      setError(true);
    }
  };

  const sendUp = (newValue) => {
    setDialogValue({
      name: "",
      phone_number: newValue,
    });
    toggleOpen(true); //opening dialog
  };

  return (
    <>
      <ComboBoxMulti
        options={allPhone_numbers}
        defaultValues={selectedEstablishmentsPhone_numbers}
        onChangeValue={onChangeValue}
        placeholder={T("Phone_numbers")}
        addNewItem={sendUp}
        removeOption={(id) => {
          removePhoneNumber({
            variables: { id },
          });
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">
            {T("Add new phone number")}
          </DialogTitle>
          <DialogContent>
            <Table>
              {/* Why a table? */}
              <TableBody>
                <TableRow key={1}>
                  <MyTableCell>
                    <TextInput
                      name="name"
                      label={T("Name")}
                      value={dialogValue.name}
                      onChange={(value) =>
                        setDialogValue({
                          ...dialogValue,
                          name: value,
                        })
                      }
                      helperText={T("Name of new phone number")}
                    />
                  </MyTableCell>
                  <MyTableCell>
                    <TextInput
                      name="phone_number"
                      label={T("Phone number")}
                      value={dialogValue.phone_number}
                      onChange={(value) => {
                        setDialogValue({
                          ...dialogValue,
                          phone_number: value,
                        });
                        setError(false);
                      }}
                      error={error}
                      helperText={
                        !error ? T("Write phone number") : T(errorMessage)
                      }
                    />
                  </MyTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              {T("Cancel")}
            </Button>
            <Button onClick={handleSubmit} color="primary" variant="contained">
              {T("Add")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
