import { urlService } from "../url";
import { ajaxService } from "../ajax";
import { T } from "../../utils/translate";
import { useUserStore } from "../../store/User";
import * as jwt_decode from "jwt-decode";

export const PASS_HASH = "telidoo_auth_session_pass_hash";
export const USER_EMAIL = "telidoo_active_user_email";
const AUTH_SESSION_DATA_TOKEN = "telidoo_auth_session_data_token";

interface RegistrationModel {
  ico: string;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  region: string;
}

interface LoginRequest {
  success: boolean;
  token?: string;
  error?: string;
}

interface SemiLoginRequest {
  success: boolean;
  token?: string;
  error?: string;
  hash?: string;
}

interface SetEmailRequest {
  success: boolean;
  token?: string;
  error?: string;
  hash?: string;
}

interface SetPasswordRequest {
  success: boolean;
  token?: string;
  error?: string;
}

interface VerifyRequest {
  success: boolean;
  token?: string;
  error?: string;
}

interface RegistrationRequest {
  success: boolean;
  error?: string;
}

interface ResetRequest {
  success: boolean;
  error?: string;
}

interface ChangePasswordRequest {
  success: boolean;
  error?: string;
}

interface VerifyHashRequest {
  success: boolean;
  error?: string;
}

class AuthenticationService {
  public getAuthorizationRequestHeader() {
    const token = localStorage.getItem(AUTH_SESSION_DATA_TOKEN);

    if (token) {
      return { Authorization: `Bearer ${token}` };
    }
    return null;
  }

  public decodeToken() {
    const token = localStorage.getItem(AUTH_SESSION_DATA_TOKEN);
    if (!token) {
      return null;
    }
    try {
      return jwt_decode(token);
    } catch (e) {
      return null;
    }
  }

  public getCountryCode() {
    return this.decodeToken()?.country || "CZ";
  }

  public logout() {
    console.log("logout");
    this.resetAuthorizationData();
    useUserStore.getState().setAuthenticated(false);
  }

  public login(email: string, password: string): Promise<LoginRequest> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<LoginRequest>(urlService.auth("/login"), {
          email: email,
          password: password,
        })
        .then((data) => {
          if (data.success && data.token) {
            this.afterLogin(data.token);
            localStorage.setItem(USER_EMAIL, email);
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          //fake auth
          // this.afterLogin('xxx');
          // resolve();
          reject(error.message);
        });
    });
  }

  public resetPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<ResetRequest>(urlService.auth("/reset"), {
          email: email,
        })
        .then((data) => {
          if (data.success) {
            alert(T("Reset_password_success"));
            window.location.replace(urlService.front("/login"));
            resolve();
          } else {
            reject("email_invalid");
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }

  public changePassword(
    email: string,
    oldPassword: string,
    password: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<ChangePasswordRequest>(urlService.auth("/change"), {
          email: email,
          oldPassword: oldPassword,
          password: password,
          //todo send auth
        })
        .then((data) => {
          if (data.success) {
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }

  public hashAccess(hash: string): Promise<SemiLoginRequest> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<SemiLoginRequest>(urlService.auth("/hashAccess"), {
          hash,
        })
        .then((data) => {
          if (data.success && data.token) {
            this.afterLogin(data.token);
            this.setPassHash(data.hash);
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }

  public adminAccess(companyId: string): Promise<SemiLoginRequest> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<SemiLoginRequest>(urlService.auth("/adminAccess"), {
          companyId,
        })
        .then((data) => {
          if (data.success && data.token) {
            this.afterLogin(data.token);
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error.message);
        });
    });
  }

  public appAccess(apiKey: string): Promise<SemiLoginRequest> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<SemiLoginRequest>(urlService.auth("/appAccess"), {
          apiKey,
        })
        .then((data) => {
          if (data.success && data.token) {
            this.afterLogin(data.token);
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error.message);
        });
    });
  }

  public setEmail(
    email: string,
    passHash: string,
    andVerify: boolean
  ): Promise<SetEmailRequest> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<SetEmailRequest>(urlService.auth("/setEmail"), {
          email,
          passHash,
          andVerify,
        })
        .then((data) => {
          if (data.success) {
            console.log("setEmail data", data);
            // this.afterLogin(data.token);
            // this.setPassHash(data.hash);
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }

  public setNewPassword(
    passHash: string,
    email: string,
    password: string
  ): Promise<SetPasswordRequest> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<SetPasswordRequest>(urlService.auth("/setPassword"), {
          passHash,
          email,
          password,
        })
        .then((data) => {
          if (data.success) {
            console.log("setPassword data", data);
            // this.afterLogin(data.token);
            // this.setPassHash(data.hash);
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }

  //will send POST to createActivationRequest endpoint, which will basically send activation email
  public createActivationRequest(
    user_id: string,
    contact_email: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<ChangePasswordRequest>(
          urlService.auth("/createActivationRequest"),
          {
            user_id,
            contact_email,
          }
        )
        .then((data) => {
          if (data.success) {
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }

  public verify(guid: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<VerifyRequest>(urlService.auth("/verify"), {
          request_id: guid,
          new_password: password,
        })
        .then((data) => {
          if (data.success && data.token) {
            this.afterLogin(data.token);
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }

  public registration(data: RegistrationModel): Promise<void> {
    return new Promise((resolve, reject) => {
      ajaxService
        .post<RegistrationRequest>(urlService.auth("/register"), data)
        .then((data) => {
          if (data.success) {
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }

  public getUserData(): Promise<void> {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem(AUTH_SESSION_DATA_TOKEN);
      if (token) {
        resolve();
      } else {
        reject();
      }
    });
  }

  private setPassHash(passHash: string) {
    localStorage.setItem(PASS_HASH, passHash);
  }

  private afterLogin(token: string) {
    this.setAuthorizationData(token);
    useUserStore.getState().setAuthenticated(true);
    window.top.location.replace(urlService.front("/"));
  }

  private setAuthorizationData(token: string) {
    localStorage.setItem(AUTH_SESSION_DATA_TOKEN, token);
  }

  private resetAuthorizationData() {
    console.log("resetAuthorizationData");
    localStorage.removeItem(AUTH_SESSION_DATA_TOKEN);
  }
}

export const authenticationService = new AuthenticationService();
