import * as React from "react";

import { Typography } from "@material-ui/core";

import { EditTable } from "./EditTable";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AddEditEstablishment } from "../Establishment/types";
import { Phone_numberUserType, Phone_numberType } from "../PhoneNumber/types";
import { UserType } from "./types";
import { Role } from "../Role/types";
import { T } from "../../utils/translate";
import { HOST_ROLE } from "../../utils/constants";

export const UserAdd: React.FC<{
  addUser: any;
  usersData: { users: UserType[] };
  phone_numbersData?: { phone_numbers: Phone_numberType[] };
  phone_numbers_users?: Phone_numberUserType[];
  establishmentsData?: { establishments: AddEditEstablishment[] };
  myCompanyRoles: { roles: Role[] };
  globalRefresh: Function;
}> = ({
  addUser,
  usersData,
  phone_numbersData,
  phone_numbers_users,
  establishmentsData,
  myCompanyRoles,
  globalRefresh,
}) => {
  return (
    <div>
      <Accordion
        defaultExpanded={usersData?.users.length === 0}
        style={{ marginTop: "1rem" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{T("Add new user")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "min(100%, 1000px)" }}>
            <EditTable
              operation={"add"}
              addEditUser={addUser}
              data={{
                id: "",
                first_name: "",
                last_name: "",
                email: "",
                active: true,
                role_id: HOST_ROLE,
                disabled: false,
                is_public_email: true,
                photo_url: "",
              }}
              phone_numbersData={phone_numbersData}
              phone_numbers_users={phone_numbers_users}
              establishmentsData={establishmentsData}
              myCompanyRoles={myCompanyRoles}
              globalRefresh={globalRefresh}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
