import * as React from "react";

import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/ArrowBackIos";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { Link } from "react-router-dom";

import { T } from "../../utils/translate";
import { yup } from "../../utils/yup";
import { TextInput, Select, Label } from "../../sharedComponents/forms";
import { authenticationService } from "../../services/authentication";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

import mailIlust from "./mail.svg";
import czFlag from "./cz-flag.svg";
import skFlag from "./sk-flag.svg";
import logo from "src/layout/logo.svg";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { Check } from "@material-ui/icons";

const FORM_VALIDATION = yup.object().shape({
  contact_email: yup.string().required().email(),
  contact_first_name: yup.string().required(),
  contact_last_name: yup.string().required(),
  contact_phone: yup
    .string()
    .required()
    .test("phone-number", T("Phone number is not valid"), function (value) {
      console.log(value);
      console.log(this.parent);
      const phoneNumber = parsePhoneNumberFromString(
        value,
        this.parent.country
      );
      console.log(phoneNumber);
      return phoneNumber && phoneNumber.isValid();
    }),
  ico: yup
    .string()
    .required()
    .matches(/^\d{8}$/, T("Bad_format_ico")),
  country: yup.string(),
  wants_terminal: yup.boolean(),
  affiliate: yup.string(),
  business_subject: yup.string().when("wants_terminal", {
    is: true,
    then: yup.string().required(),
  }),
  terminal_count: yup.number().when("wants_terminal", {
    is: true,
    then: yup.number().required(),
  }),
  terminal_avg_turnover: yup.number().when("wants_terminal", {
    is: true,
    then: yup.number().required(),
  }),
  terminal_avg_transaction: yup.number().when("wants_terminal", {
    is: true,
    then: yup.number().required(),
  }),
});

const INIT_VALUES: RegistrationFormModel = {
  contact_email: "",
  contact_first_name: "",
  contact_last_name: "",
  contact_phone: "",
  ico: "",
  country: "CZ",
  wants_terminal: true,
  affiliate: "",
  business_subject: "",
  terminal_count: 1,
};

interface RegistrationFormModel {
  ico: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email: string;
  contact_phone: string;
  country: string;
  wants_terminal: boolean;
  business_subject?: string;
  terminal_count?: number;
  terminal_avg_turnover?: number;
  terminal_avg_transaction?: number;
  affiliate?: string;
}

export const RegistrationPage: React.FC<{}> = () => {
  const [successState, setSuccessState] = React.useState(false);
  const [agreeTerms, setAgreeTerms] = React.useState(false);
  const onSubmit = (values: RegistrationFormModel) => {
    console.log(values);
    const processedPhoneNumber = parsePhoneNumberFromString(
      values.contact_phone,
      values.country as CountryCode
    );
    if (!processedPhoneNumber || !processedPhoneNumber.isValid()) {
      alert(T("Phone number is not valid"));
      return;
    }
    values.contact_phone = processedPhoneNumber.number.toString();
    authenticationService
      .registration({
        ...values,
        contact_name:
          values.contact_first_name + " " + values.contact_last_name,
        region: values.country,
      })
      .then(() => {
        setSuccessState(true);
      })
      .catch((error: string) => {
        alert(T(error));
      });
  };

  const formMethods = useForm({
    resolver: yupResolver(FORM_VALIDATION),
    defaultValues: INIT_VALUES,
    mode: "onBlur",
  });

  if (successState) {
    return (
      <main className="main">
        <div className="main__content login">
          <div className="col-16">
            <div className="row">
              <a href="https://business.telidoo.com/">
                <img src={logo} className="logo" />
              </a>
              <form className="form login__form">
                <img
                  src={mailIlust}
                  style={{ width: "250px", marginBottom: "40px" }}
                />
                <h2>{T("Registration was successful.")}</h2>
                {T(
                  "Email was sent to you with a link to activate the account and create a password."
                )}
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }

  const currency = formMethods.watch("country") == "CZ" ? "Kč" : "EUR";

  return (
    <main className="main">
      <div className="main__content login registration">
        <div className="col-16">
          <div className="row">
            <a href="https://business.telidoo.com/">
              <img src={logo} className="logo" />
            </a>
            <FormProvider {...formMethods}>
              <form
                className="form login__form"
                onSubmit={formMethods.handleSubmit(onSubmit)}
              >
                <h1 className="login__form--title">{T("Registration")}</h1>

                <Controller
                  name="country"
                  render={({ value, onChange }) => {
                    return (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <Label
                            style={{
                              marginBottom: 0,
                              marginRight: 32,
                            }}
                          >
                            {T("Country")} *
                          </Label>
                          <div
                            onClick={() => onChange("CZ")}
                            style={{
                              marginRight: 32,
                              cursor: "pointer",
                              opacity: value == "CZ" ? 1 : 0.5,
                            }}
                          >
                            <img src={czFlag} width={32} height={32} />
                          </div>
                          <div
                            onClick={() => onChange("SK")}
                            style={{
                              cursor: "pointer",
                              opacity: value == "SK" ? 1 : 0.5,
                            }}
                          >
                            <img src={skFlag} width={32} height={32} />
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />

                <TextInput autohook name="ico" label={`${T("ICO")} *`} />

                <p>
                  {T(
                    "Other information about the company will be loaded automaically"
                  )}
                </p>

                <h3>Kontaktní osoba</h3>

                <TextInput
                  autohook
                  name="contact_first_name"
                  label={`${T("First name")} *`}
                />

                <TextInput
                  autohook
                  name="contact_last_name"
                  label={`${T("Last name")} *`}
                />

                <TextInput
                  autohook
                  name="contact_email"
                  label={`${T("Email")} *`}
                />

                <TextInput
                  autohook
                  name="contact_phone"
                  label={`${T("Phone")} *`}
                />

                <Controller
                  name="wants_terminal"
                  render={({ value, onChange }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onChange={(e) => onChange(e.target.checked)}
                            color="primary"
                          />
                        }
                        label="Chci zřídit platební terminál"
                      />
                    );
                  }}
                />

                <h3>Firemní údaje</h3>

                {formMethods.watch("wants_terminal") && (
                  <>
                    <TextInput
                      autohook
                      name="business_subject"
                      label={`Předmět podnikání *`}
                    />

                    <TextInput
                      autohook
                      name="terminal_count"
                      label={`Počet terminálů" *`}
                      type="number"
                    />

                    <TextInput
                      autohook
                      name="terminal_avg_turnover"
                      label={`Uveďte předpokládaný měsíční obrat na kartách v ${currency} *`}
                      placeholder="např. 100000"
                      type="number"
                    />

                    <TextInput
                      autohook
                      name="terminal_avg_transaction"
                      label={`Uveďte předpokládaný průměr transakce v ${currency} *`}
                      placeholder="např. 300"
                      type="number"
                    />
                  </>
                )}

                <TextInput autohook name="affiliate" label={"Affiliate kód"} />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeTerms}
                      onChange={() => setAgreeTerms(!agreeTerms)}
                      color="primary"
                    />
                  }
                  label={
                    <>
                      Souhlasím s{" "}
                      <a
                        href="https://business.telidoo.com/terms/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "#2b48a4",
                          textDecoration: "underline",
                        }}
                      >
                        obchodními podmínkami
                      </a>
                      <br />a{" "}
                      <a
                        href="https://business.telidoo.com/privacy/"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "#2b48a4",
                          textDecoration: "underline",
                        }}
                      >
                        zpracováním osobních údajů
                      </a>
                    </>
                  }
                />

                <div className="btn-row">
                  <Link to="/login">
                    <Button color="primary" startIcon={<BackIcon />}>
                      {T("Login")}
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    endIcon={<Check />}
                    disabled={!agreeTerms}
                  >
                    {T("Send")}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </main>
  );
};
