import React, { useState } from "react";

import {
  Typography,
  Card,
  Button,
  IconButton,
  Grid,
  LinearProgress,
  Divider,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

import {
  useGetReservioIntegrationQuery,
  GetReservioIntegrationQuery,
  useUpsertSmsTemplatesMutation,
  useUpdateReservioIntegrationMutation,
  Reservio_Integration_Set_Input,
  useCreateReservioUserMutation,
  CreateReservioUserInput,
} from "src/graphql";

import { ConnectedUserType, TemplateType } from "../types";
import {
  TextInput,
  Label,
  TimeIntervalInput,
} from "src/sharedComponents/forms";
import { T } from "src/utils/translate";

const TEMPLATE_TYPES = [
  "sms_template_accepted",
  "sms_template_confirmed",
  "sms_template_rejected",
  "sms_template_cancelled",
  "sms_template_moved",
  "sms_template_reminder",
];

const EditForm: React.FC<{
  values?: GetReservioIntegrationQuery;
  saveValues?: (values: Reservio_Integration_Set_Input) => void;
}> = ({ values, saveValues }) => {
  // console.log(values);
  const templateTypes = TEMPLATE_TYPES.map((it) => ({ name: T(it), key: it }));
  const defaultValues = Object.fromEntries(
    templateTypes.map((it) => [
      it.key,
      values.reservio_integration[0][it.key]?.text || "",
    ])
  );
  defaultValues.reminder_interval_before =
    values.reservio_integration[0].reminder_interval_before;
  const form = useForm<Reservio_Integration_Set_Input>({
    defaultValues,
  });

  return (
    <>
      <form onSubmit={form.handleSubmit(saveValues)}>
        {templateTypes.map((type) => (
          <TextInput
            key={type.key}
            label={type.name}
            name={type.key}
            register={form.register}
            textarea
          />
        ))}
        <Label>{T("Reservation reminder interval before")}</Label>
        <Controller
          control={form.control}
          name={"reminder_interval_before"}
          render={({ onChange, onBlur, value }) => (
            <TimeIntervalInput value={value} onChange={onChange} />
          )}
        />
        <Button type="submit" variant="contained">
          {T("Save")}
        </Button>
      </form>
    </>
  );
};

export const LoginForm: React.FC<{
  createUser: (user: CreateReservioUserInput) => void;
}> = ({ createUser }) => {
  const { handleSubmit, register } = useForm<{
    username: string;
    password: string;
  }>();

  const onSubmit = handleSubmit((data) => {
    createUser(data);
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <TextInput
          label={T("Email")}
          placeholder={T("Email")}
          name="username"
          register={register}
        />
        <TextInput
          label={T("Password")}
          placeholder={T("Password")}
          name="password"
          type="password"
          register={register}
        />

        <Button type="submit" variant="contained">
          {T("Save")}
        </Button>
      </form>
    </>
  );
};

export const Reservio: React.FC<{
  connectedUsers: ConnectedUserType[];
  templates?: TemplateType[];
}> = ({ connectedUsers, templates }) => {
  const query = useGetReservioIntegrationQuery();
  const [upsertSmsTemplates] = useUpsertSmsTemplatesMutation();
  const [updateIntegration] = useUpdateReservioIntegrationMutation({
    onCompleted: () => {
      query.refetch();
    },
  });
  const [createUser] = useCreateReservioUserMutation({
    onCompleted: (data) => {
      if (data.create_reservio_user.success) {
        query.refetch();
      } else {
        alert(T("Reservio integration failed"));
      }
    },
  });
  const saveValues = async (values) => {
    // console.log(values);
    const templates = await upsertSmsTemplates({
      variables: {
        objects: TEMPLATE_TYPES.map((it) => ({
          text: values[it],
          name: T(it),
          special_type: it,
        })),
      },
    });
    const templatesResponse = templates?.data.insert_smsapi_templates.returning;
    // console.log(templatesResponse);
    updateIntegration({
      variables: {
        values: {
          reminder_interval_before: values.reminder_interval_before,
          ...Object.fromEntries(
            TEMPLATE_TYPES.map((type) => {
              const found = templatesResponse.find(
                (it) => it.special_type === type && it.text !== ""
              );
              if (found) {
                return [type + "_id", found.id];
              } else {
                return [type + "_id", null];
              }
            })
          ),
        },
      },
    });
  };

  if (query.loading) {
    return null;
  }
  const loggedIn = query.data?.reservio_integration?.length > 0;

  return (
    <>
      <Typography variant="h6">{T("Reservio integration")}</Typography>
      {loggedIn ? (
        <Card style={{ padding: "1rem" }}>
          <div>
            {T("Connected to account") +
              " " +
              query?.data.reservio_integration[0].reservio_email}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: T("Reservio info HTML") }}
          ></div>
          <EditForm values={query?.data} saveValues={saveValues} />
        </Card>
      ) : (
        <Card style={{ padding: "1rem" }}>
          <div>{T("Log in to Reservio")}</div>
          <LoginForm
            createUser={(user) => createUser({ variables: { user } })}
          />
        </Card>
      )}
    </>
  );
};
