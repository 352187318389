import * as React from 'react';
import { Link } from 'react-router-dom';

export const Page404: React.FC = () => {
    return (
        <main
            className="notfound-page"
            style={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100vw',
                height: '100vh',
            }}
        >
            <div>
                <h1>404 - Stránka nenalezena</h1>
            </div>
            <div>
                <Link to="/">Return home</Link>
            </div>
        </main>
    );
};
