import styled from "styled-components";
import { TableCell } from "@material-ui/core";

export const Wrapper = styled.div`
  padding: 2rem;
  box-shadow: 0px 1px 2px rgba(36, 80, 141, 0.2);
  border-radius(5px): ;
  background-color: white;
  margin: auto;
  max-width: 600px;
`;

export const MyTableCell = styled(TableCell)<{ width?: string }>`
  border-bottom: 0px;
  margin: 0;
  padding: 0 16px 0 4px;
  ${(props) => (props.width ? `width: ${props.width}` : ``)}
`;

export const MyTableCellBorder = styled(TableCell)<{ width?: string }>`
  letter-spacing: 0em;
  ${(props) => (props.width ? `width: ${props.width}` : ``)}
`;

export const MyNBTableCell = styled(MyTableCell)`
  border: 0;
`;

export const FirstTableCell = styled(MyTableCell)`
  width: 110px;
`;
export const SecondTableCell = styled(MyTableCell)`
  width: 110px;
`;
