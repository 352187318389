import React from "react";
import { TextInput } from ".";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { T } from "../../utils/translate";

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      marginBottom: "1em",
    },
    option: {
      '&[data-focus="true"]': {
        backgroundColor: "rgb(222,235,255)",
      },
      '&[aria-selected="true"]': {
        backgroundColor: "rgb(235,235,235)",
      },
      background: "white",
      fontWeight: 300,
      "&:hover": {
        background: "rgb(222,235,255)",
      },
    },
    endAdornment: {
      right: 5,
      opacity: 0.5,
      "&:hover": {
        opacity: 0.9,
        color: "red",
      },
    },
  });
});

interface ItemType {
  label: string;
  value: number;
}

interface TimeIntervalInputProps {
  value: number;
  onChange: (value: number) => void;
  label?: string;
}

export const TimeIntervalInput: React.FC<TimeIntervalInputProps> = ({
  value = 3600,
  onChange,
  label,
}) => {
  const classes = useStyles();
  const filter = createFilterOptions<ItemType>();

  const parseValue = (str) => {
    // https://stackoverflow.com/questions/11909457/how-to-parse-a-duration-string-into-seconds-with-javascript
    let seconds = 0;
    const days = str.match(/(\d+)\s*d/);
    const hours = str.match(/(\d+)\s*h/);
    const minutes = str.match(/(\d+)\s*m/);
    if (days) {
      seconds += parseInt(days[1]) * 86400;
    }
    if (hours) {
      seconds += parseInt(hours[1]) * 3600;
    }
    if (minutes) {
      seconds += parseInt(minutes[1]) * 60;
    }
    return seconds;
  };
  const valueLabel = (seconds) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    let str = "";
    if (days > 0) {
      str += days + "d ";
    }
    if (hours > 0) {
      str += hours + "h ";
    }
    if (minutes > 0) {
      str += minutes + "m";
    }
    return str;
  };

  const defaultOptions: ItemType[] = [
    { label: valueLabel(value), value },
    ...[900, 3600, 14400, 86400, 172800]
      .filter((it) => it != value)
      .map((it) => ({
        label: valueLabel(it),
        value: it,
      })),
  ];

  return (
    <Autocomplete
      classes={classes}
      selectOnFocus
      blurOnSelect
      clearOnBlur
      disableClearable
      options={defaultOptions}
      noOptionsText={T("No options")}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          const seconds = parseValue(params.inputValue);
          if (seconds != 0) {
            filtered[0] = {
              value: seconds,
              label: `${valueLabel(seconds)}`,
            };
          }
        }
        return filtered;
      }}
      getOptionLabel={(option) => (option?.label ? option.label : "-")}
      getOptionSelected={(option, value) => option?.value === value?.value}
      renderInput={(params) => <TextInput label={label} {...params} />}
      value={{ label: valueLabel(value), value }}
      onChange={(event, newValue) => {
        if (newValue) {
          console.log(newValue);
          onChange(newValue.value);
        } else {
          console.log(value);
          onChange(value);
        }
      }}
    />
  );
};
