import React from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  LinearProgress,
  TableSortLabel,
} from "@material-ui/core";
import ViewportList from "react-viewport-list";

import { IDataTableController } from "./DataTableController";

interface DataTableProps {
  controller: IDataTableController;
}

export const DataTable: React.FC<DataTableProps> = ({ controller }) => {
  const renderRow = (row) => {
    controller.prepareRow(row);
    return (
      <TableRow
        {...row.getRowProps(controller.getRowProps({ row }))}
        key={row.getRowProps().key}
      >
        {row.cells.map((cell) => {
          return (
            <TableCell
              {...cell.getCellProps(controller.getCellProps({ cell }))}
              key={cell.getCellProps().key}
            >
              {cell.render("Cell")}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };
  const shouldVirtualize = controller.rows.length > 500;
  return (
    <TableContainer component={Paper}>
      <Table {...controller.getTableProps()}>
        <TableHead>
          {controller.headerGroups.map((headerGroup) => (
            <>
              {headerGroup.headers.filter((it) => it.canFilter).length > 0 && (
                <TableRow
                  {...headerGroup.getHeaderGroupProps(() => ({
                    style: {
                      verticalAlign: "bottom",
                    },
                  }))}
                  key={headerGroup.getHeaderGroupProps().key + "_filter"}
                >
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps()}
                      size={"small"}
                      key={column.getHeaderProps().key}
                    >
                      {column.canFilter ? column.render("Filter") : null}
                    </TableCell>
                  ))}
                </TableRow>
              )}
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.getHeaderGroupProps().key}
              >
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...(column.canSort
                      ? column.getHeaderProps(column.getSortByToggleProps())
                      : column.getHeaderProps())}
                    size={"small"}
                    key={column.getHeaderProps().key}
                  >
                    {column.render("Header")}
                    {column.canSort ? (
                      <TableSortLabel
                        active={column.isSorted}
                        // react-table has a unsorted state which is not treated here
                        direction={column.isSortedDesc ? "desc" : "asc"}
                      />
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            </>
          ))}
        </TableHead>
        <TableBody
          style={{ willChange: shouldVirtualize ? "transform" : "initial" }}
        >
          <TableRow>
            {controller.loading && (
              // Use our custom loading state to show a loading indicator
              <TableCell colSpan={10000} size={"small"}>
                <LinearProgress />
              </TableCell>
            )}
          </TableRow>
          {shouldVirtualize ? (
            <ViewportList
              items={controller.rows}
              itemMinSize={52}
              overscan={10}
            >
              {(row) => renderRow(row)}
            </ViewportList>
          ) : (
            controller.rows.map((row) => renderRow(row))
          )}
        </TableBody>
      </Table>
      {controller.pageSize && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[controller.pageSize]}
          rowsPerPage={controller.pageSize}
          count={controller.fullRowCount}
          page={controller.pageIndex}
          onChangePage={(e, page) => controller.setPageIndex(page)}
        />
      )}
    </TableContainer>
  );
};
