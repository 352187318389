import * as React from "react";
import { yup } from "../../utils/yup";
import { TextInput } from "../../sharedComponents/forms";
import { authenticationService } from "../../services/authentication";
import { USER_EMAIL } from "../../services/authentication/authenticationService";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { T } from "../../utils/translate";
import { Form } from "../../sharedComponents/Form";
import { Alert } from "@material-ui/lab";

const FORM_VALIDATION = yup.object().shape({
  oldPassword: yup.string().required().min(8),
  password: yup.string().required().min(8),
  secondPassword: yup
    .string()
    .required()
    .min(8)
    .oneOf([yup.ref("password"), null], T("Password_must_by_same")),
});

const INIT_VALUES: ChangePasswordFormModel = {
  oldPassword: "",
  password: "",
  secondPassword: "",
};

interface ChangePasswordFormModel {
  oldPassword: string;
  password: string;
  secondPassword: string;
}

export const ChangePassword: React.FC<{}> = () => {
  const [change, setChange] = React.useState(false);

  const onSubmit = async (values: ChangePasswordFormModel, reset: Function) => {
    const email = await localStorage.getItem(USER_EMAIL);
    console.log("email", email);
    if (!email || email === "undefined") {
      console.error("email not in local storage!");
      authenticationService.logout();
      return;
    }
    authenticationService
      .changePassword(email, values.oldPassword, values.password)
      .then(() => {
        reset();
        setChange(true);
      })
      .catch((error: string) => {
        alert(T(error));
      });
  };

  const formClicked = () => {
    setChange(false);
  };

  return (
    <div
      onClick={() => {
        formClicked();
      }}
      style={{ maxWidth: 400 }}
    >
      <Form<ChangePasswordFormModel>
        onSubmit={onSubmit}
        defaultValues={INIT_VALUES}
        validationSchema={FORM_VALIDATION}
      >
        <Typography variant="h2" gutterBottom>
          {T("Change password")}
        </Typography>

        {change && (
          <div style={{ marginBottom: "1rem" }}>
            <Alert severity="success">
              {T("Password was changed successfully")}
            </Alert>
          </div>
        )}
        <TextInput
          autohook
          type="password"
          name="oldPassword"
          label={`${T("Old password")} *`}
        />
        <TextInput
          autohook
          type="password"
          name="password"
          label={`${T("New password")} *`}
        />
        <TextInput
          autohook
          type="password"
          name="secondPassword"
          label={`${T("New password again")} *`}
        />
        <Button variant="contained" color="primary" type="submit">
          {T("Change")}
        </Button>
      </Form>
    </div>
  );
};

// <>

// </>
