import * as React from "react";

import { T } from "../../utils/translate";

import { Phone_numberUserType, Phone_numberType } from "../PhoneNumber/types";
import {
  useInsertPhoneNumberMutation,
  useDeletePhoneNumberMutation,
} from "../../graphql";
import cloneDeep from "lodash/cloneDeep";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { TextInput, ComboBoxMulti } from "../../sharedComponents/forms";
import { Table, TableBody, TableRow } from "@material-ui/core";
import { MyTableCell } from "../../style/styledComponents/table";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { authenticationService } from "src/services/authentication";

interface UserPhoneNumberProps {
  editingId?: string | undefined;
  allPhone_numbers: Phone_numberType[];
  setPhone_numbersToSend: Function;
  globalRefresh: Function;
  allUsersPhone_numbers: Phone_numberUserType[];
  usedNumbers: string[];
}

export const UserPhoneNumber: React.FC<UserPhoneNumberProps> = ({
  allPhone_numbers,
  allUsersPhone_numbers,
  editingId,
  setPhone_numbersToSend,
  globalRefresh,
  usedNumbers,
}) => {
  const [
    selectedUsersPhone_numbers,
    setSelectedUsersPhone_numbers,
  ] = React.useState([]);
  React.useEffect(() => {
    if (allUsersPhone_numbers && allUsersPhone_numbers.length) {
      const newValues = allUsersPhone_numbers
        .filter((x) => x.user?.id === editingId)
        .map((x) => x.phone_number)
        .map((pn) => ({
          name: `${pn.name} (${pn.phone_number})`,
          id: pn.id,
          disabled: usedNumbers.indexOf(pn.id) >= 0,
        }));

      setSelectedUsersPhone_numbers(newValues);
      setPhone_numbersToSend(newValues);
    }
  }, [allUsersPhone_numbers]);

  //add phone number from here
  const [
    addPhone_number,
    // { error: errorAddPhone_number, data: dataAddPhone_number },
  ] = useInsertPhoneNumberMutation({
    onCompleted({ insert_phone_numbers_one }) {
      console.log("insert_phone_numbers_one", insert_phone_numbers_one);
      const newValue = {
        id: insert_phone_numbers_one.id,
        // name: insert_phone_numbers_one.name,
        // phone_number: insert_phone_numbers_one.phone_number,
        name: insert_phone_numbers_one.phone_number,
      };

      const newValues = cloneDeep(selectedUsersPhone_numbers);
      newValues.push(newValue);
      // variable in this function to give default values to combo box multi
      onChangeValue(newValues);

      setTimeout(() => {
        globalRefresh();
      }, 1);
    },

    onError(error) {
      console.error(error);
    },
  });

  const [
    removePhoneNumber,
    // { error: errorRemovePN, data: dataRemovePN },
  ] = useDeletePhoneNumberMutation({
    onCompleted(answer) {
      console.log("delete answer", answer);
      if (!answer?.delete_phone_numbers_by_pk?.id) {
        alert(T("Deleting phone number failed. Do you have a permission?"));
      } else {
        const deletedId = answer.delete_phone_numbers_by_pk.id;
        let selectedOptions = cloneDeep(selectedUsersPhone_numbers);
        selectedOptions = selectedOptions.filter((x) => x.id !== deletedId);
        onChangeValue(selectedOptions);
      }
      setTimeout(() => {
        globalRefresh();
      }, 100);
    },
  });

  const addItem = (phone_number) => {
    if (!phone_number) return;
    console.log("Adding phone number", phone_number);
    try {
      addPhone_number({
        variables: {
          phone_number,
          active: true,
          name: "",
        },
      });
    } catch (e) {
      console.warn("Cannot add phone_number", e);
      alert(T("Saving data failed."));
    }
  };

  //to update parent
  const onChangeValue = (newValues) => {
    console.log("onChangeValue", newValues);
    setSelectedUsersPhone_numbers(newValues);
    setPhone_numbersToSend(newValues);
  };

  const sendUp = (newValue) => {
    const phoneNumber = parsePhoneNumberFromString(
      newValue,
      authenticationService.getCountryCode()
    );
    if (phoneNumber && phoneNumber.isValid()) {
      console.log(phoneNumber.number.toString());
      addItem(phoneNumber.number.toString());
    } else {
      alert(T("Phone number is not valid"));
    }
  };

  return (
    <>
      <ComboBoxMulti
        options={allPhone_numbers}
        defaultValues={selectedUsersPhone_numbers}
        onChangeValue={onChangeValue}
        placeholder={T("Phone_numbers")}
        addNewItem={sendUp}
        removeOption={(id) => {
          removePhoneNumber({
            variables: { id },
          });
        }}
      />
    </>
  );
};
