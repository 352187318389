import * as React from "react";

import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useGetMyCompanyQuery } from "../../graphql";
import { AddEditEstablishment, EstUser } from "./types";
import { EditTable } from "./EditTable";
import { UserType } from "../User/types";
import {
  Phone_numberEstablishmentType,
  Phone_numberType,
} from "../PhoneNumber/types";
import { T } from "../../utils/translate";

export const EstablishmentAdd: React.FC<{
  addEstablishment: any;
  myCompanyEstablishments: { establishments: AddEditEstablishment[] };
  usersData?: UserType[];
  phone_numbersData?: Phone_numberType[];
  phone_numbers_establishments?: Phone_numberEstablishmentType[];
  establishments_users?: EstUser[];
  globalRefresh: Function;
}> = ({
  addEstablishment,
  myCompanyEstablishments,
  usersData,
  phone_numbersData,
  phone_numbers_establishments,
  establishments_users,
  globalRefresh,
}) => {
  //add pre filled data if first establishment
  let addingDefault = false;
  if (
    !myCompanyEstablishments ||
    !myCompanyEstablishments.establishments ||
    !Array.isArray(myCompanyEstablishments?.establishments) ||
    myCompanyEstablishments?.establishments.length === 0
  ) {
    addingDefault = true;
  }

  const [expanded, setExpanded] = React.useState(addingDefault);

  const {
    data: estData,
    // refetch: estRefetch,
    // loading: estLoading,
    // networkStatus: estNetworkStatus,
  } = useGetMyCompanyQuery({
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });
  const myCompany = estData?.companies[0];

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={(e, isExpanded) => setExpanded(isExpanded)}
        style={{ marginTop: "1rem" }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{T("Add new establishment")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {(!addingDefault || myCompany) && (
            <div style={{ width: "min(100%, 1000px)" }}>
              <EditTable
                operation={"add"}
                addEditEstablishment={addEstablishment}
                closeForm={() => setExpanded(false)}
                data={Object.assign(
                  {
                    id: "",
                    name: "",
                    address_street: "",
                    address_city: "",
                    address_postcode: "",
                    phone_number: "",
                    website: "",
                    photo_url: "",
                    email: "",
                    facebook: "",
                    instagram: "",
                  },
                  addingDefault ? myCompany : {}
                )}
                usersData={usersData}
                phone_numbersData={phone_numbersData}
                phone_numbers_establishments={phone_numbers_establishments}
                establishments_users={establishments_users}
                globalRefresh={globalRefresh}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
