import * as React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { UserType, UserEstablishmentType } from "./types";
import { yup } from "../../utils/yup";
import { Form } from "../../sharedComponents/Form";
import { TextInput } from "../../sharedComponents/forms";
import { T } from "../../utils/translate";
import { MyTableCell } from "../../style/styledComponents/table";
import { UserEstablishment } from "./UserEstablishment";

import {
  useUpdateUserEstablishmentsMutation,
  useUpdateUserPhoneNumbersMutation,
} from "../../graphql";

import { AddEditEstablishment } from "../Establishment/types";
import { UserPhoneNumber } from "./UserPhoneNumber";
import { Role } from "../Role/types";
import { Phone_numberType, Phone_numberUserType } from "../PhoneNumber/types";
import { HOST_ROLE, UNVERIFIED_ROLE } from "../../utils/constants";
import { Select } from "../../sharedComponents/forms";
import { ImgMaster } from "../../sharedComponents/CropUploadComponent/ImgMaster";
import cloneDeep from "lodash/cloneDeep";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

interface EditTableProps {
  addEditUser: any;
  editingId?: string;
  closeForm?: () => void;
  data: UserType; // data for the form we are editing
  operation: string;
  userEstablishmentsData?: { establishments_users: UserEstablishmentType[] };
  phone_numbersData: { phone_numbers: Phone_numberType[] };
  globalRefresh?: Function;
  phone_numbers_users: Phone_numberUserType[];
  establishments_users?: UserEstablishmentType[];
  establishmentsData?: { establishments: AddEditEstablishment[] };
  myCompanyRoles: { roles: Role[] };
  resendEmail?: (id: string, email: string) => void;
  removeUser?: (id: string) => void;
}

const FORM_VALIDATION = yup.object().shape({
  /*.required("Please enter website"),*/
  first_name: yup.string(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
});

export const EditTable: React.FC<EditTableProps> = ({
  addEditUser,
  editingId,
  closeForm,
  data,
  operation,
  phone_numbersData,
  globalRefresh,
  phone_numbers_users,
  establishmentsData,
  myCompanyRoles,
  userEstablishmentsData,
  resendEmail,
  removeUser,
}) => {
  const [selectedEstablishments, setSelectedEstablishments] = React.useState(
    []
  );
  const [phoneNumbersToSend, setPhoneNumbersToSend] = React.useState([]);
  const [role, setRole] = React.useState(
    data?.role?.id ? data?.role?.id : HOST_ROLE
  );
  const [disabled, setDisabled] = React.useState(
    data?.disabled === true ? "Disabled" : "Enabled"
  );
  const [photoUrls, setPhotoUrls] = React.useState([]);
  const [isPublicEmail, setIsPublicEmail] = React.useState(
    data?.is_public_email
  );

  React.useEffect(() => {
    try {
      const parsed = JSON.parse(data.photo_url);
      if (Array.isArray(parsed)) {
        setPhotoUrls(JSON.parse(data.photo_url));
      }
    } catch (e) {
      //   console.log("photo url parse try error");
    }
  }, [data.photo_url]);

  React.useEffect(() => {
    setIsPublicEmail(data.is_public_email);
  }, [data.is_public_email]);

  console.log("START data", data);
  console.log(
    "START establishments_users",
    userEstablishmentsData?.establishments_users
  );
  const DEFAULT_VALUES = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    role_id: data.role_id,
    disabled: data.disabled,
    is_public_email: data.is_public_email,
  };

  const [
    deleteAndInsertUsersPhone_numbers,
    // { error: errorPnu, data: dataPhu },
  ] = useUpdateUserPhoneNumbersMutation({
    errorPolicy: "all",
    onCompleted(answer) {
      console.log("deleted and insert p n e done", answer);
      // setEdit(false);
      // companyRefetch();
      //phone_numberUserRefetch(); todo
    },
  });

  const [
    deleteAndInsertUsersEstablishments,
    // { error: errorUI, data: dataUI },
  ] = useUpdateUserEstablishmentsMutation({
    errorPolicy: "all",
    onCompleted(answer) {
      console.log("deleted and insert users ests  done", answer);
      // setEdit(false);
      // globalRefresh();
    },
  });

  const submitForm = async (data, reset) => {
    console.log("submitForm data", data, role);
    try {
      const addEditResult = await addEditUser({
        variables: {
          id: editingId,
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          role_id: role,
          disabled: Boolean(disabled === "Disabled"),
          is_public_email: isPublicEmail,
          photo_url: JSON.stringify(photoUrls),
        },
      });

      if (!editingId) {
        if (addEditResult?.data?.insert_users_one?.id) {
          editingId = addEditResult.data.insert_users_one.id;
        }
      }

      if (editingId) {
        //saving users_establishments separately
        const objects = selectedEstablishments.map((se) => ({
          establishment_id: se.id,
          user_id: editingId,
        }));

        console.log("INSERTING selectedEst", objects, typeof objects);

        deleteAndInsertUsersEstablishments({
          variables: {
            objects,
            user_id: editingId,
          },
        });

        console.log(">>>phoneNumbersToSend", phoneNumbersToSend);
        const userObjects = phoneNumbersToSend.map((toSend) => ({
          user_id: editingId,
          phone_number_id: toSend.id,
        }));

        console.log("userObjects", userObjects);

        deleteAndInsertUsersPhone_numbers({
          variables: {
            objects: userObjects,
            user_id: editingId,
          },
        });
      }

      if (operation == "add") {
        reset();
      }
      if (closeForm) {
        closeForm();
      }
    } catch (e) {
      if (closeForm) {
        closeForm();
      }
      console.warn("Saving data failed.", e);
      alert(T("Saving data failed."));
    }
  };

  const onFileChange = (photo) => {
    setPhotoUrls([photo]);
  };
  const onFileRemove = (photo) => {
    const photos = cloneDeep(photoUrls).filter((x) => x !== photo);
    setPhotoUrls(photos);
  };

  return (
    <Form
      className={"form"}
      defaultValues={DEFAULT_VALUES}
      validationSchema={FORM_VALIDATION}
      onSubmit={(data, reset) => submitForm(data, reset)}
    >
      <Table>
        <TableBody>
          <TableRow key={1}>
            <MyTableCell>
              <TextInput autohook name="first_name" label={T("First name")} />
            </MyTableCell>
            <MyTableCell>
              <TextInput autohook name="last_name" label={T("Last name")} />
            </MyTableCell>
          </TableRow>
          <TableRow>
            <MyTableCell>
              <TextInput autohook name="email" label={T("Email")} />
            </MyTableCell>
            <MyTableCell>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPublicEmail}
                    onChange={() => setIsPublicEmail(!isPublicEmail)}
                    name="is_public_email"
                    color="primary"
                  />
                }
                label={T("Email is public")}
              />
            </MyTableCell>
          </TableRow>

          <TableRow>
            <MyTableCell>
              {myCompanyRoles?.roles && (
                <Select
                  label={T("Role")}
                  options={myCompanyRoles?.roles.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                  value={{
                    value: role,
                    label: myCompanyRoles?.roles.find((r) => r.id === role)
                      ?.name,
                  }}
                  onChange={(newValue: { value: string; label: string }) => {
                    setRole(newValue.value);
                  }}
                />
              )}
            </MyTableCell>
            <MyTableCell>
              {operation === "edit" && (
                <Select
                  label={T("Enabled")}
                  options={[
                    { value: "Enabled", label: T("Active") },
                    { value: "Disabled", label: T("Deactivated") },
                  ]}
                  value={
                    disabled === "Enabled"
                      ? { value: "Enabled", label: T("Active") }
                      : { value: "Disabled", label: T("Deactivated") }
                  }
                  onChange={(newValue: { value: string; label: string }) => {
                    setDisabled(newValue.value);
                  }}
                />
              )}
            </MyTableCell>
          </TableRow>
        </TableBody>
      </Table>

      <UserPhoneNumber
        allPhone_numbers={phone_numbersData?.phone_numbers.map((pn) => ({
          name: `${pn.name} (${pn.phone_number})`,
          id: pn.id,
        }))}
        allUsersPhone_numbers={phone_numbers_users}
        setPhone_numbersToSend={setPhoneNumbersToSend}
        editingId={editingId}
        globalRefresh={globalRefresh}
        usedNumbers={
          operation === "edit"
            ? phone_numbers_users
                ?.filter((pnu) => pnu.user?.id !== editingId)
                ?.map((pnu) => pnu.phone_number?.id)
            : phone_numbers_users?.map((pnu) => pnu.phone_number?.id)
        }
      />
      <Typography
        variant="subtitle1"
        style={{ marginBottom: 8, marginTop: -12, fontSize: "0.8rem" }}
      >
        {T("Calls will show up with the employees name and photo")}
      </Typography>

      <UserEstablishment
        allEstablishments={establishmentsData?.establishments.map((e) => ({
          name: e.name,
          id: e.id,
        }))}
        allUsersEstablishments={userEstablishmentsData?.establishments_users}
        setEstablishmentsToSend={setSelectedEstablishments}
        editingId={editingId}
      />

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexWrap: "wrap-reverse",
        }}
      >
        <div style={{ marginTop: 15 }}>
          <ImgMaster
            operation={operation}
            onFileChange={onFileChange}
            onFileRemove={onFileRemove}
            photoUrls={photoUrls}
            titleText={T("Set your profile photo")}
          />
        </div>
        {operation === "edit" && (
          <div style={{ marginTop: 15 }}>
            <Typography variant="subtitle2" gutterBottom>
              {T("User status")}
            </Typography>

            {data.active ? (
              <Alert severity="success"> {T("User activated")}</Alert>
            ) : (
              <div>
                <Alert severity="warning">
                  {T("Waiting for email verification")}
                </Alert>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resendEmail(editingId, data.email)}
                >
                  {T("Resend activation email")}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <div style={{ height: 30 }} />

      <Button size="small" variant="contained" color="primary" type="submit">
        {operation === "add" ? T("Add") : T("Save")}
      </Button>
      {operation === "edit" && (
        <Button size="small" onClick={() => closeForm()}>
          {T("Cancel")}
        </Button>
      )}
      {editingId && removeUser && (
        <Button
          size="small"
          // @ts-ignore
          variant="delete"
          style={{ float: "right" }}
          onClick={(e) => {
            if (!window.confirm("Are you sure you want to delete this user?")) {
              return;
            }
            if (!editingId) {
              return;
            }
            removeUser(editingId);
            if (closeForm) {
              closeForm();
            }
          }}
        >
          {T("Delete")}
        </Button>
      )}
    </Form>
  );
};
