import * as React from "react";

import { T } from "../../utils/translate";

import { ComboBoxMulti } from "../../sharedComponents/forms/ComboBoxMulti";
import { EstUser, NameIdUserType } from "./types";

interface EstablishmentUserProps {
  editingId?: string | undefined;
  allUsers: NameIdUserType[];
  setUsersToSend: Function;
  allEstablishmentsUsers: EstUser[];
}

export const EstablishmentUser: React.FC<EstablishmentUserProps> = ({
  allUsers,
  allEstablishmentsUsers,
  editingId,
  setUsersToSend,
}) => {
  //   console.log("allUsers", allUsers);
  //   console.log("allUsersUsers", allEstablishmentsUsers);
  //   console.log("editingId", editingId);
  const [
    selectedEstablishmentsUsers,
    setSelectedEstablishmentsUsers,
  ] = React.useState([]);

  React.useEffect(() => {
    if (allEstablishmentsUsers && allEstablishmentsUsers.length) {
      const newValues = allEstablishmentsUsers
        .filter((x) => x.establishment?.id === editingId)
        .map((x) => x.user)
        .map((u) => ({
          id: u.id,
          name: u.first_name + " " + u.last_name + " (" + u.email + ")",
        }));
      //   console.log("(filter, map) newValues", newValues);
      setSelectedEstablishmentsUsers(newValues);
      setUsersToSend(newValues);
    }
  }, [allEstablishmentsUsers]);

  //to update parent
  const onChangeValue = (newValues) => {
    setSelectedEstablishmentsUsers(newValues);
    setUsersToSend(newValues);
  };

  //   console.log(
  //     "(default) selectedEstablishmentsUsers",
  //     selectedEstablishmentsUsers
  //   );
  return (
    <ComboBoxMulti
      options={allUsers}
      defaultValues={selectedEstablishmentsUsers}
      onChangeValue={onChangeValue}
      placeholder={T("Users")}
    />
  );
};
