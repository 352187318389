import React from "react";

import MuiMenu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
    width: 200,
  },
}));

export const Menu: React.FC<{
  render: (handleClose: () => void) => React.ReactElement[];
}> = ({ render }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!render) {
    console.error("Render fn for Menu must exist!");
    return null;
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-controls={"login-menu"}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <AccountCircleIcon fontSize="large" />
      </IconButton>
      <MuiMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {render(handleClose)}
      </MuiMenu>
    </div>
  );
};
