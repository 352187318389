import * as React from "react";

import {
  Typography,
  TableContainer,
  Paper,
  makeStyles,
  createStyles,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Payment } from "./types";
import EditModal from "../../sharedComponents/EditModal";
import { HeaderWithRefresh } from "../../style/styledComponents/components";
import { Payment as PaymentComponent } from "../Payment/Payment";

export const MyTableCell = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const MyPhotoTableCell = styled(MyTableCell)`
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0px;
`;

export const MyTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const ThumbnailImg = styled.img`
  ${(props) => props.height && `height: ${props.height};`}
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

interface PaymentsSelectProps {
  myCompanyPayments: { payments: Payment[] };
  paymentsLoading: any;
  pageCount: number;
  page: number;
  setPage: Function;
  globalRefresh: any;
}

type Anchor = "top" | "left" | "bottom" | "right";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
        fontSize: 20,
      },
    },
  })
);

export const PaymentsSelect: React.FC<PaymentsSelectProps> = ({
  myCompanyPayments,
  paymentsLoading,
  pageCount,
  page,
  setPage,
  globalRefresh,
}) => {
  const [editingId, setEditingId] = React.useState("");

  const tableHeader = ["Amount", "Paid", "Description", "Date created"];

  const handleChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const renderPaymentsTable = (payments?: Payment[]) => {
    return (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeader.map((th, i) => (
                  <MyTableCell key={i}>{th}</MyTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {payments?.map(
                ({
                  amount,
                  currency_code,
                  date_created,
                  date_paid,
                  description,
                  id,
                  key,
                  paid,
                  quick_code,
                  quick_code_expires,
                }) => {
                  return (
                    <MyTableRow key={id} onClick={(e) => setEditingId(id)}>
                      <MyTableCell>
                        {amount} {currency_code}
                      </MyTableCell>

                      <MyTableCell>
                        {paid ? <CheckCircleIcon /> : <CancelIcon />}
                      </MyTableCell>
                      <MyTableCell> {description} </MyTableCell>
                      <MyTableCell>
                        {new Date(date_created).toUTCString()}
                      </MyTableCell>
                    </MyTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Pagination
            count={pageCount}
            shape="rounded"
            size="large"
            page={page}
            onChange={handleChange}
          />
        </div>
      </React.Fragment>
    );
  };

  const editedPayment = myCompanyPayments?.payments?.find(
    (p) => p.id === editingId
  );

  return (
    <div>
      <HeaderWithRefresh>
        <div>
          <Typography variant="h2">Payments</Typography>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 6, marginTop: -8 }}
          >
            Payments for your company will be shown here.
          </Typography>
        </div>
        <IconButton onClick={() => globalRefresh()}>
          <RefreshIcon />
        </IconButton>
      </HeaderWithRefresh>

      <div style={{ marginBottom: "1rem" }}>
        <Link to="/create-invoice">
          <Button size="medium" variant="contained" color="primary">
            Create new payment
          </Button>
        </Link>
      </div>

      {renderPaymentsTable(myCompanyPayments?.payments)}

      <EditModal
        title={"Payment detail"}
        editObject={editedPayment}
        onClose={() => setEditingId("")}
        editComponent={(editObject, closeForm) => (
          <PaymentComponent originalPayment={editObject} back={false} />
        )}
        width={700}
      />
    </div>
  );
};
