import React from "react";

import { authenticationService } from "../../services/authentication";
import {
  useGetEstablishmentsQuery,
  useInsertUserMutation,
  useRemoveUserMutation,
  useGetUsersQuery,
  useInsertUserVerificationRequestMutation,
  useUpdateUserMutation,
  useGetEstablishmentsUserQuery,
  useGetRolesQuery,
  useGetPhoneNumbersQuery,
  useGetPhoneNumbersUsersQuery,
} from "../../graphql";

import Typography from "@material-ui/core/Typography";
import { T } from "../../utils/translate";
import { yup } from "../../utils/yup";

import { UserSelect } from "./UserSelect";
import { UserAdd } from "./UserAdd";

export const User: React.FC<{}> = () => {
  const {
    data: usersData,
    refetch: usersRefetch,
    loading: usersLoading,
    // networkStatus: usersNetworkStatus,
  } = useGetUsersQuery();

  const { data: myCompanyRoles, refetch: mcrRefetch } = useGetRolesQuery();

  const [
    addVerificationRequest,
    // { error: errorAddVerificationRequest, data: dataAddVerificationRequest },
  ] = useInsertUserVerificationRequestMutation({
    errorPolicy: "all",
    onCompleted: async ({ insert_verification_requests_one }) => {
      try {
        await authenticationService.createActivationRequest(
          insert_verification_requests_one.id,
          insert_verification_requests_one.email
        );
        alert(
          T(
            "New email was set successfully. Continue by setting up your password. Instructions were sent to your email."
          )
        );
      } catch (e) {
        console.warn("error", e);
      }
    },
  });

  const {
    //duplicated in Establishment
    data: userEstablishmentsData, //these are establishments belonging to this user
    refetch: userEstRefetch,
    // loading: userEstLoading,
    // error: userEstError,
    // networkStatus: userEstNetworkStatus,
  } = useGetEstablishmentsUserQuery({
    //GET_USER_EST original
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    onCompleted(answer) {
      console.log(
        "user est data answer",
        answer,
        Boolean(answer),
        answer.establishments_users,
        answer?.establishments_users.length,
        Boolean(answer.establishments_users.length),
        Boolean(answer && answer.establishments_users.length)
      );

      //I dont set selectedEst here. I do it when editingId is changed,
      //so in selectedEst are only establishments_users for given user (that is being edited)

      //   if (answer) {
      //     if (answer.establishments_users.length) {
      //       setSelectedEst(
      //         answer?.establishments_users.map((eu) => eu.establishment)
      //       );
      //     }
      //   } else {
      //     console.error("there should be establishments_users");
      //   }
    },
  });

  const {
    //duplicated in establishment.tsx, later connect it via Mobex?
    data: establishmentsData, //all est of company that user could be linked to
    refetch: estRefetch,
    loading: estLoading,
    // networkStatus: estNetworkStatus,
  } = useGetEstablishmentsQuery({
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: phone_numbersData, //duplicated from phone numbers
    refetch: phone_numbersRefetch,
    // loading: phone_numbersLoading,
    // networkStatus: phone_numbersNetworkStatus,
  } = useGetPhoneNumbersQuery();

  const {
    data: phone_numberUserData, //these are all phone_numbers linked to  users - I get them all
    refetch: phone_numberUserRefetch,
    // loading: phone_numberUserDataLoading,
    // error: phone_numberUserDataError,
    // networkStatus: phone_numberUserDataNetworkStatus,
  } = useGetPhoneNumbersUsersQuery();

  const globalRefresh = () => {
    console.log("globalRefresh");
    usersRefetch();
    mcrRefetch();
    userEstRefetch();
    estRefetch();
    phone_numbersRefetch();
    phone_numberUserRefetch();
  };

  const [
    editUser /*{ error: errorEditUser, data: dataEditUser }*/,
  ] = useUpdateUserMutation({
    onCompleted(answer) {
      console.log("update_users_by_pk", answer);
      if (!answer?.update_users_by_pk) {
        console.warn("Users not updated.");
        alert(T("Users not updated. Do you have a permission?"));
      } else {
        setTimeout(() => globalRefresh(), 300);
      }
    },
  });

  const [
    addUser /*{ error: errorAddUser, data: dataAddUser }*/,
  ] = useInsertUserMutation({
    onCompleted(data) {
      globalRefresh();
      const insert_users_one = data.insert_users_one;
      try {
        addVerificationRequest({
          variables: {
            user_id: insert_users_one.id,
            email: insert_users_one.email,
          },
        });
      } catch (e) {
        console.warn("error", e);
      }
      return data;
    },
  });

  const [removeUser] = useRemoveUserMutation({
    onCompleted(data) {
      globalRefresh();
    },
  });

  const resendEmail = (id, email) => {
    if (!yup.string().email().isValidSync(email)) {
      alert(T("invalid_email"));
      return;
    }
    addVerificationRequest({
      variables: {
        user_id: id,
        email,
      },
    });
  };

  if (usersLoading) {
    return (
      <>
        <Typography variant="h2">{T("Users")}</Typography>
      </>
    );
  }

  return (
    <div>
      <UserSelect
        editUser={editUser}
        usersData={usersData}
        phone_numbersData={phone_numbersData}
        phone_numbers_users={phone_numberUserData?.phone_numbers_users}
        userEstablishmentsData={userEstablishmentsData}
        globalRefresh={globalRefresh}
        establishmentsData={establishmentsData}
        myCompanyRoles={myCompanyRoles}
        resendEmail={resendEmail}
        removeUser={(id) => removeUser({ variables: { id } })}
      />

      <UserAdd
        addUser={addUser}
        usersData={usersData}
        phone_numbersData={phone_numbersData}
        phone_numbers_users={phone_numberUserData?.phone_numbers_users}
        establishmentsData={establishmentsData}
        myCompanyRoles={myCompanyRoles}
        globalRefresh={globalRefresh}
      />
    </div>
  );
};
