import * as React from "react";
import { authenticationService } from "../../services/authentication";
import { T } from "../../utils/translate";
import { Redirect } from "react-router-dom";

export const Start: React.FC<{
  location: any;
}> = ({ location }) => {
  const [redirectVerification, setRedirectVerification] = React.useState(false);

  const verifyHash = () => {
    if (location.search.indexOf("?ad=") == 0) {
      authenticationService
        .adminAccess(location.search.split("?ad=")[1])
        .then((data: any) => {
          // Handled in authenticationService
        })
        .catch((error: string) => {
          // TODO error messages
          alert(T(error));
        });
      return;
    }
    if (location.search.indexOf("?app=") == 0) {
      authenticationService
        .appAccess(location.search.split("?app=")[1])
        .then((data: any) => {
          // Handled in authenticationService
        })
        .catch((error: string) => {
          if (error == "verification_pending") {
            setRedirectVerification(true);
          } else {
            alert(T(error));
          }
        });
      return;
    }

    const hashpass = location.search.split("?h=")[1];
    authenticationService
      .hashAccess(hashpass)
      .then((data: any) => {
        // Handled in authenticationService
      })
      .catch((error: string) => {
        // TODO error messages
        alert(T(error));
      });
  };

  React.useEffect(() => {
    verifyHash();
  }, []);

  if (redirectVerification) {
    return <Redirect to="/verification" />;
  }

  return null;
};
