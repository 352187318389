import { CategoryDetailType } from "./types";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
// import { MyXTableCell, NBTableCell, VenuePhoto } from "./StyledComponents";
import React from "react";
import { yup } from "../../utils/yup";
import { Form } from "../../sharedComponents/Form";
import { TextInput } from "../../sharedComponents/forms";
import { T } from "../../utils/translate";

interface CategoryDetailProps {
  data: CategoryDetailType;
  editingId: string;
  closeForm: Function;
  editCategory: Function;
}

const FORM_VALIDATION = yup.object().shape({
  name: yup.string().required(),
});

export const CategoryDetail: React.FC<CategoryDetailProps> = ({
  data,
  editingId,
  closeForm,
  editCategory,
}) => {
  if (!data) {
    if (closeForm) {
      closeForm();
    }
    return null;
  }

  const DEFAULT_VALUES = {
    name: data.name,
    id: data.id,
  };

  const submitForm = async (editedData, reset) => {
    console.log("submit form ", editedData);
    try {
      await editCategory({
        variables: {
          name: editedData.name,
          id: editedData.id,
        },
      });
      reset();
      if (closeForm) {
        closeForm();
      }
    } catch (e) {
      if (closeForm) {
        closeForm();
      }
      console.warn("Editing establishment failed.", e);
      alert(T("Saving data failed."));
    }
  };

  return (
    <div>
      <Form
        className={"form"}
        defaultValues={DEFAULT_VALUES}
        validationSchema={FORM_VALIDATION}
        onSubmit={(editedData, reset) => submitForm(editedData, reset)}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <TextInput autohook name="name" label={T("Name")} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextInput autohook name="id" label={"Id"} disabled />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    if (closeForm) {
                      closeForm();
                    }
                  }}
                >
                  Close
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Form>
    </div>
  );
};
