import React, { useState, useRef } from "react";
import { CropModal } from "./CropModal";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { datagetterService } from "../../services/datagetter";
import { T } from "../../utils/translate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      //   height: "100%",
      overflow: "auto",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

const CropUploadComponent: React.FC<{
  onFileChange: Function;
  operation: string;
  setUploading: Function;
  noCrop?: boolean;
}> = ({ onFileChange, operation, setUploading, noCrop = false }) => {
  const classes = useStyles();
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState({ preview: undefined, file: null });
  const cropper = useRef();
  const [open, setOpen] = useState(false);

  const onChange = (e) => {
    //initial upload into img shown in modal
    e.preventDefault();
    let files;
    if (e.dataTransfer) files = e.dataTransfer.files;
    else if (e.target) files = e.target.files;

    if (noCrop) {
      const formData = new FormData();
      console.log(files[0]);
      formData.append("img-edit", files[0]);
      uploadFile(formData);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setSrc(reader.result);
      setImage({ preview: reader.result, file: files[0] });
      setOpen(true);
    };
    reader.readAsDataURL(files[0]);
  };

  const sendToServer = async () => {
    //cropped img from modal to server
    setUploading(true);
    const formData = new FormData();
    setOpen(false);
    const blob = await fetch(image.preview).then((r) => r.blob());
    formData.append("img-edit", blob);
    await uploadFile(formData);
  };

  const uploadFile = async (formData) => {
    datagetterService
      .fileUpload(formData)
      .then((data) => {
        // @ts-ignore
        const photoUrl = data.cloudinaryPhotoString;
        setUploading(false);
        onFileChange(photoUrl);
      })
      .catch((error) => {
        alert(error);
      });
  };

  if (open) {
    return (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={{ outline: "none" }}>
            <CropModal
              src={src}
              cropper={cropper}
              setImage={setImage}
              image={image}
              sendToServer={sendToServer}
            />
          </div>
        </Fade>
      </Modal>
    );
  }

  return (
    <div>
      <input
        accept="image/*"
        // className={classes.input}
        id={`file-0-${operation}`}
        multiple
        type="file"
        onChange={onChange}
        name="myfile"
        style={{ display: "none" }}
      />
      <label htmlFor={`file-0-${operation}`}>
        <Button
          // @ts-ignore
          variant="contained"
          color="primary"
          component="span"
          style={{ textAlign: "center", lineHeight: 1.4 }}
          startIcon={<CloudUploadIcon />}
        >
          {" " + T("Upload image")}
        </Button>
      </label>
    </div>
  );
};

export default CropUploadComponent;
