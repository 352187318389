import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import { Menu } from "./Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { authenticationService } from "./../services/authentication";

import { T } from "./../utils/translate";
import logo from "./logo.svg";

const UserCompany = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  text-align: right;
  margin-right: 15px;
  text-decoration: none;
`;

const User = styled.div`
  font-weight: bold;
`;
const Company = styled.div``;

const Header: React.FC<{ username: string; company: string }> = ({
  username,
  company,
}) => {
  return (
    <>
      <div
        style={{ flexGrow: 1 }}
      >
        <Link to="/"><img src={logo} style={{ height: "40px" }} /></Link>
      </div>
      <Hidden xsDown implementation="css">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <UserCompany>
            <Link to="/profile" key={"profile"}>
              <User>{username}</User>
            </Link>
            <Link to="/company" key={"company"}>
              <Company>{company}</Company>
            </Link>
          </UserCompany>
          <Menu
            render={(handleClose) => [
              <Link
                key={"profile"}
                to="/profile"
                style={{
                  textDecoration: "none",
                }}
                onClick={handleClose}
              >
                <MenuItem>{T("Profile")}</MenuItem>
              </Link>,
              <Link
                key={"change-password"}
                to="/change-password"
                style={{
                  textDecoration: "none",
                }}
                onClick={handleClose}
              >
                <MenuItem>{T("Change password")}</MenuItem>
              </Link>,
              <MenuItem
                key={"logout"}
                onClick={() => authenticationService.logout()}
              >
                {T("Log out")}
              </MenuItem>,
            ]}
          />
        </div>
      </Hidden>
    </>
  );
};

export default Header;
