import React, { useState, useEffect } from "react";

import {
  Typography,
  Card,
  Button,
  IconButton,
  Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { observe, dispatch } from "src/utils/eventBus";

import {
  useShoptetImportContactsMutation,
  useInsertSmsTemplateMutation,
  useGetShoptetEshopsQuery,
  useGetCompanyDicQuery,
  useGetShoptetSmsTemplatesQuery,
  GetShoptetSmsTemplatesQuery,
  useUpdateShoptetEshopsDicMutation,
  useDeleteSmsTemplateMutation,
} from "src/graphql";
import { ConnectedUserType, TemplateType } from "../types";
import { TextInput, Select } from "src/sharedComponents/forms";

import { T } from "src/utils/translate";

const CONDITION_VARIABLES = {
  CASH_ON_DELIVERY: "1",
  BANK_TRANSFER: "2",
  PAY_IN_CASH: "3",
  PAY_BY_CARD: "4",
  TRUE: "True",
  FALSE: "False",
};

const CONDITION_STATEMENTS = {
  STATEMENTS_WITH_EXACT_NAMES: ["ORDER_PAID", "PAYMENT_TYPE"],
  STATEMENTS_WITHOUT_EXACT_NAMES: [
    "DELIVERY_TYPE",
    "COUNTRY_CODE",
    "LANGUAGE_CODE",
  ],
};

const EditForm: React.FC<{
  values?: GetShoptetSmsTemplatesQuery;
  insertQuery?: any;
}> = ({ values, insertQuery }) => {
  const [newTemplateEventType, setNewTemplateEventType] = useState("");
  const [newTemplateStateName, setNewTemplateStateName] = useState("");
  const [newTemplateName, setNewTemplateName] = useState("");
  const [newTemplateText, setNewTemplateText] = useState("");
  const [newTemplateCondition, setNewTemplateCondition] = useState("");
  const [conditionError, setConditionError] = useState<boolean | String>(false);

  const selectOptions = [
    { label: T("sms_status_order_created"), value: "created" },
    { label: T("sms_status_order_pending"), value: "-1" },
    { label: T("sms_status_order_in_process"), value: "-2" },
    { label: T("sms_status_order_completed"), value: "-3" },
    { label: T("sms_status_order_canceled"), value: "-4" },
    { label: T("sms_status_custom"), value: "custom" },
  ];

  const handleNewTemplate = () => {
    if (
      !newTemplateEventType ||
      !newTemplateName ||
      !newTemplateText ||
      (newTemplateEventType === "custom" && !newTemplateStateName)
    )
      return;
    const condition_text_array = [];
    const condition_text_splitted = newTemplateCondition.split(/\r?\n/);
    // console.log(condition_text_splitted);
    for (const variable of condition_text_splitted) {
      let validCondition = false;
      for (const condition of CONDITION_STATEMENTS.STATEMENTS_WITH_EXACT_NAMES) {
        if (variable.startsWith(condition)) {
          validCondition = true;
          let newCondition: any = variable
            .replace(condition, "")
            .replace("=", "")
            .replace(/ /g, "")
            .split(",");
          // console.log(newCondition);
          newCondition = newCondition
            .filter((item: string) => item !== "")
            .map((it: string) => it.trim());
          for (const variable of newCondition) {
            if (!(variable in CONDITION_VARIABLES)) {
              if (variable !== "") {
                setConditionError(variable);
                return;
              }
            }
          }
          newCondition = newCondition.join(",");
          for (const [key, value] of Object.entries(CONDITION_VARIABLES)) {
            newCondition = newCondition.replace(key, value);
          }

          condition_text_array.push({
            [condition.toLowerCase()]: newCondition,
          });
        }
      }
      if (!validCondition) {
        const custom_name_condition = variable.split("=")[0].trim();
        if (variable.includes("=")) {
          const custom_name_variable = variable.split("=")[1].trim();
          if (
            CONDITION_STATEMENTS.STATEMENTS_WITHOUT_EXACT_NAMES.includes(
              custom_name_condition
            )
          ) {
            // console.log(condition_text_array);
            condition_text_array.push({
              [custom_name_condition.toLowerCase()]: custom_name_variable,
            });
          }
        }
        if (
          variable.trim() !== "" &&
          !CONDITION_STATEMENTS.STATEMENTS_WITHOUT_EXACT_NAMES.includes(
            custom_name_condition
          )
        ) {
          setConditionError(variable.trim());
          return;
        }
      }
    }

    let status_type = "system";
    if (newTemplateEventType === "custom") {
      status_type = "custom";
    }
    let order_event_type = "order:update";
    if (newTemplateEventType === "created") {
      order_event_type = "order:create";
    }
    const variables = {
      special_type: "shoptet",
      name: newTemplateName,
      text: newTemplateText,
      condition: {
        order_status: {
          status_id: newTemplateEventType,
          status_name: newTemplateStateName,
          status_type,
        },
        order_event_type,
        condition_text: condition_text_array,
        original_condition_text: newTemplateCondition,
      },
    };
    console.log(variables);
    insertQuery({
      variables: {
        special_type: "shoptet",
        name: newTemplateName,
        text: newTemplateText,
        condition: {
          order_status: {
            status_id: newTemplateEventType,
            status_name: newTemplateStateName,
            status_type,
          },
          order_event_type,
          condition_text: condition_text_array,
          original_condition_text: newTemplateCondition,
        },
      },
    });

    setNewTemplateEventType("");
    setNewTemplateStateName("");
    setNewTemplateName("");
    setNewTemplateText("");
    setNewTemplateCondition("");
    setConditionError(false);
  };

  return (
    <div
      style={{
        padding: "1rem",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleNewTemplate();
        }}
      >
        <Select
          label={T("Event type")}
          options={selectOptions}
          value={selectOptions.find((it) => it.value === newTemplateEventType)}
          onChange={(newValue: { value: string; label: string }) =>
            setNewTemplateEventType(newValue.value)
          }
        />
        {newTemplateEventType === "custom" ? (
          <TextInput
            label={T("Custom state name")}
            name="custom_state_name"
            value={newTemplateStateName}
            onChange={(value) => setNewTemplateStateName(value)}
          />
        ) : null}
        <TextInput
          label={T("Name")}
          name="name"
          value={newTemplateName}
          onChange={(value) => setNewTemplateName(value)}
        />
        <TextInput
          label={T("Text")}
          name="text"
          value={newTemplateText}
          onChange={(value) => setNewTemplateText(value)}
          textarea={true}
        />
        <TextInput
          error={conditionError ? true : false}
          helperText={
            conditionError
              ? T("Incorrect condition") + ": " + conditionError
              : null
          }
          label={T("Sending conditions")}
          name="sending_conditions"
          value={newTemplateCondition}
          onChange={(value) => setNewTemplateCondition(value)}
          textarea={true}
        />
        <Button type="submit" variant="contained">
          {T("Add")}
        </Button>
      </form>
    </div>
  );
};

export const Shoptet: React.FC<{
  connectedUsers: ConnectedUserType[];
  templates?: TemplateType[];
}> = ({ connectedUsers, templates }) => {
  const queryShoptetDic = useGetShoptetEshopsQuery();
  const queryShoptetTemplates = useGetShoptetSmsTemplatesQuery();
  const queryCompanyDic = useGetCompanyDicQuery();
  const [deleteTemplate] = useDeleteSmsTemplateMutation({
    onCompleted: () => {
      dispatch("centrumsms_templatesRefresh");
      dispatch("shoptet_templatesRefresh");
    },
  });

  const [insertTemplate] = useInsertSmsTemplateMutation({
    onCompleted: () => {
      dispatch("centrumsms_templatesRefresh");
      dispatch("shoptet_templatesRefresh");
    },
  });
  const [updateShoptetDic] = useUpdateShoptetEshopsDicMutation({
    onCompleted: (data) => {
      if (data?.update_shoptet_eshops?.returning) {
        queryShoptetDic.refetch();
      } else {
        alert(T("Shoptet connection failed"));
      }
    },
  });
  const [importContacts] = useShoptetImportContactsMutation({
    onCompleted: (data) => {
      if (data?.shoptet_import_contacts?.success) {
        alert(T("Done"));
      } else {
        alert(T("Error occured"));
      }
    },
  });

  useEffect(() => {
    return observe("shoptet_templatesRefresh", () =>
      queryShoptetTemplates.refetch()
    );
  }, []);

  if (queryShoptetDic.loading) {
    return null;
  }
  const connected = queryShoptetDic.data?.shoptet_eshops?.length > 0;

  return (
    <>
      <Typography variant="h6">{T("Shoptet integration")}</Typography>
      {connected ? (
        <>
          <Button
            variant="contained"
            style={{ marginTop: "-3rem", float: "right" }}
            onClick={() => importContacts()}
          >
            {T("Import contacts")}
          </Button>
          <Card style={{ padding: "1rem" }}>
            <div>
              {T("Connected to eshop") +
                " " +
                queryShoptetDic?.data.shoptet_eshops[0].eshop_name}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: T("Shoptet info HTML") }}
            ></div>
          </Card>
          <Typography variant="h6" style={{ marginTop: "1em" }}>
            {T("Shoptet templates")}
          </Typography>
          <Card>
            <div style={{ maxHeight: "500px", overflow: "auto" }}>
              {queryShoptetTemplates.data?.smsapi_templates.map((it) => (
                <div
                  key={it.id}
                  style={{
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    paddingRight: 16,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="subtitle2">{it.name}</Typography>
                    <span>
                      {T("Text") + ": "} {it.text}
                    </span>
                    <span>
                      {T("Condition") + ": "}
                      {it.condition?.original_condition_text?.length > 0
                        ? it.condition?.original_condition_text
                        : T("Condition null")}
                    </span>
                  </div>
                  <IconButton
                    onClick={() => deleteTemplate({ variables: { id: it.id } })}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>
            <Divider />
            <EditForm
              values={queryShoptetTemplates?.data}
              insertQuery={insertTemplate}
            />
          </Card>
        </>
      ) : (
        <Card
          style={{ padding: "1rem", display: "flex", flexDirection: "column" }}
        >
          <div>{T("Connect to Shoptet info text")}</div>
          <Button
            variant="contained"
            style={{ margin: "1rem", alignSelf: "center" }}
            onClick={() =>
              updateShoptetDic({
                variables: {
                  dic: queryCompanyDic.data.companies[0].dic,
                  company_id: queryCompanyDic.data.companies[0].id,
                },
              })
            }
          >
            {T("Connect to Shoptet")}
          </Button>
        </Card>
      )}
    </>
  );
};
