import * as React from "react";

import {
  useGetTerminalTransactionsLazyQuery,
  useGetTerminalTransactionsQuery,
  useGetTerminalsQuery,
  useGetTransactionsQuery,
} from "../../graphql";
import { useQuery, useMutation } from "@apollo/client";
import { Typography, Button, IconButton, Card } from "@material-ui/core";
import { HeaderWithRefresh } from "src/style/styledComponents/components";
import { T } from "src/utils/translate";
import {
  DataTable,
  DateFilter,
  useDataTable,
} from "src/sharedComponents/table";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FileCopyIcon from "@material-ui/icons/FileCopyOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import * as XLSX from "xlsx/dist/xlsx.mini.min";
import { Link } from "react-router-dom";

export const PaymentTerminals: React.FC<{}> = () => {
  const [exportLoading, setExportLoading] = React.useState("");
  const terminalsQuery = useGetTerminalsQuery({
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });
  const terminalTransactionsQuery = useGetTerminalTransactionsQuery({
    variables: {
      pc3000_company_id: terminalsQuery.data?.terminals?.[0]?.pc3000_company_id,
      api_key: terminalsQuery.data?.terminals?.[0]?.api_key,
      offset: 0,
    },
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
    skip: terminalsQuery.loading || terminalsQuery.data?.terminals.length == 0,
  });
  const globalRefresh = () => {
    terminalTransactionsQuery.refetch();
  };
  const handleExport = async () => {
    const data = [];
    const totalPages = Math.ceil(
      terminalTransactionsQuery.data.info.totalCount / 10
    );
    for (let page = 0; page < totalPages; page++) {
      setExportLoading(" " + (page + 1) + "/" + totalPages);
      const res = await terminalTransactionsQuery.fetchMore({
        variables: {
          offset: page * 10,
        },
      });
      console.log(res.data.rows);
      res.data.rows.forEach((row) => {
        data.push({
          timestamp: new Date(
            Number(row.terminalTimestamp) * 1000
          ).toLocaleString(),
          id: row.id,
          posId: row.posId,
          transactionType: row.transactionType,
          amount: row.amount,
          currencyCode: row.currencyCode,
          successful: row.successful,
          responseText: row.responseText,
        });
      });
    }
    const sheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Contacts");
    XLSX.writeFile(workbook, "Telidoo_Transactions_Export.xlsx");
    setExportLoading("");
  };
  const newPaymentTerminal = () => {
    // TODO
  };

  const columns = React.useMemo(
    () => [
      {
        Header: T("Date"),
        accessor: "terminalTimestamp",
        Cell: ({ value }) => new Date(value * 1000).toLocaleString(),
        Filter: DateFilter(),
        filter: "date",
        width: 10,
      },
      {
        Header: "ID",
        accessor: "id",
        width: 10,
      },
      {
        Header: "POS",
        accessor: "posId",
        width: 10,
      },
      {
        Header: "Částka",
        id: "amount",
        accessor: (row) => row.amount + " " + row.currencyCode,
        width: 10,
      },
      {
        Header: "Úspěch",
        accessor: "successful",
        type: "boolean",
        Cell: ({ value }) =>
          value ? (
            <CheckCircleIcon style={{ opacity: 0.8, color: "green" }} />
          ) : (
            <CancelIcon style={{ opacity: 0.8, color: "red" }} />
          ),
        width: 5,
      },
      {
        Header: "Status",
        accessor: "responseText",
        width: 10,
      },
    ],
    []
  );

  const { controller } = useDataTable({
    columns,
    query: terminalTransactionsQuery,
    pageSize: 10,
    compact: false,
  });

  return (
    <div>
      <HeaderWithRefresh>
        <div>
          <Typography variant="h2">{T("Payment Terminals")}</Typography>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 6, marginTop: -8 }}
          >
            {T("Manage your payment terminals")}
          </Typography>
        </div>
        <div>
          {/*<Button variant="contained" onClick={newPaymentTerminal}>
            {T("New payment terminal")}
          </Button>*/}
          <IconButton onClick={() => globalRefresh()}>
            <RefreshIcon />
          </IconButton>
        </div>
      </HeaderWithRefresh>

      {terminalsQuery.loading && <p>Načítání...</p>}
      {terminalsQuery.data?.terminals?.length > 0 ? (
        <>
          <div style={{ marginBottom: "1rem" }}>
            <Link to="/payment-terminals/statements">
              <Button variant="contained" startIcon={<FileCopyIcon />}>
                {T("Statements")}
              </Button>
            </Link>
            <Button
              onClick={handleExport}
              disabled={exportLoading !== ""}
              startIcon={<ListAltIcon />}
            >
              {T("Export") + " XLS" + exportLoading}
            </Button>
          </div>
          <DataTable controller={controller} />
        </>
      ) : (
        <Card>
          <div style={{ padding: 30 }}>
            <Typography variant="h6">{T("No payment terminals")}</Typography>

            <p>
              Zatím nemáte žádné platební terminály. Pokud jste vyplnili žádost,
              budeme vás kontaktovat. <br />V případě jakýchkoliv dotazů nás
              neváhejte kontaktovat na <b>+420 533 533 052</b>.
            </p>
            {/*<Button variant="contained" onClick={newPaymentTerminal}>
            {T("New payment terminal")}
        </Button>*/}
          </div>
        </Card>
      )}
    </div>
  );
};
