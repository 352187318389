import * as React from "react";

import { Typography } from "@material-ui/core";

import { EditTable } from "./EditTable";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { AddEditEstablishment } from "../Establishment/types";
import { Phone_numberType } from "./types";
import { T } from "../../utils/translate";
import { UserType, UserPhone_numberType } from "../User/types";

export const PhoneNumberAdd: React.FC<{
  addPhone_number: any;
  phone_numbersData: { phone_numbers: Phone_numberType[] };
  usersData?: { users: UserType[] };
  phone_numbers_users?: UserPhone_numberType[];
  establishmentsData?: { establishments: AddEditEstablishment[] };
  globalRefresh: Function;
}> = ({
  addPhone_number,
  phone_numbersData,
  usersData,
  phone_numbers_users,
  establishmentsData,
  globalRefresh,
}) => {
  console.log("phone number add");
  return (
    <div>
      <Accordion
        defaultExpanded={phone_numbersData?.phone_numbers.length === 0}
        style={{ marginTop: "1rem" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5">{T("Add new phone number")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "min(100%, 1000px)" }}>
            <EditTable
              operation={"add"}
              addEditPhone_number={addPhone_number}
              data={{
                name: "",
                phone_number: "",
                active: true,
              }}
              usersData={usersData}
              phone_numbers_users={phone_numbers_users}
              establishmentsData={establishmentsData}
              globalRefresh={globalRefresh}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
