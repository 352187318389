import React, { useState, useEffect } from "react";

import {
  useSendSmsMutation,
  useSendSmsDeliveryMutation,
  useUpdateSmsSentMutation,
  useInsertSmsDeliveryMutation,
} from "../../graphql";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useLocation } from "react-router-dom";
import {
  Card,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import { ConnectedUserType, TemplateType } from "./types";
import { T } from "src/utils/translate";
import { TextInput } from "src/sharedComponents/forms";
import { Select, Label } from "src/sharedComponents/forms";
import { observe, dispatch } from "src/utils/eventBus";
import { SelectModal } from "src/pages/Contacts";
import { authenticationService } from "src/services/authentication";

export const Send: React.FC<{
  connectedUsers: ConnectedUserType[];
  templates: TemplateType[];
}> = ({ connectedUsers, templates }) => {
  const [selecting, setSelecting] = useState(false);

  const [sender, setSender] = useState("auto");
  const defaultValues = { recipients: "", body: "" };
  const { register, handleSubmit, reset, getValues, setValue, watch } = useForm(
    {
      defaultValues,
      mode: "onBlur",
    }
  );
  const body = watch("body");
  const recipients = watch("recipients");
  const recipientsCount = recipients.split("\n").filter((it) => !!it).length;

  const [sendMessage] = useSendSmsMutation();
  const [sendMessagesMultiple] = useSendSmsDeliveryMutation();
  const [markSent] = useUpdateSmsSentMutation();
  const [insertDelivery] = useInsertSmsDeliveryMutation();

  const location = useLocation<{ recipients: string }>();

  const submitForm = async (data) => {
    console.log(data);
    if (!data.recipients || !data.body) return;
    const recipients = [];
    for (const recipient of data.recipients.split("\n")) {
      const phoneNumber = parsePhoneNumberFromString(
        recipient.split("(")[0],
        authenticationService.getCountryCode()
      );
      if (phoneNumber && phoneNumber.isValid()) {
        recipients.push(phoneNumber.number);
      }
    }
    if (data.body.indexOf("TODAY_DATE") >= 0) {
      data.body = data.body.replace(
        "TODAY_DATE",
        new Date().toLocaleDateString("cs-cz")
      );
    }
    if (data.body.indexOf("TOMORROW_DATE") >= 0) {
      const tomorrowDate = new Date();
      tomorrowDate.setDate(tomorrowDate.getDate() + 1);
      data.body = data.body.replace(
        "TOMORROW_DATE",
        tomorrowDate.toLocaleDateString("cs-cz")
      );
    }
    reset();
    if (recipients.length == 0) {
      // No recipient
      return;
    } else if (recipients.length == 1) {
      try {
        let senderId = 0;
        if (sender == "auto") {
          if (!isNaN(parseInt(recipients[0]))) {
            senderId = parseInt(recipients[0]) % connectedUsers.length;
          }
        } else {
          senderId = +sender;
        }
        const response = await sendMessage({
          variables: {
            token: connectedUsers[senderId].token,
            message: data.body,
            recipient: recipients[0],
            second_sim: connectedUsers[senderId].second_sim,
          },
        });
        await markSent({
          variables: {
            objects: [{ id: response.data.centrumsms_send }],
          },
        });
      } catch (error) {
        console.log(error);
        window.alert(T("Error occured"));
        return;
      }
    } else {
      const recipientsList = [];
      if (sender == "auto") {
        connectedUsers.forEach((connectedUser, i) => {
          recipientsList.push({
            token: connectedUser.token,
            second_sim: connectedUser.second_sim,
            recipients: recipients.filter(
              (it) => parseInt(it) % connectedUsers.length == i
            ),
          });
        });
      } else {
        recipientsList.push({
          token: connectedUsers[+sender].token,
          second_sim: connectedUsers[+sender].second_sim,
          recipients: recipients,
        });
      }
      try {
        for (const recipientList of recipientsList) {
          if (recipientList.recipients.length == 0) continue;
          const deliveryResponse = await insertDelivery({
            variables: {
              object: { message_count: recipientList.recipients.length },
            },
          });
          const delivery_id =
            deliveryResponse.data.insert_smsapi_deliveries_one.id;
          const response = await sendMessagesMultiple({
            variables: {
              token: recipientList.token,
              recipients: recipientList.recipients.join(";"),
              body: data.body,
              second_sim: recipientList.second_sim,
            },
          });
          const objects = response.data.centrumsms_send_group.map((it) => ({
            id: it,
            delivery_id,
          }));
          console.log(objects);
          const markSentResponse = await markSent({
            variables: {
              objects,
            },
          });
          console.log(markSentResponse);
        }
      } catch (error) {
        console.log(error);
        window.alert(T("Error occured"));
        return;
      }
    }
    dispatch("centrumsms_historyRefresh");
  };

  useEffect(() => {
    return observe("centrumsms_setRecipient", (it) => {
      const newRecipients = [
        ...getValues()
          ["recipients"].split("\n")
          .filter((it) => it != ""),
        it.phoneNumber,
      ];
      setValue("recipients", newRecipients.join("\n"), {
        shouldValidate: true,
      });
    });
  }, []);
  useEffect(() => {
    const recipients = location?.state?.recipients;
    if (recipients || !getValues()["recipients"]) {
      setValue("recipients", recipients, {
        shouldValidate: true,
      });
    }
  }, [location?.state]);

  const handleSelect = (data) => {
    const newRecipients = [
      ...getValues()
        ["recipients"].split("\n")
        .filter((it) => it != ""),
      ...data.map(
        (it) =>
          it.phone_number + " (" + it.first_name + " " + it.last_name + ")"
      ),
    ];
    setValue("recipients", newRecipients.join("\n"), {
      shouldValidate: true,
    });
    setSelecting(false);
  };

  const finishTime = (recipientsCount) => {
    const date = new Date();
    let sendersCount = 1;
    if (sender == "auto") {
      sendersCount = connectedUsers.filter(
        (it) => it.second_sim == false
      ).length;
    }
    date.setMinutes(date.getMinutes() + recipientsCount / sendersCount);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const senders = [
    {
      label: "Automaticky",
      value: "auto",
    },
    ...connectedUsers.map(({ phone_number }, i) => ({
      label: phone_number,
      value: i.toString(),
    })),
  ];

  return (
    <Card style={{ marginBottom: 32, padding: 24 }}>
      <SelectModal
        isOpen={selecting}
        onSubmit={handleSelect}
        onClose={() => setSelecting(false)}
      />
      <form onSubmit={handleSubmit(submitForm)}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Label>{T("Sender")}</Label>
            <Select
              options={senders}
              value={senders.find((it) => it.value == sender)}
              onChange={(newValue: { value: string; label: string }) =>
                setSender(newValue.value)
              }
            />
            <TextInput
              name="recipients"
              label={T("Recipients")}
              placeholder={T("You can separate by line")}
              textarea
              rows={8}
              register={register}
            />
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => setSelecting(true)}
            >
              {T("Select contacts")}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-end",
            }}
          >
            <TextInput
              name="body"
              label={T("Text")}
              textarea
              rows={8}
              register={register}
            />
            <div
              style={{
                alignSelf: "flex-end",
                marginBottom: "-1.251rem",
              }}
            >
              {body.length} {T("characters")}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginRight: "1rem" }}
              >
                {T("Send")}
              </Button>
              {recipientsCount > 1 &&
                T("Delivery will be finished approximately") +
                  " " +
                  finishTime(recipientsCount)}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ flex: "1 1 auto", height: 0, overflow: "auto" }}>
              <Label>{T("Templates")}</Label>
              <List style={{ marginLeft: "-16px" }}>
                {templates?.map((it) => (
                  <ListItem
                    key={it.id}
                    button
                    onClick={() => setValue("body", it.text)}
                  >
                    <ListItemText
                      primary={it.name}
                      style={{
                        maxHeight: "3.2em",
                        overflow: "hidden",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};
