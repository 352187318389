import React from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import ListItemLink from "src/sharedComponents/ListItemLink";
import { T } from "src/utils/translate";

import BusinessTwoTone from "@material-ui/icons/BusinessTwoTone";
import PeopleAltTwoTone from "@material-ui/icons/PeopleAltTwoTone";
import PlaceTwoTone from "@material-ui/icons/PlaceTwoTone";
import CallTwoTone from "@material-ui/icons/CallTwoTone";
import SecurityTwoTone from "@material-ui/icons/SecurityTwoTone";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PaymentTwoTone from "@material-ui/icons/PaymentTwoTone";
import VpnKeyTwoTone from "@material-ui/icons/VpnKeyTwoTone";
import ExitToAppTwoTone from "@material-ui/icons/ExitToAppTwoTone";
import FaceTwoTone from "@material-ui/icons/FaceTwoTone";
import MessageTwoTone from "@material-ui/icons/MessageTwoTone";
import ContactsTwoTone from "@material-ui/icons/ContactsTwoTone";

import { authenticationService } from "./../services/authentication";
import {
  AccountBalanceTwoTone,
  AccountBalanceWalletTwoTone,
  ReceiptTwoTone,
  StarBorder,
} from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerContainer: {
      overflow: "auto",
    },
    parentList: {
      paddingTop: theme.spacing(2),
      paddingBottom: 0,
    },
    listItem: {
      paddingLeft: theme.spacing(3),
    },
    listItemIcon: {
      color: "#2b48a4",
    },
    nestedList: {
      paddingTop: 0,
    },
    nestedListItem: {
      paddingLeft: theme.spacing(6),
    },
    subheader: {
      marginBottom: "-4px",
      color: "black",
    },
  })
);

export const Drawer: React.FC<{
  showUser?: { username: string; company: string };
  onClose: () => void;
}> = ({ showUser = null, onClose }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.drawerContainer} onClick={onClose}>
        <List
          className={classes.parentList}
          subheader={
            showUser && (
              <ListSubheader className={classes.subheader} component="div">
                {showUser.company}
              </ListSubheader>
            )
          }
        >
          {[{ name: T("Company"), link: "/company" }].map((item) => (
            <ListItemLink
              to={item.link}
              key={item.link}
              className={classes.listItem}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <BusinessTwoTone />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemLink>
          ))}
        </List>
        <List component="div" className={classes.nestedList}>
          {[
            {
              name: "Establishments",
              link: "/establishment",
              icon: <PlaceTwoTone />,
            },
            { name: "Users", link: "/user", icon: <PeopleAltTwoTone /> },
            {
              name: "Phone numbers",
              link: "/phone_number",
              icon: <CallTwoTone />,
            },
            { name: "Roles", link: "/role", icon: <SecurityTwoTone /> },
          ].map((item) => (
            <ListItemLink
              to={item.link}
              key={item.name}
              className={classes.nestedListItem}
            >
              <ListItemIcon className={classes.listItemIcon}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={T(item.name)} />
            </ListItemLink>
          ))}
        </List>

        {[
          {
            name: T("CentrumSMS"),
            link: "/centrumsms",
            icon: <MessageTwoTone />,
          },
          {
            name: T("Contacts"),
            link: "/contacts",
            icon: <ContactsTwoTone />,
          },
          {
            name: T("Payment Terminals"),
            link: "/payment-terminals",
            icon: <PaymentTwoTone />,
          },
          /*{
            name: T("Transactions"),
            link: "/transactions",
            icon: <AccountBalanceWalletTwoTone />,
          },
          { name: T("Payments"), link: "/payments", icon: <ReceiptTwoTone /> },*/
        ].map((item) => (
          <ListItemLink
            to={item.link}
            key={item.link}
            className={classes.listItem}
          >
            <ListItemIcon className={classes.listItemIcon}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </ListItemLink>
        ))}
        {showUser && (
          <>
            <Divider />
            <List
              subheader={
                showUser && (
                  <ListSubheader className={classes.subheader} component="div">
                    {showUser.username}
                  </ListSubheader>
                )
              }
            >
              {[
                {
                  name: T("Profile"),
                  link: "/profile",
                  icon: <FaceTwoTone />,
                },
                {
                  name: T("Change password"),
                  link: "/change-password",
                  icon: <VpnKeyTwoTone />,
                },
              ].map((item) => (
                <ListItemLink
                  to={item.link}
                  key={item.link}
                  className={classes.listItem}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemLink>
              ))}
              <ListItem
                button
                onClick={() => authenticationService.logout()}
                className={classes.listItem}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <ExitToAppTwoTone />
                </ListItemIcon>
                <ListItemText primary={T("Log out")} />
              </ListItem>
            </List>
          </>
        )}
      </div>
    </>
  );
};

export default Drawer;
