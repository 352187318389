import React, { useState } from "react";

import {
  Typography,
  Card,
  Button,
  IconButton,
  LinearProgress,
  Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  useInsertSmsTagMutation,
  useDeleteSmsTagMutation,
  useInsertSmsTemplateMutation,
  useDeleteSmsTemplateMutation,
} from "src/graphql";
import { TextInput } from "src/sharedComponents/forms";
import { T } from "src/utils/translate";
import { observe, dispatch } from "src/utils/eventBus";

import { ConnectedUserType, TagsType, TemplateType } from "../types";

export const SettingsMain: React.FC<{
  connectedUsers: ConnectedUserType[];
  tags?: TagsType[];
  templates?: TemplateType[];
}> = ({ connectedUsers, tags, templates }) => {
  const [newTag, setNewTag] = useState("");
  const [newTemplate, setNewTemplate] = useState("");
  const [newTemplateName, setNewTemplateName] = useState("");

  const [insertTag] = useInsertSmsTagMutation({
    onCompleted: () => {
      dispatch("centrumsms_tagsRefresh");
    },
  });
  const [deleteTag] = useDeleteSmsTagMutation({
    onCompleted: () => {
      dispatch("centrumsms_tagsRefresh");
    },
  });
  const [insertTemplate] = useInsertSmsTemplateMutation({
    onCompleted: () => {
      dispatch("centrumsms_templatesRefresh");
    },
  });
  const [deleteTemplate] = useDeleteSmsTemplateMutation({
    onCompleted: () => {
      dispatch("centrumsms_templatesRefresh");
      dispatch("shoptet_templatesRefresh");
    },
  });

  const handleNewTag = () => {
    if (!newTag) return;
    insertTag({ variables: { name: newTag } });
    setNewTag("");
  };

  const handleNewTemplate = () => {
    if (!newTemplate || !newTemplateName) return;
    insertTemplate({ variables: { name: newTemplateName, text: newTemplate } });
    setNewTemplate("");
    setNewTemplateName("");
  };

  return (
    <>
      <Typography variant="h6">{T("Connected numbers")}</Typography>
      <Card>
        {connectedUsers.map((it) => (
          <div key={it.phone_number}>
            <div style={{ padding: 16 }}>
              <div style={{ float: "right" }}>
                naposledy online{" "}
                {new Date(Number(it.last_seen)).toLocaleDateString()}
              </div>
              <Typography variant="subtitle2">{it.phone_number}</Typography>
              <div>
                API token: {it.token} {it.second_sim && ", second_sim = true"}
              </div>
              <div
                style={{
                  clear: "both",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <LinearProgress
                  variant="determinate"
                  value={(it.second_sim ? it.count_second : it.count) / 30}
                  style={{ flex: 1, marginRight: 15 }}
                />
                {it.second_sim ? it.count_second : it.count}/{it.total_count}{" "}
                SMS
              </div>
            </div>
            <Divider />
          </div>
        ))}
      </Card>

      <Typography variant="h6" style={{ marginTop: "1em" }}>
        {T("Templates")}
      </Typography>
      <Card>
        <div style={{ maxHeight: "500px", overflow: "auto" }}>
          {templates?.map((it) => (
            <div
              key={it.id}
              style={{
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ flex: 1 }}>
                <Typography variant="subtitle2">{it.name}</Typography>
                {it.text}
              </div>
              <IconButton
                onClick={() => deleteTemplate({ variables: { id: it.id } })}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </div>
        <Divider />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleNewTemplate();
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              paddingLeft: "1rem",
              paddingTop: "1rem",
            }}
          >
            <TextInput
              label={T("Name")}
              value={newTemplateName}
              onChange={(value) => setNewTemplateName(value)}
            />
            <Button
              type="submit"
              variant="contained"
              style={{ margin: "1rem" }}
            >
              {T("Add")}
            </Button>
          </div>

          <div
            style={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            <TextInput
              label={T("Text")}
              value={newTemplate}
              onChange={(value) => setNewTemplate(value)}
              textarea={true}
            />
            <div style={{ marginTop: "-0.5rem", marginBottom: "0.5rem" }}>
              {newTemplate.length} {T("characters")}
            </div>
          </div>
        </form>
      </Card>

      <Typography variant="h6" style={{ marginTop: "1em" }}>
        {T("Tags")}
      </Typography>
      <Typography variant="subtitle1">{T("For your organization")}</Typography>
      <Card>
        {tags?.map((it) => (
          <div key={it.id} style={{ paddingLeft: 16, paddingRight: 16 }}>
            {it.name}
            <IconButton onClick={() => deleteTag({ variables: { id: it.id } })}>
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
        <Divider />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleNewTag();
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              paddingLeft: "1rem",
              paddingTop: "1rem",
            }}
          >
            <TextInput
              label={T("Name")}
              placeholder={T("New item")}
              value={newTag}
              onChange={(value) => setNewTag(value)}
            />
            <Button
              type="submit"
              variant="contained"
              style={{ margin: "1rem" }}
            >
              {T("Add")}
            </Button>
          </div>
        </form>
      </Card>
    </>
  );
};
