import { cs } from "./cs";

const langs = new Map<string, Map<string, string>>();
langs.set("cs", cs);

const languageCode = "cs";

const innerTranslate = (key: string, langCode: string): string => {
  const lang = langs.get(langCode);
  if (lang) {
    const translateText = cs.get(key);
    if (translateText) {
      return translateText;
    }
  }
  console.error("no translation for key: ", key, "language: ", langCode);
  return "###" + key;
};

export const T = (key: string, langCode = languageCode): string => {
  return innerTranslate(key, langCode);
};
