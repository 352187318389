import axios from "axios";

class GeocodingService {
  // TODO

  public searchAddressCUZK(
    address: string
  ): Promise<{ lat: number; lng: number }> {
    return new Promise<{ lat: number; lng: number }>((resolve, reject) => {
      axios
        .get(
          "https://ags.cuzk.cz/arcgis/rest/services/RUIAN/Vyhledavaci_sluzba_nad_daty_RUIAN/MapServer/exts/GeocodeSOE/findAddressCandidates",
          {
            params: { SingleLine: address, f: "pjson" },
          }
        )
        .then((response) => {
          if (
            response.data &&
            response.data.candidates &&
            response.data.candidates.length > 0
          ) {
            resolve({
              lat:
                (response.data.candidates[0].attributes.Ymax +
                  response.data.candidates[0].attributes.Ymin) /
                2,
              lng:
                (response.data.candidates[0].attributes.Xmax +
                  response.data.candidates[0].attributes.Xmin) /
                2,
            });
          } else {
            reject(response.data);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }

  public searchAddress(address: string): Promise<{ lat: number; lng: number }> {
    return new Promise<{ lat: number; lng: number }>((resolve, reject) => {
      axios
        .get("https://api.mapy.cz/geocode", {
          params: { query: address },
        })
        .then((response) => {
          if (!response.data) {
            reject();
            return;
          }
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(response.data, "text/xml");
          const el = xmlDoc.getElementsByTagName("item")[0];
          if (!el) {
            reject();
            return;
          }
          resolve({
            lat: Number(el.getAttribute("y")),
            lng: Number(el.getAttribute("x")),
          });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  }
}

export const geocodingService = new GeocodingService();
