import * as React from "react";
import { T } from "../../utils/translate";

import {
  useCancelPaymentMutation,
  useRenewPaymentMutation,
} from "../../graphql";
import { Typography, Button } from "@material-ui/core";

import styled from "styled-components";
import { Invoice } from "./Invoice";
import { SendPayment } from "./SendPayment";
import { MyDivider } from "../../style/styledComponents/Mui";
import { Payment as PaymentType } from "../Payments/types";
import * as QRCode from "qrcode.react";
import { ArrowBackIos } from "@material-ui/icons";

export const TwoButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
`;
const QrDiv = styled.div`
  border-radius: 4px;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.14),
    0 6px 10px 0 rgba(0, 0, 0, 0.098), 0 1px 18px 0 rgba(0, 0, 0, 0.084);
  padding: 10px 10px 5px 10px;
`;

export const Payment: React.FC<{
  originalPayment: PaymentType;
  back: any;
}> = ({ originalPayment, back }) => {
  const [payment, setPayment] = React.useState(originalPayment);

  const seconds = Math.round(
    (Date.parse(payment.quick_code_expires) - Date.now()) / 1000
  );
  const [timeLeft, setTimeLeft] = React.useState(seconds);

  const [
    cancelPayment,
    { error: errorCancelPayment, data: dataCancelPayment },
  ] = useCancelPaymentMutation();

  const renew = () => {
    console.log("renew", payment.id);
    renewPayment({ variables: { payment_id: payment.id } });
  };

  const [renewPayment] = useRenewPaymentMutation({
    onCompleted(answer) {
      const { quick_code, quick_code_expires } = answer?.renew_payment_code;
      setPayment({ ...payment, quick_code_expires, quick_code });

      const seconds = Math.round(
        (Date.parse(quick_code_expires) - Date.now()) / 1000
      );

      setTimeLeft(seconds);
    },
  });

  React.useEffect(() => {
    // exit early when we reach 0
    console.log("timeLeft", timeLeft);
    if (!timeLeft) {
      return;
    }

    if (timeLeft < 0) {
      renew();
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return (
    <div style={{ padding: "8px 16px 4px 16px" }}>
      {back && (
        //   back has 2 functions: to return back on create-invoice,
        //and to tell if this component was called from create-invoice (true),
        //or view payment detail(false)
        <Button
          onClick={() => {
            cancelPayment({ variables: { cancelled: true, id: payment.id } });
            if (back) {
              back();
            }
          }}
          style={{ cursor: "pointer", marginBottom: "2rem" }}
        >
          <ArrowBackIos /> Back
        </Button>
      )}
      {payment && (
        <React.Fragment>
          <div
            style={{ display: "flex", justifyContent: "flex-start", gap: 40 }}
          >
            <div>
              <QrDiv>
                <QRCode size={200} value={`${payment?.key}`} />
              </QrDiv>
            </div>
            <Invoice payment={payment} />
          </div>

          <MyDivider />
          <Typography variant="h3" gutterBottom>
            Payment code:
          </Typography>
          <Typography
            variant="h2"
            style={{ textDecoration: !timeLeft ? "line-through" : "" }}
          >
            {payment.quick_code}
          </Typography>
          {!timeLeft ? (
            <Typography variant="subtitle1">Expired</Typography>
          ) : (
            <Typography variant="subtitle1">
              Expires in: {timeLeft} seconds
            </Typography>
          )}
          <TwoButtons>
            <Button variant="contained" color="primary" onClick={renew}>
              Renew
            </Button>
            {back && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  cancelPayment({
                    variables: { id: payment.id, cancelled: true },
                  });
                  if (back) {
                    back();
                  }
                }}
              >
                Cancel
              </Button>
            )}
          </TwoButtons>

          <MyDivider />
          <SendPayment payment={payment} />
        </React.Fragment>
      )}
    </div>
  );
};
