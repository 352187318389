import * as React from "react";
import { Form } from "../../sharedComponents/Form";

import { useSendPaymentMutation } from "../../graphql";
import { yup } from "../../utils/yup";
import { Payment as PaymentType } from "../Payments/types";
import { TextInput } from "../../sharedComponents/forms";
import { Typography, Button, makeStyles } from "@material-ui/core";
import { T } from "../../utils/translate";
import Alert from "@material-ui/lab/Alert";

interface SendPaymentProps {
  payment: PaymentType;
}

const useStyles = makeStyles({
  alert: {
    "& .MuiAlert-message": {
      fontSize: 14,
    },
  },
});

export const SendPayment: React.FC<SendPaymentProps> = ({ payment }) => {
  const [success, setSuccess] = React.useState(-1);

  const [sendPayment] = useSendPaymentMutation({
    onCompleted(answer) {
      console.log("answer", answer);
      if (answer.send_payment.success) {
        console.log("payment success");
        setSuccess(1);
      } else {
        console.log("payment fail");
        setSuccess(0);
      }
      setTimeout(() => {
        setSuccess(-1);
      }, 5000);
    },
  });

  const classes = useStyles();

  return (
    <Form
      className={"form"}
      defaultValues={{ user_contact: "" }}
      validationSchema={yup.object().shape({
        user_contact: yup.string().required(),
      })}
      onSubmit={(data: any) => {
        console.log("data", data);
        sendPayment({
          variables: {
            payment_id: payment.id,
            user_contact: data.user_contact,
          },
        });
      }}
    >
      <Typography variant="h3" gutterBottom>
        {T("Send payment request to:")}
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 20,
        }}
      >
        <TextInput
          autohook
          name="user_contact"
          placeholder={T("Contact")}
          style={{ width: 200, marginBottom: 0 }}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          style={{ marginRight: 12, height: "fit-content" }}
        >
          Send
        </Button>
      </div>

      <div style={{ display: "flex" }}>
        {success === 1 && (
          <Alert className={classes.alert} severity="success">
            Payment sending successful
          </Alert>
        )}
        {success === 0 && (
          <Alert className={classes.alert} severity="error">
            Payment sending failed
          </Alert>
        )}
      </div>
    </Form>
  );
};
