import * as React from "react";
import { T } from "../../utils/translate";
import Divider from "@material-ui/core/Divider";

import { TransactionsSelect } from "./TransactionsSelect";
import { Typography, Button } from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

export const Deposit: React.FC<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom>
        Deposit money
      </Typography>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h3">Bank transfer</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          <Typography variant="subtitle1" gutterBottom>
            IBAN: CZ 1234567890
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            VS: 123456
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h3">Telidoo Payment</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: "block" }}>
          <Typography variant="subtitle1" gutterBottom>
            Receive money from other Telidoo user by creating invoice.
          </Typography>
          <Link to="/create-invoice">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ marginTop: 7 }}
            >
              Create Invoice
            </Button>
          </Link>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
