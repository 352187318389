import * as React from "react";
import { Redirect, RouteProps, Route } from "react-router-dom";
import { useUserStore } from "../../store/User";
import { Layout } from "../../layout";

export const AppRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const { component, ...rest } = props;
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);
  console.log("isAuthenticated " + isAuthenticated);
  if (!isAuthenticated) {
    return <Redirect to={"/login"} />;
  }

  const Component = component;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};
