import * as React from "react";

import { Table, TableBody, TableRow, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import {
  Phone_numberType,
  Phone_numberUserType,
  Phone_numberEstablishmentType,
} from "./types";
import { yup } from "../../utils/yup";
import { Form } from "../../sharedComponents/Form";
import { TextInput } from "../../sharedComponents/forms";
import { T } from "../../utils/translate";
import { MyTableCell } from "../../style/styledComponents/table";

import {
  useUpdatePhoneNumberUsersMutation,
  useUpdatePhoneNumberEstablishmentsMutation,
} from "../../graphql";
import { AddEditEstablishment } from "../Establishment/types";
import { UserType } from "../User/types";
import { Label, Select } from "../../sharedComponents/forms";
import { Typography } from "@material-ui/core";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { authenticationService } from "src/services/authentication";

interface EditTableProps {
  addEditPhone_number: any;
  editingId?: string;
  closeForm?: Function;
  data: Phone_numberType; // data for the form we are editing
  removePhoneNumber?: Function;
  operation: string;
  phone_numbers_establishments?: Phone_numberEstablishmentType[];
  usersData: { users: UserType[] };
  globalRefresh?: Function;
  phone_numbers_users?: Phone_numberUserType[];
  establishments_phone_numbers?: Phone_numberEstablishmentType[];
  establishmentsData?: { establishments: AddEditEstablishment[] };
}

export const PHONE_NUMBER_FORM_VALIDATION = yup.object().shape({
  name: yup.string(),
  phone_number: yup
    .string()
    .required(T("Phone number is required"))
    .test("phone-number", T("Phone number is not valid"), (value) => {
      const phoneNumber = parsePhoneNumberFromString(
        value,
        authenticationService.getCountryCode()
      );
      return phoneNumber && phoneNumber.isValid();
    }),
});

export const EditTable: React.FC<EditTableProps> = ({
  addEditPhone_number,
  editingId,
  closeForm,
  data,
  removePhoneNumber,
  operation,
  usersData,
  globalRefresh,
  phone_numbers_users,
  establishmentsData,
  phone_numbers_establishments,
}) => {
  const [establishmentsToSend, setEstablishmentsToSend] = React.useState([]);
  const [usersToSend, setUsersToSend] = React.useState([]);
  const [active, setActive] = React.useState(
    data.active === true ? "Active" : "Deactivated"
  );

  React.useEffect(() => {
    if (!phone_numbers_establishments || !editingId) {
      return;
    }
    //we have array of phone_numbers_establishments (table connecting these 2)
    //some of them are however empty, because I can only get where I have (hasura) access - only data for my company.
    //So in reality, this table will have thousands of lines but only few real values visible to this user/company.
    //so I need to filter it. Then I will just take first, and take it as selected option.
    //We only allow one establishment to be linked to the phone number, so there shouldn't be more than one anyway.
    const filteredPNE = phone_numbers_establishments.filter(
      (x) => x.establishment && x.phone_number && x.phone_number.id == editingId
    );
    console.log("filteredPNE", filteredPNE);
    //if empty array, there isn't nothing linked.
    //NOTE: this could be done much easier but is using phone_number - establishment relation table from historical reasons
    if (filteredPNE && Array.isArray(filteredPNE) && filteredPNE.length) {
      const selectedObject = {
        value: filteredPNE[0]?.establishment.id,
        label: filteredPNE[0]?.establishment.name,
      };
      console.log("selectedObject", selectedObject);
      setEstablishmentsToSend([selectedObject]);
    }
  }, [phone_numbers_establishments]);

  //same as above, but for users
  React.useEffect(() => {
    if (!phone_numbers_users || !editingId) {
      return;
    }
    const filteredPNU = phone_numbers_users.filter(
      (x) => x.user && x.phone_number && x.phone_number.id == editingId
    );
    if (filteredPNU && Array.isArray(filteredPNU) && filteredPNU.length) {
      const selectedObject = {
        value: filteredPNU[0]?.user.id,
        label:
          filteredPNU[0]?.user?.first_name +
          " " +
          filteredPNU[0]?.user?.last_name +
          " (" +
          filteredPNU[0]?.user?.email +
          ")",
      };
      console.log("selectedObject", selectedObject);
      setUsersToSend([selectedObject]);
    }
  }, [phone_numbers_users]);

  console.log("START data", data);

  const DEFAULT_VALUES = {
    name: data.name,
    phone_number: parsePhoneNumberFromString(
      data.phone_number,
      authenticationService.getCountryCode()
    )?.format("INTERNATIONAL"),
    active: data.active,
  };

  const [
    deleteAndInsertPhone_numbersUsers,
    // { error: errorPnu, data: dataPhu },
  ] = useUpdatePhoneNumberUsersMutation({
    onCompleted(answer) {
      // setEdit(false);
      // companyRefetch();
      //userPhone_numberRefetch(); todo
    },
  });

  const [
    deleteAndInsertPhone_numbersEstablishments,
    // { error: errorUI, data: dataUI },
  ] = useUpdatePhoneNumberEstablishmentsMutation({
    errorPolicy: "all",
    onCompleted(answer) {
      console.log("deleted and insert phone_numbers ests  done", answer);
      // setEdit(false);
      // companyRefetch();
      //   phone_numberEstRefetch();
      globalRefresh();
    },
  });

  const submitForm = async (data, reset) => {
    console.log("submitForm data", data);
    try {
      const processedPhoneNumber = parsePhoneNumberFromString(
        data.phone_number,
        authenticationService.getCountryCode()
      );

      if (!processedPhoneNumber || !processedPhoneNumber.isValid()) {
        alert(T("Phone number is not valid"));
        return;
      }
      console.log("processedPhoneNumber.number", processedPhoneNumber.number);
      const addEditResult = await addEditPhone_number({
        variables: {
          id: editingId,
          name: data.name,
          phone_number: processedPhoneNumber.number,
          active: active === "Active",
        },
      });

      if (!editingId) {
        editingId = addEditResult.data.insert_phone_numbers_one.id;
      }

      console.log(">>>Phone numbers ToSend", establishmentsToSend);
      if (editingId) {
        //saving .... separately
        const objects = establishmentsToSend.map((toSend) => ({
          establishment_id: toSend.value,
          phone_number_id: editingId,
        }));

        console.log("objects", objects);
        //todo check object doesn't have null inside so apollo erros is prevented
        deleteAndInsertPhone_numbersEstablishments({
          variables: {
            objects,
            phone_number_id: editingId,
          },
        });

        console.log(">>>usersToSend", usersToSend);
        const userObjects = usersToSend.map((toSend) => ({
          user_id: toSend.value, //value is id here
          phone_number_id: editingId,
        }));

        console.log("userObjects", userObjects);

        deleteAndInsertPhone_numbersUsers({
          variables: {
            objects: userObjects,
            phone_number_id: editingId,
          },
        });
      }
      if (operation != "edit") {
        reset();
        setEstablishmentsToSend([]);
        setUsersToSend([]);
        globalRefresh();
      }

      if (closeForm) {
        closeForm();
      }
    } catch (e) {
      if (closeForm) {
        closeForm();
      }
      console.warn("Saving data failed.", e);
      alert(T("Saving data failed."));
    }
  };

  console.log("establishmentsData?.establishments", establishmentsData);
  console.log("phone_numbers_establishments", phone_numbers_establishments);

  return (
    // phone_number clicked edit, editing mode
    <Form
      className={"form"}
      defaultValues={DEFAULT_VALUES}
      validationSchema={PHONE_NUMBER_FORM_VALIDATION}
      onSubmit={(data: any, reset) => submitForm(data, reset)}
    >
      <Table>
        <TableBody>
          <TableRow key={1}>
            <MyTableCell>
              <TextInput
                autohook
                name="phone_number"
                label={T("Phone number")}
              />
            </MyTableCell>
            <MyTableCell>
              <TextInput autohook name="name" label={T("Name")} />
            </MyTableCell>
          </TableRow>
          {operation === "edit" && (
            <TableRow>
              <MyTableCell>
                <Select
                  label={T("Active")}
                  options={[
                    { value: "Active", label: T("Active") },
                    { value: "Deactivated", label: T("Deactivated") },
                  ]}
                  value={
                    active === "Active"
                      ? { value: "Active", label: T("Active") }
                      : { value: "Deactivated", label: T("Deactivated") }
                  }
                  onChange={(newValue: { value: string; label: string }) => {
                    setActive(newValue.value);
                  }}
                />
              </MyTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {usersData?.users && usersData?.users.length && (
        <Select
          label={T("Linked user")}
          options={[{ value: null, label: "-" }].concat(
            usersData?.users.map((e) => ({
              value: e.id,
              label: e?.first_name + " " + e?.last_name + " (" + e?.email + ")",
            }))
          )}
          value={usersToSend[0] || { value: null, label: "-" }}
          onChange={(newValue: { value: string; label: string }) => {
            console.log("[newValue]", [newValue]);
            if (newValue.value) {
              setUsersToSend([newValue]);
            } else {
              setUsersToSend([]);
            }
          }}
        />
      )}

      {establishmentsData?.establishments &&
        establishmentsData?.establishments.length > 0 && (
          <Select
            label={T("Linked establishment")}
            options={[{ value: null, label: "-" }].concat(
              establishmentsData?.establishments.map((e) => ({
                value: e.id,
                label: e.name,
              }))
            )}
            value={establishmentsToSend[0] || { value: null, label: "-" }}
            onChange={(newValue: { value: string; label: string }) => {
              console.log("[newValue]", [newValue]);
              if (newValue.value) {
                console.log("setEst", newValue);
                setEstablishmentsToSend([newValue]);
              } else {
                console.log("setEst []");
                setEstablishmentsToSend([]);
              }
            }}
          />
        )}

      {operation === "edit" && data.app_connection_active != null && (
        <div style={{ marginTop: 15, marginBottom: 30 }}>
          <Label>{T("Linked app")}</Label>

          {data.app_connection_active === true && (
            <Alert severity="success"> {T("App connected")}</Alert>
          )}
          {data.app_connection_active === false && (
            <div>
              <Alert severity="warning">
                {T("Waiting for app verification")}
              </Alert>
            </div>
          )}
        </div>
      )}

      <Button size="small" variant="contained" color="primary" type="submit">
        {operation === "add" ? T("Add") : T("Save")}
      </Button>
      {editingId && (
        <Button size="small" onClick={() => closeForm()}>
          {T("Cancel")}
        </Button>
      )}
      {editingId && (
        <Button
          size="small"
          // @ts-ignore
          variant="delete"
          style={{ float: "right" }}
          onClick={(e) => {
            if (
              !window.confirm(
                "Are you sure you want to delete this phone number?"
              )
            ) {
              return;
            }
            if (!editingId) {
              return;
            }
            removePhoneNumber({
              variables: { id: editingId },
            });
            if (closeForm) {
              closeForm();
            }
          }}
        >
          {T("Delete")}
        </Button>
      )}
    </Form>
  );
};
