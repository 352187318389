import { urlService } from "../url";
import { ajaxService } from "../ajax";

interface UploadResponse {
  success: boolean;
  error?: string;
}

interface passHashToEmailsRequest {
  success: boolean;
  emails: string[];
  error?: string;
}

class DatagetterService {
  public fileUpload(formData: object): Promise<{}> {
    console.log("formData", formData);
    return new Promise<{}>((resolve, reject) => {
      ajaxService
        .post<UploadResponse>(urlService.api("/api/uploadImg"), formData)
        .then((data) => {
          if (data.success) {
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }

  public passHashToEmails(passHash: string): Promise<{ emails: string[] }> {
    return new Promise<{ emails: string[] }>((resolve, reject) => {
      ajaxService
        .post<passHashToEmailsRequest>(
          urlService.api("/api/passHashToEmails"),
          {
            passHash,
          }
        )
        .then((data) => {
          if (data.success) {
            // window.location.replace(urlService.front('/login'));
            resolve(data);
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error.message);
          reject(error.message);
        });
    });
  }
}

export const datagetterService = new DatagetterService();
