import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import {
  Typography,
  Card,
  Button,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import ListItemLink from "src/sharedComponents/ListItemLink";

import SettingsIcon from "@material-ui/icons/Settings";
import PowerIcon from "@material-ui/icons/Power";

import { ConnectedUserType, TagsType, TemplateType } from "./types";

import { SettingsMain } from "./Settings/Main";
import { Reservio } from "./Settings/Reservio";
import { Shoptet } from "./Settings/Shoptet";
import { T } from "src/utils/translate";

export const Settings: React.FC<{
  connectedUsers: ConnectedUserType[];
  tags?: TagsType[];
  templates?: TemplateType[];
}> = ({ connectedUsers, tags, templates }) => {
  if (connectedUsers.length == 0) {
    return (
      <Card>
        <div style={{ padding: 30 }}>
          <Typography variant="h6">{T("No number set up")}</Typography>
          {T("CentrumSMS setup instructions")
            .split("\n")
            .map((it, i) => (
              <p key={i}>{it}</p>
            ))}
        </div>
      </Card>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={3}>
        <List component="nav">
          <ListItemLink to="/centrumsms/settings">
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={T("Settings")} />
          </ListItemLink>
          <ListItemLink to="/centrumsms/settings/integrations/reservio">
            <ListItemIcon>
              <PowerIcon />
            </ListItemIcon>
            <ListItemText primary={T("Reservio integration")} />
          </ListItemLink>
          <ListItemLink to="/centrumsms/settings/integrations/shoptet">
            <ListItemIcon>
              <PowerIcon />
            </ListItemIcon>
            <ListItemText primary={T("Shoptet integration")} />
          </ListItemLink>
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <Switch>
          <Route
            exact
            path="/centrumsms/settings"
            render={(props) => (
              <SettingsMain
                connectedUsers={connectedUsers}
                tags={tags}
                templates={templates}
              />
            )}
          />
          <Route
            exact
            path="/centrumsms/settings/integrations/reservio"
            render={(props) => (
              <Reservio connectedUsers={connectedUsers} templates={templates} />
            )}
          />
          <Route
            exact
            path="/centrumsms/settings/integrations/shoptet"
            render={(props) => (
              <Shoptet connectedUsers={connectedUsers} templates={templates} />
            )}
          />
        </Switch>
      </Grid>
    </Grid>
  );
};
