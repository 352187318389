/**
 * @file A simple Event Bus to facilitate communication between components
 * @author Tomáš Martykán
 */

const observers = [];

type DestructorFunction = () => void;
type CallbackFunction = (...args: any[]) => void;

/**
 * Observe an event and receive callbacks.
 *
 * @param event The event name to subscribe to.
 * @param callback The callback function. Will receive any args passed by the dispatch.
 * @returns Destructor function of the observer.
 */
export function observe(
  event: string,
  callback: CallbackFunction
): DestructorFunction {
  const id = observers.length;
  observers[id] = { event, callback };
  return () => {
    observers.splice(id);
  };
}

/**
 * Dispatch and event
 *
 * @param event The event name to dispatch.
 * @param args Arguments that will be forwarded to the observer.
 */
export function dispatch(event: string, ...args: any[]): void {
  observers.forEach((observer) => {
    if (observer.event == event) {
      observer.callback(...args);
    }
  });
}
