import * as React from "react";
import cloneDeep from "lodash/cloneDeep";

import { T } from "../../utils/translate";
import styled from "styled-components";

import {
  FormControlLabel,
  Checkbox,
  Divider,
  IconButton,
  InputBase,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import IconHome from "@material-ui/icons/Home";
import IconBack from "@material-ui/icons/ArrowBack";
import FolderIcon from "@material-ui/icons/Folder";

import { useGetAllCategoriesQuery } from "../../graphql";
import CategoriesChips from "./CategoriesChips";
import { TOP_CATEGORY_UUID, NOTHING_HERE } from "../../utils/constants";

export type DefaultValue = {
  id: string;
  name: string;
  parent_id: string;
  parent: Object;
};

const PaperDiv = styled.div`
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  padding: 24px;
  outline: none;
`;
const MyTableRow = styled(TableRow)`
  &:hover {
    background-color: #f5f5f5;
  }
`;
const MyTableCell = styled(TableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const ResultTable: React.FC<{
  myCategories?: any;
  parents?: any;
  state?: any;
  handleChange?: any;
  goUpDown?: any;
  allCategories?: any;
}> = ({
  myCategories,
  parents,
  state,
  handleChange,
  goUpDown,
  allCategories,
}) => {
  const renderTableRow = (key, name, id, indent) => {
    return (
      <MyTableRow key={key}>
        <MyTableCell
          title={name === NOTHING_HERE ? "Nothing here" : "Down"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            goUpDown(id);
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              goUpDown(id);
            }}
            style={{
              marginRight: 16,
            }}
          >
            <FolderIcon />
          </IconButton>
          {name}
        </MyTableCell>

        <MyTableCell padding="checkbox">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.checked.some((c) => c.id === id)}
                onChange={(e) => handleChange({ id: id, name: name })}
                name="checkedB"
                color="primary"
              />
            }
            label=""
          />
        </MyTableCell>
      </MyTableRow>
    );
  };
  return (
    <>
      <Table aria-label="simple table">
        <TableBody>
          {myCategories.length === 0 &&
            renderTableRow("..key", NOTHING_HERE, null, 0)}
          {myCategories
            .filter((mc) =>
              allCategories?.categories.find((ac) => ac?.parent_id === mc.id)
            )
            .map(({ name, parent_id, parent, id }) => {
              return renderTableRow(id, name, id, parents.length);
            })}
        </TableBody>
      </Table>

      <div style={{ padding: "17px 42px" }}>
        <div style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}>
          {myCategories
            .filter((mc) =>
              allCategories?.categories.every((ac) => ac.parent_id !== mc.id)
            )
            .map((myc) => {
              return (
                <div key={myc.id} style={{ marginBottom: 12 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.checked.some((c) => c.id === myc.id)}
                        onChange={(e) =>
                          handleChange({ id: myc.id, name: myc.name })
                        }
                        name="checkedC"
                        color="primary"
                      />
                    }
                    label={myc.name}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export const CompanyCategories: React.FC<{
  defaultValues: DefaultValue[];
  onChangeValue: (values: Partial<DefaultValue>[]) => void;
  handleClose: () => void;
}> = ({ defaultValues, onChangeValue, handleClose }) => {
  const [parent_id, setParent_id] = React.useState(TOP_CATEGORY_UUID);
  const [parentIdHistory, setParentIdHistory] = React.useState([
    TOP_CATEGORY_UUID,
  ]);
  //   const [selectedCategory, setSelectedCategory] = React.useState(null);

  const [myCategories, setMyCategories] = React.useState<
    { name?: string; parent_id?: string; parent?: any; id: string }[]
  >([]); //myCategories contains categories currently displaied, it's calculated from allCategories
  const [state, setState] = React.useState({
    checked: [], // {id: "", name: ""}[]
  });
  const [searchFilter, setSearchFilter] = React.useState("");

  const handleChange = (
    // event: React.ChangeEvent<HTMLInputElement>,
    category: { id: string; name: string }
  ) => {
    let checked = state.checked;
    if (checked.some((c) => c.id === category.id)) {
      checked = checked.filter((c) => c.id !== category.id);
    } else {
      checked.push(category);
    }
    // setState({ ...state, [event.target.name]: event.target.checked });
    console.log("checked,", checked);
    setState({ checked });
    onChangeValue(checked);
  };

  //   const {
  //     data: myCategories,
  //     refetch: refetchMyCategories,
  //     networkStatus: myCategoriesNetworkStatus,
  //   } = useQuery(GET_MY_CATEGORIES, {
  //     variables: { parent_id },
  //     onCompleted: () => {},
  //     notifyOnNetworkStatusChange: true,
  //   });

  const {
    data: allCategories,
    // refetch: refetchAllCategories,
    // networkStatus: allCategoriesNetworkStatus,
  } = useGetAllCategoriesQuery({
    onCompleted: (answer) => {
      const firstLevelCategories = answer?.categories.filter(
        (c) => c?.parent_id === TOP_CATEGORY_UUID
      );
      console.log("first level categories", firstLevelCategories);
      setMyCategories(firstLevelCategories);
    },
  });

  React.useEffect(() => {
    console.log(searchFilter);
    if (searchFilter && searchFilter.length > 3) {
      setTimeout(() => {
        setParent_id(null);
        setMyCategories(
          allCategories?.categories.filter(
            (c) =>
              c?.name.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0
          )
        );
      }, 0);
    } else if (parent_id !== TOP_CATEGORY_UUID) {
      goUpDown(TOP_CATEGORY_UUID);
    }
  }, [searchFilter]);

  const goBack = () => {
    const myParentIdHistory = cloneDeep(parentIdHistory);
    console.log("myParentIdHistory", myParentIdHistory);
    console.log("parentIdHistory", parentIdHistory);
    myParentIdHistory.pop();
    const last = myParentIdHistory[myParentIdHistory.length - 1];
    console.log("last", last);
    if (!myParentIdHistory.length) {
      console.log("pushing 0");
      myParentIdHistory.push(TOP_CATEGORY_UUID);
    }
    setParentIdHistory(myParentIdHistory);
    console.log("set Parent Id", last);
    if (last) {
      setParent_id(last);
      setMyCategories(
        allCategories?.categories.filter((c) => c?.parent_id === last)
      );
    }
  };

  const goUpDown = (id) => {
    console.log("go up down id", id);
    if (!id) {
      id = TOP_CATEGORY_UUID;
    }
    let newParent;

    //if this is bottom level category - has no parent - we don't go deeper
    if (!allCategories?.categories.find((c) => c?.parent_id === id)) {
      console.log("There is nothing deeper");
      newParent = allCategories?.categories.find((ac) => ac.id === id)
        .parent_id;
    } else {
      newParent = id;
      const myParentHistoryId = cloneDeep(parentIdHistory);
      myParentHistoryId.push(newParent);
      setParentIdHistory(myParentHistoryId);
    }
    setParent_id(newParent);
    setMyCategories(
      allCategories?.categories.filter((c) => c?.parent_id === newParent)
    );
  };

  //show breadcrumps
  let parent = allCategories?.categories?.find((ac) => {
    return ac.id === parent_id;
  });

  const parents = [];
  while (parent && parent.name) {
    parents.push({ name: parent.name, id: parent.id });
    parent = parent?.parent;
  }
  //breadcrumps end

  React.useEffect(() => {
    console.log("SETTING default values");
    const checked = defaultValues.map((d) => ({ id: d.id, name: d.name }));
    setState({ checked });
  }, [defaultValues]);

  return (
    <PaperDiv style={{ maxWidth: "100%", width: "calc(min(100vw, 800px))" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <CategoriesChips
          chipData={state.checked}
          setChecked={(checked) => {
            setState({ checked });
            onChangeValue(checked);
          }}
        />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleClose}
        >
          {T("Done")}
        </Button>
      </div>

      <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "0.5rem",
          }}
        >
          <IconButton onClick={() => goUpDown(TOP_CATEGORY_UUID)}>
            <IconHome />
          </IconButton>
          <IconButton onClick={() => goBack()}>
            <IconBack />
          </IconButton>

          <Breadcrumbs style={{ marginLeft: "2rem", flex: 1 }}>
            {parents.reverse().map((p, i) =>
              i < parents.length - 1 ? (
                <Link
                  onClick={() => goUpDown(p.id)}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {p.name}
                </Link>
              ) : (
                <Typography color="textPrimary">{p.name}</Typography>
              )
            )}
          </Breadcrumbs>

          <InputBase
            placeholder={"Hledat..."}
            defaultValue=""
            style={{ width: 200 }}
            onChange={(e) => setSearchFilter(e.target.value)}
          />
        </div>

        <Divider />

        <ResultTable
          {...{
            parents,
            handleChange,
            myCategories,
            state,
            goUpDown,
            allCategories,
          }}
        />
      </TableContainer>
    </PaperDiv>
  );
};
