import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { Card, MenuItem, Select, IconButton, Tooltip } from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";

import {
  useDataTable,
  DataTable,
  TextFilter,
  SelectFilter,
  DateFilter,
} from "src/sharedComponents/table";
import { useGetSmsDeliveriesQuery } from "src/graphql";

import { T } from "src/utils/translate";
import { observe, dispatch } from "src/utils/eventBus";

export const Deliveries: React.FC<{ userids: number[] }> = ({ userids }) => {
  const deliveriesQuery = useGetSmsDeliveriesQuery({});

  useEffect(() => {
    return observe("centrumsms_historyRefresh", () =>
      deliveriesQuery.refetch()
    );
  }, []);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      deliveriesQuery.refetch();
    }, 20000);
    return () => clearInterval(refreshInterval);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: T("Date"),
        accessor: "created_at",
        Cell: ({ value }) => new Date(value).toLocaleString(),
        Filter: DateFilter(),
        filter: "date",
        width: 10,
      },
      {
        Header: T("Text"),
        id: "text",
        accessor: (it) => it.latest_message_body ?? "",
        width: 20,
      },
      {
        Header: "Počet čekajících",
        id: "count_waiting",
        accessor: (it) =>
          it.message_count - (it.sent_count + it.delivered_count),
        width: 10,
      },
      {
        Header: "Počet odeslaných",
        id: "count_sent",
        accessor: (it) => it.sent_count + it.delivered_count,
        width: 10,
      },
      {
        Header: "Počet doručených",
        id: "count_delivered",
        accessor: (it) => it.delivered_count,
        width: 10,
      },
      /*{
        Header: T("Status"),
        accessor: "status",
        Filter: SelectFilter({
          options: [
            { value: "new", text: T("Status new") },
            { value: "queued", text: T("Status queued") },
            { value: "sent", text: T("Status sent") },
            { value: "delivered", text: T("Status delivered") },
          ],
        }),
        Cell: ({ value }) => T("Status " + value),
        width: 10,
      },*/
    ],
    []
  );

  const { controller } = useDataTable({
    columns,
    query: deliveriesQuery,
    pageSize: 10,
    compact: false,
  });

  return (
    <Card>
      <DataTable controller={controller} />
    </Card>
  );
};
