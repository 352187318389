import * as React from "react";

import {
  Typography,
  TableContainer,
  TableHead,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { AddEditEstablishment, EstUser } from "./types";
import { EditTable } from "./EditTable";

import EditModal from "../../sharedComponents/EditModal";
import { HeaderWithRefresh } from "../../style/styledComponents/components";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import Alert from "@material-ui/lab/Alert";

import { UserType } from "../User/types";
import {
  Phone_numberType,
  Phone_numberEstablishmentType,
} from "../PhoneNumber/types";
import { T } from "../../utils/translate";
import {
  CLOUDINARY_BASE_URL_1,
  CLOUDINARY_BASE_URL_2,
  QUALITY_100,
} from "../../utils/constants";

export const MyTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const ThumbnailImg = styled.img`
  ${(props) => props.height && `height: ${props.height};`}
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

interface EstablishmentSelectProps {
  editEstablishment: any;
  myCompanyEstablishments: { establishments: AddEditEstablishment[] };
  removeEstablishment: any;
  usersData?: UserType[];
  phone_numbersData?: Phone_numberType[];
  globalRefresh?: Function;
  phone_numbers_establishments?: Phone_numberEstablishmentType[];
  establishments_users?: EstUser[];
}

type Anchor = "top" | "left" | "bottom" | "right";

//name is bit misleading. This is showing list of establishments, but also AddEstablishment at the bottom
export const EstablishmentSelect: React.FC<EstablishmentSelectProps> = ({
  myCompanyEstablishments,
  removeEstablishment,
  editEstablishment,
  usersData,
  phone_numbersData,
  phone_numbers_establishments,
  establishments_users,
  globalRefresh,
}) => {
  const [editingId, setEditingId] = React.useState("");

  const tableHeader = [
    T("Name"),
    T("Street"),
    T("City"),
    T("Postcode"),
    T("Phone number"),
    T("Website"),
    T("Email"),
    T("Photo"),
    T("Users"),
    // "Orders",
    // "Pricelists",
    // "Reservations",
  ];

  const renderEstablishmentsTable = (
    establishments: AddEditEstablishment[]
  ) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeader.map((th, i) => (
                <TableCell key={i}>{th}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {establishments.map(
              ({
                id,
                address_city,
                address_postcode,
                address_street,
                name,
                website,
                email,
                photo_url,
              }) => {
                let firstPhoto = "";
                try {
                  firstPhoto = JSON.parse(photo_url)[0];
                } catch (e) {
                  // No photo
                }
                return (
                  <MyTableRow key={id} onClick={(e) => setEditingId(id)}>
                    <TableCell> {name} </TableCell>
                    <TableCell> {address_street} </TableCell>
                    <TableCell> {address_city} </TableCell>
                    <TableCell> {address_postcode} </TableCell>
                    <TableCell>
                      <ul>
                        {phone_numbers_establishments
                          ?.filter((x) => x.establishment?.id === id)
                          .map((x) => (
                            <li key={x?.phone_number?.id}>
                              {`${x.phone_number.name} (${x.phone_number.phone_number})`}
                            </li>
                          ))}
                      </ul>
                    </TableCell>
                    <TableCell> {website} </TableCell>
                    <TableCell> {email} </TableCell>
                    <TableCell>
                      {firstPhoto ? (
                        <ThumbnailImg
                          src={
                            CLOUDINARY_BASE_URL_1 +
                            QUALITY_100 +
                            CLOUDINARY_BASE_URL_2 +
                            firstPhoto
                          }
                          alt="thumbnail"
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    {/* <TableCell> TODO </TableCell>
                    <TableCell> TODO </TableCell>
                    <TableCell> TODO </TableCell> */}
                    <TableCell>
                      <ul style={{ paddingLeft: "16px" }}>
                        {establishments_users
                          ?.filter((eu) => eu?.establishment?.id === id)
                          .map((se) => (
                            <li key={se?.user.id}>
                              {se?.user.first_name +
                                " " +
                                se?.user.last_name +
                                " (" +
                                se?.user.email +
                                ")"}
                            </li>
                          ))}
                      </ul>
                    </TableCell>
                  </MyTableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const editedEstablishment = myCompanyEstablishments?.establishments.find(
    (e) => e.id === editingId
  );

  return !myCompanyEstablishments ||
    !myCompanyEstablishments.establishments ||
    !Array.isArray(myCompanyEstablishments?.establishments) ||
    myCompanyEstablishments?.establishments.length === 0 ? (
    <>
      <Typography variant="h2">{T("Establishments")}</Typography>
      <Typography
        variant="subtitle1"
        style={{ marginBottom: 6, marginTop: -8 }}
      >
        {T("Set up establishments that will show up in the catalog")}
      </Typography>
      <div style={{ marginBottom: "1rem" }}>
        <Alert severity="warning">
          {T("No establishments exist for your company. Create the first one!")}
        </Alert>
      </div>
    </>
  ) : (
    <>
      <HeaderWithRefresh>
        <Typography variant="h2">{T("Establishments")}</Typography>
        <IconButton onClick={() => globalRefresh()}>
          <RefreshIcon />
        </IconButton>
      </HeaderWithRefresh>

      {renderEstablishmentsTable(myCompanyEstablishments.establishments)}

      <EditModal
        title={T("Editing establishment")}
        editObject={editedEstablishment}
        onClose={() => setEditingId("")}
        editComponent={(editObject, closeForm) => (
          <EditTable
            addEditEstablishment={editEstablishment}
            editingId={editingId}
            closeForm={closeForm}
            data={editObject}
            removeEstablishment={removeEstablishment}
            operation={"edit"}
            usersData={usersData}
            phone_numbersData={phone_numbersData}
            globalRefresh={globalRefresh}
            phone_numbers_establishments={phone_numbers_establishments}
            establishments_users={establishments_users}
          />
        )}
      />
    </>
  );
};
