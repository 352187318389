import React from "react";
import { TextInput } from ".";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { T } from "../../utils/translate";

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      marginBottom: "1em",
    },
    option: {
      '&[data-focus="true"]': {
        backgroundColor: "rgb(222,235,255)",
      },
      '&[aria-selected="true"]': {
        backgroundColor: "rgb(235,235,235)",
      },
      background: "white",
      fontWeight: 300,
      "&:hover": {
        background: "rgb(222,235,255)",
      },
    },
    endAdornment: {
      right: 5,
      opacity: 0.5,
      "&:hover": {
        opacity: 0.9,
        color: "red",
      },
    },
  });
});

interface OptionItem {
  value: string;
  label: string;
}

interface SelectProps {
  options: OptionItem[];
  value?: OptionItem;
  onChange: (value: OptionItem) => void;
  label?: string;
}

export const Select: React.FC<SelectProps> = ({
  options,
  value = null,
  onChange,
  label,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      classes={classes}
      selectOnFocus
      blurOnSelect
      clearOnBlur
      disableClearable
      noOptionsText={T("No options")}
      options={options}
      getOptionLabel={(option) => (option?.label ? option.label : "-")}
      getOptionSelected={(option, value) => option?.value === value?.value}
      renderInput={(params) => <TextInput label={label} {...params} />}
      value={value}
      onChange={(event, newValue) => {
        if (newValue) {
          onChange(newValue);
        } else {
          onChange({ value: null, label: "-" });
        }
      }}
    />
  );
};
