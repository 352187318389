import * as React from "react";

import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/ArrowBackIos";

import { T } from "../../utils/translate";
import { Form } from "../../sharedComponents/Form";
import { yup } from "../../utils/yup";
import { TextInput } from "../../sharedComponents/forms";
import { authenticationService } from "../../services/authentication";

import logo from "src/layout/logo.svg";

const FORM_VALIDATION = yup.object().shape({
  email: yup.string().required().email(),
});

const INIT_VALUES: ResetPasswordFormModel = {
  email: "",
};

interface ResetPasswordFormModel {
  email: string;
}

export const ResetPasswordPage: React.FC<{}> = () => {
  const onSubmit = (values: ResetPasswordFormModel) => {
    authenticationService
      .resetPassword(values.email)
      .then(() => {
        // Handled in authenticationService
      })
      .catch((error: string) => {
        alert(T(error));
      });
  };

  return (
    <main className="main">
      <div className="main__content login">
        <div className="col-16">
          <div className="row">
            <a href="https://business.telidoo.com/"><img src={logo} className="logo" /></a>
            <Form<ResetPasswordFormModel>
              onSubmit={onSubmit}
              defaultValues={INIT_VALUES}
              validationSchema={FORM_VALIDATION}
              className="form login__form"
            >
              <h1 className="title-h3 login__form--title">
                {T("Reset_password")}
              </h1>
              <TextInput autohook name="email" label={`${T("Email")} *`} />

              <Button variant="contained" color="primary" type="submit">
                {T("Send")}
              </Button>
              <div>
                <Link to="/login">
                  <Button color="primary" startIcon={<BackIcon />}>
                    {T("Back_to_login")}
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};
