import * as React from "react";

import { T } from "../../utils/translate";

import { ComboBoxMulti } from "../../sharedComponents/forms";
import { UserEstablishmentType } from "./types";
import { EstablishmentType } from "../Establishment/types";

interface UserEstablishmentProps {
  editingId?: string | undefined;
  allEstablishments: EstablishmentType[];
  setEstablishmentsToSend: Function;
  allUsersEstablishments: UserEstablishmentType[];
}

export const UserEstablishment: React.FC<UserEstablishmentProps> = ({
  allEstablishments,
  allUsersEstablishments,
  editingId,
  setEstablishmentsToSend,
}) => {
  const [
    selectedUsersEstablishments,
    setSelectedUsersEstablishments,
  ] = React.useState([]);

  React.useEffect(() => {
    if (allUsersEstablishments && allUsersEstablishments.length) {
      const newValues = allUsersEstablishments
        .filter((x) => x.user?.id === editingId)
        .map((x) => x.establishment)
        .map((u) => ({
          id: u.id,
          name: u.name,
        }));
      setSelectedUsersEstablishments(newValues);
      setEstablishmentsToSend(newValues);
    }
  }, [allUsersEstablishments]);

  //to update parent
  const onChangeValue = (newValues) => {
    setSelectedUsersEstablishments(newValues);
    setEstablishmentsToSend(newValues);
  };

  return (
    <ComboBoxMulti
      options={allEstablishments}
      defaultValues={selectedUsersEstablishments}
      onChangeValue={onChangeValue}
      placeholder={T("Establishments")}
    />
  );
};
