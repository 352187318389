import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import {
  Card,
  MenuItem,
  Select,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";

import {
  useDataTable,
  DataTable,
  TextFilter,
  SelectFilter,
  DateFilter,
} from "src/sharedComponents/table";
import {
  useGetSmsHistoryQuery,
  useUpdateSmsTagMutation,
} from "src/graphql";

import { TagsType } from "./types";
import { T } from "src/utils/translate";
import { observe, dispatch } from "src/utils/eventBus";

const PhoneNumberContactCell: React.FC<{
  row: any;
  value: any;
  setFilter: any;
}> = ({ row, value, setFilter }) => {
  const navigationHistory = useHistory();
  const hasContact =
    row?.original?.contact != null && row?.original?.contact[0];
  const handleReply = (e) => {
    let phoneNumber = value;
    if (hasContact) {
      phoneNumber +=
        " (" +
        row?.original?.contact[0].first_name +
        " " +
        row?.original?.contact[0].last_name +
        ")";
    }
    dispatch("centrumsms_setRecipient", {
      phoneNumber,
    });
    window.scroll(0, 0);
    e.preventDefault();
  };
  const handleOpenContact = () => {
    navigationHistory.push("contacts", { phoneNumber: value });
  };
  const handleSetFilter = () => {
    setFilter("phone_number", value);
  };
  return (
    <>
      <Tooltip title={T("Reply")}>
        <IconButton
          style={{ float: "right", marginTop: hasContact ? 4 : -8 }}
          onClick={handleReply}
          size="small"
        >
          <ReplyIcon />
        </IconButton>
      </Tooltip>
      {hasContact && (
        <Tooltip title={T("Contact")}>
          <a style={{ cursor: "pointer" }} onClick={handleOpenContact}>
            {row?.original?.contact[0].first_name +
              " " +
              row?.original?.contact[0].last_name +
              " "}
          </a>
        </Tooltip>
      )}
      <Tooltip title={T("Filter")}>
        <a style={{ cursor: "pointer" }} onClick={handleSetFilter}>
          {value}
        </a>
      </Tooltip>
    </>
  );
};

export const History: React.FC<{
  tags?: TagsType[];
  userids: number[];
}> = ({ tags, userids }) => {
  const historyQuery = useGetSmsHistoryQuery({
    // @ts-ignore
    variables: { userids },
  });

  const [setTag] = useUpdateSmsTagMutation({
    onCompleted: () => {
      historyQuery.refetch();
    },
  });

  useEffect(() => {
    return observe("centrumsms_historyRefresh", () => historyQuery.refetch());
  }, []);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      if (historyQuery.variables.offset == 0) historyQuery.refetch();
    }, 20000);
    return () => clearInterval(refreshInterval);
  }, [historyQuery?.variables?.offset]);

  const query = {
    loading: historyQuery.loading,
    data: {
      ...historyQuery.data,
      rows: historyQuery.data?.rows?.map((it) => ({ ...it, ...it.smsapi_queue })) ?? undefined,
    },
    refetch: historyQuery.refetch,
  };

  const columns = React.useMemo(
    () => [
      {
        Header: T("Date"),
        accessor: "date",
        Cell: ({ value }) => new Date(value + "Z").toLocaleString(),
        Filter: DateFilter(),
        filter: "date",
        width: 10,
      },
      {
        Header: T("Type"),
        accessor: "type",
        Cell: ({ value }) => (value == 1 ? T("Incoming") : T("Outgoing")),
        Filter: SelectFilter({
          options: [
            { value: 0, text: T("Outgoing") },
            { value: 1, text: T("Incoming") },
          ],
        }),
        filter: "exact",
        width: 5,
      },
      {
        Header: T("Phone number"),
        accessor: "phone_number",
        Cell: PhoneNumberContactCell,
        Filter: TextFilter,
        filterSelector: (value) => [
          {
            _or: [
              { phone_number: { _ilike: "%" + value + "%" } },
              { contact: { first_name: { _ilike: "%" + value + "%" } } },
              { contact: { last_name: { _ilike: "%" + value + "%" } } },
            ],
          },
        ],
        width: 15,
      },
      {
        Header: T("Text"),
        accessor: "body",
        Filter: TextFilter,
        width: 50,
      },
      {
        Header: T("Status"),
        accessor: "status",
        Filter: SelectFilter({
          options: [
            { value: "new", text: T("Status new") },
            { value: "queued", text: T("Status queued") },
            { value: "sent", text: T("Status sent") },
            { value: "delivered", text: T("Status delivered") },
            { value: "received", text: T("Status received") },
            { value: "blocked", text: T("Status blocked") },
          ],
        }),
        Cell: ({ value }) => T("Status " + value),
        width: 10,
      },
      ...(tags?.length > 0
        ? [
            {
              Header: T("Tag"),
              id: "tag",
              accessor: (row) => row.extra?.tag?.id,
              Filter: SelectFilter({
                options: [
                  ...tags?.map((it) => ({ value: it.id, text: it.name })),
                ],
              }),
              filterSelector: (value) => [
                { extra: { tag: { id: { _eq: value } } } },
              ],
              Cell: ({ row }) => (
                <Tooltip title="">
                  <Select
                    value={row.original.extra?.tag?.id || ""}
                    onChange={(e) =>
                      setTag({
                        variables: {
                          id: row.original.id,
                          tag: e.target.value?.toString(),
                        },
                      })
                    }
                  >
                    <MenuItem value={null}>-</MenuItem>
                    {tags?.map((it, i) => (
                      <MenuItem key={i} value={it.id}>
                        {it.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              ),
              width: 10,
            },
          ]
        : []),
    ],
    [tags]
  );

  const { controller } = useDataTable({
    columns,
    query,
    pageSize: 20,
    fixedFilters: [{ phone_number: { _neq: "BOT:support_bot" } }],
    compact: true,
  });

  return (
    <Card style={{ overflow: "auto" }}>
      <DataTable controller={controller} />
    </Card>
  );
};
