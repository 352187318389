export const CLOUDINARY_BASE_URL_1 =
  "https://res.cloudinary.com/dmgujubef/image/upload/";
export const QUALITY_100 = "h_100,q_70,w_100,c_fill";
export const QUALITY_500 = "h_1000,q_70,w_1000,c_fit";
export const CLOUDINARY_BASE_URL_2 = "/";
export const TOP_CATEGORY_UUID = "00000000-0000-0000-0000-000000000001";
export const NOTHING_HERE = "..";

export const ADMIN_ROLE = "00000000-0000-0000-0000-000000000001";
export const HOST_ROLE = "00000000-0000-0000-0000-000000000004";
export const UNVERIFIED_ROLE = "00000000-0000-0000-0000-000000000005";
