export type AppSettings = {
  baseUrl: string;
  authUrl: string;
  authScope: string;
  authClientId: string;
  apiUrl: string;
};

export function getAppSettings(): AppSettings {
  return {
    baseUrl: getFromEnv("PUBLIC_URL"),
    authUrl: getFromEnv("REACT_APP_AUTH_URL"),
    authScope: getFromEnv("REACT_APP_AUTH_SCOPE"),
    authClientId: getFromEnv("REACT_APP_AUTH_CLIENT_ID"),
    apiUrl: getFromEnv("REACT_APP_API_URL"),
  };
}

function getFromEnv(key: string): string {
  const val = process.env[key];
  if (typeof val === "undefined") {
    throw new Error("Missing config. Key: " + key);
  }
  return val;
}
