import React, { useState, useEffect } from "react";

import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import * as XLSX from "xlsx/dist/xlsx.mini.min";
import objectPath from "object-path";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import { Select } from "src/sharedComponents/forms";
import { T } from "src/utils/translate";
import { authenticationService } from "src/services/authentication";

interface ImportColumn {
  key: string;
  name: string;
}

export const ImportModal: React.FC<{
  file?: File;
  columns: ImportColumn[];
  onImport: (data: any) => void;
  onClose: () => void;
}> = ({ file, columns, onImport, onClose }) => {
  const [loadedWorkbook, setLoadedWorkbook] = useState(null);
  const [hasHeaderRow, setHasHeaderRow] = useState(true);
  const [parsedContent, setParsedContent] = useState(null);
  const [columnMapping, setColumnMapping] = useState<Record<string, string>>(
    {}
  );
  useEffect(() => {
    if (!file) return;

    const reader = new FileReader();
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      setLoadedWorkbook(workbook);
    };
    reader.readAsArrayBuffer(file);
  }, [file]);
  useEffect(() => {
    if (!loadedWorkbook) return;
    const first_worksheet = loadedWorkbook.Sheets[loadedWorkbook.SheetNames[0]];
    const content = XLSX.utils.sheet_to_json(first_worksheet, {
      header: hasHeaderRow ? 0 : "A",
    });
    setParsedContent(content);
    if (hasHeaderRow && content && content[0]) {
      const columnMapping = {};
      Object.keys(content[0]).forEach((it) => {
        const found = columns.find((col) => col.name == it || col.key == it);
        if (found) {
          columnMapping[found.key] = it;
        }
      });
      setColumnMapping(columnMapping);
    }
  }, [loadedWorkbook, hasHeaderRow]);

  const handleSave = () => {
    const result = parsedContent.map((it) => {
      const output = {};
      Object.entries(columnMapping).forEach(([key, value]) => {
        if (value && it[value]) {
          let content = it[value] + "";
          if (key == "phone_number") {
            const phoneNumber = parsePhoneNumberFromString(
              content,
              authenticationService.getCountryCode()
            );
            if (phoneNumber && phoneNumber.isValid()) {
              content = phoneNumber.number.toString();
            } else {
              content = "";
            }
          }
          objectPath.set(output, key, content);
        }
      });
      return output;
    });
    onImport(result);
    onClose();
  };

  return (
    <Dialog open={!!file} onClose={() => onClose()} maxWidth="lg">
      <DialogTitle>{T("Import")}</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={hasHeaderRow}
              onChange={() => setHasHeaderRow(!hasHeaderRow)}
              color="primary"
            />
          }
          label={T("Table has header row")}
        />
        {columns.map((column) => {
          const options = [{ value: null, label: "-" }];
          if (parsedContent) {
            Object.keys(parsedContent[0]).forEach((it) => {
              options.push({
                label: it + " (" + parsedContent[0][it] + ")",
                value: it,
              });
            });
          }
          const value = options.find(
            (it) => it.value == columnMapping[column.key]
          );
          return (
            <Select
              key={"select" + column.key}
              label={column.name}
              options={options}
              value={value}
              onChange={(newValue) =>
                setColumnMapping({
                  ...columnMapping,
                  [column.key]: newValue.value,
                })
              }
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary" variant="contained">
          {T("Save")}
        </Button>
        <Button onClick={() => onClose()} color="primary">
          {T("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
