import * as React from "react";

import { useGetPaymentsQuery } from "../../graphql";
import { PaymentsSelect } from "./PaymentsSelect";
import { Typography, Button } from "@material-ui/core";

const LIMIT = 10;

export const Payments: React.FC<{}> = () => {
  const [page, setPage] = React.useState(1);

  const {
    data: paymentsData,
    refetch: paymentsRefetch,
    loading: paymentsLoading,
    networkStatus: paymentsNetworkStatus,
  } = useGetPaymentsQuery({
    variables: {
      limit: LIMIT,
      offset: (page - 1) * LIMIT,
    },
  });
  const pageCount = Math.ceil(
    (paymentsData?.info?.aggregate?.totalCount ?? 0) / LIMIT
  );

  const globalRefresh = () => {
    paymentsRefetch();
  };
  return (
    <div>
      <PaymentsSelect
        myCompanyPayments={{ payments: paymentsData?.rows }}
        paymentsLoading={paymentsLoading}
        pageCount={pageCount}
        page={page}
        setPage={setPage}
        globalRefresh={globalRefresh}
      />
    </div>
  );
};
