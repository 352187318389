import React, { useState, useEffect } from "react";
import { Route, Switch, Link } from "react-router-dom";
import {
  useGetSmsConnectedNumbersQuery,
  useGetSmsTagsQuery,
  useGetSmsTemplatesQuery,
  useGetShoptetEshopsQuery,
} from "../../graphql";
import { observe } from "../../utils/eventBus";

import {
  Typography,
  CircularProgress,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { Send } from "./Send";
import { History } from "./History";
import { Deliveries } from "./Deliveries";
import { Settings } from "./Settings";
import { T } from "../../utils/translate";
import { HeaderWithRefresh } from "../../style/styledComponents/components";
import { ConnectedUserType, TemplateType, TagsType } from "./types";

const MainPage: React.FC<{
  connectedUsersInfo: ConnectedUserType[];
  templatesInfo: TemplateType[];
  tagsInfo: TagsType[];
}> = ({ connectedUsersInfo, templatesInfo, tagsInfo }) => {
  const [tabPage, setTabPage] = useState(0);
  return (
    <>
      <Send connectedUsers={connectedUsersInfo} templates={templatesInfo} />
      <Tabs
        value={tabPage}
        onChange={(e, index) => setTabPage(index)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label={T("History")} />
        <Tab label={T("Deliveries")} />
      </Tabs>
      <div style={{ display: tabPage == 0 ? "block" : "none" }}>
        {connectedUsersInfo.length > 0 && <History tags={tagsInfo} userids={connectedUsersInfo.map(it => it.userid)} />}
      </div>
      <div style={{ display: tabPage == 1 ? "block" : "none" }}>
        {connectedUsersInfo.length > 0 && <Deliveries userids={connectedUsersInfo.map(it => it.userid)} />}
      </div>
    </>
  );
};

export const CentrumSMS: React.FC<{}> = () => {
  const {
    data: connectedUsers,
    refetch: connectedUsersRefetch,
    loading: connectedUsersLoading,
    error: connectedUsersError,
  } = useGetSmsConnectedNumbersQuery();
  const {
    data: tags,
    refetch: tagsRefetch,
    loading: tagsLoading,
    error: tagsError,
  } = useGetSmsTagsQuery();
  const {
    data: templates,
    refetch: templatesRefetch,
    loading: templatesLoading,
    error: templatesError,
  } = useGetSmsTemplatesQuery();

  useEffect(() => {
    return observe("centrumsms_tagsRefresh", () => tagsRefetch());
  }, []);
  useEffect(() => {
    return observe("centrumsms_templatesRefresh", () => templatesRefetch());
  }, []);

  if (connectedUsersLoading) {
    return (
      <>
        <Typography variant="h2">{T("CentrumSMS")}</Typography>
        <CircularProgress />
      </>
    );
  }
  if (connectedUsersError) {
    return (
      <>
        <Typography variant="h2">{T("CentrumSMS")}</Typography>
        <Alert>{T("Error occured")}</Alert>
      </>
    );
  }

  const connectedUsersInfo = (connectedUsers.phone_numbers || [])
    .flatMap((it) => it.centrumsms_info || [])
    .flatMap((it) => {
      if (it.second_sim) {
        return [
          { ...it, second_sim: false },
          {
            ...it,
            second_sim: true,
            phone_number: it.second_sim_number || it.phone_number + " (SIM 2)",
            count: 0,
          },
        ];
      } else {
        return [it];
      }
    })
    .filter((it) => it.activated);
  const tagsInfo = tags?.smsapi_tags;

  const templatesInfo = templates?.smsapi_templates
    .map((template) => {
      if (template.text === "") {
        return;
      } else {
        return template;
      }
    })
    .filter((obj) => obj);

  return connectedUsersInfo.length > 0 ? (
    <>
      <Switch>
        <Route
          exact
          path="/centrumsms/"
          render={(props) => (
            <>
              <HeaderWithRefresh>
                <Link to="/centrumsms/">
                  <Typography variant="h2">{T("CentrumSMS")}</Typography>
                </Link>
                <Link to="/centrumsms/settings">
                  <Button>{T("Settings")}</Button>
                </Link>
              </HeaderWithRefresh>
              <MainPage
                connectedUsersInfo={connectedUsersInfo}
                templatesInfo={templatesInfo}
                tagsInfo={tagsInfo}
              />
            </>
          )}
        />
        <Route
          path="/centrumsms/settings"
          render={(props) => (
            <>
              <HeaderWithRefresh>
                <Link to="/centrumsms/">
                  <Typography variant="h2">{T("CentrumSMS")}</Typography>
                </Link>
                <Link to="/centrumsms/">
                  <Button>{T("Back")}</Button>
                </Link>
              </HeaderWithRefresh>
              <Settings
                connectedUsers={connectedUsersInfo}
                tags={tagsInfo}
                templates={templatesInfo}
              />
            </>
          )}
        />
      </Switch>
    </>
  ) : (
    <>
      <Typography variant="h2">{T("CentrumSMS")}</Typography>
      <Settings connectedUsers={connectedUsersInfo} />
    </>
  );
};
