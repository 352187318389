import React from "react";

import { PhoneNumberSelect } from "./PhoneNumberSelect";
import { PhoneNumberAdd } from "./PhoneNumberAdd";

import { T } from "../../utils/translate";

import {
  useGetEstablishmentsQuery,
  useGetUsersQuery,
  useGetPhoneNumbersQuery,
  useGetPhoneNumbersUsersQuery,
  useGetPhoneNumbersEstablishmentsQuery,
  useInsertPhoneNumberMutation,
  useUpdatePhoneNumberMutation,
  useDeletePhoneNumberMutation,
} from "../../graphql";

export const PhoneNumber: React.FC<{}> = () => {
  const {
    data: phone_numbersData,
    refetch: phone_numbersRefetch,
    // loading: phone_numbersLoading,
    // networkStatus: phone_numbersNetworkStatus,
  } = useGetPhoneNumbersQuery();

  const [
    addPhone_number,
    // { error: errorAddPhone_number, data: dataAddPhone_number },
  ] = useInsertPhoneNumberMutation({
    onCompleted({ insert_phone_numbers_one }) {
      //phone_numbersRefetch();
    },
    onError(error) {
      if (error && JSON.stringify(error).includes("Uniqueness violation")) {
        window.alert(T("Error: This phone number is already used."));
      }
    },
  });

  const [
    editPhone_number,
    // { error: errorEditPhone_number, data: dataEditPhone_number },
  ] = useUpdatePhoneNumberMutation({
    onCompleted(answer) {
      phone_numbersRefetch();
      if (!answer?.update_phone_numbers_by_pk) {
        console.warn("Phone_numbers not updated.");
        alert(T("Phone number not updated. Do you have a permission?"));
      }
    },

    onError(error) {
      if (error && JSON.stringify(error).includes("Uniqueness violation")) {
        window.alert(T("Error: This phone number is already used."));
      } else {
        console.error("general error phone number", error);
      }
    },
  });

  const [
    removePhoneNumber,
    // { error: errorRemovePN, data: dataRemovePN },
  ] = useDeletePhoneNumberMutation({
    onCompleted(answer) {
      console.log("delete anseer", answer);
      if (!answer?.delete_phone_numbers_by_pk?.id) {
        alert(T("Deleting phone number failed. Do you have a permission?"));
      }
      phone_numbersRefetch();
    },
  });

  const {
    data: phone_numberUserData, //these are all phone_numbers linked to  users - I get them all
    refetch: phone_numberUserRefetch,
    // loading: phone_numberUserDataLoading,
    // error: phone_numberUserDataError,
    // networkStatus: phone_numberUserDataNetworkStatus,
  } = useGetPhoneNumbersUsersQuery();
  const {
    data: phone_numberEstablishmentsData, //these are all phone_numbers linked to  users - I get them all
    refetch: phone_numberEstablishmentsRefetch,
    // loading: phone_numberEstablishmentsLoading,
    // error: phone_numberEstablishmentsError,
    // networkStatus: phone_numberEstablishmentsNetworkStatus,
  } = useGetPhoneNumbersEstablishmentsQuery();

  const {
    //duplicated in establishment.tsx, later connect it via Mobex?
    data: establishmentsData, //all establishment of company that phone_number could be linked to
    // refetch: establishmentsRefetch,
    loading: establishmentsLoading,
    // networkStatus: establishmentsNetworkStatus,
  } = useGetEstablishmentsQuery({
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: usersData, //all est of company that phone_number could be linked to
    // refetch: usersRefetch,
    // loading: usersLoading,
    // networkStatus: usersNetworkStatus,
  } = useGetUsersQuery({
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });

  const globalRefresh = () => {
    console.log("globalRefresh");
    phone_numberUserRefetch();
    phone_numberEstablishmentsRefetch();
    phone_numbersRefetch();
  };

  return (
    <div>
      <PhoneNumberSelect
        editPhone_number={editPhone_number}
        phone_numbers={phone_numbersData?.phone_numbers}
        establishmentsLoading={establishmentsLoading}
        usersData={usersData}
        phone_numbers_users={phone_numberUserData?.phone_numbers_users}
        phone_numbers_establishments={
          phone_numberEstablishmentsData?.phone_numbers_establishments
        }
        globalRefresh={globalRefresh}
        establishmentsData={establishmentsData}
        removePhoneNumber={removePhoneNumber}
      />

      <PhoneNumberAdd
        addPhone_number={addPhone_number}
        phone_numbersData={phone_numbersData}
        usersData={usersData}
        phone_numbers_users={phone_numberUserData?.phone_numbers_users}
        establishmentsData={establishmentsData}
        globalRefresh={globalRefresh}
      />
    </div>
  );
};
