import React from "react";
import { Divider } from "@material-ui/core";

export const MyDivider: React.FC<{}> = () => {
  return (
    <React.Fragment>
      <Divider
        variant="middle"
        style={{
          marginTop: 30,
          marginBottom: 30,
          display: "flex",
        }}
      />
    </React.Fragment>
  );
};
