import * as React from "react";
import { T } from "../../utils/translate";

import { useCreatePaymentMutation } from "../../graphql";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableRow,
} from "@material-ui/core";
import { TextInput } from "../../sharedComponents/forms";
import { Form } from "../../sharedComponents/Form";
import { yup } from "../../utils/yup";
import {
  Wrapper,
  MyTableCell,
  MyNBTableCell,
} from "../../style/styledComponents/table";
import { Payment } from "../Payment";

const DEFAULT_VALUES = {
  amount: "",
  description: "",
};

const VALIDATION_SCHEMA = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required")
    .typeError("Amount must be a number")
    .positive("Amount must be greater than zero"),
  description: yup.string(),
});

export const CreateInvoice: React.FC<{}> = () => {
  const [payment, setPayment] = React.useState(null);

  const [
    createPayment,
    { error: errorCreatePayment, data: datacreatePayment },
  ] = useCreatePaymentMutation({
    onCompleted(data) {
      console.log("PAYMENT oncomplete", data?.create_payment?.payment);
      if (data?.create_payment?.payment) {
        console.log("success");
        setPayment(data?.create_payment?.payment);
      }
    },
  });

  console.log("return", payment);
  return (
    <div>
      <Wrapper>
        {!payment?.key && (
          <Form
            defaultValues={DEFAULT_VALUES}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={(
              data: { amount: string; description: string },
              reset: Function
            ) => {
              console.log("submitted data", data);
              if (!parseInt(data.amount, 10)) {
                console.warn("Amount must be number!");
                return;
              }
              createPayment({
                variables: {
                  amount: parseInt(data.amount, 10),
                  description: data.description,
                  currency_code: "CZK",
                },
              });
              reset();
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <MyTableCell>
                    <Typography variant="h2" gutterBottom>
                      Create Invoice
                    </Typography>
                  </MyTableCell>
                </TableRow>
                <TableRow>
                  <MyNBTableCell>
                    <TextInput
                      autohook
                      name="amount"
                      placeholder={T("Amount")}
                      label={T("Amount")}
                    />
                  </MyNBTableCell>
                </TableRow>
                <TableRow>
                  <MyNBTableCell>
                    <TextInput
                      autohook
                      textarea
                      name="description"
                      placeholder={T("Description")}
                      label={T("Description")}
                    />
                  </MyNBTableCell>
                </TableRow>
                <TableRow>
                  <MyTableCell>
                    <Button variant="contained" color="primary" type="submit">
                      Create
                    </Button>
                  </MyTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Form>
        )}

        {payment?.key && (
          <React.Fragment>
            <Payment
              originalPayment={payment}
              back={() => {
                setPayment(null);
              }}
            />
          </React.Fragment>
        )}
      </Wrapper>
    </div>
  );
};
