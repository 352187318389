import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { Typography, Button, IconButton, Card } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Route, Link } from "react-router-dom";
import * as XLSX from "xlsx/dist/xlsx.mini.min";
import objectPath from "object-path";

import { HeaderWithRefresh } from "src/style/styledComponents/components";
import EditModal from "src/sharedComponents/EditModal";
import { DataTable } from "src/sharedComponents/table";
import {
  useGetContactsQuery,
  useGetContactsCustomFieldsQuery,
  useInsertContactMutation,
  useInsertMultipleContactsMutation,
  useUpdateContactMutation,
  useDeleteContactMutation,
  useDeleteContactsMultiMutation,
  useGetShoptetEshopsQuery,
} from "src/graphql";
import { T } from "src/utils/translate";

import { useContactsTable } from "./ContactsTable";
import { EditTable } from "./EditTable";
import { ImportModal } from "./ImportModal";
import { CustomFieldsModal } from "./CustomFieldsModal";

export const Contacts: React.FC<{ location: any }> = (props) => {
  const [editing, setEditing] = useState(null);
  const [importFile, setImportFile] = useState(null);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState(null);

  const navigationHistory = useHistory();
  const queryShoptet = useGetShoptetEshopsQuery();

  const contactsQuery = useGetContactsQuery({
    onCompleted: () => {
      if (searchPhoneNumber) {
        const found = contactsQuery.data.rows.find(
          (it) => it.phone_number == searchPhoneNumber
        );
        if (found) {
          setEditing(found);
        }
      }
    },
  });

  const [insertContactMutation] = useInsertContactMutation({
    onCompleted: () => {
      contactsQuery.refetch();
    },
  });
  const [insertMultipleContactsMutation] = useInsertMultipleContactsMutation({
    onCompleted: () => {
      contactsQuery.refetch();
    },
  });
  const [updateContactMutation] = useUpdateContactMutation({
    onCompleted: () => {
      contactsQuery.refetch();
    },
  });
  const [deleteContactMutation] = useDeleteContactMutation({
    onCompleted: () => {
      contactsQuery.refetch();
    },
  });
  const [deleteContactsMultiMutation] = useDeleteContactsMultiMutation({
    onCompleted: () => {
      contactsQuery.refetch();
    },
  });
  const globalRefresh = () => {
    contactsQuery.refetch();
  };

  useEffect(() => {
    setSearchPhoneNumber(props?.location?.state?.phoneNumber || null);
  }, [props?.location?.state]);

  // Table controller
  const contactsCustomFields = useGetContactsCustomFieldsQuery();
  const customFields = React.useMemo(
    () =>
      contactsCustomFields?.data?.rows?.map((it) => ({
        ...it.custom_field,
        name: it.custom_field.name || "",
        key: it.custom_field.id,
      })) || [],
    [contactsCustomFields?.data]
  );
  const shoptet = queryShoptet.data?.shoptet_eshops?.length > 0;

  const { controller } = useContactsTable({
    contactsQuery,
    setEditing,
    customFields,
    checkboxes: true,
    shoptet,
  });

  // Import settings
  const importColumns = [
    { key: "title_before_name", name: T("Title before") },
    { key: "first_name", name: T("First name") },
    { key: "last_name", name: T("Last name") },
    { key: "title_after_name", name: T("Title after") },
    { key: "phone_number", name: T("Phone number") },
    { key: "email", name: T("Email") },
    { key: "address_street", name: T("Street") },
    { key: "address_city", name: T("City") },
    { key: "address_postcode", name: T("Postcode") },
    { key: "address_country", name: T("Country") },
    ...customFields.map((it) => ({ ...it, key: "custom_values." + it.key })),
  ];
  const handleImport = (result) => {
    console.log(result);
    insertMultipleContactsMutation({ variables: { contacts: result } });
  };
  const handleExport = () => {
    const input = [...contactsQuery.data.rows];
    const data = input.map((it) => {
      const output = {};
      importColumns.forEach(({ key, name }) => {
        try {
          output[name] = objectPath.get(it, key) || "";
        } catch (error) {
          // Ignore
        }
      });
      return output;
    });
    console.log(data);
    const sheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Contacts");
    XLSX.writeFile(workbook, "Telidoo_Contacts_Export.xlsx");
  };
  const handleMassDelete = () => {
    const ids = controller.selectedFlatRows.map((it) => it.original.id);
    deleteContactsMultiMutation({ variables: { ids } });
  };
  const handleSend = () => {
    const recipients = controller.selectedFlatRows
      .map(
        (it) =>
          it.original.phone_number +
          " (" +
          it.original.first_name +
          " " +
          it.original.last_name +
          ")"
      )
      .join("\n");
    navigationHistory.push("centrumsms", { recipients });
  };
  return (
    <>
      <ImportModal
        file={importFile}
        columns={importColumns}
        onImport={handleImport}
        onClose={() => setImportFile(null)}
      />
      <Route path="/contacts/customfields">
        <CustomFieldsModal contactsCustomFields={contactsCustomFields} />
      </Route>

      <HeaderWithRefresh>
        <div>
          <Typography variant="h2">{T("Contacts")}</Typography>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 6, marginTop: -8 }}
          >
            {T("Manage your customers and business contacts")}
          </Typography>
        </div>
        <IconButton onClick={() => globalRefresh()}>
          <RefreshIcon />
        </IconButton>
      </HeaderWithRefresh>

      <div style={{ marginBottom: "1rem" }}>
        <Button variant="contained" onClick={() => setEditing("new")}>
          {T("New contact")}
        </Button>

        <input
          accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet,text/csv"
          id={`file-import`}
          type="file"
          onChange={(e: any) => {
            e.preventDefault();
            if (e.dataTransfer) setImportFile(e.dataTransfer.files[0]);
            else if (e.target) setImportFile(e.target.files[0]);
          }}
          style={{ display: "none" }}
        />
        <label htmlFor={`file-import`}>
          <Button
            color="primary"
            component="span"
            style={{ textAlign: "center", lineHeight: 1.4 }}
          >
            {T("Import") + " XLS"}
          </Button>
        </label>

        <Button onClick={handleExport}>{T("Export") + " XLS"}</Button>

        <Link to="/contacts/customfields" style={{ justifySelf: "flex-end" }}>
          <Button style={{ float: "right" }}>{T("Custom fields")}</Button>
        </Link>
      </div>

      {controller.selectedFlatRows.length > 0 && (
        <Card
          style={{
            marginBottom: "5px",
            padding: "5px 15px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1" style={{ marginRight: "30px" }}>
            {T("Selected")} {controller.selectedFlatRows.length}
          </Typography>
          <Button
            size="small"
            // @ts-ignore
            variant="delete"
            onClick={handleMassDelete}
          >
            {T("Delete")}
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleSend}
            style={{ marginLeft: 15 }}
          >
            {T("Send")}
          </Button>
        </Card>
      )}

      <DataTable controller={controller} />

      <EditModal
        title={editing == "new" ? T("New contact") : T("Editing contact")}
        editObject={editing}
        onClose={() => setEditing(null)}
        editComponent={(editObject, closeForm) => (
          <EditTable
            insertMutation={insertContactMutation}
            updateMutation={updateContactMutation}
            deleteMutation={deleteContactMutation}
            editObject={editObject}
            closeForm={closeForm}
            customFields={customFields}
          />
        )}
      />
    </>
  );
};
