import * as React from "react";

import { useGetTransactionsQuery } from "../../graphql";
import { useQuery, useMutation } from "@apollo/client";
import { TransactionsSelect } from "./TransactionsSelect";
import { Typography, Button } from "@material-ui/core";
import { Deposit } from "./Deposit";
import { Withdraw } from "./Withdraw";
import { MyDivider } from "../../style/styledComponents/Mui";

const LIMIT = 10;

export const Transactions: React.FC<{}> = () => {
  const [page, setPage] = React.useState(1);

  const {
    data: transactionsData,
    refetch: transactionsRefetch,
    loading: transactionsLoading,
    networkStatus: transactionsNetworkStatus,
  } = useGetTransactionsQuery({
    variables: {
      limit: LIMIT,
      offset: (page - 1) * LIMIT,
    },
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });
  const pageCount = Math.ceil(
    (transactionsData?.info?.aggregate?.totalCount ?? 0) / LIMIT
  );

  const globalRefresh = () => {
    console.log("globalRefresh");
    // allTransactionsRefetch();
    transactionsRefetch();
  };
  return (
    <div>
      <div style={{ maxWidth: 1000, margin: "0 auto" }}>
        <Typography variant="h2">
          Total balance:&nbsp;
          {transactionsData?.info?.aggregate?.sumAmount?.amount ?? "0"} CZK
        </Typography>

        <MyDivider />

        <Deposit />

        <div style={{ height: 20 }} />

        <Withdraw />

        <div style={{ height: 20 }} />

        <TransactionsSelect
          myCompanyTransactions={transactionsData}
          transactionsLoading={transactionsLoading}
          pageCount={pageCount}
          page={page}
          setPage={setPage}
          globalRefresh={globalRefresh}
        />
      </div>
    </div>
  );
};
