import * as React from "react";

import {
  Typography,
  TableContainer,
  TableHead,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import RefreshIcon from "@material-ui/icons/Refresh";
import Alert from "@material-ui/lab/Alert";

import { Phone_numberType, Phone_numberEstablishmentType } from "./types";

import { EditTable } from "./EditTable";
import EditModal from "../../sharedComponents/EditModal";

import { HeaderWithRefresh } from "../../style/styledComponents/components";
import { AddEditEstablishment } from "../Establishment/types";

import { T } from "../../utils/translate";
import { UserType, UserPhone_numberType } from "../User/types";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { authenticationService } from "src/services/authentication";

export const MyTableCell = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const MyPhotoTableCell = styled(MyTableCell)`
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0px;
`;

export const MyTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const ThumbnailImg = styled.img`
  ${(props) => props.height && `height: ${props.height};`}
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

interface Phone_numberSelectProps {
  editPhone_number: any;
  phone_numbers: Phone_numberType[];
  establishmentsLoading: any;
  establishmentsData?: { establishments: AddEditEstablishment[] };
  usersData?: { users: UserType[] };
  globalRefresh?: Function;
  removePhoneNumber: Function;
  phone_numbers_users?: UserPhone_numberType[];
  phone_numbers_establishments: Phone_numberEstablishmentType[];
}

type Anchor = "top" | "left" | "bottom" | "right";

//name is bit misleading. This is showing list of phone_numbers, but also AddPhone_number at the bottom
export const PhoneNumberSelect: React.FC<Phone_numberSelectProps> = ({
  phone_numbers,
  editPhone_number,
  usersData,
  phone_numbers_users,
  globalRefresh,
  removePhoneNumber,
  establishmentsData,
  phone_numbers_establishments,
}) => {
  const [editingId, setEditingId] = React.useState("");

  const tableHeader = [
    T("Phone number"),
    T("Name"),
    T("Active"),
    T("Linked user"),
    T("Linked establishments"),
    T("Linked app"),
  ];

  const renderPhone_numbersTable = (phone_numbers: Phone_numberType[]) => {
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeader.map((th, i) => (
                <MyTableCell key={i}>{th}</MyTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {phone_numbers.map(
              ({ id, name, phone_number, active, app_connection_active }) => {
                let parsedPhoneNumber = parsePhoneNumberFromString(
                  phone_number,
                  authenticationService.getCountryCode()
                )?.format("INTERNATIONAL");
                if (!parsedPhoneNumber) {
                  parsedPhoneNumber = phone_number;
                }
                return (
                  <MyTableRow
                    key={id}
                    onClick={(e) => {
                      setEditingId(id);
                    }}
                  >
                    <MyTableCell>{parsedPhoneNumber}</MyTableCell>
                    <MyTableCell>{name} </MyTableCell>
                    <MyTableCell style={{ opacity: 0.8 }}>
                      {active ? <CheckCircleIcon /> : <CancelIcon />}
                    </MyTableCell>
                    <MyTableCell>
                      <span style={{ margin: 0 }}>
                        {phone_numbers_users
                          ?.filter((x) => x.phone_number?.id === id)
                          .map((x) => (
                            <span key={x?.user?.id}>
                              {`${x.user.first_name} ${x.user.last_name} (${x.user.email})`}
                            </span>
                          ))}
                      </span>
                    </MyTableCell>
                    <MyTableCell>
                      <span>
                        {phone_numbers_establishments
                          ?.filter((x) => x.phone_number?.id === id)
                          .map((x) => (
                            <span key={x?.establishment?.id}>
                              {x.establishment.name}
                            </span>
                          ))}
                      </span>
                    </MyTableCell>
                    <MyTableCell style={{ opacity: 0.8 }}>
                      {app_connection_active === true && <CheckCircleIcon />}
                      {app_connection_active === false && <CancelIcon />}
                    </MyTableCell>
                  </MyTableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  if (
    !phone_numbers ||
    !Array.isArray(phone_numbers) ||
    phone_numbers.length === 0
  ) {
    return (
      <Alert severity="warning">
        {T("No phone numbers exist for your company. Create one!")}
      </Alert>
    );
  }

  return (
    <div key={"phone_number_select"}>
      <HeaderWithRefresh>
        <div>
          <Typography variant="h2">{T("Phone numbers")}</Typography>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 6, marginTop: -8 }}
          >
            {T("Add company phone numbers to identify them in incoming calls")}
          </Typography>
        </div>
        <IconButton onClick={() => globalRefresh()}>
          <RefreshIcon />
        </IconButton>
      </HeaderWithRefresh>

      {renderPhone_numbersTable(phone_numbers)}

      <EditModal
        title={T("Editing phone number")}
        editObject={phone_numbers?.find((e) => e.id === editingId)}
        onClose={() => setEditingId("")}
        editComponent={(editObject, closeForm) => (
          <EditTable
            addEditPhone_number={editPhone_number}
            editingId={editingId}
            closeForm={closeForm}
            data={editObject}
            operation={"edit"}
            usersData={usersData}
            globalRefresh={globalRefresh}
            removePhoneNumber={removePhoneNumber}
            phone_numbers_users={phone_numbers_users}
            establishmentsData={establishmentsData}
            phone_numbers_establishments={phone_numbers_establishments}
          />
        )}
      />
    </div>
  );
};
