import * as React from "react";
import Button from "@material-ui/core/Button";
import { Redirect, Link } from "react-router-dom";

import { yup } from "src/utils/yup";
import { TextInput } from "src/sharedComponents/forms";
import { authenticationService } from "src/services/authentication";
import { T } from "src/utils/translate";
import { Form } from "src/sharedComponents/Form";
import logo from "src/layout/logo.svg"

const FORM_VALIDATION = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required(),
});

const INIT_VALUES: LoginFormModel = {
  email: "",
  password: "",
};

interface LoginFormModel {
  email: string;
  password: string;
}

export const LoginPage: React.FC<{}> = () => {
  const [redirectVerification, setRedirectVerification] = React.useState(false);

  const onSubmit = (values: LoginFormModel) => {
    authenticationService
      .login(values.email, values.password)
      .then((data: any) => {
        // Handled in authenicaionService
      })
      .catch((error: string) => {
        if (error == "verification_pending") {
          setRedirectVerification(true);
        } else {
          alert(T(error));
        }
      });
  };

  if (redirectVerification) {
    return <Redirect to="/verification" />;
  }

  return (
    <main className="main">
      <div className="main__content login">
        <div className="col-16">
          <div className="row">
            <a href="https://business.telidoo.com/"><img src={logo} className="logo" /></a>
            <Form<LoginFormModel>
              onSubmit={onSubmit}
              defaultValues={INIT_VALUES}
              validationSchema={FORM_VALIDATION}
              className="form login__form"
            >
              <h1 className="title-h3 login__form--title">{T("Login")}</h1>
              <TextInput autohook name="email" label={`${T("Email")} *`} />

              <TextInput
                autohook
                name="password"
                label={`${T("Password")} *`}
                type="password"
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="large"
                >
                  {T("Log in")}
                </Button>
                <div style={{ marginTop: "1rem" }}>
                  <Link to="/registration">
                    <Button color="primary">{T("Registration")}</Button>
                  </Link>
                  <Link to="/resetpassword">
                    <Button color="primary">{T("Reset_password")}</Button>
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </main>
  );
};
