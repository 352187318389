import React from "react";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Checkbox, IconButton } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";

import { TextInput } from "./TextInput";
import { T } from "../../utils/translate";

export const useStyles = makeStyles((theme) => {
  return createStyles({
    icon: {
      color: theme.palette.grey[500],
      right: 12,
      position: "absolute",
      userSelect: "none",
      pointerEvents: "none",
    },
    option: {
      '&[data-focus="true"]': {
        backgroundColor: "rgb(222,235,255)",
      },
      '&[aria-selected="true"]': {
        backgroundColor: "rgb(225,235,255)",
      },
      background: "white",
      fontWeight: 300,
      "&:hover": {
        background: "rgb(43,72,164)",
        color: "#fff",
      },
      "& > span": {
        marginRight: 10,
        fontSize: 18,
      },
      paddingRight: 0,
    },
    endAdornment: {
      right: 5,
      opacity: 0.5,
      "&:hover": {
        opacity: 0.9,
        color: "red",
      },
    },
  });
});

const filter = createFilterOptions<OptionType>();

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ComboBoxMulti: React.FC<{
  options: OptionType[];
  defaultValues?: OptionType[];
  onChangeValue: (value: OptionType[]) => void;
  addNewItem?: (value: string) => void;
  removeOption?: (id: string) => void;
  placeholder: string;
}> = ({
  options,
  onChangeValue,
  defaultValues,
  placeholder,
  addNewItem,
  removeOption = undefined,
}) => {
  const [value, setValue] = React.useState<OptionType[]>([]);

  React.useEffect(() => {
    setValue(defaultValues);
  }, [defaultValues]);

  const classes = useStyles();

  function renderOption(option: OptionType) {
    if (option.id === "1") {
      // When does this happen?
      return <React.Fragment>{option.name}</React.Fragment>;
    }

    const striked = option.disabled
      ? { textDecorationLine: "line-through" }
      : {};
    const styles = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    };

    return (
      <div style={{ ...striked, ...styles }}>
        <div>
          <React.Fragment>
            {!option?.type ? (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={value?.some((v) => v.id === option.id)}
              />
            ) : (
              <IconButton>
                <AddCircleOutlineIcon />
              </IconButton>
            )}
          </React.Fragment>
          {option.name}
        </div>
        {removeOption && !option.disabled && option?.type !== "add" && (
          <IconButton
            style={{
              fontSize: 18,
              padding: "0px 20px",
              cursor: "pointer",
            }}
            title={T("Remove")}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              removeOption(option.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  }

  if (options) {
    return (
      <Autocomplete
        multiple
        freeSolo
        value={value}
        onChange={(_, newValues: OptionType[] | null) => {
          // TODO is this OK code?
          console.log("newValues", newValues);
          if (typeof newValues === "string") {
            console.error("!!newVaue is string"); //not using this in new version of mui
          } else if (Array.isArray(newValues)) {
            const newValue = newValues.find((v) => v?.type === "add");
            console.log("-->newValue", newValue);

            if (addNewItem && newValue) {
              addNewItem(newValue.inputValue);
            } else {
              //just selecting checkbox
              console.log("setValue(newValues);", newValues);
              //just for now, I ignore the options which user added
              newValues = newValues.filter((nv) => !nv.type);
              setValue(newValues);
              onChangeValue(newValues);
            }
          } else {
            console.error("newValues is not array", newValues);
          }
        }}
        disableCloseOnSelect
        options={options}
        classes={{
          option: classes.option,
          endAdornment: classes.endAdornment,
        }}
        autoHighlight
        getOptionSelected={(option, value) => value.id === option.id}
        getOptionDisabled={(option) => option.disabled}
        renderOption={renderOption}
        renderInput={(params) => {
          return (
            <TextInput
              {...params}
              label={placeholder}
              placeholder={placeholder}
            />
          );
        }}
        noOptionsText={T("No options")}
        filterOptions={(options, params) => {
          const filtered = filter(options, params) as OptionType[];

          if (addNewItem) {
            filtered.unshift({
              inputValue: params.inputValue,
              name: T("Add") + " " + params.inputValue,
              type: "add",
            });
          }

          return filtered;
        }}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.name;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disableClearable
      />
    );
  }

  return <TextInput label={placeholder} placeholder={T("Loading...")} />;
};

interface OptionType {
  id?: string;
  name?: string;
  inputValue?: string;
  type?: "add";
  disabled?: boolean;
}
