import React from "react";
import {
  Input,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { DatePicker } from "@material-ui/pickers";
import { T } from "src/utils/translate";

interface FilterProps {
  column: {
    filterValue: string;
    setFilter: (any) => void;
  };
}

export const TextFilter: React.FC<FilterProps> = ({
  column: { filterValue, setFilter },
}) => {
  return (
    <Input
      placeholder={T("Filter...")}
      value={filterValue || ""}
      onChange={(e) => setFilter(e.target.value || undefined)}
      endAdornment={
        filterValue && (
          <InputAdornment position="end">
            <IconButton onClick={(e) => setFilter(undefined)}>
              <CancelIcon fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

export const SelectFilter: ({ options }) => React.FC<FilterProps> = ({
  options,
}) => ({ column: { filterValue, setFilter } }) => {
  return (
    <Select
      value={filterValue || ""}
      onChange={(e) =>
        setFilter(e.target.value != null ? e.target.value : undefined)
      }
    >
      <MenuItem value={null}>-</MenuItem>
      {options.map((it, i) => (
        <MenuItem key={i} value={it.value}>
          {it.text}
        </MenuItem>
      ))}
    </Select>
  );
};

export const DateFilter: () => React.FC<FilterProps> = () => ({
  column: { filterValue, setFilter },
}) => {
  return (
    <DatePicker
      placeholder={T("Filter...")}
      value={filterValue ? new Date(filterValue) : null}
      onChange={(e) => setFilter(e ? e.getTime() : undefined)}
      autoOk
      clearable
      disableFuture
      format="dd.MM.yy"
      cancelLabel=""
      clearLabel={T("Cancel")}
      InputProps={{
        endAdornment: filterValue && (
          <IconButton onClick={(e) => setFilter(undefined)}>
            <CancelIcon fontSize="small" />
          </IconButton>
        ),
      }}
    />
  );
};
