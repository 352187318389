import React, { useEffect } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import { DataTable } from "src/sharedComponents/table";
import { T } from "src/utils/translate";

import {
  useGetContactsQuery,
  useGetContactsCustomFieldsQuery,
  useGetShoptetEshopsQuery,
} from "src/graphql";
import { useContactsTable } from "./ContactsTable";

export const SelectModal: React.FC<{
  isOpen: boolean;
  onSubmit: (data: any) => void;
  onClose: () => void;
}> = ({ isOpen, onSubmit, onClose }) => {
  const contactsQuery = useGetContactsQuery({});
  const contactsCustomFields = useGetContactsCustomFieldsQuery();
  const queryShoptet = useGetShoptetEshopsQuery();

  const customFields = React.useMemo(
    () =>
      contactsCustomFields?.data?.rows?.map((it) => ({
        ...it.custom_field,
        name: it.custom_field.name || "",
        key: it.custom_field.id,
      })) || [],
    [contactsCustomFields?.data]
  );
  const shoptet = queryShoptet.data?.shoptet_eshops?.length > 0;
  const { controller } = useContactsTable({
    contactsQuery,
    customFields,
    checkboxes: true,
    compact: true,
    fixedFilters: [{ phone_number: { _neq: "" } }],
    shoptet,
  });

  useEffect(() => {
    if (!isOpen) {
      controller.toggleAllRowsSelected(false);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth="lg">
      <DialogTitle>{T("Select contacts")}</DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <DataTable controller={controller} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            onSubmit(controller.selectedFlatRows.map((it) => it.original))
          }
          color="primary"
          variant="contained"
        >
          {T("Save")}
        </Button>
        <Button onClick={() => onClose()} color="primary">
          {T("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
