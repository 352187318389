import * as React from "react";

import { useGetTerminalStatementsQuery } from "../../graphql";
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { HeaderWithRefresh } from "src/style/styledComponents/components";
import { T } from "src/utils/translate";
import {
  DataTable,
  DateFilter,
  useDataTable,
} from "src/sharedComponents/table";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloseIcon from "@material-ui/icons/Close";
import { theme } from "src/layout/theme";

export const PaymentTerminalsStatements: React.FC<{}> = () => {
  const [showingStatement, setShowingStatement] = React.useState("");
  const terminalStatementsQuery = useGetTerminalStatementsQuery({
    variables: {
      offset: 0,
      limit: 20,
    },
    errorPolicy: "all",
    notifyOnNetworkStatusChange: true,
  });
  const globalRefresh = () => {
    terminalStatementsQuery.refetch();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: T("Date"),
        accessor: "date",
        Cell: ({ value }) => new Date(value).toLocaleDateString(),
        Filter: DateFilter(),
        filter: "date",
        width: 10,
      },
      {
        Header: "ID",
        accessor: "acquirers_id",
        width: 10,
      },
      {
        Header: "Typ",
        accessor: "type",
        Cell: ({ value }) =>
          value === "tx" ? "Výpis transakcí" : "Výpis provedených úhrad",
        width: 10,
      },
      {
        Header: "",
        accessor: "url",
        Cell: ({ value, row }) => {
          const filename = row.values.type + "_" + row.values.date + ".pdf";
          return (
            <>
              <Button
                color="primary"
                style={{ float: "right", color: theme.palette.primary.main }}
                onClick={() => setShowingStatement(value)}
              >
                Zobrazit
              </Button>
              <Button
                href={value + "_" + filename}
                download={filename}
                color="primary"
                style={{ float: "right", color: theme.palette.primary.main }}
              >
                Stáhnout
              </Button>
            </>
          );
        },
        width: 10,
      },
    ],
    []
  );

  const { controller } = useDataTable({
    columns,
    query: terminalStatementsQuery,
    pageSize: 20,
    compact: true,
  });

  return (
    <div>
      <Dialog
        open={showingStatement !== ""}
        onClose={() => setShowingStatement("")}
        maxWidth="xl"
      >
        <IconButton
          onClick={() => setShowingStatement("")}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <iframe
            src={
              "https://docs.google.com/gview?url=" +
              encodeURIComponent(showingStatement + "-view.pdf") +
              "&embedded=true"
            }
            style={{
              width: "80vw",
              height: "80vh",
              border: "none",
              marginTop: 50,
            }}
            frameBorder={0}
          ></iframe>
        </DialogContent>
      </Dialog>
      <HeaderWithRefresh>
        <div>
          <Typography variant="h2">
            {T("Payment Terminals Statements")}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginBottom: 6, marginTop: -8 }}
          >
            {T("Manage your payment terminals statements")}
          </Typography>
        </div>
        <div>
          <IconButton onClick={() => globalRefresh()}>
            <RefreshIcon />
          </IconButton>
        </div>
      </HeaderWithRefresh>

      {terminalStatementsQuery.loading && <p>Načítání...</p>}
      <DataTable controller={controller} />
    </div>
  );
};
