import * as React from "react";

import {
  Typography,
  Button,
  Table,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { TextInput } from "../../sharedComponents/forms";
import { Form } from "../../sharedComponents/Form";
import { yup } from "../../utils/yup";
import {
  MyTableCell,
  FirstTableCell,
  SecondTableCell,
} from "../../style/styledComponents/table";
import styled from "styled-components";
import * as QRCode from "qrcode.react";

const MySecondTableCell = styled(SecondTableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
`;

const MyFirstTableCell = styled(FirstTableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const Invoice: React.FC<{ payment: any }> = ({ payment }) => {
  return (
    <div>
      <Typography variant="h2" gutterBottom>
        Invoice created:
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <MyFirstTableCell>Amount</MyFirstTableCell>
            <MySecondTableCell>
              {payment?.amount} {payment?.currency_code}
            </MySecondTableCell>
          </TableRow>
          <TableRow>
            <MyFirstTableCell>Key</MyFirstTableCell>
            <MySecondTableCell>{payment?.key}</MySecondTableCell>
          </TableRow>
          <TableRow>
            <MyFirstTableCell>Quick Code</MyFirstTableCell>
            <MySecondTableCell>{payment?.quick_code}</MySecondTableCell>
          </TableRow>
          <TableRow>
            <MyFirstTableCell>Description</MyFirstTableCell>
            <MySecondTableCell>{payment?.description}</MySecondTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
