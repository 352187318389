import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { AppRoute } from "./sharedComponents/Routes/AppRoute";

import { Page404 } from "./pages/Page404";
import { Page403 } from "./pages/Page403";

import { Start } from "./pages/Start";
import { LoginPage } from "./pages/LoginPage";
import {
  RegistrationPage,
  VerificationPendingPage,
} from "./pages/RegistrationPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { ActivationPage } from "./pages/ActivationPage";

import { MainPage } from "./pages/MainPage";
import { Company } from "./pages/Company";
import { Role } from "./pages/Role";
import { User } from "./pages/User";
import { Establishment } from "./pages/Establishment";
import { Categories } from "./pages/Categories";
import { PhoneNumber } from "./pages/PhoneNumber";
import { ChangePassword } from "./pages/ChangePassword";
import { CentrumSMS } from "./pages/CentrumSMS";
import { Contacts } from "./pages/Contacts";
import { Profile } from "./pages/Profile";
import { Payments } from "./pages/Payments";
import { CreateInvoice } from "./pages/CreateInvoice";
import { Transactions } from "./pages/Transactions";
import { PaymentTerminals } from "./pages/PaymentTerminals";
import { PaymentTerminalsStatements } from "./pages/PaymentTerminals/PaymentTerminalsStatements";

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/start" component={Start} />
      <Route path="/login" component={LoginPage} />
      <Route path="/registration" component={RegistrationPage} />
      <Route path="/verification" component={VerificationPendingPage} />
      <Route path="/resetpassword" component={ResetPasswordPage} />
      <Route path="/activate/:guid" component={ActivationPage} />

      <AppRoute path="/change-password" component={ChangePassword} />
      <AppRoute path="/profile" component={Profile} />
      <AppRoute path="/company" component={Company} />
      <AppRoute path="/role" component={Role} />
      <AppRoute path="/user" component={User} />
      <AppRoute path="/establishment" component={Establishment} />
      <AppRoute path="/payments" component={Payments} />
      <AppRoute path="/create-invoice" component={CreateInvoice} />
      <AppRoute path="/transactions" component={Transactions} />
      <AppRoute path="/categories" component={Categories} />
      <AppRoute path="/phone_number" component={PhoneNumber} />
      <AppRoute path="/centrumsms" component={CentrumSMS} />
      <AppRoute path="/contacts" component={Contacts} />
      <AppRoute
        path="/payment-terminals/statements"
        component={PaymentTerminalsStatements}
      />
      <AppRoute path="/payment-terminals" component={PaymentTerminals} />

      <Route path="/403" component={Page403} />
      <Route path="/404" component={Page404} />

      <AppRoute path="/" component={MainPage} />

      <Route component={Page404} />
    </Switch>
  );
};
