import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import Yup from "yup";
import { yupResolver } from "@hookform/resolvers";

interface Props<T> {
  defaultValues: T;
  validationSchema: Yup.ObjectSchema | Yup.Lazy;
  onSubmit: (data: T, reset: () => void) => void;
  className?: string;
}

export const Form: <T extends any>(
  props: React.PropsWithChildren<Props<T>>
) => React.ReactElement = (props) => {
  const {
    defaultValues,
    validationSchema,
    onSubmit,
    children,
    className,
  } = props;

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const onSubmitInner = (values: any) => {
    onSubmit(values, formMethods.reset);
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit(onSubmitInner)}
        className={className}
      >
        {children}
      </form>
    </FormProvider>
  );
};
