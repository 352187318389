export const initApiLang: (lang: Map<string, string>) => void = (lang) => {
  lang.set("invalid_details", "Neplatný email nebo heslo");
  lang.set(
    "too_many_requests",
    "Rate limiting - příliš mnoho požadavků najednou, uživatel musí počkat"
  );
  lang.set("region_invalid", "Neplatný region, zatím pouze CZ");
  lang.set(
    "company_already_exists",
    "Firma se stejným IČO je již zaregistrovaná. Pokud firma paří Vám a k účtu nemáte přístup, kontaktujte nás."
  );
  lang.set(
    "company_invalid",
    "IČO není platné nebo nebylo nalezeno v rejstříku"
  );
  lang.set(
    "email_already_exists",
    "Jiný uživatel se stejným emailem již existuje"
  );
  lang.set("email_invalid", "Email je neplatný");
  lang.set("request_id_invalid", "Neplatné ID potvrzení - špatný odkaz");
  lang.set("weak_password", "Slabé heslo. Musí mít alespoň 8 znaků");
};
