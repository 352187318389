import * as React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grow, IconButton } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

import { Typography, Paper, CircularProgress } from "@material-ui/core";
import CropUploadComponent from "./CropUploadComponent";
import ImageCarousel from "./ImageCarousel";

import {
  CLOUDINARY_BASE_URL_1,
  CLOUDINARY_BASE_URL_2,
  QUALITY_100,
} from "../../utils/constants";
import { T } from "../../utils/translate";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "220px",
      display: "flex",
      alignItems: "center",
      "& > *": {
        marginLeft: "20px",
        marginRight: "20px",
      },
    },
    input: {
      display: "none",
    },
    image: {
      width: "121px",
      height: "121px",
      objectFit: "cover",
      cursor: "pointer",
    },
    tilebar: {
      background: "#00000000",
    },
  })
);

export const ImgMaster: React.FC<{
  operation: string;
  onFileChange: Function;
  onFileRemove: Function;
  photoUrls: string[];
  titleText?: string;
  single?: boolean;
  noCrop?: boolean;
}> = ({
  operation,
  onFileChange,
  onFileRemove,
  photoUrls,
  titleText,
  noCrop = false,
}) => {
  const classes = useStyles();
  const [carouselState, setCarouselState] = React.useState(false);
  const [activeImg, setActive] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);

  const openCarouselOrPreview = (idx) => {
    setActive(idx);
    setCarouselState(true);
  };

  const deleteImage = (photoUrl) => {
    if (window.confirm(T("Really delete?"))) {
      onFileRemove(photoUrl);
    }
  };

  const renderMultiple = (photoUrls) => {
    if (photoUrls.length > 0 && Array.isArray(photoUrls)) {
      return (
        <>
          {photoUrls.map((tile, idx) => (
            <div
              key={idx}
              style={{ position: "relative", width: 121, height: 121 }}
            >
              <img
                src={
                  CLOUDINARY_BASE_URL_1 +
                  QUALITY_100 +
                  CLOUDINARY_BASE_URL_2 +
                  tile
                }
                alt="thumbnail"
                className={classes.image}
                onClick={() => openCarouselOrPreview(idx)}
              />
              <div
                style={{
                  position: "absolute",
                  top: -7,
                  right: -7,
                }}
              >
                <IconButton
                  onClick={() => deleteImage(tile)}
                  style={{
                    color: "white",
                    background:
                      "radial-gradient(circle, rgba(0,0,0,0.5) 5%, rgba(255,255,255,0) 45%)",
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </div>
            </div>
          ))}
        </>
      );
    }
  };

  return (
    <div>
      {titleText && (
        <Typography variant="h6" gutterBottom>
          {titleText}
        </Typography>
      )}
      <Paper
        style={{
          display: "flex",
          marginTop: 10,
          width: "fit-content",
          maxWidth: "100%",
          flexWrap: "wrap",
        }}
      >
        <div className={classes.root}>
          <CropUploadComponent
            onFileChange={onFileChange}
            operation={operation}
            setUploading={setUploading}
            noCrop={noCrop}
          />

          {/* {photoUrl && (
            <Button
              onClick={() => setPhotoUrl([])}
              // @ts-ignore
              variant="delete"
              component="span"
              size="small"
            >
              Delete
            </Button>
          )} */}
        </div>
        <div style={{ display: "flex" }}>
          {renderMultiple(photoUrls)}
          {uploading && (
            <CircularProgress
              style={{
                marginTop: 27,
                marginLeft: 30,
                width: 51,
                height: 51,
                marginRight: 30,
              }}
            />
          )}
        </div>
      </Paper>
      {carouselState && (
        <Grow in={carouselState}>
          <div>
            <ImageCarousel
              open={carouselState}
              photos={photoUrls}
              current={activeImg}
              setCarouselState={setCarouselState}
            />
          </div>
        </Grow>
      )}
    </div>
  );
};
