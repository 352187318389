import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import TagFacesIcon from "@material-ui/icons/TagFaces";
import styled from "styled-components";

interface ChipData {
  id: string;
  name: string;
  label: string;
}

const Wrapper = styled.div`
  position: relative;
  cursor: text;
  font-size: 1.4rem;
  width: 100%;
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      listStyle: "none",
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
);

export const CategoriesChips: React.FC<{
  chipData: ChipData[];
  setChecked: (chipData: ChipData[]) => void;
  addMore?: React.ReactElement;
}> = ({ chipData, setChecked, addMore = undefined }) => {
  const classes = useStyles();

  const handleDelete = (chipToDelete: ChipData) => () => {
    console.log("chipdata", chipData);
    const chips = chipData;
    console.log(
      "chips",
      chips,
      "filtered",
      chips.filter((chip) => chip.id !== chipToDelete.id)
    );
    setChecked(chips.filter((chip) => chip.id !== chipToDelete.id));
  };

  return (
    <Wrapper>
      {chipData.map((data) => {
        let icon;

        if (data?.label === "React") {
          icon = <TagFacesIcon />;
        }

        return (
          <span key={data.id}>
            <Chip
              icon={icon}
              label={data.name}
              onDelete={data.name === "React" ? undefined : handleDelete(data)}
              className={classes.chip}
            />
          </span>
        );
      })}
      {addMore}
    </Wrapper>
  );
};

export default CategoriesChips;
