import * as React from "react";

import {
  Typography,
  TableContainer,
  Paper,
  makeStyles,
  createStyles,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import styled from "styled-components";
import { Transaction } from "./types";
import { ShowTable } from "./ShowTable";
import EditModal from "../../sharedComponents/EditModal";

export const MyTableCell = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
`;

export const MyPhotoTableCell = styled(MyTableCell)`
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0px;
`;

export const MyTableRow = styled(TableRow)`
  cursor: pointer;
`;
export const ThumbnailImg = styled.img`
  ${(props) => props.height && `height: ${props.height};`}
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

interface TransactionsSelectProps {
  myCompanyTransactions: { rows: Transaction[] };
  transactionsLoading: any;
  pageCount: number;
  page: number;
  setPage: Function;
  globalRefresh: any;
}

type Anchor = "top" | "left" | "bottom" | "right";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > * + *": {
        marginTop: theme.spacing(2),
        fontSize: 20,
      },
    },
  })
);

export const TransactionsSelect: React.FC<TransactionsSelectProps> = ({
  myCompanyTransactions,
  pageCount,
  page,
  setPage,
  globalRefresh,
}) => {
  const [editingId, setEditingId] = React.useState("");

  const classes = useStyles();

  const tableHeader = ["Amount", "Paid", "Type", "Extra data", "Date created"];

  const handleChange = (e: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const renderTransactionsTable = (transactions: Transaction[]) => {
    return (
      <React.Fragment>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tableHeader.map((th, i) => (
                  <MyTableCell key={i}>{th}</MyTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map(
                ({
                  id,
                  payment_id,
                  type,
                  extra_data,
                  date_created,
                  currency_code,
                  cancelled,
                  amount,
                }) => {
                  return (
                    <MyTableRow key={id} onClick={(e) => setEditingId(id)}>
                      <MyTableCell>
                        {amount} {currency_code}
                      </MyTableCell>

                      <MyTableCell>
                        {cancelled ? <CheckCircleIcon /> : <CancelIcon />}
                      </MyTableCell>
                      <MyTableCell> {type} </MyTableCell>
                      <MyTableCell> {extra_data} </MyTableCell>
                      <MyTableCell>
                        {new Date(date_created).toUTCString()}
                      </MyTableCell>
                    </MyTableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          className={classes.root}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Pagination
            count={pageCount}
            shape="rounded"
            size="large"
            page={page}
            onChange={handleChange}
          />
        </div>
      </React.Fragment>
    );
  };

  const editedTransaction = myCompanyTransactions?.rows.find(
    (p) => p.id === editingId
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: 9,
          marginBottom: 10,
          alignItems: "center",
        }}
      >
        <Typography variant="h2">Transactions</Typography>
        <IconButton onClick={() => globalRefresh()}>
          <RefreshIcon />
        </IconButton>
      </div>

      {myCompanyTransactions?.rows ? (
        renderTransactionsTable(myCompanyTransactions.rows)
      ) : (
        <Typography variant="subtitle1">No transactions</Typography>
      )}

      <EditModal
        title={"Transaction detail"}
        editObject={editedTransaction}
        onClose={() => setEditingId("")}
        editComponent={(editObject, closeForm) => (
          <ShowTable
            data={editObject}
            editingId={editingId}
            closeForm={closeForm}
          />
        )}
      />
    </div>
  );
};
