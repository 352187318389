import * as React from 'react';

export const Page403: React.FC = () => {
    return (
        <main className="notfound-page" style={{        
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",}}> 
        <h1>403 - Nemáte oprávnění</h1>
      </main>
    );
};
