import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from "@material-ui/core";
import { T } from "../../utils/translate";
import {
  CompanyCategories,
  DefaultValue,
} from "../Categories/CompanyCategories";
import styled from "styled-components";

const AddMoreDiv = styled.div`
  height: 32px;
  margin: 6px;
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      outline: "none",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

export const TransitionsModal: React.FC<{
  defaultValues: DefaultValue[];
  onChangeValue: (values: DefaultValue[]) => void;
  onClose: () => void;
}> = ({ defaultValues, onChangeValue, onClose }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div>
      <AddMoreDiv onClick={handleOpen}>
        <Button size="small" variant="contained" color="primary">
          {T("Add category")}
        </Button>
      </AddMoreDiv>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div style={{ outline: "none", overflowY: "initial" }}>
            <CompanyCategories
              onChangeValue={onChangeValue}
              defaultValues={defaultValues}
              handleClose={handleClose}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;
