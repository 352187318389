import { getAppSettings } from "../appsettings";

class UrlService {
  private _baseUrl: string;
  private _apiUrl: string;
  private _authUrl: string;

  public constructor(baseUrl: string, apiUrl: string, authUrl: string) {
    this._baseUrl = baseUrl;
    this._apiUrl = apiUrl;
    this._authUrl = authUrl;
  }

  public front(path: string) {
    return `${this._baseUrl}${path}`;
  }

  public api(path: string) {
    console.log("apiUrl", this._apiUrl);
    console.log("path", path);
    if (this._apiUrl[this._apiUrl.length - 1] === "/" && path[0] === "/") {
      path = path.substr(1);
    }
    return `${this._apiUrl}${path}`;
  }

  public auth(path: string) {
    return `${this._authUrl}${path}`;
  }
}

const aps = getAppSettings();

export const urlService = new UrlService(aps.baseUrl, aps.apiUrl, aps.authUrl);
