import React, { useState, useEffect, useCallback } from "react";
import {
  Backdrop,
  Portal,
  Slide,
  GridListTile,
  GridList,
  makeStyles,
  Box,
  IconButton,
  Dialog,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ClearIcon from "@material-ui/icons/Clear";
import classnames from "classnames";
import {
  CLOUDINARY_BASE_URL_1,
  CLOUDINARY_BASE_URL_2,
  QUALITY_100,
  QUALITY_500,
} from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  imageList: {
    overflow: "auto",
    width: 610,
    flexWrap: "nowrap",
    margin: "20px 0",
  },
  imageItem: {
    height: 121,
    overflow: "hidden",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    opacity: 0.3,
    "&.acitve": {
      opacity: 1,
    },
  },
  leftRightButton: {
    height: 500,
    width: 100,
  },
}));

const TIMEOUT = 400;

const ImageCarousel = ({ open, photos, current, setCarouselState }) => {
  const [index, setIndex] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [slide, setSlide] = useState(false);
  const [slideDirection, setSlideDirection] = useState<"left" | "right">(
    "left"
  );
  const classes = useStyles();

  //idx is position of photo to slide to. If unknown, it's -1
  const goToNext = (direction, idx = 0) => {
    let x;
    if (slide) {
      x = index;
    } else {
      x = index2;
    }

    if (idx > -1) {
      if (idx < x) {
        direction = "left";
      } else if (idx > x) {
        direction = "right";
      } else {
        return;
      }
    }

    const newIndex =
      idx > -1
        ? idx
        : direction === "left"
        ? (x - 1 + photos.length) % photos.length
        : (x + 1 + photos.length) % photos.length;

    if (!slide) {
      setIndex(newIndex);
    } else {
      setIndex2(newIndex);
    }
    const slideNow = slide;

    if (!slide) {
      if (direction === "left") {
        direction = "right";
      } else {
        direction = "left";
      }
    }
    setSlideDirection(direction);
    setSlide(!slideNow); //slide is state altering between 2 Slide components
  };

  const keydownFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setCarouselState(false);
      } else if (event.keyCode === 37 || event.keyCode === 100) {
        goToNext("left", -1);
        event.preventDefault();
      } else if (event.keyCode === 39 || event.keyCode === 102) {
        goToNext("right", -1);
        event.preventDefault();
      }
    },
    [goToNext, setCarouselState]
  );

  useEffect(() => {
    document.addEventListener("keydown", keydownFunction, false);

    return () => {
      document.removeEventListener("keydown", keydownFunction, false);
    };
  }, [keydownFunction]);

  useEffect(() => {
    setIndex(current);
  }, [current]);

  useEffect(() => {
    setSlide(open);
  }, [open]);

  return (
    <Dialog
      fullScreen
      PaperProps={{
        style: {
          background: "transparent",
          color: "inherit",
          boxShadow: "none",
        },
      }}
      open={open}
      onClose={() => setCarouselState(false)}
      onClick={() => setCarouselState(false)}
    >
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box
          display="flex"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
          position="relative"
          width="100%"
        >
          {[index2, index].map((it, i) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 0,
                bottom: 100,
                left: 100,
                right: 100,
              }}
              key={`image${i}`}
            >
              <Slide
                direction={
                  i == 1
                    ? slideDirection
                    : slideDirection === "left"
                    ? "right"
                    : "left"
                }
                in={i == 1 ? slide : !slide}
                timeout={TIMEOUT}
              >
                <div
                  onClick={(e) => e.stopPropagation()}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      CLOUDINARY_BASE_URL_1 +
                      QUALITY_500 +
                      CLOUDINARY_BASE_URL_2 +
                      photos[it]
                    }
                    alt={`image${it}`}
                    style={{ width: "min(600px, 100%)" }}
                  />
                </div>
              </Slide>
            </div>
          ))}
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              goToNext("left", -1);
            }}
            className={classes.leftRightButton}
          >
            <ArrowBackIosIcon /*size={100}*/ style={{ color: "#fff" }} />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              goToNext("right", -1);
            }}
            className={classes.leftRightButton}
          >
            <ArrowForwardIosIcon /*size={100}*/ style={{ color: "#fff" }} />
          </IconButton>
        </Box>
        <Box mt={5} position="absolute" bottom={10}>
          <GridList cellHeight={121} className={classes.imageList} cols={5}>
            {photos.map((tile, idx) => (
              <GridListTile
                classes={{ tile: classes.imageItem }}
                key={idx}
                cols={1}
                onClick={(e) => {
                  e.stopPropagation();
                  const x = slide ? index : index2;
                  if (x != idx) {
                    goToNext(false, idx);
                  }
                }}
              >
                <img
                  src={
                    CLOUDINARY_BASE_URL_1 +
                    QUALITY_100 +
                    CLOUDINARY_BASE_URL_2 +
                    tile
                  }
                  alt="thumbnail"
                  className={classnames(
                    classes.image,
                    idx === (slide ? index : index2) && "acitve"
                  )}
                />
              </GridListTile>
            ))}
          </GridList>
        </Box>
      </Box>
      <IconButton
        style={{ position: "absolute", top: 20, right: 20 }}
        onClick={() => setCarouselState(false)}
      >
        <ClearIcon /* size={150} */ style={{ color: "#fff" }} />
      </IconButton>
    </Dialog>
  );
};

export default ImageCarousel;
